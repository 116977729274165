import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

// TYPES
import {
  TAuthenticationConfigurationType,
  AuthenticationConfigurationType,
} from '../types/authConfig.type';

/* -------------------------------------------------------------------------------- */

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedAuthenticationConfiguration:
    AuthenticationConfigurationType.createEmpty(
      'customerSignature',
    ) /* FIX THIS */,
  authConfigs: [],
};

// Slice
const slice = createSlice({
  name: 'authConfigs',
  initialState,
  reducers: {
    fetchAuthConfigsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchAuthConfigsSuccess: (state: any, { payload }) => {
      if (state.selectedAuthenticationConfiguration.id > 0) {
        state.selectedAuthenticationConfiguration =
          payload.find(
            (config: any) =>
              config.id === state.selectedAuthenticationConfiguration.id,
          ) ||
          AuthenticationConfigurationType.createEmpty(
            'customerSignature',
          ); /* FIX */
      }
      state.authConfigs = payload;
      finishedLoadingSuccess(state);
    },
    fetchAuthConfigsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createAuthConfigRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createAuthConfigSuccess: (state: any, { payload }) => {
      state.authConfigs.push(payload);
      state.selectedAuthenticationConfiguration = payload;
      finishedLoadingSuccess(state);
    },
    createAuthConfigFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentAuthConfig: (state: any, { payload }) => {
      state.selectedAuthenticationConfiguration = payload;
    },
    deleteAuthConfigRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteAuthConfigSuccess: (state: any, { payload }) => {
      state.authConfigs = state.authConfigs.filter(
        (authenticationConfiguration: TAuthenticationConfigurationType) =>
          authenticationConfiguration.id !== payload.id,
      );
      state.selectedAuthenticationConfiguration =
        AuthenticationConfigurationType.createEmpty(
          'customerSignature',
        ); /* FIX */
      finishedLoadingSuccess(state);
    },
    deleteAuthConfigFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateAuthConfigRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateAuthConfigSuccess: (state: any, { payload }) => {
      const index = state.authConfigs.findIndex(
        (authenticationConfiguration: TAuthenticationConfigurationType) =>
          authenticationConfiguration.id === payload.id,
      );
      state.authConfigs[index] = payload;
      finishedLoadingSuccess(state);
    },
    updateAuthConfigFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const authenticationConfigurationsLoading = (state: any) =>
  state.authConfigs.loading;
export const authenticationConfigurationsSelector = (state: any) =>
  state.authConfigs.authConfigs;
export const authenticationConfigurationSelectorCreateNew =
  (customerSignature: string) => () => {
    return AuthenticationConfigurationType.createEmpty(customerSignature);
  };
export const selectedAuthenticationConfigurationSelector = (state: any) => {
  return state.authConfigs.selectedAuthenticationConfiguration;
};

// Actions
export const {
  fetchAuthConfigsRequest,
  fetchAuthConfigsSuccess,
  fetchAuthConfigsFailure,
  createAuthConfigRequest,
  createAuthConfigSuccess,
  createAuthConfigFailure,
  setCurrentAuthConfig,
  deleteAuthConfigRequest,
  deleteAuthConfigFailure,
  deleteAuthConfigSuccess,
  updateAuthConfigRequest,
  updateAuthConfigSuccess,
  updateAuthConfigFailure,
} = slice.actions;

export const fetchAuthenticationConfigurations =
  () => async (dispatch: any) => {
    try {
      dispatch(fetchAuthConfigsRequest());
      const authConfigs = await api.get({
        endpoint: `/auth`,
      });
      dispatch(fetchAuthConfigsSuccess(authConfigs));
    } catch (e) {
      dispatch(fetchAuthConfigsFailure());
      return console.error(e);
    }
  };

export const createAuthenticationConfiguration =
  (authenticationConfiguration: TAuthenticationConfigurationType) =>
  async (dispatch: any) => {
    try {
      dispatch(createAuthConfigRequest());
      const { id: _, ...AuthConfigBody } = authenticationConfiguration;
      const response = await api.get({
        endpoint: `/auth`,
        data: AuthConfigBody,
      });
      dispatch(createAuthConfigSuccess(response));
    } catch (e) {
      dispatch(createAuthConfigFailure());
      return console.error(e);
    }
  };

export const createEmptyAuthenticationConfiguration =
  (customerSignature: string) => async (dispatch: any) => {
    try {
      dispatch(createAuthConfigRequest());
      const response =
        AuthenticationConfigurationType.createEmpty(customerSignature);
      dispatch(createAuthConfigSuccess(response));
    } catch (e) {
      dispatch(createAuthConfigFailure());
      return console.error(e);
    }
  };

export const setSelectedAuthenticationConfiguration =
  (authenticationConfiguration: TAuthenticationConfigurationType) =>
  (dispatch: any) => {
    dispatch(setCurrentAuthConfig(authenticationConfiguration));
  };

export const copyAuthenticationConfiguration =
  (authenticationConfiguration: TAuthenticationConfigurationType) =>
  async (dispatch: any) => {
    try {
      dispatch(createAuthConfigRequest());
      const response = {
        ...authenticationConfiguration,
        id: 0,
      };
      dispatch(createAuthConfigSuccess(response));
    } catch (e) {
      dispatch(createAuthConfigFailure());
      return console.error(e);
    }
  };

export const deleteAuthenticationConfiguration =
  (authenticationConfiguration: TAuthenticationConfigurationType) =>
  async (dispatch: any) => {
    try {
      dispatch(deleteAuthConfigRequest());
      await api.delete({
        endpoint: `/auth/${authenticationConfiguration.id}`,
      });
      dispatch(deleteAuthConfigSuccess(authenticationConfiguration));
    } catch (e) {
      dispatch(deleteAuthConfigFailure());
      return console.error(e);
    }
  };

export const updateAuthenticationConfiguration =
  (authenticationConfiguration: TAuthenticationConfigurationType) =>
  async (dispatch: any) => {
    try {
      dispatch(updateAuthConfigRequest());
      const { ...AuthConfigBody } = authenticationConfiguration;
      const response = await api.patch({
        endpoint: `/auth/${authenticationConfiguration.id}`,
        data: { ...AuthConfigBody },
      });
      dispatch(updateAuthConfigSuccess(response));
    } catch (e) {
      dispatch(updateAuthConfigFailure());
      return console.error(e);
    }
  };
