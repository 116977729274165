export const LANG_CODE = {
  Danish: 'da',
  Dutch: 'nl',
  English: 'en',
  Finnish: 'fi',
  German: 'de',
  Norwegian: 'nb',
  Swedish: 'sv',
};

// Languages supported in TE Admin
export const TEX_LANGUAGES = {
  [LANG_CODE.English]: 'English',
  [LANG_CODE.Swedish]: 'Svenska',
  // [LANG_CODE.Norwegian]: 'Norsk',
  // [LANG_CODE.Danish]: 'Dansk',
  // [LANG_CODE.Dutch]: 'Nederlands',
  // [LANG_CODE.Finnish]: 'Suomi',
  // [LANG_CODE.German]: 'Deutsch',
};

export const DEFAULT_LANG = LANG_CODE.English;
