import { createIntl } from 'react-intl';

import { DEFAULT_LANG } from '../constants/i18n.constants';
import translatedMessages from './translations/messages';

const LANG_KEY = 'language';

let currentLanguage = localStorage.getItem(LANG_KEY);
if (!currentLanguage) {
  currentLanguage = DEFAULT_LANG;
  localStorage.setItem(LANG_KEY, currentLanguage);
}

const intl = createIntl({
  locale: currentLanguage,
  messages: translatedMessages[currentLanguage],
});

export default intl;

const isCurrentLanguage = (lang) => lang === currentLanguage;
const getCurrentLanguage = () => currentLanguage;

const setLanguage = (lang) => {
  if (!isCurrentLanguage(lang)) {
    localStorage.setItem(LANG_KEY, lang);
    window.location.reload();
  }
};

const { formatMessage } = intl;

const getInlineString = (key, ...args) => {
  let result = intl.messages[key];
  if (args.length > 0) {
    const numArgs = args.length;
    let i, arg, index;
    for (i = 0; i < numArgs; i++) {
      arg = args[i];
      index = result.indexOf(`{${i}}`);
      if (index !== -1) {
        result = result.replace(`{${i}}`, arg);
      }
    }
  }
  return result;
};

export {
  formatMessage,
  setLanguage,
  isCurrentLanguage,
  getCurrentLanguage,
  getInlineString,
};
