import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import {
  TOrganizationNode,
  TOrganizationTreeNode,
  OrganizationNode,
} from '../types/organization.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedOrganization: OrganizationNode.createEmpty(),

  allOrganizationTreeNodes: [],
};

// Slice
const slice = createSlice({
  name: 'organizationNodes',
  initialState,
  reducers: {
    fetchOrganizationsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchOrganizationTreeNodesSuccess: (state: any, { payload }) => {
      state.allOrganizationTreeNodes = payload;
    },
    fetchOrganizationsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    getOrganizationNodeSuccess: (state: any, { payload }) => {
      state.selectedOrganization = payload;
    },
    setCurrentOrganization: (state: any, { payload }) => {
      state.selectedOrganization = payload;
    },
    createOrganizationRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createOrganizationSuccess: (state: any, { payload }) => {
      state.selectedOrganization = payload;
      finishedLoadingSuccess(state);
    },
    createOrganizationFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    deleteOrganizationRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteOrganizationSuccess: (state: any) => {
      state.selectedOrganization = OrganizationNode.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteOrganizationFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateOrganizationRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateOrganizationSuccess: (state: any, { payload }) => {
      state.selectedOrganization = payload;
      finishedLoadingSuccess(state);
    },
    updateOrganizationFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const organizationsLoading = (state: any) =>
  state.organizationNodes.loading;
export const organizationTreeNodesSelector = (state: any) =>
  state.organizationNodes.allOrganizationTreeNodes as TOrganizationTreeNode[];
export const organizationSelectorCreateNew = () => () => {
  return OrganizationNode.createEmpty();
};
export const selectedOrganizationNodeSelector = (state: any) => {
  return state.organizationNodes.selectedOrganization;
};

// Actions
export const {
  fetchOrganizationsRequest,
  fetchOrganizationTreeNodesSuccess,
  fetchOrganizationsFailure,
  getOrganizationNodeSuccess,
  setCurrentOrganization,
  createOrganizationRequest,
  createOrganizationSuccess,
  createOrganizationFailure,
  deleteOrganizationRequest,
  deleteOrganizationSuccess,
  deleteOrganizationFailure,
  updateOrganizationRequest,
  updateOrganizationSuccess,
  updateOrganizationFailure,
} = slice.actions;

// Getting the full tree with TOrganizationTreeNode[]
export const fetchOrganizationTreeNodes = () => async (dispatch: any) => {
  try {
    dispatch(fetchOrganizationsRequest());
    const organizations = await api.get({
      endpoint: `/organizations-nodes`,
    });
    dispatch(fetchOrganizationTreeNodesSuccess(organizations));
  } catch (e) {
    dispatch(fetchOrganizationsFailure());
    return console.error(e);
  }
};

// Get a node
export const getOrganizationNode =
  (nodeId: string) => async (dispatch: any) => {
    try {
      dispatch(fetchOrganizationsRequest());
      const organization = await api.get({
        endpoint: `/organizations-nodes/nodes/${nodeId}`,
      });
      dispatch(getOrganizationNodeSuccess(organization));
    } catch (e: any) {
      dispatch(fetchOrganizationsFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

// Set selected node
export const setSelectedOrganization =
  (organization: TOrganizationNode) => async (dispatch: any) => {
    dispatch(setCurrentOrganization(organization));
  };

// Create a new node
export const createOrganizationNode =
  (organization: TOrganizationNode) => async (dispatch: any) => {
    try {
      dispatch(createOrganizationRequest());
      const { organizationId: _, ...organizationBody } = organization;
      const response = await api.post({
        endpoint: `/organization-nodes/nodes`,
        data: organizationBody,
      });
      dispatch(createOrganizationSuccess(response));
    } catch (e: any) {
      dispatch(createOrganizationFailure());
      return console.error(e.response);
    }
  };

export const createEmptyOrganizationNode =
  (parentNodes?: string[]) => async (dispatch: any) => {
    try {
      dispatch(createOrganizationRequest());
      const response = OrganizationNode.createEmpty(parentNodes);
      dispatch(createOrganizationSuccess(response));
    } catch (e: any) {
      dispatch(createOrganizationFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

// Copy node
export const copyOrganizationNode =
  (organization: TOrganizationNode) => async (dispatch: any) => {
    try {
      dispatch(createOrganizationRequest());
      const response = {
        ...organization,
        organizationId: 'new',
      };
      dispatch(createOrganizationSuccess(response));
    } catch (e: any) {
      dispatch(createOrganizationFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

// Delete a node
export const deleteOrganizationNode =
  (organization: TOrganizationNode) => async (dispatch: any) => {
    try {
      dispatch(deleteOrganizationRequest());
      await api.delete({
        endpoint: `/organization-nodes/nodes/${organization.organizationId}`,
      });
      dispatch(deleteOrganizationSuccess());
    } catch (e) {
      dispatch(deleteOrganizationFailure());
      return console.error(e);
    }
  };

// Update a node
export const updateOrganizationNode =
  (organization: TOrganizationNode) => async (dispatch: any) => {
    try {
      dispatch(updateOrganizationRequest());
      const { ...organizationBody } = organization;
      const response = await api.patch({
        endpoint: `/organization-nodes/nodes/${organization.organizationId}`,
        data: { ...organizationBody },
      });
      dispatch(updateOrganizationSuccess(response));
    } catch (e: any) {
      dispatch(updateOrganizationFailure());
      return console.error(e.response);
    }
  };
