import { useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

// ACTIONS
import { fieldsSelector } from '../../slices/field.slice';
import { reservationTemplatesSelector } from '../../slices/reservationTemplate.slice';
import { organizationsSelector } from '../../slices/organization.slice';

// i18N
import intl from '../../i18n/intl';

// STYLES
import {
  Form,
  Collapse,
  Input,
  Checkbox,
  Select,
  Transfer,
  List,
  Button,
} from 'antd';

// TYPES
import { TExternalOwner, TType } from '../../types/type.type';
import { TField } from '../../types/field.type';
import { TReservationTemplate } from '../../types/reservation.type';

// UTILS
import {
  getExtIdOnField,
  getNameOnField,
  getNameOnType,
  getExtIdOnType,
  getDescriptionOnType,
  getNameOnReservationTemplates,
} from '../../utils/getNameOn';

// COMPONENTS
import { TEItem } from '../TEToolTip';

const onBlur = () => {};
const onFocus = () => {};

type Props = {
  type: TType;
  onChange: Function;
  limitations: any;
  onDelete: any;
};

type PropsTypes = {
  types: Array<TType>;
  type: TType;
  subTypes: Array<number>;
  onChange: Function;
  externalOwners: Array<TExternalOwner>;
  aliasTypes: Array<number>;
  parents: any;
};

type PropsFields = {
  fieldsChoosen: any;
  onChange: Function;
  parentFields: Array<number>;
};

type PropsOrgTemps = {
  organizations: Array<number>;
  templates: Array<number>;
  onChange: Function;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const TypesGeneralSettings = ({
  type,
  onChange,
  limitations,
  onDelete = () => {},
}: Props) => {
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  const onChangeCheckboxHandler = (event: any, property: string) => {
    onChange(property, event.target.checked);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Form.Item
          tooltip={`(Maximum size of ${limitations.name} bytes)`}
          label={`${language.name}`}
        >
          <Input
            size='small'
            defaultValue={type.name}
            value={type.name}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'name', limitations.name)
            }
          />
        </Form.Item>
        <Form.Item
          label={language.ext_id}
          tooltip={`${language.ext_id} (Maximum size of ${limitations.extId} bytes)`}
        >
          <Input
            size='small'
            defaultValue={type.extId}
            value={type.extId}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'extId', limitations.extId)
            }
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'active')
            }
            checked={type.active}
          >
            {language.active}
          </Checkbox>
        </Form.Item>
        <p className='form-item-text' style={{ marginBottom: '1em' }}>
          {language.id}: {type.id}
        </p>
        <TEItem helpText={language.help_text_types_personaldata}>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'personalData')
            }
            checked={type.personalData}
          >
            {language.personal_data}
          </Checkbox>
        </TEItem>
        <Form.Item
          label={language.description}
          tooltip={`(Maximum size of ${limitations.description} bytes)`}
        >
          <Input.TextArea
            size='small'
            rows={5}
            defaultValue={type.description}
            value={type.description}
            onChange={(value: any) =>
              onChangeInputHandler(
                value,
                'description',
                limitations.description,
              )
            }
          />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button type='primary' danger onClick={() => onDelete(type)}>
            {language.delete_type}
          </Button>
          <p
            className='small-heading-text'
            style={{ marginLeft: 8, marginBottom: 0 }}
          >
            {language.warning_not_delete_type_in_use}
          </p>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const TypesPropertiesSubtypes = ({
  type,
  types,
  subTypes,
  onChange,
  externalOwners,
  aliasTypes,
  parents,
}: PropsTypes) => {
  // Datasource with available types to choose as subtypes
  // All root's subtypes excluding all the selected type's parents / grandparents,
  // and excluding the selected type
  const dataSourceTypes = types
    ?.filter((t: TType) => type.id !== t.id)
    .filter((t: TType) => parents?.indexOf(t.id) === -1)
    .map((t: TType) => ({
      key: String(t.id),
      title: String(t.extId),
      name: t.name,
      description: t.description,
    }));

  // The choosen types = subTypes
  const targetKeys = subTypes?.map((type: any) => String(type));

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const onSelection = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChange = (nextTargetKeys: any) => {
    onChange(
      'subTypes',
      nextTargetKeys.map((key: string) => parseInt(key)),
    );
  };

  const onChangeCheckboxHandler = (event: any, property: string) => {
    onChange(property, event.target.checked);
  };

  const onChangeSelectHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
  ) => {
    onChange(property, event);
  };

  // Get name on types
  const getNameAndExtIdOnType = (aliasType: number) => {
    const result = types.find((t: TType) => t.id === aliasType);
    if (result) {
      if (result.name === type.name) {
        return '-';
      }
      return (
        <p style={{ marginBottom: 0 }}>
          {result.name} ({result.extId})
        </p>
      );
    }
    return type;
  };

  return (
    <Collapse defaultActiveKey={['3']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.properties_subtypes} key='1'>
        <Form.Item>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'allowStandardObjects')
            }
            checked={type.allowStandardObjects}
          >
            {language.allow_standard_objects}
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'allowAbstractObjects')
            }
            checked={type.allowAbstractObjects}
          >
            {language.allow_abstract_objects}
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'allowVirtualObjects')
            }
            checked={type.allowVirtualObjects}
          >
            {language.allow_virtual_objects}
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'personType')
            }
            checked={type.personType}
          >
            {language.person_type}
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'locationType')
            }
            checked={type.locationType}
          >
            {language.location_type}
          </Checkbox>
        </Form.Item>
        <Form.Item
          label={language.alias_type}
          tooltip={language.help_text_types_aliastype}
        >
          <Select
            size='small'
            onChange={(value: any) => onChangeSelectHandler(value, 'aliasType')}
            onFocus={onFocus}
            onBlur={onBlur}
            value={type.aliasType}
            disabled={aliasTypes.length <= 1}
          >
            {aliasTypes.map((aliasType: any) => (
              <Select.Option key={aliasType} value={aliasType}>
                {(getNameAndExtIdOnType(aliasType) as TType).name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.external_ownership}
          tooltip={language.help_text_types_external_ownership}
        >
          <Select
            size='small'
            onChange={(value: any) =>
              onChangeSelectHandler(value, 'externalOwner')
            }
            onFocus={onFocus}
            onBlur={onBlur}
            value={type.externalOwner}
          >
            {externalOwners.map((externalOwner: TExternalOwner) => (
              <Select.Option key={externalOwner.id} value={externalOwner.name}>
                {externalOwner.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={`${language.subtypes}`}>
          <Transfer
            dataSource={dataSourceTypes}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onSelectionChange}
            onSelectChange={onSelection}
            render={(item) => (
              <span>
                {`${item.title} (${item.name})`}
              </span>
            )}
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};

export const TypesFields = ({
  fieldsChoosen,
  onChange,
  parentFields,
}: PropsFields) => {
  // Data for all Fields
  const fields: TField[] = useSelector(fieldsSelector);
  const dataSourceFields = fields.map((field: any) => ({
    key: String(field.id),
    title: field.extId,
    name: field.name,
    description: field.description,
  }));

  // Selected fields
  const targetKeys = fieldsChoosen?.map((selectedField: any) =>
    String(selectedField),
  );

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const onSelection = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChange = (nextTargetKeys: any) => {
    onChange(
      'fields',
      nextTargetKeys.map((key: string) => parseInt(key)),
    );
  };

  return (
    <Collapse defaultActiveKey={['4']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.fields} key='3'>
        <Form.Item label={`${language.fields}`}>
          <Transfer
            dataSource={dataSourceFields}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onSelectionChange}
            onSelectChange={onSelection}
            render={(item) => (
              <span>
                {item.title} ({item.name})
              </span>
            )}
          />
        </Form.Item>
        <p className='small-heading-text'>{language.parent_fields}</p>
        <List
          size='small'
          bordered
          dataSource={parentFields}
          renderItem={(item) => (
            <List.Item>
              <span>
                {`${getExtIdOnField(fields, item)} (${getNameOnField(
                  fields,
                  item,
                )})`}
              </span>
            </List.Item>
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export const TypesOrganizationsTemplates = ({
  organizations,
  templates,
  onChange,
}: PropsOrgTemps) => {
  // ORGANIZATIONS
  // All available organizations
  const allOrganizations: any = useSelector(organizationsSelector);
  const organizationsToChooseFrom = allOrganizations.filter(
    (org: any) => !org.subOrganizations,
  );

  const dataSourceOrganizations = organizationsToChooseFrom.map((org: any) => ({
    key: String(org.id),
    title: org.name,
    description: org.description,
  }));

  const targetKeysOrganizations = organizations?.map(
    (selectedOrganization: any) => String(selectedOrganization),
  );
  const [selectedKeysOrganizations, setSelectedKeysOrganizations] = useState<
    string[]
  >([]);

  const onSelectionOrganizations = (
    sourceSelectedKeys: any,
    targetSelectedKeys: any,
  ) => {
    setSelectedKeysOrganizations([
      ...sourceSelectedKeys,
      ...targetSelectedKeys,
    ]);
  };

  const onSelectionChangeOrganizations = (nextTargetKeys: any) => {
    onChange(
      'organizations',
      nextTargetKeys.map((key: string) => parseInt(key)),
    );
  };
  // TEMPLATES
  const reservationTemplates: TReservationTemplate[] = useSelector(
    reservationTemplatesSelector,
  );

  return (
    <Collapse defaultActiveKey={['5']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.organizations_templates} key='4'>
        <Form.Item label={`${language.organizations}`}>
          <Transfer
            dataSource={dataSourceOrganizations}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeysOrganizations}
            selectedKeys={selectedKeysOrganizations}
            onChange={onSelectionChangeOrganizations}
            onSelectChange={onSelectionOrganizations}
            render={(item: any) => item.title}
          />
        </Form.Item>
        <p className='small-heading-text'>{language.reservation_templates}</p>
        <List
          size='small'
          bordered
          dataSource={getNameOnReservationTemplates(
            reservationTemplates,
            templates,
          )}
          renderItem={(item: any) => <List.Item key={item}>{item}</List.Item>}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
