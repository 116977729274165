export type TAvailabilityRelationType = {
  name: string;
  extId: string;
  id: number;
  description: string;
  constitutesObstacles: boolean;
  occupiedByDefault: boolean;
  availabilityTypes: Array<TAvailabilityTypesItem>;
};

export class AvailabilityRelationType {
  static createEmpty = (): TAvailabilityRelationType => ({
    name: '',
    extId: '',
    id: 0,
    description: '',
    constitutesObstacles: false,
    occupiedByDefault: false,
    availabilityTypes: [],
  });
}

export type TAvailabilityTypesItem = {
  type: number | string | undefined;
  ownObject: boolean;
  userObject: boolean;
};

export class AvailabilityTypesItem {
  static create = (
    typeId: number | string | undefined,
  ): TAvailabilityTypesItem => ({
    type: typeId,
    ownObject: false,
    userObject: false,
  });
}
