import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// SLICES
import { setBreadcrumbs } from '../../../slices/ui.slice';
import { fieldsSelector } from '../../../slices/field.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form } from 'antd';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import NormalListView from '../../../components/List/NormalListView';
import {
  FieldRelationsGeneralSettings,
  FieldRelationsConfiguration,
  FieldRelationsReservationTemplates,
} from '../../../components/FieldRelationsComponent';
import Documentation from '../../../components/Documentation';

// UTILS
import { timeConverter } from '../../../utils/timeConverter';

// TYPES
import {
  TFieldRelation,
  EMatchMode,
  EOperator,
} from '../../../types/fieldRelation.type';
import { EFieldType, TField } from '../../../types/field.type';

// ACTIONS
import {
  fetchFieldRelations,
  fieldRelationsSelector,
  createFieldRelation,
  deleteFieldRelation,
  updateFieldRelation,
  createEmptyFieldRelation,
  copyFieldRelation,
  setSelectedFieldRelation,
  selectedFieldRelationSelector,
  fetchLimitationsFieldRelationsSelector,
  fetchLimitationsFieldRelations,
} from '../../../slices/fieldRelation.slice';

// UTILS
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const FieldRelationsPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const fieldRelations: TFieldRelation[] = useSelector(fieldRelationsSelector);
  const limitations = useSelector(fetchLimitationsFieldRelationsSelector);
  const selectedFieldRelation: TFieldRelation = useSelector(
    selectedFieldRelationSelector,
  );

  // Select all fields
  const allFields: TField[] = useSelector(fieldsSelector);

  // The state for setting error if ext.id already exists
  const [nameError, setNameError] = useState(false);

  const onFieldRelationSelected = (selectedValue: any) => {
    const currentFieldRelation = fieldRelations.find(
      (fieldRelation: TFieldRelation) => fieldRelation.id === selectedValue,
    );
    if (currentFieldRelation) {
      dispatch(setSelectedFieldRelation(currentFieldRelation));
    }
    setUnvalidFieldRelation(false);
  };

  const [form] = Form.useForm();

  const listData = fieldRelations.map((fieldRelation: any) => ({
    listLabel: fieldRelation.name,
    value: fieldRelation.id,
  }));

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: '/', label: `${customerSignature}` },
        { path: `/system`, label: `${language.system}` },
        {
          path: `/system/field-relations`,
          label: `${language.field_relations}`,
        },
        {
          path: `/system/field-relations`,
          label: `${selectedFieldRelation.name}`,
        },
      ]),
    );
  }, [
    dispatch,
    customerSignature,
    selectedFieldRelation.id,
    selectedFieldRelation.name,
  ]);

  useEffect(() => {
    dispatch(fetchFieldRelations());
    dispatch(fetchLimitationsFieldRelations());
  }, [dispatch]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabled(selectedFieldRelation, fieldRelations));
  }, [fieldRelations, selectedFieldRelation]);

  // EVENT HANDLERS
  // Add new field relation
  const onPlusButton = () => {
    setUnvalidFieldRelation(false);
    dispatch(createEmptyFieldRelation());
  };

  // Deletes selected field relation
  const onDeleteButton = () => {
    dispatch(deleteFieldRelation(selectedFieldRelation));
  };

  // Excluding the selected field relation
  const otherFieldRelations = fieldRelations.filter(
    (fieldRelation) => fieldRelation !== selectedFieldRelation,
  );

  // Save changes function
  const onSaveChangesFieldRelationButton = () => {
    if (selectedFieldRelation.id === 0) {
      if (
        _.some(
          otherFieldRelations,
          (fieldRelation: TFieldRelation) =>
            fieldRelation.name === selectedFieldRelation.name,
        )
      ) {
        setNameError(true);
      } else {
        dispatch(createFieldRelation(selectedFieldRelation));
        setNameError(false);
      }
    } else {
      dispatch(updateFieldRelation(selectedFieldRelation));
      setNameError(false);
    }
  };

  // Function for setting correct MatchMode based on fieldTypes
  // in source- and matchField
  const selectMatchMode = (
    firstType: string | undefined,
    secondType: string | undefined,
  ) => {
    if (
      firstType === EFieldType.CATEGORY &&
      secondType === EFieldType.CATEGORY
    ) {
      return EMatchMode.MATCH_ONE;
    }
    return null;
  };

  // Function for setting correct Operator based on fieldTypes
  // in source- and matchField
  const selectOperator = (
    firstType: string | undefined,
    secondType: string | undefined,
  ) => {
    if (firstType === EFieldType.INTEGER && secondType === EFieldType.INTEGER) {
      return EOperator.E;
    }
    return null;
  };

  // State and function for checking if the combination of fieldTypes is a valid FieldRelation combination
  const [unvalidFieldRelation, setUnvalidFieldRelation] = useState(false);
  const checkValidFieldRelation = (
    firstType: string | undefined,
    secondType: string | undefined,
  ) => {
    if (firstType !== EFieldType.INTEGER && secondType === EFieldType.INTEGER) {
      setUnvalidFieldRelation(true);
    } else if (
      firstType !== EFieldType.CHECKBOX &&
      secondType === EFieldType.CHECKBOX
    ) {
      setUnvalidFieldRelation(true);
    } else if (
      (firstType === EFieldType.CHECKBOX &&
        secondType === EFieldType.CATEGORY) ||
      (firstType === EFieldType.INTEGER && secondType === EFieldType.CATEGORY)
    ) {
      setUnvalidFieldRelation(true);
    } else {
      setUnvalidFieldRelation(false);
    }
  };

  const onHandleChange = (property: string, value: any) => {
    if (property === 'sourceField' || property === 'matchField') {
      // Figure out if the field type of the selected property is category or not
      const selectedField = allFields.find(
        (field: TField) => field.id === value,
      );
      const otherProperty =
        property === 'sourceField' ? 'matchField' : 'sourceField';
      const otherField = allFields.find(
        (field: TField) => field.id === selectedFieldRelation[otherProperty],
      );
      dispatch(
        setSelectedFieldRelation({
          ...selectedFieldRelation,
          [property]: value,
          matchMode: selectMatchMode(
            selectedField?.fieldType,
            otherField?.fieldType,
          ),
          operator: selectOperator(
            selectedField?.fieldType,
            otherField?.fieldType,
          ),
        }),
      );
      checkValidFieldRelation(selectedField?.fieldType, otherField?.fieldType);
    } else {
      dispatch(
        setSelectedFieldRelation({
          ...selectedFieldRelation,
          [property]: value,
        }),
      );
    }
  };

  // Discard changes function
  const onDiscardChanges = () => {
    onFieldRelationSelected(selectedFieldRelation.id);
  };

  // Copy field relation
  const onCopy = () => {
    dispatch(copyFieldRelation(selectedFieldRelation));
  };

  if (selectedFieldRelation) {
    return (
      <>
        <ListView
          sectionHeading={language.field_relations}
          number={listData.length}
          onSelect={onPlusButton}
        >
          <NormalListView
            data={listData}
            onSelect={onFieldRelationSelected}
            onDelete={onDeleteButton}
            onCopy={onCopy}
            selectedItemId={selectedFieldRelation.id}
          />
        </ListView>
        <div className='inner-content--wrapper'>
          <SectionHeader
            listChoice={
              selectedFieldRelation.name
                ? `${selectedFieldRelation.name}`
                : `${language.field_relation}`
            }
            history={selectedFieldRelation.history}
            modifiedDate={
              selectedFieldRelation.history?.length !== 0
                ? timeConverter(selectedFieldRelation?.history[0]?.modified)
                : undefined
            }
            modifiedBy={selectedFieldRelation.history[0]?.modifiedBy}
            changeHandler={onSaveChangesFieldRelationButton}
            discardHandler={onDiscardChanges}
            displayProp={false}
            isDisabled={unvalidFieldRelation === true ? true : saveDisabled}
            error={
              nameError === true
                ? `${language.name_field_relation_already_exists}`
                : null
            }
          />
          <Form layout='vertical' form={form}>
            <div style={{ position: 'relative' }}>
              <Documentation
                documentation={selectedFieldRelation.documentation}
                onChange={onHandleChange}
                limitationValue={limitations.documentation}
              />
              <FieldRelationsGeneralSettings
                fieldRelation={selectedFieldRelation}
                onChange={onHandleChange}
                limitations={limitations}
              />
              <FieldRelationsConfiguration
                fieldRelation={selectedFieldRelation}
                onChange={onHandleChange}
                unvalidFieldRelation={unvalidFieldRelation}
              />
              <FieldRelationsReservationTemplates
                reservationTemplatesConnections={
                  selectedFieldRelation.reservationTemplates
                }
                onChange={onHandleChange}
              />
            </div>
          </Form>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default FieldRelationsPage;
