// Functions for drag & drop possibility, returning the new sorted array
export const changeArrayOrder = (
  arr: Array<any>,
  index1: number,
  index2: number,
) => {
  const newArr = [...arr];
  const selectedField = newArr[index1];
  newArr.splice(index1, 1);
  newArr.splice(index2, 0, selectedField);
  return newArr;
};
