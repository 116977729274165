import { useState } from 'react';
import { menuJSON, nextMenuJSON } from '../../assets/menuJSON';
import { useRouteMatch } from 'react-router-dom';

import pkg from '../../../package.json';

// COMPONENTS
import MenuItem from '../MenuItem';
import SettingsMenuItem from '../SettingsMenuItem';
import Menu2ndLevel from '../Menu2ndLevel';

// UTILS
import { deriveTopLevelSelectedItem } from '../../utils/menu.utils';

// STYLES
import './index.scss';
import { Divider, Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

// IMAGES
import Logo from '../../assets/images/TEAdmin_logo.png';

// TYPES
import { TMenuItem } from '../../types/menuItem.type';

// COMPONENTS
import { TEToolTip } from '../TEToolTip';

// CONSTANTS
const getSelectedMenuItem = (route: string): TMenuItem | null => {
  const menuItem = menuJSON.find((menuElement) => menuElement.route === route);
  return menuItem || null;
};

const Menu = () => {
  const match = useRouteMatch();
  const derivedSelectedItem: string = deriveTopLevelSelectedItem(match);
  const menuItem = getSelectedMenuItem(derivedSelectedItem);
  const [collapsedSideBarProp, setCollapsedSideBarProp] = useState(false);
  const [expandedSideBarProp, setExpandedSideBarProp] = useState(true);

  const classNames = ['collapse-expand--buttons-wrapper'];
  if (menuItem && menuItem.subItems?.length < 1) {
    classNames.push(' hidden');
  }

  const classNamesRight = ['sidebar-hide--button right'];
  if (expandedSideBarProp) {
    classNamesRight.push('hidden');
  }

  const classNamesLeft = ['sidebar-hide--button left'];
  if (collapsedSideBarProp) {
    classNamesLeft.push('hidden');
  }

  return (
    <>
      <div className='menu--wrapper'>
        <div>
          <img src={Logo} alt='TE Admin' width='36' />
        </div>
        <div className='item--wrapper'>
          {process.env.REACT_APP_TE_APP_ENV === 'production' ? (
            <>
              <MenuItem
                key={menuJSON[0].route}
                label={menuJSON[0].label}
                route={menuJSON[0].route}
                icon={menuJSON[0].icon}
                isSelected={menuJSON[0].route === derivedSelectedItem}
              />
              <MenuItem
                key={menuJSON[1].route}
                label={menuJSON[1].label}
                route={menuJSON[1].route}
                icon={menuJSON[1].icon}
                isSelected={menuJSON[1].route === derivedSelectedItem}
              />
            </>
          ) : (
            <>
              {menuJSON.map((menuItem: any) => (
                <MenuItem
                  key={menuItem.route}
                  label={menuItem.label}
                  route={menuItem.route}
                  icon={menuItem.icon}
                  isSelected={menuItem.route === derivedSelectedItem}
                />
              ))}
              <Divider orientation='center'></Divider>
              {nextMenuJSON.map((menuItem: any) => (
                <MenuItem
                  key={menuItem.route}
                  label={menuItem.label}
                  route={menuItem.route}
                  icon={menuItem.icon}
                  isSelected={menuItem.route === derivedSelectedItem}
                />
              ))}
            </>
          )}
        </div>
        <div className='bottom--wrapper'>
          <div className={classNames.join(' ')}>
            <TEToolTip helpText='Expand menu'>
              <Button
                className={classNamesRight.join(' ')}
                icon={<RightOutlined style={{ fontSize: '14px' }} />}
                onClick={() => {
                  setExpandedSideBarProp(true);
                  setCollapsedSideBarProp(false);
                }}
              />
            </TEToolTip>
            <TEToolTip helpText='Collapse menu'>
              <Button
                className={classNamesLeft.join(' ')}
                icon={<LeftOutlined style={{ fontSize: '14px' }} />}
                onClick={() => {
                  setCollapsedSideBarProp(true);
                  setExpandedSideBarProp(false);
                }}
              />
            </TEToolTip>
          </div>
          <SettingsMenuItem />
        </div>
        <div>
          <p className='version-number-text'>v.{pkg.version}</p>
        </div>
      </div>

      {menuItem && (
        <Menu2ndLevel
          title={menuItem.label}
          subItems={menuItem.subItems || []}
          noSideBarProp={
            menuItem.subItems.length < 1 || collapsedSideBarProp === true
          }
        />
      )}
    </>
  );
};

export default Menu;
