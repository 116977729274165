import { createSlice } from '@reduxjs/toolkit';

import api from '../services/api.service';

// TYPES
import {
  ReservationTemplate,
  TMoveReservationsRequest,
  TReservationTemplate,
} from '../types/reservation.type';
import { ActionSettings } from '../components/Advanced';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedReservationTemplate: ReservationTemplate.createEmpty(),
  allReservationTemplates: [],
  reservationTemplatesForOtherPages: [],
  limitations: {},
  errorMessage: {},
  advancedSettings: [],
  movedReservationsInfo: {},
  movedToTrashResponse: {},
};

// Slice
const slice = createSlice({
  name: 'reservationTemplates',
  initialState,
  reducers: {
    fetchReservationTemplatesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchReservationTemplatesSuccess: (state: any, { payload }) => {
      if (state.selectedReservationTemplate.id > 0) {
        state.selectedReservationTemplate =
          payload.find(
            (template: any) =>
              template.id === state.selectedReservationTemplate.id,
          ) || ReservationTemplate.createEmpty();
      }
      state.allReservationTemplates = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchReservationTemplatesForOthersSuccess: (state: any, { payload }) => {
      state.reservationTemplatesForOtherPages = payload.results;
    },
    fetchLimitationsReservationTemplatesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchAdvancedSettingsReservationTemplatesSuccess: (
      state: any,
      { payload },
    ) => {
      state.advancedSettings = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchReservationTemplatesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createReservationTemplateRequest: (state: any) => {
      state.errorMessage = '';
      isLoadingRequest(state);
    },
    createReservationTemplateSuccess: (state: any, { payload }) => {
      state.allReservationTemplates.push(payload);
      state.selectedReservationTemplate = payload;
      finishedLoadingSuccess(state);
    },
    createReservationTemplateFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
    setCurrentReservationTemplate: (state: any, { payload }) => {
      state.selectedReservationTemplate = payload;
      state.errorMessage = '';
      state.movedReservationsInfo = '';
      state.movedToTrashResponse = '';
    },
    deleteReservationTemplateRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteReservationTemplateSuccess: (state: any, payload: any) => {
      state.allReservationTemplates = state.allReservationTemplates.filter(
        (template: TReservationTemplate) => template.id !== payload.payload.id,
      );
      state.selectedReservationTemplate = ReservationTemplate.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteReservationTemplateFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateReservationTemplateRequest: (state: any) => {
      state.errorMessage = '';
      isLoadingRequest(state);
    },
    updateReservationTemplateSuccess: (state: any, { payload }) => {
      state.errorMessage = '';
      const newReservationTemplates = [...state.allReservationTemplates];
      const index = state.allReservationTemplates.findIndex(
        (template: TReservationTemplate) => template.id === payload.id,
      );
      newReservationTemplates[index] = payload;
      state.allReservationTemplates = newReservationTemplates;
      finishedLoadingSuccess(state);
    },
    moveReservationsSuccess: (state: any, { payload }) => {
      state.movedReservationsInfo = payload;
      finishedLoadingSuccess(state);
    },
    moveToTrashSuccess: (state: any, { payload }) => {
      state.movedToTrashResponse = payload;
      finishedLoadingSuccess(state);
    },
    updateReservationTemplateFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const reservationTemplatesLoading = (state: any) =>
  state.reservationTemplates.loading;
export const reservationTemplatesSelector = (state: any) =>
  state.reservationTemplates.allReservationTemplates as TReservationTemplate[];
export const reservationTemplatesForOtherPagesSelector = (state: any) =>
  state.reservationTemplates.reservationTemplatesForOtherPages;
export const reservationTemplateSelectorCreateNew = () => () => {
  return ReservationTemplate.createEmpty();
};
export const selectedReservationTemplateSelector = (state: any) => {
  return state.reservationTemplates.selectedReservationTemplate;
};
export const limitationsReservationTemplatesSelector = (state: any) =>
  state.reservationTemplates.limitations;
export const reservationTemplateErrorMessageSelector = (state: any) =>
  state.reservationTemplates.errorMessage;
export const advancedReservationTemplatesSelector = (state: any) =>
  state.reservationTemplates.advancedSettings;
export const movedReservationsInfoSelector = (state: any) =>
  state.reservationTemplates.movedReservationsInfo;
export const movedToTrashResponseSelector = (state: any) =>
  state.reservationTemplates.movedToTrashResponse;

// Actions
export const {
  fetchReservationTemplatesRequest,
  fetchReservationTemplatesSuccess,
  fetchReservationTemplatesForOthersSuccess,
  fetchLimitationsReservationTemplatesSuccess,
  fetchAdvancedSettingsReservationTemplatesSuccess,
  fetchReservationTemplatesFailure,
  createReservationTemplateRequest,
  createReservationTemplateSuccess,
  createReservationTemplateFailure,
  setCurrentReservationTemplate,
  deleteReservationTemplateRequest,
  deleteReservationTemplateSuccess,
  deleteReservationTemplateFailure,
  updateReservationTemplateRequest,
  updateReservationTemplateSuccess,
  updateReservationTemplateFailure,
  moveReservationsSuccess,
  moveToTrashSuccess,
} = slice.actions;

export const fetchReservationTemplates =
  (
    templateTypeOption?: string,
    templateTypeQuery?: string,
    reservationModeOption?: string,
    reservationModeQuery?: string,
    activeOption?: string,
    activeQuery?: string,
    searchTextOption?: string,
    searchTextQuery?: string | number,
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(fetchReservationTemplatesRequest());
      let url = `/reservation-templates`;
      let encodedSortQuery: string | undefined;
      if (searchTextQuery) {
        encodedSortQuery = encodeURIComponent(searchTextQuery);
      }
      if (
        templateTypeQuery !== undefined &&
        encodedSortQuery !== undefined &&
        reservationModeQuery === undefined &&
        activeQuery === undefined
      ) {
        if (encodedSortQuery === undefined) {
          url = `/reservation-templates${templateTypeOption}=${templateTypeQuery}`;
        } else {
          url = `/reservation-templates?${templateTypeOption}=${templateTypeQuery}&${searchTextOption}=${encodedSortQuery}`;
        }
      } else if (
        templateTypeQuery !== undefined &&
        reservationModeQuery === undefined &&
        activeQuery === undefined &&
        encodedSortQuery === undefined
      ) {
        url = `/reservation-templates?${templateTypeOption}=${templateTypeQuery}`;
      } else if (
        templateTypeQuery === undefined &&
        reservationModeQuery === undefined &&
        activeQuery === undefined &&
        encodedSortQuery !== undefined
      ) {
        url = `/reservation-templates?${searchTextOption}=${encodedSortQuery}`;
      } else if (
        activeQuery !== undefined &&
        templateTypeQuery === undefined &&
        reservationModeQuery === undefined &&
        encodedSortQuery === undefined
      ) {
        url = `/reservation-templates?${activeOption}=${activeQuery}`;
      } else if (
        reservationModeQuery !== undefined &&
        activeQuery === undefined &&
        templateTypeQuery === undefined &&
        encodedSortQuery === undefined
      ) {
        url = `/reservation-templates?${reservationModeOption}=${reservationModeQuery}`;
      } else if (
        reservationModeQuery !== undefined &&
        activeQuery !== undefined &&
        templateTypeQuery === undefined &&
        encodedSortQuery === undefined
      ) {
        url = `/reservation-templates?${reservationModeOption}=${reservationModeQuery}&${activeOption}=${activeQuery}`;
      } else if (
        reservationModeQuery !== undefined &&
        activeQuery !== undefined &&
        templateTypeQuery !== undefined &&
        encodedSortQuery === undefined
      ) {
        url = `/reservation-templates?${reservationModeOption}=${reservationModeQuery}&${activeOption}=${activeQuery}&${templateTypeOption}=${templateTypeQuery}`;
      } else if (
        reservationModeQuery === undefined &&
        activeQuery !== undefined &&
        templateTypeQuery !== undefined &&
        encodedSortQuery !== undefined
      ) {
        url = `/reservation-templates?${activeOption}=${activeQuery}&${templateTypeOption}=${templateTypeQuery}&${searchTextOption}=${encodedSortQuery}`;
      } else if (
        reservationModeQuery === undefined &&
        activeQuery !== undefined &&
        templateTypeQuery !== undefined &&
        encodedSortQuery === undefined
      ) {
        url = `/reservation-templates?${activeOption}=${activeQuery}&${templateTypeOption}=${templateTypeQuery}`;
      } else if (
        reservationModeQuery === undefined &&
        activeQuery !== undefined &&
        templateTypeQuery === undefined &&
        encodedSortQuery !== undefined
      ) {
        url = `/reservation-templates?${activeOption}=${activeOption}&${searchTextOption}=${encodedSortQuery}`;
      } else if (
        reservationModeQuery !== undefined &&
        activeQuery === undefined &&
        templateTypeQuery !== undefined &&
        encodedSortQuery === undefined
      ) {
        url = `/reservation-templates?${reservationModeOption}=${reservationModeQuery}&${templateTypeOption}=${templateTypeQuery}`;
      } else if (
        reservationModeQuery !== undefined &&
        activeQuery === undefined &&
        templateTypeQuery === undefined &&
        encodedSortQuery !== undefined
      ) {
        url = `/reservation-templates?${reservationModeOption}=${reservationModeQuery}&${searchTextOption}=${encodedSortQuery}`;
      }
      const reservationTemplates = await api.get({
        endpoint: url,
      });
      dispatch(fetchReservationTemplatesSuccess(reservationTemplates));
    } catch (e) {
      dispatch(fetchReservationTemplatesFailure());
      return console.error(e);
    }
  };

export const fetchReservationTemplatesForOtherPages =
  () => async (dispatch: any) => {
    try {
      dispatch(fetchReservationTemplatesRequest());
      const reservationTemplates = await api.get({
        endpoint: `/reservation-templates`,
      });
      dispatch(fetchReservationTemplatesForOthersSuccess(reservationTemplates));
    } catch (e) {
      dispatch(fetchReservationTemplatesFailure());
      return console.error(e);
    }
  };

export const fetchLimitationsForReservationTemplates =
  () => async (dispatch: any) => {
    try {
      dispatch(fetchReservationTemplatesRequest());
      const limitationsReservationTemplates = await api.get({
        endpoint: `/reservation-templates/limitations`,
      });
      dispatch(
        fetchLimitationsReservationTemplatesSuccess(
          limitationsReservationTemplates,
        ),
      );
    } catch (e) {
      dispatch(fetchReservationTemplatesFailure());
      return console.error(e);
    }
  };

export const fetchAdvancedSettingsForReservationTemplate =
  (reservationTemplate: TReservationTemplate) => async (dispatch: any) => {
    try {
      dispatch(fetchReservationTemplatesRequest());
      const advancedSettings: ActionSettings[] = await api.get({
        endpoint: `/reservation-templates/${reservationTemplate.id}/advanced`,
      });
      dispatch(
        fetchAdvancedSettingsReservationTemplatesSuccess(advancedSettings),
      );
    } catch (e) {
      dispatch(fetchReservationTemplatesFailure());
      return console.error(e);
    }
  };

export const createReservationTemplate =
  (reservationTemplate: TReservationTemplate) => async (dispatch: any) => {
    try {
      dispatch(createReservationTemplateRequest());
      const { id: _, ...reservationTemplateBody } = reservationTemplate;
      const response = await api.post({
        endpoint: `/reservation-templates`,
        data: reservationTemplateBody,
      });
      dispatch(createReservationTemplateSuccess(response));
      dispatch(fetchReservationTemplates());
    } catch (e: any) {
      dispatch(createReservationTemplateFailure(e.response.data.message));
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const createEmptyReservationTemplate = () => async (dispatch: any) => {
  try {
    dispatch(createReservationTemplateRequest());
    const response = ReservationTemplate.createEmpty();
    dispatch(createReservationTemplateSuccess(response));
  } catch (e: any) {
    dispatch(createReservationTemplateFailure(e.response.data.message));
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedReservationTemplate =
  (reservationTemplate: TReservationTemplate) => async (dispatch: any) => {
    dispatch(setCurrentReservationTemplate(reservationTemplate));
  };

export const copyReservationTemplate =
  (reservationTemplate: TReservationTemplate) => async (dispatch: any) => {
    try {
      dispatch(createReservationTemplateRequest());
      const response = {
        ...reservationTemplate,
        id: 0,
      };
      dispatch(createReservationTemplateSuccess(response));
    } catch (e: any) {
      dispatch(createReservationTemplateFailure(e.response.data.message));
      return console.error(e);
    }
  };

export const deleteReservationTemplate =
  (reservationTemplate: TReservationTemplate) => async (dispatch: any) => {
    try {
      dispatch(deleteReservationTemplateRequest());
      await api.delete({
        endpoint: `/reservation-templates/${reservationTemplate.id}`,
      });
      dispatch(deleteReservationTemplateSuccess(reservationTemplate));
    } catch (e: any) {
      dispatch(deleteReservationTemplateFailure());
      return console.error(e);
    }
  };

export const updateReservationTemplate =
  (reservationTemplate: TReservationTemplate) => async (dispatch: any) => {
    try {
      dispatch(updateReservationTemplateRequest());
      const { ...ReservationTemplateBody } = reservationTemplate;
      const response = await api.patch({
        endpoint: `/reservation-templates/${reservationTemplate.id}`,
        data: { ...ReservationTemplateBody },
      });
      dispatch(updateReservationTemplateSuccess(response));
    } catch (e: any) {
      dispatch(updateReservationTemplateFailure(e.response.data.message));
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const moveReservations =
  (
    moveReservationsRequest: TMoveReservationsRequest,
    reservationTemplate: TReservationTemplate,
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(updateReservationTemplateRequest());
      const { ...MoveReservationsRequestBody } = moveReservationsRequest;
      const response = await api.patch({
        endpoint: `/reservation-templates/${reservationTemplate.id}/move-reservations`,
        data: { ...MoveReservationsRequestBody },
      });
      dispatch(moveReservationsSuccess(response));
    } catch (e: any) {
      dispatch(updateReservationTemplateFailure(e.response.data.message));
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const moveReservationsToTrash =
  (reservationTemplate: TReservationTemplate) => async (dispatch: any) => {
    try {
      dispatch(updateReservationTemplateRequest());
      const response = await api.patch({
        endpoint: `/reservation-templates/${reservationTemplate.id}/move-to-trash`,
      });
      dispatch(moveToTrashSuccess(response));
    } catch (e: any) {
      dispatch(updateReservationTemplateFailure(e.response.data.message));
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
