import {
  IDefaultPaginatedResult,
  IDefaultPaginatedState,
  IDefaultReducerState,
} from '../types/state.interface';

export const finishedLoadingSuccess = (state: IDefaultReducerState): void => {
  state.loading = false;
  state.hasErrors = false;
};

export const finishedLoadingFailure = (state: IDefaultReducerState): void => {
  state.loading = false;
  state.hasErrors = true;
};

export const isLoadingRequest = (state: IDefaultReducerState): void => {
  state.loading = true;
  state.hasErrors = false;
};

export const stateHasFetchedAllObjectsSuccess = (
  payload: IDefaultPaginatedResult,
  state: IDefaultPaginatedState,
  createFn: Function,
): void => {
  const { results, page, limit, totalPages }: IDefaultPaginatedResult = payload;
  const iteratedResults = results.map((el: any) => createFn(el));

  const map = iteratedResults.reduce(
    (tot, acc) => ({
      ...tot,
      [acc.id]: acc,
    }),
    {},
  );
  state.results = iteratedResults;
  state.map = map;
  state.page = page;
  state.limit = limit;
  state.totalPages = totalPages;
};

export const deleteEntityFromState = (
  id: string,
  state: IDefaultPaginatedState,
  idKey = 'id',
): void => {
  const { [id]: _, ...updState } = state.map;
  state.map = updState;
  const idx = state.results.findIndex((el) => el && el[idKey] === id);
  if (idx > -1)
    state.results = [
      ...state.results.slice(0, idx),
      ...state.results.slice(idx + 1),
    ];
};

export const addEntityToState = (
  state: IDefaultPaginatedState,
  payload: any,
  createFn: Function,
  idKey = 'id',
): void => {
  const item = createFn(payload);
  const map = { ...state.map, [item[idKey]]: item };
  const results = [...state.results, item];
  state.map = map;
  state.results = results;
};

export const updateOrCreateEntities = (
  state: IDefaultPaginatedState,
  payload: any,
  createFn: Function,
  idKey = 'id',
): void => {
  // Create the objects
  const iteratedResults = payload.map((el: any) => createFn(el));

  const map = iteratedResults.reduce(
    (tot: any, acc: any) => ({
      ...tot,
      [acc.id]: acc,
    }),
    state.map,
  );
  const results = iteratedResults.reduce((tot: any, acc: any) => {
    const idx = tot.findIndex((el: any) => el[idKey] === acc[idKey]);
    if (idx > -1) return [...tot.slice(0, idx), acc, ...tot.slice(idx + 1)];
    return [...tot, acc];
  }, state.results);
  state.map = map;
  state.results = results;
};

export const updateOrCreateEntity = (
  state: IDefaultPaginatedState,
  payload: any,
  createFn: Function,
  idKey = 'id',
): void => {
  // Create the objects
  const result = createFn(payload);

  state.map = {
    ...state.map,
    [result[idKey]]: result,
  };
  const idx = state.results.findIndex((el: any) => el[idKey] === result[idKey]);
  if (idx > -1) {
    state.results = [
      ...state.results.slice(0, idx),
      result,
      ...state.results.slice(idx + 1),
    ];
  } else {
    state.results = [...state.results, result];
  }
};
