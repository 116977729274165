import { ChangeEvent, useState } from 'react';

// i18N
import intl from '../../i18n/intl';

// STYLES
import {
  Collapse,
  Form,
  Input,
  Transfer,
  List,
  Button,
  Popconfirm,
} from 'antd';
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons';

// TYPES
import {
  TOrganizationNode,
  TOrganizationTreeNode,
} from '../../types/organization.type';
import { TTEObject } from '../../types/object.type';

type Props = {
  organizationNode: TOrganizationNode;
  onChange: any;
  limitations?: any;
  orgTreeNode: TOrganizationTreeNode;
  relatedObjects: TTEObject[];
  onDelete: any;
  onCopy: any;
  treeListData: any;
  onHandleChangeOfParentNodes: any;
};

const language: any = intl.messages;

/* ------------------------------------------------------------------------------------------ */

export const OrganizationsGeneralSettings = ({
  organizationNode,
  onChange,
  limitations,
  orgTreeNode,
  relatedObjects,
  onDelete = () => {},
  onCopy = () => {},
  treeListData,
  onHandleChangeOfParentNodes,
}: Props) => {
  // EVENT HANDLERS
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  const transferDataSource: any = [];
  const flatten = (list = []) => {
    list.forEach((item: any) => {
      transferDataSource.push(item);
      flatten(item.children);
    });
  };
  flatten(treeListData);

  // The organizations to choose from; allOrgTreeNodes minus parentNodeIds
  const transferDataSourceWithoutParents = transferDataSource.filter(
    (data: any) => !organizationNode.parentNodeIds.includes(data.key),
  );

  // Already setted subOrganizations
  const targetKeysOrganizations: any = orgTreeNode?.children?.map(
    (child: any) => child.id,
  );

  // Suborganizations selected for saving to orgTreeNode
  const [selectedKeysOrganizations, setSelectedKeysOrganizations] = useState<
    any[]
  >([]);
  const onSelection = (
    sourceSelectedKeysOrganizations: any,
    targetSelectedKeysOrganizations: any,
  ) => {
    setSelectedKeysOrganizations([
      ...sourceSelectedKeysOrganizations,
      ...targetSelectedKeysOrganizations,
    ]);
  };

  // Function for updating new tree structure
  const onSelectionChangeOrganizations = (nextTargetKeys: any) => {
    onHandleChangeOfParentNodes(
      [organizationNode.organizationId, ...organizationNode.parentNodeIds],
      nextTargetKeys.filter(
        (key: any) =>
          orgTreeNode.children.map((child: any) => child.id).indexOf(key) ===
          -1,
      ),
    );
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Button
          type='default'
          style={{ marginBottom: '16px' }}
          icon={<CopyOutlined />}
          onClick={() => onCopy()}
        >
          Copy organization
        </Button>
        <Popconfirm
          title={language.sure_to_delete_org}
          onConfirm={() => onDelete(organizationNode)}
          onCancel={() => console.log('should do nothing, no delete')}
          okText={language.yes}
          cancelText={language.no}
        >
          <Button
            type='primary'
            danger
            style={{ marginBottom: '16px', marginLeft: '8px' }}
            icon={<DeleteOutlined />}
          >
            {language.delete_organization}
          </Button>
        </Popconfirm>
        <Form.Item label={`${language.name}`}>
          <Input
            size='small'
            defaultValue={organizationNode?.name}
            value={organizationNode?.name}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'name', limitations.name)
            }
          />
        </Form.Item>
        <p className='small-heading-text'>
          {language.id}: {organizationNode?.organizationId}
        </p>
        <Form.Item label={`${language.description}`}>
          <Input.TextArea
            size='small'
            rows={5}
            defaultValue={organizationNode?.description}
            value={organizationNode?.description}
            onChange={(value: any) =>
              onChangeInputHandler(
                value,
                'description',
                limitations.description,
              )
            }
          />
        </Form.Item>
        <Form.Item label={language.sub_organizations}>
          <Transfer
            targetKeys={targetKeysOrganizations}
            dataSource={transferDataSourceWithoutParents}
            className='tree-transfer'
            render={(item: any) => item.title}
            showSelectAll={false}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            selectedKeys={selectedKeysOrganizations}
            onChange={onSelectionChangeOrganizations}
            onSelectChange={onSelection}
          />
        </Form.Item>
        <p className='small-heading-text' style={{ marginBottom: 0 }}>
          {language.related_objects}
        </p>
        <List
          size='small'
          bordered
          dataSource={relatedObjects}
          renderItem={(item: any) => (
            <List.Item key={item.id}>{item.extId}</List.Item>
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
