import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TMaintenance } from '../types/maintenance.type';

// SLICES
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  maintenance: [],
  maintenanceResponse: {},
  deleteAllResponse: {},
  closeUnusedSessionsResponse: {},
};

// Slice
const slice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    fetchMaintenanceRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchMaintenanceSuccess: (state: any, { payload }) => {
      state.maintenance = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchMaintenanceFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateMaintenanceRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateMaintenanceSuccess: (state: any, { payload }) => {
      state.maintenanceResponse = payload;
      finishedLoadingSuccess(state);
    },
    updateMaintenanceFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    deleteRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteAllSuccess: (state: any, { payload }) => {
      state.deleteAllResponse = payload;
      finishedLoadingSuccess(state);
    },
    closeUnusedSessionsSuccess: (state: any, { payload }) => {
      state.closeUnusedSessionsResponse = payload;
      finishedLoadingSuccess(state);
    },
    deleteFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const maintenanceLoading = (state: any) => state.maintenance.loading;
export const maintenanceSelector = (state: any) =>
  state.maintenance.maintenance as TMaintenance;
export const maintenanceResponseSelector = (state: any) =>
  state.maintenance.maintenanceResponse;
export const deleteAllSelector = (state: any) =>
  state.maintenance.deleteAllResponse;
export const closeUnusedSessionsSelector = (state: any) =>
  state.maintenance.closeUnusedSessionsResponse;

// Actions
export const {
  fetchMaintenanceRequest,
  fetchMaintenanceSuccess,
  fetchMaintenanceFailure,
  deleteAllSuccess,
  closeUnusedSessionsSuccess,
  deleteRequest,
  deleteFailure,
  updateMaintenanceRequest,
  updateMaintenanceSuccess,
  updateMaintenanceFailure,
} = slice.actions;

export const fetchMaintenance = () => async (dispatch: any) => {
  try {
    dispatch(fetchMaintenanceRequest());
    const maintenance = await api.get({
      endpoint: `/system-maintenance`,
    });
    dispatch(fetchMaintenanceSuccess(maintenance));
  } catch (e) {
    dispatch(fetchMaintenanceFailure());
    return console.error(e);
  }
};

export const updateMaintenance =
  (maintenanceItemId: any) => async (dispatch: any) => {
    try {
      dispatch(updateMaintenanceRequest());
      const response = await api.patch({
        endpoint: `/system-maintenance/${maintenanceItemId}`,
      });
      dispatch(updateMaintenanceSuccess(response));
    } catch (e) {
      dispatch(updateMaintenanceFailure());
      return console.error(e);
    }
  };

export const deleteAll = (request: any) => async (dispatch: any) => {
  try {
    dispatch(deleteRequest());
    await api.delete({
      endpoint: `/system-maintenance/delete-all`,
    });
    dispatch(deleteAllSuccess(request));
  } catch (e) {
    dispatch(deleteFailure());
    return console.error(e);
  }
};

export const closeUnusedSessionsAction =
  (shortInterval: any) => async (dispatch: any) => {
    try {
      dispatch(deleteRequest());
      const response = await api.delete({
        endpoint: `/system-maintenance/close-unused-sessions?shortInterval=${shortInterval}`,
      });
      dispatch(closeUnusedSessionsSuccess(response));
    } catch (e: any) {
      dispatch(deleteFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
