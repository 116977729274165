import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import {
  TOrganizationNode,
  TOrganizationTreeNode,
  OrganizationNode,
} from '../types/organization.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  allOrganizations: [],
  limitations: {},
};

// Slice
const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    fetchOrganizationsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchOrganizationsSuccess: (state: any, { payload }) => {
      state.allOrganizations = payload;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsOrganizationsSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchOrganizationsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const organizationsLoading = (state: any) => state.organizations.loading;
export const organizationsSelector = (state: any) =>
  state.organizations.allOrganizations;
export const fetchLimitationsOrganizationsSelector = (state: any) =>
  state.organizations.limitations;

// Actions
export const {
  fetchOrganizationsRequest,
  fetchOrganizationsSuccess,
  fetchLimitationsOrganizationsSuccess,
  fetchOrganizationsFailure,
} = slice.actions;

export const fetchOrganizations = () => async (dispatch: any) => {
  try {
    dispatch(fetchOrganizationsRequest());
    const organizations = await api.get({ endpoint: `/organizations` });
    dispatch(fetchOrganizationsSuccess(organizations));
  } catch (e) {
    dispatch(fetchOrganizationsFailure());
    return console.error(e);
  }
};

export const fetchLimitationsOrganizations = () => async (dispatch: any) => {
  try {
    dispatch(fetchOrganizationsRequest());
    const limitationsOrganizations = await api.get({
      endpoint: `/organizations/limitations`,
    });
    dispatch(fetchLimitationsOrganizationsSuccess(limitationsOrganizations));
  } catch (e) {
    dispatch(fetchOrganizationsFailure());
    return console.error(e);
  }
};
