import axios from 'axios';
import TokenService from './token.service';
import { configService } from './config.service';
import { TOption, TAPIRequest, TAPIRequestShorthand } from '../types/api.type';

const tokenService = TokenService.getService();

/**
 * @function prepareOption
 * @description wrapper function to axios option object
 * @param {String} method request HTTP method
 * @param {Any} data data to pass in request
 * @param {Bool} requiresAuth whehter bearer token is required
 * @param {Obj} headers additional headers to be sent
 * @param {Obj} params explicitely set params
 * @returns {String}
 */
const prepareOption = (
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  data: any,
  requiresAuth: Boolean,
  headers: any,
  params: any,
): TOption => {
  const option: TOption = {
    method,
    headers: {
      'Access-Control-Allow-Origin': '*',
      ...headers,
    },
    body: {},
  };

  if (requiresAuth) {
    const token = tokenService.getToken();
    option.headers = {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
      ...headers,
    };
  }

  if (data) {
    if (method === 'GET') {
      option.params = { ...data, ...(params || {}) };
    } else {
      if (Array.isArray(data)) {
        option.data = [...data];
      } else {
        option.data = { ...data };
      }
      if (params) option.params = { ...params };
    }
  }
  return option;
};

/**
 * @function getAPIUrl
 * @description returns URL for TE Preferences API depending on environment
 * @param {String} endpoint route to be called
 * @returns {String}
 */
const getAPIUrl = (endpoint: string) => {
  if (endpoint.search('http://') > -1 || endpoint.search('https://') > -1)
    return endpoint;
  return `${configService.REACT_APP_BASE_URL}${endpoint}`;
};

const apiRequest = async ({
  method,
  endpoint,
  absoluteUrl = false,
  data,
  params = null,
  requiresAuth = true,
  headers,
}: TAPIRequest) => {
  const fullUrl = !absoluteUrl ? getAPIUrl(endpoint) : endpoint;
  const option = prepareOption(method, data, requiresAuth, headers, params);
  return axios(fullUrl, option).then((response: any) => {
    return response.data;
  });
};

const exports = {
  get: async ({
    endpoint,
    absoluteUrl = false,
    data,
    requiresAuth = true,
    headers,
  }: TAPIRequestShorthand) =>
    apiRequest({
      method: 'GET',
      endpoint,
      absoluteUrl,
      data,
      requiresAuth,
      headers,
    }),
  post: async ({
    endpoint,
    absoluteUrl = false,
    data,
    requiresAuth = true,
    headers,
    params,
  }: TAPIRequestShorthand) =>
    apiRequest({
      method: 'POST',
      endpoint,
      absoluteUrl,
      data,
      requiresAuth,
      headers,
      params,
    }),
  put: async ({
    endpoint,
    absoluteUrl = false,
    data,
    requiresAuth = true,
    headers,
    params,
  }: TAPIRequestShorthand) =>
    apiRequest({
      method: 'PUT',
      endpoint,
      absoluteUrl,
      data,
      requiresAuth,
      headers,
      params,
    }),
  patch: async ({
    endpoint,
    absoluteUrl = false,
    data,
    requiresAuth = true,
    headers,
    params,
  }: TAPIRequestShorthand) =>
    apiRequest({
      method: 'PATCH',
      endpoint,
      absoluteUrl,
      data,
      requiresAuth,
      headers,
      params,
    }),
  delete: async ({
    endpoint,
    absoluteUrl = false,
    data,
    requiresAuth = true,
    headers,
  }: TAPIRequestShorthand) =>
    apiRequest({
      method: 'DELETE',
      endpoint,
      absoluteUrl,
      data,
      requiresAuth,
      headers,
    }),
};

export default exports;
