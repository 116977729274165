import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../slices/ui.slice';
import { Redirect } from 'react-router-dom';

// i18N
import intl from '../../i18n/intl';

const language: any = intl.messages;

const ObjectPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: '/object-settings', label: `${language.object_settings}` },
      ]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Redirect to='/object-settings/object-manager' />;
};

export default ObjectPage;
