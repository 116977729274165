// Old version - compatible with current rest-api connected to the server
export type TOrganization = {
  name: string;
  id: number;
  description: string;
  subOrganizations?: Array<number>;
};

export class Organization {
  static createEmpty = (): TOrganization => ({
    name: '',
    id: 0,
    description: '',
    subOrganizations: [],
  });
}

/**
 * Typing of Organization Tree Node and Organization Node
 *  that we will get from the new
 *  organization-service
 **/

export type TOrganizationTreeNode = {
  id: string;
  name: string;
  children: TOrganizationTreeNode[];
};

export type TOrganizationNode = {
  organizationId: string;
  name: string;
  description?: string;
  parentNodeIds: string[];
};

/**
 * Class for creating empty
 **/

export class OrganizationNode {
  static createEmpty = (parentNodeIds?: string[]): TOrganizationNode => ({
    organizationId: 'new',
    name: '',
    description: '',
    parentNodeIds: parentNodeIds !== undefined ? parentNodeIds : [],
  });
}
