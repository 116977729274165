import { useDispatch } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';

// REDUX
import {
  deleteAuthenticationToken,
  setSelectedAuthenticationTokens,
} from '../../slices/authenticationToken.slice';

// i18N
import intl from '../../i18n/intl';

// STYLES
import {
  Space,
  Input,
  Checkbox,
  Popconfirm,
  Button,
  Table,
  Collapse,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

// TYPES
import {
  AuthenticationToken,
  TAuthenticationToken,
} from '../../types/authenticationToken.type';

const language: any = intl.messages;

type Props = {
  authenticationTokens: TAuthenticationToken[];
  onChange: any;
};

/* ---------------------------------------------------------------------- */

export const AuthenticationTokensComponent = ({
  authenticationTokens,
  onChange,
}: Props) => {
  const dispatch = useDispatch();

  const [isError, setIsError] = useState('');

  // EVENT HANDLERS
  const onChangeHandler = (
    authenticationToken: TAuthenticationToken,
    value: any,
    property: string,
  ) => {
    onChange(value, property, authenticationToken);
  };

  const onCheckValidation = (password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      setIsError(`${language.repeated_password_should_match_with_password}!`);
    } else {
      setIsError('');
    }
  };

  const onDeleteToken = (authenticationToken: TAuthenticationToken) => {
    dispatch(deleteAuthenticationToken(authenticationToken));
  };

  const onAdd = () => {
    const newAuthenticationTokens: TAuthenticationToken[] = [
      ...authenticationTokens,
    ];
    newAuthenticationTokens.push(AuthenticationToken.create());
    dispatch(setSelectedAuthenticationTokens(newAuthenticationTokens));
  };

  const columns = [
    {
      title: <span className='small-heading-text'>{language.action}</span>,
      key: 'action',
      width: '80px',
      className: 'center-aligned',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={language.sure_to_delete}
            onConfirm={() => onDeleteToken(record.authenticationToken)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: <span className='small-heading-text'>{language.active}</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      width: '80px',
      className: 'center-aligned',
      render: (isActive: boolean, record: any) => (
        <Checkbox
          checked={isActive}
          onChange={(event: any) =>
            onChangeHandler(
              record.authenticationToken,
              event.target.checked,
              'isActive',
            )
          }
        />
      ),
    },
    {
      title: <span className='small-heading-text'>{language.client_id}</span>,
      dataIndex: 'clientId',
      key: 'clientId',
      width: '180px',
      render: (clientId: string, record: any) => (
        <Input
          size='small'
          value={clientId}
          onChange={(event: any) =>
            onChangeHandler(
              record.authenticationToken,
              event.target.value,
              'clientId',
            )
          }
        />
      ),
    },
    {
      title: <span className='small-heading-text'>{language.password}</span>,
      dataIndex: 'password',
      key: 'password',
      width: '180px',
      render: (password: string, record: any) => (
        <Input
          size='small'
          type='password'
          value={password}
          onChange={(event: any) => {
            onChangeHandler(
              record.authenticationToken,
              event.target.value,
              'password',
            );
            onCheckValidation(event.target.value, record.confirmPassword);
          }}
          minLength={6}
        />
      ),
    },
    {
      title: (
        <span className='small-heading-text'>{language.repeat_password}</span>
      ),
      dataIndex: 'confirmPassword',
      key: 'confirmPassword',
      width: '180px',
      render: (confirmPassword: string, record: any) => (
        <Input
          size='small'
          type='password'
          value={confirmPassword}
          onChange={(event: any) => {
            onChangeHandler(
              record.authenticationToken,
              event.target.value,
              'confirmPassword',
            );
            onCheckValidation(record.password, event.target.value);
          }}
        />
      ),
    },
    {
      title: <span className='small-heading-text'>{language.use_token}</span>,
      dataIndex: 'useToken',
      key: 'useToken',
      width: '100px',
      className: 'center-aligned',
      render: (useToken: boolean, record: any) => (
        <Checkbox
          checked={useToken}
          onChange={(event: any) =>
            onChangeHandler(
              record.authenticationToken,
              event.target.checked,
              'useToken',
            )
          }
        />
      ),
    },
    {
      title: <span className='small-heading-text'>{language.last_used}</span>,
      dataIndex: 'lastUsed',
      key: 'lastUsed',
      width: '100px',
      render: (lastUsed: any) => (
        <span>
          {lastUsed !== null
            ? moment.unix(lastUsed).utc().format('YYYY-MM-DD')
            : '-'}
        </span>
      ),
    },
  ];

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header='Authentication tokens' key='3'>
        <Button
          size='small'
          icon={<PlusOutlined />}
          style={{ marginLeft: 20 }}
          onClick={onAdd}
        >
          {language.add}
        </Button>
        <div
          style={{
            height: '20px',
            marginLeft: 20,
            marginTop: 20,
            marginBottom: 0,
          }}
        >
          <p
            style={{
              color: '#ff6357',
            }}
          >
            {isError}
          </p>
        </div>
        <Table
          size='small'
          style={{ padding: '20px' }}
          columns={columns}
          dataSource={authenticationTokens?.map(
            (authenticationToken: TAuthenticationToken) => ({
              key: authenticationToken.id,
              authenticationToken,
              ...authenticationToken,
            }),
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
