export type TApi = {
  isActive: boolean;
  id: number;
  name: string;
  signature: string;
  validFrom: number | null;
  expires: number | null;
  certificateName: string;
  certificateRegistered: number;
  certificateExpires: number;
  certificateIssuer: string;
  propertyGroups: Array<TAPIPropertyGroup>;
  description: string;
};

export type TAPIPropertyGroup = {
  id: number;
  signature: string;
  name: string;
  properties: Array<TAPIProperties>;
};

export type TAPIProperties = {
  id: number;
  signature: string;
  name: string;
  isActive: boolean;
  description: string;
  organizations: Array<number>;
  properties: string;
};
export class Api {
  static createEmpty = (): TApi => ({
    isActive: true,
    id: 0,
    name: '',
    signature: '',
    validFrom: null,
    expires: null,
    certificateName: '',
    certificateRegistered: Date.now(),
    certificateExpires: 0,
    certificateIssuer: '-',
    propertyGroups: [],
    description: '',
  });
}

export class NewApi {
  static create = (): TApi => ({
    isActive: true,
    id: 0,
    name: '',
    signature: '',
    validFrom: null,
    expires: null,
    certificateName: '',
    certificateRegistered: Date.now(),
    certificateExpires: 0,
    certificateIssuer: '-',
    propertyGroups: [],
    description: '',
  });
}
export class APIPropertyGroup {
  static createEmpty = (): TAPIPropertyGroup => ({
    id: 0,
    signature: '',
    name: '',
    properties: [],
  });
}
export class APIProperty {
  static createEmpty = (): TAPIProperties => ({
    id: 0,
    signature: '',
    name: '',
    isActive: true,
    description: '',
    organizations: [],
    properties: '',
  });
}
