// i18N
import intl from '../../i18n/intl';

// STYLES
import './index.scss';
import { Button, Modal } from 'antd';

// TYPES
import { THistory } from '../../types/dateHistory.type';

// COMPONENTS
import ActionButton from '../Buttons';
import { timeConverter } from '../../utils/timeConverter';

type Props = {
  listChoice?: string;
  listChoiceHeading?: string;
  modifiedDate?: number | null | string;
  modifiedBy?: string | undefined;
  changeHandler?: any;
  discardHandler?: any;
  displayProp: boolean;
  history: Array<THistory> | boolean | undefined;
  error?: any;
  isDisabled?: any;
};

const language: any = intl.messages;

const SectionHeader = ({
  listChoice,
  listChoiceHeading,
  modifiedDate,
  modifiedBy,
  changeHandler = () => {},
  discardHandler = () => {},
  displayProp,
  history,
  error,
  isDisabled,
}: Props) => {
  // Modal showing history of modified
  const HistoryModal = () => {
    Modal.info({
      title: `${language.modification_history}`,
      content: (
        <div style={{ maxHeight: 350, overflowY: 'scroll' }}>
          {typeof history === 'object' &&
            history.map((h: any) => (
              <p key={Math.random()}>
                <span style={{ fontWeight: 'bold' }}>
                  {timeConverter(h.modified)}
                </span>
                : {h.modifiedBy}
              </p>
            ))}
        </div>
      ),
      onOk() {},
    });
  };

  const classNames = ['section-header'];
  if (listChoice !== undefined) {
    classNames.push('not-visible');
  }

  return (
    <div className='section-header-wrapper'>
      <div className='section-header-main'>
        <h1 className={classNames.join(' ')}>{listChoiceHeading}</h1>
        <h4 style={{ marginBottom: 0 }}>{listChoice}</h4>
        <div
          className='header-right-bar--wrapper'
          style={{ alignItems: 'center' }}
        >
          {modifiedDate && (
            <p className='modified-by-text'>
              {typeof history === 'object' ? (
                <Button type='text' onClick={HistoryModal}>
                  <span>{language.last_modified}:</span>
                </Button>
              ) : (
                <span>{language.last_modified}: </span>
              )}
              {modifiedDate} / {modifiedBy}
            </p>
          )}
          <div className='section-header-button-bar'>
            <ActionButton
              title={language.save_changes}
              action={() => changeHandler}
              isDisabled={isDisabled === true}
              isDanger={false}
              displayProp={displayProp}
              isSaveChangesBtn
            />
            <ActionButton
              title={language.discard_changes}
              action={() => discardHandler}
              isDisabled={false}
              isDanger
              displayProp={displayProp}
            />
          </div>
        </div>
      </div>
      <p
        style={{
          color: '#ff6357',
          background: 'white',
          margin: 0,
        }}
      >
        {error}
      </p>
    </div>
  );
};

export default SectionHeader;
