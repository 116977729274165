import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// REDUX
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  allLimitations: [],
};

// Slice
const slice = createSlice({
  name: 'limitations',
  initialState,
  reducers: {
    fetchLimitationsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchLimitationsSuccess: (state: any, { payload }) => {
      state.allLimitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const limitationsLoading = (state: any) => state.limitations.loading;
export const limitationsSelector = (state: any) =>
  state.limitations.allLimitations;

// Actions
export const {
  fetchLimitationsRequest,
  fetchLimitationsSuccess,
  fetchLimitationsFailure,
} = slice.actions;

export const fetchLimitations = () => async (dispatch: any) => {
  try {
    dispatch(fetchLimitationsRequest());
    const limitations = await api.get({ endpoint: `/limitations` });
    dispatch(fetchLimitationsSuccess(limitations));
  } catch (e) {
    dispatch(fetchLimitationsFailure());
    return console.error(e);
  }
};
