import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';
import _ from 'lodash';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form, List, Tooltip, Button, Popconfirm, Menu } from 'antd';
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import './index.scss';

// TYPES
import { EFieldType, TField } from '../../../types/field.type';
import { TNewSortOrderFields } from '../../../types/sortOrder.type';

// ACTIONS
import {
  createField,
  deleteField,
  updateField,
  createEmptyField,
  copyField,
  selectedFieldSelector,
  setSelectedField,
  fetchLanguagesFields,
  languagesFieldsSelector,
  limitationsFieldsSelector,
  fetchLimitationsFields,
  fetchFields,
  fieldErrorMessageSelector,
  updateFieldsOrder,
  fieldsSelector,
  advancedFieldsSelector,
  fetchAdvancedSettingsForField,
} from '../../../slices/field.slice';

// UTILS
import { timeConverter } from '../../../utils/timeConverter';
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import NormalListView from '../../../components/List/NormalListView';
import DraggableTable from '../../../components/ReuseableTables/DragableTable/DragableTable';
import {
  FieldsGeneralSettings,
  FieldsPreferences,
  FieldsCategories,
  FieldsReferences,
  FieldsConnections,
} from '../../../components/FieldsComponent';
import LanguagePreferences from '../../../components/LanguagePreferences';
import Documentation from '../../../components/Documentation';
import Advanced, { ActionSettings } from '../../../components/Advanced';
import { ListSearch } from '../../../components/ListSearch';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

const FieldsPage = ({ customerSignature }: Props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const fields: TField[] = useSelector(fieldsSelector);
  const languagesFields = useSelector(languagesFieldsSelector);
  const limitations = useSelector(limitationsFieldsSelector);
  const selectedField: TField = useSelector(selectedFieldSelector);
  const errorMessage = useSelector(fieldErrorMessageSelector);
  const advancedSettings: ActionSettings[] = useSelector(
    advancedFieldsSelector,
  );

  // State for setting error messages
  const [error, setError] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState(false);

  const onFieldSelected = (selectedValue: any) => {
    const currentField = fields.find(
      (field: TField) => field.id === selectedValue,
    );
    if (currentField) {
      dispatch(setSelectedField(currentField));
      setError(false);
    }
    setFieldLocked(true);
  };

  const [sortQuery, setSortQuery] = useState<string>('extId');
  const [sortOption, setSortOption] = useState<string>('sort');
  const [fieldTypeQuery, setFieldTypeQuery] = useState<string>();
  const [fieldTypeOption, setFieldTypeOption] = useState<string>();
  const [searchTextQuery, setSearchTextQuery] = useState<string>();
  const [searchTextOption, setSearchTextOption] = useState<string>();
  const [secondColumn, setSecondColumn] = useState<boolean>(false);

  // When searching with optional text string
  const onSearch = (value: string) => {
    setSearchTextOption('searchText');
    setSearchTextQuery(value);
  };

  const listData = fields.map((field: any) => ({
    listLabel: field[sortQuery === 'name' ? `${sortQuery}` : 'extId'],
    value: field.id,
    fieldType:
      secondColumn === true
        ? field.fieldType.charAt(0).toUpperCase() + field.fieldType.slice(1)
        : null,
  }));

  const sortArray = ['extId', 'name', 'fieldOrder'];

  // State for det lock-button
  const [lockedField, setFieldLocked] = useState(true);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/fields`,
          label: `${language.fields}`,
        },
        {
          path: `/system/fields`,
          label: `${selectedField.extId}`,
        },
      ]),
    );
    setErrorMessageState(false);
  }, [dispatch, customerSignature, selectedField?.extId, selectedField.id]);

  useEffect(() => {
    dispatch(fetchLanguagesFields());
    dispatch(fetchLimitationsFields());
    dispatch(
      fetchFields(
        sortOption,
        sortQuery,
        fieldTypeOption,
        fieldTypeQuery,
        searchTextOption,
        searchTextQuery,
      ),
    );
  }, [
    dispatch,
    sortQuery,
    sortOption,
    fieldTypeOption,
    fieldTypeQuery,
    searchTextQuery,
    searchTextOption,
  ]);

  useEffect(() => {
    if (selectedField && selectedField.id !== 0) {
      dispatch(fetchAdvancedSettingsForField(selectedField));
    }
  }, [dispatch, selectedField]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabled(selectedField, fields));
  }, [fields, selectedField]);

  // EVENT HANDLERS
  // Adds new field
  const onPlusButton = () => {
    dispatch(createEmptyField());
  };

  // Deletes selected field
  const onDeleteButton = () => {
    dispatch(deleteField(selectedField));
    if (errorMessage === {}) {
      setErrorMessageState(false);
    } else {
      setErrorMessageState(true);
    }
  };

  // Excluding the selected field
  const otherFields = fields.filter((field: TField) => field !== selectedField);

  // Save changes function
  const onSaveChangesFieldButton = () => {
    if (selectedField.id === 0) {
      if (
        _.some(
          otherFields,
          (field: TField) => field.extId === selectedField.extId,
        )
      ) {
        setError(true);
      } else {
        dispatch(createField(selectedField));
        setError(false);
      }
    } else {
      dispatch(updateField(selectedField));
      setError(false);
      setFieldLocked(true);
    }
  };

  const onHandleChange = (property: string, value: any) => {
    dispatch(
      setSelectedField({
        ...selectedField,
        [property]: value,
      }),
    );
    form.setFieldsValue({
      fieldType: value,
      filter: value,
      sumType: value,
      topLevelType: value,
      titleType: value,
    });
  };

  // Discard changes function
  const onDiscardChanges = () => {
    onFieldSelected(selectedField.id);
  };

  // Copy field
  const onCopy = () => {
    dispatch(copyField(selectedField));
  };

  // The sort order object to send to the server to change order on fields
  const newSortOrderObject = (
    arr: Array<any>,
    index1: number,
    index2: number,
  ) => {
    const newArr = [...arr];
    const fields =
      index1 < index2
        ? // If dragging downwards, sortField will be the element you put the selectedReservationMode below
          [newArr[index2].id, newArr[index1].id]
        : // If dragging upwards, sortField will be the element you put the selectedReservationMode above
          [newArr[index1].id, newArr[index2].id];
    const newSortOrder: TNewSortOrderFields = {
      fields,
      sortField: newArr[index2].id,
    };
    return newSortOrder;
  };

  // Function saving the new fieldsOrder object and updating the new order
  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newSortOrder: TNewSortOrderFields = newSortOrderObject(
        fields,
        dragIndex,
        hoverIndex,
      );
      dispatch(updateFieldsOrder(newSortOrder));
    },
    [dispatch, fields],
  );

  const menu = (
    <Menu
      onClick={(event: any) => {
        if (event.domEvent.target.id === 'sort') {
          setSortOption(event.domEvent.target.id);
          setSortQuery(event.key);
        } else if (event.domEvent.target.id === 'fieldType') {
          setFieldTypeOption(event.domEvent.target.id);
          setFieldTypeQuery(event.key);
        }
        if (
          secondColumn === true &&
          event.domEvent.target.id === 'secondColumn'
        ) {
          setSecondColumn(false);
        } else if (event.domEvent.target.id === 'secondColumn') {
          setSecondColumn(true);
        }
      }}
      className='list-search--menu'
    >
      <Menu.SubMenu title={language.field_type}>
        <Menu.Item
          key={'all'}
          id={'fieldType'}
          className='list-search--menu-item'
        >
          {language.all}
        </Menu.Item>
        {Object.entries(EFieldType).map(([value]) => (
          <Menu.Item
            key={value}
            id={'fieldType'}
            className='list-search--menu-item'
          >
            {language[value]}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu title={language.sort_on}>
        {sortArray.map((sort: any) => (
          <Menu.Item key={sort} id={'sort'} className='list-search--menu-item'>
            {language[sort]}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu title={language.second_column}>
        <Menu.Item
          key='secondColumn'
          id='secondColumn'
          className='list-search--menu-item'
        >
          {language.field_type}
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  const columns = [
    {
      render: (record: any) =>
        record.value !== selectedField.id ? (
          <List.Item
            className='draggable-list-item'
            onClick={() => onFieldSelected(record.value)}
            actions={[
              <Tooltip
                title={language.copy}
                placement='topLeft'
                key={record.id}
              >
                <Button
                  size='small'
                  type='default'
                  icon={<CopyOutlined />}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onCopy();
                  }}
                  // How to make this one below false ALWAYS?
                  ant-click-animating-without-extra-node='false'
                  className='list-item-action-button copy'
                />
              </Tooltip>,
              <Popconfirm
                key={record.id}
                title={`${language.sure_to_delete_object}?`}
                onConfirm={(event: any) => {
                  event.stopPropagation();
                  onDeleteButton();
                }}
                onCancel={() => console.log('should do nothing, no delete')}
                okText={language.yes}
                cancelText={language.no}
                className='list-item-action-button'
              >
                <Button
                  size='small'
                  danger
                  ghost
                  type='default'
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>,
            ]}
          >
            <Tooltip title={record.listLabel} placement='left'>
              <p className='list-item-text'>{record.listLabel}</p>
            </Tooltip>
          </List.Item>
        ) : (
          <List.Item
            className='draggable-list-item'
            onClick={() => onFieldSelected(record.value)}
            actions={[
              <Tooltip
                title={language.copy}
                placement='topLeft'
                key={record.value}
              >
                <Button
                  size='small'
                  type='default'
                  className='list-item-action-button copy'
                  icon={<CopyOutlined />}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onCopy();
                  }}
                  // How to make this one below false ALWAYS?
                  ant-click-animating-without-extra-node='false'
                />
              </Tooltip>,
              <Popconfirm
                title={`${language.sure_to_delete_object}?`}
                key={record.value}
                onConfirm={(event: any) => {
                  event.stopPropagation();
                  onDeleteButton();
                }}
                onCancel={() => console.log('should do nothing, no delete')}
                okText={language.yes}
                cancelText={language.no}
              >
                <Button
                  size='small'
                  danger
                  ghost
                  type='default'
                  className='list-item-action-button'
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>,
            ]}
          >
            <Tooltip title={record.listLabel} placement='left'>
              <p className='list-item-text'>{record.listLabel}</p>
            </Tooltip>
          </List.Item>
        ),
    },
  ];

  if (selectedField) {
    return (
      <>
        <ListView
          sectionHeading={language.fields}
          number={listData.length}
          onSelect={onPlusButton}
          extraWidth={secondColumn === true}
        >
          <ListSearch onSearch={onSearch} menu={menu} />
          {sortQuery === 'fieldOrder' ? (
            <DraggableTable
              className='draggable-table-as-listview'
              size='small'
              columns={columns}
              dataSource={listData.map((data: any) => ({
                key: data.value,
                ...data,
                data,
              }))}
              onDrag={moveRow}
              tableLayout='fixed'
            />
          ) : (
            <NormalListView
              data={listData}
              onSelect={onFieldSelected}
              onDelete={onDeleteButton}
              onCopy={onCopy}
              selectedItemId={selectedField.id}
            />
          )}
        </ListView>
        <div className='inner-content--wrapper'>
          <SectionHeader
            listChoice={
              selectedField.name ? `${selectedField.name}` : `${language.field}`
            }
            history={selectedField.history}
            modifiedDate={
              selectedField.history?.length !== 0
                ? timeConverter(selectedField?.history[0]?.modified)
                : undefined
            }
            modifiedBy={selectedField.history[0]?.modifiedBy}
            changeHandler={onSaveChangesFieldButton}
            discardHandler={onDiscardChanges}
            displayProp={false}
            error={
              error === true
                ? `${language.extid_field_already_exists}`
                : errorMessageState === true
                ? `${errorMessage}`
                : null
            }
            isDisabled={saveDisabled}
          />
          <Form layout='vertical' form={form}>
            <Documentation
              onChange={onHandleChange}
              documentation={selectedField.documentation}
              limitationValue={limitations.documentation}
            />
            <FieldsGeneralSettings
              field={selectedField}
              onChange={onHandleChange}
              limitations={limitations}
              setFieldLocked={setFieldLocked}
              lockedField={lockedField}
            />
            <LanguagePreferences
              languages={languagesFields}
              onChange={onHandleChange}
              altDesignations={selectedField.altDesignations}
              limitationName={limitations.name}
              limitationDescription={limitations.description}
            />
            <FieldsPreferences
              field={selectedField}
              onChange={onHandleChange}
              limitations={limitations}
            />
            <FieldsCategories
              field={selectedField}
              categories={selectedField.categories}
              onChange={onHandleChange}
            />
            <FieldsReferences
              field={selectedField}
              fields={fields}
              referenceFields={selectedField.referenceFields}
              onChange={onHandleChange}
            />
            <FieldsConnections
              connectionTypes={selectedField.types}
              connectionTemplates={selectedField.reservationTemplates}
              connectionOrganizations={selectedField.organizations}
              onChange={onHandleChange}
            />
            <Advanced
              options={advancedSettings}
              selectedUrl={'fields'}
              selectedId={selectedField.id}
            />
          </Form>
        </div>
      </>
    );
  }
  return null;
};

export default FieldsPage;
