import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import Form from 'antd/lib/form/Form';

// TYPES
import { TAuthenticationToken } from '../../../types/authenticationToken.type';
import { TApi } from '../../../types/tapi.type';

// ACTIONS
import {
  apisSelector,
  createApi,
  fetchApis,
  setSelectedApi,
  setSelectedApis,
  updateApis,
} from '../../../slices/api.slice';
import {
  authenticationTokensSelector,
  createAuthenticationToken,
  fetchAuthenticationTokens,
  setSelectedAuthenticationTokens,
  updateAuthenticationTokens,
} from '../../../slices/authenticationToken.slice';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import {
  APIGeneralSettings,
  APIProperties,
} from '../../../components/APIComponent';
import { AuthenticationTokensComponent } from '../../../components/AuthenticationTokensComponent';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const APIPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const applicationKeys: TApi[] = useSelector(apisSelector);
  const authenticationTokens: TAuthenticationToken[] = useSelector(
    authenticationTokensSelector,
  );

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/api-services`,
          label: `${language.api_services}`,
        },
        {
          path: `/api-services/api`,
          label: `API`,
        },
      ]),
    );
    dispatch(fetchApis());
    dispatch(fetchAuthenticationTokens());
  }, [customerSignature, dispatch]);

  // EVENT HANDLERS
  const onChangeHandler = (value: any, property: string, key: any) => {
    const index = applicationKeys.indexOf(key);
    const updatedApplicationKey: any = Object.assign({}, key);
    if (property === 'name') {
      updatedApplicationKey.certificateName = value;
    }
    updatedApplicationKey[property] = value;
    const newApplicationKeys: TApi[] = [...applicationKeys];
    newApplicationKeys.splice(index, 1, updatedApplicationKey);
    dispatch(setSelectedApis(newApplicationKeys));
    dispatch(setSelectedApi({ ...key, [property]: value }));
  };

  const onHandleChangeAuthenticationTokens = (
    value: any,
    property: string,
    token: TAuthenticationToken,
  ) => {
    const index = authenticationTokens.indexOf(token);
    const updatedAuthenticationToken: any = Object.assign({}, token);
    updatedAuthenticationToken[property] = value;
    const newAuthenticationTokens: TAuthenticationToken[] = [
      ...authenticationTokens,
    ];
    newAuthenticationTokens.splice(index, 1, updatedAuthenticationToken);
    dispatch(setSelectedAuthenticationTokens(newAuthenticationTokens));
  };

  // Save changes function
  const onSaveChangesApisButton = () => {
    if (
      // eslint-disable-next-line array-callback-return
      applicationKeys.map((applicationKey: TApi) => {
        if (applicationKey.id === 0) {
          dispatch(createApi(applicationKey));
        }
        dispatch(fetchApis());
      })
    ) {
      dispatch(updateApis(applicationKeys));
      dispatch(fetchApis());
    } else if (
      // eslint-disable-next-line array-callback-return
      authenticationTokens.map((authenticationToken: TAuthenticationToken) => {
        if (authenticationToken.id === 0) {
          dispatch(createAuthenticationToken(authenticationToken));
        }
      })
    ) {
      dispatch(updateAuthenticationTokens(authenticationTokens));
      dispatch(fetchAuthenticationTokens());
    } else {
      dispatch(updateApis(applicationKeys));
      dispatch(fetchApis());
      dispatch(updateAuthenticationTokens(authenticationTokens));
      dispatch(fetchAuthenticationTokens());
    }
  };

  // Discard changes function
  const onDiscardChanges = () => {
    dispatch(fetchApis());
    dispatch(fetchAuthenticationTokens());
  };

  return (
    <div className='inner-content--wrapper'>
      <SectionHeader
        listChoiceHeading={'API'}
        changeHandler={onSaveChangesApisButton}
        discardHandler={onDiscardChanges}
        displayProp={false}
        history={false}
      />
      <Form>
        <APIGeneralSettings
          applicationKeys={applicationKeys}
          onChange={onChangeHandler}
        />
        <APIProperties applicationKeys={applicationKeys} />
        <AuthenticationTokensComponent
          authenticationTokens={authenticationTokens}
          onChange={onHandleChangeAuthenticationTokens}
        />
      </Form>
    </div>
  );
};

export default APIPage;
