import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Input, Select, Table} from "antd";
import './index.scss';
import {TTravelTimePaddingDistance, TTravelTimePaddingRule,} from "../../../types/padding.type";
import intl from "../../../i18n/intl";
import {typesSelector} from "../../../slices/type.slice";
import {fieldsSelector} from "../../../slices/field.slice";
import {EFieldType, TField} from "../../../types/field.type";
import {cloneDeep} from "lodash";
import {
    getSpecificPaddingValueFromState,
    setTravelTimeRule,
    setTravelTimeRulePadding,
    travelTimeRuleSelector
} from "../../../slices/paddingRules.slice";
import {FromSecondsInputNumber} from "../../FromSeconds/FromSecondsInputNumber";


type Props = {
    rule: TTravelTimePaddingRule
}

const language: any = intl.messages;

const TimeTravelPaddingMatrix = ({rule}: Props) => {
    const dispatch = useDispatch();

    const allTypes = useSelector(typesSelector);
    const allFields = useSelector(fieldsSelector).filter((field: TField) => field.fieldType === EFieldType.CATEGORY);

    useEffect(() => {
        updateFields(rule.controllingTypeId);
        const fieldValues = updateFieldValues(rule.controllingFieldId);
        generateColumns(fieldValues);
        generateData(fieldValues);
    }, [rule.controllingTypeId, rule.controllingFieldId]);

    const [fields, setFields] = useState<TField[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);

    const updateFields = (fieldId: number) => {
        fieldId === 1 ?
            setFields(allFields) :
            setFields(allFields.filter((field: any) => field.types.includes(fieldId)));
    }

    const updateFieldValues = (fieldId: number) => {
        return fieldId === 0 ? [] : allFields.filter(field => field.id === fieldId)[0].categories

    }

    const generateColumns = (fieldValues: string[]) => {
        setColumns([{
            title: "",
            key: "row-header",
            width: 150,
            dataIndex: undefined,
            fixed: 'left',
            render: (value: any, row: any, rowIdx: any) => {
                return (<span>{fieldValues[rowIdx]}</span>)
            },
        }, ...fieldValues.map((fieldValue, colIdx) => ({
            title: fieldValue,
            dataIndex: undefined,
            width: 200,
            key: `col-${colIdx}`,
            render: (value: any, row: any, rowIdx: any) => {
                if (rowIdx > colIdx) {
                    // Locate this input field in the distance state
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    const travelTimeRule = useSelector(travelTimeRuleSelector)
                    const distance = travelTimeRule.distances.findIndex((distance: TTravelTimePaddingDistance) => {
                        return JSON.stringify(distance.fieldValues) === JSON.stringify([fieldValues[colIdx], fieldValues[rowIdx]]) ||
                            JSON.stringify(distance.fieldValues) === JSON.stringify([fieldValues[rowIdx], fieldValues[colIdx]])
                    });

                    const distanceValue = getSpecificPaddingValueFromState(distance)

                    return (
                        <div style={{display: "flex"}}>
                            {
                                FromSecondsInputNumber(
                                    // @ts-ignore
                                    distanceValue || 0,
                                    (value: any) => {
                                        if (distance === -1) {
                                            const distances = cloneDeep(travelTimeRule.distances)
                                            distances.push({
                                                fieldValues: [fieldValues[colIdx], fieldValues[rowIdx]],
                                                padding: parseInt(value)
                                            })
                                            dispatch(setTravelTimeRule({
                                                ...rule,
                                                distances
                                            }))
                                        } else {
                                            dispatch(setTravelTimeRulePadding({
                                                index: distance,
                                                padding: parseInt(value)
                                            }))
                                        }
                                    },
                                    'matrix',
                                )
                            }
                        </div>
                    )
                }
            }
        }))]);
    }


    const generateData = (fieldValues: string[]) => {
        setData(fieldValues.map((item, index) => {
            return {
                key: index,
            }
        }))
    }

    return (
        <>
            <div className={"controlling-objects-form-wrapper"}>
                <h3>{language.controlling_objects}</h3>
                <div className={"controlling-objects-select"}>
                    <div className={"controlling-objects-select-label"}>Type</div>
                    <Select
                        value={rule?.controllingTypeId}
                        showSearch
                        filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{width: 160}} onSelect={(value: any) => {
                        const ruleCopy = cloneDeep(rule);
                        updateFields(value);
                        ruleCopy.controllingTypeId = value;
                        ruleCopy.controllingFieldId = 0;
                        dispatch(setTravelTimeRule(ruleCopy))
                    }}>
                        <Select.Option key={0} value={0}>{language.select_type}</Select.Option>
                        {
                            allTypes.map((type: any) => (
                                <Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>
                            ))
                        }
                    </Select>
                </div>
                <div className={"controlling-objects-select"}>
                    <div className={"controlling-objects-select-label"}>Field criteria</div>
                    <Select
                        value={rule?.controllingFieldId}
                        showSearch
                        filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        style={{width: 160}} onSelect={(value: any) => {
                        const fieldValues = updateFieldValues(value);
                        if (fieldValues.length > 0) {
                            dispatch(setTravelTimeRule({...rule, controllingFieldId: value}))
                        }
                        generateColumns(fieldValues);
                        generateData(fieldValues);
                    }}>
                        <Select.Option key={0} value={0}>{language.select_field}</Select.Option>
                        {
                            fields.map((field: any) => (
                                <Select.Option key={field.id} value={field.id}>{field.name}</Select.Option>
                            ))
                        }
                    </Select>
                </div>
            </div>
            <Table style={{marginTop: 50, height: 500}} columns={columns} pagination={false}
                   dataSource={data}
                   scroll={{x: 1500, y: 500}}/>
        </>
    )
};

export default TimeTravelPaddingMatrix;