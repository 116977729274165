import _ from 'lodash';

// Re-useable function for checking if the 'Save changes' button shall be disabled or not.
// Disabled button = no unsaved changes
export const isSavedDisabled = (
  objectWithId: any,
  objectsWithIds: Array<any>,
) => {
  const ignoreProps = ['history'];
  const originalObject = objectsWithIds.find(
    (object: any) => object.id === objectWithId.id,
  );
  if (!originalObject) {
    return false;
  }
  return _.every(Object.keys(objectWithId), (key: any) => {
    if (ignoreProps.indexOf(key) !== -1) {
      return true;
    }
    return _.isEqual(objectWithId[key], originalObject[key]);
  });
};

// Functions for when we have no id
export const isSavedDisabledNoId = (updatedObject: any, object: any) => {
  if (updatedObject !== object) {
    return false;
  } else {
    return true;
  }
};

// Special function for objects since we do not have 'selectedObject'
export const isSavedDisabledObjects = (
  selectedObjects: any[],
  allObjects: any[],
) => {
  if (
    _.some(selectedObjects, (selectedObject: any) => {
      return !isSavedDisabled(selectedObject, allObjects);
    })
  ) {
    return false;
  } else {
    return true;
  }
};
