import { useDispatch, useSelector } from 'react-redux';

// REDUX
import {
  fetchPasswordRules,
  setUpdatedPasswordRules,
  updatedPasswordRulesSelector,
  updatePasswordRules,
} from '../../../slices/passwordRules.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Collapse, Input, Checkbox, InputNumber } from 'antd';

// TYPES
import { TPasswordRules } from '../../../types/systempreferences.type';

// UTILS
import { isSavedDisabledNoId } from '../../../utils/isSavedDisabled';

// COMPONENTS
import SystemPrefsActionButtons from '../SystemPrefsActionButtons';
import { useEffect, useState } from 'react';

type PropsPasswordRules = {
  passwordRules: TPasswordRules;
  limitations: any;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const PasswordRules = ({
  passwordRules,
  limitations,
}: PropsPasswordRules) => {
  const dispatch = useDispatch();
  const updatedPasswordRules: TPasswordRules = useSelector(
    updatedPasswordRulesSelector,
  );

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabledNoId(updatedPasswordRules, passwordRules));
  }, [updatedPasswordRules, passwordRules]);

  // EVENT HANDLERS
  const onHandleChanges = (
    value: any,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (new TextEncoder().encode(value).length > limitationValue) {
        return;
      }
    }
    const newPasswordRules: any = {
      ...updatedPasswordRules,
      [property]: value,
    };
    dispatch(setUpdatedPasswordRules(newPasswordRules));
  };
  // Save changes function
  const onSaveChangesButton = () => {
    dispatch(updatePasswordRules(updatedPasswordRules));
  };

  // Discard changes function
  const onDiscardChanges = () => {
    dispatch(fetchPasswordRules());
  };
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.password_rules} key='5'>
        <SystemPrefsActionButtons
          displayProp={false}
          changeHandler={onSaveChangesButton}
          discardHandler={onDiscardChanges}
          isDisabled={saveDisabled}
        />
        <div style={{ marginLeft: 20, marginTop: '8px' }}>
          <div
            className='row-direction--wrapper'
            style={{ alignItems: 'center', marginBottom: 8 }}
          >
            <p
              className='form-item-text'
              style={{ marginTop: 0, width: '130px' }}
            >
              {language.minimum_length}
            </p>
            <InputNumber
              min={0}
              max={200}
              size='small'
              value={updatedPasswordRules.minimumLength}
              onChange={(value: any) => onHandleChanges(value, 'minimumLength')}
              style={{ width: '60px' }}
            />
          </div>
          <div className='checkbox-wrapper'>
            <Checkbox
              onChange={(event: any) =>
                onHandleChanges(
                  event.target.checked,
                  'doNotAllowPasswordsContainingLoginName',
                )
              }
              checked={
                updatedPasswordRules.doNotAllowPasswordsContainingLoginName
              }
            >
              {language.do_not_allow_passwords_containing_login_name}
            </Checkbox>
            <Checkbox
              style={{ marginLeft: 0 }}
              onChange={(event: any) =>
                onHandleChanges(
                  event.target.checked,
                  'doNotAllowPasswordsOnBlackList',
                )
              }
              checked={updatedPasswordRules.doNotAllowPasswordsOnBlackList}
            >
              {language.do_not_allow_passwords_on_black_list}
            </Checkbox>
            <Checkbox
              style={{ marginLeft: 0 }}
              onChange={(event: any) =>
                onHandleChanges(event.target.checked, 'requireLowerCase')
              }
              checked={updatedPasswordRules.requireLowerCase}
            >
              {language.require_lower_case_character} (a-z)
            </Checkbox>
            <Checkbox
              style={{ marginLeft: 0 }}
              onChange={(event: any) =>
                onHandleChanges(event.target.checked, 'requireUpperCase')
              }
              checked={updatedPasswordRules.requireUpperCase}
            >
              {language.require_upper_case_character} (A-Z)
            </Checkbox>
            <Checkbox
              style={{ marginLeft: 0 }}
              onChange={(event: any) =>
                onHandleChanges(event.target.checked, 'requireDigit')
              }
              checked={updatedPasswordRules.requireDigit}
            >
              {language.require_digit_character} (0-9)
            </Checkbox>
            <div className='row-direction--wrapper'>
              <Checkbox
                style={{ marginLeft: 0 }}
                onChange={(event: any) =>
                  onHandleChanges(event.target.checked, 'requireSpecial')
                }
                checked={updatedPasswordRules.requireSpecial}
              >
                {language.require_special_character}
              </Checkbox>
              <Input
                style={{ width: 200, color: '#0f1818', fontSize: '16px' }}
                size='small'
                value={updatedPasswordRules.specialCharacters}
                onChange={(event: any) =>
                  onHandleChanges(
                    event.target.value,
                    'specialCharacters',
                    limitations.specialCharacters,
                  )
                }
              />
            </div>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};
