import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// i18N
import intl from '../../i18n/intl';

// REDUX
import {
  getListOfHolidays,
  holidaySelector,
  createHolidayReservation,
  createdHolidaysSelector,
} from '../../slices/holidayReservation.slice';

// STYLES
import { Collapse, DatePicker, Form, Select, Button, List } from 'antd';
import './index.scss';

// TYPES
import {
  THolidayRegion,
  THolidayType,
  THoliday,
} from '../../types/holidayreservation.type';

type Props = {
  holidayRegions: THolidayRegion[];
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

const onBlur = () => {};
const onFocus = () => {};

export const HolidayReservationsComponent = ({ holidayRegions }: Props) => {
  const [selectedHolidayRegion, setSelectedHolidayRegion] =
    useState<THolidayRegion>();
  const [selectedHolidayType, setSelectedHolidayType] =
    useState<THolidayType>();
  const [selectedFromDate, setSelectedFromDate] = useState<number>();
  const [selectedToDate, setSelectedToDate] = useState<number>();

  const onHolidayRegionSelected = (selectedValue: any) => {
    const currentHolidayRegion = holidayRegions.find(
      (holidayRegion: any) => holidayRegion.id === selectedValue,
    );
    setSelectedHolidayRegion(currentHolidayRegion);
  };

  const onHolidayTypeSelected = (selectedValue: any) => {
    const currentHolidayType = selectedHolidayRegion?.holidayTypes.find(
      (holidayType: any) => holidayType.id === selectedValue,
    );
    setSelectedHolidayType(currentHolidayType);
  };

  const selectedHolidays: THoliday[] = useSelector(holidaySelector);

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      selectedHolidayRegion !== undefined &&
      selectedHolidayType !== undefined &&
      selectedFromDate !== undefined &&
      selectedToDate !== undefined
    ) {
      dispatch(
        getListOfHolidays(
          `${selectedHolidayRegion.id}`,
          `${selectedHolidayType.id}`,
          selectedFromDate,
          selectedToDate,
        ),
      );
    }
    setListHolidays(false);
    setCreatedHolidays(false);
  }, [
    dispatch,
    selectedToDate,
    selectedFromDate,
    selectedHolidayRegion?.id,
    selectedHolidayType?.id,
  ]);

  const createdHolidays = useSelector(createdHolidaysSelector);

  const [listHolidays, setListHolidays] = useState(false);
  const [createHolidays, setCreatedHolidays] = useState(false);

  const onListHolidays = () => {
    // Will show a list of the holidays for the selected period
    setListHolidays(true);
  };

  const createHolidayReservationsFromSelection = () => {
    // Make a post request to the server with the states above
    // to create these as holiday reservations
    if (
      selectedHolidayRegion !== undefined &&
      selectedHolidayType !== undefined &&
      selectedFromDate !== undefined &&
      selectedToDate !== undefined
    ) {
      const holidayReservationRequest = {
        holidayRegion: selectedHolidayRegion.id,
        holidayType: selectedHolidayType.id,
        startDate: selectedFromDate,
        endDate: selectedToDate,
      };
      dispatch(createHolidayReservation(holidayReservationRequest));
    }
    setCreatedHolidays(true);
  };

  const [form] = Form.useForm();

  return (
    <Form layout='vertical' form={form}>
      <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
        <Collapse.Panel header={language.holiday_reservations} key='1'>
          <Form.Item label='Region'>
            <Select
              showSearch
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              size='small'
              onSelect={(value: any) => onHolidayRegionSelected(value)}
              placeholder='Select region'
              onFocus={onFocus}
              onBlur={onBlur}
              value={selectedHolidayRegion?.id}
            >
              {holidayRegions?.map((region: any) => (
                <Select.Option key={region.id} value={region.id}>
                  {region.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={language.type}>
            <Select
              size='small'
              showSearch
              onSelect={(value: any) => onHolidayTypeSelected(value)}
              placeholder={language.select_type}
              onFocus={onFocus}
              onBlur={onBlur}
              value={selectedHolidayType?.id}
            >
              {selectedHolidayRegion?.holidayTypes?.map((type: any) => (
                <Select.Option key={type.name} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={`${language.from} / ${language.to}`}>
            <div className='row-direction--wrapper'>
              <DatePicker
                size='small'
                format='YYYY-MM-DD'
                value={
                  selectedFromDate ? moment.unix(selectedFromDate).utc() : null
                }
                onChange={(value: any) =>
                  setSelectedFromDate(parseInt(value.format('X')))
                }
              />
              <p
                className='small-heading-text'
                style={{ marginLeft: 4, marginRight: 4, marginBottom: 0 }}
              >
                -
              </p>
              <DatePicker
                size='small'
                value={
                  selectedToDate ? moment.unix(selectedToDate).utc() : null
                }
                onChange={(value: any) =>
                  setSelectedToDate(parseInt(value.format('X')))
                }
              />
            </div>
          </Form.Item>
          <Button size='small' type='default' onClick={onListHolidays}>
            {language.list_holidays}
          </Button>
          <Button
            size='small'
            type='default'
            style={{ marginLeft: '6px' }}
            onClick={createHolidayReservationsFromSelection}
          >
            {language.create_holiday_reservations_from_selection}
          </Button>
          {createHolidays && createdHolidays >= 0 ? (
            <p className='small-heading-text' style={{ marginTop: '20px' }}>
              {createdHolidays} holiday reservations are created.<br></br>
              {createdHolidays < selectedHolidays?.length ? (
                <span>
                  Already created holiday reservations are not included.
                </span>
              ) : null}
            </p>
          ) : null}
          {listHolidays && selectedHolidays.length === 0 ? (
            <p
              className='small-heading-text'
              style={{ marginBottom: 0, marginTop: '20px' }}
            >
              There are no holidays in the choosen interval.
            </p>
          ) : listHolidays ? (
            <>
              <p
                className='small-heading-text'
                style={{ marginBottom: 0, marginTop: '20px' }}
              >
                There are {selectedHolidays.length} holidays in the choosen
                interval:
              </p>{' '}
              <List
                size='small'
                style={{ width: '500px' }}
                bordered
                dataSource={selectedHolidays}
                renderItem={(item) => (
                  <List.Item
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <span style={{ marginRight: '6px' }}>
                      {moment.unix(item.startTime).utc().format('YYYY-MM-DD')}:
                    </span>
                    <span style={{ margin: 0, fontWeight: 'bold' }}>
                      {item.name}
                    </span>
                  </List.Item>
                )}
              />
            </>
          ) : null}
        </Collapse.Panel>
      </Collapse>
    </Form>
  );
};
