import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form } from 'antd';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import NormalListView from '../../../components/List/NormalListView';
import {
  MemberRelationsGeneralSettings,
  MemberRelationsTypes,
} from '../../../components/MemberRelationComponent';

// TYPES
import { TMemberType } from '../../../types/member.type';

// ACTIONS
import {
  fetchMemberTypes,
  memberTypesSelector,
  createMemberType,
  deleteMemberType,
  updateMemberType,
  createEmptyMemberType,
  copyMemberType,
  selectedMemberTypeSelector,
  setSelectedMemberType,
  fetchLimitationsMemberTypesSelector,
  fetchLimitationsMemberTypes,
  memberTypeErrorMessageSelector,
} from '../../../slices/memberType.slice';

// UTILS
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const MemberTypesPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const memberTypes: TMemberType[] = useSelector(memberTypesSelector);
  const limitations = useSelector(fetchLimitationsMemberTypesSelector);
  const selectedMemberType: TMemberType = useSelector(
    selectedMemberTypeSelector,
  );
  const errorMessage = useSelector(memberTypeErrorMessageSelector);

  const [form] = Form.useForm();

  const listData = memberTypes.map((memberType: any) => ({
    listLabel: memberType.name,
    value: memberType.id,
  }));

  const onMemberTypeSelected = (selectedValue: any) => {
    const currentMemberType = memberTypes.find(
      (memberType) => memberType.id === selectedValue,
    );
    if (currentMemberType) {
      dispatch(setSelectedMemberType(currentMemberType));
    }
  };

  // State for setting error messages
  const [errorMessageState, setErrorMessageState] = useState(false);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/member-types`,
          label: `${language.member_types}`,
        },
        {
          path: `/${selectedMemberType.id}`,
          label: `${selectedMemberType.name}`,
        },
      ]),
    );
    setErrorMessageState(false);
  }, [
    dispatch,
    customerSignature,
    selectedMemberType.id,
    selectedMemberType.name,
  ]);

  useEffect(() => {
    dispatch(fetchMemberTypes());
    dispatch(fetchLimitationsMemberTypes());
  }, [dispatch]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabled(selectedMemberType, memberTypes));
  }, [memberTypes, selectedMemberType]);

  // EVENT HANDLERS
  // Adds new member type
  const onPlusButton = () => {
    dispatch(createEmptyMemberType());
  };

  // Deletes choosen member types
  const onDeleteButton = () => {
    dispatch(deleteMemberType(selectedMemberType));
  };

  // Save changes function
  const onSaveChangesMemberTypeButton = () => {
    if (selectedMemberType.id === 0) {
      dispatch(createMemberType(selectedMemberType));
      if (errorMessage === {}) {
        setErrorMessageState(false);
      } else {
        setErrorMessageState(true);
      }
    }
    dispatch(updateMemberType(selectedMemberType));
    if (errorMessage === {}) {
      setErrorMessageState(false);
    } else {
      setErrorMessageState(true);
    }
  };

  const onHandleChange = (property: string, value: any) => {
    dispatch(
      setSelectedMemberType({
        ...selectedMemberType,
        [property]: value,
      }),
    );
    form.setFieldsValue({
      property: value,
    });
  };

  // Discard changes function
  const onDiscardChanges = () => {
    onMemberTypeSelected(selectedMemberType.id);
  };

  // Copy member types
  const onCopy = () => {
    dispatch(copyMemberType(selectedMemberType));
  };

  return (
    <>
      <ListView
        sectionHeading={language.member_types}
        number={listData.length}
        onSelect={onPlusButton}
      >
        <NormalListView
          data={listData}
          onSelect={onMemberTypeSelected}
          onDelete={onDeleteButton}
          onCopy={onCopy}
          selectedItemId={selectedMemberType.id}
        />
      </ListView>
      <div className='inner-content--wrapper'>
        <SectionHeader
          listChoice={
            selectedMemberType.name
              ? `${selectedMemberType.name}`
              : `${language.member_type}`
          }
          changeHandler={onSaveChangesMemberTypeButton}
          discardHandler={onDiscardChanges}
          displayProp={false}
          history={false}
          isDisabled={saveDisabled}
          error={
            errorMessageState === true && errorMessage.message !== undefined
              ? `${errorMessage.message}`
              : null
          }
        />
        <Form layout='vertical' form={form}>
          <MemberRelationsGeneralSettings
            memberType={selectedMemberType}
            onChange={onHandleChange}
            limitations={limitations}
          />
          <MemberRelationsTypes
            memberTypesItems={selectedMemberType.memberTypes}
            onChange={onHandleChange}
          />
        </Form>
      </div>
    </>
  );
};

export default MemberTypesPage;
