import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { Field, TField } from '../types/field.type';
import { TNewSortOrderFields } from '../types/sortOrder.type';

// REDUX
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedField: Field.createEmpty(),
  allFields: [],
  fieldsForObjects: [],
  languages: [],
  limitations: {},
  errorMessage: {},
  advancedSettings: [],
};

// Slice
const slice = createSlice({
  name: 'fields',
  initialState,
  reducers: {
    fetchFieldsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchFieldsSuccess: (state: any, { payload }) => {
      if (state.selectedField.id > 0) {
        state.selectedField =
          payload.find((field: any) => field.id === state.selectedField.id) ||
          Field.createEmpty();
      }
      state.allFields = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchFieldsForObjectsSuccess: (state: any, { payload }) => {
      state.fieldsForObjects = payload.results;
    },
    fetchLanguagesFieldsSuccess: (state: any, { payload }) => {
      state.languages = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsFieldsSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchFieldsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createFieldRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createFieldSuccess: (state: any, { payload }) => {
      state.allFields.push(payload);
      state.selectedField = payload;
      finishedLoadingSuccess(state);
    },
    createFieldFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentField: (state: any, { payload }) => {
      state.selectedField = payload;
      state.errorMessage = '';
    },
    deleteFieldRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteFieldSuccess: (state: any, payload: any) => {
      state.allFields = state.allFields.filter(
        (field: TField) => field.id !== payload.payload.id,
      );
      state.selectedField = Field.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteFieldFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
    updateFieldRequest: (state: any) => {
      state.errorMessage = '';
      isLoadingRequest(state);
    },
    updateFieldSuccess: (state: any, { payload }) => {
      state.errorMessage = '';
      const index = state.allFields.findIndex(
        (field: TField) => field.id === payload.id,
      );
      state.allFields[index] = payload.history;
      state.selectedField = payload;
      finishedLoadingSuccess(state);
    },
    updateFieldFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
    updateFieldsOrderRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateFieldsOrderSuccess: (state: any, payload: any) => {
      const newFields = payload.payload
        .map((p: number) =>
          state.allFields.find((field: TField) => p === field.id),
        )
        .filter((f: any) => f !== undefined);
      state.allFields = newFields;
      finishedLoadingSuccess(state);
    },
    updateFieldsOrderFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    fetchAdvancedSettingsFieldsSuccess: (state: any, { payload }) => {
      state.advancedSettings = payload.results;
      finishedLoadingSuccess(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const fieldsLoading = (state: any) => state.fields.loading;
export const fieldsSelector = (state: any) =>
  state.fields.allFields as TField[];
export const fieldsForObjectsSelector = (state: any) =>
  state.fields.fieldsForObjects as TField[];
export const fieldSelectorCreateNew = () => () => {
  return Field.createEmpty();
};
export const selectedFieldSelector = (state: any) => {
  return state.fields.selectedField;
};
export const languagesFieldsSelector = (state: any) =>
  state.fields.languages as [];
export const limitationsFieldsSelector = (state: any) =>
  state.fields.limitations;
export const fieldErrorMessageSelector = (state: any) =>
  state.fields.errorMessage;
export const advancedFieldsSelector = (state: any) =>
  state.fields.advancedSettings;

// Actions
export const {
  fetchFieldsRequest,
  fetchFieldsSuccess,
  fetchFieldsForObjectsSuccess,
  fetchLanguagesFieldsSuccess,
  fetchLimitationsFieldsSuccess,
  fetchAdvancedSettingsFieldsSuccess,
  fetchFieldsFailure,
  createFieldRequest,
  createFieldSuccess,
  createFieldFailure,
  setCurrentField,
  deleteFieldRequest,
  deleteFieldSuccess,
  deleteFieldFailure,
  updateFieldRequest,
  updateFieldSuccess,
  updateFieldFailure,
  updateFieldsOrderRequest,
  updateFieldsOrderSuccess,
  updateFieldsOrderFailure,
} = slice.actions;

export const fetchFields =
  (
    sortOption?: string,
    sortQuery?: string,
    fieldTypeOption?: string,
    fieldTypeQuery?: string,
    searchTextOption?: string,
    searchTextQuery?: string,
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(fetchFieldsRequest());
      let url = `/fields`;
      let encodedSortQuery: string | undefined;
      if (searchTextQuery) {
        encodedSortQuery = encodeURIComponent(searchTextQuery);
      }
      if (
        sortQuery !== undefined &&
        searchTextQuery !== undefined &&
        fieldTypeQuery === undefined
      ) {
        if (searchTextOption !== undefined && encodedSortQuery === undefined) {
          url = `/fields?${sortOption}=${sortQuery}`;
        } else {
          url = `/fields?${sortOption}=${sortQuery}&${searchTextOption}=${encodedSortQuery}`;
        }
      } else if (
        sortQuery !== undefined &&
        encodedSortQuery === undefined &&
        fieldTypeQuery !== undefined
      ) {
        url = `/fields?${sortOption}=${sortQuery}&${fieldTypeOption}=${fieldTypeQuery}`;
      } else if (
        sortQuery === undefined &&
        searchTextQuery === undefined &&
        fieldTypeQuery !== undefined
      ) {
        url = `/fields?${fieldTypeOption}=${fieldTypeQuery}`;
      } else if (
        sortQuery === undefined &&
        searchTextQuery !== undefined &&
        fieldTypeQuery !== undefined
      ) {
        url = `/fields?${fieldTypeOption}=${fieldTypeQuery}&${searchTextOption}=${encodedSortQuery}`;
      } else if (
        sortQuery !== undefined &&
        searchTextQuery === undefined &&
        fieldTypeQuery === undefined
      ) {
        url = `/fields?${sortOption}=${sortQuery}`;
      } else if (
        sortQuery === undefined &&
        searchTextQuery !== undefined &&
        encodedSortQuery !== undefined &&
        fieldTypeQuery === undefined
      ) {
        url = `/fields?${searchTextOption}=${encodedSortQuery}`;
      } else if (
        sortQuery !== undefined &&
        searchTextQuery !== undefined &&
        fieldTypeQuery !== undefined
      ) {
        url = `/fields?${sortOption}=${sortQuery}&${fieldTypeOption}=${fieldTypeQuery}&${searchTextOption}=${encodedSortQuery}`;
      }
      const fields = await api.get({ endpoint: url });
      dispatch(fetchFieldsSuccess(fields));
    } catch (e) {
      dispatch(fetchFieldsFailure());
      return console.error(e);
    }
  };

export const fetchFieldsForObjects = () => async (dispatch: any) => {
  try {
    dispatch(fetchFieldsRequest());
    const fields = await api.get({
      endpoint: `/fields?sort=fieldOrder`,
    });
    dispatch(fetchFieldsForObjectsSuccess(fields));
  } catch (e) {
    dispatch(fetchFieldsFailure());
    return console.error(e);
  }
};

export const fetchLanguagesFields = () => async (dispatch: any) => {
  try {
    dispatch(fetchFieldsRequest());
    const languagesFields = await api.get({ endpoint: `/fields/languages` });
    dispatch(fetchLanguagesFieldsSuccess(languagesFields));
  } catch (e) {
    dispatch(fetchFieldsFailure());
    return console.error(e);
  }
};

export const fetchLimitationsFields = () => async (dispatch: any) => {
  try {
    dispatch(fetchFieldsRequest());
    const limitationsFields = await api.get({
      endpoint: `/fields/limitations`,
    });
    dispatch(fetchLimitationsFieldsSuccess(limitationsFields));
  } catch (e) {
    dispatch(fetchFieldsFailure());
    return console.error(e);
  }
};

export const createField = (field: TField) => async (dispatch: any) => {
  try {
    dispatch(createFieldRequest());
    const { id: _, ...fieldBody } = field;
    const response = await api.post({
      endpoint: `/fields`,
      data: fieldBody,
    });
    dispatch(createFieldSuccess(response));
    dispatch(fetchFields());
  } catch (e: any) {
    dispatch(createFieldFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const createEmptyField = () => async (dispatch: any) => {
  try {
    dispatch(createFieldRequest());
    const response = Field.createEmpty();
    dispatch(createFieldSuccess(response));
  } catch (e: any) {
    dispatch(createFieldFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedField = (field: TField) => async (dispatch: any) => {
  dispatch(setCurrentField(field));
};

export const copyField = (field: TField) => async (dispatch: any) => {
  try {
    dispatch(createFieldRequest());
    const response = {
      ...field,
      id: 0,
      description: '',
      documentation: '',
      categories: [],
      referenceFields: [],
      checkSum: 0,
    };
    dispatch(createFieldSuccess(response));
  } catch (e) {
    dispatch(createFieldFailure());
    return console.error(e);
  }
};

export const deleteField = (field: TField) => async (dispatch: any) => {
  try {
    dispatch(deleteFieldRequest());
    await api.delete({
      endpoint: `/fields/${field.id}`,
    });
    dispatch(deleteFieldSuccess(field));
  } catch (e: any) {
    dispatch(deleteFieldFailure(e.response.data.message));
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const updateField = (field: TField) => async (dispatch: any) => {
  try {
    dispatch(updateFieldRequest());
    const { ...FieldBody } = field;
    const response = await api.patch({
      endpoint: `/fields/${field.id}`,
      data: { ...FieldBody },
    });
    dispatch(updateFieldSuccess(response));
  } catch (e: any) {
    dispatch(updateFieldFailure(e.response.data.message));
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const updateFieldsOrder =
  (fieldsOrder: TNewSortOrderFields) => async (dispatch: any) => {
    try {
      dispatch(updateFieldsOrderRequest());
      const response = await api.patch({
        endpoint: `/fields/sort-order`,
        data: fieldsOrder,
      });
      dispatch(updateFieldsOrderSuccess(response));
    } catch (e: any) {
      dispatch(updateFieldsOrderFailure());
      return console.error(e.response?.data ? e.response.data : e.response);
    }
  };

export const fetchAdvancedSettingsForField =
  (field: TField) => async (dispatch: any) => {
    try {
      dispatch(fetchFieldsRequest());
      const fields = await api.get({
        endpoint: `/fields/${field.id}/advanced`,
      });
      dispatch(fetchAdvancedSettingsFieldsSuccess(fields));
    } catch (e) {
      dispatch(fetchFieldsFailure());
      return console.error(e);
    }
  };
