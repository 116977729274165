import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import '../index.scss';
import { Collapse, Input, Checkbox, Select, InputNumber, Form } from 'antd';

// COMPONENTS
import { TEToolTip, TEItem } from '../../TEToolTip';

// TYPES
import {
  TSystemPreferencesMisc,
  EAcceptJavaClient,
} from '../../../types/systempreferences.type';
import { TType } from '../../../types/type.type';

// ACTIONS
import { typesSelector } from '../../../slices/type.slice';
import {
  setUpdatedSystemPreferences,
  updatedSystemPreferencesSelector,
  updateSystemPreferences,
  fetchSystemPreferences,
} from '../../../slices/systemPreferences.slice';

// UTILS
import { isSavedDisabledNoId } from '../../../utils/isSavedDisabled';

// COMPONENTS
import SystemPrefsActionButtons from '../SystemPrefsActionButtons';
import { EditableFeaturesTable } from './EditableFeaturesTable';

const onBlur = () => {};
const onFocus = () => {};

type PropsMisc = {
  systemPreferences: TSystemPreferencesMisc;
  limitations: any;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

const maxImportFieldValuesArray = [
  { key: `15 (${language.normal})`, value: 15 },
  { key: '20', value: 20 },
  { key: '30', value: 30 },
  { key: '40', value: 40 },
  { key: '50', value: 50 },
];

const maxReservationObjectsArray = [
  { key: `25 (${language.low})`, value: 25 },
  { key: `50 (${language.normal})`, value: 50 },
  { key: `75 (${language.high})`, value: 75 },
  { key: `100 (${language.extreme})`, value: 100 },
  { key: `125 (${language.wild_thing})`, value: 125 },
  { key: `150 (${language.you_kidding})`, value: 150 },
];

export const SystemPreferences = ({
  systemPreferences,
  limitations,
}: PropsMisc) => {
  const dispatch = useDispatch();
  const updatedSystemPreferences: TSystemPreferencesMisc = useSelector(
    updatedSystemPreferencesSelector,
  );
  const types: TType[] = useSelector(typesSelector);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(
      isSavedDisabledNoId(updatedSystemPreferences, systemPreferences),
    );
  }, [updatedSystemPreferences, systemPreferences]);

  // EVENT HANDLERS
  const onHandleChanges = (
    value: any,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (new TextEncoder().encode(value).length > limitationValue) {
        return;
      }
    }
    const newSystemPreferences: any = {
      ...updatedSystemPreferences,
      [property]: value,
    };
    dispatch(setUpdatedSystemPreferences(newSystemPreferences));
  };
  // Save changes function
  const onSaveChangesButton = () => {
    dispatch(updateSystemPreferences(updatedSystemPreferences));
  };

  // Discard changes function
  const onDiscardChanges = () => {
    dispatch(fetchSystemPreferences());
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header='Miscellaneous' key='4'>
        <SystemPrefsActionButtons
          displayProp={false}
          changeHandler={onSaveChangesButton}
          discardHandler={onDiscardChanges}
          isDisabled={saveDisabled}
        />
        <p className='form-item-text'>
          <TEToolTip helpText={language.help_active_features_system_prefs}>
            {language.active_features}:
          </TEToolTip>
          {updatedSystemPreferences?.activeFeatures?.map(
            (activeFeature: any) => (
              <span
                key={activeFeature}
                style={{ fontStyle: 'italic', marginLeft: 6 }}
              >
                {activeFeature}
              </span>
            ),
          )}
        </p>
        <p className='form-item-text'>
          <TEToolTip helpText={'no_help_text'}>
            {language.editable_features}
          </TEToolTip>
          <EditableFeaturesTable
            editableFeatures={updatedSystemPreferences?.editableFeatures}
            onChange={onHandleChanges}
            property='editableFeatures'
          />
        </p>
        <Form.Item
          label='Customer name'
          tooltip={language.help_customer_name_system_prefs}
          style={{ marginTop: 12, marginBottom: 10 }}
        >
          <Input
            size='small'
            value={updatedSystemPreferences.customerName}
            onChange={(event: any) =>
              onHandleChanges(
                event.target.value,
                'customerName',
                limitations.customerName,
              )
            }
            style={{ maxWidth: '75vw' }}
          />
        </Form.Item>
        <Form.Item
          tooltip={language.help_calendar_background_text}
          label={language.calendar_background_text}
          style={{ marginBottom: 10 }}
        >
          <Input
            size='small'
            value={updatedSystemPreferences.calendarBackgroundText}
            onChange={(event: any) =>
              onHandleChanges(
                event.target.value,
                'calendarBackgroundText',
                limitations.calendarBackgroundText,
              )
            }
            style={{ maxWidth: '75vw' }}
          />
        </Form.Item>
        <Form.Item
          tooltip={language.help_order_accept_span_system_prefs}
          style={{ marginBottom: 10 }}
          label={language.order_accept_span}
        >
          <Input.Group
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <InputNumber
              size='small'
              value={updatedSystemPreferences.orderAcceptSpanFrom}
              onChange={(value: any) =>
                onHandleChanges(value, 'orderAcceptSpanFrom')
              }
              min={0}
              max={100}
              style={{ marginRight: 4 }}
            />
            <p className='form-item-text' style={{ marginTop: 0 }}>
              % to
            </p>
            <InputNumber
              size='small'
              value={updatedSystemPreferences.orderAcceptSpanTo}
              onChange={(value: any) =>
                onHandleChanges(value, 'orderAcceptSpanTo')
              }
              min={0}
              max={100}
              style={{ marginLeft: 4, marginRight: 4 }}
            />
            <p className='form-item-text' style={{ marginTop: 0 }}>
              %
            </p>
          </Input.Group>
        </Form.Item>
        <div>
          <Form.Item
            tooltip={language.help_allow_java_client_system_prefs}
            label={language.allow_Java_client}
            style={{ marginBottom: 10 }}
          >
            <Select
              size='small'
              onChange={(value: any) =>
                onHandleChanges(value, 'allowJavaClient')
              }
              onFocus={onFocus}
              onBlur={onBlur}
              value={updatedSystemPreferences?.allowJavaClient}
              style={{ width: 180 }}
            >
              {Object.entries(EAcceptJavaClient).map(([key, value]) => (
                <Select.Option key={key} value={key}>
                  {language[value]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            tooltip={language.help_allow_java_client_system_prefs}
            label={language.help_allow_double_booking_of_type_system_prefs}
            style={{ marginBottom: 10 }}
          >
            <Select
              size='small'
              onChange={(value: any) =>
                onHandleChanges(value, 'doubleBookingType')
              }
              onFocus={onFocus}
              onBlur={onBlur}
              value={updatedSystemPreferences.doubleBookingType}
              style={{ width: 180 }}
            >
              {types.map((type: any) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            tooltip={language.help_max_reservation_objects_system_prefs}
            label={language.max_reservation_objects}
            style={{ marginBottom: 10 }}
          >
            <Select
              size='small'
              style={{ width: 180 }}
              onChange={(value: any) =>
                onHandleChanges(value, 'maxReservationObjects')
              }
              onFocus={onFocus}
              onBlur={onBlur}
              value={updatedSystemPreferences.maxReservationObjects}
            >
              {maxReservationObjectsArray.map((maxObject: any) => (
                <Select.Option key={maxObject.key} value={maxObject.value}>
                  {maxObject.key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            tooltip={language.help_max_import_field_values}
            label={language.max_import_field_values}
            style={{ marginBottom: 10 }}
          >
            <Select
              size='small'
              style={{ width: 180 }}
              onChange={(value: any) =>
                onHandleChanges(value, 'maxImportFieldValues')
              }
              onFocus={onFocus}
              onBlur={onBlur}
              value={updatedSystemPreferences.maxImportFieldValues}
            >
              {maxImportFieldValuesArray.map((maxImportValue: any) => (
                <Select.Option
                  key={maxImportValue.key}
                  value={maxImportValue.value}
                >
                  {maxImportValue.key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <p className='form-item-text'>{language.optional_relations}</p>
        <TEToolTip
          helpText={
            language.help_make_optional_if_any_object_is_optional_on_multi_selection_system_prefs
          }
        >
          <Checkbox
            onChange={(event: any) =>
              onHandleChanges(
                event.target.checked,
                'optionalRelationsAnyObject',
              )
            }
            checked={updatedSystemPreferences.optionalRelationsAnyObject}
          >
            {
              language.make_optional_if_any_object_is_optional_on_multi_selection
            }
          </Checkbox>
        </TEToolTip>
        <p className='form-item-text'>{language.reservation_exceptions}</p>
        <TEToolTip
          helpText={
            language.help_hide_possibility_of_making_member_exceptions_on_reservations_system_prefs
          }
        >
          <Checkbox
            onChange={(event: any) =>
              onHandleChanges(event.target.checked, 'reservationExceptionsHide')
            }
            checked={updatedSystemPreferences.reservationExceptionsHide}
          >
            {
              language.hide_possibility_of_making_member_exceptions_on_reservations
            }
          </Checkbox>
        </TEToolTip>
        <p className='form-item-text'>{language.reservationOrder_list}</p>
        <TEToolTip
          helpText={
            language.help_use_reserve_permisson_on_objects_in_list_when_modify_permission_is_used_system_prefs
          }
        >
          <Checkbox
            onChange={(event: any) =>
              onHandleChanges(
                event.target.checked,
                'useReservePermissionOnObjects',
              )
            }
            checked={updatedSystemPreferences.useReservePermissionOnObjects}
          >
            {
              language.use_reserve_permisson_on_objects_in_list_when_modify_permission_is_used
            }
          </Checkbox>
        </TEToolTip>
        <p className='form-item-text'>{language.reservation_permissions}</p>
        <TEToolTip
          helpText={
            language.help_allow_non_admin_users_to_change_reservation_organizations_system_prefs
          }
        >
          <Checkbox
            onChange={(event: any) =>
              onHandleChanges(
                event.target.checked,
                'reservationPermissionsAllowChangeOrganization',
              )
            }
            checked={
              updatedSystemPreferences.reservationPermissionsAllowChangeOrganization
            }
          >
            {language.allow_non_admin_users_to_change_reservation_organizations}
          </Checkbox>
        </TEToolTip>
        <p className='form-item-text'>{language.affect_orders}</p>
        <TEToolTip helpText={language.help_allow_user_to_change_system_prefs}>
          <Checkbox
            onChange={(event: any) =>
              onHandleChanges(event.target.checked, 'affectOrdersAllowChange')
            }
            checked={updatedSystemPreferences.affectOrdersAllowChange}
          >
            {language.allow_user_to_change}{' '}
            {`${language.affect_orders}`.toLowerCase()}
            {language.on_template_regardless_of_reservation_count}
          </Checkbox>
        </TEToolTip>
      </Collapse.Panel>
    </Collapse>
  );
};
