import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// REDUX
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import { ServicesComponent } from '../../../components/ServicesComponent';

// TYPES

// ACTIONS

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const ServicesPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/api-services`,
          label: `${language.api_services}`,
        },
        {
          path: `/api-services/services`,
          label: `${language.services}`,
        },
      ]),
    );
  }, [customerSignature, dispatch]);

  return (
    <div className='inner-content--wrapper'>
      <SectionHeader
        listChoiceHeading={language.services}
        displayProp
        history={false}
      />
      <ServicesComponent />
    </div>
  );
};

export default ServicesPage;
