import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

// TYPES
import { TEUser } from '../types/user.type';
import { TTEUser } from '@timeedit/types/lib';

/* -------------------------------------------------------------------------------- */

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedUser: TEUser.createEmpty('customerSignature') /* FIX */,
  users: [],
};

// Slice
const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsersRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchUsersSuccess: (state: any, { payload }) => {
      if (state.selectedUser.id !== null) {
        state.selectedUser =
          payload.find((u: any) => u.id === state.selectedUser.id) ||
          TEUser.createEmpty('customerSignature'); /* FIX */
      }
      state.users = payload;
      finishedLoadingSuccess(state);
    },
    fetchUsersFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createUserRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createUserSuccess: (state: any, { payload }) => {
      state.users.push(payload);
      state.selectedUser = payload;
      finishedLoadingSuccess(state);
    },
    createUserFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentUser: (state: any, { payload }) => {
      state.selectedUser = payload;
    },
    deleteUserRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteUserSuccess: (state: any, { payload }) => {
      state.users = state.users.filter(
        (user: TTEUser) => user.id !== payload.id,
      );
      state.selectedUser = TEUser.createEmpty('customerSignature'); /* FIX */
      finishedLoadingSuccess(state);
    },
    deleteUserFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateUserRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateUserSuccess: (state: any, { payload }) => {
      const newUsers = [...state.users];
      const index = state.users.findIndex(
        (user: TTEUser) => user.id === payload.id,
      );
      state.users[index] = payload;
      state.users = newUsers;
      finishedLoadingSuccess(state);
    },
    updateUserFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const usersLoading = (state: any) => state.users.loading;
export const usersSelector = (state: any) => state.users.users;
export const userSelectorCreateNew = (customerSignature: string) => () => {
  return TEUser.createEmpty(customerSignature);
};
export const selectedUserSelector = (state: any) => {
  return state.users.selectedUser;
};

// Actions
export const {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure,
  createUserRequest,
  createUserSuccess,
  createUserFailure,
  setCurrentUser,
  deleteUserRequest,
  deleteUserFailure,
  deleteUserSuccess,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
} = slice.actions;

export const fetchUsers = () => async (dispatch: any) => {
  try {
    dispatch(fetchUsersRequest());
    const users = await api.get({
      endpoint: `/users`,
    });
    dispatch(fetchUsersSuccess(users));
  } catch (e) {
    dispatch(fetchUsersFailure());
    return console.error(e);
  }
};

export const createUser = (user: TTEUser) => async (dispatch: any) => {
  try {
    dispatch(createUserRequest());
    const { id: _, organizationId: customerSignature, ...UserBody } = user;
    const response = await api.post({
      endpoint: `/users`,
      data: UserBody,
    });
    // const responses: any = await Promise.all(results);
    dispatch(createUserSuccess(response));
    dispatch(fetchUsers());
  } catch (e) {
    dispatch(createUserFailure());
    return console.error(e);
  }
};

export const createEmptyUser =
  (customerSignature: string) => async (dispatch: any) => {
    try {
      dispatch(createUserRequest());
      const response = TEUser.createEmpty(customerSignature);
      dispatch(createUserSuccess(response));
    } catch (e) {
      dispatch(createUserFailure());
      return console.error(e);
    }
  };

export const setSelectedUser = (user: TTEUser) => (dispatch: any) => {
  dispatch(setCurrentUser(user));
};

export const copyUser = (user: TTEUser) => async (dispatch: any) => {
  try {
    dispatch(createUserRequest());
    const response = {
      ...user,
      id: null,
    };
    dispatch(createUserSuccess(response));
  } catch (e) {
    dispatch(createUserFailure());
    return console.error(e);
  }
};

export const deleteUser = (user: TTEUser) => async (dispatch: any) => {
  try {
    dispatch(deleteUserRequest());
    await api.delete({
      endpoint: `/users/${user.id}`,
    });
    dispatch(deleteUserSuccess(user));
  } catch (e) {
    dispatch(deleteUserFailure());
    return console.error(e);
  }
};

export const updateUser = (user: TTEUser) => async (dispatch: any) => {
  try {
    dispatch(updateUserRequest());
    const { ...UserBody } = user;
    const response = await api.patch({
      endpoint: `/users/${user.id}`,
      data: { ...UserBody },
    });
    dispatch(updateUserSuccess(response));
  } catch (e) {
    dispatch(updateUserFailure());
    return console.error(e);
  }
};
