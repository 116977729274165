import { useDispatch, useSelector } from 'react-redux';

// REDUX
import {
  fetchUserPreferences,
  setUpdatedUserPreferences,
  updatedUserPreferencesSelector,
  updateUserPreferences,
} from '../../slices/userPreferences.slice';

// i18N
import intl from '../../i18n/intl';

// STYLES
import { Collapse, Input, Form } from 'antd';

// TYPES
import { TUserPreferences } from '../../types/systempreferences.type';

// UTILS
import { isSavedDisabledNoId } from '../../utils/isSavedDisabled';

// COMPONENTS
import { TEToolTip } from '../TEToolTip';
import SystemPrefsActionButtons from '../SystemPrefs/SystemPrefsActionButtons';

type PropsUserPreferences = {
  userPreferences: TUserPreferences;
  limitations: any;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const UserPreferences = ({
  userPreferences,
  limitations,
}: PropsUserPreferences) => {
  const dispatch = useDispatch();
  const updatedUserPreferences: TUserPreferences = useSelector(
    updatedUserPreferencesSelector,
  );

  // EVENT HANDLERS
  const onHandleChanges = (
    value: any,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (new TextEncoder().encode(value).length > limitationValue) {
        return;
      }
    }
    const newUserPreferences: any = {
      ...updatedUserPreferences,
      [property]: value,
    };
    dispatch(setUpdatedUserPreferences(newUserPreferences));
  };
  // Save changes function
  const onSaveChangesButton = () => {
    dispatch(updateUserPreferences(updatedUserPreferences));
  };

  // Discard changes function
  const onDiscardChanges = () => {
    dispatch(fetchUserPreferences());
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.user_settings} key='6'>
        <SystemPrefsActionButtons
          displayProp={false}
          changeHandler={onSaveChangesButton}
          discardHandler={onDiscardChanges}
          isDisabled={isSavedDisabledNoId(
            updatedUserPreferences,
            userPreferences,
          )}
        />
        <Form.Item
          tooltip={language.help_external_web_login_url_system_prefs}
          label={language.external_web_login_URL}
          style={{ marginBottom: 10 }}
        >
          <Input
            size='small'
            value={updatedUserPreferences.externalWebLoginUrl}
            onChange={(event: any) =>
              onHandleChanges(
                event.target.value,
                'externalWebLoginUrl',
                limitations.externalWebLoginUrl,
              )
            }
          />
        </Form.Item>
        <Form.Item
          tooltip={language.help_user_object_patterns_system_prefs}
          label={language.user_object_patterns}
          style={{ marginBottom: 10 }}
        >
          <div style={{ marginLeft: 20 }}>
            <p className='form-item-text'>{language.add_patterns}</p>
            <Input.TextArea
              rows={8}
              size='small'
              value={updatedUserPreferences.userObjectAddPattern}
              onChange={(event: any) =>
                onHandleChanges(
                  event.target.value,
                  'userObjectAddPattern',
                  limitations.userObjectAddPattern,
                )
              }
              style={{ width: '30vw' }}
            />
            <p className='form-item-text'>{language.remove_patterns}</p>
            <Input.TextArea
              rows={8}
              size='small'
              value={updatedUserPreferences.userObjectRemovePattern}
              onChange={(event: any) =>
                onHandleChanges(
                  event.target.value,
                  'userObjectRemovePattern',
                  limitations.userObjectRemovePattern,
                )
              }
              style={{ width: '30vw' }}
            />
          </div>
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};
