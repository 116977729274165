const isOldAdmin = !!process.env.REACT_APP_TE_0LD_ADMIN

export const configs = {
  development: {
    REACT_APP_BASE_URL: 'http://localhost:8080/v1',
    REACT_APP_AUTH_DOMAIN: 'https://auth-beta.timeedit.io',
    TOKEN_NAME: 'teAuthToken',
    CLIENT_ID: 'teAdmin',
    APP_NAME: 'teAdmin',
    VIEWS_URL: `https://views.timeedit.io/v1`,
  },
  beta: {
    REACT_APP_BASE_URL: isOldAdmin ? 'https://old-admin-beta.timeedit.io/v1' : 'https://api-beta.timeedit.io/v1',
    REACT_APP_AUTH_DOMAIN: 'https://auth-beta.timeedit.io',
    TOKEN_NAME: 'teAuthToken',
    CLIENT_ID: 'teAdmin',
    APP_NAME: 'teAdmin',
    VIEWS_URL: `https://views-beta.timeedit.io/v1`,
  },
  staging: {
    REACT_APP_BASE_URL: isOldAdmin ? 'https://old-admin.timeedit.io/v1' : 'https://api.timeedit.io/v1',
    REACT_APP_AUTH_DOMAIN: 'https://auth.timeedit.io',
    TOKEN_NAME: 'teAuthToken',
    CLIENT_ID: 'teAdmin',
    APP_NAME: 'teAdmin',
    VIEWS_URL: `https://views.timeedit.com/v1`,
  },
  production: {
    REACT_APP_BASE_URL: isOldAdmin ? 'https://old-admin.timeedit.net/v1' : 'https://api.timeedit.net/v1',
    REACT_APP_AUTH_DOMAIN: 'https://auth.timeedit.net',
    TOKEN_NAME: 'teAuthToken',
    CLIENT_ID: 'teAdmin',
    APP_NAME: 'teAdmin',
    VIEWS_URL: `https://views.timeedit.com/v1`,
  },
};
