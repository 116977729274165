export enum EReservationKindMemberType {
  ABSTRACT = 'ABSTRACT',
  PHYSICAL = 'PHYSICAL',
}

export type TMemberType = {
  name: string;
  id: number;
  description: string;
  reservationKind: EReservationKindMemberType;
  memberTypes: Array<TMemberTypesItem> | [];
};

export class MemberType {
  static createEmpty = (): TMemberType => ({
    name: '',
    id: 0,
    reservationKind: EReservationKindMemberType.PHYSICAL,
    description: '',
    memberTypes: [],
  });
}

export type TMemberTypesItem = {
  type: number | string | undefined;
  memberLevel: number;
};

export class MemberTypesItem {
  static create = (
    typeId: number | string | undefined,
    memberLevel: number,
  ): TMemberTypesItem => ({
    type: typeId,
    memberLevel: memberLevel,
  });
}
