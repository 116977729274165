import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  publicInfo: {},
};

// Slice
const slice = createSlice({
  name: 'publicInfo',
  initialState,
  reducers: {
    fetchPublicInfoRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchPublicInfoSuccess: (state: any, { payload }) => {
      state.publicInfo = payload;
      finishedLoadingSuccess(state);
    },
    fetchPublicInfoFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selector
export const publicInfoSelector = (state: any) => state.publicInfo.publicInfo;

// Actions
export const {
  fetchPublicInfoRequest,
  fetchPublicInfoSuccess,
  fetchPublicInfoFailure,
} = slice.actions;

export const fetchPublicInfo =
  (customerSignature: string) => async (dispatch: any) => {
    try {
      if (customerSignature !== 'undefined') {
        dispatch(fetchPublicInfoRequest());
        const request = { customerSignature };
        const publicInfo = await api.get({
          endpoint: `/public/info/podhigs`,
          data: { ...request },
        });
        dispatch(fetchPublicInfoSuccess(publicInfo));
      }
    } catch (e) {
      dispatch(fetchPublicInfoFailure());
      return console.error(e);
    }
  };
