import {
  ObjectGeneralInformation,
  ObjectMembers,
  ObjectRelations,
  ObjectAvailabilityRelated,
  ObjectOptionalRelated,
  ObjectOrganizations,
} from '../ObjectsComponent';

type Props = {
  object: any;
  onChange: any;
  onChangeTwoProperties: any;
  limitations?: any;
  objects?: any;
};

export const SingleObjectEditor = ({
  object,
  onChange,
  onChangeTwoProperties,
  limitations,
  objects,
}: Props) => {
  return (
    <>
      <ObjectGeneralInformation
        object={object}
        onChange={onChange}
        onChangeTwoProperties={onChangeTwoProperties}
        limitations={limitations}
        key={object?.id}
      />
      {/*  @todo: Request to the server to get conditionals for which of
                the below ones that shall be available on the object. 
                Will come with the API? */}
      <ObjectMembers
        objectMembers={object?.members}
        onChange={onChange}
        objects={objects}
      />
      <ObjectRelations
        objectRelations={object?.relations}
        onChange={onChange}
        objects={objects}
      />
      <ObjectAvailabilityRelated
        availabilityRelations={object?.availabilityRelations}
        onChange={onChange}
        objects={objects}
        object={object}
      />
      <ObjectOptionalRelated
        objectOptionalRelated={object?.optionalRelations}
        onChange={onChange}
        objects={objects}
        object={object}
      />
      <ObjectOrganizations
        objectOrganizations={object?.organizations}
        onChange={onChange}
      />
    </>
  );
};
