import { THistory } from './dateHistory.type';

export enum EProperty {
  STANDARD = 'STANDARD',
  ABSTRACT = 'ABSTRACT',
  VIRTUAL_STANDARD = 'VIRTUAL_STANDARD',
  VIRTUAL_ABSTRACT = 'VIRTUAL_ABSTRACT',
  TEMPLATE_OBJECT = 'TEMPLATE_OBJECT',
}

export enum EPersonalInformation {
  YES = 'YES',
  NO = 'NO',
  DEFAULT = 'DEFAULT',
}

export type TTEObject = {
  id: number;
  extId: string;
  property: EProperty;
  personalInformation: EPersonalInformation;
  teFields: Array<TTEFieldValue>;
  types: Array<number>;
  created: number;
  createdBy: string;
  history: Array<THistory>;
  active: boolean;
  members: Array<TDateRange>;
  relations: Array<TDateRangeRelations>;
  availabilityRelations: Array<TAvailabilityRelations>;
  optionalRelations: Array<number>;
  organizations: Array<number>;
};

export class TEObject {
  static createEmpty = (
    selectedType: number,
    fieldIds: number[],
  ): TTEObject => ({
    id: 0,
    extId: '',
    property: EProperty.STANDARD,
    personalInformation: EPersonalInformation.DEFAULT,
    teFields: fieldIds.map((id: any) => ({ fieldId: id, values: [''] })),
    types: [selectedType],
    created: parseInt((Date.now() / 1000).toFixed()),
    createdBy: '',
    history: [],
    active: true,
    members: [],
    relations: [],
    availabilityRelations: [],
    optionalRelations: [],
    organizations: [],
  });
}

export type TTEFieldValue = {
  fieldId: number;
  values: Array<any>;
};

export type TDateRange = {
  objectId: number;
  start: number;
  end: number;
};
export class DateRange {
  static create = (dateRange: any) => ({
    objectId: dateRange,
    start: parseInt((Date.now() / 1000).toFixed()),
    end: parseInt((Date.now() / 1000).toFixed()) + 31556926,
  });
}

export type TDateRangeRelations = {
  objectIds: Array<number>;
  start: number;
  end: number;
};

export class DateRangeRelations {
  static create = (objectId: number) => ({
    objectIds: [objectId],
    start: parseInt((Date.now() / 1000).toFixed()),
    end: parseInt((Date.now() / 1000).toFixed()) + 31556926,
  });
}

export type TAvailabilityRelations = {
  availabilityTypeId: number;
  objectIds: Array<number>;
};

export class AvailabilityRelations {
  static create = (availabilityType: number, id: number) => ({
    availabilityTypeId: availabilityType,
    objectIds: [id],
  });
}
