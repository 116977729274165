export const deriveTopLevelSelectedItem = (match) => {
  /**
   * Outcome: get the first part of the route, i.e.,
   * instead of /system/reservation-modes get /system
   **/
  const splitPath = match.path.split('/');
  return `/${splitPath[1]}`;
};

export const isSecondLevelItemSelected = (route, match) => {
  const splitPath = match.path.split('/');
  if (!splitPath[1] || !splitPath[2]) return false;
  return `/${splitPath[1]}/${splitPath[2]}` === route;
};
