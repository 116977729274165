import { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// i18N
import intl from '../../i18n/intl';

// STYLES
import { Collapse, Form, Input, Select, Transfer } from 'antd';

// ACTIONS
import {
  fetchReservationTemplates,
  reservationTemplatesSelector,
} from '../../slices/reservationTemplate.slice';

// TYPES
import { TReservationTemplate } from '../../types/reservation.type';
import {
  ERelationType,
  TRelationType,
  TRelationTypesItem,
} from '../../types/relation.type';

// COMPONENTS
import RelationTypeTable from './RelationTypeTable';
import { TEItem } from '../TEToolTip';

type PropsGeneral = {
  relationType: TRelationType;
  onChange: Function;
  limitations: any;
};

type PropsTypes = {
  relationTypesItems: TRelationTypesItem[];
  onChange: Function;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const RelationTypeGeneralSettings = ({
  relationType,
  onChange,
  limitations,
}: PropsGeneral) => {
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  const onChangeSelectHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
  ) => {
    onChange(property, event);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Form.Item
          tooltip={`(Maximum size of ${limitations.name} bytes)`}
          label={language.name}
        >
          <Input
            size='small'
            defaultValue={relationType.name}
            value={relationType.name}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'name', limitations.name)
            }
          />
        </Form.Item>
        <p className='form-item-text' style={{ marginBottom: '1em' }}>
          {language.id}: {relationType.id}
        </p>
        <Form.Item
          tooltip={`(Maximum size of ${limitations.description} bytes)`}
          label={language.description}
        >
          <Input.TextArea
            size='small'
            rows={5}
            defaultValue={relationType.description}
            value={relationType.description}
            onChange={(value: any) =>
              onChangeInputHandler(
                value,
                'description',
                limitations.description,
              )
            }
          />
        </Form.Item>
        <Form.Item label={`${language.relation_type}`}>
          <Select
            size='small'
            onChange={(value: any) =>
              onChangeSelectHandler(value, 'relationType')
            }
            value={relationType.relationType}
          >
            {Object.entries(ERelationType).map(([value, key]) => (
              <Select.Option key={key} value={value}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const RelationTypesItems = ({
  relationTypesItems,
  onChange,
}: PropsTypes) => {
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.types} key='2'>
        <RelationTypeTable
          relationTypesItems={relationTypesItems}
          onTypesChanged={onChange}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */
type PropsConnections = {
  reservationTemplatesConnections: Array<number>;
  onChange: Function;
};

export const RelationTypeReservationTemplates = ({
  reservationTemplatesConnections,
  onChange,
}: PropsConnections) => {
  // Here we make an API-request for fetching all available Reservation templates
  const dispatch = useDispatch();
  const reservationTemplates: TReservationTemplate[] = useSelector(
    reservationTemplatesSelector,
  );

  useEffect(() => {
    dispatch(fetchReservationTemplates());
  }, [dispatch]);

  // Data with ALL available res.templates
  const dataSource = reservationTemplates.map((template: any) => ({
    key: String(template.id),
    title: template.name,
    description: template.description,
  }));

  // The choosen ones
  const targetKeys = reservationTemplatesConnections?.map(
    (selectedtemplate: any) => String(selectedtemplate),
  );

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const onSelection = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChange = (nextTargetKeys: any) => {
    onChange(
      'reservationTemplates',
      nextTargetKeys.map((key: string) => parseInt(key)),
    );
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.reservation_templates} key='2'>
        <Form.Item label={`${language.reservation_templates}`}>
          <Transfer
            dataSource={dataSource}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onSelectionChange}
            onSelectChange={onSelection}
            render={(item) => item.title}
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};
