import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

// HOOKS
import { useAuth, AuthStatus } from '../../hooks/useAuth';
import { useQuery } from '../../hooks/useQuery';

// REDUX
import { userSelector } from '../../slices/auth.slice';

// SERVICES
import TokenService from '../../services/token.service';

// COMPONENTS
import MainLayout from '../MainLayout';

const tokenService = TokenService.getService();

const renderLogin = (customerSignature) =>
  window.location.replace(`/login?customerSignature=${customerSignature}`);

const getCustomerSignature = (query, tokenData, tokenService) => {
  // First try to get customer signature from query
  const customerSignatureFromQuery = query.get('customerSignature');
  if (customerSignatureFromQuery) return customerSignatureFromQuery;

  // Try the redux state
  if (tokenData && tokenData.cus) return tokenData.cus;

  // If not present on query, try to get it from the token
  const token = tokenService.getToken();
  if (!token) return undefined;
  const tokenContent = jwtDecode(token);
  if (tokenContent.cus) return tokenContent.cus;
  return undefined;
};

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ children, ...rest }) => {
  const authStatus = useAuth(false);
  const query = useQuery();
  const tokenData = useSelector(userSelector);

  const customerSignature = getCustomerSignature(
    query,
    tokenData,
    tokenService,
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authStatus === AuthStatus.NOT_AUTHENTICATED)
          return renderLogin(customerSignature);
        return <MainLayout>{children}</MainLayout>;
      }}
    />
  );
};

export default PrivateRoute;
