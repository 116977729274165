import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// ACTIONS
import { userSelector, login } from './slices/auth.slice';

// COMPONENTS
import PrivateRoute from './components/PrivateRoute';

// PAGES
import HomePage from './pages/homepage';
import SignInPage from './components/signIn';
import SystemPage from './pages/system';
import ReservationModePage from './pages/system/reservationModes';
import ReservationTemplatesPage from './pages/system/reservationTemplates';
import TypesPage from './pages/system/types';
import FieldsPage from './pages/system/fields';
import MemberTypesPage from './pages/system/memberTypes';
import RelationTypesPage from './pages/system/relationTypes';
import FieldRelationsPage from './pages/system/fieldRelations';
import AvailabilityTypesPage from './pages/system/availabilityTypes';
import TimeRulesPage from './pages/system/timeRules';
import PeriodsPage from './pages/system/periods';
import HolidayReservationsPage from './pages/system/holidayReservations';
import SystemPreferencesPage from './pages/system/systemPreferences';
import MaintenancePage from './pages/system/maintenance';
import OrganizationsUsersPage from './pages/organizationsAndUsers';
import OrganizationsPage from './pages/organizationsAndUsers/organizations';
import RolesAndPermissionsPage from './pages/organizationsAndUsers/rolesAndPermissions';
import UserManagerPage from './pages/organizationsAndUsers/userManager';
import AuthenticationPage from './pages/organizationsAndUsers/authentication';
import ObjectPage from './pages/objectSettings';
import ObjectManagerPage from './pages/objectSettings/objectManager';
import ChooseColorsPage from './pages/objectSettings/chooseColors';
import ConvertColorsPage from './pages/objectSettings/convertColors';
import APIandServicesPage from './pages/apiAndServices';
import ServicesPage from './pages/apiAndServices/services';
import APIPage from './pages/apiAndServices/api';
import PaddingPage from "./pages/system/paddingRules";

// STYLES
import 'antd/dist/antd.less';
import './App.scss';

const App = () => {
  const dispatch = useDispatch();

  // Fetching the customerSignature from auth-slice
  const customerSignature = useSelector(userSelector);

  useEffect(() => {
    dispatch(login);
  }, [dispatch]);

  return (
    <Router>
      <Switch>
        <Route exact path='/login'>
          <SignInPage />
        </Route>
        <PrivateRoute exact path='/'>
          <HomePage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system'>
          <SystemPage />
        </PrivateRoute>
        <PrivateRoute exact path='/system/reservation-modes'>
          <ReservationModePage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/reservation-templates'>
          <ReservationTemplatesPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/types'>
          <TypesPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/fields'>
          <FieldsPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/member-types'>
          <MemberTypesPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/relation-types'>
          <RelationTypesPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/field-relations'>
          <FieldRelationsPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/availability-types'>
          <AvailabilityTypesPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/time-rules'>
          <TimeRulesPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/periods'>
          <PeriodsPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/holidays'>
          <HolidayReservationsPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/system-preferences'>
          <SystemPreferencesPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/maintenance'>
          <MaintenancePage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/system/padding-rules'>
          <PaddingPage
              customerSignature={
                customerSignature !== null ? customerSignature.cus : ''
              }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/organizations-users'>
          <OrganizationsUsersPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/organizations-users/organizations'>
          <OrganizationsPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/organizations-users/roles-and-permissions'>
          <RolesAndPermissionsPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/organizations-users/user-manager'>
          <UserManagerPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/organizations-users/authentication'>
          <AuthenticationPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/object-settings'>
          <ObjectPage />
        </PrivateRoute>
        <PrivateRoute exact path='/object-settings/object-manager'>
          <ObjectManagerPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/object-settings/colors'>
          <ChooseColorsPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/object-settings/convert-colors'>
          <ConvertColorsPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/api-services'>
          <APIandServicesPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/api-services/services'>
          <ServicesPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
        <PrivateRoute exact path='/api-services/api'>
          <APIPage
            customerSignature={
              customerSignature !== null ? customerSignature.cus : ''
            }
          />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default App;
