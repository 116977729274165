// i18N
import intl from '../../i18n/intl';

// STYLES
import { Modal, Select } from 'antd';

type Props = {
  title: string;
  okText: string;
  onOK: any;
  onCancel: any;
  visible: boolean;
  value: any;
  array: Array<any>;
  update: any;
};

const language: any = intl.messages;

export const SelectMultipleModal = ({
  title,
  okText,
  onOK,
  onCancel,
  visible,
  value,
  array,
  update,
}: Props) => {
  return (
    <Modal
      title={title}
      okText={okText}
      cancelText={language.cancel}
      onCancel={onCancel}
      width='700px'
      onOk={onOK}
      visible={visible}
    >
      <Select
        style={{ width: 180 }}
        mode='multiple'
        value={value}
        onChange={update}
        key={title}
      >
        {array}
      </Select>
    </Modal>
  );
};
