// i18N
import intl from '../../i18n/intl';

// STYLES
import { Form, Button, List } from 'antd';

const language: any = intl.messages;

export const ServicesComponent = () => {
  const [form] = Form.useForm();
  return (
    <Form layout='vertical' form={form} style={{ padding: '20px' }}>
      <div>
        <Button type='primary' danger style={{ margin: 2 }}>
          {language.clear}
        </Button>
        <Button type='primary' style={{ margin: 2 }}>
          {language.update}
        </Button>
        <Button
          type='primary'
          style={{ background: '#0e5c67', border: '#0e5c67', margin: 2 }}
        >
          {language.reinit_services}
        </Button>
      </div>
      <List size='small' bordered style={{ marginTop: 10 }}></List>
    </Form>
  );
};
