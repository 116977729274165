// i18N
import intl from '../../i18n/intl';

// STYLES
import { Collapse, Form, Input } from 'antd';
import { ChangeEvent } from 'react';

// COMPONENTS
import { TEToolTip } from '../TEToolTip';

/* ------------------------------------------------------------------------------------- */
type Props = {
  documentation: any;
  onChange: any;
  limitationValue?: any;
};

const language: any = intl.messages;

const Documentation = ({ documentation, onChange, limitationValue }: Props) => {
  // EVENT HANDLERS
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  return (
    <Collapse defaultActiveKey={['4']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel
        header={
          <TEToolTip helpText={`(Maximum size of ${limitationValue} bytes)`}>
            <span>{language.documentation}</span>
          </TEToolTip>
        }
        key='2'
      >
        <div className='inside--wrapper'>
          <Form.Item>
            <Input.TextArea
              size='small'
              rows={10}
              defaultValue={documentation}
              value={documentation}
              onChange={(value: any) =>
                onChangeInputHandler(value, 'documentation', limitationValue)
              }
            />
          </Form.Item>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default Documentation;
