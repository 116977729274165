export type TTimeZone = {
  id: number;
  shortName: string;
  name: string;
  timeZone: string;
  daylightSavingsTime: string;
  isDefault: boolean;
  offset: string;
};

export class TimeZone {
  static createEmpty = (): TTimeZone => ({
    id: 0,
    shortName: '',
    name: '',
    timeZone: '',
    daylightSavingsTime: '',
    isDefault: true,
    offset: '',
  });
}

export class NewTimeZone {
  static create = (zoneId: string): TTimeZone => ({
    id: 0,
    shortName: '',
    name: '',
    timeZone: zoneId,
    daylightSavingsTime: '',
    isDefault: false,
    offset: '',
  });
}

export type TZone = {
  id: string;
  name: string;
};

export type TDst = {
  id: string;
  name: string;
};
