import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TSystemPreferencesMisc } from '../types/systempreferences.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  limitations: {},
  systemPreferences: {},
  updatedSystemPreferences: {},
};

// Slice
const slice = createSlice({
  name: 'systemPreferences',
  initialState,
  reducers: {
    fetchSystemPreferencesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchSystemPreferencesSuccess: (state: any, { payload }) => {
      state.systemPreferences = payload;
      state.updatedSystemPreferences = payload;
      finishedLoadingSuccess(state);
    },
    fetchSystemPreferencesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    fetchLimitationsSystemPreferencesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    setsUpdatedSystemPreferences: (state: any, { payload }) => {
      state.updatedSystemPreferences = payload;
    },
    updateSystemPreferencesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateSystemPreferencesSuccess: (state: any, { payload }) => {
      state.systemPreferences = payload;
      state.updatedSystemPreferences = payload;
      finishedLoadingSuccess(state);
    },
    updateSystemPreferencesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const systemPreferencesLoading = (state: any) =>
  state.systemPreferences.loading;
export const systemPreferencesSelector = (state: any) =>
  state.systemPreferences.systemPreferences as TSystemPreferencesMisc;
export const updatedSystemPreferencesSelector = (state: any) =>
  state.systemPreferences.updatedSystemPreferences;
export const limitationsSystemPreferencesSelector = (state: any) =>
  state.systemPreferences.limitations;

// Actions
export const {
  fetchSystemPreferencesRequest,
  fetchSystemPreferencesSuccess,
  fetchSystemPreferencesFailure,
  fetchLimitationsSystemPreferencesSuccess,
  setsUpdatedSystemPreferences,
  updateSystemPreferencesRequest,
  updateSystemPreferencesSuccess,
  updateSystemPreferencesFailure,
} = slice.actions;

export const fetchSystemPreferences = () => async (dispatch: any) => {
  try {
    dispatch(fetchSystemPreferencesRequest());
    const systemPreferences = await api.get({
      endpoint: `/system-preferences`,
    });
    dispatch(fetchSystemPreferencesSuccess(systemPreferences));
  } catch (e) {
    dispatch(fetchSystemPreferencesFailure());
    return console.error(e);
  }
};

export const fetchLimitationsForSystemPreferences =
  () => async (dispatch: any) => {
    try {
      dispatch(fetchSystemPreferencesRequest());
      const limitationsSystemPreferences = await api.get({
        endpoint: `/system-preferences/limitations`,
      });
      dispatch(
        fetchLimitationsSystemPreferencesSuccess(limitationsSystemPreferences),
      );
    } catch (e) {
      dispatch(fetchSystemPreferencesFailure());
      return console.error(e);
    }
  };

export const setUpdatedSystemPreferences =
  (systemPreferences: TSystemPreferencesMisc) => async (dispatch: any) => {
    dispatch(setsUpdatedSystemPreferences(systemPreferences));
  };

export const updateSystemPreferences =
  (systemPreferences: TSystemPreferencesMisc) => async (dispatch: any) => {
    try {
      dispatch(updateSystemPreferencesRequest());
      const { ...SystemPreferencesBody } = systemPreferences;
      const response = await api.patch({
        endpoint: `/system-preferences`,
        data: { ...SystemPreferencesBody },
      });
      dispatch(updateSystemPreferencesSuccess(response));
    } catch (e: any) {
      dispatch(updateSystemPreferencesFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
