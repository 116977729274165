import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import jwtDecode from 'jwt-decode';
import TokenService from '../services/token.service';
import { configService } from '../services/config.service';
import { IState } from '../types/state.interface';

// SLICES
import { fetchLimitations } from './limitations.slice';
import { fetchLanguages } from './language.slice';
import { fetchReservationModes } from './reservationMode.slice';
import { fetchReservationTemplates } from './reservationTemplate.slice';
import { fetchTypes } from './type.slice';
import { fetchFields } from './field.slice';
import { fetchMemberTypes } from './memberType.slice';
import { fetchRelationTypes } from './relationType.slice';
import { fetchFieldRelations } from './fieldRelation.slice';
import { fetchAvailabilityTypes } from './availabilityType.slice';
import { fetchTimeRules } from './timeRule.slice';
import { fetchPeriods } from './period.slice';
import { fetchHolidayRegions } from './holidayReservation.slice';
import { fetchSupportInfo } from './supportInfo.slice';
import { fetchEmailPreferences } from './emailPreferences.slice';
import { fetchSystemPreferences } from './systemPreferences.slice';
import { fetchPasswordRules } from './passwordRules.slice';
import { fetchUserPreferences } from './userPreferences.slice';
import { fetchTimeZones, fetchZones, fetchDsts } from './timeZone.slice';
import { fetchMaintenance } from './maintenance.slice';
import { fetchSystemActions } from './systemActions.slice';
import { fetchObjects } from './object.slice';
import { fetchOrganizations } from './organization.slice';
import { fetchOrganizationTreeNodes } from './organizationNode.slice';
import { fetchUsers } from './user.slice';
import { fetchRoles } from './role.slice';
import { fetchApis } from './api.slice';
import { fetchAuthenticationTokens } from './authenticationToken.slice';
import { fetchAuthenticationConfigurations } from './authConfig.slice';

const tokenService = TokenService.getService();

const slice = createSlice({
  name: 'auth',
  initialState: {
    loading: true,
    hasErrors: false,
    tokenData: null,
    refreshToken: null,
  },
  reducers: {
    onFetchTokenSuccess: (state, { payload: token }) => {
      if (!token) {
        state.hasErrors = true;
        state.loading = false;
        state.tokenData = null;
        return;
      }
      state.hasErrors = false;
      state.loading = false;
      state.tokenData = token;
    },
    createLoginRequest: (state: any) => {
      state.loading = false;
    },
    onLogout: (state) => {
      state.hasErrors = false;
      state.loading = false;
      state.tokenData = null;
    },
  },
});

export default slice.reducer;

// Selectors
export const authSelector = (state: IState) => state.auth;
export const userSelector = (state: IState) => state.auth.tokenData;
export const signedInWithRightCustomerSignature =
  (
    customerSignature: string | null,
    verifyCustomerSignature: boolean = false,
  ) =>
  (state: IState) => {
    /**
     * Verify that:
     * 1) Token data property exists
     * 2) tokenData.cus === customerSignature
     */
    if (!state.auth.tokenData || !state.auth.tokenData.cus) return false;

    if (!verifyCustomerSignature) return true;

    if (!customerSignature) return false;

    if (state.auth.tokenData.cus === customerSignature) return true;

    return false;
  };

export const authLoadingSelector = (state: IState) => state.auth.loading;
export const authErrorSelector = (state: IState) => state.auth.hasErrors;

export const { onFetchTokenSuccess, onLogout, createLoginRequest } =
  slice.actions;

export const login =
  (username: string, password: string, customerSignature: string) =>
  async (dispatch: any) => {
    try {
      // Send username, password, customerSignature + clientId to backend
      dispatch(createLoginRequest());

      const response = await api.post({
        endpoint: `/auth/login`,
        data: {
          username,
          password,
          customerSignature,
          clientId: configService.CLIENT_ID,
        },
        requiresAuth: false,
      });

      // Get token from response
      const { accessToken }: { accessToken: string } = response;
      // Set token in token service (local storage)
      tokenService.setToken(accessToken);
      // Decode the token
      const decodedToken = jwtDecode(accessToken);
      // Return success call
      dispatch(onFetchTokenSuccess(decodedToken));
      // Fetch initial data
      dispatch(fetchInitialData());
    } catch (error: any) {
      dispatch(onLogout());
      console.error(error.message);
    }
  };

export const fetchInitialData = () => async (dispatch: any) => {
  dispatch(fetchLimitations());
  dispatch(fetchLanguages());
  dispatch(fetchReservationModes());
  dispatch(fetchReservationTemplates());
  dispatch(fetchTypes());
  dispatch(fetchFields());
  dispatch(fetchMemberTypes());
  dispatch(fetchRelationTypes());
  dispatch(fetchFieldRelations());
  dispatch(fetchAvailabilityTypes());
  dispatch(fetchTimeRules());
  dispatch(fetchPeriods());
  dispatch(fetchHolidayRegions());
  dispatch(fetchSupportInfo());
  dispatch(fetchEmailPreferences());
  dispatch(fetchSystemPreferences());
  dispatch(fetchPasswordRules());
  dispatch(fetchUserPreferences());
  dispatch(fetchTimeZones());
  dispatch(fetchZones());
  dispatch(fetchDsts());
  dispatch(fetchMaintenance());
  dispatch(fetchSystemActions());
  dispatch(fetchObjects());
  dispatch(fetchOrganizations());
  dispatch(fetchOrganizationTreeNodes());
  dispatch(fetchUsers());
  dispatch(fetchRoles());
  dispatch(fetchApis());
  dispatch(fetchAuthenticationTokens());
  dispatch(fetchAuthenticationConfigurations());
};
