// TYPES
import { TTEFieldValue } from '../types/object.type';
import { TField } from '../types/field.type';
import { TType } from '../types/type.type';

export const isFieldOnObject = (allFields: TTEFieldValue[], id: number) => {
  let existing = false;
  allFields.forEach((f: TTEFieldValue) => {
    if (f.fieldId === id) {
      existing = true;
    }
  });
  return existing;
};

export const isFieldIdOnObject = (allFields: number[], id: number) => {
  let existing = false;
  allFields.forEach((f: number) => {
    if (f === id) {
      existing = true;
    }
  });
  return existing;
};

export const getNonReferenceFields = (
  fields: TField[],
  type: TType | undefined,
) => {
  if (type !== undefined) {
    const parentsOnType: number[] = type?.parentFields;
    const fieldsOnType: number[] = type?.fields;
    const allFields: number[] = parentsOnType?.concat(fieldsOnType);
    const getFieldType = (fieldId: number) => {
      const result = fields.find((f: TField) => f.id === fieldId);
      if (result) {
        return { id: result.id, fieldType: result.fieldType };
      }
      return null;
    };
    const fieldWithFieldType = allFields?.map((field: number) =>
      getFieldType(field),
    );
    const excludeReference = fieldWithFieldType?.filter(
      (field: any) => field?.fieldType !== 'reference',
    );
    return excludeReference?.map((field: any) => field?.id);
  }
};
