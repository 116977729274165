import { useDispatch } from 'react-redux';
import { SAVE_CHANGES_CLASSNAME } from '../../hooks/useOutsideClick';

// STYLES
import { Button } from 'antd';
import './index.scss';
import { useState } from 'react';

type Props = {
  title: string;
  isDisabled: boolean;
  isDanger: boolean;
  action: any;
  displayProp: boolean;
  isSaveChangesBtn?: boolean;
};

const ActionButton = ({
  title,
  action,
  isDisabled,
  isDanger,
  displayProp,
  isSaveChangesBtn = false,
}: Props) => {
  const dispatch = useDispatch();

  const [hidden] = useState(false);
  const classNames = [];
  if (hidden === true) {
    classNames.push('hidden');
  }

  return (
    <>
      <Button
        hidden={displayProp}
        danger={isDanger}
        type='default'
        htmlType='submit'
        key={title}
        onClick={(event) => dispatch(action(event))}
        disabled={isDisabled}
        className={`${classNames.join(` `)} ${
          isSaveChangesBtn ? SAVE_CHANGES_CLASSNAME : ''
        }`}
      >
        {title}
      </Button>
    </>
  );
};

export default ActionButton;
