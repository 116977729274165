import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TPasswordRules } from '../types/systempreferences.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  limitations: {},
  passwordRules: {},
  updatedPasswordRules: {},
};

// Slice
const slice = createSlice({
  name: 'passwordRules',
  initialState,
  reducers: {
    fetchPasswordRulesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchPasswordRulesSuccess: (state: any, { payload }) => {
      state.passwordRules = payload;
      state.updatedPasswordRules = payload;
      finishedLoadingSuccess(state);
    },
    fetchPasswordRulesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    fetchLimitationsPasswordRulesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    setsUpdatedPasswordRules: (state: any, { payload }) => {
      state.updatedPasswordRules = payload;
    },
    updatePasswordRulesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updatePasswordRulesSuccess: (state: any, { payload }) => {
      state.passwordRules = payload;
      state.updatedPasswordRules = payload;
      finishedLoadingSuccess(state);
    },
    updatePasswordRulesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const passwordRulesLoading = (state: any) =>
  state.emailPreferences.loading;
export const passwordRulesSelector = (state: any) =>
  state.passwordRules.passwordRules as TPasswordRules;
export const updatedPasswordRulesSelector = (state: any) =>
  state.passwordRules.updatedPasswordRules;
export const limitationsPasswordRulesSelector = (state: any) =>
  state.passwordRules.limitations;

// Actions
export const {
  fetchPasswordRulesRequest,
  fetchPasswordRulesSuccess,
  fetchPasswordRulesFailure,
  fetchLimitationsPasswordRulesSuccess,
  setsUpdatedPasswordRules,
  updatePasswordRulesRequest,
  updatePasswordRulesSuccess,
  updatePasswordRulesFailure,
} = slice.actions;

export const fetchPasswordRules = () => async (dispatch: any) => {
  try {
    dispatch(fetchPasswordRulesRequest());
    const passwordRules = await api.get({
      endpoint: `/password-rules`,
    });
    dispatch(fetchPasswordRulesSuccess(passwordRules));
  } catch (e) {
    dispatch(fetchPasswordRulesFailure());
    return console.error(e);
  }
};

export const fetchLimitationsForPasswordRules = () => async (dispatch: any) => {
  try {
    dispatch(fetchPasswordRulesRequest());
    const limitationsPasswordRules = await api.get({
      endpoint: `/password-rules/limitations`,
    });
    dispatch(fetchLimitationsPasswordRulesSuccess(limitationsPasswordRules));
  } catch (e) {
    dispatch(fetchPasswordRulesFailure());
    return console.error(e);
  }
};

export const setUpdatedPasswordRules =
  (passwordRules: TPasswordRules) => async (dispatch: any) => {
    dispatch(setsUpdatedPasswordRules(passwordRules));
  };

export const updatePasswordRules =
  (passwordRules: TPasswordRules) => async (dispatch: any) => {
    try {
      dispatch(updatePasswordRulesRequest());
      const { ...passwordRulesBody } = passwordRules;
      const response = await api.patch({
        endpoint: `/password-rules`,
        data: { ...passwordRulesBody },
      });
      dispatch(updatePasswordRulesSuccess(response));
    } catch (e: any) {
      dispatch(updatePasswordRulesFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
