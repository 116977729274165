import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';
import _ from 'lodash';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form, Menu } from 'antd';

// TYPES
import { TType, TTreeListData } from '../../../types/type.type';

// ACTIONS
import {
  createType,
  deleteType,
  updateType,
  createEmptyType,
  selectedTypeSelector,
  setSelectedType,
  fetchLanguagesTypes,
  fetchLanguagesTypesSelector,
  fetchLimitationsTypesSelector,
  fetchLimitationsTypes,
  fetchExternalOwners,
  fetchExternalOwnersSelector,
  deleteTypeSelector,
  typesSelector,
  fetchTypes,
  sortedTypesSelector,
  advancedTypesSelector,
  fetchAdvancedSettingsForType,
} from '../../../slices/type.slice';
import {
  fetchReservationTemplatesForOtherPages,
  reservationTemplatesForOtherPagesSelector,
} from '../../../slices/reservationTemplate.slice';

// TYPES
import {
  TReservationTemplate,
  TTemplateType,
} from '../../../types/reservation.type';

// UTILS
import { timeConverter } from '../../../utils/timeConverter';
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

// COMPONENTS
import { ListSearch } from '../../../components/ListSearch';
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import TreeListView from '../../../components/List/TreeListView';
import {
  TypesGeneralSettings,
  TypesPropertiesSubtypes,
  TypesFields,
  TypesOrganizationsTemplates,
} from '../../../components/TypesComponents';
import LanguagePreferences from '../../../components/LanguagePreferences';
import Documentation from '../../../components/Documentation';
import Advanced, { ActionSettings } from '../../../components/Advanced';

type Props = {
  customerSignature: string;
};

const isTypeDisabled = (typeId: number, sortedTypes: TType[]) => {
  if (sortedTypes.length > 0) {
    return sortedTypes.map((type: TType) => type.id).indexOf(typeId) === -1;
  } else {
    return false;
  }
};

// Function for creating the type hierarchy tree
export const createTypeTree = (
  current: any,
  types: TType[],
  sortedTypes: TType[],
  sortQuery?: any,
) => {
  const node: TTreeListData = {
    title: current
      ? current[sortQuery === 'parentExtId' ? 'extId' : 'name']
      : '',
    key: String(current?.id),
    children: [],
    disabled: isTypeDisabled(current?.id, sortedTypes),
  };
  if (current?.subTypes) {
    current.subTypes.forEach(() => {
      node.children.push(
        createTypeTree(types.shift(), types, sortedTypes, sortQuery),
      );
    });
  }
  return node;
};

// Functions and variables to find out which ones that will be the possible alias types per selected type.
// All the parents + the selected type itself
export const isChildOf = (type: TType, children: TTreeListData[]): boolean => {
  let found = false;
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (parseInt(child.key) === type.id) {
      found = true;
    } else if (!found && child.children.length > 0) {
      found = isChildOf(type, child.children);
    }
  }
  return found;
};

export const findMyParents = (
  type: TType,
  tree: TTreeListData,
  result: number[],
) => {
  if (isChildOf(type, tree.children)) {
    result.push(parseInt(tree.key));
    tree.children.forEach((child: TTreeListData) => {
      findMyParents(type, child, result);
    });
  }
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

const TypesPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const types = useSelector(typesSelector);
  const sortedTypes = useSelector(sortedTypesSelector);
  const languagesTypes = useSelector(fetchLanguagesTypesSelector);
  const limitations = useSelector(fetchLimitationsTypesSelector);
  const externalOwners = useSelector(fetchExternalOwnersSelector);
  const selectedType: TType = useSelector(selectedTypeSelector);
  const errorMessage = useSelector(deleteTypeSelector);
  const reservationTemplates: TReservationTemplate[] = useSelector(
    reservationTemplatesForOtherPagesSelector,
  );
  const advancedSettings: ActionSettings[] = useSelector(advancedTypesSelector);

  // State for setting error messages
  const [error, setError] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState(false);

  const [sortOption, setSortOption] = useState<string>('sort');
  const [sortQuery, setSortQuery] = useState<string | number>('parentName');
  const [reservationTemplateOption, setReservationTemplateOption] =
    useState<string>();
  const [reservationTemplateQuery, setReservationTemplateQuery] = useState<
    number | undefined
  >();
  const [searchTextOption, setSearchTextOption] = useState<string>();
  const [searchTextQuery, setSearchTextQuery] = useState<string>();

  // Creating the type-hierarchy tree
  const treeTypes = [...types] || [];
  let treeListData: TTreeListData[] = [{ title: '', key: '', children: [] }];
  if (treeTypes.length > 0) {
    treeListData = [
      createTypeTree(treeTypes?.shift(), treeTypes, sortedTypes, sortQuery),
    ];
  }

  const onTypeSelected = (selectedValue: any) => {
    const currentType = types.find(
      (type: TType) => type.id === parseInt(selectedValue),
    );
    if (currentType) {
      dispatch(setSelectedType(currentType));
      setError(false);
    }
  };

  // When searching with optional text string
  const onSearch = (value: string) => {
    setSearchTextOption('searchText');
    setSearchTextQuery(value);
  };

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/types`,
          label: `${language.types}`,
        },
        {
          path: `/${selectedType.id}`,
          label: `${selectedType.name}`,
        },
      ]),
    );
    setErrorMessageState(false);
  }, [dispatch, selectedType.id, customerSignature, selectedType.name]);

  useEffect(() => {
    dispatch(fetchLanguagesTypes());
    dispatch(
      fetchTypes(
        sortOption,
        sortQuery,
        reservationTemplateOption,
        reservationTemplateQuery,
        searchTextOption,
        searchTextQuery,
      ),
    );
    dispatch(fetchLimitationsTypes());
    dispatch(fetchExternalOwners());
    dispatch(fetchReservationTemplatesForOtherPages());
    if (selectedType.id !== 0) {
      dispatch(fetchAdvancedSettingsForType(selectedType));
    }
  }, [
    dispatch,
    selectedType.id,
    sortOption,
    sortQuery,
    reservationTemplateOption,
    reservationTemplateQuery,
    searchTextOption,
    searchTextQuery,
  ]);

  useEffect(() => {
    if (selectedType && selectedType.id !== 0) {
      dispatch(fetchAdvancedSettingsForType(selectedType));
    }
  }, [dispatch, selectedType]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabled(selectedType, types));
  }, [types, selectedType]);

  const [form] = Form.useForm();

  // EVENT HANDLERS
  // Adds new type
  const onPlusButton = () => {
    dispatch(createEmptyType());
  };

  // Deletes choosen type
  const onDeleteButton = () => {
    dispatch(deleteType(selectedType));
    if (errorMessage === {}) {
      setErrorMessageState(false);
    } else {
      setErrorMessageState(true);
    }
  };

  // Excluding the selected type
  const otherTypes = types.filter((type) => type !== selectedType);

  // Save changes function
  const onSaveChangesTypeButton = () => {
    if (selectedType.id === 0) {
      if (
        _.some(otherTypes, (type: TType) => type.extId === selectedType.extId)
      ) {
        setError(true);
      } else {
        dispatch(createType(selectedType));
        setError(false);
      }
    } else {
      dispatch(updateType(selectedType));
      setError(false);
    }
  };

  const onHandleChange = (property: string, value: any) => {
    dispatch(
      setSelectedType({
        ...selectedType,
        [property]: value,
      }),
    );
    form.setFieldsValue({
      aliasType: value,
      externalOwner: value,
    });
  };

  // Discard changes function
  const onDiscard = () => {
    onTypeSelected(selectedType.id);
  };

  const parents: Array<number> = [];
  findMyParents(selectedType, treeListData[0], parents);
  const aliasTypes = parents;
  const aliasTypesExcludingRootType = aliasTypes?.filter(
    (type: number) => type !== types[0]?.id,
  );
  //  Excluding types if any of aliasTypesExcludingRootType is included on a reservationtemplate
  const typesUsedOnReservationTemplates = reservationTemplates.map(
    (reservationTemplate: TReservationTemplate) =>
      reservationTemplate.types.map((type: TTemplateType) => type.type),
  );
  const typesUsedOnResTemplatesUniq = _.uniq(
    typesUsedOnReservationTemplates?.flat(),
  );
  // Exclude the types used on any reservation template and add the current type
  const aliasTypesToChooseFrom = aliasTypesExcludingRootType
    ?.filter(
      (type: number) => typesUsedOnResTemplatesUniq?.indexOf(type) === -1,
    )
    .concat(selectedType.id);

  const sortArray = [
    { value: 'parentName', label: 'name' },
    { value: 'parentExtId', label: 'extId' },
  ];

  const menu = (
    <Menu
      onClick={(event: any) => {
        if (event.domEvent.target.id === 'sort') {
          setSortOption(event.domEvent.target.id);
          setSortQuery(event.key);
        } else if (event.domEvent.target.id === 'reservationTemplate') {
          setReservationTemplateOption(event.domEvent.target.id);
          setReservationTemplateQuery(parseInt(event.key));
          if (event.key === 'item_1-menu-item_0') {
            setReservationTemplateQuery(undefined);
          }
        }
      }}
      className='list-search--menu'
    >
      <Menu.SubMenu title={language.sort_on}>
        {sortArray.map((sort: any) => (
          <Menu.Item
            key={sort.value}
            id={'sort'}
            className='list-search--menu-item'
          >
            {language[sort.label]}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu title={language.reservation_template}>
        <Menu.Item
          key={undefined}
          id={'reservationTemplate'}
          className='list-search--menu-item'
        >
          {language.all}
        </Menu.Item>
        {reservationTemplates.map(
          (reservationTemplate: TReservationTemplate) => (
            <Menu.Item
              key={reservationTemplate.id}
              id='reservationTemplate'
              className='list-search--menu-item'
            >
              {reservationTemplate.name}
            </Menu.Item>
          ),
        )}
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <>
      <ListView
        sectionHeading={language.types}
        number={types.length}
        onSelect={onPlusButton}
      >
        <ListSearch onSearch={onSearch} menu={menu} />
        {sortedTypes.length > 0 && (
          <p style={{ marginBottom: 0, paddingLeft: '8px' }}>
            Search result: {sortedTypes.length}
          </p>
        )}
        <TreeListView
          data={treeListData}
          onSelect={onTypeSelected}
          automaticallyExpand
        />
      </ListView>
      <div className='inner-content--wrapper'>
        <SectionHeader
          listChoice={
            selectedType.name ? `${selectedType.name}` : `${language.type}`
          }
          history={selectedType?.history}
          modifiedDate={
            selectedType.history?.length > 0
              ? timeConverter(selectedType?.history[0]?.modified)
              : ''
          }
          modifiedBy={
            selectedType.history?.length > 0
              ? selectedType?.history[0]?.modifiedBy
              : ''
          }
          changeHandler={onSaveChangesTypeButton}
          discardHandler={onDiscard}
          displayProp={false}
          error={
            error === true
              ? `${language.extid_type_already_exists}`
              : errorMessageState === true
              ? `${errorMessage}`
              : null
          }
          isDisabled={saveDisabled}
        />
        <Form layout='vertical' form={form}>
          <Documentation
            documentation={selectedType.documentation}
            onChange={onHandleChange}
            limitationValue={limitations.documentation}
          />
          <TypesGeneralSettings
            type={selectedType}
            onChange={onHandleChange}
            limitations={limitations}
            onDelete={onDeleteButton}
          />
          <LanguagePreferences
            languages={languagesTypes}
            altDesignations={selectedType.altDesignations}
            onChange={onHandleChange}
            limitationName={limitations.name}
            limitationDescription={limitations.description}
          />
          <TypesPropertiesSubtypes
            type={selectedType}
            types={types}
            aliasTypes={aliasTypesToChooseFrom}
            subTypes={selectedType.subTypes}
            onChange={onHandleChange}
            externalOwners={externalOwners}
            parents={parents}
          />
          <TypesFields
            fieldsChoosen={selectedType.fields}
            parentFields={selectedType.parentFields}
            onChange={onHandleChange}
          />
          <TypesOrganizationsTemplates
            organizations={selectedType.organizations}
            templates={selectedType.reservationTemplates}
            onChange={onHandleChange}
          />
          <Advanced
            options={advancedSettings}
            selectedUrl={'types'}
            selectedId={selectedType.id}
          />
        </Form>
      </div>
    </>
  );
};

export default TypesPage;
