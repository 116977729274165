export type TRole = {
  name: string;
  id: number;
  description: string;
};

export class Role {
  static createEmpty = (): TRole => ({
    name: '',
    id: 0,
    description: '',
  });
}
