import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useCallback } from 'react';
import _ from 'lodash';

// REDUX
import {
  fetchTypes,
  sortedTypesSelector,
  typesSelector,
} from '../../../slices/type.slice';
import {
  availabilityTypesSelector,
  fetchAvailabilityTypes,
} from '../../../slices/availabilityType.slice';
import {
  fetchMemberTypes,
  memberTypesSelector,
} from '../../../slices/memberType.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import './index.scss';
import 'antd/dist/antd.css';
import {
  Space,
  Button,
  Popconfirm,
  Form,
  Checkbox,
  Input,
  Radio,
  Select,
  InputNumber,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';

// TYPES
import {
  EReservationKind,
  EMandatoryProperty,
  EObjectProperty,
  ETemplateTypesText,
  TemplateType,
  TTemplateType,
  EOptionalRelation,
} from '../../../types/reservation.type';
import { TTreeListData, TType } from '../../../types/type.type';
import {
  EReservationKindMemberType,
  TMemberType,
} from '../../../types/member.type';
import { TAvailabilityRelationType } from '../../../types/availabilityrelation.type';

// UTILS
import { getNameOnType } from '../../../utils/getNameOn';

// COMPONENTS
import CombinationsComponent from './CombinationsComponent';
import DragableTable from '../../ReuseableTables/DragableTable/DragableTable';
import { TEToolTip } from '../../TEToolTip';
import { findMyParents, createTypeTree } from '../../../pages/system/types';
import { changeArrayOrder } from '../../../utils/changeArrayOrder';

const onBlur = () => {};
const onFocus = () => {};

const language: any = intl.messages;

type Props = {
  visibleCols: any;
  templateTypes: TTemplateType[];
  onTypesChanged: any;
  typeCombinations: Array<any>;
  onCombinationsChanged: any;
  limitations: any;
};

const TypesTable = ({
  visibleCols,
  templateTypes,
  typeCombinations,
  onTypesChanged,
  onCombinationsChanged,
  limitations,
}: Props) => {
  const dispatch = useDispatch();

  // Fetching all types
  const types: TType[] = useSelector(typesSelector);
  const sortedTypes: TType[] = useSelector(sortedTypesSelector);
  useEffect(() => {
    dispatch(fetchTypes());
  }, [dispatch]);

  // Fetching all Member types
  const memberTypes: TMemberType[] = useSelector(memberTypesSelector);
  useEffect(() => {
    dispatch(fetchMemberTypes());
  }, [dispatch]);

  // Fetching all Availability types
  const availabilityTypes: TAvailabilityRelationType[] = useSelector(
    availabilityTypesSelector,
  );
  useEffect(() => {
    dispatch(fetchAvailabilityTypes());
  }, [dispatch]);

  // EVENT HANDLERS
  const onChangeTypeHandler = (
    templateType: any,
    property: string,
    value: any,
  ) => {
    const index = templateTypes.indexOf(templateType);
    const updatedType = Object.assign({}, templateType);
    updatedType[property] = value;
    const newTypes: TTemplateType[] = [...templateTypes];
    newTypes.splice(index, 1, updatedType);
    onTypesChanged('types', newTypes);
  };

  const addType = (typeId: number | string | undefined) => {
    const newTemplateTypes: TTemplateType[] = [...templateTypes];
    newTemplateTypes.push(TemplateType.create(typeId));
    onTypesChanged('types', newTemplateTypes);
  };

  const onDeleteHandler = (typeId: number) => {
    onTypesChanged(
      'types',
      templateTypes.filter((type: any) => type.type !== typeId),
    );
  };

  // Functions for finding memberTypes and availabilityTypes that the user are allowed to choose
  const findingMemberTypes = (typeId: number) => {
    const memberTypesAvailable = memberTypes.filter((memberType: any) =>
      _.some(
        memberType.memberTypes,
        (memberTypeType: any) => memberTypeType.type === typeId,
      ),
    );
    return memberTypesAvailable;
  };

  const findingAvailabilityTypes = (typeId: number) => {
    const availabilityTypesAvailable = availabilityTypes.filter(
      (availabilityType: any) =>
        _.some(
          availabilityType.availabilityTypes,
          (availabilityTypeType: any) => availabilityTypeType.type === typeId,
        ),
    );
    return availabilityTypesAvailable;
  };

  const findType = (typeId: number) => {
    const result = types.find((type: TType) => type.id === typeId);
    if (result) {
      return result;
    }
  };

  const findMemberTypesByParentTypes = (type: any, tree: TTreeListData) => {
    const parents: number[] = [];
    findMyParents(type, tree, parents);
    let availableMemberTypes = findingMemberTypes(type.id);
    parents.forEach(
      (parent: any) =>
        (availableMemberTypes = availableMemberTypes.concat(
          findingMemberTypes(parent),
        )),
    );
    return availableMemberTypes.concat({
      id: 0,
      name: '-',
      description: '',
      reservationKind: EReservationKindMemberType.ABSTRACT,
      memberTypes: [],
    });
  };

  const findAvailabilityTypesByParentTypes = (
    type: any,
    tree: TTreeListData,
  ) => {
    const parents: number[] = [];
    findMyParents(type, tree, parents);
    let availableAvailabilityTypes = findingAvailabilityTypes(type.id);
    parents.forEach(
      (parent: any) =>
        (availableAvailabilityTypes = availableAvailabilityTypes.concat(
          findingAvailabilityTypes(parent),
        )),
    );
    return availableAvailabilityTypes.concat({
      name: '-',
      extId: '',
      id: 0,
      description: '',
      constitutesObstacles: false,
      occupiedByDefault: false,
      availabilityTypes: [],
    });
  };

  const treeTypes = [...types] || [];
  let treeListData: TTreeListData[] = [{ title: '', key: '', children: [] }];
  if (treeTypes.length > 0) {
    treeListData = [createTypeTree(treeTypes.shift(), treeTypes, sortedTypes)];
  }

  let columns: ColumnsType<object> = [
    {
      dataIndex: 'sort',
      width: '1vw',
      render: () => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />,
    },
    {
      key: 'action',
      width: '1vw',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={`${language.sure_to_delete}`}
            onConfirm={() => onDeleteHandler(record.type)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={`${language.yes}`}
            cancelText={`${language.no}`}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: (
        <TEToolTip helpText={language.help_type_res_template_types}>
          <span className='types-table-text'>{language.type}</span>
        </TEToolTip>
      ),
      dataIndex: 'type',
      className: 'drag-visible',
      key: 'type',
      render: (type: any) => (
        <span className='types-table-text'>{getNameOnType(types, type)}</span>
      ),
    },
  ];

  // Conditional rendering for what to show in the GUI
  if (visibleCols.properties === true) {
    columns.push(
      {
        title: (
          <TEToolTip
            helpText={`${language.help_minmax_res_template_types} (Maximum of 127 bytes)`}
          >
            <span className='types-table-text'>{language.min_max}</span>
          </TEToolTip>
        ),
        dataIndex: 'min',
        width: '4vw',
        key: 'min',
        render: (min: any, record: any) => (
          <Input.Group style={{ display: 'flex', flexDirection: 'row' }}>
            <InputNumber
              size='small'
              style={{ width: '3vw' }}
              className='res-types-list-inputs'
              value={min}
              onChange={(value: any) =>
                onChangeTypeHandler(record.templateType, 'min', value)
              }
              min={1}
            />
            <InputNumber
              size='small'
              style={{ width: '3vw', marginLeft: 2 }}
              className='res-types-list-inputs'
              value={record.max}
              onChange={(value: any) =>
                onChangeTypeHandler(record.templateType, 'max', value)
              }
              max={limitations}
            />
          </Input.Group>
        ),
      },

      {
        title: (
          <TEToolTip
            helpText={language.help_reservation_kind_res_template_types}
          >
            <span className='types-table-text'>{'Type properties'}</span>
          </TEToolTip>
        ),
        dataIndex: 'reservationKind',
        key: 'reservationKind',
        width: '7vw',
        render: (reservationKind: any, record: any) => (
          <Select
            size='small'
            style={{ width: '7vw' }}
            className='res-types-list-inputs'
            dropdownMatchSelectWidth
            value={reservationKind}
            onChange={(value: any) =>
              onChangeTypeHandler(record.templateType, 'reservationKind', value)
            }
          >
            {Object.entries(EReservationKind).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        ),
      },
    );
  }
  if (visibleCols.occurrence === true) {
    columns = columns.concat([
      {
        title: (
          <TEToolTip helpText={language.help_ma_com_opt_res_template_types}>
            <span className='types-table-text'>{language.ma_com_opt}</span>
          </TEToolTip>
        ),
        width: '5vw',
        dataIndex: 'mandatoryProperty',
        key: 'mandatoryProperty',
        render: (mandatoryProperty: any, record: any) => (
          <Radio.Group
            className='radio-group--wrapper'
            value={mandatoryProperty}
            onChange={(event: any) =>
              onChangeTypeHandler(
                record.templateType,
                'mandatoryProperty',
                event.target.value,
              )
            }
          >
            {Object.entries(EMandatoryProperty).map(([key, value]) => (
              <Radio key={key} value={value} />
            ))}
          </Radio.Group>
        ),
      },

      {
        title: (
          <TEToolTip helpText={language.help_obj_virt_inco_res_template_types}>
            <span className='types-table-text'>{language.obj_virt_inco}</span>
          </TEToolTip>
        ),
        width: '5vw',
        dataIndex: 'objectProperty',
        key: 'objectProperty',
        render: (objectProperty: any, record: any) => (
          <Radio.Group
            className='radio-group--wrapper'
            value={objectProperty}
            onChange={(event: any) =>
              onChangeTypeHandler(
                record.templateType,
                'objectProperty',
                event.target.value,
              )
            }
          >
            {Object.entries(EObjectProperty).map(([key, value]) => (
              <Radio key={key} value={value} />
            ))}
          </Radio.Group>
        ),
      },
    ]);
  }

  if (visibleCols.members === true) {
    columns.push({
      title: (
        <TEToolTip helpText={language.help_member_type_res_template_types}>
          <span className='types-table-text'>{language.member_type}</span>
        </TEToolTip>
      ),
      dataIndex: 'memberType',
      key: 'memberType',
      width: '9vw',
      render: (memberType: any, record: any) => {
        return (
          <Select
            size='small'
            style={{ width: '9vw' }}
            className='res-types-list-inputs'
            value={memberType}
            onChange={(value: any) =>
              onChangeTypeHandler(record.templateType, 'memberType', value)
            }
            onFocus={onFocus}
            onBlur={onBlur}
            /*             // @ts-ignore
            getPopupContainer={() =>
              document.getElementsByClassName('outside-click-provider')[0]
            } */
          >
            {findMemberTypesByParentTypes(
              findType(record.type),
              treeListData[0],
            ).map((memberType: any) => (
              <Select.Option key={memberType.id} value={memberType.id}>
                {memberType.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
    });
  }

  if (visibleCols.availability === true) {
    columns.push({
      title: (
        <TEToolTip
          helpText={language.help_availability_type_res_template_types}
        >
          <span className='types-table-text'>{language.availability_type}</span>
        </TEToolTip>
      ),
      width: '6vw',
      dataIndex: 'availabilityType',
      key: 'availabilityType',
      render: (availabilityType: any, record: any) => {
        return (
          <Select
            size='small'
            style={{ width: '120px' }}
            className='res-types-list-inputs'
            value={availabilityType}
            onChange={(value: any) =>
              onChangeTypeHandler(
                record.templateType,
                'availabilityType',
                value,
              )
            }
          >
            {findAvailabilityTypesByParentTypes(
              findType(record.type),
              treeListData[0],
            ).map((availabilityType: any) => (
              <Select.Option
                key={availabilityType.id}
                value={availabilityType.id}
              >
                {availabilityType.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
    });
  }

  if (visibleCols.special === true) {
    columns = columns.concat([
      {
        title: (
          <TEToolTip helpText={language.help_request_type_res_template_types}>
            <span className='types-table-text'>{'Req. type'}</span>
          </TEToolTip>
        ),
        width: '3vw',
        dataIndex: 'requestType',
        key: 'requestType',
        render: (requestType: any, record: any) => (
          <Checkbox
            onChange={(event: any) =>
              onChangeTypeHandler(
                record.templateType,
                'requestType',
                event.target.checked,
              )
            }
            key={record.templateType.type}
            checked={requestType}
          />
        ),
      },
      {
        title: (
          <TEToolTip helpText={language.help_can_per_res_template_types}>
            <span className='types-table-text'>{language.canc_per}</span>
          </TEToolTip>
        ),
        width: '3vw',
        dataIndex: 'cancelNoPermisson',
        key: 'cancelNoPermisson',
        render: (cancelNoPermisson: boolean, record: any) => (
          <Checkbox
            onChange={(event: any) =>
              onChangeTypeHandler(
                record.templateType,
                'cancelNoPermisson',
                event.target.checked,
              )
            }
            key={record.templateType.type}
            checked={cancelNoPermisson}
          />
        ),
      },
      {
        title: (
          <TEToolTip helpText={language.help_opt_rel_res_template_types}>
            <span className='types-table-text'>{language.opt_rel}</span>
          </TEToolTip>
        ),
        width: '5vw',
        dataIndex: 'optionalRelation',
        key: 'optionalRelation',
        render: (optionalRelation: any, record: any) => (
          <Select
            className='res-types-list-inputs'
            dropdownMatchSelectWidth
            size='small'
            style={{ width: '5vw' }}
            onChange={(value: any) =>
              onChangeTypeHandler(
                record.templateType,
                'optionalRelation',
                value,
              )
            }
            onFocus={onFocus}
            onBlur={onBlur}
            value={optionalRelation}
          >
            {Object.entries(EOptionalRelation).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        title: (
          <TEToolTip helpText={language.help_auto_o_res_template_types}>
            <span className='types-table-text'>{'Auto obj.'}</span>
          </TEToolTip>
        ),
        width: '3vw',
        dataIndex: 'automaticObjectSelection',
        key: 'automaticObjectSelection',
        render: (automaticObjectSelection: boolean, record: any) => (
          <Checkbox
            onChange={(event: any) =>
              onChangeTypeHandler(
                record.templateType,
                'automaticObjectSelection',
                event.target.checked,
              )
            }
            key={record.templateType.type}
            checked={automaticObjectSelection}
          />
        ),
      },
    ]);
  }

  if (visibleCols.text === true) {
    columns = columns.concat([
      {
        title: (
          <TEToolTip helpText={language.help_res_text_res_template_types}>
            <span className='types-table-text'>{language.res_text}</span>
          </TEToolTip>
        ),
        dataIndex: 'reservationText',
        key: 'reservationText',
        width: '4vw',
        render: (reservationText: any, record: any) => (
          <Select
            dropdownMatchSelectWidth
            size='small'
            className='res-types-list-inputs'
            onChange={(value: any) =>
              onChangeTypeHandler(record.templateType, 'reservationText', value)
            }
            onFocus={onFocus}
            onBlur={onBlur}
            value={reservationText}
            /*             // @ts-ignore
            getPopupContainer={() =>
              document.getElementsByClassName('outside-click-provider')[0]
            } */
          >
            {Object.entries(ETemplateTypesText).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        title: (
          <TEToolTip helpText={language.help_obst_text_res_template_types}>
            <span className='types-table-text'>{language.obst_text}</span>
          </TEToolTip>
        ),
        dataIndex: 'obstacleText',
        key: 'obstacleText',
        width: '4vw',
        render: (obstacleText: any, record: any) => (
          <Select
            dropdownMatchSelectWidth
            className='res-types-list-inputs'
            size='small'
            onChange={(value: any) =>
              onChangeTypeHandler(record.templateType, 'obstacleText', value)
            }
            onFocus={onFocus}
            onBlur={onBlur}
            value={obstacleText}
            /*             // @ts-ignore
            getPopupContainer={() =>
              document.getElementsByClassName('outside-click-provider')[0]
            } */
          >
            {Object.entries(ETemplateTypesText).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        ),
      },
    ]);
  }

  if(visibleCols.padding) {
    columns = columns.concat([
      {
        title: (
            <TEToolTip helpText={language.help_padding_res_template_type}>
              <span className='types-table-text'>{'Padding'}</span>
            </TEToolTip>
        ),
        width: '3vw',
        dataIndex: 'padding',
        key: 'padding',
        render: (padding: boolean, record: any) => (
            <Checkbox
                onChange={(event: any) =>
                  onChangeTypeHandler(
                      record.templateType,
                      'padding',
                      event.target.checked,
                  )
                }
                key={record.templateType.type}
                checked={padding}
            />
        ),
      }]);
  }

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      onTypesChanged(
        'types',
        changeArrayOrder(templateTypes, dragIndex, hoverIndex),
      );
    },
    [onTypesChanged, templateTypes],
  );

  // Excluding the root node, the types used as alias type and the already choosen ones
  const typesExcludingRootType = types?.filter(
    (type: TType) => type.id !== types[0].id,
  );
  const typesUsedAsAliasTypes = _.uniq(
    typesExcludingRootType
      .map((type: TType) =>
        type.aliasType !== type.id ? type.aliasType : null,
      )
      .filter((typeId: number | null) => typeId !== null),
  );
  const typesNotUsedAsAliasTypes = typesExcludingRootType.filter(
    (type: TType) => typesUsedAsAliasTypes.indexOf(type.id) === -1,
  );
  const typeIds = templateTypes?.map((templateType: any) => templateType.type);
  const typesToChooseFrom = typesNotUsedAsAliasTypes?.filter(
    (type: TType) => typeIds?.indexOf(type.id) === -1,
  ); // Excluding the already choosen ones

  return (
    <>
      <Form.Item label={`${language.add_type}`} style={{ marginBottom: 10 }}>
        <Select
          size='small'
          onChange={addType}
          placeholder={language.select_type}
          showSearch
          onFocus={onFocus}
          onBlur={onBlur}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value='-'
        >
          {typesToChooseFrom.map((type: any) => (
            <Select.Option key={type.name} value={type.id}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <DragableTable
        size='small'
        columns={columns}
        dataSource={templateTypes?.map((templateType: any) => {
          return {
            key: templateType.type,
            ...templateType,
            templateType,
          };
        })}
        onDrag={moveRow}
      />
      <CombinationsComponent
        typeCombinations={typeCombinations}
        onChange={onCombinationsChanged}
        types={templateTypes?.filter(
          (tp: any) => tp.mandatoryProperty === EMandatoryProperty.COMBINATION,
        )}
      />
    </>
  );
};

export default TypesTable;
