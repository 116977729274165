import { ChangeEvent, useState } from 'react';

// i18N
import intl from '../../i18n/intl';

// STYLES
import { Collapse, Form, Input, Select, Transfer, List } from 'antd';

// COMPONENTS
import PeriodsTable from './PeriodsTable';
import { TEItem } from '../TEToolTip';

// TYPES
import { TPeriod, EPeriodAccess } from '../../types/period.type';

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

type PropsGeneral = {
  period: TPeriod;
  onChange: Function;
  limitations?: any;
};

export const PeriodsGeneralSettings = ({
  period,
  onChange,
  limitations,
}: PropsGeneral) => {
  // EVENT HANDLERS
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  const onChangeSelectHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
  ) => {
    onChange(property, event);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Form.Item
          label={language.name}
          tooltip={`(Maximum size of ${limitations.name} bytes)`}
        >
          <Input
            size='small'
            defaultValue={period.name}
            value={period.name}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'name', limitations.name)
            }
          />
        </Form.Item>
        <Form.Item
          tooltip={`(Maximum size of ${limitations.shortName} bytes)`}
          label={language.short_name}
        >
          <Input
            size='small'
            defaultValue={period.shortName}
            value={period.shortName}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'shortName', limitations.shortName)
            }
          />
        </Form.Item>
        <p className='form-item-text' style={{ marginBottom: '1em' }}>
          {language.id}: {period.id}
        </p>
        <Form.Item
          tooltip={`(Maximum size of ${limitations.description} bytes)`}
          label={language.description}
        >
          <Input.TextArea
            size='small'
            rows={5}
            defaultValue={period.description}
            value={period.description}
            onChange={(value: any) =>
              onChangeInputHandler(
                value,
                'description',
                limitations.description,
              )
            }
          />
        </Form.Item>
        <Form.Item
          label={language.user_access}
          tooltip={language.help_text_periods_user_access}
        >
          <Select
            size='small'
            value={period.userAccess}
            onChange={(value: any) =>
              onChangeSelectHandler(value, 'userAccess')
            }
          >
            {Object.entries(EPeriodAccess).map(([key, value]) => (
              <Select.Option value={key} key={key}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */
type PropsIntervalsPeriods = {
  period: any;
  periods: any;
  childPeriods: any;
  timeIntervals: any;
  parentPeriods: any;
  onChange: Function;
};

export const PeriodsIntervalsPeriods = ({
  period,
  periods,
  childPeriods,
  timeIntervals,
  parentPeriods,
  onChange,
}: PropsIntervalsPeriods) => {
  // Datasource with ALL available Periods
  const dataSourcePeriods = periods
    .filter((p: any) => period.id !== p.id)
    .map((p: any) => ({
      key: String(p.id),
      name: p.name,
      description: p.name,
    }));

  // The choosen periods = childPeriods
  const targetKeys = childPeriods?.map((selectedPeriod: any) =>
    String(selectedPeriod),
  );

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const onSelection = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChange = (nextTargetKeys: any) => {
    onChange(
      'childPeriods',
      nextTargetKeys.map((key: string) => parseInt(key)),
    );
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.intervals_and_related_periods} key='2'>
        <PeriodsTable
          period={period}
          intervals={timeIntervals}
          onIntervalsChanged={onChange}
        />
        <Form.Item label={`${language.child_periods}`}>
          <Transfer
            dataSource={dataSourcePeriods}
            showSearch
            filterOption={(input: any, option: any) =>
              option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onSelectionChange}
            onSelectChange={onSelection}
            render={(item) => item.name}
          />
        </Form.Item>
        <p className='small-heading-text'>{language.parent_periods}</p>
        <List
          size='small'
          bordered
          dataSource={parentPeriods}
          renderItem={(item: any) => (
            <List.Item key={item.id}>{item.name}</List.Item>
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
