import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import {
  AvailabilityRelationType,
  TAvailabilityRelationType,
} from '../types/availabilityrelation.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedAvailabilityType: AvailabilityRelationType.createEmpty(),
  allAvailabilityTypes: [],
  limitations: {},
};

// Slice
const slice = createSlice({
  name: 'availabilityTypes',
  initialState,
  reducers: {
    fetchAvailabilityTypesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchAvailabilityTypesSuccess: (state: any, { payload }) => {
      if (state.selectedAvailabilityType.id > 0) {
        state.selectedAvailabilityType =
          payload.find(
            (type: any) => type.id === state.selectedAvailabilityType.id,
          ) || AvailabilityRelationType.createEmpty();
      }
      state.allAvailabilityTypes = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsAvailabilityTypesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchAvailabilityTypesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createAvailabilityTypeRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createAvailabilityTypeSuccess: (state: any, { payload }) => {
      state.allAvailabilityTypes.push(payload);
      state.selectedAvailabilityType = payload;
      finishedLoadingSuccess(state);
    },
    createAvailabilityTypeFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentAvailabilityType: (state: any, { payload }) => {
      state.selectedAvailabilityType = payload;
    },
    deleteAvailabilityTypeRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteAvailabilityTypeSuccess: (state: any, payload: any) => {
      state.allAvailabilityTypes = state.allAvailabilityTypes.filter(
        (availabilityType: TAvailabilityRelationType) =>
          availabilityType.id !== payload.payload.id,
      );
      state.selectedAvailabilityType = AvailabilityRelationType.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteAvailabilityTypeFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateAvailabilityTypeRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateAvailabilityTypeSuccess: (state: any, { payload }) => {
      const index = state.allAvailabilityTypes.findIndex(
        (availabilityType: TAvailabilityRelationType) =>
          availabilityType.id === payload.id,
      );
      state.allAvailabilityTypes[index] = payload;
      finishedLoadingSuccess(state);
    },
    updateAvailabilityTypeFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const availabilityTypesLoading = (state: any) =>
  state.availabilityTypes.loading;
export const availabilityTypesSelector = (state: any) =>
  state.availabilityTypes.allAvailabilityTypes as TAvailabilityRelationType[];
export const relationTypeSelectorCreateNew = () => () => {
  return AvailabilityRelationType.createEmpty();
};
export const selectedAvailabilityTypeSelector = (state: any) => {
  return state.availabilityTypes.selectedAvailabilityType;
};
export const fetchLimitationsAvailabilityTypesSelector = (state: any) =>
  state.availabilityTypes.limitations;

// Actions
export const {
  fetchAvailabilityTypesRequest,
  fetchAvailabilityTypesSuccess,
  fetchLimitationsAvailabilityTypesSuccess,
  fetchAvailabilityTypesFailure,
  createAvailabilityTypeRequest,
  createAvailabilityTypeSuccess,
  createAvailabilityTypeFailure,
  setCurrentAvailabilityType,
  deleteAvailabilityTypeRequest,
  deleteAvailabilityTypeSuccess,
  deleteAvailabilityTypeFailure,
  updateAvailabilityTypeRequest,
  updateAvailabilityTypeSuccess,
  updateAvailabilityTypeFailure,
} = slice.actions;

export const fetchAvailabilityTypes = () => async (dispatch: any) => {
  try {
    dispatch(fetchAvailabilityTypesRequest());
    const availabilityTypes = await api.get({
      endpoint: `/availability-types`,
    });
    dispatch(fetchAvailabilityTypesSuccess(availabilityTypes));
  } catch (e) {
    dispatch(fetchAvailabilityTypesFailure());
    return console.error(e);
  }
};

export const fetchLimitationsAvailabilityTypes =
  () => async (dispatch: any) => {
    try {
      dispatch(fetchAvailabilityTypesRequest());
      const limitationsAvailabilityTypes = await api.get({
        endpoint: `/availability-types/limitations`,
      });
      dispatch(
        fetchLimitationsAvailabilityTypesSuccess(limitationsAvailabilityTypes),
      );
    } catch (e) {
      dispatch(fetchAvailabilityTypesFailure());
      return console.error(e);
    }
  };

export const createAvailabilityType =
  (availabilityType: TAvailabilityRelationType) => async (dispatch: any) => {
    try {
      dispatch(createAvailabilityTypeRequest());
      const { id: _, ...availabilityTypeBody } = availabilityType;
      const response = await api.post({
        endpoint: `/availability-types`,
        data: availabilityTypeBody,
      });
      dispatch(createAvailabilityTypeSuccess(response));
      dispatch(fetchAvailabilityTypes());
    } catch (e: any) {
      dispatch(createAvailabilityTypeFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const createEmptyAvailabilityType = () => async (dispatch: any) => {
  try {
    dispatch(createAvailabilityTypeRequest());
    const response = AvailabilityRelationType.createEmpty();
    dispatch(createAvailabilityTypeSuccess(response));
  } catch (e: any) {
    dispatch(createAvailabilityTypeFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedAvailabilityType =
  (availabilityType: TAvailabilityRelationType) => async (dispatch: any) => {
    dispatch(setCurrentAvailabilityType(availabilityType));
  };

export const copyAvailabilityType =
  (availabilityType: TAvailabilityRelationType) => async (dispatch: any) => {
    try {
      dispatch(createAvailabilityTypeRequest());
      const response = {
        ...availabilityType,
        id: 0,
        description: '',
      };
      dispatch(createAvailabilityTypeSuccess(response));
    } catch (e: any) {
      dispatch(createAvailabilityTypeFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const deleteAvailabilityType =
  (availabilityType: TAvailabilityRelationType) => async (dispatch: any) => {
    try {
      dispatch(deleteAvailabilityTypeRequest());
      await api.delete({
        endpoint: `/availability-types/${availabilityType.id}`,
      });
      dispatch(deleteAvailabilityTypeSuccess(availabilityType));
    } catch (e) {
      dispatch(deleteAvailabilityTypeFailure());
      return console.error(e);
    }
  };

export const updateAvailabilityType =
  (availabilityType: TAvailabilityRelationType) => async (dispatch: any) => {
    try {
      dispatch(updateAvailabilityTypeRequest());
      const { ...AvailabilityTypeBody } = availabilityType;
      const response = await api.patch({
        endpoint: `/availability-types/${availabilityType.id}`,
        data: { ...AvailabilityTypeBody },
      });
      dispatch(updateAvailabilityTypeSuccess(response));
    } catch (e: any) {
      dispatch(updateAvailabilityTypeFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
