// i18N
import intl from '../../i18n/intl';

// STYLES
import { Collapse, Form, Input, Select, List } from 'antd';

// TYPES
import { TRole } from '../../types/role.type';
import { ChangeEvent } from 'react';
import Checkbox from 'antd/lib/checkbox/Checkbox';

// COMPONENTS
import ActionButton from '../Buttons';

type PropsRoles = {
  role: TRole;
  onChange: any;
  limitations?: any;
};

type PropsPermissions = {
  onChange: any;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const RolesComponent = ({ role, onChange, limitations }: PropsRoles) => {
  // EVENT HANDLERS
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.roles} key='1'>
        <Form.Item label={`${language.name}:`}>
          <Input
            size='small'
            defaultValue={role.name}
            value={role.name}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'name', limitations.name)
            }
          />
        </Form.Item>
        <p className='small-heading-text'>
          {language.id}: {role.id}
        </p>
        <Form.Item label={`${language.description}:`}>
          <Input.TextArea
            size='small'
            rows={5}
            defaultValue={role.description}
            value={role.description}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'description', limitations.name)
            }
          />
        </Form.Item>
        <Form.Item label='Add permissions'>
          <Select showSearch size='small' onFocus={onFocus} onBlur={onBlur}>
            {/*  Add the options here */}
          </Select>
        </Form.Item>
        <List> {/* Add permissions for the role */}</List>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

const onBlur = () => {};
const onFocus = () => {};

export const PermissionsComponent = ({ onChange }: PropsPermissions) => {
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.permissions} key='2'>
        <div className='collapse-heading-button-bar'>
          <ActionButton
            title={language.save_changes}
            action={() => {}} // onSaveChangesPermissionsButton
            isDisabled={false}
            isDanger={false}
            displayProp={false}
            isSaveChangesBtn
          />
          <ActionButton
            title={language.discard_changes}
            action={() => {}} // onDiscardChanges
            isDisabled={false}
            isDanger
            displayProp={false}
          />
        </div>
        <Form.Item>
          <Select showSearch size='small' onFocus={onFocus} onBlur={onBlur}>
            {/*  Add the options here */}
          </Select>
        </Form.Item>
        <List></List>
        <Form.Item label='Name'>
          <Input size='small' />
        </Form.Item>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '40px' }}>
            <Form.Item>
              <Checkbox>Read</Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox>Change</Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox>Delete</Checkbox>
            </Form.Item>
          </div>
          <Form.Item>
            <Checkbox>Choose all</Checkbox>
          </Form.Item>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};
