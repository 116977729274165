import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// i18N
import intl from '../../../i18n/intl';

// REDUX
import {
  emailPreferencesSelector,
  fetchEmailPreferences,
  setUpdatedEmailPreferences,
  updatedEmailPreferencesSelector,
  updateEmailPreferences,
} from '../../../slices/emailPreferences.slice';

// STYLES
import { Collapse, Input, Checkbox, Select, Button, Modal, List } from 'antd';

// TYPES
import {
  TEmailTemplate,
  TEmailPreferences,
} from '../../../types/systempreferences.type';

// UTILS
import { isSavedDisabledNoId } from '../../../utils/isSavedDisabled';

// COMPONENTS
import { TEToolTip } from '../../TEToolTip';
import SystemPrefsActionButtons from '../SystemPrefsActionButtons';

const onBlur = () => {};
const onFocus = () => {};

type PropsEmailTemplates = {
  emailVariables: any;
  limitations: any;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const EmailSettings = () => {
  const dispatch = useDispatch();
  const emailPreferences = useSelector(emailPreferencesSelector);
  const updatedEmailPreferences: TEmailPreferences = useSelector(
    updatedEmailPreferencesSelector,
  );

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(
      isSavedDisabledNoId(updatedEmailPreferences, emailPreferences),
    );
  }, [updatedEmailPreferences, emailPreferences]);

  // EVENT HANDLERS
  const onHandleChanges = (value: any, property: string) => {
    const newEmailPreferences = {
      ...updatedEmailPreferences,
      [property]: value,
    };
    dispatch(setUpdatedEmailPreferences(newEmailPreferences));
  };

  const onSaveChangesButton = () => {
    dispatch(updateEmailPreferences(updatedEmailPreferences));
  };

  const onDiscardChanges = () => {
    dispatch(fetchEmailPreferences());
  };

  const emailMaxMembersArray = [
    { key: `50 (${language.low})`, value: 50 },
    { key: `100 (${language.normal})`, value: 100 },
    { key: `300 (${language.high})`, value: 300 },
    { key: `500 (${language.extreme})`, value: 500 },
  ];

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header='E-mail settings' key='2'>
        <SystemPrefsActionButtons
          displayProp={false}
          changeHandler={onSaveChangesButton}
          discardHandler={onDiscardChanges}
          isDisabled={saveDisabled}
        />
        <TEToolTip helpText={language.help_email_max_member_system_prefs}>
          <p className='form-item-text' style={{ marginRight: 6 }}>
            {language.email_max_members}
          </p>
        </TEToolTip>
        <Select
          size='small'
          style={{ width: '200px' }}
          value={updatedEmailPreferences?.emailMaxMembers}
          onChange={(value: any) => onHandleChanges(value, 'emailMaxMembers')}
        >
          {emailMaxMembersArray.map((maxMembers: any) => (
            <Select.Option value={maxMembers.value} key={maxMembers.key}>
              {maxMembers.key}
            </Select.Option>
          ))}
        </Select>
        <p className='form-item-text'>{language.email}</p>
        <TEToolTip
          helpText={
            language.help_do_not_use_the_user_email_address_as_system_prefs
          }
        >
          <Checkbox
            onChange={(event: any) =>
              onHandleChanges(event.target.checked, 'useUserEmailAsFrom')
            }
            checked={updatedEmailPreferences.useUserEmailAsFrom}
          >
            {language.do_not_use_the_user_email_address_as} &quot;
            {`${language.from}`.toLowerCase()}&quot;
          </Checkbox>
        </TEToolTip>
        <div style={{ marginLeft: 25 }}>
          <div style={{ margin: 0 }}>
            <div className='as-from--wrapper' style={{ marginBottom: '8px' }}>
              <p
                style={{ marginRight: 4, marginTop: 0 }}
                className='form-item-text'
              >
                {language.set}
              </p>
              <Input
                size='small'
                style={{ width: 200 }}
                value={updatedEmailPreferences.fromAddress}
                onChange={(event: any) => {
                  onHandleChanges(event.target.value, 'fromAddress');
                }}
              />
              <p
                style={{ width: 100, marginLeft: 4, marginTop: 0 }}
                className='form-item-text'
              >
                {language.as} &quot;{`${language.from}`.toLowerCase()}&quot;
              </p>
            </div>
            <div className='checkbox-wrapper'>
              <TEToolTip
                helpText={
                  language.help_set_the_user_email_address_as_system_prefs
                }
              >
                <Checkbox
                  onChange={(event: any) =>
                    onHandleChanges(
                      event.target.checked,
                      'useUserEmailAsReplyTo',
                    )
                  }
                  checked={updatedEmailPreferences.useUserEmailAsReplyTo}
                >
                  {language.set_the_user_email_address_as} &quot;
                  {language.reply_to}&quot;
                </Checkbox>
              </TEToolTip>
              <TEToolTip
                helpText={
                  language.help_include_empty_reservation_fields_when_sending_email_system_prefs
                }
              >
                <Checkbox
                  style={{ marginLeft: 0 }}
                  onChange={(event: any) =>
                    onHandleChanges(
                      event.target.checked,
                      'includeEmptyReservationFields',
                    )
                  }
                  checked={
                    updatedEmailPreferences.includeEmptyReservationFields
                  }
                >
                  {language.include_empty_reservation_fields_when_sending_email}
                </Checkbox>
              </TEToolTip>
              <TEToolTip
                helpText={
                  language.help_send_server_emails_to_Slack_for_debugging_system_prefs
                }
              >
                <Checkbox
                  style={{ marginLeft: 0 }}
                  onChange={(event: any) =>
                    onHandleChanges(
                      event.target.checked,
                      'sendServerEmailToSlack',
                    )
                  }
                  checked={updatedEmailPreferences.sendServerEmailToSlack}
                >
                  {language.send_server_emails_to_Slack_for_debugging}
                </Checkbox>
              </TEToolTip>
            </div>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const EmailTemplates = ({
  limitations,
  emailVariables,
}: PropsEmailTemplates) => {
  const dispatch = useDispatch();
  const emailPreferences = useSelector(emailPreferencesSelector);
  const [selectedEmailPreferencesType, setSelectedEmailPreferencesType] =
    useState<any>();
  const updatedEmailPreferences: TEmailPreferences = useSelector(
    updatedEmailPreferencesSelector,
  );

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(
      isSavedDisabledNoId(updatedEmailPreferences, emailPreferences),
    );
  }, [updatedEmailPreferences, emailPreferences]);

  // EVENT HANDLERS
  const onHandleChanges = (value: any, property: string) => {
    const newEmailPreferences = {
      ...emailPreferences,
      [property]: value,
    };
    dispatch(setUpdatedEmailPreferences(newEmailPreferences));
  };

  const onEmailPreferencesTypeSelected = (selectedValue: any) => {
    const currentMailPreferenceType =
      updatedEmailPreferences.emailTemplates.find(
        (pref: any) => pref.id === selectedValue,
      );
    setSelectedEmailPreferencesType(currentMailPreferenceType);
  };

  const selectedEmailPreference = updatedEmailPreferences.emailTemplates?.find(
    (eMailPreference: any) =>
      eMailPreference.id === selectedEmailPreferencesType?.id,
  );

  const onChangeEmailPreferencesTemplates = (
    emailTemplate: any,
    property: string,
    value: any,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (new TextEncoder().encode(value).length > limitationValue) {
        return;
      }
    }
    const index = updatedEmailPreferences.emailTemplates.indexOf(emailTemplate);
    const updatedEmailPreferencesTemplate = Object.assign({}, emailTemplate);
    updatedEmailPreferencesTemplate[property] = value;
    const newEmailPreferencesTemplates: TEmailTemplate[] = [
      ...updatedEmailPreferences.emailTemplates,
    ];
    newEmailPreferencesTemplates.splice(
      index,
      1,
      updatedEmailPreferencesTemplate,
    );
    onHandleChanges(newEmailPreferencesTemplates, 'emailTemplates');
  };

  const onSaveChangesButton = () => {
    dispatch(updateEmailPreferences(updatedEmailPreferences));
  };

  const onDiscardChanges = () => {
    dispatch(fetchEmailPreferences());
  };

  // Event handler for the Modal
  const previewHandler = () => {
    Modal.info({
      title: `${language.preview_of_email_template}`,
      content: (
        <div>
          <p style={{ fontWeight: 900 }}>{selectedEmailPreference?.subject}</p>
          <p>{selectedEmailPreference?.message}</p>
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.email_templates} key='3'>
        <SystemPrefsActionButtons
          displayProp={false}
          changeHandler={onSaveChangesButton}
          discardHandler={onDiscardChanges}
          isDisabled={saveDisabled}
        />
        <div className='emailtemplates-view--wrapper'>
          <div>
            <Select
              size='small'
              showSearch
              onFocus={onFocus}
              onBlur={onBlur}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={language.email_preferences}
              onSelect={onEmailPreferencesTypeSelected}
              value={selectedEmailPreferencesType?.id}
            >
              {updatedEmailPreferences.emailTemplates?.map((eMailType: any) => (
                <Select.Option key={eMailType.id} value={eMailType.id}>
                  {eMailType.name}
                </Select.Option>
              ))}
            </Select>
            <TEToolTip
              helpText={`(Maximum size of ${limitations.subject} bytes)`}
            >
              <Input
                size='small'
                value={selectedEmailPreference?.subject}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  onChangeEmailPreferencesTemplates(
                    selectedEmailPreference,
                    'subject',
                    event.target.value,
                    limitations.subject,
                  );
                }}
              />
            </TEToolTip>
            <TEToolTip
              helpText={`(Maximum size of ${limitations.message} bytes)`}
            >
              <p className='form-item-text'>{language.message}</p>
            </TEToolTip>
            <Input.TextArea
              size='small'
              style={{ marginBottom: 20 }}
              rows={12}
              value={selectedEmailPreference?.message}
              onChange={(event: any) => {
                onChangeEmailPreferencesTemplates(
                  selectedEmailPreference,
                  'message',
                  event.target.value,
                  limitations.message,
                );
              }}
            />
            <Button size='small' type='dashed' onClick={previewHandler}>
              {language.preview}
            </Button>
          </div>
          <div style={{ marginLeft: 30 }}>
            <div
              className='row-direction--wrapper'
              style={{ alignItems: 'center' }}
            >
              <p style={{ fontSize: 12, margin: 0 }}>
                {language.variables_to_use_in_email}
              </p>
            </div>
            <List
              className='variables-list'
              size='small'
              style={{ width: '15vw' }}
              bordered
              dataSource={emailVariables}
              renderItem={(item: any) => (
                <List.Item className='variables-list-item'>{item}</List.Item>
              )}
            />
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};
