import { useEffect, useState } from 'react';

// SERVICES
import api from '../../services/api.service';

// STYLES
import './index.scss';
import { Collapse, Form, Select } from 'antd';

type Props = {
  options: ActionSettings[];
  selectedUrl: string;
  selectedId: number;
};

export type ActionSettings = {
  actionId: string;
  name: string;
};

/* ------------------------------------------------------------------------------ */

const Advanced = ({ options, selectedUrl, selectedId }: Props) => {
  const [selectedAdvancedInfo, setSelectedAdvancedInfo] = useState<any>(null);
  const [selectedActionId, setSelectedActionId] = useState<any>();

  // EVENT HANDLER
  const onActionSelected = (selectedValue: string) => {
    setSelectedActionId(selectedValue);
  };

  useEffect(() => {
    // Sending a GET request to the server with the choosen actionId, if it is setted
    if (selectedActionId !== undefined) {
      const fetchInfo = async () => {
        const response = await api.get({
          endpoint: `/${selectedUrl}/${selectedId}/advanced/${selectedActionId}`,
        });
        const result = await response;
        setSelectedAdvancedInfo(result);
      };
      fetchInfo();
    }
  }, [selectedId, selectedUrl, selectedActionId]);

  useEffect(() => {
    setSelectedActionId(undefined);
  }, [selectedId]);

  return (
    <Collapse defaultActiveKey={['7']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header='Advanced' key='1'>
        <div className='inside--wrapper'>
          <Form.Item>
            <Select
              size='small'
              placeholder='Select action'
              onChange={(value: any) => onActionSelected(value)}
              value={selectedActionId}
            >
              {options.map((option: any) => (
                <Select.Option key={option.actionId} value={option.actionId}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        {selectedActionId !== undefined && (
          <html>
            <body>
              <pre>
                <code>{selectedAdvancedInfo?.result}</code>
              </pre>
            </body>
          </html>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default Advanced;
