// i18N
import intl from '../../i18n/intl';

// STYLES
import { Collapse } from 'antd';

// TYPES
import { TAltDesignations, TLanguage } from '../../types/languages.type';
import { LanguageTable } from './LanguageTable';

type Props = {
  altDesignations: Array<TAltDesignations>;
  languages?: Array<TLanguage>;
  onChange: any;
  limitationName: any;
  limitationDescription: any;
};

const language: any = intl.messages;

const LanguagePreferences = ({
  altDesignations,
  languages,
  onChange,
  limitationName,
  limitationDescription,
}: Props) => {

  const onChangeHandler = (
    changedValue: any,
    property: string,
    value: any,
    limitationValue: any,
  ) => {
    if (limitationValue) {
      if (new TextEncoder().encode(value).length > limitationValue) {
        return;
      }
    }
    const index = altDesignations.indexOf(changedValue);
    const updatedLanguage = Object.assign({}, changedValue);
    updatedLanguage[property] = value;
    const languages: TAltDesignations[] = [...altDesignations];
    languages.splice(index, 1, updatedLanguage);
    onChange('altDesignations', languages);
  };

  return (
    <Collapse defaultActiveKey={['2']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.language_preferences} key='1'>
        <LanguageTable
          altDesignations={altDesignations}
          onChangeHandler={onChangeHandler}
          onChange={onChange}
          limitationDescription={limitationDescription}
          limitationName={limitationName}
          languages={languages}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default LanguagePreferences;
