// TYPES
import { TAvailabilityRelationType } from '../types/availabilityrelation.type';
import { TField } from '../types/field.type';
import { TTEObject } from '../types/object.type';
import { TReservationTemplate } from '../types/reservation.type';
import { TRole } from '../types/role.type';
import { TTimeZone } from '../types/timeZone.type';
import { TType } from '../types/type.type';

// Getting right name on types
export const getNameOnType = (types: TType[], item: any) => {
  const result = types.find((i: any) => i.id === item);
  if (result) {
    return result.name;
  }
  return item;
};

// Getting ext.id on type
export const getExtIdOnType = (types: TType[], item: any) => {
  const result = types.find((i: any) => i.id === item);
  if (result) {
    return result.extId;
  }
  return item;
};

// Getting description on type
export const getDescriptionOnType = (types: TType[], item: any) => {
  const result = types.find((i: any) => i.id === item);
  if (result) {
    return result.description;
  }
  return item;
};

// Getting ext.id on fields
export const getExtIdOnField = (fields: TField[], field: number) => {
  const result = fields.find((f: any) => f.id === field);
  if (result) {
    return result.extId;
  }
  return field;
};

// Getting right name on fields
export const getNameOnField = (fields: TField[], field: number) => {
  const result = fields.find((f: any) => f.id === field);
  if (result) {
    return result.name;
  }
  return field;
};

// Getting right name on organizations
export const getNameOnOrganization = (organizations: any[], item: any) => {
  const result = organizations.find((org: any) => org.id === item);
  if (result) {
    return result.name;
  }
  return item;
};

export const getNameOnOrganizationWithOrgId = (
  organizations: any[],
  item: any,
) => {
  const result = organizations.find((org: any) => org.organizationId === item);
  if (result) {
    return result.name;
  }
  return item;
};

// Getting right name on availabilityTypes
export const getNameOnAvailabilityType = (
  availabilityTypes: TAvailabilityRelationType[],
  availabilityType: TAvailabilityRelationType,
) => {
  const result = availabilityTypes.find((o: any) => o.id === availabilityType);
  if (result) {
    return result.name;
  }
  return availabilityType;
};

// Getting right name on objects
export const getNameOnObject = (objects: TTEObject[], object: number) => {
  const result = objects.find((mt: any) => mt.id === object);
  if (result) {
    return result.extId;
  }
  return object;
};

// Getting right extId on objects
export const getExtIdName = (array: any[], item: any) => {
  const result = array.find((i: any) => i.id === item);
  if (result) {
    return result.extId;
  }
  return item;
};

// Getting right name on reservation templates
export const getNameOnReservationTemplates = (
  reservationTemplates: TReservationTemplate[],
  templates: number[],
) =>
  reservationTemplates
    ?.filter((rt: any) => templates?.includes(rt.id))
    ?.map((rt) => (rt.active === true ? `${rt.name}` : `(${rt.name})`)) ?? [];

// Getting right name on timeZones
export const getNameOnTimeZone = (
  timeZones: TTimeZone[],
  timeZoneId: number,
) => {
  const result = timeZones.find((timeZone: any) => timeZone.id === timeZoneId);
  if (result) {
    return result.name;
  }
  return timeZoneId;
};

// Getting name on role
export const getNameOnRole = (roles: TRole[], role: any) => {
  const result = roles.find((r: any) => r.id === parseInt(role));
  if (result) {
    return result.name;
  }
  return role;
};
