import { useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

// ACTIONS
import { reservationTemplatesSelector } from '../../slices/reservationTemplate.slice';
import { organizationsSelector } from '../../slices/organization.slice';
import { typesSelector } from '../../slices/type.slice';

// i18N
import intl from '../../i18n/intl';

// STYLES
import './index.scss';
import {
  Form,
  Collapse,
  Input,
  Checkbox,
  Select,
  Transfer,
  InputNumber,
  Button,
  AutoComplete,
} from 'antd';
import { LockFilled, UnlockFilled } from '@ant-design/icons';

// COMPONENTS
import FieldTable from './TableElement';
import { RenderElement } from '../RenderElements';
import { FromSecondsInputNumber } from '../FromSeconds/FromSecondsInputNumber';
import { TEToolTip, TEItem } from '../TEToolTip';

// TYPES
import {
  TField,
  EPersonalDataCheck,
  EPreferencesFilter,
  EFieldType
} from '../../types/field.type';
import { TReservationTemplate } from '../../types/reservation.type';
import { TType } from '../../types/type.type';
import { TOrganizationTreeNode } from '../../types/organization.type';

type PropsGeneral = {
  field: TField;
  onChange: any;
  limitations: any;
  setFieldLocked: any;
  lockedField: boolean;
};

type PropsPreferences = {
  field: TField;
  onChange: any;
  limitations: any;
};
/* ---------------------------------------------------------------------- */

const onBlur = () => {};
const onFocus = () => {};

const language: any = intl.messages;

export const FieldsGeneralSettings = ({
  field,
  onChange,
  limitations,
  setFieldLocked,
  lockedField,
}: PropsGeneral) => {
  // EVENT HANDLERS
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  const onChangeSelectHandler = (property: string, event: any) => {
    onChange(property, event);
  };

  // Sets which field types the user can change to depending on current fieldType and max lengths
  const fieldTypes = [];
  if (field.fieldType === EFieldType.NON_SEARCHABLE_TEXT) {
    fieldTypes.push(
      { key: EFieldType.TEXT, value: EFieldType.TEXT },
      { key: EFieldType.COMMENT, value: EFieldType.COMMENT },
      { key: EFieldType.SIGNATURE, value: EFieldType.SIGNATURE },
    );
  } else if (field.fieldType === EFieldType.REFERENCE) {
    fieldTypes.push(
      { key: EFieldType.TEXT, value: EFieldType.TEXT },
      { key: EFieldType.SIGNATURE, value: EFieldType.SIGNATURE },
      { key: EFieldType.TELEPHONE, value: EFieldType.TELEPHONE },
    );
  } else if (field.fieldType === EFieldType.CATEGORY) {
    fieldTypes.push(
      { key: EFieldType.TEXT, value: EFieldType.TEXT },
      { key: EFieldType.COMMENT, value: EFieldType.COMMENT },
      { key: EFieldType.NON_SEARCHABLE_TEXT, value: EFieldType.NON_SEARCHABLE_TEXT },
    );
  } else if (field.fieldType === EFieldType.TELEPHONE) {
    fieldTypes.push(
      { key: EFieldType.TEXT, value: EFieldType.TEXT },
      { key: EFieldType.SIGNATURE, value: EFieldType.SIGNATURE },
      { key: EFieldType.COMMENT, value: EFieldType.COMMENT },
      { key: EFieldType.NON_SEARCHABLE_TEXT, value: EFieldType.NON_SEARCHABLE_TEXT },
    );
  } else if (field.fieldType === EFieldType.COMMENT) {
    fieldTypes.push({ key: EFieldType.NON_SEARCHABLE_TEXT, value: EFieldType.NON_SEARCHABLE_TEXT });
    if (field.length < limitations['text.length']) {
      fieldTypes.push({ key: EFieldType.TEXT, value: EFieldType.TEXT });
    }
  } else if (field.fieldType === EFieldType.CHECKBOX) {
    fieldTypes.push({ key: EFieldType.INTEGER, value: EFieldType.INTEGER });
  } else if (field.fieldType === EFieldType.INTEGER) {
    fieldTypes.push(
      { key: EFieldType.CHECKBOX, value: EFieldType.CHECKBOX },
      { key: EFieldType.TEXT, value: EFieldType.TEXT },
      { key: EFieldType.COMMENT, value: EFieldType.COMMENT },
      { key: EFieldType.NON_SEARCHABLE_TEXT, value: EFieldType.NON_SEARCHABLE_TEXT },
    );
  } else if (field.fieldType === EFieldType.SIGNATURE) {
    fieldTypes.push(
      { key: EFieldType.TEXT, value: EFieldType.TEXT },
      { key: EFieldType.TELEPHONE, value: EFieldType.TELEPHONE },
      { key: EFieldType.NON_SEARCHABLE_TEXT, value: EFieldType.NON_SEARCHABLE_TEXT },
    );
    if (field.unique !== true) {
      fieldTypes.push({ key: EFieldType.COMMENT, value: EFieldType.COMMENT });
    }
  } else if (field.fieldType === EFieldType.URL) {
    fieldTypes.push({ key: EFieldType.NON_SEARCHABLE_TEXT, value: EFieldType.NON_SEARCHABLE_TEXT });
    if (field.length < limitations['text.length']) {
      fieldTypes.push({ key: EFieldType.TEXT, value: EFieldType.TEXT });
    }
    if (field.unique !== true) {
      fieldTypes.push({ key: EFieldType.COMMENT, value: EFieldType.COMMENT });
    }
  } else if (field.fieldType === EFieldType.EMAIL) {
    fieldTypes.push({ key: EFieldType.NON_SEARCHABLE_TEXT, value: EFieldType.NON_SEARCHABLE_TEXT });
    if (field.length < limitations['text.length']) {
      fieldTypes.push({ key: EFieldType.TEXT, value: EFieldType.TEXT });
    }
    if (field.unique !== true) {
      fieldTypes.push({ key: EFieldType.COMMENT, value: EFieldType.COMMENT });
    }
  } else if (field.fieldType === EFieldType.TEXT) {
    fieldTypes.push(
      { key: EFieldType.NON_SEARCHABLE_TEXT, value: EFieldType.NON_SEARCHABLE_TEXT },
      { key: EFieldType.EMAIL, value: EFieldType.EMAIL },
      { key: EFieldType.URL, value: EFieldType.URL },
    );
    if (field.length < limitations['signature.length']) {
      fieldTypes.push(
        { key: EFieldType.TELEPHONE, value: EFieldType.TELEPHONE },
        { key: EFieldType.SIGNATURE, value: EFieldType.SIGNATURE },
      );
    }
    if (
      field.length < limitations['category.length'] &&
      field.unique !== true
    ) {
      fieldTypes.push({ key: EFieldType.CATEGORY, value: EFieldType.CATEGORY });
    }
    if (field.unique !== true) {
      fieldTypes.push({ key: EFieldType.COMMENT, value: EFieldType.COMMENT });
    }
    // NOTE: Right now we cannot change from 'text' to 'integer'. Need some verification check from the server to fix that.
  }
  // If none of the above fieldTypes, i.e. new field to be created, all fieldTypes are available to choose from:
  else {
    fieldTypes.push(
      { key: EFieldType.TEXT, value: EFieldType.TEXT },
      { key: EFieldType.COMMENT, value: EFieldType.COMMENT },
      { key: EFieldType.SIGNATURE, value: EFieldType.SIGNATURE },
      { key: EFieldType.NON_SEARCHABLE_TEXT, value: EFieldType.NON_SEARCHABLE_TEXT },
      { key: EFieldType.EMAIL, value: EFieldType.EMAIL },
      { key: EFieldType.URL, value: EFieldType.URL },
      { key: EFieldType.COMMENT, value: EFieldType.INTEGER },
      { key: EFieldType.CATEGORY, value: EFieldType.CATEGORY },
      { key: EFieldType.TELEPHONE, value: EFieldType.TELEPHONE },
      { key: EFieldType.INTEGER, value: EFieldType.INTEGER },
      { key: EFieldType.LENGTH, value: EFieldType.LENGTH },
      { key: EFieldType.CHECKBOX, value: EFieldType.CHECKBOX },
      { key: EFieldType.REFERENCE, value: EFieldType.REFERENCE },
      { key: EFieldType.NON_SEARCHABLE_REFERENCE, value: EFieldType.NON_SEARCHABLE_REFERENCE },
    );
  }

  if (field) {
    return (
      <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
        <Collapse.Panel header={language.general_settings} key='1'>
          <Form.Item label={`${language.field_type}`}>
            <Button
              icon={lockedField === true ? <LockFilled /> : <UnlockFilled />}
              style={{ border: 'none', boxShadow: 'none' }}
              onClick={() => setFieldLocked(lockedField !== true)}
            />
            <Select
              showSearch
              onFocus={onFocus}
              onBlur={onBlur}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              size='small'
              value={language[field.fieldType]}
              onChange={(value: string) =>
                onChangeSelectHandler('fieldType', value)
              }
              disabled={field.id === 0 ? false : lockedField === true}
            >
              {fieldTypes.map((fieldType: any) => (
                <Select.Option value={fieldType.value} key={fieldType.key}>
                  {language[fieldType.value]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <p className='form-item-text' style={{ marginBottom: '1em' }}>
            {language.id}: {field.id}
          </p>
          <Form.Item
            label={language.name}
            tooltip={`(Maximum size of ${limitations.name} bytes)`}
          >
            <Input
              size='small'
              defaultValue={field.name}
              value={field.name}
              onChange={(value: any) =>
                onChangeInputHandler(value, 'name', limitations.name)
              }
            />
          </Form.Item>
          <Form.Item
            label={language.ext_id}
            tooltip={`${language.help_ext_id} (Maximum size of ${limitations.extId} bytes)`}
          >
            <Input
              size='small'
              defaultValue={field.extId}
              value={field.extId}
              onChange={(value: any) =>
                onChangeInputHandler(value, 'extId', limitations.extId)
              }
            />
          </Form.Item>
          <Form.Item label={`${language.personal_data}`}>
            <Select
              onFocus={onFocus}
              onBlur={onBlur}
              size='small'
              onChange={(value: any) =>
                onChangeSelectHandler('personalData', value)
              }
              value={field.personalData}
              disabled={field.id === 0 ? false : lockedField === false}
            >
              {Object.entries(EPersonalDataCheck).map(([key, value]) => (
                <Select.Option value={key} key={key}>
                  {language[value]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={language.description}
            tooltip={`(Maximum size of ${limitations.description} bytes)`}
          >
            <Input.TextArea
              size='small'
              rows={5}
              defaultValue={field.description}
              value={field.description}
              onChange={(value: any) =>
                onChangeInputHandler(
                  value,
                  'description',
                  limitations.description,
                )
              }
            />
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
    );
  }
  return null;
};

/* ---------------------------------------------------------------------- */

export const FieldsPreferences = ({
  field,
  onChange,
  limitations,
}: PropsPreferences) => {
  // All available types
  const types: TType[] = useSelector(typesSelector);

  const arrayToChooseFrom = types.concat({
    name: '-',
    extId: '',
    id: 0,
    active: false,
    personalData: false,
    description: '',
    altDesignations: [],
    allowStandardObjects: false,
    allowAbstractObjects: false,
    allowVirtualObjects: false,
    personType: false,
    locationType: false,
    aliasType: 0,
    externalOwner: '',
    subTypes: [],
    fields: [],
    parentFields: [],
    organizations: [],
    reservationTemplates: [],
    documentation: '',
    history: [],
  });

  // EVENT HANDLERS
  const onChangeSelectHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event, limitationValue);
  };

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event, limitationValue);
  };

  const onChangeCheckboxHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
  ) => {
    onChange(property, event.target.checked);
  };

  const findLimitations = (fieldType: any) => {
    return limitations[`${fieldType}.length`];
  };

  if (field) {
    return (
      <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
        <Collapse.Panel header={language.preferences} key='3'>
          {field.fieldType === EFieldType.COMMENT ||
          field.fieldType === EFieldType.TEXT ||
          field.fieldType === EFieldType.CATEGORY ||
          field.fieldType === EFieldType.EMAIL ||
          field.fieldType === EFieldType.NON_SEARCHABLE_TEXT ||
          field.fieldType === EFieldType.SIGNATURE ||
          field.fieldType === EFieldType.TELEPHONE ||
          field.fieldType === EFieldType.URL ? (
            <Form.Item
              label={language.filter}
              tooltip={language.help_filter_fields}
            >
              <Input.Group compact>
                <AutoComplete
                  style={{ width: '100%' }}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  size='small'
                  onChange={(value: any) => {
                    if (
                      field.fieldType === EFieldType.TEXT &&
                      new TextEncoder().encode(value).length >
                        limitations['text.filter']
                    ) {
                      return;
                    } else if (
                      field.fieldType === EFieldType.EMAIL &&
                      new TextEncoder().encode(value).length >
                        limitations['email.filter']
                    ) {
                      return;
                    } else if (
                      field.fieldType === EFieldType.COMMENT &&
                      new TextEncoder().encode(value).length >
                        limitations['comment.filter']
                    ) {
                      return;
                    } else if (
                      field.fieldType === EFieldType.NON_SEARCHABLE_REFERENCE &&
                      new TextEncoder().encode(value).length >
                        limitations['nonSearchableReference.filter']
                    ) {
                      return;
                    } else if (
                      field.fieldType === EFieldType.NON_SEARCHABLE_TEXT &&
                      new TextEncoder().encode(value).length >
                        limitations['nonSearchableText.filter']
                    ) {
                      return;
                    } else if (
                      field.fieldType === EFieldType.SIGNATURE &&
                      new TextEncoder().encode(value).length >
                        limitations['signature.filter']
                    ) {
                      return;
                    } else if (
                      field.fieldType === EFieldType.TELEPHONE &&
                      new TextEncoder().encode(value).length >
                        limitations['telephone.filter']
                    ) {
                      return;
                    } else if (
                      field.fieldType === EFieldType.URL &&
                      new TextEncoder().encode(value).length >
                        limitations['url.filter']
                    ) {
                      return;
                    } else if (
                      new TextEncoder().encode(value).length >
                      limitations.filter
                    ) {
                      return;
                    }
                    onChangeSelectHandler(value, 'filter');
                  }}
                  value={field.filter}
                  options={Object.entries(EPreferencesFilter).map(
                    ([key, value]) => ({ value: value }),
                  )}
                />
              </Input.Group>
            </Form.Item>
          ) : null}
          {field.fieldType === EFieldType.COMMENT ||
          field.fieldType === EFieldType.TEXT ||
          field.fieldType === EFieldType.CATEGORY ||
          field.fieldType === EFieldType.EMAIL ||
          field.fieldType === EFieldType.NON_SEARCHABLE_TEXT ||
          field.fieldType === EFieldType.SIGNATURE ||
          field.fieldType === EFieldType.TELEPHONE ||
          field.fieldType === EFieldType.URL ? (
            <Form.Item
              label={language.length}
              tooltip={`${language.help_length_fields}. For the field type '${
                field.fieldType
              }' the max length is ${findLimitations(field.fieldType)} bytes.`}
            >
              <InputNumber
                size='small'
                min={0}
                max={findLimitations(field.fieldType)}
                defaultValue={field.length}
                value={field.length}
                onChange={(value: any) => {
                  onChangeSelectHandler(value, 'length');
                }}
              />
            </Form.Item>
          ) : null}
          {field.fieldType === EFieldType.INTEGER ? (
            <Form.Item
              label={language.min_max}
              tooltip={language.help_min_max_fields}
            >
              <Input.Group style={{ width: 100 }}>
                <InputNumber
                  size='small'
                  min={0}
                  max={100}
                  defaultValue={field.min}
                  value={field.min}
                  onChange={(value: any) => {
                    if (
                      new TextEncoder().encode(value).length <
                      limitations['integer.min']
                    ) {
                      return;
                    }
                    onChangeSelectHandler(value, 'min');
                  }}
                />
              </Input.Group>
              <p className='semicolon-divider'> / </p>
              <Input.Group style={{ width: 100 }}>
                <InputNumber
                  size='small'
                  min={0}
                  defaultValue={field.max}
                  value={field.max}
                  onChange={(value: any) =>
                    onChangeSelectHandler(
                      value,
                      'max',
                      limitations['integer.max'],
                    )
                  }
                />
              </Input.Group>
            </Form.Item>
          ) : null}
          {field.fieldType === EFieldType.LENGTH ? (
            <>
              <p className='small-heading-text'>{`${language.min_max}:`}</p>
              <TEToolTip helpText={language.help_min_max_fields}>
                <div className='length-input' style={{ marginBottom: 24 }}>
                  <Form.Item className='length-input' style={{ margin: 0 }}>
                    {FromSecondsInputNumber(
                      field.min,
                      onChangeSelectHandler,
                      'min',
                      limitations['length.min'],
                    )}
                  </Form.Item>
                  <p className='semicolon-divider'> / </p>
                  <Form.Item className='length-input' style={{ margin: 0 }}>
                    {FromSecondsInputNumber(
                      field.max,
                      onChangeSelectHandler,
                      'max',
                      limitations['length.max'],
                    )}
                  </Form.Item>
                </div>{' '}
              </TEToolTip>
            </>
          ) : null}
          <Form.Item
            label={language.sum_type}
            tooltip={language.help_sum_type_fields}
          >
            <Select
              size='small'
              showSearch
              onFocus={onFocus}
              onBlur={onBlur}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={field.sumType}
              onChange={(value: any) => onChangeSelectHandler(value, 'sumType')}
              disabled={field.fieldType !== EFieldType.INTEGER}
            >
              {arrayToChooseFrom.map((type: any) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={language.top_level_type}
            tooltip={language.help_top_level_type_fields}
          >
            <Select
              size='small'
              value={field.topLevelType}
              onChange={(value: any) =>
                onChangeSelectHandler(value, 'topLevelType')
              }
              showSearch
              onFocus={onFocus}
              onBlur={onBlur}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={
                !(
                  field.fieldType === EFieldType.CATEGORY ||
                  field.fieldType === EFieldType.CHECKBOX
                )
              }
            >
              {arrayToChooseFrom.map((type: any) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={language.title_type}
            tooltip={language.help_title_type_fields}
          >
            <Select
              size='small'
              showSearch
              value={field.titleType}
              onChange={(value: any) =>
                onChangeSelectHandler(value, 'titleType')
              }
              onFocus={onFocus}
              onBlur={onBlur}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={
                !(
                  field.fieldType === EFieldType.CATEGORY ||
                  field.fieldType === EFieldType.CHECKBOX
                )
              }
            >
              {arrayToChooseFrom.map((type: any) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={language.ref_separator}
            tooltip={language.help_ref_separator_fields}
          >
            <Input
              size='small'
              defaultValue={field.refSeparator}
              value={field.refSeparator}
              onChange={(event: any) =>
                onChangeHandler(
                  event.target.value,
                  'refSeparator',
                  limitations.refSeparator,
                )
              }
              disabled={
                !(
                  field.fieldType === EFieldType.REFERENCE ||
                  field.fieldType === EFieldType.NON_SEARCHABLE_REFERENCE
                )
              }
            />
          </Form.Item>
          <Form.Item>
            <Checkbox
              onChange={(value: any) =>
                onChangeCheckboxHandler(value, 'searchableInReservationList')
              }
              checked={field.searchableInReservationList}
              disabled={
                !(
                  field.fieldType === EFieldType.CATEGORY ||
                  field.fieldType === EFieldType.CHECKBOX
                )
              }
            >
              {language.searchable_in_reservation}
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              onChange={(value: any) =>
                onChangeCheckboxHandler(value, 'reservationTextField')
              }
              checked={field.reservationTextField}
              disabled
            >
              {language.reservation_in_text_field}
            </Checkbox>
          </Form.Item>
          <TEItem helpText={language.help_mandatory_fields}>
            <Checkbox
              onChange={(value: any) =>
                onChangeCheckboxHandler(value, 'mandatory')
              }
              checked={field.mandatory}
              disabled={
                !!(
                  field.reservationTextField === true ||
                  field.fieldType === EFieldType.CHECKBOX ||
                  field.fieldType === EFieldType.COMMENT ||
                  field.fieldType === EFieldType.LENGTH
                )
              }
            >
              {language.mandatory}
            </Checkbox>
          </TEItem>
          <TEItem helpText={language.help_unique_fields}>
            <Checkbox
              onChange={(value: any) =>
                onChangeCheckboxHandler(value, 'unique')
              }
              checked={field.unique}
              disabled={
                !!(
                  field.reservationTextField === true ||
                  field.fieldType === EFieldType.CHECKBOX ||
                  field.fieldType === EFieldType.COMMENT ||
                  field.fieldType === EFieldType.LENGTH ||
                  field.fieldType === EFieldType.URL ||
                  field.fieldType === EFieldType.CATEGORY ||
                  field.fieldType === EFieldType.INTEGER ||
                  field.fieldType === EFieldType.REFERENCE ||
                  field.fieldType === EFieldType.NON_SEARCHABLE_REFERENCE
                )
              }
            >
              {language.unique}
            </Checkbox>
          </TEItem>
          <TEItem helpText={language.help_multiple_fields}>
            <Checkbox
              onChange={(value: any) =>
                onChangeCheckboxHandler(value, 'multiple')
              }
              checked={field.multiple}
              disabled={
                !!(
                  field.reservationTextField === true ||
                  field.fieldType === EFieldType.CHECKBOX ||
                  field.fieldType === EFieldType.COMMENT ||
                  field.fieldType === EFieldType.LENGTH ||
                  field.fieldType === EFieldType.REFERENCE ||
                  field.fieldType === EFieldType.NON_SEARCHABLE_REFERENCE
                )
              }
            >
              {language.multiple}
            </Checkbox>
          </TEItem>
          <TEItem helpText={language.help_editable_fields}>
            <Checkbox
              onChange={(value: any) =>
                onChangeCheckboxHandler(value, 'editable')
              }
              checked={field.editable}
              disabled={
                !!(
                  field.reservationTextField === true ||
                  field.fieldType === EFieldType.CHECKBOX ||
                  field.fieldType === EFieldType.COMMENT ||
                  field.fieldType === EFieldType.LENGTH ||
                  field.fieldType === EFieldType.REFERENCE ||
                  field.fieldType === EFieldType.NON_SEARCHABLE_REFERENCE ||
                  field.fieldType === EFieldType.NON_SEARCHABLE_TEXT ||
                  field.fieldType === EFieldType.SIGNATURE ||
                  field.fieldType === EFieldType.EMAIL ||
                  field.fieldType === EFieldType.URL ||
                  field.fieldType === EFieldType.TELEPHONE
                )
              }
            >
              {language.editable}
            </Checkbox>
          </TEItem>
          <TEItem helpText={language.help_searchable_fields}>
            <Checkbox
              onChange={(value: any) =>
                onChangeCheckboxHandler(value, 'searchable')
              }
              checked={field.searchable}
              disabled
            >
              {language.searchable}
            </Checkbox>
          </TEItem>
          <TEItem helpText={language.help_listable_fields}>
            <Checkbox
              onChange={(value: any) =>
                onChangeCheckboxHandler(value, 'listable')
              }
              checked={field.listable}
              disabled
            >
              {language.listable}
            </Checkbox>
          </TEItem>
          <TEItem helpText={language.help_primary_fields}>
            <Checkbox
              onChange={(value: any) =>
                onChangeCheckboxHandler(value, 'primary')
              }
              checked={field.primary}
              disabled
            >
              {language.primary}
            </Checkbox>
          </TEItem>
          <TEItem helpText={language.help_sortable_fields}>
            <Checkbox
              onChange={(value: any) =>
                onChangeCheckboxHandler(value, 'sortable')
              }
              checked={field.sortable}
              disabled
            >
              {language.sortable}
            </Checkbox>
          </TEItem>
          <Form.Item
            label={language.virtual_category}
            tooltip={`(Maximum size of ${limitations.virtualCategory} bytes)`}
          >
            <Input
              size='small'
              defaultValue={field.virtualCategory}
              value={field.virtualCategory}
              onChange={(event: any) =>
                onChangeHandler(
                  event.target.value,
                  'virtualCategory',
                  limitations.virtualCategory,
                )
              }
              disabled={field.fieldType !== EFieldType.CATEGORY}
            />
          </Form.Item>
          <Form.Item
            label={language.abstract_category}
            tooltip={`(Maximum size of ${limitations.abstractCategory} bytes)`}
          >
            <Input
              size='small'
              defaultValue={field.abstractCategory}
              value={field.abstractCategory}
              onChange={(event: any) =>
                onChangeHandler(
                  event.target.value,
                  'abstractCategory',
                  limitations.abstractCategory,
                )
              }
              disabled={field.fieldType !== EFieldType.CATEGORY}
            />
          </Form.Item>
          <Form.Item
            tooltip={`(Maximum size of ${limitations.defaultValue} bytes)`}
            label={language.default_value}
          >
            {RenderElement(
              field.fieldType,
              field.defaultValue,
              'defaultValue',
              field.categories,
              onChangeHandler,
            )}
          </Form.Item>
        </Collapse.Panel>
      </Collapse>
    );
  }
  return null;
};

/* ---------------------------------------------------------------------- */

type PropsCategories = {
  categories: Array<any>;
  onChange: Function;
  field: any;
};

export const FieldsCategories = ({
  field,
  categories,
  onChange,
}: PropsCategories) => {
  if (field.fieldType === EFieldType.CATEGORY || field.fieldType === 'Category') {
    return (
      <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
        <Collapse.Panel header={language.categories} key='4'>
          <FieldTable
            categories={categories}
            onCategoriesChanged={onChange}
            disabled={field.editable === true}
          />
        </Collapse.Panel>
      </Collapse>
    );
  } else
    return (
      <Collapse
        defaultActiveKey={['1']}
        ghost
        style={{ fontSize: 18 }}
      ></Collapse>
    );
};

/* ---------------------------------------------------------------------- */
type PropsReferences = {
  onChange: Function;
  fields: Array<any>;
  referenceFields: Array<any>;
  field: any;
};

export const FieldsReferences = ({
  field,
  fields,
  referenceFields,
  onChange,
}: PropsReferences) => {
  // Data with ALL available reference fields for the selected field type
  const dataSourceFields: any[] = [];
  if (
    field.fieldType === EFieldType.TEXT ||
    field.fieldType === EFieldType.SIGNATURE ||
    field.fieldType === EFieldType.TELEPHONE
  ) {
    dataSourceFields.push(
      ...fields
        .filter((f: any) => f.fieldType === EFieldType.REFERENCE)
        .map((f: any) => ({
          key: f.id,
          name: f.extId,
          description: f.description,
          fieldType: f.fieldType,
        })),
    );
  } else if (field.fieldType === EFieldType.REFERENCE) {
    dataSourceFields.push(
      ...fields
        .filter((f: any) => f.fieldType === EFieldType.TEXT)
        .map((f: any) => ({
          key: f.id,
          name: f.extId,
          description: f.description,
          fieldType: f.fieldType,
        })),
    );
    dataSourceFields.push(
      ...fields
        .filter((f: any) => f.fieldType === EFieldType.SIGNATURE)
        .map((f: any) => ({
          key: f.id,
          name: f.extId,
          description: f.description,
          fieldType: f.fieldType,
        })),
    );
    dataSourceFields.push(
      ...fields
        .filter((f: any) => f.fieldType === EFieldType.TELEPHONE)
        .map((f: any) => ({
          key: f.id,
          name: f.extId,
          description: f.description,
          fieldType: f.fieldType,
        })),
    );
  } else if (
    field.fieldType === EFieldType.EMAIL ||
    field.fieldType === EFieldType.URL ||
    field.fieldType === EFieldType.NON_SEARCHABLE_TEXT
  ) {
    dataSourceFields.push(
      ...fields
        .filter((f: any) => f.fieldType === EFieldType.NON_SEARCHABLE_REFERENCE)
        .map((f: any) => ({
          key: f.id,
          name: f.extId,
          description: f.description,
          fieldType: f.fieldType,
        })),
    );
  } else if (field.fieldType === EFieldType.NON_SEARCHABLE_REFERENCE) {
    dataSourceFields.push(
      ...fields
        .filter((f: any) => f.fieldType === EFieldType.EMAIL)
        .map((f: any) => ({
          key: f.id,
          name: f.extId,
          description: f.description,
          fieldType: f.fieldType,
        })),
    );
    dataSourceFields.push(
      ...fields
        .filter((f: any) => f.fieldType === EFieldType.URL)
        .map((f: any) => ({
          key: f.id,
          name: f.extId,
          description: f.description,
          fieldType: f.fieldType,
        })),
    );
    dataSourceFields.push(
      ...fields
        .filter((f: any) => f.fieldType === EFieldType.NON_SEARCHABLE_TEXT)
        .map((f: any) => ({
          key: f.id,
          name: f.extId,
          description: f.description,
          fieldType: f.fieldType,
        })),
    );
  }

  // The already setted referenceFields
  const targetKeys = referenceFields.map((selectedField: any) => selectedField);

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const onSelection = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChange = (nextTargetKeys: any) => {
    onChange(
      'referenceFields',
      nextTargetKeys.map((key: string) => key),
    );
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.references} key='4'>
        <Form.Item label={`${language.reference_fields}`}>
          <Transfer
            disabled={
              field.fieldType === EFieldType.CATEGORY ||
              field.fieldType === EFieldType.LENGTH ||
              field.fieldType === EFieldType.INTEGER ||
              field.fieldType === EFieldType.COMMENT ||
              field.fieldType === EFieldType.CHECKBOX
            }
            dataSource={dataSourceFields}
            showSearch
            filterOption={(input: any, option: any) =>
              option.name.toLowerCase().indexOf(input.toLowerCase()) > -1
            }
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onSelectionChange}
            onSelectChange={onSelection}
            render={(item) => (
              <p style={{ margin: 0 }}>
                {item.name} ({item.fieldType})
              </p>
            )}
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */
type PropsConnections = {
  onChange: Function;
  connectionTypes: Array<any>;
  connectionTemplates: Array<any>;
  connectionOrganizations: Array<any>;
};

export const FieldsConnections = ({
  connectionTypes,
  connectionTemplates,
  connectionOrganizations,
  onChange,
}: PropsConnections) => {
  // TYPES
  // All available types
  const types: TType[] = useSelector(typesSelector);

  // Data with all available types
  const dataSourceTypes = types.map((type: any) => ({
    key: String(type.id),
    title: type.name,
    description: type.description,
  }));

  const targetKeysTypes = connectionTypes?.map((selectedType: any) =>
    String(selectedType),
  );
  const [selectedKeysTypes, setSelectedKeysTypes] = useState<string[]>([]);

  const onSelectionTypes = (
    sourceSelectedKeys: any,
    targetSelectedKeys: any,
  ) => {
    setSelectedKeysTypes([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChangeTypes = (nextTargetKeys: any) => {
    onChange(
      'types',
      nextTargetKeys.map((key: string) => parseInt(key)),
    );
  };

  // Reservation templates
  const reservationTemplates: TReservationTemplate[] = useSelector(
    reservationTemplatesSelector,
  );

  // Data with ALL available res.templates
  const dataSourceTemplates = reservationTemplates.map((template: any) => ({
    key: String(template.id),
    title: template.name,
    description: template.description,
  }));

  const targetKeysTemplates = connectionTemplates?.map(
    (selectedTemplate: any) => String(selectedTemplate),
  );
  const [selectedKeysTemplates, setSelectedKeysTemplates] = useState<string[]>(
    [],
  );

  const onSelectionTemplates = (
    sourceSelectedKeysTemplates: any,
    targetSelectedKeysTemplates: any,
  ) => {
    setSelectedKeysTemplates([
      ...sourceSelectedKeysTemplates,
      ...targetSelectedKeysTemplates,
    ]);
  };

  const onSelectionChangeTemplates = (nextTargetKeysTemplates: any) => {
    onChange(
      'reservationTemplates',
      nextTargetKeysTemplates.map((key: string) => parseInt(key)),
    );
  };

  // Organizations
  const organizations: TOrganizationTreeNode[] = useSelector(
    organizationsSelector,
  );
  const dataSourceOrganizations = organizations.map((org: any) => ({
    key: String(org.id),
    title: org.name,
    description: org.description,
  }));

  const targetKeysOrganizations = connectionOrganizations?.map(
    (selectedOrg: any) => String(selectedOrg),
  );
  const [selectedKeysOrganizations, setSelectedKeysOrganizations] = useState<
    string[]
  >([]);

  const onSelectionOrganizations = (
    sourceSelectedKeysOrganizations: any,
    targetSelectedKeysOrganizations: any,
  ) => {
    setSelectedKeysOrganizations([
      ...sourceSelectedKeysOrganizations,
      ...targetSelectedKeysOrganizations,
    ]);
  };

  const onSelectionChangeOrganizations = (nextTargetKeysOrganizations: any) => {
    onChange(
      'organizations',
      nextTargetKeysOrganizations.map((key: string) => parseInt(key)),
    );
  };
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.connections} key='4'>
        <Form.Item label={`${language.types}`}>
          <Transfer
            dataSource={dataSourceTypes}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeysTypes}
            selectedKeys={selectedKeysTypes}
            onChange={onSelectionChangeTypes}
            onSelectChange={onSelectionTypes}
            render={(item) => item.title}
          />
        </Form.Item>
        <Form.Item label={`${language.reservation_templates}`}>
          <Transfer
            dataSource={dataSourceTemplates}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeysTemplates}
            selectedKeys={selectedKeysTemplates}
            onChange={onSelectionChangeTemplates}
            onSelectChange={onSelectionTemplates}
            render={(item) => item.title}
          />
        </Form.Item>
        <Form.Item label={`${language.organizations}`}>
          <Transfer
            dataSource={dataSourceOrganizations}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeysOrganizations}
            selectedKeys={selectedKeysOrganizations}
            onChange={onSelectionChangeOrganizations}
            onSelectChange={onSelectionOrganizations}
            render={(item) => item.title}
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};
