import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// i18N
import intl from '../../../i18n/intl';

// ACTIONS
import { setBreadcrumbs } from '../../../slices/ui.slice';
import { objectsSelector } from '../../../slices/object.slice';
import {
  fetchLimitationsOrganizationsSelector,
  fetchLimitationsOrganizations,
} from '../../../slices/organization.slice';
import {
  copyOrganizationNode,
  createEmptyOrganizationNode,
  createOrganizationNode,
  deleteOrganizationNode,
  fetchOrganizationTreeNodes,
  getOrganizationNode,
  organizationTreeNodesSelector,
  selectedOrganizationNodeSelector,
  setSelectedOrganization,
  updateOrganizationNode,
} from '../../../slices/organizationNode.slice';

// STYLES
import { Form, Input } from 'antd';

// TYPES
import {
  TOrganizationNode,
  TOrganizationTreeNode,
} from '../../../types/organization.type';
import { TTEObject } from '../../../types/object.type';
import { TTreeListData } from '../../../types/treeListData.type';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import { OrganizationsGeneralSettings } from '../../../components/OrganizationsComponent';
import TreeListView from '../../../components/List/TreeListView';

// UTILS
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

const language: any = intl.messages;

type Props = {
  customerSignature: string;
};

// Function for creating the organization hierarchy tree
const createOrganizationTree = (organizations: TOrganizationTreeNode[]) => {
  return organizations?.map((org: any) => {
    const node: TTreeListData = {
      title: org.name,
      key: org.id,
      children: createOrganizationTree(org.children),
    };
    return node;
  });
};

/* -------------------------------------------------------------------------- */

const OrganizationsPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const limitations = useSelector(fetchLimitationsOrganizationsSelector);
  const selectedOrganizationNode: any = useSelector(
    selectedOrganizationNodeSelector,
  );

  const organizationTreeNodes: TOrganizationTreeNode[] = useSelector(
    organizationTreeNodesSelector,
  );
  const objects: TTEObject[] = useSelector(objectsSelector);

  const [selectedOrgTreeNode, setSelectedOrgTreeNode] = useState<any>();

  const onOrganizationSelected = (selectedValue: string) => {
    dispatch(getOrganizationNode(selectedValue)); // @todo: Check with breakpoint
    const currentTreeNode: TOrganizationTreeNode | null = findTreeNodeWithId(
      selectedValue,
      organizationTreeNodes,
    );
    if (currentTreeNode) {
      setSelectedOrgTreeNode(currentTreeNode);
    }
  };

  const findTreeNodeWithId = (
    id: string,
    treeNodes: TOrganizationTreeNode[],
  ) => {
    let foundNode: TOrganizationTreeNode | null = null;
    treeNodes.forEach((node: TOrganizationTreeNode) => {
      if (foundNode) {
        return;
      }
      if (node.id === id) {
        foundNode = node;
      } else {
        foundNode = findTreeNodeWithId(id, node.children);
      }
    });
    return foundNode;
  };

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/organizations-users`,
          label: `${language.organizations_users}`,
        },
        {
          path: `/organizations-users/organizations`,
          label: `${language.organizations}`,
        },
        {
          path: `/organizations-users/organizations`,
          label: `${selectedOrganizationNode?.name}`,
        },
      ]),
    );
  }, [
    dispatch,
    selectedOrganizationNode?.organizationId,
    selectedOrganizationNode?.name,
    customerSignature,
  ]);

  useEffect(() => {
    dispatch(fetchOrganizationTreeNodes());
    dispatch(fetchLimitationsOrganizations());
  }, [dispatch]);

  // Creating the organization tree
  const treeOrganizations: TOrganizationTreeNode[] =
    organizationTreeNodes || [];
  let treeListData: TTreeListData[] = [{ title: '', key: '', children: [] }];
  if (treeOrganizations.length > 0) {
    treeListData = createOrganizationTree(treeOrganizations);
  }
  // Objects related to the selected organization
  const relatedObjects = objects.filter((object: TTEObject) =>
    object.organizations.includes(
      parseInt(selectedOrganizationNode?.organizationId),
    ),
  );

  // EVENT HANDLERS
  // New organization
  const onPlusButton = (selectedOrganization: TOrganizationNode) => {
    const parentNodes: any[] = [];
    // When creating a new org. we need to include its parents,
    // which will depend upon where in the org.tree the user 'stands'
    // when clicking the plus-button
    if (selectedOrganization !== undefined) {
      parentNodes.push(selectedOrganization.organizationId);
      dispatch(createEmptyOrganizationNode(parentNodes));
    } else {
      dispatch(createEmptyOrganizationNode());
    }
  };

  // Deletes choosen organization
  const onDeleteButton = () => {
    dispatch(deleteOrganizationNode(selectedOrganizationNode));
  };

  // Save changes function
  const onSaveChangesOrganizationsButton = () => {
    if (selectedOrganizationNode.organizationId === 'new') {
      dispatch(createOrganizationNode(selectedOrganizationNode));
    }
    dispatch(updateOrganizationNode(selectedOrganizationNode));
  };

  // Handle changes function
  const onHandleChange = (property: string, value: string) => {
    dispatch(
      setSelectedOrganization({
        ...selectedOrganizationNode,
        [property]: value,
      }),
    );
  };

  const onHandleChangeOfParentNodes = async (
    newParentNodeIds: string[], // nya föräldrarna
    orgNodeId: number[], // dom som får nya föräldrar
  ) => {
    // 1. Visa upp rätt förflyttning i sub-org vyn
    // 2. Skicka rätt anrop till servern, dvs. skicka in barnet (orgNode) och ge den parentNodesIds
  };

  // Discard changes function
  const onDiscard = () => {
    onOrganizationSelected(selectedOrganizationNode.organizationId);
  };

  // Copy org. function
  const onCopy = () => {
    dispatch(copyOrganizationNode(selectedOrganizationNode));
  };

  const [form] = Form.useForm();

  return (
    <>
      <ListView
        sectionHeading={language.organizations}
        number={0} // @todo: Change! Shall show the number of organizationNodes shown
        onSelect={() => onPlusButton(selectedOrganizationNode)}
      >
        <TreeListView
          data={treeListData}
          onSelect={onOrganizationSelected}
          automaticallyExpand
        />
      </ListView>
      <div className='inner-content--wrapper'>
        <SectionHeader
          listChoice={
            selectedOrganizationNode?.name
              ? `${selectedOrganizationNode?.name}`
              : `${language.organization}`
          }
          changeHandler={onSaveChangesOrganizationsButton}
          discardHandler={onDiscard}
          displayProp={false}
          history={false}
          // isDisabled={isSavedDisabled(selectedOrganization, organizationNodes)}
          // @todo: Need to redo above function (I guess), to work for org.nodes
        />
        <Form layout='vertical' form={form}>
          <div style={{ position: 'relative' }}>
            <OrganizationsGeneralSettings
              organizationNode={selectedOrganizationNode}
              orgTreeNode={selectedOrgTreeNode}
              onChange={onHandleChange}
              limitations={limitations}
              relatedObjects={relatedObjects}
              onDelete={onDeleteButton}
              onCopy={onCopy}
              treeListData={treeListData}
              onHandleChangeOfParentNodes={onHandleChangeOfParentNodes}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default OrganizationsPage;
