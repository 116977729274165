import { THistory } from './dateHistory.type';

export enum EOperator {
  E = '=',
  GTE = '>=',
  GT = '>',
  LTE = '<=',
  LT = '<',
}

export enum EMatchMode {
  MATCH_ONE = 'MATCH_ONE',
  MATCH_ALL = 'MATCH_ALL',
}

export type TFieldRelation = {
  name: string;
  id: number;
  description: string;
  sourceField: number | null;
  matchField?: number | null | any;
  operator?: EOperator | null | any;
  matchMode?: EMatchMode | null;
  reservationTemplates: Array<number>;
  documentation: string;
  history: Array<THistory>;
};

export class FieldRelation {
  static createEmpty = (): TFieldRelation => ({
    name: '',
    id: 0,
    description: '',
    sourceField: null,
    matchField: null,
    operator: null,
    matchMode: null,
    reservationTemplates: [],
    documentation: '',
    history: [],
  });
}
