import { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// i18N
import intl from '../../../i18n/intl';

// REDUX
import { selectedObjectsSelector } from '../../../slices/object.slice';

// STYLES
import './index.scss';
import {
  Table,
  Space,
  Button,
  Popconfirm,
  DatePicker,
  Select,
  Tooltip,
} from 'antd';
import { DeleteOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';

// TYPES
import {
  TDateRange,
  TTEObject,
  DateRange,
  TDateRangeRelations,
  DateRangeRelations,
} from '../../../types/object.type';

// UTILS
import { getExtIdName, getNameOnObject } from '../../../utils/getNameOn';

// COMPONENTS
import { SelectMultipleModal } from '../../Modals';

type PropsMemberObjects = {
  objectMembers: Array<TDateRange>;
  objects: Array<TTEObject>;
  onChange: any;
};

type PropsRelationObjects = {
  objectRelations: Array<TDateRangeRelations>;
  objects: Array<TTEObject>;
  onChange: any;
};

const language: any = intl.messages;

export const MembersTable = ({
  objectMembers,
  objects,
  onChange,
}: PropsMemberObjects) => {
  const selectedObjects: any = useSelector(selectedObjectsSelector);

  // EVENT HANDLERS
  const onChangeDateRange = (dates: any, objectMember: any) => {
    const index = objectMembers.indexOf(objectMember);
    const updatedObjectMember = Object.assign({}, objectMember);
    updatedObjectMember.start = parseInt(dates[0].format('X'));
    updatedObjectMember.end = parseInt(dates[1].format('X'));
    const newObjectMembers = [...objectMembers];
    newObjectMembers.splice(index, 1, updatedObjectMember);
    onChange(newObjectMembers, 'members', selectedObjects);
  };

  const addDateRange = (dateRange: any) => {
    const newDateRange = [...objectMembers];
    newDateRange.push(DateRange.create(dateRange));
    onChange(newDateRange, 'members', selectedObjects);
  };

  const onDeleteHandler = (objectMemberId: any) => {
    onChange(
      objectMembers.filter(
        (objectMember: any) => objectMember.objectId !== objectMemberId,
      ),
      'members',
      selectedObjects,
    );
  };

  // All available objects = objects - choosen object(s)
  const availableObjects = objects.filter(
    (object: any) =>
      !_.some(objectMembers, (objectType: any) => objectType.id === object.id),
  );

  const columns = [
    {
      title: `${language.members}`,
      dataIndex: 'objectId',
      key: 'objectId',
      width: '220px',
      render: (objectId: number) => (
        <span key={objectId}>{getNameOnObject(objects, objectId)}</span>
      ),
    },
    {
      title: 'Daterange',
      dataIndex: 'start',
      key: 'start',
      width: '280px',
      render: (start: any, record: any) => (
        <DatePicker.RangePicker
          format='YYYY-MM-DD'
          size='small'
          value={[moment.unix(start).utc(), moment.unix(record.end).utc()]}
          onChange={(dates: any) => {
            onChangeDateRange(dates, record.objectMember);
          }}
        />
      ),
    },
    {
      title: `${language.delete}`,
      key: 'action',
      render: (record: any) => (
        <Space size='small'>
          <Popconfirm
            title={language.sure_to_delete_type}
            onConfirm={() => onDeleteHandler(record.objectId)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Tooltip title={language.delete}>
              <Button
                size='small'
                danger
                ghost
                type='default'
                className='types-list-action-button-delete'
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Select
        size='small'
        onChange={addDateRange}
        placeholder={language.select_member_type}
        showSearch
        className='object-selector'
      >
        {availableObjects.map((object: TTEObject) => (
          <Select.Option value={object.id} key={object.id} label={object.extId}>
            {object.extId}
          </Select.Option>
        ))}
      </Select>
      <Table
        size='small'
        dataSource={objectMembers?.map((objectMember: any) => ({
          key: objectMember.objectId,
          objectMember,
          ...objectMember,
        }))}
        columns={columns}
      />
    </>
  );
};

export const RelationsTable = ({
  objectRelations,
  objects,
  onChange,
}: PropsRelationObjects) => {
  const selectedObjects: any = useSelector(selectedObjectsSelector);

  // EVENT HANDLERS
  const onChangeDateRange = (dates: any, objectRelation: any) => {
    const index = objectRelations.indexOf(objectRelation);
    const updatedObjectRelation = Object.assign({}, objectRelation);
    updatedObjectRelation.start = parseInt(dates[0].format('X'));
    updatedObjectRelation.end = parseInt(dates[1].format('X'));
    const newObjectRelations = [...objectRelations];
    newObjectRelations.splice(index, 1, updatedObjectRelation);
    onChange(newObjectRelations, 'relations', selectedObjects);
  };

  const addDateRange = (objectId: number) => {
    const newDateRange = [...objectRelations];
    newDateRange.push(DateRangeRelations.create(objectId));
    onChange(newDateRange, 'relations', selectedObjects);
  };

  const onDeleteHandler = (removedObjectRelation: any) => {
    onChange(
      objectRelations.filter(
        (objectRelation: any) => objectRelation !== removedObjectRelation,
      ),
      'relations',
      selectedObjects,
    );
  };

  const [showModal, setShowModal] = useState<any>(false);
  const [objectId, setObjectId] = useState<any>();
  const [modalValue, setModalValue] = useState<any>();
  const [selectedObjectRelation, setSelectedObjectRelation] = useState<any>();

  const updateObjectId = (value: any) => {
    setObjectId(value);
  };

  const onModalOpen = (modalId: string, objectRelation: any) => {
    setShowModal(modalId);
    setModalValue(objectRelation);
    setSelectedObjectRelation(objectRelation);
  };

  const onModalClose = () => {
    setShowModal(false);
    setModalValue(null);
    setSelectedObjectRelation(null);
  };

  // All available objects to choose from = objects - choosen object(s)
  let objectsToChooseFrom: any = [];
  const avail = () => {
    selectedObjectRelation?.objectIds.forEach((objectId: any) => {
      objectsToChooseFrom = objects.filter(
        (object: any) => object.id !== objectId,
      );
    });
  };
  avail();

  // Objects available for removal
  const objectsAvailableForRemoval = selectedObjectRelation?.objectIds;

  const onHandleAdd = (value: any, objectRelation: any) => {
    const index = objectRelations?.indexOf(objectRelation);
    const updatedObject = Object.assign({}, objectRelation);
    updatedObject.objectIds = [...updatedObject.objectIds];
    value.forEach((v: any) => {
      updatedObject.objectIds.push(v);
    });
    const newArray = [...objectRelations];
    newArray.splice(index, 1, updatedObject);
    onChange(newArray, 'relations', selectedObjects);
    setShowModal(false);
  };

  const onHandleRemove = (value: any, objectRelation: any) => {
    const index = objectRelations?.indexOf(objectRelation);
    const updatedObject = Object.assign({}, objectRelation);
    updatedObject.objectIds = [...updatedObject.objectIds];
    value.forEach((v: any) => {
      updatedObject.objectIds = updatedObject.objectIds.filter(
        (objectId: any) => objectId !== v,
      );
    });
    const newArray = [...objectRelations];
    newArray.splice(index, 1, updatedObject);
    onChange(newArray, 'relations', selectedObjects);
    setShowModal(false);
  };

  const columns = [
    {
      title: `Relation objects`,
      dataIndex: 'objectIds',
      key: 'objectIds',
      width: '320px',
      render: (objectIds: any) => (
        <span>
          {objectIds.map((objectId: number) => (
            <span key={objectId}>{getExtIdName(objects, objectId)}, </span>
          ))}
        </span>
      ),
    },
    {
      title: `Add relation object(s)`,
      key: 'addRelationObject',
      width: '180px',
      render: (record: any) => (
        <>
          <Button
            size='small'
            type='default'
            style={{ marginLeft: 6 }}
            onClick={() => onModalOpen('addObject', record.objectRelation)}
            icon={<PlusOutlined />}
          />
        </>
      ),
    },
    {
      title: `Remove relation object(s)`,
      key: 'removeRelationObject',
      width: '180px',
      render: (record: any) => (
        <>
          <Button
            size='small'
            type='default'
            style={{ marginLeft: 6 }}
            onClick={() => onModalOpen('removeObject', record.objectRelation)}
            icon={<MinusOutlined />}
          />
        </>
      ),
    },
    {
      title: 'Daterange',
      dataIndex: 'start',
      key: 'start',
      width: '280px',
      render: (start: number, record: any) => (
        <DatePicker.RangePicker
          format='YYYY-MM-DD'
          size='small'
          value={[moment.unix(start).utc(), moment.unix(record.end).utc()]}
          onChange={(dates: any) => {
            onChangeDateRange(dates, record.objectRelation);
          }}
        />
      ),
    },

    {
      title: `${language.delete}`,
      key: 'action',
      render: (record: any) => (
        <Space size='small'>
          <Popconfirm
            title={language.sure_to_delete_type}
            onConfirm={() => onDeleteHandler(record.objectRelation)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Tooltip title={language.delete}>
              <Button
                size='small'
                danger
                ghost
                type='default'
                className='types-list-action-button-delete'
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Select
        size='small'
        onChange={addDateRange}
        placeholder={language.select_relation_type}
        showSearch
        className='object-selector'
      >
        {objects.map((object: TTEObject) => (
          <Select.Option value={object.id} key={object.id}>
            {object.extId}
          </Select.Option>
        ))}
      </Select>
      <Table
        size='small'
        dataSource={objectRelations?.map(
          (objectRelation: TDateRangeRelations) => ({
            key: objectRelation.start,
            objectRelation,
            ...objectRelation,
          }),
        )}
        columns={columns}
      />
      <SelectMultipleModal
        title={'Add relation object(s)'}
        okText={'Save added relation object(s)'}
        onOK={() => onHandleAdd(objectId, modalValue)}
        visible={showModal === 'addObject'}
        onCancel={onModalClose}
        array={objectsToChooseFrom.map((object: any) => (
          <Select.Option key={object.id} value={object.id} label={object.extId}>
            {object.extId}
          </Select.Option>
        ))}
        update={updateObjectId}
        value={objectId}
      />
      <SelectMultipleModal
        title={'Remove relation object(s)'}
        okText={'Save removed relation object(s)'}
        onOK={() => onHandleRemove(objectId, modalValue)}
        visible={showModal === 'removeObject'}
        onCancel={onModalClose}
        array={objectsAvailableForRemoval?.map((object: any) => (
          <Select.Option
            key={object}
            value={object}
            label={getExtIdName(objects, object)}
          >
            {getExtIdName(objects, object)}
          </Select.Option>
        ))}
        update={updateObjectId}
        value={objectId}
      />
    </>
  );
};
