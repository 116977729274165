// TYPES
import { TAltDesignations } from './languages.type';
import { THistory } from './dateHistory.type';

export enum EFieldType {
  TEXT = 'TEXT',
  SIGNATURE = 'SIGNATURE',
  EMAIL = 'EMAIL',
  URL = 'URL',
  TELEPHONE = 'TELEPHONE',
  CATEGORY = 'CATEGORY',
  COMMENT = 'COMMENT',
  INTEGER = 'INTEGER',
  LENGTH = 'LENGTH',
  CHECKBOX = 'CHECKBOX',
  REFERENCE = 'REFERENCE',
  NON_SEARCHABLE_TEXT = 'NON_SEARCHABLE_TEXT',
  NON_SEARCHABLE_REFERENCE = 'NON_SEARCHABLE_REFERENCE',
}

export enum EPersonalDataCheck {
  NONE = 'NONE',
  YES = 'YES',
  NO = 'NO',
}

export enum EPreferencesFilter {
  CHOICE1 = '[0-9]',
  CHOICE2 = '[0-9- +]',
  CHOICE3 = '[0-9a-z]',
  CHOICE4 = '[0-9a-zA-ZåäöÅÄÖ_-]',
  CHOICE5 = '[@_-.0-9a-zA-Z]',
  CHOICE6 = '[@_-.0-9a-zA-ZåäöÅÄÖ]',
}

export type TField = {
  fieldType: string;
  name: string;
  id: number;
  extId: string;
  personalData: EPersonalDataCheck;
  description: string;
  altDesignations: Array<TAltDesignations>;
  filter: EPreferencesFilter | string;
  length: number;
  min: number;
  max: number;
  sumType: number;
  topLevelType: number;
  titleType: number;
  refSeparator: string;
  searchableInReservationList: boolean;
  reservationTextField: boolean;
  mandatory: boolean;
  unique: boolean;
  multiple: boolean;
  editable: boolean;
  searchable: boolean;
  listable: boolean;
  primary: boolean;
  sortable: boolean;
  virtualCategory: string;
  abstractCategory: string;
  defaultValue: string;
  checkSum: number;
  categories: Array<string>;
  referenceFields: Array<string>;
  types: Array<number>;
  reservationTemplates: Array<number>;
  organizations: Array<number>;
  documentation: string;
  history: Array<THistory>;
};

export class Field {
  static createEmpty = (): TField => ({
    fieldType: '',
    name: '',
    id: 0,
    extId: '',
    personalData: EPersonalDataCheck.NONE,
    description: '',
    altDesignations: [],
    filter: '',
    length: 0,
    min: 0,
    max: 100,
    sumType: 0,
    topLevelType: 0,
    titleType: 0,
    refSeparator: ', ',
    searchableInReservationList: false,
    reservationTextField: false,
    mandatory: false,
    unique: false,
    multiple: false,
    editable: false,
    searchable: false,
    listable: false,
    primary: false,
    sortable: false,
    virtualCategory: '',
    abstractCategory: '',
    defaultValue: '',
    checkSum: 0,
    categories: [],
    referenceFields: [],
    types: [],
    reservationTemplates: [],
    organizations: [],
    documentation: '',
    history: [],
  });
}
