import { useState } from 'react';
import { useSelector } from 'react-redux';

// REDUX
import { fieldsSelector } from '../../slices/field.slice';
import { typesSelector } from '../../slices/type.slice';
import { organizationsSelector } from '../../slices/organization.slice';
import { selectedObjectsSelector } from '../../slices/object.slice';
import { availabilityTypesSelector } from '../../slices/availabilityType.slice';

// i18N
import intl from '../../i18n/intl';

// STYLES
import {
  Form,
  Input,
  Collapse,
  Select,
  Checkbox,
  Transfer,
  InputNumber,
  Button,
} from 'antd';
import { PlusOutlined, MinusOutlined, EditOutlined } from '@ant-design/icons';
import './index.scss';

// TYPES
import {
  EPersonalInformation,
  EProperty,
  TTEFieldValue,
  TTEObject,
} from '../../types/object.type';
import { EFieldType, TField } from '../../types/field.type';
import { TType } from '../../types/type.type';
import { TOrganizationTreeNode } from '../../types/organization.type';
import { TAvailabilityRelationType } from '../../types/availabilityrelation.type';

// COMPONENTS
import { MembersTable, RelationsTable } from './Tables/index';
import { FieldCategory } from './FieldCategory';
import { TEToolTip, TEItem } from '../TEToolTip';

// UTILS
import {
  isFieldOnObject,
  isFieldIdOnObject,
  getNonReferenceFields,
} from '../../utils/objectUtils';
import {
  getNameOnAvailabilityType,
  getNameOnType,
} from '../../utils/getNameOn';
import { timeConverter } from '../../utils/timeConverter';

/* ------------------------------------------------------------------------- */

const language: any = intl.messages;

const onBlur = () => {};
const onFocus = () => {};

type PropsGeneral = {
  onChange: any;
  onChangeTwoProperties: any;
  object: any;
  limitations: any;
};

type PropsMembers = {
  onChange: any;
  objectMembers: any;
  objects: Array<TTEObject>;
};

type PropsRelations = {
  onChange: any;
  objectRelations: any;
  objects: Array<TTEObject>;
};

type PropsAvailabilityRelated = {
  onChange: any;
  availabilityRelations: any;
  objects: Array<TTEObject>;
  object: TTEObject;
};

type PropsOptionalRelated = {
  onChange: any;
  objectOptionalRelated: any;
  objects: Array<TTEObject>;
  object: TTEObject;
};

type PropsOrganizations = {
  onChange: any;
  objectOrganizations: any;
};

export const ObjectGeneralInformation = ({
  onChange,
  object,
  onChangeTwoProperties,
  limitations,
}: PropsGeneral) => {
  // EVENT HANDLERS
  const onChangeInputHandler = (object: any, event: any, property: string) => {
    const objects: any = [];
    objects.push(object);
    onChange(event.target.value, property, objects);
  };

  const onChangeCheckboxHandler = (
    object: any,
    event: any,
    property: string,
  ) => {
    const objects: any = [];
    objects.push(object);
    onChange(event.target.checked, property, objects);
  };

  const onChangeSelectHandler = (object: any, event: any, property: string) => {
    const objects: any = [];
    objects.push(object);
    onChange(event, property, objects);
  };

  // Setting all fields
  const fields: TField[] = useSelector(fieldsSelector);
  const availableFields = object?.teFields;
  const getNameFieldOnFields = (availableFields: Array<TTEFieldValue>) => {
    const fieldArray: Array<any> = [];
    // eslint-disable-next-line array-callback-return
    availableFields?.map((availableField: TTEFieldValue) => {
      // eslint-disable-next-line array-callback-return
      fields.map((field: TField) => {
        if (field.id === availableField.fieldId) {
          fieldArray.push({ ...field, values: availableField.values });
        }
      });
    });
    return fieldArray;
  };

  // Getting right name on types
  const types: TType[] = useSelector(typesSelector);

  const selectedObjects: any = useSelector(selectedObjectsSelector);

  const onChangeFieldValues = (
    fieldValue: any,
    index: number,
    fieldId: number,
  ) => {
    const field = Object.assign(
      {},
      object.teFields.find(
        (teField: TTEFieldValue) => teField.fieldId === fieldId,
      ),
    );
    field.values = [...field.values];
    field.values[index] = fieldValue;
    const newFields = [...object.teFields];
    let fieldIndex = -1;
    newFields.forEach((newField: any, idx: number) => {
      if (newField.fieldId === field.fieldId) {
        fieldIndex = idx;
      }
    });
    newFields.splice(fieldIndex, 1, field);
    onChange(newFields, 'teFields', selectedObjects);
  };

  const onAddFieldValue = (fieldId: number) => {
    const field = Object.assign(
      {},
      object.teFields.find(
        (teField: TTEFieldValue) => teField.fieldId === fieldId,
      ),
    );
    field.values = [...field.values];
    field.values.push('');
    const newFields = [...object.teFields];
    let fieldIndex = -1;
    newFields.forEach((newField: any, idx: number) => {
      if (newField.fieldId === field.fieldId) {
        fieldIndex = idx;
      }
    });
    newFields.splice(fieldIndex, 1, field);
    onChange(newFields, 'teFields', selectedObjects);
  };

  const onDeleteFieldValue = (fieldId: number, index: number) => {
    const field = Object.assign(
      {},
      object.teFields.find(
        (teField: TTEFieldValue) => teField.fieldId === fieldId,
      ),
    );
    field.values = [...field.values];
    field.values.splice(index, 1);
    const newFields = [...object.teFields];
    let fieldIndex = -1;
    newFields.forEach((newField: any, idx: number) => {
      if (newField.fieldId === field.fieldId) {
        fieldIndex = idx;
      }
    });
    newFields.splice(fieldIndex, 1, field);
    onChange(newFields, 'teFields', selectedObjects);
  };

  // Conditionals for rendering of right element based on fieldType
  const renderElements = (
    type: EFieldType,
    values: any,
    property: string,
    label: string,
    fieldId: number,
    fieldCategories: Array<any>,
    editable: boolean,
    multiple: boolean,
  ) => {
    if (
      type === EFieldType.TEXT ||
      type === EFieldType.SIGNATURE ||
      type === EFieldType.URL ||
      type === EFieldType.NON_SEARCHABLE_TEXT ||
      type === EFieldType.REFERENCE
    ) {
      return (
        <Form.Item label={`${label}:`} key={fieldId}>
          {values.map((value: any, index: number) => (
            <Input
              key={index}
              size='small'
              value={value}
              onChange={(event: any) =>
                onChangeFieldValues(event.target.value, index, fieldId)
              }
            />
          ))}
        </Form.Item>
      );
    } else if (type === EFieldType.TELEPHONE || type === EFieldType.EMAIL) {
      return (
        <Form.Item label={`${label}:`} key={fieldId}>
          {values.map((value: any, index: number) => (
            <div key={fieldId} className='field-wrapper'>
              <Input
                key={index}
                size='small'
                value={value}
                onChange={(event: any) =>
                  onChangeFieldValues(event.target.value, index, fieldId)
                }
              />
              <Button
                type='text'
                icon={<PlusOutlined />}
                onClick={() => onAddFieldValue(fieldId)}
              ></Button>
              {index !== 0 && (
                <Button
                  type='text'
                  icon={<MinusOutlined />}
                  onClick={() => onDeleteFieldValue(fieldId, index)}
                ></Button>
              )}
            </div>
          ))}
        </Form.Item>
      );
    } else if (type === EFieldType.NON_SEARCHABLE_REFERENCE) {
      return (
        <Form.Item label={`${label}:`} key={fieldId}>
          {values.map((value: any) => (
            <Input key={value} size='small' value={value} disabled />
          ))}
        </Form.Item>
      );
    } else if (type === EFieldType.COMMENT) {
      return (
        <Form.Item label={`${label}:`} key={fieldId}>
          {values.map((value: any, index: number) => (
            <Input.TextArea
              key={index}
              size='small'
              rows={4}
              value={value}
              onChange={(event: any) =>
                onChangeFieldValues(event.target.value, index, fieldId)
              }
            />
          ))}
        </Form.Item>
      );
    } else if (type === EFieldType.INTEGER || type === EFieldType.LENGTH) {
      return (
        <Form.Item label={`${label}:`} key={fieldId}>
          {values.map((value: any, index: number) => (
            <InputNumber
              key={index}
              size='small'
              value={value}
              onChange={(value: any) =>
                onChangeFieldValues(value, index, fieldId)
              }
            />
          ))}
        </Form.Item>
      );
    } else if (type === EFieldType.CHECKBOX) {
      return (
        <div key={fieldId}>
          {values.map((value: any, index: number) => (
            <Checkbox
              key={fieldId}
              checked={value}
              onChange={(event: any) =>
                onChangeFieldValues(event.target.checked, index, fieldId)
              }
            >
              {label}
            </Checkbox>
          ))}
        </div>
      );
    } else if (type === EFieldType.CATEGORY) {
      return (
        <FieldCategory
          key={fieldId}
          label={label}
          values={values}
          onChangeFieldValues={onChangeFieldValues}
          fieldId={fieldId}
          fieldCategories={fieldCategories}
          onAddFieldValue={onAddFieldValue}
          onDeleteFieldValue={onDeleteFieldValue}
          editable={editable}
          multiple={multiple}
        />
      );
    }
  };

  const [showEditType, setShowEditType] = useState(false);
  const onShowEdit = () => {
    if (showEditType === false) {
      setShowEditType(true);
    } else {
      setShowEditType(false);
    }
  };

  const dataSource = types?.map((type: any) => ({
    key: String(type.id),
    title: type.name,
    description: type.description,
  }));

  const targetKeys = object?.types?.map((selectedType: any) =>
    String(selectedType),
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const onSelectionChange = (
    sourceSelectedKeys: any,
    targetSelectedKeys: any,
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onChangeTypes = (nextTargetKeys: any) => {
    const allTypesIds: number[] = nextTargetKeys;
    if (allTypesIds.length === 0) {
      return;
    }
    const currentFields = [...object.teFields];
    let allFields: any[] = [];
    allFields = allFields.concat(currentFields);
    let visibleFields: any = [];
    allTypesIds.forEach((typeId: any) => {
      const type = types.find((type: TType) => type.id === parseInt(typeId));
      const validFields: any[] | undefined = getNonReferenceFields(
        fields,
        type,
      );
      visibleFields = visibleFields.concat(validFields);
      // Creates a TTEField  with empty values of the new field values
      validFields?.forEach((field: number) => {
        if (!isFieldOnObject(allFields, field)) {
          allFields.push({ fieldId: field, values: [''] });
        }
      });
    });
    allFields = allFields.filter((field: TTEFieldValue) =>
      isFieldIdOnObject(visibleFields, field.fieldId),
    );
    onChangeTwoProperties(
      [allFields, nextTargetKeys.map((key: string) => parseInt(key))],
      ['teFields', 'types'],
      [object],
    );
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.information} key='1'>
        <p className='small-heading-text'>
          {language.id}: {object?.id}
        </p>
        <TEItem label={language.ext_id} helpText={language.help_ext_id}>
          <Input
            size='small'
            value={object?.extId}
            onChange={(value: any) =>
              onChangeInputHandler(object, value, 'extId')
            }
          />
        </TEItem>
        <Form.Item label={`${language.property}:`}>
          <Select
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            size='small'
            value={object?.property}
            onChange={(value: any) =>
              onChangeSelectHandler(object, value, 'property')
            }
          >
            {Object.entries(EProperty).map(([key, value]) => (
              <Select.Option value={value} key={key}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={`${language.personal_information}:`}>
          <Select
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            size='small'
            value={object?.personalInformation}
            onChange={(value: any) =>
              onChangeSelectHandler(object, value, 'personalInformation')
            }
          >
            {Object.entries(EPersonalInformation).map(([key, value]) => (
              <Select.Option value={key} key={key}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {getNameFieldOnFields(availableFields).map((field: any) => (
          <div key={field.id} style={{ marginBottom: 26 }}>
            {renderElements(
              field.fieldType,
              field.values,
              object.teFields,
              field.name,
              field.id,
              field.categories,
              field.editable,
              field.multiple,
            )}
          </div>
        ))}
        <div style={{ marginBottom: '20px' }}>
          <p className='small-heading-text' style={{ marginBottom: 0 }}>
            {language.types}:{' '}
          </p>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {object?.types?.map((type: any) => (
                <p
                  className='text-value'
                  style={{ marginBottom: 0 }}
                  key={type}
                >
                  {getNameOnType(types, type)}
                </p>
              ))}
            </div>
            <TEToolTip helpText='Change object type' key={Math.random()}>
              <Button
                size='small'
                style={{ marginLeft: 40 }}
                icon={<EditOutlined />}
                onClick={onShowEdit}
              />
            </TEToolTip>
          </div>
        </div>
        {showEditType && (
          <Transfer
            dataSource={dataSource}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onSelectChange={onSelectionChange}
            onChange={onChangeTypes}
            render={(item) => item.title}
          />
        )}
        <p className='small-heading-text' style={{ marginTop: '16px' }}>
          {language.created}: {timeConverter(object?.created)}
        </p>
        <p className='small-heading-text' style={{ marginBottom: '16px' }}>
          {language.created_by}: {object?.createdBy}
        </p>
        <p className='small-heading-text'>
          {language.modified}:{' '}
          {object.history ? timeConverter(object?.history[0]?.modified) : ''}
        </p>
        <p className='small-heading-text' style={{ marginBottom: '32px' }}>
          {language.modified_by}: {object?.history[0]?.modifiedBy}
        </p>
        <Form.Item>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(object, checked, 'active')
            }
            checked={object?.active}
          >
            {language.active}
          </Checkbox>
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ------------------------------------------------------------------------- */

export const ObjectMembers = ({
  onChange,
  objectMembers,
  objects,
}: PropsMembers) => {
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.members} key='2'>
        <MembersTable
          objectMembers={objectMembers}
          objects={objects}
          onChange={onChange}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

/* ------------------------------------------------------------------------- */

export const ObjectRelations = ({
  onChange,
  objectRelations,
  objects,
}: PropsRelations) => {
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.relations} key='5'>
        <RelationsTable
          objectRelations={objectRelations}
          objects={objects}
          onChange={onChange}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

/* ------------------------------------------------------------------------- */

export const ObjectAvailabilityRelated = ({
  onChange,
  availabilityRelations,
  objects,
  object,
}: PropsAvailabilityRelated) => {
  const selectedObjects: any = useSelector(selectedObjectsSelector);
  const availabilityTypes: TAvailabilityRelationType[] = useSelector(
    availabilityTypesSelector,
  );

  const availabilityTypesToChooseFrom = availabilityRelations?.map(
    (availabilityRelation: any) => availabilityRelation.availabilityTypeId,
  );

  const [selectedAvailabilityType, setSelectedAvailabilityType] = useState(
    availabilityTypesToChooseFrom ? availabilityTypesToChooseFrom[0] : null,
  );

  const selectedAvailabilityRelation = availabilityRelations?.find(
    (related: any) => related.availabilityTypeId === selectedAvailabilityType,
  );

  //  All available objects = all objects -  current object
  const dataSourceAvailabilityRelations = objects
    .filter((o: any) => object?.id !== o.id)
    .map((o: any) => ({
      key: String(o.id),
      title: o.extId,
      description: o.description,
    }));

  const targetKeys = selectedAvailabilityRelation?.objectIds.map(
    (objectId: number) => String(objectId),
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const onSelection = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChange = (nextTargetKeys: any) => {
    const index = availabilityRelations?.indexOf(selectedAvailabilityRelation);
    const updatedObject = Object.assign({}, selectedAvailabilityRelation);
    updatedObject.objectIds = nextTargetKeys.map((key: string) =>
      parseInt(key),
    );
    const newArray = [...availabilityRelations];
    newArray.splice(index, 1, updatedObject);
    onChange(newArray, 'availabilityRelations', selectedObjects);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.availability_related} key='4'>
        <Select
          size='small'
          style={{ width: 200, marginBottom: '16px' }}
          defaultValue={
            availabilityTypesToChooseFrom
              ? availabilityTypesToChooseFrom[0]
              : null
          }
          onChange={(value: any) => setSelectedAvailabilityType(value)}
        >
          {availabilityTypesToChooseFrom?.map((availabilityType: any) => (
            <Select.Option key={availabilityType} value={availabilityType}>
              {getNameOnAvailabilityType(availabilityTypes, availabilityType)}
            </Select.Option>
          ))}
        </Select>
        <Transfer
          showSearch
          filterOption={(input: any, option: any) =>
            option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dataSource={dataSourceAvailabilityRelations}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={onSelectionChange}
          onSelectChange={onSelection}
          render={(item) => item.title}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

/* ------------------------------------------------------------------------- */

export const ObjectOptionalRelated = ({
  onChange,
  objectOptionalRelated,
  objects,
  object,
}: PropsOptionalRelated) => {
  const selectedObjects: any = useSelector(selectedObjectsSelector);

  //  All available objects = all objects -  current object
  const dataSourceOptionalRelations = objects
    .filter((o: any) => object?.id !== o.id)
    .map((o: any) => ({
      key: String(o.id),
      title: o.extId,
      description: o.description,
    }));

  const targetKeys = objectOptionalRelated?.map(
    (selectedOptionalRelation: any) => String(selectedOptionalRelation),
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const onSelection = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChange = (nextTargetKeys: any) => {
    onChange(
      nextTargetKeys.map((key: string) => parseInt(key)),
      'optionalRelations',
      selectedObjects,
    );
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.optional_related} key='5'>
        <Transfer
          showSearch
          filterOption={(input: any, option: any) =>
            option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dataSource={dataSourceOptionalRelations}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={onSelectionChange}
          onSelectChange={onSelection}
          render={(item) => item.title}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

/* ------------------------------------------------------------------------- */

export const ObjectOrganizations = ({
  onChange,
  objectOrganizations,
}: PropsOrganizations) => {
  const selectedObjects: any = useSelector(selectedObjectsSelector);

  // All available organizations
  const organizations: TOrganizationTreeNode[] = useSelector(
    organizationsSelector,
  );

  const dataSourceOrganizations = organizations.map((org: any) => ({
    key: String(org.id),
    title: org.name,
    description: org.description,
  }));

  const targetKeys = objectOrganizations?.map((selectedMember: any) =>
    String(selectedMember),
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const onSelection = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChange = (nextTargetKeys: any) => {
    onChange(
      nextTargetKeys.map((key: string) => parseInt(key)),
      'organizations',
      selectedObjects,
    );
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.organizations} key='5'>
        <Transfer
          showSearch
          filterOption={(input: any, option: any) =>
            option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dataSource={dataSourceOrganizations}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={onSelectionChange}
          onSelectChange={onSelection}
          render={(item) => item.title}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
