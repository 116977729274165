import { configService } from './config.service';

const TokenService = (function () {
  console.info(process.env);
  let _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj) {
    sessionStorage.setItem(configService.TOKEN_NAME, JSON.stringify(tokenObj));
  }
  function _getToken() {
    const json = sessionStorage.getItem(configService.TOKEN_NAME);
    if (!json) return null;
    return JSON.parse(json);
  }
  function _clearToken() {
    sessionStorage.removeItem(configService.TOKEN_NAME);
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getToken: _getToken,
    clearToken: _clearToken,
  };
})();

export default TokenService;
