import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// i18N
import intl from '../../../i18n/intl';

// ACTIONS
import { setBreadcrumbs } from '../../../slices/ui.slice';

// STYLES
import { Form, List, Button, Tooltip, Popconfirm, Menu, Dropdown } from 'antd';
import { DeleteOutlined, CopyOutlined, DownOutlined } from '@ant-design/icons';

// COMPONENTS
import ListView from '../../../components/List';
import DraggableTable from '../../../components/ReuseableTables/DragableTable/DragableTable';
import SectionHeader from '../../../components/SectionHeader';
import {
  ReservationModeGeneralSettings,
  ReservationModeReservationTemplates,
} from '../../../components/ReservationModeComponents';
import LanguagePreferences from '../../../components/LanguagePreferences';
import Documentation from '../../../components/Documentation';

// TYPES
import { TReservationMode } from '../../../types/reservation.type';
import { TNewSortOrderReservationModes } from '../../../types/sortOrder.type';

// ACTIONS
import {
  createReservationMode,
  deleteReservationMode,
  updateReservationMode,
  createEmptyReservationMode,
  copyReservationMode,
  setSelectedReservationMode,
  selectedReservationModeSelector,
  fetchLanguagesForReservationModes,
  languagesReservationModesSelector,
  limitationsReservationModesSelector,
  fetchLimitationsForReservationModes,
  updateReservationModesOrder,
  reservationModesSelector,
  fetchReservationModes,
} from '../../../slices/reservationMode.slice';

// UTILS
import { timeConverter } from '../../../utils/timeConverter';
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const ReservationModePage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const reservationModes: TReservationMode[] = useSelector(
    reservationModesSelector,
  );

  const languagesReservationModes = useSelector(
    languagesReservationModesSelector,
  );
  const limitations = useSelector(limitationsReservationModesSelector);
  const selectedReservationMode: TReservationMode = useSelector(
    selectedReservationModeSelector,
  );

  // State for setting error if ext.id already exists
  const [extIdError, setExtIdError] = useState(false);

  const [sortOption, setSortOption] = useState<string>('sort');
  const [sortQuery, setSortQuery] = useState<string>('reservationModeOrder');
  const [templateTypeOption, setTemplateTypeOption] = useState();
  const [templateTypeQuery, setTemplateTypeQuery] = useState();

  const onReservationModeSelected = (selectedValue: any) => {
    const currentReservationMode = reservationModes.find(
      (reservationMode: TReservationMode) =>
        reservationMode.id === selectedValue,
    );
    if (currentReservationMode) {
      dispatch(setSelectedReservationMode(currentReservationMode));
      setExtIdError(false);
    }
  };

  const [form] = Form.useForm();

  const listData = reservationModes.map((reservationmode: any) => ({
    listLabel: reservationmode[sortQuery === 'extId' ? `${sortQuery}` : 'name'],
    value: reservationmode.id,
  }));

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/reservation-modes`,
          label: `${language.reservation_modes}`,
        },
        {
          path: `/system/reservation-modes`,
          label: `${selectedReservationMode?.name}`,
        },
      ]),
    );
  }, [
    dispatch,
    customerSignature,
    selectedReservationMode.id,
    selectedReservationMode?.name,
  ]);

  useEffect(() => {
    dispatch(
      fetchReservationModes(
        sortOption,
        sortQuery,
        templateTypeOption,
        templateTypeQuery,
      ),
    );
    dispatch(fetchLanguagesForReservationModes());
    dispatch(fetchLimitationsForReservationModes());
  }, [dispatch, sortOption, sortQuery, templateTypeOption, templateTypeQuery]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabled(selectedReservationMode, reservationModes));
  }, [reservationModes, selectedReservationMode]);

  // EVENT HANDLERS
  // Adds new reservation mode
  const onPlusButton = () => {
    dispatch(createEmptyReservationMode());
  };

  // Deletes choosen reservation mode
  const onDeleteButton = () => {
    dispatch(deleteReservationMode(selectedReservationMode));
  };

  // Excluding the selected res.mode
  const otherReservationModes = reservationModes.filter(
    (reservationMode) => reservationMode !== selectedReservationMode,
  );

  // Save changes function
  const onSaveChangesReservationModeButton = () => {
    if (selectedReservationMode.id === 0) {
      if (
        _.some(
          otherReservationModes,
          (mode: TReservationMode) =>
            mode.extId === selectedReservationMode.extId,
        )
      ) {
        setExtIdError(true);
      } else {
        dispatch(createReservationMode(selectedReservationMode));
        setExtIdError(false);
      }
    } else {
      dispatch(updateReservationMode(selectedReservationMode));
      setExtIdError(false);
    }
  };

  const onHandleChange = (property: string, value: any) => {
    dispatch(
      setSelectedReservationMode({
        ...selectedReservationMode,
        [property]: value,
      }),
    );
  };

  // Discard changes function
  const onDiscardChanges = () => {
    onReservationModeSelected(selectedReservationMode.id);
  };

  // Copy reservation mode
  const onCopy = () => {
    dispatch(copyReservationMode(selectedReservationMode));
  };

  // The sort order object to send to the server to change order on reservation modes
  const newSortOrderObject = (
    arr: Array<any>,
    index1: number,
    index2: number,
  ) => {
    const newArr = [...arr];
    const reservationModes =
      index1 < index2
        ? // If dragging downwards, sortReservationMode will be the element you put the selectedReservationMode below
          [newArr[index2].id, newArr[index1].id]
        : // If dragging upwards, sortReservationMode will be the element you put the selectedReservationMode above
          [newArr[index1].id, newArr[index2].id];
    const newSortOrder: TNewSortOrderReservationModes = {
      reservationModes,
      sortReservationMode: newArr[index2].id,
    };
    return newSortOrder;
  };

  // Function saving the new reservationModesOrder object and updating the new order
  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newSortOrder: TNewSortOrderReservationModes = newSortOrderObject(
        reservationModes,
        dragIndex,
        hoverIndex,
      );
      dispatch(updateReservationModesOrder(newSortOrder));
    },
    [dispatch, reservationModes],
  );

  const menu = (
    <Menu
      onClick={(event: any) => {
        if (event.domEvent.target.id === 'sort') {
          setSortOption(event.domEvent.target.id);
          setSortQuery(event.key);
        }
        if (event.domEvent.target.id === 'templateType') {
          setTemplateTypeOption(event.domEvent.target.id);
          setTemplateTypeQuery(event.key);
        }
      }}
      style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
      className='list-search--menu'
    >
      <Menu.SubMenu
        title={language.filter_on_template_type}
        style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
      >
        <Menu.Item
          key='all'
          id='templateType'
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {language.all}
        </Menu.Item>
        <Menu.Item
          key='reservation'
          id='templateType'
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {language.reservation}
        </Menu.Item>
        <Menu.Item
          key='availability'
          id='templateType'
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {language.availability}
        </Menu.Item>
        <Menu.Item
          key='infoReservation'
          id='templateType'
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {language.infoReservation}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        title={language.sort_on}
        style={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <Menu.Item
          key='reservationModeOrder'
          id='sort'
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {language.reservationModeOrder}
        </Menu.Item>
        <Menu.Item
          key='extId'
          id='sort'
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {language.extId}
        </Menu.Item>
        <Menu.Item
          key='name'
          id='sort'
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          {language.name}
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  const columns = [
    {
      render: (record: any) =>
        record.value !== selectedReservationMode.id ? (
          <List.Item
            className='draggable-list-item'
            onClick={() => onReservationModeSelected(record.value)}
            actions={[
              <Tooltip
                title={language.copy}
                placement='topLeft'
                key={record.id}
              >
                <Button
                  size='small'
                  type='default'
                  icon={<CopyOutlined />}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onCopy();
                  }}
                  // How to make this one below false ALWAYS?
                  ant-click-animating-without-extra-node='false'
                  className='list-item-action-button copy'
                />
              </Tooltip>,
              <Popconfirm
                key={record.id}
                title={`${language.sure_to_delete_object}?`}
                onConfirm={(event: any) => {
                  event.stopPropagation();
                  onDeleteButton();
                }}
                onCancel={() => console.info('should do nothing, no delete')}
                okText={language.yes}
                cancelText={language.no}
                className='list-item-action-button'
              >
                <Button
                  size='small'
                  danger
                  ghost
                  type='default'
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>,
            ]}
          >
            <Tooltip title={record.listLabel} placement='left'>
              <p className='list-item-text'>{record.listLabel}</p>
            </Tooltip>
          </List.Item>
        ) : (
          <List.Item
            className='draggable-list-item'
            onClick={() => onReservationModeSelected(record.value)}
            actions={[
              <Tooltip
                title={language.copy}
                placement='topLeft'
                key={record.value}
              >
                <Button
                  size='small'
                  type='default'
                  className='list-item-action-button copy'
                  icon={<CopyOutlined />}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onCopy();
                  }}
                  // How to make this one below false ALWAYS?
                  ant-click-animating-without-extra-node='false'
                />
              </Tooltip>,
              <Popconfirm
                title={`${language.sure_to_delete_object}?`}
                key={record.value}
                onConfirm={(event: any) => {
                  event.stopPropagation();
                  onDeleteButton();
                }}
                onCancel={() => console.info('should do nothing, no delete')}
                okText={language.yes}
                cancelText={language.no}
              >
                <Button
                  size='small'
                  danger
                  ghost
                  type='default'
                  className='list-item-action-button'
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>,
            ]}
          >
            <Tooltip title={record.listLabel} placement='left'>
              <p className='list-item-text'>{record.listLabel}</p>
            </Tooltip>
          </List.Item>
        ),
    },
  ];

  return (
    <>
      <ListView
        sectionHeading={language.reservation_modes}
        number={listData.length}
        onSelect={onPlusButton}
      >
        <Dropdown overlay={menu} className='search-dropdown'>
          <Button
            size='small'
            type='default'
            style={{ width: 'auto', fontSize: '14px', marginTop: '12px' }}
          >
            Filter / Sort
            <DownOutlined />
          </Button>
        </Dropdown>
        <DraggableTable
          className='draggable-table-as-listview'
          size='small'
          columns={columns}
          dataSource={listData.map((data: any) => ({
            key: data.value,
            ...data,
            data,
          }))}
          onDrag={moveRow}
          tableLayout='fixed'
        />
      </ListView>
      <div className='inner-content--wrapper'>
        <SectionHeader
          listChoice={
            selectedReservationMode?.name
              ? `${selectedReservationMode?.name}`
              : `${language.reservation_mode}`
          }
          history={selectedReservationMode?.history}
          modifiedDate={
            selectedReservationMode.history?.length > 0
              ? timeConverter(selectedReservationMode?.history[0]?.modified)
              : ''
          }
          modifiedBy={
            selectedReservationMode.history?.length > 0
              ? selectedReservationMode?.history[0]?.modifiedBy
              : ''
          }
          changeHandler={onSaveChangesReservationModeButton}
          discardHandler={onDiscardChanges}
          displayProp={false}
          error={
            extIdError === true
              ? `${language.extid_reservation_mode_already_exists}`
              : null
          }
          isDisabled={saveDisabled}
        />
        <Form layout='vertical' form={form}>
          <div style={{ position: 'relative' }}>
            <Documentation
              onChange={onHandleChange}
              documentation={selectedReservationMode?.documentation}
              limitationValue={limitations.documentation}
            />
            <ReservationModeGeneralSettings
              reservationMode={selectedReservationMode}
              onChange={onHandleChange}
              limitations={limitations}
            />
            <LanguagePreferences
              languages={languagesReservationModes}
              altDesignations={selectedReservationMode.altDesignations}
              onChange={onHandleChange}
              limitationName={limitations.name}
              limitationDescription={limitations.description}
            />
            <ReservationModeReservationTemplates
              templates={selectedReservationMode?.reservationTemplates}
              onChange={onHandleChange}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReservationModePage;
