import React from 'react';

// i18N
import intl from '../../i18n/intl';

// STYLES
import './index.scss';
import { Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

type Props = {
  sectionHeading?: string;
  listTitle?: string;
  number: number;
  children?: React.ReactNode | null;
  onSelect: any;
  extraWidth?: boolean;
};

const language: any = intl.messages;

const ListView = ({
  sectionHeading,
  listTitle,
  number,
  children,
  onSelect = () => {},
  extraWidth,
}: Props) => {
  const classNames = [];
  if (onSelect === 'hidden') {
    classNames.push('not-visible');
  }
  const classNamesWrapper = ['list--wrapper'];
  if (extraWidth) {
    classNamesWrapper.push('extra-width--wrapper');
  } else {
    classNamesWrapper.push('normal-width--wrapper');
  }

  return (
    <div className={classNamesWrapper.join(' ')}>
      <h1 className='section-header'>{sectionHeading}</h1>
      <h4>{listTitle}</h4>
      <div className='top-heading--list'>
        <Tooltip title={language.add}>
          <Button
            size='small'
            type='default'
            onClick={onSelect}
            icon={<PlusOutlined />}
            className={classNames.join(' ')}
          />
        </Tooltip>
        <div className='list-counter'>{number}</div>
      </div>
      {children}
    </div>
  );
};

export default ListView;
