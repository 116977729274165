import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { MemberType, TMemberType } from '../types/member.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedMemberType: MemberType.createEmpty(),
  allMemberTypes: [],
  limitations: {},
  errorMessage: {},
};

// Slice
const slice = createSlice({
  name: 'memberTypes',
  initialState,
  reducers: {
    fetchMemberTypesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchMemberTypesSuccess: (state: any, { payload }) => {
      if (state.selectedMemberType.id > 0) {
        state.selectedMemberType =
          payload.find(
            (type: any) => type.id === state.selectedMemberType.id,
          ) || MemberType.createEmpty();
      }
      state.allMemberTypes = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsMemberTypesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchMemberTypesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createMemberTypeRequest: (state: any) => {
      state.errorMessage = '';
      isLoadingRequest(state);
    },
    createMemberTypeSuccess: (state: any, { payload }) => {
      state.allMemberTypes.push(payload);
      state.selectedMemberType = payload;
      finishedLoadingSuccess(state);
    },
    createMemberTypeFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
    setCurrentMemberType: (state: any, { payload }) => {
      state.selectedMemberType = payload;
      state.errorMessage = '';
    },
    deleteMemberTypeRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteMemberTypeSuccess: (state: any, payload: any) => {
      state.allMemberTypes = state.allMemberTypes.filter(
        (memberType: TMemberType) => memberType.id !== payload.payload.id,
      );
      state.selectedMemberType = MemberType.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteMemberTypeFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateMemberTypeRequest: (state: any) => {
      state.errorMessage = '';
      isLoadingRequest(state);
    },
    updateMemberTypeSuccess: (state: any, { payload }) => {
      state.errorMessage = '';
      const index = state.allMemberTypes.findIndex(
        (memberType: TMemberType) => memberType.id === payload.id,
      );
      state.allMemberTypes[index] = payload;
      finishedLoadingSuccess(state);
    },
    updateMemberTypeFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const memberTypesLoading = (state: any) => state.memberTypes.loading;
export const memberTypesSelector = (state: any) =>
  state.memberTypes.allMemberTypes as TMemberType[];
export const memberTypeSelectorCreateNew = () => () => {
  return MemberType.createEmpty();
};
export const selectedMemberTypeSelector = (state: any) => {
  return state.memberTypes.selectedMemberType;
};
export const fetchLimitationsMemberTypesSelector = (state: any) =>
  state.memberTypes.limitations;
export const memberTypeErrorMessageSelector = (state: any) =>
  state.memberTypes.errorMessage;

// Actions
export const {
  fetchMemberTypesRequest,
  fetchMemberTypesSuccess,
  fetchLimitationsMemberTypesSuccess,
  fetchMemberTypesFailure,
  createMemberTypeRequest,
  createMemberTypeSuccess,
  createMemberTypeFailure,
  setCurrentMemberType,
  deleteMemberTypeRequest,
  deleteMemberTypeSuccess,
  deleteMemberTypeFailure,
  updateMemberTypeRequest,
  updateMemberTypeSuccess,
  updateMemberTypeFailure,
} = slice.actions;

export const fetchMemberTypes = () => async (dispatch: any) => {
  try {
    dispatch(fetchMemberTypesRequest());
    const memberTypes = await api.get({ endpoint: `/member-types` });
    dispatch(fetchMemberTypesSuccess(memberTypes));
  } catch (e) {
    dispatch(fetchMemberTypesFailure());
    return console.error(e);
  }
};

export const fetchLimitationsMemberTypes = () => async (dispatch: any) => {
  try {
    dispatch(fetchMemberTypesRequest());
    const limitationsMemberTypes = await api.get({
      endpoint: `/member-types/limitations`,
    });
    dispatch(fetchLimitationsMemberTypesSuccess(limitationsMemberTypes));
  } catch (e) {
    dispatch(fetchMemberTypesFailure());
    return console.error(e);
  }
};

export const createMemberType =
  (memberType: TMemberType) => async (dispatch: any) => {
    try {
      dispatch(createMemberTypeRequest());
      const { id: _, ...memberTypeBody } = memberType;
      const response = await api.post({
        endpoint: `/member-types`,
        data: memberTypeBody,
      });
      dispatch(createMemberTypeSuccess(response));
      dispatch(fetchMemberTypes());
    } catch (e: any) {
      dispatch(createMemberTypeFailure(e.response.data));
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const createEmptyMemberType = () => async (dispatch: any) => {
  try {
    dispatch(createMemberTypeRequest());
    const response = MemberType.createEmpty();
    dispatch(createMemberTypeSuccess(response));
  } catch (e: any) {
    dispatch(createMemberTypeFailure(e.response.data));
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedMemberType =
  (memberType: TMemberType) => async (dispatch: any) => {
    dispatch(setCurrentMemberType(memberType));
  };

export const copyMemberType =
  (memberType: TMemberType) => async (dispatch: any) => {
    try {
      dispatch(createMemberTypeRequest());
      const response = {
        ...memberType,
        id: 0,
        description: '',
      };
      dispatch(createMemberTypeSuccess(response));
    } catch (e: any) {
      dispatch(createMemberTypeFailure(e.response.data));
      return console.error(e);
    }
  };

export const deleteMemberType =
  (memberType: TMemberType) => async (dispatch: any) => {
    try {
      dispatch(deleteMemberTypeRequest());
      await api.delete({
        endpoint: `/member-types/${memberType.id}`,
      });
      dispatch(deleteMemberTypeSuccess(memberType));
    } catch (e) {
      dispatch(deleteMemberTypeFailure());
      return console.error(e);
    }
  };

export const updateMemberType =
  (memberType: TMemberType) => async (dispatch: any) => {
    try {
      dispatch(updateMemberTypeRequest());
      const { ...MemberTypeBody } = memberType;
      const response = await api.patch({
        endpoint: `/member-types/${memberType.id}`,
        data: { ...MemberTypeBody },
      });
      dispatch(updateMemberTypeSuccess(response));
    } catch (e: any) {
      dispatch(updateMemberTypeFailure(e.response.data));
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
