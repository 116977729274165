import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form, Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

// TYPES
import { TPeriod } from '../../../types/period.type';

// ACTIONS
import {
  fetchPeriods,
  periodsSelector,
  createPeriod,
  deletePeriod,
  updatePeriod,
  createEmptyPeriod,
  copyPeriod,
  selectedPeriodSelector,
  setSelectedPeriod,
  fetchLimitationsPeriodsSelector,
  fetchLimitationsPeriods,
  periodErrorMessageSelector,
} from '../../../slices/period.slice';

// UTILS
import { isSavedDisabled } from '../../../utils/isSavedDisabled';
import { timeConverter } from '../../../utils/timeConverter';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import NormalListView from '../../../components/List/NormalListView';
import {
  PeriodsGeneralSettings,
  PeriodsIntervalsPeriods,
} from '../../../components/PeriodsComponent';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const PeriodsPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const limitations = useSelector(fetchLimitationsPeriodsSelector);
  const periods: TPeriod[] = useSelector(periodsSelector);
  const selectedPeriod: TPeriod = useSelector(selectedPeriodSelector);
  const errorMessage = useSelector(periodErrorMessageSelector);

  const [showHiddenOption, setShowHiddenOption] =
    useState<string>('showHidden');
  const [showHiddenQuery, setShowHiddenQuery] = useState<boolean>(false);
  const [rootPeriodsOption, setRootPeriodsOption] = useState<string>();
  const [rootPeriodsQuery, setRootPeriodsQuery] = useState<
    boolean | undefined
  >();

  const listData = periods.map((period: any) => ({
    listLabel: period.name,
    value: period.id,
  }));

  // State for setting error messages
  const [error, setError] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState(false);

  const onPeriodSelected = (selectedValue: any) => {
    const currentPeriod = periods.find((period) => period.id === selectedValue);
    if (currentPeriod) {
      dispatch(setSelectedPeriod(currentPeriod));
    }
  };

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/periods`,
          label: `${language.periods}`,
        },
        {
          path: `/${selectedPeriod.id}`,
          label: `${selectedPeriod.name}`,
        },
      ]),
    );
  }, [
    dispatch,
    selectedPeriod.id,
    selectedPeriod.name,
    selectedPeriod.intervals,
    customerSignature,
  ]);

  useEffect(() => {
    setError(false);
    setErrorMessageState(false);
    dispatch(
      fetchPeriods(
        showHiddenOption,
        showHiddenQuery,
        rootPeriodsOption,
        rootPeriodsQuery,
      ),
    );
    dispatch(fetchLimitationsPeriods());
  }, [
    dispatch,
    showHiddenOption,
    showHiddenQuery,
    rootPeriodsOption,
    rootPeriodsQuery,
  ]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabled(selectedPeriod, periods));
  }, [periods, selectedPeriod]);

  const [form] = Form.useForm();

  const getParentPeriods = (periodId: number, allPeriods: Array<TPeriod>) => {
    const result: TPeriod[] = [];
    allPeriods.forEach((period) => {
      // eslint-disable-next-line array-callback-return
      period.childPeriods?.map((child: any) => {
        if (child === periodId) {
          result.push(period);
        }
      });
    });
    return result;
  };

  // EVENT HANDLERS
  // Adds new period
  const onPlusButton = () => {
    dispatch(createEmptyPeriod());
  };

  // Deletes choosen period
  const onDeleteButton = () => {
    dispatch(deletePeriod(selectedPeriod));
    if (errorMessage === {}) {
      setErrorMessageState(false);
    } else {
      setErrorMessageState(true);
    }
  };

  // Saves changes done on period
  const onSaveChangesPeriodButton = () => {
    if (selectedPeriod.id === 0) {
      dispatch(createPeriod(selectedPeriod));
    } else {
      if (
        selectedPeriod.childPeriods === undefined &&
        selectedPeriod.intervals === undefined
      ) {
        setError(true);
      } else {
        dispatch(updatePeriod(selectedPeriod));
        setError(false);
      }
    }
    if (errorMessage === {}) {
      setErrorMessageState(false);
    } else {
      setErrorMessageState(true);
    }
  };

  const onHandleChange = (property: string, value: any) => {
    dispatch(setSelectedPeriod({ ...selectedPeriod, [property]: value }));
    form.setFieldsValue({ userAccess: value });
    setErrorMessageState(false);
  };

  // Discard changes function
  const onDiscardChanges = () => {
    onPeriodSelected(selectedPeriod.id);
  };

  // Copy period
  const onCopy = () => {
    dispatch(copyPeriod(selectedPeriod));
  };

  const menu = (
    <Menu
      onClick={(event: any) => {
        if (event.domEvent.target.id === 'showHidden') {
          setShowHiddenOption(event.domEvent.target.id);
          setShowHiddenQuery(event.key);
        }
        if (event.domEvent.target.id === 'rootPeriods') {
          setRootPeriodsOption(event.domEvent.target.id);
          setRootPeriodsQuery(event.key);
        }
      }}
      className='list-search--menu'
    >
      <Menu.SubMenu title={language.filter_on_root_periods}>
        <Menu.Item
          key={'true'}
          id='rootPeriods'
          className='list-search--menu-item'
        >
          {language.root_periods}
        </Menu.Item>
        <Menu.Item
          key={'false'}
          id='rootPeriods'
          className='list-search--menu-item'
        >
          {language.all_periods}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title={language.hidden_periods}>
        <Menu.Item
          key={'true'}
          id='showHidden'
          className='list-search--menu-item'
        >
          {language.show_hidden_periods}
        </Menu.Item>
        <Menu.Item
          key={'false'}
          id='showHidden'
          className='list-search--menu-item'
        >
          {language.hide_hidden_periods}
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <>
      <ListView
        sectionHeading={language.periods}
        number={listData.length}
        onSelect={onPlusButton}
      >
        <Dropdown overlay={menu} className='search-dropdown'>
          <Button
            size='small'
            type='default'
            style={{ fontSize: '14px', marginTop: '12px' }}
          >
            Filter
            <DownOutlined />
          </Button>
        </Dropdown>
        <NormalListView
          data={listData}
          onSelect={onPeriodSelected}
          onDelete={onDeleteButton}
          onCopy={onCopy}
          selectedItemId={selectedPeriod.id}
        />
      </ListView>
      <div className='inner-content--wrapper'>
        <SectionHeader
          listChoice={
            selectedPeriod.name
              ? `${selectedPeriod.name}`
              : `${language.period}`
          }
          history={selectedPeriod?.history}
          modifiedDate={
            selectedPeriod.history?.length > 0
              ? timeConverter(selectedPeriod?.history[0]?.modified)
              : ''
          }
          modifiedBy={
            selectedPeriod.history?.length > 0
              ? selectedPeriod?.history[0]?.modifiedBy
              : ''
          }
          changeHandler={onSaveChangesPeriodButton}
          discardHandler={onDiscardChanges}
          displayProp={false}
          error={
            error === true
              ? `Period must have intervals or children!`
              : errorMessageState === true
              ? `${errorMessage}`
              : null
          }
          isDisabled={saveDisabled}
        />
        <Form layout='vertical' form={form}>
          <PeriodsGeneralSettings
            period={selectedPeriod}
            onChange={onHandleChange}
            limitations={limitations}
          />
          <PeriodsIntervalsPeriods
            period={selectedPeriod}
            periods={periods}
            timeIntervals={selectedPeriod.intervals}
            childPeriods={selectedPeriod.childPeriods}
            onChange={onHandleChange}
            parentPeriods={getParentPeriods(selectedPeriod.id, periods)}
          />
        </Form>
      </div>
    </>
  );
};

export default PeriodsPage;
