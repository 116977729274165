// STYLES
import { Form, Collapse, Select, Button } from 'antd';

const onBlur = () => {};
const onFocus = () => {};

export const ConvertColorsComponent = () => {
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <p className='form-item-text'>
        Convert existing coloring by color field to coloring on objects. Select
        the previously used color bar to create and assign colors for all
        affected objects.
      </p>
      <Collapse.Panel header='Choose field' key='1'>
        <Form.Item>
          <Select
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            size='small'
          >
            {/*   <Select.Option>
              
              </Select.Option> */}
          </Select>
        </Form.Item>
        <Button type='primary' style={{ alignSelf: 'flex-end' }}>
          Convert
        </Button>
      </Collapse.Panel>
    </Collapse>
  );
};
