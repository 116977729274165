import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { ReactNode } from 'react';

// STYLES
import './index.scss';

// TYPES
type Props = {
  label: string;
  route: string;
  icon: ReactNode;
  isSelected: boolean;
};

const MenuItem = ({ label, route, icon, isSelected }: Props) => {
  return (
    <div className='menu-item--wrapper'>
      <Tooltip title={label} placement='right'>
        <Link to={route}>
          <span
            className={`menu-item--icon ${isSelected ? 'active' : 'inactive'}`}
          >
            {icon}
          </span>
        </Link>
      </Tooltip>
    </div>
  );
};

export default MenuItem;
