import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TSupportInfo } from '../types/systempreferences.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  limitations: {},
  supportInfo: {},
  updatedSupportInfo: {},
};

// Slice
const slice = createSlice({
  name: 'supportInfo',
  initialState,
  reducers: {
    fetchSupportInfoRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchSupportInfoSuccess: (state: any, { payload }) => {
      state.supportInfo = payload;
      state.updatedSupportInfo = payload;
      finishedLoadingSuccess(state);
    },
    fetchSupportInfoFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    fetchLimitationsSupportInfoSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    setsUpdatedSupportInfo: (state: any, { payload }) => {
      state.updatedSupportInfo = payload;
    },
    updateSupportInfoRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateSupportInfoSuccess: (state: any, { payload }) => {
      state.supportInfo = payload;
      state.updatedSupportInfo = payload;
      finishedLoadingSuccess(state);
    },
    updateSupportInfoFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const supportInfoLoading = (state: any) => state.supportInfo.loading;
export const supportInfoSelector = (state: any) =>
  state.supportInfo.supportInfo as TSupportInfo;
export const updatedSupportInfoSelector = (state: any) =>
  state.supportInfo.updatedSupportInfo;
export const limitationsSupportInfoSelector = (state: any) =>
  state.supportInfo.limitations;

// Actions
export const {
  fetchSupportInfoRequest,
  fetchSupportInfoSuccess,
  fetchSupportInfoFailure,
  fetchLimitationsSupportInfoSuccess,
  setsUpdatedSupportInfo,
  updateSupportInfoRequest,
  updateSupportInfoSuccess,
  updateSupportInfoFailure,
} = slice.actions;

export const fetchSupportInfo = () => async (dispatch: any) => {
  try {
    dispatch(fetchSupportInfoRequest());
    const supportInfo = await api.get({
      endpoint: `/support-info`,
    });
    dispatch(fetchSupportInfoSuccess(supportInfo));
  } catch (e) {
    dispatch(fetchSupportInfoFailure());
    return console.error(e);
  }
};

export const fetchLimitationsForSupportInfo = () => async (dispatch: any) => {
  try {
    dispatch(fetchSupportInfoRequest());
    const limitationsSupportInfo = await api.get({
      endpoint: `/support-info/limitations`,
    });
    dispatch(fetchLimitationsSupportInfoSuccess(limitationsSupportInfo));
  } catch (e) {
    dispatch(fetchSupportInfoFailure());
    return console.error(e);
  }
};

export const setUpdatedSupportInfo =
  (supportInfo: TSupportInfo) => async (dispatch: any) => {
    dispatch(setsUpdatedSupportInfo(supportInfo));
  };

export const updateSupportInfo =
  (supportInfo: TSupportInfo) => async (dispatch: any) => {
    try {
      dispatch(updateSupportInfoRequest());
      const { ...supportInfoBody } = supportInfo;
      const response = await api.patch({
        endpoint: `/support-info`,
        data: { ...supportInfoBody },
      });
      dispatch(updateSupportInfoSuccess(response));
    } catch (e: any) {
      dispatch(updateSupportInfoFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
