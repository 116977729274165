import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import {
  TAuthenticationToken,
  AuthenticationTokenCreateEmpty,
} from '../types/authenticationToken.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedAuthenticationToken: AuthenticationTokenCreateEmpty.createEmpty(),
  allAuthenticationTokens: [],
};

// Slice
const slice = createSlice({
  name: 'authenticationTokens',
  initialState,
  reducers: {
    fetchAuthenticationTokensRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchAuthenticationTokensSuccess: (state: any, { payload }) => {
      payload.forEach((token: any) => (token.confirmPassword = token.password));
      const selectedIds = state.allAuthenticationTokens.map(
        (authenticationToken: any) => authenticationToken.id,
      );
      state.allAuthenticationTokens = payload.filter(
        (aT: any) => selectedIds.indexOf(aT.id) !== -1,
      );
      state.allAuthenticationTokens = payload;
      finishedLoadingSuccess(state);
    },
    fetchAuthenticationTokensFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createAuthenticationTokenRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createAuthenticationTokenSuccess: (state: any, { payload }) => {
      state.allAuthenticationTokens.push(payload);
      state.selectedAuthenticationToken = payload;
      finishedLoadingSuccess(state);
    },
    createAuthenticationTokenFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentAuthenticationToken: (state: any, { payload }) => {
      state.selectedAuthenticationToken = payload;
    },
    setAuthenticationTokens: (state: any, { payload }) => {
      state.allAuthenticationTokens = payload;
    },
    deleteAuthenticationTokenRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteAuthenticationTokenSuccess: (state: any, payload: any) => {
      state.allAuthenticationTokens = state.allAuthenticationTokens.filter(
        (authenticationToken: TAuthenticationToken) =>
          authenticationToken.id !== payload.payload.id,
      );
      state.selectedAuthenticationToken =
        AuthenticationTokenCreateEmpty.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteAuthenticationTokenFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateAuthenticationTokenRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateAuthenticationTokenSuccess: (state: any, { payload }) => {
      payload.forEach((p: any) => {
        const index = state.allAuthenticationTokens.findIndex(
          (aT: TAuthenticationToken) => aT.id === p.id,
        );
        state.allAuthenticationTokens[index] = p;
      });
      finishedLoadingSuccess(state);
    },
    updateAuthenticationTokenFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const authenticationTokensLoading = (state: any) =>
  state.authenticationTokens.loading;
export const authenticationTokensSelector = (state: any) =>
  state.authenticationTokens.allAuthenticationTokens as TAuthenticationToken[];
export const authenticationTokenSelectorCreateNew = () => () => {
  return AuthenticationTokenCreateEmpty.createEmpty();
};
export const selectedAuthenticationTokenSelector = (state: any) => {
  return state.authenticationTokens.selectedAuthenticationToken;
};

// Actions
export const {
  fetchAuthenticationTokensRequest,
  fetchAuthenticationTokensSuccess,
  fetchAuthenticationTokensFailure,
  createAuthenticationTokenRequest,
  createAuthenticationTokenSuccess,
  createAuthenticationTokenFailure,
  setCurrentAuthenticationToken,
  setAuthenticationTokens,
  deleteAuthenticationTokenRequest,
  deleteAuthenticationTokenSuccess,
  deleteAuthenticationTokenFailure,
  updateAuthenticationTokenRequest,
  updateAuthenticationTokenSuccess,
  updateAuthenticationTokenFailure,
} = slice.actions;

export const fetchAuthenticationTokens = () => async (dispatch: any) => {
  try {
    dispatch(fetchAuthenticationTokensRequest());
    const authenticationTokens = await api.get({
      endpoint: `/authentication-tokens`,
    });
    dispatch(fetchAuthenticationTokensSuccess(authenticationTokens));
  } catch (e) {
    dispatch(fetchAuthenticationTokensFailure());
    return console.error(e);
  }
};

export const createAuthenticationToken =
  (authenticationToken: TAuthenticationToken) => async (dispatch: any) => {
    try {
      dispatch(createAuthenticationTokenRequest());
      const { id: _, ...authenticationTokenBody } = authenticationToken;
      delete authenticationTokenBody.confirmPassword;
      const response = await api.post({
        endpoint: `/authentication-tokens`,
        data: authenticationTokenBody,
      });
      dispatch(createAuthenticationTokenSuccess(response));
    } catch (e: any) {
      dispatch(createAuthenticationTokenFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const setSelectedAuthenticationTokens =
  (authenticationTokens: TAuthenticationToken[]) => async (dispatch: any) => {
    dispatch(setAuthenticationTokens(authenticationTokens));
  };

export const deleteAuthenticationToken =
  (authenticationToken: TAuthenticationToken) => async (dispatch: any) => {
    try {
      dispatch(deleteAuthenticationTokenRequest());
      await api.delete({
        endpoint: `/authentication-tokens/${authenticationToken.id}`,
      });
      dispatch(deleteAuthenticationTokenSuccess(authenticationToken));
    } catch (e) {
      dispatch(deleteAuthenticationTokenFailure());
      return console.error(e);
    }
  };

export const updateAuthenticationTokens =
  (authenticationTokens: TAuthenticationToken[]) => async (dispatch: any) => {
    try {
      dispatch(updateAuthenticationTokenRequest());
      const results = await authenticationTokens.map(
        async (authenticationToken: TAuthenticationToken) => {
          const { confirmPassword: _, ...AuthenticationTokenBody } =
            authenticationToken;
          const response = await api.patch({
            endpoint: `/authentication-tokens/${authenticationToken.id}`,
            data: { ...AuthenticationTokenBody },
          });
          return response;
        },
      );
      const responses: any = await Promise.all(results);
      dispatch(updateAuthenticationTokenSuccess(responses));
    } catch (e) {
      dispatch(updateAuthenticationTokenFailure());
      return console.error(e);
    }
  };
