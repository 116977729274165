// STYLES
import './index.scss';

// TYPES
import { TMenuSubItem } from '../../types/menuItem.type';

// COMPONENTS
import Menu2ndLevelItem from '../Menu2ndLevelItem/index';

type Props = {
  title: string;
  subItems: TMenuSubItem[];
  noSideBarProp: boolean;
  collapsedSideBarProp?: boolean;
};

const Menu2ndLevel = ({
  title,
  subItems,
  noSideBarProp,
  collapsedSideBarProp,
}: Props) => {
  const classNames = ['sidebar-menu--wrapper'];
  if (noSideBarProp === true || collapsedSideBarProp === true) {
    classNames.push('hidden');
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='heading--wrapper'>
        <h3 className='sidebar-heading--text'>{title}</h3>
      </div>
      <div className='sidebar-item--wrapper'>
        {subItems.map((subItem: any) => (
          <Menu2ndLevelItem
            route={subItem.route}
            label={subItem.label}
            key={subItem.label}
          />
        ))}
      </div>
    </div>
  );
};

export default Menu2ndLevel;
