import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setBreadcrumbs } from '../../slices/ui.slice';
import { menuJSON, nextMenuJSON } from '../../assets/menuJSON';

// STYLES
import './index.scss';
import { Divider } from 'antd';

type Props = {
  customerSignature: string;
};

const HomePage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        { path: `/`, label: `Homepage` },
      ]),
    );
  }, [customerSignature, dispatch]);

  return (
    <div className='page-links--wrapper'>
      {process.env.REACT_APP_TE_APP_ENV === 'production' ? (
        <>
          <Link
            key={menuJSON[1].label}
            to={menuJSON[1].route}
            className='page-link'
          >
            <div>
              {menuJSON[1].label}{' '}
              <div className='page-text'>{menuJSON[1].text}</div>
            </div>
          </Link>
        </>
      ) : (
        <>
          {menuJSON.slice(1, menuJSON.length).map((menu: any) => (
            <Link key={menu.label} to={menu.route} className='page-link'>
              <div>
                {menu.label} <div className='page-text'>{menu.text}</div>
              </div>
            </Link>
          ))}
          <Divider />
          {nextMenuJSON.map((menu: any) => (
            <Link key={menu.label} to={menu.route} className='page-link'>
              <div>
                {menu.label} <div className='page-text'>{menu.text}</div>
              </div>
            </Link>
          ))}
        </>
      )}
    </div>
  );
};

export default HomePage;
