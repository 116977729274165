// i18N
import intl from '../../../i18n/intl';

// STYLES
import '../index.scss';
import { List, Button, Popconfirm, Tooltip, Divider } from 'antd';
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons';

type Props = {
  data: TListValues[] | undefined;
  onSelect: any;
  onDelete?: any;
  onCopy?: any;
  selectedItemId: boolean | number | string | any;
};

type TListValues = {
  value: string | number;
  listLabel: string;
  fieldType?: string;
};

const language: any = intl.messages;

const NormalListView = ({
  data,
  onSelect = () => {},
  onDelete = () => {},
  onCopy = () => {},
  selectedItemId,
}: Props) => {
  const classNamesHiddenCopy = ['list-item-action-button copy'];
  if (onCopy === false) {
    classNamesHiddenCopy.push('not-visible');
  }
  const classNamesHiddenDelete = ['list-item-action-button'];
  if (onDelete === false) {
    classNamesHiddenDelete.push('not-visible');
  }
  return (
    <List
      itemLayout='horizontal'
      dataSource={data}
      renderItem={(item) => {
        const classNames = [];
        if (item.value === selectedItemId) {
          classNames.push('selected-item');
        }
        if (item.value === 0) {
          classNames.push('not-saved-item');
        }
        if (item.value !== selectedItemId) {
          return (
            <List.Item
              className={classNames.join(' ')}
              onClick={() => {
                onSelect(item.value);
              }}
              actions={[
                <>
                  {item.fieldType && (
                    <>
                      <Divider type='vertical' />
                      <span className='list-item-text'>{item.fieldType}</span>
                    </>
                  )}
                </>,
                <Tooltip
                  title={language.copy}
                  placement='topLeft'
                  key={item.value}
                >
                  <Button
                    size='small'
                    danger
                    type='default'
                    className={classNamesHiddenCopy.join(' ')}
                    icon={<CopyOutlined />}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onCopy();
                    }}
                    // How to make this one below false ALWAYS?
                    ant-click-animating-without-extra-node='false'
                  />
                </Tooltip>,
                <Popconfirm
                  key={item.value}
                  title={`${language.sure_to_delete_object}?`}
                  onConfirm={(event: any) => {
                    event.stopPropagation();
                    onDelete(item.value);
                  }}
                  onCancel={() => console.log('should do nothing, no delete')}
                  okText={language.yes}
                  cancelText={language.no}
                >
                  <Button
                    size='small'
                    danger
                    ghost
                    type='default'
                    className={classNamesHiddenDelete.join(' ')}
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>,
              ]}
            >
              <Tooltip title={item.listLabel} placement={'left'}>
                <p className='list-item-text'>{item.listLabel}</p>
              </Tooltip>
            </List.Item>
          );
        } else {
          return (
            <List.Item
              className={classNames.join(' ')}
              onClick={() => {
                onSelect(item.value);
              }}
              actions={[
                <Tooltip
                  title={language.copy}
                  placement='topLeft'
                  key={item.value}
                >
                  <Button
                    size='small'
                    danger
                    type='default'
                    className={classNamesHiddenCopy.join(' ')}
                    icon={<CopyOutlined />}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onCopy();
                    }}
                    // How to make this one below false ALWAYS?
                    ant-click-animating-without-extra-node='false'
                  />
                </Tooltip>,
                <Popconfirm
                  title={`${language.sure_to_delete_object}?`}
                  key={item.value}
                  onConfirm={(event: any) => {
                    event.stopPropagation();
                    onDelete(item.value);
                  }}
                  onCancel={() => console.log('should do nothing, no delete')}
                  okText={language.yes}
                  cancelText={language.no}
                >
                  <Button
                    size='small'
                    danger
                    ghost
                    type='default'
                    className={classNamesHiddenDelete.join(' ')}
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>,
              ]}
            >
              <Tooltip title={item.listLabel} placement={'left'}>
                <p className='list-item-text'>{item.listLabel}</p>
              </Tooltip>
            </List.Item>
          );
        }
      }}
    />
  );
};

export default NormalListView;
