import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TSystemAction } from '../types/systemAction.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedSystemAction: {},
  allSystemActions: [],
};

// Slice
const slice = createSlice({
  name: 'systemActions',
  initialState,
  reducers: {
    fetchSystemActionsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchSystemActionsSuccess: (state: any, { payload }) => {
      state.allSystemActions = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchSystemActionSuccess: (state: any, { payload }) => {
      state.selectedSystemAction = payload;
      finishedLoadingSuccess(state);
    },
    fetchSystemActionsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentSystemAction: (state: any, { payload }) => {
      state.selectedSystemAction = payload;
    },
  },
});

export default slice.reducer;

// Selectors
export const systemActionsLoading = (state: any) => state.systemActions.loading;
export const systemActionsSelector = (state: any) =>
  state.systemActions.allSystemActions as TSystemAction[];
export const selectedSystemActionSelector = (state: any) => {
  return state.systemActions.selectedSystemAction;
};

// Actions
export const {
  fetchSystemActionsRequest,
  fetchSystemActionsSuccess,
  fetchSystemActionsFailure,
  fetchSystemActionSuccess,
  setCurrentSystemAction,
} = slice.actions;

export const fetchSystemActions = () => async (dispatch: any) => {
  try {
    dispatch(fetchSystemActionsRequest());
    const systemActions = await api.get({ endpoint: `/system-actions` });
    dispatch(fetchSystemActionsSuccess(systemActions));
  } catch (e) {
    dispatch(fetchSystemActionsFailure());
    return console.error(e);
  }
};

export const setSelectedSystemAction =
  (systemAction: TSystemAction) => async (dispatch: any) => {
    dispatch(setCurrentSystemAction(systemAction));
  };

export const fetchSystemActionInfo =
  (actionId: any, valueQuery?: string | number) => async (dispatch: any) => {
    try {
      dispatch(fetchSystemActionsRequest());
      let url = `/system-actions/${actionId}`;
      if (valueQuery !== undefined) {
        url = `/system-actions/${actionId}?value=${valueQuery}`;
      }
      const systemAction = await api.get({
        endpoint: url,
      });
      dispatch(fetchSystemActionSuccess(systemAction));
    } catch (e: any) {
      dispatch(fetchSystemActionsFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
