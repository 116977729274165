export enum ERelationType {
  USE_ONLY = 'USE_ONLY',
  STRICT = 'STRICT',
  DYNAMIC = 'DYNAMIC',
}

export type TRelationType = {
  name: string;
  id: number;
  description: string;
  relationType: ERelationType;
  relationTypes: Array<TRelationTypesItem>;
  reservationTemplates: Array<number>;
};

export class RelationType {
  static createEmpty = (): TRelationType => ({
    name: '',
    id: 0,
    description: '',
    relationType: ERelationType.USE_ONLY,
    relationTypes: [],
    reservationTemplates: [],
  });
}

export type TRelationTypesItem = {
  type: number | string | undefined;
  helpType: boolean;
};

export class RelationTypesItem {
  static create = (
    typeId: number | string | undefined,
  ): TRelationTypesItem => ({
    type: typeId,
    helpType: false,
  });
}
