import { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// i18N
import intl from '../../i18n/intl';

// ACTIONS
import {
  fetchReservationTemplates,
  reservationTemplatesSelector,
} from '../../slices/reservationTemplate.slice';

// STYLES
import {
  Collapse,
  Form,
  Input,
  Checkbox,
  Transfer,
  Button,
  Menu,
  Dropdown,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

// TYPES
import {
  TReservationMode,
  TReservationTemplate,
} from '../../types/reservation.type';

// COMPONENTS
import { TEItem } from '../TEToolTip';

type PropsGeneral = {
  reservationMode: TReservationMode;
  onChange: Function;
  limitations: any;
};

type PropsTemplates = {
  onChange: Function;
  templates: Array<number>;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const ReservationModeGeneralSettings = ({
  reservationMode,
  onChange,
  limitations,
}: PropsGeneral) => {
  // EVENT HANDLERS
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  const onChangeCheckboxHandler = (event: any, property: string) => {
    onChange(property, event.target.checked);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Form.Item
          label={language.name}
          tooltip={`(Maximum size of ${limitations.name} bytes)`}
        >
          <Input
            size='small'
            defaultValue={reservationMode.name}
            value={reservationMode.name}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'name', limitations.name)
            }
          />
        </Form.Item>
        <p className='form-item-text' style={{ marginBottom: '1em' }}>
          {language.id}: {reservationMode.id}
        </p>
        <Form.Item
          label={language.ext_id}
          tooltip={`${language.help_ext_id} (Maximum size of ${limitations.extId} bytes)`}
        >
          <Input
            size='small'
            defaultValue={reservationMode.extId}
            value={reservationMode.extId}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'extId', limitations.extId)
            }
          />
        </Form.Item>
        <Form.Item
          tooltip={`(Maximum size of ${limitations.description} bytes)`}
          label={language.description}
        >
          <Input.TextArea
            size='small'
            rows={5}
            defaultValue={reservationMode.description}
            value={reservationMode.description}
            onChange={(value: any) =>
              onChangeInputHandler(
                value,
                'description',
                limitations.description,
              )
            }
          />
        </Form.Item>
        <TEItem helpText={language.help_show_in_client}>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'showInClient')
            }
            checked={reservationMode.showInClient}
          >
            {language.show_in_client}
          </Checkbox>
        </TEItem>
        <TEItem helpText={language.help_show_in_culling}>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'showInCulling')
            }
            checked={reservationMode.showInCulling}
          >
            {language.show_in_culling}
          </Checkbox>
        </TEItem>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const ReservationModeReservationTemplates = ({
  templates,
  onChange,
}: PropsTemplates) => {
  // Here we make an API-request for fetching all available Reservation templates
  const dispatch = useDispatch();
  const reservationTemplates: TReservationTemplate[] = useSelector(
    reservationTemplatesSelector,
  );

  const [templateTypeQuery, setTemplateTypeQuery] = useState<string>('all');
  const [templateTypeOption, setTemplateTypeOption] =
    useState<string>('templateType');
  const [activeQuery, setActiveQuery] = useState<string>();
  const [activeOption, setActiveOption] = useState<string>();

  useEffect(() => {
    dispatch(
      fetchReservationTemplates(
        templateTypeOption,
        templateTypeQuery,
        undefined,
        undefined,
        activeOption,
        activeQuery,
      ),
    );
  }, [dispatch, templateTypeQuery, activeQuery]);

  // Data with ALL available res.templates
  const dataSource = reservationTemplates.map((template: any) => ({
    key: String(template.id),
    title: template.name,
    description: template.description,
    active: template.active,
  }));

  // The choosen ones
  const targetKeys = templates?.map((selectedtemplate: any) =>
    String(selectedtemplate),
  );

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const onSelection = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChange = (nextTargetKeys: any) => {
    onChange(
      'reservationTemplates',
      nextTargetKeys.map((key: string) => parseInt(key)),
    );
  };

  const menu = (
    <Menu
      onClick={(event: any) => {
        if (event.domEvent.target.id === 'templateType') {
          setTemplateTypeOption(event.domEvent.target.id);
          setTemplateTypeQuery(event.key);
        }
        if (event.domEvent.target.id === 'active') {
          setActiveOption(event.domEvent.target.id);
          setActiveQuery(event.key);
        }
      }}
      className='list-search--menu'
    >
      <Menu.SubMenu title={language.filter_on_template_type}>
        <Menu.Item
          key='all'
          id='templateType'
          className='list-search--menu-item'
        >
          {language.all}
        </Menu.Item>
        <Menu.Item
          key='reservation'
          id='templateType'
          className='list-search--menu-item'
        >
          {language.reservation}
        </Menu.Item>
        <Menu.Item
          key='availability'
          id='templateType'
          className='list-search--menu-item'
        >
          {language.availability}
        </Menu.Item>
        <Menu.Item
          key='infoReservation'
          id='templateType'
          className='list-search--menu-item'
        >
          {language.infoReservation}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title={language.filter_on_active}>
        <Menu.Item key='active' id='active' className='list-search--menu-item'>
          {language.active}
        </Menu.Item>
        <Menu.Item
          key='inactive'
          id='active'
          className='list-search--menu-item'
        >
          {language.inactive}
        </Menu.Item>
        <Menu.Item key='all' id='active' className='list-search--menu-item'>
          {language.all}
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
  return (
    <Collapse defaultActiveKey={['2']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.reservation_templates} key='2'>
        <Dropdown overlay={menu} className='search-dropdown'>
          <Button
            size='small'
            type='default'
            style={{ width: 'auto', fontSize: '14px', marginBottom: '8px' }}
          >
            Filter
            <DownOutlined />
          </Button>
        </Dropdown>
        <Form.Item>
          <Transfer
            dataSource={dataSource}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onSelectionChange}
            onSelectChange={onSelection}
            render={(item) => (
              <span>
                {item.title}
                {activeOption === 'active' && (
                  <span style={{ fontStyle: 'italic' }}>
                    - {item.active === true ? 'active' : 'inactive'}
                  </span>
                )}
              </span>
            )}
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};
