import { useState, ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { reservationTemplatesSelector } from '../../slices/reservationTemplate.slice';
import { fetchFields, fieldsSelector } from '../../slices/field.slice';

// i18N
import intl from '../../i18n/intl';

// TYPES
import { TReservationTemplate } from '../../types/reservation.type';
import { TField, EFieldType } from '../../types/field.type';
import {
  EMatchMode,
  EOperator,
  TFieldRelation,
} from '../../types/fieldRelation.type';

// STYLES
import './index.scss';
import {
  Collapse,
  Form,
  Input,
  Select,
  Transfer,
  Button,
  Divider,
  Modal,
} from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

// COMPONENTS
import { TEToolTip, TEItem } from '../TEToolTip';

type PropsGeneral = {
  fieldRelation: TFieldRelation;
  onChange: Function;
  limitations: any;
};

type PropsConfiguration = {
  fieldRelation: TFieldRelation;
  onChange: Function;
  unvalidFieldRelation: boolean;
};

type PropsReservationTemplates = {
  reservationTemplatesConnections: Array<number>;
  onChange: Function;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

const onBlur = () => {};
const onFocus = () => {};

export const FieldRelationsGeneralSettings = ({
  fieldRelation,
  onChange,
  limitations,
}: PropsGeneral) => {
  // EVENT HANDLERS
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Form.Item
          label={language.name}
          tooltip={`(Maximum size of ${limitations.name} bytes)`}
        >
          <Input
            size='small'
            defaultValue={fieldRelation.name}
            value={fieldRelation.name}
            onChange={(event: any) => {
              onChangeInputHandler(event, 'name', limitations.name);
            }}
          />
        </Form.Item>
        <p className='form-item-text' style={{ marginBottom: '1em' }}>
          {language.id}: {fieldRelation.id}
        </p>
        <Form.Item
          label={language.description}
          tooltip={`(Maximum size of ${limitations.description} bytes)`}
        >
          <Input.TextArea
            size='small'
            rows={5}
            defaultValue={fieldRelation.description}
            value={fieldRelation.description}
            onChange={(value: any) =>
              onChangeInputHandler(
                value,
                'description',
                limitations.description,
              )
            }
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const FieldRelationsConfiguration = ({
  fieldRelation,
  onChange,
  unvalidFieldRelation,
}: PropsConfiguration) => {
  const dispatch = useDispatch();
  // Fields
  const fields: TField[] = useSelector(fieldsSelector);
  useEffect(() => {
    dispatch(fetchFields());
  }, [dispatch]);

  // Gets the fieldType on the choosen field
  const getFieldTypeOnField = (field: any) => {
    const result = fields.find((f: any) => f.id === field);
    if (result) {
      return result.fieldType;
    }
    return field;
  };

  // Gets fieldName on the fields
  const getFieldNameOnField = (field: any) => {
    const result = fields.find((f: any) => f.id === field);
    if (result) {
      return result.extId;
    }
    return field;
  };

  const possibleFieldTypesToUse = [
    'show_all',
    EFieldType.INTEGER,
    EFieldType.TEXT,
    EFieldType.SIGNATURE,
    EFieldType.REFERENCE,
    EFieldType.CATEGORY,
    EFieldType.CHECKBOX,
  ];
  const [filterOnFieldTypes, setFilterOnFieldTypes] = useState<any>();

  // Fields in sourceFields will be fields with fieldType integer, text,
  // signature, reference, category & checkbox
  let sourceFields: any[] = [];
  sourceFields = fields.filter(
    (field: TField) => field.fieldType === EFieldType.INTEGER,
  );
  sourceFields.push(
    ...fields.filter((field: TField) => field.fieldType === EFieldType.TEXT),
  );
  sourceFields.push(
    ...fields.filter((field: TField) => field.fieldType === EFieldType.SIGNATURE),
  );
  sourceFields.push(
    ...fields.filter((field: TField) => field.fieldType === EFieldType.REFERENCE),
  );
  sourceFields.push(
    ...fields.filter((field: TField) => field.fieldType === EFieldType.CATEGORY),
  );
  sourceFields.push(
    ...fields.filter((field: TField) => field.fieldType === EFieldType.CHECKBOX),
  );

  let filteredSourceFields: Array<TField> = [];
  if (filterOnFieldTypes === EFieldType.TEXT) {
    filteredSourceFields.push(
      ...fields.filter((field: TField) => field.fieldType === EFieldType.TEXT),
    );
  } else if (filterOnFieldTypes === EFieldType.SIGNATURE) {
    filteredSourceFields.push(
      ...fields.filter((field: TField) => field.fieldType === EFieldType.SIGNATURE),
    );
  } else if (filterOnFieldTypes === EFieldType.REFERENCE) {
    filteredSourceFields.push(
      ...fields.filter((field: TField) => field.fieldType === EFieldType.REFERENCE),
    );
  } else if (filterOnFieldTypes === EFieldType.CATEGORY) {
    filteredSourceFields.push(
      ...fields.filter((field: TField) => field.fieldType === EFieldType.CATEGORY),
    );
  } else if (filterOnFieldTypes === EFieldType.CHECKBOX) {
    filteredSourceFields.push(
      ...fields.filter((field: TField) => field.fieldType === EFieldType.CHECKBOX),
    );
  } else if (
    filterOnFieldTypes === undefined ||
    filterOnFieldTypes === 'show_all'
  ) {
    filteredSourceFields = sourceFields;
  }

  // Array of available matchFields based on fieldType of the choosen sourceField
  let matchFields: any[] = [];
  if (
    getFieldTypeOnField(fieldRelation.sourceField) === EFieldType.TEXT ||
    getFieldTypeOnField(fieldRelation.sourceField) === EFieldType.SIGNATURE ||
    getFieldTypeOnField(fieldRelation.sourceField) === EFieldType.REFERENCE
  ) {
    matchFields = fields.filter((field: any) => field.fieldType === EFieldType.TEXT);
    matchFields.push(
      ...fields.filter((field: any) => field.fieldType === EFieldType.SIGNATURE),
    );
    matchFields.push(
      ...fields.filter((field: any) => field.fieldType === EFieldType.REFERENCE),
    );
    matchFields.push(
      ...fields.filter((field: any) => field.fieldType === EFieldType.CATEGORY),
    );
  } else if (getFieldTypeOnField(fieldRelation.sourceField) === EFieldType.CATEGORY) {
    matchFields = fields.filter((field: any) => field.fieldType === EFieldType.CATEGORY);
  } else if (getFieldTypeOnField(fieldRelation.sourceField) === EFieldType.INTEGER) {
    matchFields = fields.filter((field: any) => field.fieldType === EFieldType.INTEGER);
  } else {
    matchFields = fields.filter((field: any) => field.fieldType === EFieldType.CHECKBOX);
  }

  // EVENT HANDLER
  const onChangeSelectHandler = (event: any, property: string) => {
    onChange(property, event);
  };

  // Help text for which relations that are possible to make when creating Field relations
  const onShowHelpModal = () => {
    Modal.info({
      title: `${language.allowed_relations}`,
      content: (
        <div>
          <p>
            {language.integer_field} - {language.integer_field}{' '}
          </p>
          <p>
            {language.text_field} - {language.text_field}
          </p>
          <p>
            {language.text_field} - {language.category_field}
          </p>
          <p>
            {language.category_field} - {language.category_field}
          </p>
          <p>
            {language.checkbox_field} - {language.checkbox_field}
          </p>
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.configuration} key='2'>
        <div style={{ display: 'flex', marginBottom: '16px' }}>
          <div
            style={{
              marginRight: 100,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TEToolTip helpText={language.help_source_field_field_relations}>
              <p
                className='small-heading-text'
                style={{ marginBottom: 0, width: 90 }}
              >
                {language.source_field}
              </p>
            </TEToolTip>
            <Select
              size='small'
              showSearch
              onFocus={onFocus}
              onBlur={onBlur}
              style={{ width: 220, marginLeft: 4 }}
              value={
                fieldRelation.sourceField !== null
                  ? fieldRelation.sourceField
                  : '-'
              }
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value: any) => {
                onChangeSelectHandler(value, 'sourceField');
              }}
            >
              {filteredSourceFields
                .sort((a: any, b: any) => (a.extId > b.extId ? 1 : -1))
                .map((field: any) => (
                  <Select.Option key={field.extId} value={field.id}>
                    {field.extId}
                  </Select.Option>
                ))}
            </Select>
            {unvalidFieldRelation === true ? (
              <p style={{ color: '#ff6357', fontSize: '14px' }}>
                {language.unvalid_combination_fields}
              </p>
            ) : null}
            <Button
              size='small'
              icon={<QuestionCircleFilled />}
              style={{ border: 'none', boxShadow: 'none' }}
              onClick={onShowHelpModal}
            />
            <TEToolTip helpText={language.filter_on_field_type}>
              <Select
                size='small'
                style={{ width: 200, marginLeft: 4 }}
                placeholder={language.filter_on_field_type}
                onChange={(value: any) => setFilterOnFieldTypes(value)}
              >
                {possibleFieldTypesToUse.map((fieldType: string) => (
                  <Select.Option value={fieldType} key={fieldType}>
                    {language[fieldType]}
                  </Select.Option>
                ))}
              </Select>
            </TEToolTip>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              className='small-heading-text'
              style={{ marginBottom: 0, marginRight: 6 }}
            >
              {language.field_type}
            </p>
            <p className='info-text' style={{ marginBottom: 0 }}>
              {getFieldTypeOnField(fieldRelation.sourceField)}
            </p>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '16px' }}>
          <div
            style={{
              marginRight: 100,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TEToolTip helpText={language.help_match_field_field_relations}>
              <p
                className='small-heading-text'
                style={{ marginBottom: 0, width: 90 }}
              >
                {language.match_field}
              </p>
            </TEToolTip>
            <Select
              size='small'
              showSearch
              onFocus={onFocus}
              onBlur={onBlur}
              style={{ width: 220, marginLeft: 4 }}
              value={fieldRelation?.matchField}
              onChange={(value: any) =>
                onChangeSelectHandler(value, 'matchField')
              }
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {matchFields
                .sort((a, b) => (a.extId > b.extId ? 1 : -1))
                .map((field: any) => (
                  <Select.Option key={field.id} value={field.id}>
                    {field.extId}
                  </Select.Option>
                ))}
            </Select>
            <Button
              size='small'
              icon={<QuestionCircleFilled />}
              style={{ border: 'none', boxShadow: 'none' }}
              onClick={onShowHelpModal}
            />
            <TEToolTip helpText={language.filter_on_field_type}>
              <Select
                size='small'
                style={{ width: 200, marginLeft: 4, visibility: 'hidden' }}
                placeholder={language.filter_on_field_type}
                onChange={(value: any) => setFilterOnFieldTypes(value)}
              >
                {possibleFieldTypesToUse.map((fieldType: string) => (
                  <Select.Option value={fieldType} key={fieldType}>
                    {language[fieldType]}
                  </Select.Option>
                ))}
              </Select>
            </TEToolTip>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              className='small-heading-text'
              style={{ marginBottom: 0, marginRight: 6 }}
            >
              {language.field_type}
            </p>
            <p className='info-text' style={{ marginBottom: 0 }}>
              {getFieldTypeOnField(fieldRelation?.matchField)}
            </p>
          </div>
        </div>
        {getFieldTypeOnField(fieldRelation.sourceField) === EFieldType.INTEGER &&
        getFieldTypeOnField(fieldRelation.matchField) === EFieldType.INTEGER ? (
          <>
            <Divider />
            <p className='small-heading-text'>
              {language.additional_configuration}
            </p>
            <TEItem
              label={language.operator}
              helpText={language.help_operator_field_relations}
            >
              <div className='operator-info'>
                <p>{getFieldNameOnField(fieldRelation?.matchField)}</p>{' '}
                <Select
                  style={{ width: 80, paddingLeft: 8, paddingRight: 8 }}
                  size='small'
                  showSearch
                  onFocus={onFocus}
                  onBlur={onBlur}
                  value={fieldRelation.operator}
                  onChange={(value: any) => {
                    onChangeSelectHandler(value, 'operator');
                  }}
                  placeholder={EOperator.E}
                >
                  {Object.entries(EOperator).map(([key, value]) => (
                    <Select.Option value={value} key={key}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
                <p>{getFieldNameOnField(fieldRelation?.sourceField)}</p>
              </div>
            </TEItem>
          </>
        ) : null}
        {getFieldTypeOnField(fieldRelation.sourceField) === EFieldType.CATEGORY &&
        getFieldTypeOnField(fieldRelation.matchField) === EFieldType.CATEGORY ? (
          <>
            <Divider />
            <p className='small-heading-text'>
              {language.additional_configuration}
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TEToolTip helpText={language.help_match_mode_field_relations}>
                <p className='small-heading-text' style={{ marginBottom: 0 }}>
                  {language.match_mode}:
                </p>
              </TEToolTip>
              <div style={{ marginLeft: 4 }}>
                <Button
                  size='small'
                  type={
                    fieldRelation.matchMode === EMatchMode.MATCH_ONE
                      ? 'primary'
                      : 'default'
                  }
                  value={EMatchMode.MATCH_ONE}
                  onClick={() =>
                    onChangeSelectHandler(EMatchMode.MATCH_ONE, 'matchMode')
                  }
                >
                  {language.matchOne}
                </Button>
                <Button
                  size='small'
                  type={
                    fieldRelation.matchMode === EMatchMode.MATCH_ALL
                      ? 'primary'
                      : 'default'
                  }
                  value={EMatchMode.MATCH_ALL}
                  onClick={() =>
                    onChangeSelectHandler(EMatchMode.MATCH_ALL, 'matchMode')
                  }
                >
                  {language.matchAll}
                </Button>
              </div>
            </div>
          </>
        ) : null}
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const FieldRelationsReservationTemplates = ({
  reservationTemplatesConnections,
  onChange,
}: PropsReservationTemplates) => {
  // Data with ALL available res.templates
  const reservationTemplates: TReservationTemplate[] = useSelector(
    reservationTemplatesSelector,
  );
  const dataSourceTemplates = reservationTemplates.map((template: any) => ({
    key: String(template.id),
    title: template.name,
    description: template.description,
  }));

  const targetKeysTemplates = reservationTemplatesConnections?.map(
    (selectedTemplate: any) => String(selectedTemplate),
  );
  const [selectedKeysTemplates, setSelectedKeysTemplates] = useState<string[]>(
    [],
  );

  const onSelectionTemplates = (
    sourceSelectedKeysTemplates: any,
    targetSelectedKeysTemplates: any,
  ) => {
    setSelectedKeysTemplates([
      ...sourceSelectedKeysTemplates,
      ...targetSelectedKeysTemplates,
    ]);
  };

  const onSelectionChangeTemplates = (nextTargetKeysTemplates: any) => {
    onChange(
      'reservationTemplates',
      nextTargetKeysTemplates.map((key: string) => parseInt(key)),
    );
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.reservation_templates} key='3'>
        <Form.Item>
          <Transfer
            dataSource={dataSourceTemplates}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeysTemplates}
            selectedKeys={selectedKeysTemplates}
            onChange={onSelectionChangeTemplates}
            onSelectChange={onSelectionTemplates}
            render={(item) => item.title}
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};
