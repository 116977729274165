import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TimeZone, TTimeZone } from '../types/timeZone.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  allTimeZones: [],
  selectedTimeZone: TimeZone.createEmpty(),
  selectedTimeZones: [],
  limitations: {},
  zones: [],
  dsts: [],
  amountOfReservationsToBeMoved: {},
  numberOfReservationsMoved: {},
};

// Slice
const slice = createSlice({
  name: 'timeZones',
  initialState,
  reducers: {
    fetchTimeZonesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchTimeZonesSuccess: (state: any, { payload }) => {
      const selectedIds = state.selectedTimeZones.map(
        (selectedTimeZone: any) => selectedTimeZone.id,
      );
      state.selectedTimeZones = payload.results.filter(
        (timeZone: any) => selectedIds.indexOf(timeZone.id) !== -1,
      );
      state.selectedTimeZones = payload.results;
      state.allTimeZones = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsTimeZonesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchZonesSuccess: (state: any, { payload }) => {
      state.zones = payload;
      finishedLoadingSuccess(state);
    },
    fetchDstsSuccess: (state: any, { payload }) => {
      state.dsts = payload;
      finishedLoadingSuccess(state);
    },
    fetchTimeZonesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createTimeZoneRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createTimeZoneSuccess: (state: any, { payload }) => {
      state.allTimeZones.push(payload);
      state.selectedTimeZone = payload;
      finishedLoadingSuccess(state);
    },
    createTimeZoneFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setSelectedTimeZone: (state: any, { payload }) => {
      state.selectedTimeZone = payload;
    },
    setSelectedTimeZones: (state: any, { payload }) => {
      state.selectedTimeZones = payload;
    },
    deleteTimeZoneRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteTimeZoneSuccess: (state: any, payload: any) => {
      state.allTimeZones = state.allTimeZones.filter(
        (timeZone: TTimeZone) => timeZone.id !== payload.payload.id,
      );
      state.selectedTimeZones = state.selectedTimeZones.filter(
        (timeZone: TTimeZone) => timeZone.id !== payload.payload.id,
      );
      state.selectedTimeZone = TimeZone.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteTimeZoneFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateTimeZoneRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateTimeZonesSuccess: (state: any, { payload }) => {
      payload.forEach((p: any) => {
        const index = state.allTimeZones.findIndex(
          (timeZone: TTimeZone) => timeZone.id === p.id,
        );
        state.allTimeZones[index] = p;
      });
      finishedLoadingSuccess(state);
    },
    updateTimeZoneFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    getNumberOfReservationsToBeMoved: (state: any, { payload }) => {
      state.amountOfReservationsToBeMoved = payload;
      finishedLoadingSuccess(state);
    },
    moveTimeZonesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    moveTimeZonesSuccess: (state: any, { payload }) => {
      state.numberOfReservationsMoved = payload;
      finishedLoadingSuccess(state);
    },
    moveTimeZonesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const timeZonesLoading = (state: any) => state.timeZones.loading;
export const timeZonesSelector = (state: any) =>
  state.timeZones.allTimeZones as TTimeZone[];
export const timeZoneSelectorCreateNew = () => () => {
  return TimeZone.createEmpty();
};
export const selectedTimeZonesSelector = (state: any) => {
  return state.timeZones.selectedTimeZones;
};
export const limitationsTimeZonesSelector = (state: any) =>
  state.timeZones.limitations;
export const zonesSelector = (state: any) => state.timeZones.zones;
export const dstsSelector = (state: any) => state.timeZones.dsts;
export const amountOfReservationsToBeMovedSelector = (state: any) =>
  state.timeZones.amountOfReservationsToBeMoved;
export const amountOfReservationsMovedSelector = (state: any) =>
  state.timeZones.numberOfReservationsMoved;

// Actions
export const {
  fetchTimeZonesRequest,
  fetchTimeZonesSuccess,
  fetchLimitationsTimeZonesSuccess,
  fetchTimeZonesFailure,
  fetchZonesSuccess,
  fetchDstsSuccess,
  createTimeZoneRequest,
  createTimeZoneSuccess,
  createTimeZoneFailure,
  setSelectedTimeZone,
  setSelectedTimeZones,
  deleteTimeZoneRequest,
  deleteTimeZoneSuccess,
  deleteTimeZoneFailure,
  updateTimeZoneRequest,
  updateTimeZonesSuccess,
  updateTimeZoneFailure,
  getNumberOfReservationsToBeMoved,
  moveTimeZonesRequest,
  moveTimeZonesSuccess,
  moveTimeZonesFailure,
} = slice.actions;

export const fetchTimeZones = () => async (dispatch: any) => {
  try {
    dispatch(fetchTimeZonesRequest());
    const timeZones = await api.get({
      endpoint: `/time-zones`,
    });
    dispatch(fetchTimeZonesSuccess(timeZones));
  } catch (e) {
    dispatch(fetchTimeZonesFailure());
    return console.error(e);
  }
};

export const fetchLimitationsForTimeZones = () => async (dispatch: any) => {
  try {
    dispatch(fetchTimeZonesRequest());
    const limitationsTimeZones = await api.get({
      endpoint: `/time-zones/limitations`,
    });
    dispatch(fetchLimitationsTimeZonesSuccess(limitationsTimeZones));
  } catch (e) {
    dispatch(fetchTimeZonesFailure());
    return console.error(e);
  }
};

export const fetchZones = () => async (dispatch: any) => {
  try {
    dispatch(fetchTimeZonesRequest());
    const zones = await api.get({
      endpoint: `/time-zones/zones`,
    });
    dispatch(fetchZonesSuccess(zones));
  } catch (e) {
    dispatch(fetchTimeZonesFailure());
    return console.error(e);
  }
};

export const fetchDsts = () => async (dispatch: any) => {
  try {
    dispatch(fetchTimeZonesRequest());
    const dsts = await api.get({
      endpoint: `/time-zones/dsts`,
    });
    dispatch(fetchDstsSuccess(dsts));
  } catch (e) {
    dispatch(fetchTimeZonesFailure());
    return console.error(e);
  }
};

export const createTimeZone =
  (timeZone: TTimeZone) => async (dispatch: any) => {
    try {
      dispatch(createTimeZoneRequest());
      const { id: _, ...timeZoneBody } = timeZone;
      const response = await api.post({
        endpoint: `/time-zones`,
        data: timeZoneBody,
      });
      dispatch(createTimeZoneSuccess(response));
    } catch (e: any) {
      dispatch(createTimeZoneFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const setUpdatedTimeZones =
  (timeZones: TTimeZone[]) => async (dispatch: any) => {
    dispatch(setSelectedTimeZones(timeZones));
  };

export const deleteTimeZone =
  (timeZone: TTimeZone) => async (dispatch: any) => {
    try {
      dispatch(deleteTimeZoneRequest());
      await api.delete({
        endpoint: `/time-zones/${timeZone.id}`,
      });
      dispatch(deleteTimeZoneSuccess(timeZone));
    } catch (e) {
      dispatch(deleteTimeZoneFailure());
      return console.error(e);
    }
  };
export const updateTimeZones =
  (timeZones: Array<TTimeZone>) => async (dispatch: any) => {
    try {
      dispatch(updateTimeZoneRequest());
      const results = await timeZones.map(async (timeZone: TTimeZone) => {
        const { ...TimeZoneBody } = timeZone;
        const response = await api.patch({
          endpoint: `/time-zones/${timeZone.id}`,
          data: { ...TimeZoneBody },
        });
        return response;
      });
      const responses: any = await Promise.all(results);
      dispatch(updateTimeZonesSuccess(responses));
    } catch (e: any) {
      dispatch(updateTimeZoneFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const fetchNumberOfReservationsToBeMoved =
  (fromTimeZone: number, toTimeZone: number) => async (dispatch: any) => {
    try {
      dispatch(fetchTimeZonesRequest());
      const amountOfReservationsToBeMoved = await api.get({
        endpoint: `/time-zones/move-reservations?fromTimeZone=${fromTimeZone}&toTimeZone=${toTimeZone}`,
      });
      dispatch(getNumberOfReservationsToBeMoved(amountOfReservationsToBeMoved));
    } catch (e) {
      dispatch(fetchTimeZonesFailure());
      return console.error(e);
    }
  };

export const moveReservationsBetweenTimeZones =
  (timeZonesToMoveBetween: { fromTimeZone: number; toTimeZone: number }) =>
  async (dispatch: any) => {
    try {
      dispatch(moveTimeZonesRequest());
      const { ...timeZonesToMoveBetweenBody } = timeZonesToMoveBetween;
      const amountOfReservationsMoved = await api.patch({
        endpoint: `/time-zones/move-reservations`,
        data: timeZonesToMoveBetweenBody,
      });
      dispatch(moveTimeZonesSuccess(amountOfReservationsMoved));
    } catch (e) {
      dispatch(moveTimeZonesFailure());
      return console.error(e);
    }
  };
