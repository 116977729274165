import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import {
  Maintenance,
  MaintenanceMiscellaneous,
} from '../../../components/MaintenanceComponent';

// ACTIONS
import {
  fetchMaintenance,
  maintenanceSelector,
} from '../../../slices/maintenance.slice';
import { systemActionsSelector } from '../../../slices/systemActions.slice';

// TYPES
import { TSystemAction } from '../../../types/systemAction.type';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const MaintenancePage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const maintenance: any = useSelector(maintenanceSelector);
  const systemActions: TSystemAction[] = useSelector(systemActionsSelector);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/maintenance`,
          label: `${language.maintenance}`,
        },
      ]),
    );
    dispatch(fetchMaintenance());
  }, [customerSignature, dispatch]);

  return (
    <div className='inner-content--wrapper'>
      <SectionHeader
        listChoiceHeading={language.maintenance}
        displayProp
        history={false}
      />
      <Maintenance maintenance={maintenance} />
      <MaintenanceMiscellaneous systemActions={systemActions} />
    </div>
  );
};

export default MaintenancePage;
