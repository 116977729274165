import React, {useState} from 'react';
import './index.scss';
import intl from "../../../i18n/intl";
import {Select, Table} from "antd";
import {
    fetchPaddingRulesViewerListFailure,
    fetchPaddingRulesViewerListRequest,
    fetchPaddingRulesViewerListSuccess,
    paddingRulesSelector,
    paddingRuleViewerListSelector,
} from "../../../slices/paddingRules.slice";
import {useDispatch, useSelector} from "react-redux";
import {TEToolTip} from "../../TEToolTip";
import {typesSelector} from "../../../slices/type.slice";
import {reservationTemplatesSelector} from "../../../slices/reservationTemplate.slice";
import {TType} from "../../../types/type.type";
import {TPaddingRule} from "../../../types/padding.type";
import api from "../../../services/api.service";

const language: any = intl.messages;

type Props = {
    onPaddingRuleSelected: any,
    handleCreate: any
}

/* ---------------------------------------------------------------------- */

const PaddingRulesViewerComponent = ({onPaddingRuleSelected, handleCreate}: Props) => {
    const dispatch = useDispatch();

    const paddingRules: TPaddingRule[] = useSelector(paddingRulesSelector)
    const reservationTemplates = useSelector(reservationTemplatesSelector);
    const allTypes = useSelector(typesSelector);
    const paddingRulesViewerList = useSelector(paddingRuleViewerListSelector);
    const [typeId, setTypeId] = useState(0);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 25,
        total: 0
    });

    const [typeList, setTypeList] = useState<TType[]>([]);

    const columns = [
        {
            title: (
                <TEToolTip helpText=''>
                    <span className='small-heading-text'>{language.object}</span>
                </TEToolTip>
            ),
            dataIndex: 'name',
            key: 'name',
            width: "15%",
            render: (record: any) => (
                <span>{record}</span>
            ),
        },
        {
            title: (
                <TEToolTip helpText=''>
                    <span className='small-heading-text'>{language.rules}</span>
                </TEToolTip>
            ),
            key: 'rules',
            width: "70%",
            render: (record: any) => {
                if (record.paddingRules.length) {
                    return (
                        record.paddingRules.map((id: number) => {
                            const name = paddingRules.find((paddingRule: TPaddingRule) => paddingRule.id === id)?.name
                            return <span className={"rule-button"} key={id} onClick={() => onPaddingRuleSelected(id)}> | {name}</span>
                        })

                    )
                }
                return (<div/>)
            },
        },
        {
            title: (
                <TEToolTip helpText=''>
                    <span className='small-heading-text'>{language.add_rule}</span>
                </TEToolTip>
            ),
            key: 'addItem',
            width: "15%",
            render: (record: any) => (
                <span className={"add-rule-button"} onClick={() => {
                    handleCreate({
                        impactedObjects: [{
                            typeId,
                            fieldCriteria: {
                                fieldId: 0,
                                fieldValue: 0,
                            },
                            objectExtIds: [record.extId]
                        }]
                    })
                }}>+ {language.add_rule}</span>
            ),
        }
    ]

    const getData = async (typeId: any, paginationNew: any = pagination) => {
        try {
            dispatch(fetchPaddingRulesViewerListRequest());
            const response = await api.get({
                endpoint: `/padding-rules/objects-by-type/${parseInt(typeId)}?page=${paginationNew.current}&limit=${paginationNew.pageSize}`
            });
            setPagination({
                ...paginationNew,
                total: response.totalPages * response.limit
            });
            dispatch(fetchPaddingRulesViewerListSuccess(response))
        } catch (e: any) {
            dispatch(fetchPaddingRulesViewerListFailure(e.response.data.message));
            console.error(e);
        }
    }

    return (

        <div className={"inner-content--wrapper"} style={{width: "calc(100vw - 37.0rem)"}}>
            <h2>{language.padding_viewer}</h2>
            <div style={{display: "flex", marginTop: "20px"}}>
                <div style={{width: 200}}>{language.reservation_templates}</div>
                <Select defaultValue={0} style={{width: 160}} onSelect={(value: any) => {
                    const reservationTemplate = reservationTemplates.find((template: any) => {
                        return template.id === value
                    })
                    const types = allTypes.filter((type: any) =>
                        reservationTemplate?.types.find((reservationTemplateType: any) =>
                            reservationTemplateType.type === type.id && reservationTemplateType.padding))
                    setTypeList(types)
                }}>
                    <Select.Option key={0} value={0}>{language.select_reservation_template}</Select.Option>
                    {
                        reservationTemplates.map(template =>
                            <Select.Option key={template.id} value={template.id}>{template.name}</Select.Option>)
                    }
                </Select>
            </div>
            <div style={{display: "flex", marginTop: "20px"}}>
                <div style={{width: 200}}>{language.type}</div>
                <Select value={typeId} style={{width: 160}} onSelect={(value: any) => {
                    setTypeId(parseInt(value))
                    getData(value)
                }}>
                    <Select.Option key={0} value={0}>{language.select_type}</Select.Option>
                    {
                        typeList.map((type: any) => (
                            <Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>
                        ))
                    }
                </Select>
            </div>
            <Table
                size='small'
                dataSource={paddingRulesViewerList?.map((item: any) => {
                    return {
                        name: item.firstPrimaryField,
                        key: item.id,
                        extId: item.extId,
                        paddingRules: item.paddingRuleIds
                    }
                })}
                columns={columns}
                rowKey={record => record.key}
                style={{marginTop: "40px", overflowY: "scroll"}}
                pagination={pagination}
                onChange={(pagination) => {
                    return getData(typeId, pagination)
                }}
            />
        </div>

    );
}

export default PaddingRulesViewerComponent;