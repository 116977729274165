import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// REDUX
import {
  deleteApi,
  selectedApiSelector,
  setSelectedApi,
  setSelectedApis,
} from '../../slices/api.slice';

// i18N
import intl from '../../i18n/intl';

// TYPES
import {
  APIPropertyGroup,
  NewApi,
  TApi,
  TAPIPropertyGroup,
} from '../../types/tapi.type';

// COMPONENTS
import PropertiesTableView from '../List/TableView';
import { PropertyComponent } from './PropertyComponent';

// STYLES
import './index.scss';
import {
  Collapse,
  Table,
  Space,
  Button,
  Popconfirm,
  Input,
  Checkbox,
  Select,
  Divider,
} from 'antd';
import {
  DeleteOutlined,
  SelectOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const language: any = intl.messages;

/* ---------------------------------------------------------------------------------- */

type Props = {
  applicationKeys: TApi[];
  onChange: any;
};

export const APIGeneralSettings = ({ applicationKeys, onChange }: Props) => {
  const dispatch = useDispatch();

  // EVENT HANDLERS
  const onChangeHandler = (
    applicationKey: TApi,
    value: any,
    property: string,
  ) => {
    onChange(value, property, applicationKey, applicationKey.name);
  };

  const onAdd = () => {
    const newApplicationKeys: TApi[] = [...applicationKeys];
    newApplicationKeys.push(NewApi.create());
    dispatch(setSelectedApis(newApplicationKeys));
  };

  const onDeleteApi = (applicationKey: TApi) => {
    dispatch(deleteApi(applicationKey));
  };

  const columns = [
    {
      title: (
        <span className='small-heading-text left-table-heading'>
          Application keys
        </span>
      ),
      children: [
        {
          title: <span className='small-heading-text'>{language.action}</span>,
          key: 'action',
          className: 'center-aligned',
          render: (record: any) => (
            <Space size='middle'>
              <Popconfirm
                title={language.sure_to_delete}
                onConfirm={() => onDeleteApi(record.applicationKey)}
                onCancel={() => console.log('should do nothing, no delete')}
                okText={language.yes}
                cancelText={language.no}
              >
                <Button
                  size='small'
                  danger
                  ghost
                  type='default'
                  className='types-list-action-button-delete'
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          ),
        },
        {
          title: <span className='small-heading-text'>{language.active}</span>,
          className: 'center-aligned',
          render: (isActive: boolean, record: any) => (
            <Checkbox
              checked={isActive}
              onChange={(event: any) =>
                onChangeHandler(
                  record.applicationKey,
                  event.target.checked,
                  'isActive',
                )
              }
            />
          ),
        },
        {
          title: <span className='small-heading-text'>{language.name}</span>,
          dataIndex: 'name',
          key: 'name',
          width: '180px',
          className: 'table-column-item',
          render: (name: string, record: any) => (
            <Input
              size='small'
              value={name}
              onChange={(event: any) =>
                onChangeHandler(
                  record.applicationKey,
                  event.target.value,
                  'name',
                )
              }
            />
          ),
        },
        {
          title: <span className='small-heading-text'>Signature</span>,
          dataIndex: 'signature',
          key: 'signature',
          width: '180px',
          className: 'table-column-item',
          render: (signature: any, record: any) => (
            <Input
              size='small'
              value={signature}
              onChange={(event: any) =>
                onChangeHandler(
                  record.applicationKey,
                  event.target.value,
                  'signature',
                )
              }
            />
          ),
        },
        {
          title: <span className='small-heading-text'>Valid from</span>,
          dataIndex: 'validFrom',
          key: 'validFrom',
          width: '120px',
          className: 'table-column-item',
          render: (validFrom: any, record: any) => (
            <Input
              size='small'
              value={validFrom}
              onChange={(event: any) =>
                onChangeHandler(
                  record.applicationKey,
                  event.target.value,
                  'validFrom',
                )
              }
            />
          ),
        },
        {
          title: <span className='small-heading-text'>Expires</span>,
          dataIndex: 'expires',
          key: 'expires',
          width: '120px',
          className: 'table-column-item',
          render: (expires: any, record: any) => (
            <Input
              size='small'
              value={expires}
              onChange={(event: any) =>
                onChangeHandler(
                  record.applicationKey,
                  event.target.value,
                  'expires',
                )
              }
            />
          ),
        },
      ],
    },
    {
      title: (
        <span className='small-heading-text left-table-heading'>
          Certificates
        </span>
      ),
      children: [
        {
          title: <span className='small-heading-text'>Name</span>,
          dataIndex: 'certificateName',
          key: 'certificateName',
          width: '120px',
          className: 'table-column-item',
          render: (certificateName: any) => <span>{certificateName}</span>,
        },
        {
          title: <span className='small-heading-text'>Registered</span>,
          dataIndex: 'certificateRegistered',
          key: 'certificateRegistered',
          width: '100px',
          className: 'table-column-item',
          render: (certificateRegistered: any) => (
            <span>
              {moment.unix(certificateRegistered).utc().format('YYYY-MM-DD')}
            </span>
          ),
        },
        {
          title: <span className='small-heading-text'>Expires</span>,
          dataIndex: 'certificateExpires',
          key: 'certificateExpires',
          width: '100px',
          className: 'table-column-item',
          render: (certificateExpires: any) => (
            <span>
              {moment.unix(certificateExpires).utc().format('YYYY-MM-DD')}
            </span>
          ),
        },
        {
          title: <span className='small-heading-text'>Issuer</span>,
          dataIndex: 'certificateIssuer',
          key: 'certificateIssuer',
          width: '100px',
          className: 'table-column-item',
          render: (certificateIssuer: any) => <span>{certificateIssuer}</span>,
        },
        {
          title: <span className='small-heading-text'>Description</span>,
          dataIndex: 'description',
          key: 'description',
          width: '100px',
          className: 'table-column-item',
          render: (description: any) => (
            <span style={{ width: 60 }} className='table-column-item'>
              {description}
            </span>
          ),
        },
        {
          title: <span className='small-heading-text'>Import text</span>,
          width: '100px',
          className: 'table-column-item center-aligned',
          render: (record: any) => (
            <Button
              disabled={record.id !== 0}
              icon={<SelectOutlined />}
              size='small'
            />
          ),
        },
      ],
    },
  ];

  return (
    <Collapse defaultActiveKey={['2']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header='Keys and certificates' key='1'>
        <Button
          size='small'
          icon={<PlusOutlined />}
          style={{ marginLeft: 20 }}
          onClick={onAdd}
        >
          {language.add}
        </Button>
        <Table
          size='small'
          columns={columns}
          style={{ padding: '20px' }}
          dataSource={applicationKeys?.map((applicationKey: TApi) => ({
            key: applicationKey.id,
            applicationKey,
            ...applicationKey,
          }))}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------------------- */

type PropsProperties = {
  applicationKeys: TApi[];
};

export const APIProperties = ({ applicationKeys }: PropsProperties) => {
  const dispatch = useDispatch();
  const selectedAPIKey: any = useSelector(selectedApiSelector);

  // Function for selecting applicationKey in the Select-list
  const onApplicationSelected = (selectedValue: any) => {
    const currentApplicationKey = applicationKeys.find(
      (applicationKey: any) => applicationKey.id === selectedValue,
    );
    dispatch(setSelectedApi(currentApplicationKey));
  };

  const onChange = (value: any, property: string) => {
    const index = applicationKeys.indexOf(selectedAPIKey);
    const updatedApplicationKey: any = Object.assign({}, selectedAPIKey);
    if (property === 'name') {
      updatedApplicationKey.certificateName = value;
    }
    updatedApplicationKey[property] = value;
    const newApplicationKeys: TApi[] = [...applicationKeys];
    newApplicationKeys.splice(index, 1, updatedApplicationKey);
    dispatch(setSelectedApis(newApplicationKeys));
    dispatch(setSelectedApi({ ...selectedAPIKey, [property]: value }));
  };

  // Function for selecting property Group
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState<any>();
  const rowSelection: any = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectedPropertyGroup(selectedRows[0].id);
    },
    getCheckboxProps: (record: TAPIPropertyGroup) => ({
      name: record.name,
    }),
  };

  const onChangePropertyGroupHandler = (
    propertyGroup: any,
    property: string,
    value: any,
    data: any[],
  ) => {
    if (property === 'properties') {
      const index = selectedAPIKey.propertyGroups.indexOf(propertyGroup);
      const updatedItem = Object.assign({}, propertyGroup);
      updatedItem[property] = value;
      const newItems: any = [...selectedAPIKey.propertyGroups];
      newItems.splice(index, 1, updatedItem);
      onChange(newItems, 'propertyGroups');
    } else {
      const index = data.indexOf(propertyGroup);
      const updatedItem = Object.assign({}, propertyGroup);
      updatedItem[property] = value;
      const newItems: any = [...data];
      newItems.splice(index, 1, updatedItem);
      onChange(newItems, 'propertyGroups');
    }
  };

  // Handlers for adding propertyGroup
  const addPropertyGroup = () => {
    const newPropertyGroups: any = [...selectedAPIKey.propertyGroups];
    newPropertyGroups.push(APIPropertyGroup.createEmpty());
    onChange(newPropertyGroups, 'propertyGroups');
  };

  const currentPropertyGroup = selectedAPIKey.propertyGroups.find(
    (propertyGroup: any) => propertyGroup.id === selectedPropertyGroup,
  );

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header='Properties' key='2'>
        <Select
          size='small'
          style={{ width: 200 }}
          onSelect={onApplicationSelected}
          value={
            selectedAPIKey?.id !== 0
              ? selectedAPIKey?.id
              : applicationKeys[0]?.id
          }
        >
          {applicationKeys.map((applicationKey: any) => (
            <Select.Option key={applicationKey.id} value={applicationKey.id}>
              {applicationKey.name}
            </Select.Option>
          ))}
        </Select>
        <Divider />
        <div className='property-section'>
          <p className='small-heading-text'>Property groups</p>
          <Button
            size='small'
            style={{ marginBottom: '8px' }}
            onClick={addPropertyGroup}
          >
            Add property group
          </Button>
          <PropertiesTableView
            data={selectedAPIKey?.propertyGroups}
            rowSelection={rowSelection}
            onChange={onChange}
            onChangePropertyGroupHandler={onChangePropertyGroupHandler}
          />
        </div>
        <div className='property-section'>
          <PropertyComponent
            properties={currentPropertyGroup?.properties}
            currentPropertyGroup={currentPropertyGroup}
            onChangePropertyGroupHandler={onChangePropertyGroupHandler}
          />
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};
