import React, {useEffect, useState} from 'react';

import intl from "../../../i18n/intl";
import {Form, Input, Switch} from "antd";
import {EPaddingRuleType, TPaddingRule} from "../../../types/padding.type";
import ObjectCriteriaComponent from "../ObjectCriteriaComponent";
import './index.scss';
import TimeTravelPaddingMatrix from "../TimeTravelPaddingMatrix";
import {cloneDeep} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {
    setPaddingRuleErrorMessage,
    travelTimeRuleSelector
} from "../../../slices/paddingRules.slice";
import {FromSecondsInputNumber} from "../../FromSeconds/FromSecondsInputNumber";
type PropsPaddingRule = {
    paddingRule: TPaddingRule;
    onChange: Function;
    limitations?: any;
}

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

const PaddingRulesFormComponent = ({limitations, paddingRule, onChange}: PropsPaddingRule) => {
    const dispatch = useDispatch();

    // @ts-ignore
    const [useTimeConditions, setUseTimeConditions] = useState( paddingRule.rule.timeConditions ? !!paddingRule.rule.timeConditions[0]?.endTime : false);
    const rule = useSelector(travelTimeRuleSelector)

    const onChangeInputHandler = (
        value: any,
        property: string,
        limitationValue?: any,
    ) => {
        if (limitationValue) {
            if (
                new TextEncoder().encode(value).length > limitationValue
            ) {
                return;
            }
        }
        onChange(property, value, limitationValue);
    };

    useEffect(() => {
        if(paddingRule.paddingType === EPaddingRuleType.DIRECT) {
            const message = 'Start time must be less than end time'
            // @ts-ignore
            if (parseInt(paddingRule.rule?.timeConditions[0]?.startTime) > parseInt((paddingRule.rule?.timeConditions[0]?.endTime || 0))) {
                    dispatch(setPaddingRuleErrorMessage(message));
                    return;
            }

            dispatch(setPaddingRuleErrorMessage(''));
        }
    }, [paddingRule])

    return (
        <>
            <Form.Item
                label={language.name}
                tooltip={`(Maximum size of ${limitations.name} bytes)`}
            >
                <Input
                    size='small'
                    value={paddingRule.name}
                    onChange={(event: any) => onChangeInputHandler(event.target.value, 'name', limitations.name)}/>
            </Form.Item>
            <Form.Item
                label={language.description}
                tooltip={`(Maximum size of ${limitations.description} bytes)`}
            >
                <Input.TextArea
                    size='small'
                    rows={10}
                    defaultValue={paddingRule.description}
                    value={paddingRule.description}
                    onChange={(event: any) => onChangeInputHandler(event.target.value, 'description', limitations.description)}/>
            </Form.Item>
            <ObjectCriteriaComponent paddingRule={paddingRule}/>

            {paddingRule.paddingType === EPaddingRuleType.DIRECT && (
                <div className={'direct-padding-input-wrapper'}>
                    <div style={{display: "flex"}}>
                        <h3>{language.time_conditionality}</h3>
                        <Form.Item style={{marginLeft: "20px"}} label={language.use_time_conditionally}>
                            <Switch defaultChecked={useTimeConditions} onChange={(value: boolean) => setUseTimeConditions(value)}/>
                        </Form.Item>
                    </div>
                    {useTimeConditions && (
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Form.Item
                                label={`${language.start_before} (${language.h_mm})`}
                            >
                                {FromSecondsInputNumber(
                                    // @ts-ignore
                                    paddingRule.rule?.timeConditions[0]?.startTime || 0,
                                    (value: any) => {
                                        const rule = cloneDeep(paddingRule.rule);
                                        // @ts-ignore
                                        if(!rule.timeConditions[0]) rule.timeConditions[0] = {};
                                        // @ts-ignore
                                        rule.timeConditions[0].startTime = value;
                                        onChangeInputHandler(rule, 'rule')
                                    },
                                    'startTime',
                                )}
                            </Form.Item>
                            <Form.Item
                                label={`${language.end_after} (${language.h_mm})`}
                            >
                                {FromSecondsInputNumber(
                                    // @ts-ignore
                                    paddingRule.rule?.timeConditions[0]?.endTime || 0,
                                    (value: any) => {
                                        const rule = cloneDeep(paddingRule.rule);
                                        // @ts-ignore
                                        if(!rule.timeConditions[0]) rule.timeConditions[0] = {};
                                        // @ts-ignore
                                        rule.timeConditions[0].endTime = value;
                                        onChangeInputHandler(rule, 'rule')
                                    },
                                    'endTime',
                                )}
                            </Form.Item>
                        </div>
                    )}

                    <h3>{language.padding}</h3>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <Form.Item
                            label={`${language.before} (${language.h_mm})`}
                        >
                            {FromSecondsInputNumber(
                                // @ts-ignore
                                paddingRule.rule?.paddingBefore,
                                (value: any) => {
                                    onChangeInputHandler({
                                        ...paddingRule.rule,
                                        paddingBefore: value,
                                    }, 'rule')
                                },
                                'paddingBefore',
                            )}
                        </Form.Item>
                        <Form.Item
                            label={`${language.after} (${language.h_mm})`}
                        >
                            {FromSecondsInputNumber(
                                // @ts-ignore
                                paddingRule.rule?.paddingAfter,
                                (value: any) => {
                                    onChangeInputHandler({
                                        ...paddingRule.rule,
                                        paddingAfter: value,
                                    }, 'rule')
                                },
                                'paddingAfter',
                            )}
                        </Form.Item>
                    </div>
                </div>
            )}

            {paddingRule.paddingType === EPaddingRuleType.TRAVEL_TIME && (
                <TimeTravelPaddingMatrix rule={rule}/>
            )}
        </>
    );
}

export default PaddingRulesFormComponent;