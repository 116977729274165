import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

// REDUX
import {
  fetchReservationTemplates,
  reservationTemplatesSelector,
} from '../../slices/reservationTemplate.slice';

// i18N
import intl from '../../i18n/intl';

// STYLES
import './index.scss';
import {
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  List,
  Button,
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

// TYPES
import {
  TTimeRule,
  EWeekdays,
  EWeekdaySelection,
  EReservationPeriodType,
  WeekdaySelection,
  ERelativeTimeTypes,
} from '../../types/timerule.type';
import { TReservationTemplate } from '../../types/reservation.type';

// UTILS
import { getNameOnReservationTemplates } from '../../utils/getNameOn';

// COMPONENTS
import TimeRulesTable from './TimeRulesTable';
import {
  FromSecondsInputNumber,
  fromSecondsToHours,
} from '../FromSeconds/FromSecondsInputNumber';
import { TEItem } from '../TEToolTip';

type Props = {
  timeRule: TTimeRule;
  onChange: Function;
  limitations?: any;
};

type PropsDetails = {
  timeRule: TTimeRule;
  onChange: Function;
  onChangeAbsoluteTimes: any;
  onChangeStandardStep: any;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const TimeRulesGeneralSettings = ({
  timeRule,
  onChange,
  limitations,
}: Props) => {
  // EVENT HANDLERS
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Form.Item
          tooltip={`(Maximum size of ${limitations.name} bytes)`}
          label={language.name}
        >
          <Input
            size='small'
            defaultValue={timeRule.name}
            value={timeRule.name}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'name', limitations.name)
            }
          />
        </Form.Item>
        <p className='form-item-text' style={{ marginBottom: '1em' }}>
          {language.id}: {timeRule.id}
        </p>
        <Form.Item
          tooltip={`(Maximum size of ${limitations.description} bytes)`}
          label={language.description}
        >
          <Input.TextArea
            size='small'
            rows={5}
            defaultValue={timeRule.description}
            value={timeRule.description}
            onChange={(value: any) =>
              onChangeInputHandler(
                value,
                'description',
                limitations.description,
              )
            }
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const TimeRulesDetails = ({
  timeRule,
  onChange,
  onChangeAbsoluteTimes,
  onChangeStandardStep,
}: PropsDetails) => {
  // EVENT HANDLERS
  const onChangeCheckboxHandler = (event: any, property: string) => {
    onChange(property, event.target.checked);
  };

  const onChangeSelectHandler = (event: any, property: string) => {
    onChange(property, event);
  };

  // Changing value on standard step will affect other properties as well, this function is handling those changes
  const onChangeStandardStepHandler = (event: any) => {
    const newValue = parseInt(event);
    if (
      timeRule.minimumStep === timeRule.standardStep &&
      timeRule.beginStep === timeRule.standardStep &&
      timeRule.importStep === timeRule.standardStep
    ) {
      onChangeStandardStep(newValue, [
        'standardStep',
        'minimumStep',
        'beginStep',
        'importStep',
      ]);
    } else if (
      timeRule.minimumStep === timeRule.standardStep &&
      timeRule.importStep === timeRule.standardStep
    ) {
      onChangeStandardStep(newValue, [
        'standardStep',
        'minimumStep',
        'importStep',
      ]);
    } else if (timeRule.minimumStep === timeRule.standardStep) {
      onChangeStandardStep(newValue, ['standardStep', 'minimumStep']);
    } else if (timeRule.beginStep === timeRule.standardStep) {
      onChangeStandardStep(newValue, ['standardStep', 'beginStep']);
    } else {
      onChange('standardStep', newValue);
    }
  };

  const onChangeDateHandler = (value: any, property: string) => {
    onChange(property, value);
  };

  // STANDARD WEEKDAYS
  const onChangeWeekdayCheckboxHandler = (
    event: any,
    property: string,
    value: any,
  ) => {
    let newWeekdaysChoosen;
    if (event.target.checked) {
      newWeekdaysChoosen = timeRule.weekdays.concat([value]);
    } else {
      newWeekdaysChoosen = timeRule.weekdays.filter(
        (weekday: any) => !_.isEqual(weekday, value),
      );
    }
    onChange(property, newWeekdaysChoosen);
  };

  // Show the already choosen weekdays from the API
  const isWeekdaySelected = (weekday: any, weekdays: any) => {
    return _.some(timeRule.weekdays, (value: any) => {
      return _.isEqual(weekday, value);
    });
  };

  // States setting enabled / disabled inputs
  const [minimumStepEnabled, setMinimumStepEnabled] = useState<boolean>(
    timeRule.minimumStep !== 0 &&
      timeRule.minimumStep !== timeRule.standardStep,
  );

  const [beginStepEnabled, setBeginStepEnabled] = useState<boolean>(
    timeRule.beginStep !== 0 && timeRule.beginStep !== timeRule.standardStep,
  );

  const [importStepEnabled, setImportStepEnabled] = useState<boolean>(
    timeRule.importStep !== 0 && timeRule.importStep !== timeRule.minimumStep,
  );
  const getHoursAndMinutes = (value: number) => {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value % 3600) / 60);
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
  };

  // TIMESLOTS WEEKDAY SELECTION
  // Sets index for the selected weekday selection
  const [selectedIndex, setSelectedIndex] = useState<any>();
  const onChoosenWeekdaySelection = (selectedValue: any) => {
    if (timeRule.timeSlotSelection) {
      setSelectedIndex(selectedValue);
    }
  };

  let choosenWeekdays = timeRule.timeSlotSelection
    ? timeRule.timeSlotSelection[selectedIndex]?.weekdays
    : [];

  // Shows the already choosen weekdays for the choosen weekday selection
  const isWeekdaySelectionSelected = (weekdaySelection: any, weekdays: any) => {
    return _.some(choosenWeekdays, (value: any) => {
      return _.isEqual(weekdaySelection, value);
    });
  };

  // Adds new weekday selection
  const onAddWeekdaySelection = () => {
    if (timeRule.timeSlotSelection) {
      const updatedWeekdaySelection = [...timeRule.timeSlotSelection];
      const newSelection = WeekdaySelection.createEmpty();
      updatedWeekdaySelection.push(newSelection);
      onChange('timeSlotSelection', updatedWeekdaySelection);
      setSelectedIndex(timeRule.timeSlotSelection.length);
    } else {
      const updatedWeekdaySelection = [];
      const newSelection = WeekdaySelection.createEmpty();
      updatedWeekdaySelection.push(newSelection);
      onChange('timeSlotSelection', updatedWeekdaySelection);
    }
  };

  // Deletes choosen time slot selection
  const onDeleteWeekdaySelection = () => {
    onChange(
      'timeSlotSelection',
      timeRule.timeSlotSelection?.filter(
        (timeSlotSelection: any, index: number) => index !== selectedIndex,
      ),
    );
  };

  // Changes of weekdays in the weekday selection
  const onChangeWeekdaySelectionWeekdays = (
    event: any,
    property: string,
    value: any,
  ) => {
    let newWeekdaysSelectionWeekdays;
    if (event.target.checked) {
      if (choosenWeekdays === undefined) {
        choosenWeekdays = [];
      }
      newWeekdaysSelectionWeekdays = choosenWeekdays.concat(value);
    } else {
      newWeekdaysSelectionWeekdays = choosenWeekdays.filter(
        (weekdaySelection: any) => !_.isEqual(weekdaySelection, value),
      );
    }
    if (timeRule.timeSlotSelection) {
      const updatedSelection = Object.assign(
        {},
        timeRule.timeSlotSelection[selectedIndex],
        {
          weekdays: newWeekdaysSelectionWeekdays,
        },
      );
      let index;
      if (selectedIndex === undefined) {
        index = timeRule.timeSlotSelection?.indexOf(
          timeRule.timeSlotSelection[0],
        );
        setSelectedIndex(index);
      } else {
        index = timeRule.timeSlotSelection?.indexOf(
          timeRule.timeSlotSelection[selectedIndex],
        );
      }
      const updatedWeekdaySelection = [...timeRule.timeSlotSelection];
      updatedWeekdaySelection.splice(index, 1, updatedSelection);
      onChange('timeSlotSelection', updatedWeekdaySelection);
    }
  };

  // Handler to handle change time slots in the table
  const onChangeTimeSlots = (newTimeSlots: any) => {
    if (timeRule.timeSlotSelection) {
      const updatedSelection = Object.assign(
        {},
        timeRule.timeSlotSelection[selectedIndex],
        {
          times: newTimeSlots,
        },
      );
      if (timeRule.timeSlotSelection[selectedIndex]) {
        const index: any = timeRule.timeSlotSelection?.indexOf(
          timeRule.timeSlotSelection[selectedIndex],
        );
        const updatedWeekdaySelection = [...timeRule.timeSlotSelection];
        updatedWeekdaySelection.splice(index, 1, updatedSelection);
        onChange('timeSlotSelection', updatedWeekdaySelection);
      }
    }
  };

  // Relative time: Sets type
  const setRelativeTimeType = (relativeTime: number) => {
    if (relativeTime % 86400 === 0) {
      return 'days';
    } else if (relativeTime % 3600 === 0) {
      return 'hours';
    } else {
      return 'minutes';
    }
  };
  // Relative time: Sets time
  const setRelativeTime = (relativeTime: number) => {
    if (relativeTime % 86400 === 0) {
      return 86400;
    } else if (relativeTime % 3600 === 0) {
      return 3600;
    } else {
      return 60;
    }
  };

  // States for when changing relative time type
  const [, setChoosenRelativeStartTimeType] = useState();
  const [, setChoosenRelativeEndTimeType] = useState();
  const [, setChoosenRelativeCancellationStartTimeType] = useState();
  const [, setChoosenRelativeCancellationEndTimeType] = useState();

  // Function for when wanting to add absolute start & end time to the selected time rule
  const changeAbsoluteTime = (
    property1: string,
    property2: string,
    isChecked: boolean,
  ) => {
    const updatedAbsoluteStartTime =
      isChecked === true ? parseInt((Date.now() / 1000).toFixed()) : 0;
    const updatedAbsoluteEndTime =
      isChecked === true
        ? parseInt((Date.now() / 1000).toFixed()) + 31556926
        : 0;
    onChangeAbsoluteTimes(
      updatedAbsoluteStartTime,
      updatedAbsoluteEndTime,
      property1,
      property2,
    );
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.details} key='2'>
        <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 16 }}>
          <Collapse.Panel header={language.standard} key='2'>
            {timeRule.timeSlotSelection === undefined ? (
              <>
                <div className='row-direction--wrapper'>
                  <Form.Item>
                    {Object.entries(EWeekdays).map(([key, value]) => (
                      <Checkbox
                        key={key}
                        onChange={(checked: any) =>
                          onChangeWeekdayCheckboxHandler(
                            checked,
                            'weekdays',
                            value,
                          )
                        }
                        checked={isWeekdaySelected(value, timeRule.weekdays)}
                      >
                        {language[value]}
                      </Checkbox>
                    ))}
                  </Form.Item>
                </div>
                <Form.Item
                  label={`${language.time} (${language.hours})`}
                  className='row-direction--wrapper'
                >
                  {fromSecondsToHours(
                    timeRule.clockTimeStart,
                    onChangeSelectHandler,
                    'clockTimeStart',
                  )}
                  <p className='semicolon-divider'> - </p>
                  {fromSecondsToHours(
                    timeRule.clockTimeEnd,
                    onChangeSelectHandler,
                    'clockTimeEnd',
                  )}
                </Form.Item>
                <Form.Item
                  label={`${language.standard_step} (${language.h_mm})`}
                >
                  {FromSecondsInputNumber(
                    timeRule.standardStep,
                    onChangeStandardStepHandler,
                    'standardStep',
                  )}
                </Form.Item>
                <Form.Item
                  label={`${language.minimum_step} (${language.h_mm})`}
                  className='row-direction--wrapper'
                >
                  <Checkbox
                    checked={minimumStepEnabled}
                    onChange={(event: any) =>
                      setMinimumStepEnabled(event.target.checked)
                    }
                  />
                  {FromSecondsInputNumber(
                    timeRule.minimumStep,
                    onChangeSelectHandler,
                    'minimumStep',
                    'limitations',
                    !minimumStepEnabled,
                  )}
                </Form.Item>
                <Form.Item
                  label={`${language.begin_step} (${language.h_mm})`}
                  className='row-direction--wrapper'
                >
                  <Checkbox
                    checked={beginStepEnabled}
                    onChange={(event: any) =>
                      setBeginStepEnabled(event.target.checked)
                    }
                  />

                  {FromSecondsInputNumber(
                    timeRule.beginStep,
                    onChangeSelectHandler,
                    'beginStep',
                    'limitations',
                    !beginStepEnabled,
                  )}
                </Form.Item>
                <Form.Item
                  label={`${language.import_step} (${language.h_mm})`}
                  className='row-direction--wrapper'
                >
                  <Checkbox
                    checked={importStepEnabled}
                    onChange={(event: any) =>
                      setImportStepEnabled(event.target.checked)
                    }
                  />
                  <Select
                    size='small'
                    value={timeRule.importStep}
                    disabled={!importStepEnabled}
                    onChange={(value: number) =>
                      onChangeSelectHandler(value, 'importStep')
                    }
                  >
                    <Select.Option value={60}>
                      {getHoursAndMinutes(60)}
                    </Select.Option>
                    <Select.Option value={timeRule.minimumStep}>
                      {getHoursAndMinutes(timeRule.minimumStep)}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label={`${language.minimum_length} (${language.h_mm})`}
                  className='row-direction--wrapper'
                >
                  {FromSecondsInputNumber(
                    timeRule.minimumLength,
                    onChangeSelectHandler,
                    'minimumLength',
                  )}
                </Form.Item>
                <Form.Item
                  label={`${language.maximum_length} (${language.h_mm})`}
                  className='row-direction--wrapper'
                >
                  {FromSecondsInputNumber(
                    timeRule.maximumLength,
                    onChangeSelectHandler,
                    'maximumLength',
                  )}
                </Form.Item>
                <Form.Item
                  label={`${language.default_length} (${language.h_mm})`}
                  className='row-direction--wrapper'
                >
                  {FromSecondsInputNumber(
                    timeRule.defaultLength,
                    onChangeSelectHandler,
                    'defaultLength',
                  )}
                </Form.Item>
                <Form.Item
                  label={`${language.begin_offset} (${language.minutes})`}
                >
                  <InputNumber
                    size='small'
                    min={0}
                    max={120}
                    step={5}
                    onChange={(value: any) =>
                      onChangeSelectHandler(value, 'beginOffset')
                    }
                    value={timeRule.beginOffset}
                    disabled={timeRule.beginOffset === 0}
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <p
                  className='small-heading-text'
                  style={{ fontStyle: 'italic', marginBottom: 0 }}
                >
                  The rule uses time slots.
                </p>
                <p
                  className='small-heading-text'
                  style={{ fontStyle: 'italic' }}
                >
                  Remove all time slots to use standard rules.
                </p>
              </>
            )}
          </Collapse.Panel>
          <Collapse.Panel header={language.time_slots} key='3'>
            <p className='small-heading-text'>{language.weekday_selection}</p>
            <div className='weekday-selection-choices--wrapper'>
              {timeRule.timeSlotSelection && (
                <Select
                  size='small'
                  onChange={(value: any) => onChoosenWeekdaySelection(value)}
                  style={{ width: 400, marginRight: 10 }}
                  placeholder='Please choose a weekday selection to see time slots'
                >
                  {timeRule.timeSlotSelection.map(
                    (timeSlotSelection: any, index: number) => (
                      <Select.Option
                        key={timeSlotSelection.weekdays.join(', ')}
                        value={index}
                      >
                        {timeSlotSelection.weekdays.join(', ')}
                      </Select.Option>
                    ),
                  )}
                </Select>
              )}
              {!timeRule.timeSlotSelection && (
                <Select
                  size='small'
                  defaultValue={EWeekdaySelection.noWeekDaySelection}
                  style={{ width: 400, marginRight: 10 }}
                >
                  {Object.entries(EWeekdaySelection).map(([key, value]) => (
                    <Select.Option value={value} key={key}>
                      {language[value]}
                    </Select.Option>
                  ))}
                </Select>
              )}
              <Button
                size='small'
                onClick={onAddWeekdaySelection}
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
              >
                {language.add_weekday_selection}
              </Button>
              <Button
                size='small'
                danger
                onClick={onDeleteWeekdaySelection}
                icon={<DeleteOutlined />}
              >
                {language.delete_weekday_selection}
              </Button>
            </div>
            <div className='row-direction--wrapper'>
              <Form.Item>
                {Object.entries(EWeekdays).map(([key, value]) => (
                  <Checkbox
                    key={key}
                    onChange={(checked: any) =>
                      onChangeWeekdaySelectionWeekdays(
                        checked,
                        'weekdays',
                        value,
                      )
                    }
                    checked={isWeekdaySelectionSelected(
                      value,
                      timeRule.timeSlotSelection?.map(
                        (timeSlot: any) => timeSlot.weekdays,
                      ),
                    )}
                  >
                    {language[value]}
                  </Checkbox>
                ))}
              </Form.Item>
            </div>
            {timeRule.timeSlotSelection && (
              <TimeRulesTable
                timeSlots={timeRule.timeSlotSelection[selectedIndex]?.times}
                onChangeTimeSlots={onChangeTimeSlots}
              />
            )}
          </Collapse.Panel>
        </Collapse>
        <p className='small-heading-text'>{language.date}</p>
        <div className='row-direction--wrapper'>
          <Form.Item>
            <Checkbox
              onChange={(event: any) =>
                changeAbsoluteTime(
                  'absoluteStartTime',
                  'absoluteEndTime',
                  event.target.checked,
                )
              }
              checked={!!timeRule.absoluteStartTime}
            />
          </Form.Item>
          <Form.Item>
            <DatePicker
              size='small'
              format='YYYY-MM-DD'
              value={
                timeRule.absoluteStartTime
                  ? moment.unix(timeRule.absoluteStartTime).utc()
                  : null
              }
              disabled={!timeRule.absoluteStartTime}
              onChange={(value: any) =>
                onChangeDateHandler(
                  parseInt(value.format('X')),
                  'absoluteStartTime',
                )
              }
            />
            <p
              className='small-heading-text'
              style={{ marginLeft: 4, marginRight: 4, marginBottom: 0 }}
            >
              -
            </p>
            <DatePicker
              size='small'
              value={
                timeRule.absoluteEndTime
                  ? moment.unix(timeRule.absoluteEndTime).utc()
                  : null
              }
              disabled={!timeRule.absoluteEndTime}
              onChange={(value: any) =>
                onChangeDateHandler(
                  parseInt(value.format('X')),
                  'absoluteEndTime',
                )
              }
            />
          </Form.Item>
        </div>
        <p className='small-heading-text'>{language.relative_start_time}</p>
        <div className='row-direction--wrapper'>
          <Form.Item>
            <InputNumber
              style={{ width: 'auto' }}
              size='small'
              min={-100000}
              max={100000}
              className='inputnumber-margin'
              onChange={(value: any) =>
                onChangeSelectHandler(
                  value * setRelativeTime(value),
                  'relativeStartTime',
                )
              }
              value={
                timeRule.relativeStartTime /
                setRelativeTime(timeRule.relativeStartTime)
              }
            />
            <Select
              size='small'
              value={language[setRelativeTimeType(timeRule.relativeStartTime)]}
              onChange={(value: any) => {
                setChoosenRelativeStartTimeType(value);
                onChangeSelectHandler(
                  value === 'minutes'
                    ? -3540
                    : value === 'hours'
                    ? -82800
                    : -86400,
                  'relativeStartTime',
                );
              }}
            >
              {Object.entries(ERelativeTimeTypes).map(([key, value]) => (
                <Select.Option value={value} key={key}>
                  {language[value]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <p className='small-heading-text'>{language.relative_end_time}</p>
        <div className='row-direction--wrapper'>
          <Form.Item>
            <InputNumber
              style={{ width: 'auto' }}
              size='small'
              min={-100000}
              max={100000}
              className='inputnumber-margin'
              onChange={(value: any) =>
                onChangeSelectHandler(
                  value * setRelativeTime(value),
                  'relativeEndTime',
                )
              }
              value={
                timeRule.relativeEndTime /
                setRelativeTime(timeRule.relativeEndTime)
              }
            />
            <Select
              size='small'
              value={language[setRelativeTimeType(timeRule.relativeEndTime)]}
              onChange={(value: any) => {
                setChoosenRelativeEndTimeType(value);
                onChangeSelectHandler(
                  value === 'minutes'
                    ? -3540
                    : value === 'hours'
                    ? -82800
                    : -86400,
                  'relativeEndTime',
                );
              }}
            >
              {Object.entries(ERelativeTimeTypes).map(([key, value]) => (
                <Select.Option value={value} key={key}>
                  {language[value]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'useStartTimeToOpenNextDay')
            }
            checked={timeRule.useStartTimeToOpenNextDay}
          >
            {language.use_start_time_to_open_next_day}
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={(event: any) => {
              onChangeCheckboxHandler(event, 'useSeparateCancellationRules');
            }}
            checked={timeRule.useSeparateCancellationRules}
          >
            {language.use_separate_cancellation_rules}
          </Checkbox>
        </Form.Item>
        {timeRule.useSeparateCancellationRules === true && (
          <>
            <p className='small-heading-text'>{language.date}</p>
            <div className='row-direction--wrapper'>
              <Form.Item>
                <Checkbox
                  onChange={(event: any) =>
                    changeAbsoluteTime(
                      'absoluteCancellationStartTime',
                      'absoluteCancellationEndTime',
                      event.target.checked,
                    )
                  }
                  checked={!!timeRule.absoluteCancellationStartTime}
                />
              </Form.Item>
              <Form.Item>
                <DatePicker
                  size='small'
                  value={
                    timeRule.absoluteCancellationStartTime
                      ? moment
                          .unix(timeRule.absoluteCancellationStartTime)
                          .utc()
                      : null
                  }
                  disabled={!timeRule.absoluteCancellationStartTime}
                  onChange={(value: any) =>
                    onChangeDateHandler(value, 'absoluteCancellationStartTime')
                  }
                />
                <p
                  className='small-heading-text'
                  style={{ marginLeft: 4, marginRight: 4, marginBottom: 0 }}
                >
                  -
                </p>
                <DatePicker
                  size='small'
                  value={
                    timeRule.absoluteCancellationEndTime
                      ? moment.unix(timeRule.absoluteCancellationEndTime).utc()
                      : null
                  }
                  disabled={!timeRule.absoluteCancellationEndTime}
                  onChange={(value: any) =>
                    onChangeDateHandler(value, 'absoluteCancellationEndTime')
                  }
                />
              </Form.Item>
            </div>
            <p className='small-heading-text'>{language.relative_start_time}</p>
            <div className='row-direction--wrapper'>
              <Form.Item>
                <InputNumber
                  style={{ width: 'auto' }}
                  size='small'
                  min={-100000}
                  max={100000}
                  className='inputnumber-margin'
                  onChange={(value: any) =>
                    onChangeSelectHandler(
                      value * setRelativeTime(value),
                      'relativeCancellationStartTime',
                    )
                  }
                  value={
                    timeRule.relativeCancellationStartTime /
                    setRelativeTime(timeRule.relativeCancellationStartTime)
                  }
                />
                <Select
                  size='small'
                  value={
                    language[
                      setRelativeTimeType(
                        timeRule.relativeCancellationStartTime,
                      )
                    ]
                  }
                  onChange={(value: any) => {
                    setChoosenRelativeCancellationStartTimeType(value);
                    onChangeSelectHandler(
                      value === 'minutes'
                        ? -3540
                        : value === 'hours'
                        ? -82800
                        : -86400,
                      'relativeCancellationStartTime',
                    );
                  }}
                >
                  {Object.entries(ERelativeTimeTypes).map(([key, value]) => (
                    <Select.Option value={value} key={key}>
                      {language[value]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <p className='small-heading-text'>{language.relative_end_time}:</p>
            <div className='row-direction--wrapper'>
              <Form.Item>
                <InputNumber
                  style={{ width: 'auto' }}
                  size='small'
                  min={-100000}
                  max={100000}
                  className='inputnumber-margin'
                  onChange={(value: any) =>
                    onChangeSelectHandler(
                      value * setRelativeTime(value),
                      'relativeCancellationEndTime',
                    )
                  }
                  value={
                    timeRule.relativeCancellationEndTime /
                    setRelativeTime(timeRule.relativeCancellationEndTime)
                  }
                />
                <Select
                  size='small'
                  value={
                    language[
                      setRelativeTimeType(timeRule.relativeCancellationEndTime)
                    ]
                  }
                  onChange={(value: any) => {
                    setChoosenRelativeCancellationEndTimeType(value);
                    onChangeSelectHandler(
                      value === 'minutes'
                        ? -3540
                        : value === 'hours'
                        ? -82800
                        : -86400,
                      'relativeCancellationEndTime',
                    );
                  }}
                >
                  {Object.entries(ERelativeTimeTypes).map(([key, value]) => (
                    <Select.Option value={value} key={key}>
                      {language[value]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </>
        )}
        <p className='small-heading-text' style={{ fontWeight: 700 }}>
          {language.reservation_periods}:
        </p>
        <Form.Item label={`${language.reservation_period} #1`}>
          <Select
            size='small'
            value={language[timeRule.reservationPeriod1]}
            onChange={(value: EReservationPeriodType) =>
              onChangeSelectHandler(value, 'reservationPeriod1')
            }
          >
            {Object.entries(EReservationPeriodType).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={`${language.reservation_length} #1`}
          className='row-direction--wrapper'
        >
          {FromSecondsInputNumber(
            timeRule.reservationLength1,
            onChangeSelectHandler,
            'reservationLength1',
          )}
        </Form.Item>
        <Form.Item label={`${language.reservation_count} #1`}>
          <InputNumber
            size='small'
            min={0}
            max={10000}
            value={timeRule.reservationCount1}
            onChange={(value: any) =>
              onChangeSelectHandler(value, 'reservationCount1')
            }
          />
        </Form.Item>
        <Form.Item label={`${language.reservation_period} #2`}>
          <Select
            size='small'
            value={language[timeRule.reservationPeriod2]}
            onChange={(value: EReservationPeriodType) =>
              onChangeSelectHandler(value, 'reservationPeriod2')
            }
          >
            {Object.entries(EReservationPeriodType).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={`${language.reservation_length} #2`}
          className='row-direction--wrapper'
        >
          {FromSecondsInputNumber(
            timeRule.reservationLength2,
            onChangeSelectHandler,
            'reservationLength2',
          )}
        </Form.Item>
        <Form.Item label={`${language.reservation_count} #2`}>
          <InputNumber
            size='small'
            min={0}
            max={10000}
            value={timeRule.reservationCount2}
            onChange={(value: any) =>
              onChangeSelectHandler(value, 'reservationCount2')
            }
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(
                checked,
                'useModifiedByWhenCalculatingUserQuota',
              )
            }
            checked={timeRule.useModifiedByWhenCalculatingUserQuota}
            disabled={timeRule.reservationLength1 === 0}
          >
            {language.use_modified_by_when_calculating}
          </Checkbox>
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

type PropsTemplates = {
  templates: Array<any>;
};

export const TimeRulesTemplates = ({ templates }: PropsTemplates) => {
  // Here we make an API-request for fetching all available Reservation templates
  const dispatch = useDispatch();
  const reservationTemplates: TReservationTemplate[] = useSelector(
    reservationTemplatesSelector,
  );

  useEffect(() => {
    dispatch(
      fetchReservationTemplates(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
        'all',
      ),
    );
  }, [dispatch]);

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.templates} key='3'>
        <p className='small-heading-text'>{language.templates}</p>
        <List
          size='small'
          bordered
          dataSource={getNameOnReservationTemplates(
            reservationTemplates,
            templates,
          )}
          renderItem={(item: any) => <List.Item key={item}>{item}</List.Item>}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
