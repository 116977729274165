import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { fetchTypes, typesSelector } from '../../slices/type.slice';

// TYPES
import { TType } from '../../types/type.type';

// STYLES
import './index.scss';
import { Form, Collapse, Select, Radio, Checkbox, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

/* ------------------------------------------------------------------------- */

const onBlur = () => {};
const onFocus = () => {};

export const ColorsChooseType = () => {
  const dispatch = useDispatch();
  const types: TType[] = useSelector(typesSelector);

  useEffect(() => {
    dispatch(fetchTypes());
  }, [dispatch]);

  const choices = [
    { value: 'Only objects with assigned color' },
    {
      value:
        'Assigned color, plus automatically assigned colors in TE Viewer for objects not having an assigned color',
    },
  ];

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header='Choose type' key='1'>
        <Form.Item>
          <Select
            size='small'
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {types.map((type: any) => (
              <Select.Option key={type.value} value={type.value}>
                {type.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className='color-checkboxes--wrapper'>
          <Checkbox>Color this type</Checkbox>
          <Radio.Group className='color-radio-group' size='small'>
            {choices.map((choice: any) => (
              <Radio
                key={choice.value}
                value={choice.value}
                style={{ fontSize: 12 }}
              >
                {choice.value}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ------------------------------------------------------------------------- */

export const ColorsFilter = () => {
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header='Color filter' key='2'>
        <Button type='text' icon={<CloseOutlined />}>
          Remove all filters
        </Button>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ------------------------------------------------------------------------- */

export const ColorsCharacteristics = () => {
  enum EVirtualChoices {
    all = 'All',
    some = 'Some',
  }

  enum EAbstractChoices {
    all = 'All',
    some = 'Some',
  }

  enum EStatus {
    book = 'Book',
    unbooked = 'Unbooked',
  }

  enum EActive {
    Active = 'Active',
    NotActive = 'Not active',
  }

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header='Characteristics' key='3'>
        <Form.Item label='Virtual:'>
          <Select
            size='small'
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.entries(EVirtualChoices).map(([key, value]) => (
              <Select.Option value={value} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Abstract:'>
          <Select
            size='small'
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.entries(EAbstractChoices).map(([key, value]) => (
              <Select.Option value={key} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Status:'>
          <Select
            size='small'
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.entries(EStatus).map(([key, value]) => (
              <Select.Option value={key} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Active:'>
          <Select
            size='small'
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.entries(EActive).map(([key, value]) => (
              <Select.Option value={key} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};
