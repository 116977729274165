import {
  TTEUser,
  ENUMS,
  TOrganizationAndRoles,
} from '@timeedit/types/lib';
const { EUserVisibility, EUserAllowJavaClientAccess } = ENUMS;

export type TScope = {
  id: string;
  name: string;
  description: string;
};

export type TAppPermission = {
  id: string;
  name: string;
  description: string;
};

export class OrganizationsAndRoles {
  static createEmpty = (): TOrganizationAndRoles => ({
    organization: '',
    role: '',
  });
}

export class TEUser {
  static createEmpty = (customerSignature: string): TTEUser => ({
    active: true,
    id: null,
    organizationId: customerSignature, // Set to customerSignature and auth-be
    // need to translate it to the right organizationId when sending a response
    // back on createUser API POST-request
    username: 'new',
    email: '',
    firstName: '',
    lastName: '',
    language: '',
    dateFormat: '',
    timezone: '',
    visibility: EUserVisibility.standard,
    personalData: true,
    description: '',
    standardOrganization: '',
    organizationsAndRoles: [],
    scopes: [],
    appPermissions: [],
    userObjects: [],
    allowJavaClientAccess: EUserAllowJavaClientAccess.standard,
    authConfigId: '',
    requirePasswordOnFirstSignIn: false,
    password: null,
    logins: [],
    created: Date.now(),
    createdBy: '',
    history: [],
  });
}
