// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Button, Table, Space, Popconfirm, Select } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

// COMPONENTS
import { TEToolTip } from '../../TEToolTip';
import { TScope } from '../../../types/user.type';

const language: any = intl.messages;

type Props = {
  dataSource: string[];
  onChange: any;
  property: string;
};
/* ------------------------------------------------------------------------- */

const mockDataAvailableScopes: TScope[] = [
  { description: '', name: 'Super user in TE Admin', id: 'TE_ADMIN::super' },
  {
    description: 'Admin user in TE Admin',
    name: 'Admin user in TE Admin',
    id: 'TE_ADMIN::admin',
  },
  { description: '', name: 'User in TE Admin', id: 'TE_ADMIN::user' },
  {
    description: 'Super user in TE CORE',
    name: 'Super user in TE Core',
    id: 'TE_CORE::super',
  },
  { description: '', name: 'Admin user in TE Core', id: 'TE_CORE::admin' },
  { description: '', name: 'User in TE Core', id: 'TE_CORE::user' },
  { description: '', name: 'Super user in TE Plan', id: 'TE_PLAN::super' },
  { description: '', name: 'Admin user in TE Plan', id: 'TE_PLAN::admin' },
  { description: '', name: 'User in TE Plan', id: 'TE_PLAN::user' },
  { description: '', name: 'Super user in TE Viewer', id: 'TE_VIEWER::super' },
  { description: '', name: 'Admin user in TE Viewer', id: 'TE_VIEWER::admin' },
  { description: '', name: 'User in TE Viewer', id: 'TE_VIEWER::user' },
];

/* ----------------------------------------------------------------------------------------------- */

export const ScopesTable = ({ dataSource, onChange, property }: Props) => {
  // EVENT HANDLERS
  const onChangeHandler = (data: any, value: any) => {
    const index = dataSource.indexOf(data.data);
    let updatedData = [...data.data];
    updatedData = value;
    const newData: any = [...dataSource];
    newData.splice(index, 1, updatedData);
    onChange(property, newData);
  };

  const onAddRow = () => {
    const newDataSource = [...dataSource];
    newDataSource.push('');
    onChange(property, newDataSource);
  };

  const onDeleteRow = (dataKey: any) => {
    onChange(
      property,
      dataSource.filter((data: any) => data !== dataKey),
    );
  };

  // Columns
  const columns = [
    {
      title: (
        <TEToolTip helpText=''>
          <span className='small-heading-text'>{language.scopes}</span>
        </TEToolTip>
      ),
      dataIndex: 'scopes',
      key: 'scopes',
      width: 260,
      render: (record: any, scopes: any) => (
        <Select
          size='small'
          value={scopes.data}
          onChange={(event: any) => onChangeHandler(scopes, event)}
        >
          {/* @todo: Change layout of list when we have the right data. Make a API request to get the list of all available scopes instead of using mockData */}
          {mockDataAvailableScopes.map((scope: TScope) => (
            <Select.Option label={scope.name} key={scope.id} value={scope.id}>
              {scope.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },

    {
      title: <span className='small-heading-text'>{language.action}</span>,
      key: 'action',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={language.sure_to_delete}
            onConfirm={() => onDeleteRow(record.data)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '1200px' }}>
      <Button
        type='default'
        size='small'
        icon={<PlusOutlined />}
        onClick={onAddRow}
        style={{ width: '120px', marginTop: '8px' }}
      >
        {language.add_row}
      </Button>
      <Table
        size='small'
        dataSource={dataSource?.map((data: string, index: number) => ({
          key: index,
          appPermission: data[0],
          data,
        }))}
        columns={columns}
        style={{ marginBottom: '16px', marginTop: '16px' }}
      />
    </div>
  );
};
