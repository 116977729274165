import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form } from 'antd';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import { ConvertColorsComponent } from '../../../components/ConvertColorsComponent';

// TYPES

// ACTIONS

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const ChooseColorsPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/object-settings`,
          label: `${language.object_settings}`,
        },
        {
          path: `/object-settings/convert-colors`,
          label: `Convert colors`,
        },
      ]),
    );
  });

  return (
    <>
      <div className='inner-content--wrapper'>
        <div>
          <SectionHeader
            listChoice='Convert colors'
            modifiedDate={2020}
            modifiedBy='admin'
            changeHandler
            discardHandler
            displayProp={false}
            history={false}
          />
          <Form layout='vertical' form={form}>
            <ConvertColorsComponent />
          </Form>
        </div>
      </div>
    </>
  );
};

export default ChooseColorsPage;
