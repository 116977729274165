// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Button, Table, Space, Popconfirm, Select } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

// COMPONENTS
import { TEToolTip } from '../../TEToolTip';
import { TAppPermission } from '../../../types/user.type';

const language: any = intl.messages;

type Props = {
  dataSource: string[];
  onChange: any;
  property: string;
};

/* ------------------------------------------------------------------------- */

const mockDataAvailableAppPermissions: TAppPermission[] = [
  {
    id: 'TE_ADMIN::executeCulling',
    name: 'TE Admin Execute Culling',
    description: '',
  },
  {
    id: 'TE_ADMIN::manageAuthConfigs',
    name: 'TE Admin Manage AuthConfigs',
    description: '',
  },
  {
    id: 'TE_ADMIN::manageOrgs',
    name: 'TE Admin Manage orgs.',
    description: '',
  },
  { id: 'TE_ADMIN::readSuper', name: 'TE Admin Read super', description: '' },
  {
    id: 'TE_ADMIN::manageTimeRues',
    name: 'TE Admin Manage time rules',
    description: '',
  },
  {
    id: 'TE_ADMIN::manageUsers',
    name: 'TE Admin Manage users',
    description: '',
  },
  {
    id: 'TE_CORE::noTimeRules',
    name: 'TE Core No time rules',
    description: 'No time rules in TE Core',
  },
  {
    id: 'TE_CORE::manageView',
    name: 'TE Core Manage view',
    description: 'Manage view in TE Core',
  },
  {
    id: 'TE_CORE::managePeriods',
    name: 'TE Core Manage periods',
    description: '',
  },
  {
    id: 'TE_CORE::teExam',
    name: 'TE Core TE Exam',
    description: 'TE Exam in TE Core',
  },
];

/* ----------------------------------------------------------------------------------------------- */

export const AppPermissionsTable = ({
  dataSource,
  onChange,
  property,
}: Props) => {
  // EVENT HANDLERS
  const onChangeHandler = (data: any, value: any) => {
    const index = dataSource.indexOf(data.data);
    let updatedData = [...data.data];
    updatedData = value;
    const newData: any = [...dataSource];
    newData.splice(index, 1, updatedData);
    onChange(property, newData);
  };

  const onAddRow = () => {
    const newDataSource = [...dataSource];
    newDataSource.push('');
    onChange(property, newDataSource);
  };

  const onDeleteRow = (dataKey: any) => {
    onChange(
      property,
      dataSource.filter((data: any) => data !== dataKey),
    );
  };

  // Columns
  const columns = [
    {
      title: (
        <TEToolTip helpText=''>
          <span className='small-heading-text'>{language.app_permissions}</span>
        </TEToolTip>
      ),
      dataIndex: 'appPermissions',
      key: 'appPermissions',
      width: 260,
      render: (record: any, appPermissions: any) => (
        <Select
          size='small'
          value={appPermissions.data}
          onChange={(event: any) => onChangeHandler(appPermissions, event)}
        >
          {/* @todo: Change layout of list when we have the right data. Make a API request to get the list of all available app permissions instead of using mockData */}
          {mockDataAvailableAppPermissions.map((appPerm: TAppPermission) => (
            <Select.Option
              label={appPerm.name}
              key={appPerm.id}
              value={appPerm.id}
            >
              {appPerm.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },

    {
      title: <span className='small-heading-text'>{language.action}</span>,
      key: 'action',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={language.sure_to_delete}
            onConfirm={() => onDeleteRow(record.data)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '1200px' }}>
      <Button
        type='default'
        size='small'
        icon={<PlusOutlined />}
        onClick={onAddRow}
        style={{ width: '120px', marginTop: '8px' }}
      >
        {language.add_row}
      </Button>
      <Table
        size='small'
        dataSource={dataSource?.map((data: string, index: number) => ({
          key: index,
          appPermission: data[0],
          data,
        }))}
        columns={columns}
        style={{ marginBottom: '16px', marginTop: '16px' }}
      />
    </div>
  );
};
