import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { RelationType, TRelationType } from '../types/relation.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedRelationType: RelationType.createEmpty(),
  allRelationTypes: [],
  limitations: {},
  errorMessage: {},
};

// Slice
const slice = createSlice({
  name: 'relationTypes',
  initialState,
  reducers: {
    fetchRelationTypesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchRelationTypesSuccess: (state: any, { payload }) => {
      if (state.selectedRelationType.id > 0) {
        state.selectedRelationType =
          payload.find(
            (type: any) => type.id === state.selectedRelationType.id,
          ) || RelationType.createEmpty();
      }
      state.allRelationTypes = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsRelationTypesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchRelationTypesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createRelationTypeRequest: (state: any) => {
      state.errorMessage = '';
      isLoadingRequest(state);
    },
    createRelationTypeSuccess: (state: any, { payload }) => {
      state.allRelationTypes.push(payload);
      state.selectedRelationType = payload;
      finishedLoadingSuccess(state);
    },
    createRelationTypeFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
    setCurrentRelationType: (state: any, { payload }) => {
      state.selectedRelationType = payload;
      state.errorMessage = '';
    },
    deleteRelationTypeRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteRelationTypeSuccess: (state: any, payload: any) => {
      state.allRelationTypes = state.allRelationTypes.filter(
        (relationType: TRelationType) => relationType.id !== payload.payload.id,
      );
      state.selectedRelationType = RelationType.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteRelationTypeFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateRelationTypeRequest: (state: any) => {
      state.errorMessage = '';
      isLoadingRequest(state);
    },
    updateRelationTypeSuccess: (state: any, { payload }) => {
      state.errorMessage = '';
      const index = state.allRelationTypes.findIndex(
        (relationType: TRelationType) => relationType.id === payload.id,
      );
      state.allRelationTypes[index] = payload;
      finishedLoadingSuccess(state);
    },
    updateRelationTypeFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const relationTypesLoading = (state: any) => state.relationTypes.loading;
export const relationTypesSelector = (state: any) =>
  state.relationTypes.allRelationTypes as TRelationType[];
export const relationTypeSelectorCreateNew = () => () => {
  return RelationType.createEmpty();
};
export const selectedRelationTypeSelector = (state: any) => {
  return state.relationTypes.selectedRelationType;
};
export const fetchLimitationsRelationTypesSelector = (state: any) =>
  state.relationTypes.limitations;
export const relationTypeErrorMessageSelector = (state: any) =>
  state.relationTypes.errorMessage;

// Actions
export const {
  fetchRelationTypesRequest,
  fetchRelationTypesSuccess,
  fetchLimitationsRelationTypesSuccess,
  fetchRelationTypesFailure,
  createRelationTypeRequest,
  createRelationTypeSuccess,
  createRelationTypeFailure,
  setCurrentRelationType,
  deleteRelationTypeRequest,
  deleteRelationTypeSuccess,
  deleteRelationTypeFailure,
  updateRelationTypeRequest,
  updateRelationTypeSuccess,
  updateRelationTypeFailure,
} = slice.actions;

export const fetchRelationTypes = () => async (dispatch: any) => {
  try {
    dispatch(fetchRelationTypesRequest());
    const relationTypes = await api.get({ endpoint: `/relation-types` });
    dispatch(fetchRelationTypesSuccess(relationTypes));
  } catch (e) {
    dispatch(fetchRelationTypesFailure());
    return console.error(e);
  }
};

export const fetchLimitationsRelationTypes = () => async (dispatch: any) => {
  try {
    dispatch(fetchRelationTypesRequest());
    const limitationsRelationTypes = await api.get({
      endpoint: `/relation-types/limitations`,
    });
    dispatch(fetchLimitationsRelationTypesSuccess(limitationsRelationTypes));
  } catch (e) {
    dispatch(fetchRelationTypesFailure());
    return console.error(e);
  }
};

export const createRelationType =
  (relationType: TRelationType) => async (dispatch: any) => {
    try {
      dispatch(createRelationTypeRequest());
      const { id: _, ...relationTypeBody } = relationType;
      const response = await api.post({
        endpoint: `/relation-types`,
        data: relationTypeBody,
      });
      dispatch(createRelationTypeSuccess(response));
      dispatch(fetchRelationTypes());
    } catch (e: any) {
      dispatch(createRelationTypeFailure(e.response.data));
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const createEmptyRelationType = () => async (dispatch: any) => {
  try {
    dispatch(createRelationTypeRequest());
    const response = RelationType.createEmpty();
    dispatch(createRelationTypeSuccess(response));
  } catch (e: any) {
    dispatch(createRelationTypeFailure(e.response.data));
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedRelationType =
  (relationType: TRelationType) => async (dispatch: any) => {
    dispatch(setCurrentRelationType(relationType));
  };

export const copyRelationType =
  (relationType: TRelationType) => async (dispatch: any) => {
    try {
      dispatch(createRelationTypeRequest());
      const response = {
        ...relationType,
        id: 0,
      };
      dispatch(createRelationTypeSuccess(response));
    } catch (e: any) {
      dispatch(createRelationTypeFailure(e.response.data));
      return console.error(e);
    }
  };

export const deleteRelationType =
  (relationType: TRelationType) => async (dispatch: any) => {
    try {
      dispatch(deleteRelationTypeRequest());
      await api.delete({
        endpoint: `/relation-types/${relationType.id}`,
      });
      dispatch(deleteRelationTypeSuccess(relationType));
    } catch (e) {
      dispatch(deleteRelationTypeFailure());
      return console.error(e);
    }
  };

export const updateRelationType =
  (relationType: TRelationType) => async (dispatch: any) => {
    try {
      dispatch(updateRelationTypeRequest());
      const { ...RelationTypeBody } = relationType;
      const response = await api.patch({
        endpoint: `/relation-types/${relationType.id}`,
        data: { ...RelationTypeBody },
      });
      dispatch(updateRelationTypeSuccess(response));
    } catch (e: any) {
      dispatch(updateRelationTypeFailure(e.response.data));
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
