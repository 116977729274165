import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { fieldsSelector, setSelectedField } from '../../../slices/field.slice';

// TYPES
import { TField } from '../../../types/field.type';

// STYLES
import './index.scss';
import { Form, Select, Button, Input, Modal } from 'antd';
import { PlusOutlined, MinusOutlined, EditFilled } from '@ant-design/icons';

type Props = {
  label: string;
  values: any;
  onChangeFieldValues: any;
  fieldId: number;
  fieldCategories: Array<any>;
  onAddFieldValue: any;
  onDeleteFieldValue: any;
  editable: boolean;
  multiple: boolean;
};

/* ------------------------------------------------------------------------- */

export const FieldCategory = ({
  label,
  values,
  onChangeFieldValues,
  fieldId,
  fieldCategories,
  onAddFieldValue,
  onDeleteFieldValue,
  editable,
  multiple,
}: Props) => {
  const dispatch = useDispatch();
  const fields: TField[] = useSelector(fieldsSelector);

  const onFieldCategoriesChanged = (
    property: string,
    value: any,
    field: any,
  ) => {
    dispatch(
      setSelectedField({
        ...field,
        [property]: value,
      }),
    );
  };

  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const onAddCategoryModalOpen = () => {
    setAddCategoryModal(true);
  };
  const onAddCategoryModalClose = () => {
    setAddCategoryModal(false);
  };

  const [newCategoryName, setNewCategoryName] = useState<any>();

  const onAddCategory = (fieldId: number, categories: any[], value: string) => {
    const field: any = fields.find((field: TField) => field.id === fieldId);
    if (categories.length <= 500) {
      const newCategory: any[] = [...categories];
      newCategory.push(value);
      onFieldCategoriesChanged('categories', newCategory, field);
      onChangeFieldValues(value, 0, fieldId);
      setAddCategoryModal(false);
    } else {
      console.info('To many categories in the list already');
    }
  };

  return (
    <div className='multiple-category-fields-wrapper'>
      <Form.Item label={`${label}:`} className='category-field-wrapper'>
        {/* If an empty array of values, still show the possibility to choose category: */}
        {values.length === 0 ? (
          <div>
            <Select
              size='small'
              style={{ width: 300 }}
              onChange={(value: any) => onChangeFieldValues(value, 0, fieldId)}
              /*               // @ts-ignore
              getPopupContainer={() =>
                document.getElementsByClassName('outside-click-provider')[0]
              } */
            >
              {fieldCategories.map((fieldCategory: any) => (
                <Select.Option key={Math.random()} value={fieldCategory}>
                  {fieldCategory}
                </Select.Option>
              ))}
            </Select>
            <Button
              type='text'
              icon={<PlusOutlined />}
              onClick={() => onAddFieldValue(fieldId)}
            ></Button>
          </div>
        ) : multiple === true ? (
          values.map((value: any, index: number) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                key={index}
                size='small'
                value={value}
                style={{ width: 300 }}
                onChange={(value: any) =>
                  onChangeFieldValues(value, index, fieldId)
                }
              >
                {fieldCategories.map((fieldCategory: any) => (
                  <Select.Option key={Math.random()} value={fieldCategory}>
                    {fieldCategory}
                  </Select.Option>
                ))}
              </Select>
              <Button
                type='text'
                icon={<PlusOutlined />}
                onClick={() => onAddFieldValue(fieldId)}
              />
              {index !== 0 && (
                <Button
                  type='text'
                  icon={<MinusOutlined />}
                  onClick={() => onDeleteFieldValue(fieldId, index)}
                ></Button>
              )}
              {editable === true ? (
                <Button
                  size='small'
                  icon={<EditFilled />}
                  onClick={onAddCategoryModalOpen}
                />
              ) : null}
              {addCategoryModal === true ? (
                <Modal
                  title='Add category'
                  okText='Add'
                  onOk={() =>
                    onAddCategory(fieldId, fieldCategories, newCategoryName)
                  }
                  cancelText='Close'
                  width='500px'
                  onCancel={onAddCategoryModalClose}
                  visible={addCategoryModal === true}
                >
                  <Input
                    size='small'
                    onChange={(event: any) =>
                      setNewCategoryName(event.target.value)
                    }
                  />
                </Modal>
              ) : null}
            </div>
          ))
        ) : (
          values.map((value: any, index: number) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                key={index}
                size='small'
                value={value}
                style={{ width: 300 }}
                onChange={(value: any) =>
                  onChangeFieldValues(value, index, fieldId)
                }
              >
                {fieldCategories.map((fieldCategory: any) => (
                  <Select.Option key={Math.random()} value={fieldCategory}>
                    {fieldCategory}
                  </Select.Option>
                ))}
              </Select>
              {editable === true ? (
                <Button
                  size='small'
                  icon={<EditFilled />}
                  onClick={onAddCategoryModalOpen}
                />
              ) : null}
              {addCategoryModal === true ? (
                <Modal
                  title='Add category'
                  okText='Add'
                  onOk={() =>
                    onAddCategory(fieldId, fieldCategories, newCategoryName)
                  }
                  cancelText='Close'
                  width='500px'
                  onCancel={onAddCategoryModalClose}
                  visible={addCategoryModal === true}
                >
                  <Input
                    size='small'
                    onChange={(event: any) =>
                      setNewCategoryName(event.target.value)
                    }
                  />
                </Modal>
              ) : null}
            </div>
          ))
        )}
      </Form.Item>
    </div>
  );
};
