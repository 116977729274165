import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

// REDUX
import { setBreadcrumbs } from '../../slices/ui.slice';

const SystemPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBreadcrumbs([{ path: '/system', label: 'System' }]));
  }, [dispatch]);

  return <Redirect to='/system/reservation-modes' />;
};

export default SystemPage;
