import { Tooltip, Form } from 'antd';

type Props = {
  helpText?: string;
  children: any;
};

type PropsFormItem = {
  helpText: string;
  label?: string;
  children: any;
};

export const TEToolTip = ({ helpText, children }: Props) => {
  return (
    <Tooltip
      placement='topLeft'
      title={helpText}
      className='help-text-on-hover'
    >
      {children}
    </Tooltip>
  );
};

export const TEItem = ({ helpText, label, children }: PropsFormItem) => {
  return (
    <TEToolTip helpText={helpText}>
      <Form.Item label={label}>{children}</Form.Item>
    </TEToolTip>
  );
};
