import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// i18N
import intl from '../../../i18n/intl';

// REDUX
import { setBreadcrumbs } from '../../../slices/ui.slice';
import {
  authenticationConfigurationsSelector,
  copyAuthenticationConfiguration,
  createAuthenticationConfiguration,
  createEmptyAuthenticationConfiguration,
  deleteAuthenticationConfiguration,
  fetchAuthenticationConfigurations,
  selectedAuthenticationConfigurationSelector,
  setSelectedAuthenticationConfiguration,
  updateAuthenticationConfiguration,
} from '../../../slices/authConfig.slice';

// STYLES
import { Form } from 'antd';

// TYPES
import { TAuthenticationConfigurationType } from '../../../types/authConfig.type';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import NormalListView from '../../../components/List/NormalListView';
import {
  AuthenticationGeneralSettings,
  AuthenticationConfiguration,
} from '../../../components/AuthenticationComponent';

// UTILS
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

const language: any = intl.messages;

type Props = {
  customerSignature: string;
};

/* ---------------------------------------------------------------------- */

const AuthenticationPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const authenticationConfigurations = useSelector(
    authenticationConfigurationsSelector,
  );
  const selectedAuthenticationConfiguration: TAuthenticationConfigurationType =
    useSelector(selectedAuthenticationConfigurationSelector);

  const listData = authenticationConfigurations?.map(
    (authConfig: TAuthenticationConfigurationType) => ({
      listLabel: authConfig.name,
      value: authConfig.id,
    }),
  );

  const onAuthenticationConfigurationSelected = (selectedValue: string) => {
    const currentAuthenticationConfiguration =
      authenticationConfigurations.find(
        (authenticationConfiguration: TAuthenticationConfigurationType) =>
          authenticationConfiguration.id === selectedValue,
      );
    if (currentAuthenticationConfiguration) {
      dispatch(
        setSelectedAuthenticationConfiguration(
          currentAuthenticationConfiguration,
        ),
      );
    }
  };

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/organizations-users`,
          label: `${language.organizations_users}`,
        },
        {
          path: `/organizations-users/authentication`,
          label: `Authentication configurations`,
        },
        {
          path: `/${selectedAuthenticationConfiguration.id}`,
          label: `${selectedAuthenticationConfiguration.name}`,
        },
      ]),
    );
  }, [
    dispatch,
    customerSignature,
    selectedAuthenticationConfiguration?.id,
    selectedAuthenticationConfiguration?.name,
  ]);

  useEffect(() => {
    dispatch(fetchAuthenticationConfigurations());
  }, [dispatch, selectedAuthenticationConfiguration?.id]);

  // @todo: Does this one really works? Check when we have right data from the server
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(
      isSavedDisabled(
        selectedAuthenticationConfiguration,
        authenticationConfigurations,
      ),
    );
  }, [authenticationConfigurations, selectedAuthenticationConfiguration]);

  // EVENT HANDLERS
  // Adds new authentication config
  const onPlusButton = () => {
    dispatch(createEmptyAuthenticationConfiguration(customerSignature));
  };

  // Deletes choosen authentication config
  const onDeleteButton = () => {
    dispatch(
      deleteAuthenticationConfiguration(selectedAuthenticationConfiguration),
    );
  };

  // Save changes function
  const onSaveChangesButton = () => {
    if (selectedAuthenticationConfiguration.id === '0') {
      dispatch(
        createAuthenticationConfiguration(selectedAuthenticationConfiguration),
      );
    } else {
      dispatch(
        updateAuthenticationConfiguration(selectedAuthenticationConfiguration),
      );
    }
  };

  // Handle changes
  const onHandleChange = (property: string, value: string) => {
    dispatch(
      setSelectedAuthenticationConfiguration({
        ...selectedAuthenticationConfiguration,
        [property]: value,
        organizationId: customerSignature, // We store customerSignature as organizationId before sending data to the server
      }),
    );
  };

  const onHandleChangeTwoProperties = (property: string[], value: any[]) => {
    dispatch(
      setSelectedAuthenticationConfiguration({
        ...selectedAuthenticationConfiguration,
        [property[0]]: value[0],
        [property[1]]: value[1],
      }),
    );
  };

  // Discard changes function
  const onDiscardChanges = () => {
    onAuthenticationConfigurationSelected(
      selectedAuthenticationConfiguration.id,
    );
  };

  // Copy authentication config
  const onCopy = () => {
    dispatch(
      copyAuthenticationConfiguration(selectedAuthenticationConfiguration),
    );
  };

  return (
    <>
      <ListView
        sectionHeading={'Authentication configurations'}
        number={listData?.length}
        onSelect={onPlusButton}
      >
        <NormalListView
          data={listData}
          onSelect={onAuthenticationConfigurationSelected}
          onDelete={onDeleteButton}
          onCopy={onCopy}
          selectedItemId={selectedAuthenticationConfiguration?.id}
        />
      </ListView>
      <div className='inner-content--wrapper'>
        <SectionHeader
          listChoice={
            selectedAuthenticationConfiguration
              ? selectedAuthenticationConfiguration.name
              : `${language.authentication_configuration}`
          }
          changeHandler={onSaveChangesButton}
          discardHandler={onDiscardChanges}
          displayProp={false}
          history={false}
          isDisabled={saveDisabled}
        />
        <Form layout='vertical' form={form}>
          <div style={{ position: 'relative' }}>
            <>
              <AuthenticationGeneralSettings
                onChange={onHandleChange}
                authenticationConfiguration={
                  selectedAuthenticationConfiguration
                }
                onChangeTwoProperties={onHandleChangeTwoProperties}
              />
              <AuthenticationConfiguration
                onChange={onHandleChange}
                authenticationConfiguration={
                  selectedAuthenticationConfiguration
                }
              />
            </>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AuthenticationPage;
