import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { Type, TType } from '../types/type.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedType: Type.createEmpty(),
  allTypes: [],
  sortedTypes: [],
  languages: [],
  limitations: {},
  externalOwners: [],
  errorMessage: {},
  advancedSettings: [],
};

// Slice
const slice = createSlice({
  name: 'types',
  initialState,
  reducers: {
    fetchTypesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchTypesSuccess: (state: any, { payload }) => {
      if (state.selectedType.id > 0) {
        state.selectedType =
          payload.results.find((type: any) => type.id === state.selectedType.id) ||
          Type.createEmpty();
      }
      state.allTypes = payload.results;
      state.sortedTypes = [];
      finishedLoadingSuccess(state);
    },
    fetchSortTypesSuccess: (state: any, { payload }) => {
      state.sortedTypes = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLanguagesTypesSuccess: (state: any, { payload }) => {
      state.languages = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsTypesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchExternalOwnersSuccess: (state: any, { payload }) => {
      state.externalOwners = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchTypesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createTypeRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createEmptyTypeSuccess: (state: any, { payload }) => {
      state.allTypes.push(payload);
      state.selectedType = payload;
      finishedLoadingSuccess(state);
    },
    createTypeSuccess: (state: any, { payload }) => {
      state.allTypes.push(payload);
      state.allTypes = state.allTypes.filter((type: TType) => type.id !== 0);
      state.selectedType = payload;
      finishedLoadingSuccess(state);
    },
    createTypeFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentType: (state: any, { payload }) => {
      state.selectedType = payload;
    },
    deleteTypeRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteTypeSuccess: (state: any, payload: any) => {
      state.allTypes = state.allTypes.filter(
        (type: TType) => type.id !== payload.payload.id,
      );
      state.selectedType = Type.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteTypeFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
    updateTypeRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateTypeSuccess: (state: any, { payload }) => {
      const newTypes = [...state.allTypes];
      const index = state.allTypes.findIndex(
        (type: TType) => type.id === payload.id,
      );
      newTypes[index] = payload;
      state.allTypes = newTypes;
      state.selectedType = payload;
      finishedLoadingSuccess(state);
    },
    updateTypeFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    fetchAdvancedSettingsTypesSuccess: (state: any, { payload }) => {
      state.advancedSettings = payload.results;
      finishedLoadingSuccess(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const typesLoading = (state: any) => state.types.loading;
export const typesSelector = (state: any) => state.types.allTypes as TType[];
export const sortedTypesSelector = (state: any) => state.types.sortedTypes;
export const typeSelectorCreateNew = () => () => {
  return Type.createEmpty();
};
export const selectedTypeSelector = (state: any) => {
  return state.types.selectedType;
};
export const fetchLanguagesTypesSelector = (state: any) =>
  state.types.languages as [];
export const fetchLimitationsTypesSelector = (state: any) =>
  state.types.limitations;
export const fetchExternalOwnersSelector = (state: any) =>
  state.types.externalOwners;
export const deleteTypeSelector = (state: any) => state.types.errorMessage;
export const advancedTypesSelector = (state: any) =>
  state.types.advancedSettings;

// Actions
export const {
  fetchTypesRequest,
  fetchTypesSuccess,
  fetchSortTypesSuccess,
  fetchLanguagesTypesSuccess,
  fetchLimitationsTypesSuccess,
  fetchExternalOwnersSuccess,
  fetchAdvancedSettingsTypesSuccess,
  fetchTypesFailure,
  createTypeRequest,
  createEmptyTypeSuccess,
  createTypeSuccess,
  createTypeFailure,
  setCurrentType,
  deleteTypeRequest,
  deleteTypeSuccess,
  deleteTypeFailure,
  updateTypeRequest,
  updateTypeSuccess,
  updateTypeFailure,
} = slice.actions;

export const fetchTypes =
  (
    sortOption?: string,
    sortQuery?: string | number,
    reservationTemplateOption?: string,
    reservationTemplateQuery?: number,
    searchTextOption?: string,
    searchTextQuery?: string | number,
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(fetchTypesRequest());
      let types;
      let encodedSortQuery: string | undefined;
      if (searchTextQuery) {
        encodedSortQuery = encodeURIComponent(searchTextQuery);
      }
      if (
        sortQuery !== undefined &&
        searchTextQuery !== undefined &&
        reservationTemplateQuery === undefined
      ) {
        if (searchTextOption !== undefined && encodedSortQuery === undefined) {
          types = await api.get({
            endpoint: `/types?${sortOption}=${sortQuery}`,
          });
          dispatch(fetchTypesSuccess(types));
        } else {
          types = await api.get({
            endpoint: `/types?${sortOption}=${sortQuery}&${searchTextOption}=${encodedSortQuery}`,
          });
          dispatch(fetchSortTypesSuccess(types));
        }
      } else if (
        sortQuery !== undefined &&
        encodedSortQuery === undefined &&
        reservationTemplateQuery === undefined
      ) {
        types = await api.get({
          endpoint: `/types?${sortOption}=${sortQuery}`,
        });
        dispatch(fetchTypesSuccess(types));
      } else if (
        sortQuery !== undefined &&
        encodedSortQuery !== undefined &&
        reservationTemplateQuery === undefined
      ) {
        types = await api.get({
          endpoint: `/types?${sortOption}=${sortQuery}&${searchTextOption}=${encodedSortQuery}`,
        });
        dispatch(fetchSortTypesSuccess(types));
      } else if (
        sortQuery === undefined &&
        encodedSortQuery === undefined &&
        reservationTemplateQuery !== undefined
      ) {
        types = await api.get({
          endpoint: `/types?${reservationTemplateOption}=${reservationTemplateQuery}`,
        });
        dispatch(fetchSortTypesSuccess(types));
      } else if (
        sortQuery === undefined &&
        encodedSortQuery !== undefined &&
        reservationTemplateQuery !== undefined
      ) {
        types = await api.get({
          endpoint: `/types?${reservationTemplateOption}=${reservationTemplateQuery}&${searchTextOption}=${encodedSortQuery}`,
        });
        dispatch(fetchSortTypesSuccess(types));
      } else if (
        sortQuery !== undefined &&
        encodedSortQuery === undefined &&
        reservationTemplateQuery !== undefined
      ) {
        types = await api.get({
          endpoint: `/types?${reservationTemplateOption}=${reservationTemplateQuery}&${sortOption}=${sortQuery}`,
        });
        dispatch(fetchSortTypesSuccess(types));
      } else if (
        sortQuery !== undefined &&
        encodedSortQuery !== undefined &&
        reservationTemplateQuery !== undefined
      ) {
        types = await api.get({
          endpoint: `/types?${sortOption}=${sortQuery}&${searchTextOption}=${encodedSortQuery}&${reservationTemplateOption}=${reservationTemplateQuery}`,
        });
        dispatch(fetchSortTypesSuccess(types));
      } else {
        const types = await api.get({ endpoint: `/types?sort=parentName` });
        dispatch(fetchTypesSuccess(types));
      }
    } catch (e) {
      dispatch(fetchTypesFailure());
      return console.error(e);
    }
  };

export const fetchLanguagesTypes = () => async (dispatch: any) => {
  try {
    dispatch(fetchTypesRequest());
    const languagesTypes = await api.get({ endpoint: `/types/languages` });
    dispatch(fetchLanguagesTypesSuccess(languagesTypes));
  } catch (e) {
    dispatch(fetchTypesFailure());
    return console.error(e);
  }
};

export const fetchLimitationsTypes = () => async (dispatch: any) => {
  try {
    dispatch(fetchTypesRequest());
    const limitationsTypes = await api.get({ endpoint: `/types/limitations` });
    dispatch(fetchLimitationsTypesSuccess(limitationsTypes));
  } catch (e) {
    dispatch(fetchTypesFailure());
    return console.error(e);
  }
};

export const fetchExternalOwners = () => async (dispatch: any) => {
  try {
    dispatch(fetchTypesRequest());
    const externalOwners = await api.get({
      endpoint: `/types/external-owners`,
    });
    dispatch(fetchExternalOwnersSuccess(externalOwners));
  } catch (e) {
    dispatch(fetchTypesFailure());
    return console.error(e);
  }
};

export const createType = (type: TType) => async (dispatch: any) => {
  try {
    dispatch(createTypeRequest());
    const { id: _, ...typeBody } = type;
    const response = await api.post({
      endpoint: `/types`,
      data: typeBody,
    });
    dispatch(createTypeSuccess(response));
  } catch (e: any) {
    dispatch(createTypeFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const createEmptyType = () => async (dispatch: any) => {
  try {
    dispatch(createTypeRequest());
    const response = Type.createEmpty();
    dispatch(createEmptyTypeSuccess(response));
  } catch (e: any) {
    dispatch(createTypeFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedType = (type: TType) => async (dispatch: any) => {
  dispatch(setCurrentType(type));
};

export const copyType = (type: TType) => async (dispatch: any) => {
  try {
    dispatch(createTypeRequest());
    const response = { ...type, id: 0, description: '', documentation: '' };
    dispatch(createTypeSuccess(response));
  } catch (e) {
    dispatch(createTypeFailure());
    return console.error(e);
  }
};

export const deleteType = (type: TType) => async (dispatch: any) => {
  try {
    dispatch(deleteTypeRequest());
    await api.delete({
      endpoint: `/types/${type.id}`,
    });
    dispatch(deleteTypeSuccess(type));
  } catch (e: any) {
    dispatch(deleteTypeFailure(e.response.data.message));
    return console.error(e);
  }
};

export const updateType = (type: TType) => async (dispatch: any) => {
  try {
    dispatch(updateTypeRequest());
    const { ...TypeBody } = type;
    const response = await api.patch({
      endpoint: `/types/${type.id}`,
      data: { ...TypeBody },
    });
    dispatch(updateTypeSuccess(response));
    dispatch(fetchTypes());
  } catch (e: any) {
    dispatch(updateTypeFailure());
    dispatch(fetchTypes());
    return console.error(
      e.response?.data ? e.response?.data : e.response ? e.response : e,
    );
  }
};

export const fetchAdvancedSettingsForType =
  (type: TType) => async (dispatch: any) => {
    try {
      dispatch(fetchTypesRequest());
      const types = await api.get({
        endpoint: `/types/${type.id}/advanced`,
      });
      dispatch(fetchAdvancedSettingsTypesSuccess(types));
    } catch (e) {
      dispatch(fetchTypesFailure());
      return console.error(e);
    }
  };
