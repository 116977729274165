import { useState } from 'react';
import { useSelector } from 'react-redux';

// REDUX
import { userSelector } from '../../slices/auth.slice';

// SERVICES
import tokenService from '../../services/token.service';

// i18n
import intl from '../../i18n/intl';

// STYLES
import './index.scss';
import { Button } from 'antd';

// COMPONENTS
import UsernameAndPassword from './UsernameAndPassword';
import ResetPassword from './resetPassword';

const language: any = intl.messages;

const SignInPage = () => {
  const [showLogIn, setShowLogIn] = useState(true);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const onShowLogIn = () => {
    setShowLogIn(true);
    setShowResetPassword(false);
  };

  const userInfo = useSelector(userSelector); // userInfo.pem is used to check whether the user has admin/super permissions or not. In that case it is userInfo.pem = 'super'
  const tokenData = useSelector(userSelector);

  const newSignIn = () => {
    const { cus: customerSignature } = tokenData;
    tokenService.clearToken();
    window.location.replace(`/login?customerSignature=${customerSignature}`);
  };

  // Check wheater user has admin permissions or not
  // If no admin permissions:
  if (userInfo !== null && userInfo?.pem !== 'super') {
    return (
      <div className='start-page-wrapper'>
        <div className='sign-in--box'>
          <h1 className='sign-in--heading'>TE Admin</h1>
          <p style={{ paddingTop: 70, paddingBottom: 70 }}>
            You are not authenticated to do any changes in TE Admin. Log in as
            admin or super user to get access to do changes!
          </p>
          <Button className='sign-in--button' onClick={() => newSignIn()}>
            {language.sign_in}!
          </Button>
        </div>
      </div>
    );
  } else {
    // If admin permissions:
    return (
      <div className='start-page-wrapper'>
        <div className='sign-in--box'>
          <h1 className='sign-in--heading'>TE Admin</h1>
          {showLogIn && (
            <>
              <UsernameAndPassword />
              {/*            <Button
                type='text'
                className='forgot-password--button'
                onClick={onShowResetPassword}
              >
                {language.forgot_password}?
              </Button> */}
            </>
          )}
          {showResetPassword && (
            <>
              <ResetPassword />
              <Button
                type='text'
                className='forgot-password--button'
                onClick={onShowLogIn}
              >
                {language.sign_in}!
              </Button>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default SignInPage;
