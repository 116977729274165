import { TAltDesignations } from './languages.type';
import { THistory } from './dateHistory.type';

export type TReservationMode = {
  name: string;
  extId: string;
  id: number;
  description: string;
  showInClient: boolean;
  showInCulling: boolean;
  altDesignations: Array<TAltDesignations>;
  reservationTemplates: Array<number>;
  documentation: string;
  history: Array<THistory>;
};

export class ReservationMode {
  static createEmpty = (): TReservationMode => ({
    name: '',
    extId: '',
    id: 0,
    description: '',
    showInClient: false,
    showInCulling: false,
    altDesignations: [],
    reservationTemplates: [],
    documentation: '',
    history: [],
  });
}

export enum EDefaultStatus {
  CONFIRMED = 'CONFIRMED',
  PLANNED = 'PLANNED',
  PRELIMINARY = 'PRELIMINARY',
}

export type TReservationTemplate = {
  name: string;
  timeRule: number;
  privateRole: number;
  standardOrganization: number;
  defaultStatus: EDefaultStatus;
  active: boolean;
  affectOrders: boolean;
  id: number;
  deleteWhenCulling: boolean;
  types: Array<TTemplateType>;
  typeCombinations: Array<Array<number>>;
  relationTypes: Array<number>;
  fields: Array<number>;
  reservationModes: Array<number>;
  organizations: Array<number>;
  documentation: string;
  history: Array<THistory>;
};

export class ReservationTemplate {
  static createEmpty = (): TReservationTemplate => ({
    name: '',
    timeRule: 0,
    privateRole: 0,
    standardOrganization: 0,
    defaultStatus: EDefaultStatus.CONFIRMED,
    active: true,
    affectOrders: false,
    id: 0,
    deleteWhenCulling: false,
    types: [],
    typeCombinations: [],
    relationTypes: [],
    fields: [],
    reservationModes: [],
    organizations: [],
    documentation: 'This is some documentation',
    history: [],
  });
}

export enum EReservationKind {
  PHYSICAL = 'PHYSICAL',
  PHYSICAL_DOUBLE = 'PHYSICAL_DOUBLE',
  ABSTRACT = 'ABSTRACT',
  ABSTRACT_DISPLAY = 'ABSTRACT_DISPLAY',
}

export enum EMandatoryProperty {
  MANDATORY = 'MANDATORY',
  COMBINATION = 'COMBINATION',
  OPTIONAL = 'OPTIONAL',
}

export enum EObjectProperty {
  OBJECT = 'OBJECT',
  VIRTUAL = 'VIRTUAL',
  INCOMPLETE = 'INCOMPLETE',
}

export enum EOptionalRelation {
  NONE = 'NONE',
  OPTIONAL = 'OPTIONAL',
  ABSTRACT = 'ABSTRACT',
}

export enum ETemplateTypesText {
  STANDARD = 'STANDARD',
  NEVER = 'NEVER',
  ALWAYS = 'ALWAYS',
}

export type TTemplateType = {
  type: number | string | undefined;
  min: number;
  max: number;
  reservationKind: EReservationKind;
  mandatoryProperty: EMandatoryProperty;
  objectProperty: EObjectProperty;
  memberType: number;
  availabilityType: number;
  requestType: boolean;
  cancelNoPermisson: boolean;
  optionalRelation: EOptionalRelation;
  automaticObjectSelection: boolean;
  reservationText: ETemplateTypesText;
  obstacleText: ETemplateTypesText;
  padding: boolean;
};

export class TemplateType {
  static create = (typeId: number | string | undefined): TTemplateType => ({
    type: typeId,
    min: 1,
    max: 5,
    reservationKind: EReservationKind.ABSTRACT,
    mandatoryProperty: EMandatoryProperty.OPTIONAL,
    objectProperty: EObjectProperty.OBJECT,
    memberType: 0,
    availabilityType: 0,
    requestType: false,
    cancelNoPermisson: false,
    optionalRelation: EOptionalRelation.NONE,
    automaticObjectSelection: false,
    reservationText: ETemplateTypesText.STANDARD,
    obstacleText: ETemplateTypesText.STANDARD,
    padding: false
  });
}

export type TMoveReservationsRequest = {
  reservationTemplate: number | undefined;
  count: number | undefined;
  date: number | undefined;
  type: number | undefined;
};
