// i18n
import intl from '../i18n/intl';

import {
  HomeFilled,
  SettingFilled,
  UserOutlined,
  ApiFilled,
  AppstoreFilled,
  SwapOutlined,
  FileTextFilled,
} from '@ant-design/icons';

const language = intl.messages;

export const menuJSON = [
  {
    label: `${language.home}`,
    route: '/',
    icon: <HomeFilled />,
    subItems: [],
  },
  {
    label: `${language.system_settings}`,
    route: '/system',
    icon: <SettingFilled />,
    subItems: [
      {
        label: `${language.reservation_modes}`,
        route: '/system/reservation-modes',
      },
      {
        label: `${language.reservation_templates}`,
        route: '/system/reservation-templates',
      },
      {
        label: `${language.types}`,
        route: '/system/types',
      },
      {
        label: `${language.fields}`,
        route: '/system/fields',
      },
      {
        label: `${language.member_types}`,
        route: '/system/member-types',
      },
      {
        label: `${language.relation_types}`,
        route: '/system/relation-types',
      },
      {
        label: `${language.field_relations}`,
        route: '/system/field-relations',
      },
      {
        label: `${language.availability_types}`,
        route: '/system/availability-types',
      },
      {
        label: `${language.time_rules}`,
        route: '/system/time-rules',
      },
      {
        label: `${language.periods}`,
        route: '/system/periods',
      },
      {
        label: `${language.holiday_reservations}`,
        route: '/system/holidays',
      },

      {
        label: `${language.system_preferences}`,
        route: '/system/system-preferences',
      },
      {
        label: `${language.maintenance}`,
        route: '/system/maintenance',
      },
      {
        label: `${language.padding}`,
        route: '/system/padding-rules',
      }
    ],
  },
  {
    label: `${language.organizations_users}`,
    text: '(under development)',
    route: '/organizations-users',
    icon: <UserOutlined />,
    subItems: [
      {
        label: `${language.organizations}`,
        route: '/organizations-users/organizations',
      },
      {
        label: `${language.roles_permissions}`,
        route: '/organizations-users/roles-and-permissions',
      },
      {
        label: `${language.user_manager}`,
        route: '/organizations-users/user-manager',
      },
      {
        label: `${language.authentication}`,
        route: '/organizations-users/authentication',
      },
    ],
  },
  {
    label: `${language.api_services}`,
    text: '(under development)',
    route: '/api-services',
    icon: <ApiFilled />,
    subItems: [
      {
        label: `${language.services}`,
        route: '/api-services/services',
      },
      {
        label: 'API',
        route: '/api-services/api',
      },
    ],
  },
  {
    label: `${language.object_settings}`,
    text: '(under development)',
    route: '/object-settings',
    icon: <AppstoreFilled />,
    subItems: [
      {
        label: `${language.object_manager}`,
        route: '/object-settings/object-manager',
      },
      /*       {
        label: 'Colors',
        route: '/object-settings/colors',
      },
      {
        label: 'Convert colors',
        route: '/object-settings/convert-colors',
      }, */
    ],
  },
  {
    label: 'TE Apps',
    text: '(under development)',
    route: '/te-apps/general-settings',
    icon: <SwapOutlined />,
    subItems: [
      {
        label: `${language.general_settings}`,
        route: '/te-apps/general-settings',
      },
      {
        label: `${language.users}`,
        route: '/te-apps/users',
      },
      {
        label: `${language.organization_groups}`,
        route: '/te-apps/organization-groups',
      },
      {
        label: `TE Server ${language.integration}`,
        route: '/te-apps/server-integration',
      },
      {
        label: `${language.products}`,
        route: '/te-apps/products',
      },
      {
        label: `${language.access_tokens}`,
        route: '/te-apps/access-tokens',
      },
      {
        label: `${language.backups}`,
        route: '/te-apps/backups',
      },
    ],
  },
  {
    label: 'GDPR',
    text: '(under development)',
    route: '/gdpr/culling',
    icon: <FileTextFilled />,
    subItems: [
      {
        label: `${language.culling}`,
        route: '/gdpr/culling',
      },
      {
        label: `${language.reports}`,
        route: '/gdpr/reports',
      },
    ],
  },
];

export const nextMenuJSON = [
  {
    label: `TE Core ${language.settings}`,
    text: '(under development)',
    route: '/te-core/views',
    icon: <span className='item--icon--text'>TEC</span>,
    subItems: [
      {
        label: `${language.views}`,
        route: '/te-core/views',
      },
      {
        label: `${language.header_periods}`,
        route: '/te-core/header-periods',
      },
      {
        label: `${language.miscellaneous}`,
        route: '/te-core/miscellaneous',
      },
    ],
  },
  {
    label: `TE Plan ${language.settings}`,
    text: '(under development)',
    route: '/te-plan/settings',
    icon: <span className='item--icon--text'>TEPL</span>,
    subItems: [
      {
        label: `${language.settings}`,
        route: '/te-plan/settings',
      },
    ],
  },
  {
    label: `TE Exam ${language.settings}`,
    text: '(under development)',
    route: '/te-exam/settings',
    icon: <span className='item--icon--text'>TEEX</span>,
    subItems: [
      {
        label: `${language.settings}`,
        route: '/te-exam/settings',
      },
    ],
  },
  {
    label: `TE Preferences ${language.settings}`,
    text: '(under development)',
    route: '/te-preferences/settings',
    icon: <span className='item--icon--text'>TEPR</span>,
    subItems: [
      {
        label: `${language.settings}`,
        route: '/te-preferences/settings',
      },
    ],
  },
];
