// i18N
import intl from '../../../i18n/intl';

// STYLES

// COMPONENTS
import ActionButton from '../../Buttons';

type Props = {
  changeHandler: any;
  discardHandler: any;
  error?: any;
  isDisabled?: any;
  displayProp: boolean;
};

const language: any = intl.messages;

const SystemPrefsActionButtons = ({
  changeHandler = () => {},
  discardHandler = () => {},
  error,
  isDisabled,
  displayProp,
}: Props) => {
  return (
    <>
      <div className='collapse-heading-button-bar'>
        <ActionButton
          title={language.save_changes}
          action={() => changeHandler}
          isDisabled={isDisabled === true}
          isDanger={false}
          displayProp={displayProp}
          isSaveChangesBtn
        />
        <ActionButton
          title={language.discard_changes}
          action={() => discardHandler}
          isDisabled={false}
          isDanger
          displayProp={displayProp}
        />
      </div>
      <p
        style={{
          color: '#ff6357',
          background: 'white',
          margin: 0,
        }}
      >
        {error}
      </p>
    </>
  );
};

export default SystemPrefsActionButtons;
