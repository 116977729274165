import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form } from 'antd';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import {
  ColorsChooseType,
  ColorsFilter,
  ColorsCharacteristics,
} from '../../../components/ChooseColorsComponent';

// TYPES

// ACTIONS

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

const ChooseColorsPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/object-settings`,
          label: `${language.object_settings}`,
        },
        {
          path: `/object-settings/colors`,
          label: `Choose colors`,
        },
      ]),
    );
  });

  return (
    <>
      <div className='inner-content--wrapper'>
        <div>
          <SectionHeader
            listChoice='Choose colors'
            modifiedDate={2020}
            modifiedBy='admin'
            changeHandler
            discardHandler
            displayProp={false}
            history={false}
          />
          <Form layout='vertical' form={form}>
            <ColorsChooseType />
            <ColorsFilter />
            <ColorsCharacteristics />
          </Form>
        </div>
      </div>
    </>
  );
};

export default ChooseColorsPage;
