import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import {
  THoliday,
  THolidayRegion,
  THolidayReservation,
} from '../types/holidayreservation.type';
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  holidayRegions: [],
  selectedHolidays: [],
  createdHolidays: {},
};

// Slice
const slice = createSlice({
  name: 'holidays',
  initialState,
  reducers: {
    fetchHolidayRegionsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchHolidayRegionsSuccess: (state: any, { payload }) => {
      state.holidayRegions = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchHolidayRegionsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    fetchHolidaysRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchHolidaysSuccess: (state: any, { payload }) => {
      state.selectedHolidays = payload;
      finishedLoadingSuccess(state);
    },
    fetchHolidaysFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createHolidayReservationRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createHolidayReservationSuccess: (state: any, { payload }) => {
      state.createdHolidays = payload.numberOfHolidaysCreated;
      finishedLoadingSuccess(state);
    },
    createHolidayReservationFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const holidaysLoading = (state: any) => state.holidays.loading;
export const holidayRegionsSelector = (state: any) =>
  state.holidayReservations.holidayRegions as THolidayRegion[];
export const holidaySelector = (state: any) =>
  state.holidayReservations.selectedHolidays as THoliday[];
export const createdHolidaysSelector = (state: any) =>
  state.holidayReservations.createdHolidays;

// Actions
export const {
  fetchHolidayRegionsRequest,
  fetchHolidayRegionsSuccess,
  fetchHolidayRegionsFailure,
  fetchHolidaysRequest,
  fetchHolidaysSuccess,
  fetchHolidaysFailure,
  createHolidayReservationRequest,
  createHolidayReservationSuccess,
  createHolidayReservationFailure,
} = slice.actions;

export const fetchHolidayRegions = () => async (dispatch: any) => {
  try {
    dispatch(fetchHolidayRegionsRequest());
    const holidayRegions = await api.get({
      endpoint: `/holidays/regions`,
    });
    dispatch(fetchHolidayRegionsSuccess(holidayRegions));
  } catch (e) {
    dispatch(fetchHolidayRegionsFailure());
    return console.error(e);
  }
};

export const getListOfHolidays =
  (
    holidayRegion: string,
    holidayType: string,
    startDate: number,
    endDate: number,
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(fetchHolidaysRequest());
      const holidays: THoliday = await api.get({
        endpoint: `/holidays?holidayRegion=${holidayRegion}&holidayType=${holidayType}&startDate=${startDate}&endDate=${endDate}`,
      });
      dispatch(fetchHolidaysSuccess(holidays));
    } catch (e) {
      dispatch(fetchHolidaysFailure());
      return console.error(e);
    }
  };

export const createHolidayReservation =
  (holidayReservation: THolidayReservation) => async (dispatch: any) => {
    try {
      dispatch(createHolidayReservationRequest());
      const { ...holidayReservationBody } = holidayReservation;
      const response = await api.post({
        endpoint: `/holidays/reservations`,
        data: holidayReservationBody,
      });
      dispatch(createHolidayReservationSuccess(response));
    } catch (e: any) {
      dispatch(createHolidayReservationFailure());
      console.error(e.response?.data ? e.response.data : e.response);
    }
  };
