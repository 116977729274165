import { combineReducers } from 'redux';

// SLICES
import uiReducer from './ui.slice';
import authReducer from './auth.slice';
import limitationsReducer from './limitations.slice';
import languagesReducer from './language.slice';
import publicInfoReducer from './publicInfo.slice';
import reservationModeReducer from './reservationMode.slice';
import reservationTemplateReducer from './reservationTemplate.slice';
import typeReducer from './type.slice';
import fieldReducer from './field.slice';
import memberTypeReducer from './memberType.slice';
import relationTypeReducer from './relationType.slice';
import fieldRelationReducer from './fieldRelation.slice';
import availabilityTypeReducer from './availabilityType.slice';
import timeRuleReducer from './timeRule.slice';
import periodReducer from './period.slice';
import holidayReservationReducer from './holidayReservation.slice';
import suppportInfoReducer from './supportInfo.slice';
import emailPreferencesReducer from './emailPreferences.slice';
import systemPreferencesReducer from './systemPreferences.slice';
import passwordRulesReducer from './passwordRules.slice';
import userPreferencesReducer from './userPreferences.slice';
import timeZonesReducer from './timeZone.slice';
import maintenanceReducer from './maintenance.slice';
import systemActionsReducer from './systemActions.slice';
import objectReducer from './object.slice';
import organizationReducer from './organization.slice';
import organizationNodeReducer from './organizationNode.slice';
import userReducer from './user.slice';
import roleReducer from './role.slice';
import apiReducer from './api.slice';
import authenticationTokenReducer from './authenticationToken.slice';
import authenticationConfigurationReducer from './authConfig.slice';
import PaddingReducer from "./paddingRules.slice";

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  limitations: limitationsReducer,
  languages: languagesReducer,
  publicInfo: publicInfoReducer,
  reservationModes: reservationModeReducer,
  reservationTemplates: reservationTemplateReducer,
  types: typeReducer,
  fields: fieldReducer,
  memberTypes: memberTypeReducer,
  relationTypes: relationTypeReducer,
  fieldRelations: fieldRelationReducer,
  availabilityTypes: availabilityTypeReducer,
  timeRules: timeRuleReducer,
  periods: periodReducer,
  holidayReservations: holidayReservationReducer,
  supportInfo: suppportInfoReducer,
  emailPreferences: emailPreferencesReducer,
  systemPreferences: systemPreferencesReducer,
  passwordRules: passwordRulesReducer,
  userPreferences: userPreferencesReducer,
  timeZones: timeZonesReducer,
  maintenance: maintenanceReducer,
  systemActions: systemActionsReducer,
  objects: objectReducer,
  organizations: organizationReducer,
  organizationNodes: organizationNodeReducer,
  users: userReducer,
  roles: roleReducer,
  apis: apiReducer,
  authenticationTokens: authenticationTokenReducer,
  authConfigs: authenticationConfigurationReducer,
  paddingRules: PaddingReducer,
});

export default rootReducer;
