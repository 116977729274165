import { useState } from 'react';

// i18N
import intl, { getInlineString } from '../../../i18n/intl';

// STYLES
import { Table, Space, Button, Popconfirm, Form, Input } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import './index.scss';

type Props = {
  categories: any;
  onCategoriesChanged: any;
  disabled?: any;
};

const language: any = intl.messages;

const FieldTable = ({ categories, onCategoriesChanged, disabled }: Props) => {
  // EVENT HANDLERS
  const onChangeInputHandler = (category: any, value: any) => {
    const index = categories.indexOf(category);
    const updatedCategory = value;
    const newCategories: any[] = [...categories];
    newCategories.splice(index, 1, updatedCategory);
    onCategoriesChanged('categories', newCategories);
  };

  const [exceededLength, setExceededLength] = useState(false);
  const addCategory = () => {
    if (categories.length <= 500) {
      const newCategory: any[] = [...categories];
      newCategory.push('');
      onCategoriesChanged('categories', newCategory);
    } else {
      setExceededLength(true);
    }
  };

  const onDeleteHandler = (record: any) => {
    onCategoriesChanged(
      'categories',
      categories.filter((category: any) => category !== record.value),
    );
  };

  const columns = [
    {
      title: `${language.category}`,
      dataIndex: '',
      key: 'index',
      render: (category: any, record: any) => (
        <Input
          size='small'
          value={record.value}
          onChange={(event: any) =>
            onChangeInputHandler(category.value, event.target.value)
          }
          disabled={disabled}
        />
      ),
    },
    {
      title: `${language.action}`,
      key: 'action',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title='Are you sure to delete this category?'
            onConfirm={() => onDeleteHandler(record)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText='Yes'
            cancelText='No'
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Form.Item className='category-button-text-wrapper'>
        <Button
          size='small'
          type='default'
          onClick={() => addCategory()}
          icon={<PlusOutlined />}
          disabled={disabled}
        >
          {language.add_category}
        </Button>
        {exceededLength === true ? (
          <p style={{ marginBottom: 0, marginLeft: 10, color: '#ff6357' }}>
            {getInlineString('exceeded_maximum_limit_categories', 500)}
          </p>
        ) : null}
      </Form.Item>
      <Table
        size='small'
        dataSource={categories?.map((category: any, index: any) => ({
          key: index,
          value: category,
          category,
        }))}
        columns={columns}
      />
    </>
  );
};

export default FieldTable;
