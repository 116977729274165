export type TEmailTemplate = {
  id: string;
  name: string;
  subject: string;
  message: string;
};

export type TSupportInfo = {
  generalDescription: string;
  generalName: string;
  generalEmail: string;
  generalPhone: string;
  generalUrl: string;
  generalUrlTitle: string;
  generalManualUrl: string;
  generalManualUrlTitle: string;
  technicalDescription: string;
  technicalName: string;
  technicalEmail: string;
  technicalPhone: string;
  technicalUrl: string;
  technicalUrlTitle: string;
  technicalManualUrl: string;
  technicalManualUrlTitle: string;
};

export type TEmailPreferences = {
  emailMaxMembers: number;
  useUserEmailAsFrom: boolean;
  fromAddress: string;
  useUserEmailAsReplyTo: boolean;
  includeEmptyReservationFields: boolean;
  sendServerEmailToSlack: boolean;
  emailTemplates: Array<TEmailTemplate>;
};

export type TPasswordRules = {
  minimumLength: number;
  doNotAllowPasswordsContainingLoginName: boolean;
  doNotAllowPasswordsOnBlackList: boolean;
  requireLowerCase: boolean;
  requireUpperCase: boolean;
  requireDigit: boolean;
  requireSpecial: boolean;
  specialCharacters: string;
};

export type TUserPreferences = {
  externalWebLoginUrl: string;
  userObjectAddPattern: string;
  userObjectRemovePattern: string;
};

export enum EAcceptJavaClient {
  YES = 'YES',
  NO = 'NO',
  DEFAULT = 'DEFAULT',
}

export type TSystemPreferencesMisc = {
  activeFeatures: string[];
  editableFeatures: string[];
  customerName: string;
  calendarBackgroundText: string;
  orderAcceptSpanFrom: number;
  orderAcceptSpanTo: number;
  allowJavaClient: EAcceptJavaClient;
  doubleBookingType: number;
  maxReservationObjects: number;
  maxImportFieldValues: number;
  optionalRelationsAnyObject: boolean;
  reservationExceptionsHide: boolean;
  useReservePermissionOnObjects: boolean;
  reservationPermissionsAllowChangeOrganization: boolean;
  affectOrdersAllowChange: boolean;
};
