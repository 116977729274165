import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TRole, Role } from '../types/role.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedRole: Role.createEmpty(),
  allRoles: [],
  limitations: {},
};

// Slice
const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    fetchRolesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchRolesSuccess: (state: any, { payload }) => {
      if (state.selectedRole.id > 0) {
        state.selectedRole =
          payload.find((role: any) => role.id === state.selectedRole.id) ||
          Role.createEmpty();
      }
      state.allRoles = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsRolesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchRolesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createRoleRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createRoleSuccess: (state: any, { payload }) => {
      state.allRoles.push(payload);
      state.selectedRole = payload;
      finishedLoadingSuccess(state);
    },
    createRoleFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentRole: (state: any, { payload }) => {
      state.selectedRole = payload;
    },
    deleteRoleRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteRoleSuccess: (state: any, payload: any) => {
      state.allRoles = state.allRoles.filter(
        (role: TRole) => role.id !== payload.payload.id,
      );
      state.selectedRole = Role.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteRoleFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateRoleRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateRoleSuccess: (state: any, { payload }) => {
      const index = state.allRoles.findIndex(
        (role: TRole) => role.id === payload.id,
      );
      state.allRoles[index] = payload;
      finishedLoadingSuccess(state);
    },
    updateRoleFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const rolesLoading = (state: any) => state.roles.loading;
export const rolesSelector = (state: any) => state.roles.allRoles as TRole[];
export const roleSelectorCreateNew = () => () => {
  return Role.createEmpty();
};
export const selectedRoleSelector = (state: any) => {
  return state.roles.selectedRole;
};
export const fetchLimitationsRolesSelector = (state: any) =>
  state.roles.limitations;

// Actions
export const {
  fetchRolesRequest,
  fetchRolesSuccess,
  fetchLimitationsRolesSuccess,
  fetchRolesFailure,
  createRoleRequest,
  createRoleSuccess,
  createRoleFailure,
  setCurrentRole,
  deleteRoleRequest,
  deleteRoleSuccess,
  deleteRoleFailure,
  updateRoleRequest,
  updateRoleSuccess,
  updateRoleFailure,
} = slice.actions;

export const fetchRoles = () => async (dispatch: any) => {
  try {
    dispatch(fetchRolesRequest());
    const roles = await api.get({ endpoint: `/roles` });
    dispatch(fetchRolesSuccess(roles));
  } catch (e) {
    dispatch(fetchRolesFailure());
    return console.error(e);
  }
};

export const fetchLimitationsRoles = () => async (dispatch: any) => {
  try {
    dispatch(fetchRolesRequest());
    const limitationsRoles = await api.get({
      endpoint: `/roles/limitations`,
    });
    dispatch(fetchLimitationsRolesSuccess(limitationsRoles));
  } catch (e) {
    dispatch(fetchRolesFailure());
    return console.error(e);
  }
};

export const createRole = (role: TRole) => async (dispatch: any) => {
  try {
    dispatch(createRoleRequest());
    const { id: _, ...roleBody } = role;
    const response = await api.post({
      endpoint: `/roles`,
      data: roleBody,
    });
    dispatch(createRoleSuccess(response));
  } catch (e: any) {
    dispatch(createRoleFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const createEmptyRole = () => async (dispatch: any) => {
  try {
    dispatch(createRoleRequest());
    const response = Role.createEmpty();
    dispatch(createRoleSuccess(response));
  } catch (e: any) {
    dispatch(createRoleFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedRole = (role: TRole) => async (dispatch: any) => {
  dispatch(setCurrentRole(role));
};

export const copyRole = (role: TRole) => async (dispatch: any) => {
  try {
    dispatch(createRoleRequest());
    const response = {
      ...role,
      id: 0,
      description: '',
    };
    dispatch(createRoleSuccess(response));
  } catch (e: any) {
    dispatch(createRoleFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const deleteRole = (role: TRole) => async (dispatch: any) => {
  try {
    dispatch(deleteRoleRequest());
    await api.delete({
      endpoint: `/roles/${role.id}`,
    });
    dispatch(deleteRoleSuccess(role));
  } catch (e) {
    dispatch(deleteRoleFailure());
    return console.error(e);
  }
};

export const updateRole = (role: TRole) => async (dispatch: any) => {
  try {
    dispatch(updateRoleRequest());
    const { ...roleBody } = role;
    const response = await api.patch({
      endpoint: `/roles/${role.id}`,
      data: { ...roleBody },
    });
    dispatch(updateRoleSuccess(response));
  } catch (e: any) {
    dispatch(updateRoleFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};
