import { useSelector } from 'react-redux';

// REDUX
import { typesSelector } from '../../../slices/type.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Table, Space, Button, Popconfirm, Form, Select, Checkbox } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

// TYPES
import {
  TRelationTypesItem,
  RelationTypesItem,
} from '../../../types/relation.type';
import { TType } from '../../../types/type.type';

// COMPONENTS
import { TEToolTip } from '../../TEToolTip';
import { getNameOnType } from '../../../utils/getNameOn';

type Props = {
  relationTypesItems: TRelationTypesItem[];
  onTypesChanged: any;
};

const onBlur = () => {};
const onFocus = () => {};

const language: any = intl.messages;

const RelationTypeTable = ({ relationTypesItems, onTypesChanged }: Props) => {
  // All types
  const types: TType[] = useSelector(typesSelector);

  // EVENT HANDLERS
  const onChangeTypeHandler = (
    relationTypesItem: any,
    property: string,
    value: any,
  ) => {
    const index = relationTypesItems.indexOf(relationTypesItem);
    const updatedType = Object.assign({}, relationTypesItem);
    updatedType[property] = value;
    const newTypes: TRelationTypesItem[] = [...relationTypesItems];
    newTypes.splice(index, 1, updatedType);
    onTypesChanged('relationTypes', newTypes);
  };

  const addType = (typeId: number | string | undefined) => {
    const newRelationTypesItems: TRelationTypesItem[] = [...relationTypesItems];
    newRelationTypesItems.push(RelationTypesItem.create(typeId));
    onTypesChanged('relationTypes', newRelationTypesItems);
  };

  const onDeleteHandler = (type: any) => {
    onTypesChanged(
      'relationTypes',
      relationTypesItems.filter(
        (relationTypesItem: any) => relationTypesItem.type !== type.type,
      ),
    );
  };

  const columns = [
    {
      title: (
        <TEToolTip helpText={language.help_type_relation_type}>
          <span className='small-heading-text'>{language.type}</span>
        </TEToolTip>
      ),
      dataIndex: 'type',
      width: 260,
      key: 'type',
      render: (relationTypesItem: any) => (
        <span>{getNameOnType(types, relationTypesItem)}</span>
      ),
    },
    {
      title: (
        <TEToolTip helpText={language.help_help_type_relation_types}>
          <span className='small-heading-text'>{language.help_type}</span>
        </TEToolTip>
      ),
      dataIndex: 'helpType',
      key: 'helpType',
      render: (helpType: any, record: any) => (
        <Checkbox
          onChange={(event: any) =>
            onChangeTypeHandler(
              record.relationTypesItem,
              'helpType',
              event.target.checked,
            )
          }
          checked={helpType}
        />
      ),
    },
    {
      title: <span className='small-heading-text'>{language.action}</span>,
      key: 'action',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={language.sure_to_delete}
            onConfirm={() => onDeleteHandler(record.relationTypesItem)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // To being able to exclude the already choosen types
  const typeIds = relationTypesItems?.map(
    (relationTypesItem: any) => relationTypesItem.type,
  );
  const typesToChooseFrom = types.filter(
    (type: TType) => typeIds?.indexOf(type.id) === -1,
  );

  return (
    <>
      <Form.Item label={`${language.add_type}`} style={{ marginBottom: 10 }}>
        <Select
          showSearch
          onFocus={onFocus}
          onBlur={onBlur}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size='small'
          onChange={addType}
          placeholder={language.add_type}
          value='-'
          /*           // @ts-ignore
          getPopupContainer={() =>
            document.getElementsByClassName('outside-click-provider')[0]
          } */
        >
          {typesToChooseFrom.map((type: any) => (
            <Select.Option key={type.id} value={type.id}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Table
        size='small'
        dataSource={relationTypesItems?.map((relationTypesItem: any) => ({
          key: relationTypesItem.type,
          ...relationTypesItem,
          relationTypesItem,
        }))}
        columns={columns}
      />
    </>
  );
};

export default RelationTypeTable;
