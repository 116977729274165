import { THistory } from './dateHistory.type';

export enum EWeekdays {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export enum EWeekdaySelection {
  noWeekDaySelection = 'no_weekday_selection',
}

export enum EReservationPeriodType {
  NONE = 'NONE',
  RESERVATION_PERIOD = 'RESERVATION_PERIOD',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export type TTimeRule = {
  name: string;
  id: number;
  description: string;
  history: Array<THistory>;
  weekdays: Array<EWeekdays>;
  clockTimeStart: number;
  clockTimeEnd: number;
  standardStep: number;
  minimumStep: number;
  beginStep: number;
  importStep: number;
  minimumLength: number;
  maximumLength: number;
  defaultLength: number;
  beginOffset: number;
  timeSlotSelection?: Array<TWeekdaySelection>;
  absoluteStartTime?: number;
  absoluteEndTime?: number;
  relativeStartTime: number;
  relativeEndTime: number;
  useStartTimeToOpenNextDay: boolean;
  useSeparateCancellationRules: boolean;
  absoluteCancellationStartTime?: number;
  absoluteCancellationEndTime?: number;
  relativeCancellationStartTime: number;
  relativeCancellationEndTime: number;
  reservationPeriod1: EReservationPeriodType;
  reservationLength1: number;
  reservationCount1: number;
  reservationPeriod2: EReservationPeriodType;
  reservationLength2: number;
  reservationCount2: number;
  useModifiedByWhenCalculatingUserQuota: boolean;
  templates: Array<number>;
};

export class TimeRule {
  static createEmpty = (): TTimeRule => ({
    name: '',
    id: 0,
    description: '',
    history: [],
    weekdays: [
      EWeekdays.MON,
      EWeekdays.TUE,
      EWeekdays.WED,
      EWeekdays.THU,
      EWeekdays.FRI,
      EWeekdays.SAT,
      EWeekdays.SUN,
    ],
    clockTimeStart: 28800,
    clockTimeEnd: 64800,
    standardStep: 900,
    minimumStep: 900,
    beginStep: 900,
    importStep: 900,
    minimumLength: 1800,
    maximumLength: 86400,
    defaultLength: 3600,
    beginOffset: 0,
    relativeStartTime: -15552000,
    relativeEndTime: 47520000,
    useStartTimeToOpenNextDay: false,
    useSeparateCancellationRules: false,
    relativeCancellationStartTime: -15552000,
    relativeCancellationEndTime: 47520000,
    reservationPeriod1: EReservationPeriodType.NONE,
    reservationLength1: 0,
    reservationCount1: 0,
    reservationPeriod2: EReservationPeriodType.NONE,
    reservationLength2: 0,
    reservationCount2: 0,
    useModifiedByWhenCalculatingUserQuota: false,
    templates: [],
  });
}

export type TTime = {
  clockTimeStart: number;
  clockTimeEnd: number;
};

export class Time {
  static createEmpty = (): TTime => ({
    clockTimeStart: 28800,
    clockTimeEnd: 36000,
  });
}
export class TimeCreate {
  static create = (clockTimeStart: number, clockTimeEnd: number): TTime => ({
    clockTimeStart: clockTimeStart,
    clockTimeEnd: clockTimeEnd,
  });
}

export type TWeekdaySelection = {
  weekdays: Array<string>;
  times: Array<TTime>;
};

export class WeekdaySelection {
  static createEmpty = (): TWeekdaySelection => ({
    weekdays: [],
    times: [],
  });
}

export enum ERelativeTimeTypes {
  days = 'days',
  hours = 'hours',
  minutes = 'minutes',
}
