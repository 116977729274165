// i18N
import intl from '../../../i18n/intl';

// STYLES
import '../index.scss';
import { Table, Button, Popconfirm, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

type Props = {
  data: any[];
  onChange?: any;
  rowSelection: any;
  selectedPropertyGroup?: any;
  onChangePropertyGroupHandler: any;
  onChangePropertyHandler?: any;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------------------- */

const PropertiesTableView = ({
  data,
  rowSelection,
  onChange,
  selectedPropertyGroup,
  onChangePropertyGroupHandler,
  onChangePropertyHandler,
}: Props) => {
  const onDeletePropertyGroupHandler = (itemId: number) => {
    onChange(
      data.filter((item: any) => item.id !== itemId),
      'propertyGroups',
    );
  };

  const onDeletePropertyHandler = (itemId: number) => {
    onChangePropertyGroupHandler(
      selectedPropertyGroup,
      'properties',
      selectedPropertyGroup.properties.filter(
        (item: any) => item.id !== itemId,
      ),
      selectedPropertyGroup.properties,
    );
  };

  const columns = [
    {
      title: <span className='small-heading-text'>{language.signature}</span>,
      dataIndex: 'signature',
      key: 'signature',
      width: '300px',
      render: (signature: any, record: any) => (
        <Input
          size='small'
          value={signature}
          onChange={(event: any) => {
            if (record.id === 0) {
              if (record.description !== undefined) {
                onChangePropertyHandler(
                  selectedPropertyGroup,
                  record.item,
                  'signature',
                  event.target.value,
                  data,
                );
              } else {
                onChangePropertyGroupHandler(
                  record.item,
                  'signature',
                  event.target.value,
                  data,
                );
              }
            }
          }}
        />
      ),
      className: 'column-item',
    },
    {
      title: <span className='small-heading-text'>{language.name}</span>,
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      render: (name: any, record: any) => (
        <Input
          size='small'
          value={name}
          onChange={(event: any) => {
            if (record.description !== undefined) {
              onChangePropertyHandler(
                selectedPropertyGroup,
                record.item,
                'name',
                event.target.value,
              );
            } else {
              onChangePropertyGroupHandler(
                record.item,
                'name',
                event.target.value,
                selectedPropertyGroup.properties,
              );
            }
          }}
        />
      ),
      className: 'column-item',
    },
    {
      title: <span className='small-heading-text'>{language.actions}</span>,
      key: 'action', // @todo: Add function for which of the items that is not possible to remove
      className: 'column-item',
      render: (record: any) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Popconfirm
            title={`${language.sure_to_delete_object}?`}
            onConfirm={() => {
              if (record.description !== undefined) {
                onDeletePropertyHandler(record.id);
              } else {
                onDeletePropertyGroupHandler(record.id);
              }
            }}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Table
      size='small'
      dataSource={data?.map((item: any) => ({
        key: item.id,
        item,
        ...item,
      }))}
      columns={columns}
      rowSelection={{ type: 'radio', ...rowSelection }}
    />
  );
};

export default PropertiesTableView;
