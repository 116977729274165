import { useEffect, useState } from 'react';
import { flattenDeep } from 'lodash';

// STYLES
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';

// TYPES
import { TTreeListData } from '../../../types/type.type';

type Props = {
  data: any[];
  onSelect: any;
  automaticallyExpand?: boolean;
};

const TreeListView = ({
  data,
  onSelect = () => {},
  automaticallyExpand,
}: Props) => {
  const getAllKeys = (data: any) => {
    // This function makes an array of keys
    const nestedKeys = data?.map((node: any) => {
      let childKeys: any = [];
      if (node.children) {
        childKeys = getAllKeys(node.children);
      }
      return [childKeys, node.key];
    });
    return flattenDeep(nestedKeys);
  };

  const [expandedKeys, setExpandedKeys] = useState<any>(getAllKeys(data));

  useEffect(() => {
    setExpandedKeys(getAllKeys(data));
  }, [data]);

  const onExpand = (newExpandedKeys: any) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(newExpandedKeys);
  };

  return (
    <Tree
      showLine={false}
      switcherIcon={<DownOutlined />}
      onSelect={(selectedValue: any) => onSelect(selectedValue[0])}
      expandedKeys={automaticallyExpand === true ? expandedKeys : ['']}
      onExpand={onExpand}
      treeData={data}
      style={{ marginTop: '12px' }}
    ></Tree>
  );
};

export default TreeListView;
