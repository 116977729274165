import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// i18N
import intl from '../../i18n/intl';

// STYLES
import './index.scss';
import {
  Input,
  InputNumber,
  Checkbox,
  Select,
  Dropdown,
  Button,
  Menu,
} from 'antd';
import {
  DownOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  EditFilled,
} from '@ant-design/icons';

// ACTIONS
import {
  fetchLimitationsFields,
  limitationsFieldsSelector,
} from '../../slices/field.slice';

// TYPES
import { EFieldType } from '../../types/field.type';
import { TTEObject } from '../../types/object.type';

// COMPONENTS
import { FromSecondsInputNumber } from '../FromSeconds/FromSecondsInputNumber';

const language: any = intl.messages;

// Render elements for field component
export const RenderElement = (
  type: EFieldType | string,
  value: any,
  property: string,
  fieldCategories: Array<any>,
  onChangeHandler: any,
) => {
  const dispatch = useDispatch();

  // Fetching limitations on input-lengths
  const limitations = useSelector(limitationsFieldsSelector);

  useEffect(() => {
    dispatch(fetchLimitationsFields());
  }, [dispatch]);

  if (
    type === EFieldType.TEXT ||
    type === EFieldType.SIGNATURE ||
    type === EFieldType.URL ||
    type === EFieldType.EMAIL ||
    type === EFieldType.NON_SEARCHABLE_TEXT ||
    type === EFieldType.TELEPHONE ||
    type === EFieldType.REFERENCE
  ) {
    return (
      <Input
        size='small'
        defaultValue={value}
        value={value}
        onChange={(event: any) => {
          if (
            new TextEncoder().encode(event.target.value).length >
            limitations.defaultValue
          ) {
            return;
          }
          onChangeHandler(event.target.value, property);
        }}
      />
    );
  } else if (type === EFieldType.NON_SEARCHABLE_REFERENCE) {
    return <Input key={value} size='small' value={value} disabled />;
  } else if (type === EFieldType.COMMENT) {
    return (
      <Input.TextArea
        size='small'
        rows={4}
        value={value}
        onChange={(event: any) => {
          if (
            new TextEncoder().encode(event.target.value).length >
            limitations.defaultValue
          ) {
            return;
          }
          onChangeHandler(event, property);
        }}
      />
    );
  } else if (type === EFieldType.INTEGER) {
    return (
      <InputNumber
        size='small'
        value={parseInt(value)}
        defaultValue={parseInt(value)}
        onChange={(value: any) => {
          if (
            new TextEncoder().encode(value).length > limitations.defaultValue
          ) {
            return;
          }
          onChangeHandler(value.toString(), property);
        }}
      />
    );
  } else if (type === EFieldType.LENGTH) {
    return FromSecondsInputNumber(value, onChangeHandler, property);
  } else if (type === EFieldType.CHECKBOX) {
    return (
      <Checkbox
        key={value}
        checked={value === '1'}
        onChange={(event: any) => {
          onChangeHandler(event.target.checked === true ? '1' : '0', property);
        }}
      />
    );
  } else if (type === EFieldType.CATEGORY) {
    return (
      <Select
        size='small'
        value={value}
        style={{ width: 150 }}
        onChange={(value: any) => {
          if (
            new TextEncoder().encode(value).length > limitations.defaultValue
          ) {
            return;
          }
          onChangeHandler(value, property);
        }}
      >
        {fieldCategories?.map((category: any) => (
          <Select.Option key={category} value={category}>
            {category}
          </Select.Option>
        ))}
      </Select>
    );
  }
};

/* ---------------------------------------------------------------------- */

// Render elements for showing field values on single object row in mass changes view
export const renderElementsSingleRow = (
  record: any,
  type: EFieldType,
  values: any,
  property: string,
  fieldId: number,
  fieldCategories: Array<any>,
  onChangeFieldValues: any,
) => {
  if (
    type === EFieldType.TEXT ||
    type === EFieldType.SIGNATURE ||
    type === EFieldType.URL ||
    type === EFieldType.EMAIL ||
    type === EFieldType.NON_SEARCHABLE_TEXT ||
    type === EFieldType.TELEPHONE
  ) {
    return (
      <>
        {values.map((value: any, index: number) => (
          <Input
            key={index}
            size='small'
            value={value}
            onChange={(event: any) =>
              onChangeFieldValues(
                [record.listedObject],
                event.target.value,
                index,
                fieldId,
              )
            }
          />
        ))}
      </>
    );
  } else if (type === EFieldType.NON_SEARCHABLE_REFERENCE) {
    return (
      <>
        {values.map((value: any, index: number) => (
          <Input
            key={index}
            size='small'
            value={value}
            disabled
            onChange={(event: any) =>
              onChangeFieldValues(
                [record.listedObject],
                event.target.value,
                index,
                fieldId,
              )
            }
          />
        ))}
      </>
    );
  } else if (type === EFieldType.COMMENT) {
    return (
      <>
        {values.map((value: any, index: number) => (
          <Input.TextArea
            className='textarea-box'
            key={index}
            size='small'
            rows={1}
            autoSize={{ minRows: 1, maxRows: 2 }}
            value={value}
            onChange={(event: any) =>
              onChangeFieldValues(
                [record.listedObject],
                event.target.value,
                index,
                fieldId,
              )
            }
          />
        ))}
      </>
    );
  } else if (type === EFieldType.INTEGER || type === EFieldType.LENGTH || type === EFieldType.REFERENCE) {
    return (
      <>
        {values.map((value: any, index: number) => (
          <InputNumber
            key={index}
            size='small'
            value={value}
            onChange={(value: any) =>
              onChangeFieldValues([record.listedObject], value, index, fieldId)
            }
          />
        ))}
      </>
    );
  } else if (type === EFieldType.CHECKBOX) {
    return (
      <>
        {values.map((value: any, index: number) => (
          <Checkbox
            key={index}
            checked={value}
            onChange={(event: any) =>
              onChangeFieldValues(
                [record.listedObject],
                event.target.checked,
                index,
                fieldId,
              )
            }
          />
        ))}
      </>
    );
  } else if (type === EFieldType.CATEGORY) {
    return (
      <>
        {values.map((value: any, index: number) => (
          <>
            <Select
              key={index}
              size='small'
              value={value}
              style={{ width: 300 }}
              className='multiple-category'
              onChange={(value: any) =>
                onChangeFieldValues(
                  [record.listedObject],
                  value,
                  index,
                  fieldId,
                )
              }
            >
              {fieldCategories.map((fieldCategory: any) => (
                <Select.Option key={fieldCategory} value={fieldCategory}>
                  {fieldCategory}
                </Select.Option>
              ))}
            </Select>
          </>
        ))}
      </>
    );
  }
};

type Props = {
  type: string;
  name: string;
  fieldId: number;
  fieldCategories: any[];
  editable: boolean;
  multiple: boolean;
  onChangeFieldValues: any;
  onHandleAdd: any;
  onHandleRemove: any;
  selectedObjects: TTEObject[];
  onAddCategoryModalOpen: any;
};

// Render elements for showing field values on heading row in mass changes view
export const RenderHeadingElement = ({
  type,
  name,
  fieldId,
  fieldCategories,
  editable,
  multiple,
  onChangeFieldValues,
  onHandleAdd,
  onHandleRemove,
  selectedObjects,
  onAddCategoryModalOpen,
}: Props) => {
  if (
    type === EFieldType.TEXT ||
    type === EFieldType.SIGNATURE ||
    type === EFieldType.URL ||
    type === EFieldType.NON_SEARCHABLE_TEXT
  ) {
    return (
      <Input
        key={Math.random()}
        value=''
        size='small'
        onChange={(event: any) =>
          onChangeFieldValues(selectedObjects, event.target.value, 0, fieldId)
        }
      />
    );
  } else if (type === EFieldType.EMAIL || type === EFieldType.TELEPHONE) {
    return (
      <div key={Math.random()}>
        <Input
          key={Math.random()}
          value=''
          size='small'
          onChange={(event: any) =>
            onChangeFieldValues(selectedObjects, event.target.value, 0, fieldId)
          }
        />
        <Button
          size='small'
          icon={<PlusSquareOutlined />}
          style={{ paddingRight: 10 }}
          className='dropdown-button'
        />
        <Button
          size='small'
          icon={<MinusSquareOutlined />}
          style={{ paddingRight: 10 }}
          className='dropdown-button'
        />
      </div>
    );
  } else if (type === EFieldType.NON_SEARCHABLE_REFERENCE) {
    return <Input key={Math.random()} disabled value={name} size='small' />;
  } else if (type === EFieldType.COMMENT) {
    return (
      <Input.TextArea
        className='textarea-box'
        key={Math.random()}
        size='small'
        rows={1}
        autoSize={{ minRows: 1, maxRows: 2 }}
        value=''
        onChange={(event: any) =>
          onChangeFieldValues(selectedObjects, event.target.value, 0, fieldId)
        }
      />
    );
  } else if (type === EFieldType.INTEGER || type === EFieldType.LENGTH || type === EFieldType.REFERENCE) {
    return (
      <InputNumber
        size='small'
        key={Math.random()}
        onChange={(value: any) =>
          onChangeFieldValues(selectedObjects, value, 0, fieldId)
        }
      />
    );
  } else if (type === EFieldType.CHECKBOX) {
    return (
      <Checkbox
        key={Math.random()}
        onChange={(event: any) =>
          onChangeFieldValues(selectedObjects, event.target.checked, 0, fieldId)
        }
      />
    );
  } else if (type === EFieldType.CATEGORY) {
    return (
      <div className='category-heading' key={Math.random()}>
        {multiple === true ? (
          <>
            <Dropdown
              key={Math.random()}
              overlay={
                <Menu
                  key={Math.random()}
                  onClick={(value: any) =>
                    onHandleAdd(selectedObjects, value.key, 'teFields', fieldId)
                  }
                >
                  {fieldCategories.map((fieldCategory: any) => (
                    <Menu.Item key={fieldCategory}>{fieldCategory}</Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button
                size='small'
                icon={<PlusSquareOutlined />}
                style={{ paddingRight: 10 }}
                className='dropdown-button'
              />
            </Dropdown>
            <Dropdown
              key={Math.random()}
              overlay={
                <Menu
                  key={Math.random()}
                  onClick={(value: any) =>
                    onHandleRemove(
                      selectedObjects,
                      value.key,
                      'teFields',
                      fieldId,
                    )
                  }
                >
                  {fieldCategories.map((fieldCategory: any) => (
                    <Menu.Item key={fieldCategory}>{fieldCategory}</Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button
                size='small'
                icon={<MinusSquareOutlined />}
                style={{ paddingRight: 10 }}
                className='dropdown-button'
              />
            </Dropdown>
          </>
        ) : (
          <Dropdown
            key={Math.random()}
            overlay={
              <Menu
                key={Math.random()}
                onClick={(value: any) =>
                  onChangeFieldValues(selectedObjects, value.key, 0, fieldId)
                }
              >
                {fieldCategories.map((fieldCategory: any) => (
                  <Menu.Item key={fieldCategory}>{fieldCategory}</Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button
              size='small'
              style={{
                paddingRight: 10,
                fontSize: '16px',
                color: '#0f1818',
              }}
            >
              {language.select}
              <DownOutlined />
            </Button>
          </Dropdown>
        )}
        {editable === true ? (
          <Button
            size='small'
            icon={<EditFilled />}
            onClick={() => onAddCategoryModalOpen(fieldId, fieldCategories)}
          />
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
