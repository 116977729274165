import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TEmailPreferences } from '../types/systempreferences.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  limitations: {},
  emailPreferences: {},
  updatedEmailPreferences: {},
  variables: {},
};

// Slice
const slice = createSlice({
  name: 'emailPreferences',
  initialState,
  reducers: {
    fetchEmailPreferencesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchEmailPreferencesSuccess: (state: any, { payload }) => {
      state.emailPreferences = payload;
      state.updatedEmailPreferences = payload;
      finishedLoadingSuccess(state);
    },
    fetchEmailPreferencesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    fetchLimitationsEmailPreferencesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchVariablesSuccess: (state: any, { payload }) => {
      state.variables = payload;
      finishedLoadingSuccess(state);
    },
    setsUpdatedEmailPreferences: (state: any, { payload }) => {
      state.updatedEmailPreferences = payload;
    },
    updateEmailPreferencesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateEmailPreferencesSuccess: (state: any, { payload }) => {
      state.emailPreferences = payload;
      state.updateEmailPreferences = payload;
      finishedLoadingSuccess(state);
    },
    updateEmailPreferencesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const emailPreferencesLoading = (state: any) =>
  state.emailPreferences.loading;
export const emailPreferencesSelector = (state: any) =>
  state.emailPreferences.emailPreferences as TEmailPreferences;
export const updatedEmailPreferencesSelector = (state: any) =>
  state.emailPreferences.updatedEmailPreferences;
export const limitationsEmailPreferencesSelector = (state: any) =>
  state.emailPreferences.limitations;
export const variablesEmailSelector = (state: any) =>
  state.emailPreferences.variables;

// Actions
export const {
  fetchEmailPreferencesRequest,
  fetchEmailPreferencesSuccess,
  fetchEmailPreferencesFailure,
  fetchLimitationsEmailPreferencesSuccess,
  fetchVariablesSuccess,
  setsUpdatedEmailPreferences,
  updateEmailPreferencesRequest,
  updateEmailPreferencesSuccess,
  updateEmailPreferencesFailure,
} = slice.actions;

export const fetchEmailPreferences = () => async (dispatch: any) => {
  try {
    dispatch(fetchEmailPreferencesRequest());
    const emailPreferences = await api.get({
      endpoint: `/email-preferences`,
    });
    dispatch(fetchEmailPreferencesSuccess(emailPreferences));
  } catch (e) {
    dispatch(fetchEmailPreferencesFailure());
    return console.error(e);
  }
};

export const fetchVariablesForEmails = () => async (dispatch: any) => {
  try {
    dispatch(fetchEmailPreferencesRequest());
    const variables = await api.get({
      endpoint: `/email-preferences/variables`,
    });
    dispatch(fetchVariablesSuccess(variables));
  } catch (e) {
    dispatch(fetchEmailPreferencesFailure());
    return console.error(e);
  }
};

export const fetchLimitationsForEmailPreferences =
  () => async (dispatch: any) => {
    try {
      dispatch(fetchEmailPreferencesRequest());
      const limitationsEmailPreferences = await api.get({
        endpoint: `/email-preferences/limitations`,
      });
      dispatch(
        fetchLimitationsEmailPreferencesSuccess(limitationsEmailPreferences),
      );
    } catch (e) {
      dispatch(fetchEmailPreferencesFailure());
      return console.error(e);
    }
  };

export const setUpdatedEmailPreferences =
  (emailPreferences: TEmailPreferences) => async (dispatch: any) => {
    dispatch(setsUpdatedEmailPreferences(emailPreferences));
  };

export const updateEmailPreferences =
  (emailPreferences: TEmailPreferences) => async (dispatch: any) => {
    try {
      dispatch(updateEmailPreferencesRequest());
      const { ...emailPreferencesBody } = emailPreferences;
      const response = await api.patch({
        endpoint: `/email-preferences`,
        data: { ...emailPreferencesBody },
      });
      dispatch(updateEmailPreferencesSuccess(response));
    } catch (e: any) {
      dispatch(updateEmailPreferencesFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
