import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18n
import intl from '../../../i18n/intl';

// STYLES
import { Form } from 'antd';

// COMPONENTS
import ListView from '../../../components/List';
import NormalListView from '../../../components/List/NormalListView';
import SectionHeader from '../../../components/SectionHeader';
import {
  RelationTypeGeneralSettings,
  RelationTypesItems,
  RelationTypeReservationTemplates,
} from '../../../components/RelationTypeComponent';

// TYPES
import { TRelationType } from '../../../types/relation.type';

// ACTIONS
import {
  fetchRelationTypes,
  relationTypesSelector,
  createRelationType,
  deleteRelationType,
  updateRelationType,
  createEmptyRelationType,
  copyRelationType,
  selectedRelationTypeSelector,
  setSelectedRelationType,
  fetchLimitationsRelationTypesSelector,
  fetchLimitationsRelationTypes,
  relationTypeErrorMessageSelector,
} from '../../../slices/relationType.slice';

// UTILS
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const RelationTypesPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const relationTypes: TRelationType[] = useSelector(relationTypesSelector);
  const limitations = useSelector(fetchLimitationsRelationTypesSelector);
  const selectedRelationType: TRelationType = useSelector(
    selectedRelationTypeSelector,
  );
  const errorMessage = useSelector(relationTypeErrorMessageSelector);

  const listData = relationTypes.map((relationType: any) => ({
    listLabel: relationType.name,
    value: relationType.id,
  }));

  const onRelationTypeSelected = (selectedValue: any) => {
    const currentRelationType = relationTypes.find(
      (relationType) => relationType.id === selectedValue,
    );
    if (currentRelationType) {
      dispatch(setSelectedRelationType(currentRelationType));
    }
  };

  // State for setting error messages
  const [errorMessageState, setErrorMessageState] = useState(false);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/relation-types`,
          label: `${language.relation_types}`,
        },
        {
          path: `/${selectedRelationType.id}`,
          label: `${selectedRelationType.name}`,
        },
      ]),
    );
    setErrorMessageState(false);
  }, [
    dispatch,
    customerSignature,
    selectedRelationType.id,
    selectedRelationType.name,
  ]);

  useEffect(() => {
    dispatch(fetchRelationTypes());
    dispatch(fetchLimitationsRelationTypes());
  }, [dispatch]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabled(selectedRelationType, relationTypes));
  }, [relationTypes, selectedRelationType]);

  const [form] = Form.useForm();

  // EVENT HANDLERS
  // Adds new relation types
  const onPlusButton = () => {
    dispatch(createEmptyRelationType());
  };

  // Deletes choosen relation type
  const onDeleteButton = () => {
    dispatch(deleteRelationType(selectedRelationType));
  };

  // Save changes function
  const onSaveChangesRelationTypeButton = () => {
    if (selectedRelationType.id === 0) {
      dispatch(createRelationType(selectedRelationType));
      if (errorMessage === {}) {
        setErrorMessageState(false);
      } else {
        setErrorMessageState(true);
      }
    } else {
      dispatch(updateRelationType(selectedRelationType));
      if (errorMessage === {}) {
        setErrorMessageState(false);
      } else {
        setErrorMessageState(true);
      }
    }
  };

  const onHandleChange = (property: string, value: any) => {
    dispatch(
      setSelectedRelationType({
        ...selectedRelationType,
        [property]: value,
      }),
    );
    form.setFieldsValue({
      relationType: value,
    });
  };

  // Discard changes function
  const onDiscardChanges = () => {
    onRelationTypeSelected(selectedRelationType.id);
  };

  // Copy relation type
  const onCopy = () => {
    dispatch(copyRelationType(selectedRelationType));
  };

  return (
    <>
      <ListView
        sectionHeading={language.relation_types}
        number={listData.length}
        onSelect={onPlusButton}
      >
        <NormalListView
          data={listData}
          onSelect={onRelationTypeSelected}
          onDelete={onDeleteButton}
          onCopy={onCopy}
          selectedItemId={selectedRelationType.id}
        />
      </ListView>
      <div className='inner-content--wrapper'>
        <SectionHeader
          listChoice={
            selectedRelationType.name
              ? `${selectedRelationType.name}`
              : `${language.relation_type}`
          }
          changeHandler={onSaveChangesRelationTypeButton}
          discardHandler={onDiscardChanges}
          displayProp={false}
          history={false}
          isDisabled={saveDisabled}
          error={
            errorMessageState === true && errorMessage.message !== undefined
              ? `${errorMessage.message}`
              : null
          }
        />
        <Form layout='vertical' form={form}>
          <RelationTypeGeneralSettings
            relationType={selectedRelationType}
            onChange={onHandleChange}
            limitations={limitations}
          />
          <RelationTypesItems
            relationTypesItems={selectedRelationType.relationTypes}
            onChange={onHandleChange}
          />
          <RelationTypeReservationTemplates
            reservationTemplatesConnections={
              selectedRelationType.reservationTemplates
            }
            onChange={onHandleChange}
          />
        </Form>
      </div>
    </>
  );
};

export default RelationTypesPage;
