import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// ACTIONS
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form, Menu } from 'antd';

// TYPES
import {
  TReservationMode,
  TReservationTemplate,
} from '../../../types/reservation.type';

// ACTIONS
import {
  fetchReservationTemplates,
  reservationTemplatesSelector,
  createReservationTemplate,
  deleteReservationTemplate,
  updateReservationTemplate,
  createEmptyReservationTemplate,
  copyReservationTemplate,
  selectedReservationTemplateSelector,
  setSelectedReservationTemplate,
  limitationsReservationTemplatesSelector,
  fetchLimitationsForReservationTemplates,
  reservationTemplateErrorMessageSelector,
  advancedReservationTemplatesSelector,
  fetchAdvancedSettingsForReservationTemplate,
} from '../../../slices/reservationTemplate.slice';
import {
  fetchReservationModes,
  reservationModesSelector,
} from '../../../slices/reservationMode.slice';

// UTILS
import { isSavedDisabled } from '../../../utils/isSavedDisabled';
import { timeConverter } from '../../../utils/timeConverter';

// COMPONENTS
import { ListSearch } from '../../../components/ListSearch';
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import NormalListView from '../../../components/List/NormalListView';
import {
  ReservationTemplatesGeneralSettings,
  ReservationTemplatesTypes,
  ReservationTemplatesConnections,
  ReservationTemplatesMoveReservations,
  MoveReservationsToTrash,
} from '../../../components/ReservationTemplatesComponents';
import Documentation from '../../../components/Documentation';
import Advanced, { ActionSettings } from '../../../components/Advanced';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const ReservationTemplatesPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const reservationTemplates: TReservationTemplate[] = useSelector(
    reservationTemplatesSelector,
  );
  const limitations = useSelector(limitationsReservationTemplatesSelector);
  const selectedReservationTemplate: TReservationTemplate = useSelector(
    selectedReservationTemplateSelector,
  );
  const errorMessage = useSelector(reservationTemplateErrorMessageSelector);
  const advancedSettings: ActionSettings[] = useSelector(
    advancedReservationTemplatesSelector,
  );

  const onReservationTemplateSelected = (selectedValue: any) => {
    const currentReservationTemplate = reservationTemplates.find(
      (reservationtemplate: any) => reservationtemplate.id === selectedValue,
    );
    if (currentReservationTemplate) {
      dispatch(setSelectedReservationTemplate(currentReservationTemplate));
    }
  };

  const reservationModes: TReservationMode[] = useSelector(
    reservationModesSelector,
  );

  // State for setting error messages
  const [errorMessageState, setErrorMessageState] = useState(false);

  const [templateTypeQuery, setTemplateTypeQuery] =
    useState<string>('reservation');
  const [templateTypeOption, setTemplateTypeOption] =
    useState<string>('templateType');
  const [searchTextQuery, setSearchTextQuery] = useState<string>();
  const [searchTextOption, setSearchTextOption] = useState<string>();
  const [activeQuery, setActiveQuery] = useState<string>();
  const [activeOption, setActiveOption] = useState<string>();
  const [reservationModeQuery, setReservationModeQuery] = useState<string>();
  const [reservationModeOption, setReservationModeOption] = useState<string>();

  // When searching with optional text string
  const onSearch = (value: string) => {
    setSearchTextOption('searchText');
    setSearchTextQuery(value);
  };

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/reservation-templates`,
          label: `${language.reservation_templates}`,
        },
        {
          path: `/system/reservation-templates`,
          label: `${selectedReservationTemplate.name}`,
        },
      ]),
    );
    setErrorMessageState(false);
  }, [
    dispatch,
    customerSignature,
    selectedReservationTemplate.id,
    selectedReservationTemplate?.name,
  ]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(
      isSavedDisabled(selectedReservationTemplate, reservationTemplates),
    );
  }, [reservationTemplates, selectedReservationTemplate]);

  useEffect(() => {
    dispatch(
      fetchReservationTemplates(
        templateTypeOption,
        templateTypeQuery,
        reservationModeOption,
        reservationModeQuery,
        activeOption,
        activeQuery,
        searchTextOption,
        searchTextQuery,
      ),
    );
    dispatch(fetchLimitationsForReservationTemplates());
    dispatch(
      fetchReservationModes(
        undefined,
        undefined,
        templateTypeOption,
        templateTypeQuery,
      ),
    );
  }, [
    dispatch,
    templateTypeQuery,
    templateTypeOption,
    activeQuery,
    activeOption,
    reservationModeQuery,
    reservationModeOption,
    searchTextQuery,
    searchTextOption,
  ]);

  useEffect(() => {
    if (selectedReservationTemplate && selectedReservationTemplate.id !== 0) {
      dispatch(
        fetchAdvancedSettingsForReservationTemplate(
          selectedReservationTemplate,
        ),
      );
    }
  }, [dispatch, selectedReservationTemplate]);

  const [form] = Form.useForm();

  const listData = reservationTemplates.map((reservationtemplate: any) => ({
    listLabel: reservationtemplate.name,
    value: reservationtemplate.id,
  }));

  // EVENT HANDLERS
  // New reservation template
  const onPlusButton = () => {
    dispatch(createEmptyReservationTemplate());
  };

  // Deletes choosen reservation template
  const onDeleteButton = () => {
    dispatch(deleteReservationTemplate(selectedReservationTemplate));
  };

  // Save changes function
  const onSaveChangesReservationTemplateButton = () => {
    if (selectedReservationTemplate.id === 0) {
      dispatch(createReservationTemplate(selectedReservationTemplate));
      if (errorMessage === {}) {
        setErrorMessageState(false);
      } else {
        setErrorMessageState(true);
      }
    } else {
      dispatch(updateReservationTemplate(selectedReservationTemplate));
      if (errorMessage === {}) {
        setErrorMessageState(false);
      } else {
        setErrorMessageState(true);
      }
    }
  };

  const onHandleChange = (property: string, value: string) => {
    dispatch(
      setSelectedReservationTemplate({
        ...selectedReservationTemplate,
        [property]: value,
      }),
    );
    form.setFieldsValue({
      defaultStatus: value,
      privateRole: value,
    });
  };

  // Discard changes function
  const onDiscard = () => {
    onReservationTemplateSelected(selectedReservationTemplate.id);
  };

  // Copy reservation template
  const onCopy = () => {
    dispatch(copyReservationTemplate(selectedReservationTemplate));
  };

  const menu = (
    <Menu
      onClick={(event: any) => {
        if (event.domEvent.target.id === 'templateType') {
          setTemplateTypeOption(event.domEvent.target.id);
          setTemplateTypeQuery(event.key);
          setReservationModeOption(event.domEvent.target.id);
          setReservationModeQuery(undefined);
        }
        if (event.domEvent.target.id === 'reservationMode') {
          if (event.key === 'all') {
            setReservationModeOption(event.domEvent.target.id);
            setReservationModeQuery(undefined);
          } else {
            setReservationModeOption(event.domEvent.target.id);
            setReservationModeQuery(event.key);
          }
        }
        if (event.domEvent.target.id === 'active') {
          setActiveOption(event.domEvent.target.id);
          setActiveQuery(event.key);
        }
      }}
      className='list-search--menu'
    >
      <Menu.SubMenu title={language.filter_on_template_type}>
        <Menu.Item
          key='reservation'
          id='templateType'
          className='list-search--menu-item'
        >
          {language.reservation}
        </Menu.Item>
        <Menu.Item
          key='availability'
          id='templateType'
          className='list-search--menu-item'
        >
          {language.availability}
        </Menu.Item>
        <Menu.Item
          key='infoReservation'
          id='templateType'
          className='list-search--menu-item'
        >
          {language.infoReservation}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title={language.filter_on_active}>
        <Menu.Item key='active' id='active' className='list-search--menu-item'>
          {language.active}
        </Menu.Item>
        <Menu.Item
          key='inactive'
          id='active'
          className='list-search--menu-item'
        >
          {language.inactive}
        </Menu.Item>
        <Menu.Item key='all' id='active' className='list-search--menu-item'>
          {language.all}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title={language.reservation_mode}>
        <Menu.Item
          key='all'
          id='reservationMode'
          className='list-search--menu-item'
        >
          {language.all}
        </Menu.Item>
        {reservationModes.map((reservationMode: TReservationMode) => (
          <Menu.Item
            key={reservationMode.id}
            id='reservationMode'
            className='list-search--menu-item'
          >
            {reservationMode.name}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    </Menu>
  );

  if (selectedReservationTemplate) {
    return (
      <>
        <ListView
          sectionHeading={language.reservation_templates}
          number={listData.length}
          onSelect={onPlusButton}
        >
          <ListSearch onSearch={onSearch} menu={menu} />
          <NormalListView
            data={listData}
            onSelect={onReservationTemplateSelected}
            onDelete={onDeleteButton}
            onCopy={onCopy}
            selectedItemId={selectedReservationTemplate.id}
          />
        </ListView>
        <div className='inner-content--wrapper'>
          <SectionHeader
            listChoice={
              selectedReservationTemplate.name
                ? `${selectedReservationTemplate.name}`
                : `${language.reservation_template}`
            }
            modifiedDate={
              selectedReservationTemplate.history?.length > 0
                ? timeConverter(
                    selectedReservationTemplate?.history[0]?.modified,
                  )
                : ''
            }
            modifiedBy={
              selectedReservationTemplate.history?.length > 0
                ? selectedReservationTemplate?.history[0]?.modifiedBy
                : ''
            }
            changeHandler={onSaveChangesReservationTemplateButton}
            discardHandler={onDiscard}
            displayProp={false}
            history={selectedReservationTemplate?.history}
            isDisabled={saveDisabled}
            error={errorMessageState === true ? `${errorMessage}` : null}
          />
          <Form layout='vertical' form={form}>
            <Documentation
              onChange={onHandleChange}
              documentation={selectedReservationTemplate.documentation}
              limitationValue={limitations.documentation}
            />
            <ReservationTemplatesGeneralSettings
              reservationTemplate={selectedReservationTemplate}
              onChange={onHandleChange}
              limitations={limitations.name}
            />
            <ReservationTemplatesTypes
              templateTypes={selectedReservationTemplate.types}
              typeCombinations={selectedReservationTemplate.typeCombinations}
              onChange={onHandleChange}
              limitations={limitations['object.max']}
            />
            <ReservationTemplatesConnections
              relationTypesConnections={
                selectedReservationTemplate.relationTypes
              }
              fieldsConnections={selectedReservationTemplate.fields}
              reservationModesConnections={
                selectedReservationTemplate.reservationModes
              }
              organizationsConnections={
                selectedReservationTemplate.organizations
              }
              onChange={onHandleChange}
            />
            <ReservationTemplatesMoveReservations
              reservationTemplate={selectedReservationTemplate}
              reservationTemplates={reservationTemplates}
            />
            <MoveReservationsToTrash
              reservationTemplate={selectedReservationTemplate}
            />
            <Advanced
              options={advancedSettings}
              selectedUrl={'reservation-templates'}
              selectedId={selectedReservationTemplate.id}
            />
          </Form>
        </div>
      </>
    );
  }
  return null;
};

export default ReservationTemplatesPage;
