let localeFormat: string = 'sv-SE';
const timeZone: string = 'UTC'; // @todo: Do we need to change this to a let based on users prefs as well?

// Finding the correct localeFormat based on set country and language for the user
// @todo: Adjust this to act based on the users' country, when we have it from the rest-api
const findLocaleFormat = (country: string, language: string) => {
  localeFormat = `${language}-${country}`;
};

export const timeConverter = (unixTimestamp: number | null) => {
  if (unixTimestamp === null) {
    return null;
  }
  const a = new Date(unixTimestamp * 1000);
  const dateObject: string =
    a.toLocaleString(/* localeFormat, {
    timeZone: timeZone,
  } */);
  return dateObject;
};

export const timeConverterDate = (unixTimestamp: number | null) => {
  if (unixTimestamp === null) {
    return null;
  }
  const a = new Date(unixTimestamp * 1000);
  const dateObject: string = a.toLocaleString(localeFormat);
  return dateObject;
};
