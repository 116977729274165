import React, { useState } from 'react';

import { useSelector } from 'react-redux';

// i18N
import intl from '../../../i18n/intl';

// REDUX

import { organizationsSelector } from '../../../slices/organization.slice';

// TYPES
import { TOrganizationTreeNode } from '../../../types/organization.type';
import { APIProperty, TAPIPropertyGroup } from '../../../types/tapi.type';

// STYLES
import { Button, Checkbox, Transfer, Divider } from 'antd';
import { LockFilled, UnlockFilled } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import PropertiesTableView from '../../List/TableView';

const language: any = intl.messages;

type Props = {
  properties: any;
  onChangePropertyGroupHandler: any;
  currentPropertyGroup: any;
};

/* ------------------------------------------------------------------------- */

export const PropertyComponent = ({
  properties,
  onChangePropertyGroupHandler,
  currentPropertyGroup,
}: Props) => {
  // Function for selecting property
  const [selectedProperty, setSelectedProperty] = useState<any>();
  const rowSelectionProperties: any = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectedProperty(selectedRows[0].id);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
  };

  const onChangePropertyHandler = (
    propertyGroup: TAPIPropertyGroup,
    item: any,
    property: any,
    value: any,
  ) => {
    const index = properties.indexOf(item || currentProperty);
    const updatedItem = Object.assign({}, item || currentProperty);
    updatedItem[property] = value;
    const newItems: any = [...properties];
    newItems.splice(index, 1, updatedItem);
    onChangePropertyGroupHandler(
      propertyGroup,
      'properties',
      newItems,
      properties,
    );
  };

  const addProperty = () => {
    const newProperties: any = [...properties];
    newProperties.push(APIProperty.createEmpty());
    onChangePropertyGroupHandler(
      currentPropertyGroup,
      'properties',
      newProperties,
    );
  };

  const currentProperty = currentPropertyGroup?.properties.find(
    (property: any) => property.id === selectedProperty,
  );

  // Transfer element for organizations:
  // Fetching all available organizations
  const organizations: TOrganizationTreeNode[] = useSelector(
    organizationsSelector,
  );

  // Data with all available organizations
  const dataSourceOrganizations = organizations.map((org: any) => ({
    key: String(org.id),
    title: org.name,
    description: org.description,
  }));

  const targetKeysOrganizations = currentProperty?.organizations?.map(
    (selectedOrganization: any) => String(selectedOrganization),
  );

  const [selectedKeysOrganizations, setSelectedKeysOrganizations] = useState<
    any[]
  >([]);

  const onSelectionOrganizations = (
    sourceSelectedKeys: any,
    targetSelectedKeys: any,
  ) => {
    setSelectedKeysOrganizations([
      ...sourceSelectedKeys,
      ...targetSelectedKeys,
    ]);
  };

  const onSelectionChangeOrganizations = (nextTargetKeys: any) => {
    onChangePropertyHandler(
      currentPropertyGroup,
      currentProperty,
      'organizations',
      nextTargetKeys.map((key: string) => parseInt(key)),
    );
  };

  // State for det lock-button
  const [lockedField, setFieldLocked] = useState(true);

  return (
    <>
      <Divider />
      <p className='small-heading-text'>{language.properties}</p>
      <Button
        size='small'
        style={{ marginBottom: '8px' }}
        onClick={addProperty}
      >
        Add property
      </Button>
      <PropertiesTableView
        data={properties}
        rowSelection={rowSelectionProperties}
        selectedPropertyGroup={currentPropertyGroup}
        onChangePropertyGroupHandler={onChangePropertyGroupHandler}
        onChangePropertyHandler={onChangePropertyHandler}
      />

      <Divider />
      {currentProperty ? (
        <>
          <p className='small-heading-text' style={{ fontStyle: 'italic' }}>
            {currentProperty?.name}
          </p>
          <Checkbox
            checked={currentProperty?.isActive}
            onChange={(event: any) =>
              onChangePropertyHandler(
                currentPropertyGroup,
                currentProperty,
                'isActive',
                event.target.checked,
              )
            }
          >
            {language.active}
          </Checkbox>
          <p
            className='small-heading-text'
            style={{ marginTop: '16px', marginBottom: 0 }}
          >
            {language.organizations}
          </p>
          <Transfer
            dataSource={dataSourceOrganizations}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeysOrganizations}
            selectedKeys={selectedKeysOrganizations}
            onChange={onSelectionChangeOrganizations}
            onSelectChange={onSelectionOrganizations}
            render={(item) => item.title}
          />
          <p
            className='small-heading-text'
            style={{ marginBottom: 0, marginTop: '16px' }}
          >
            {language.description}
          </p>
          <TextArea
            value={currentProperty?.description}
            onChange={(event: any) =>
              onChangePropertyHandler(
                currentPropertyGroup,
                currentProperty,
                'description',
                event.target.value,
              )
            }
          />
          <div
            style={{
              display: 'flex',
              marginTop: '16px',
              alignItems: 'center',
            }}
          >
            <p
              className='small-heading-text'
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              {language.properties}
            </p>
            <Button
              icon={lockedField === true ? <LockFilled /> : <UnlockFilled />}
              style={{ border: 'none', boxShadow: 'none' }}
              onClick={() => setFieldLocked(lockedField !== true)}
            />
          </div>
          <TextArea
            value={currentProperty?.properties}
            disabled={lockedField === true}
            onChange={(event: any) =>
              onChangePropertyHandler(
                currentPropertyGroup,
                currentProperty,
                'properties',
                event.target.value,
              )
            }
          />
        </>
      ) : null}
    </>
  );
};
