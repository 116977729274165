import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TUserPreferences } from '../types/systempreferences.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  limitations: {},
  userPreferences: {},
  updatedUserPreferences: {},
};

// Slice
const slice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    fetchUserPreferencesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchUserPreferencesSuccess: (state: any, { payload }) => {
      state.userPreferences = payload;
      state.updatedUserPreferences = payload;
      finishedLoadingSuccess(state);
    },
    fetchUserPreferencesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    fetchLimitationsUserPreferencesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    setsUpdatedUserPreferences: (state: any, { payload }) => {
      state.updatedUserPreferences = payload;
    },
    updateUserPreferencesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateUserPreferencesSuccess: (state: any, { payload }) => {
      state.userPreferences = payload;
      state.updatedUserPreferences = payload;
      finishedLoadingSuccess(state);
    },
    updateUserPreferencesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const userPreferencesLoading = (state: any) =>
  state.userPreferences.loading;
export const userPreferencesSelector = (state: any) =>
  state.userPreferences.userPreferences as TUserPreferences;
export const updatedUserPreferencesSelector = (state: any) =>
  state.userPreferences.updatedUserPreferences;
export const limitationsUserPreferencesSelector = (state: any) =>
  state.userPreferences.limitations;

// Actions
export const {
  fetchUserPreferencesRequest,
  fetchUserPreferencesSuccess,
  fetchUserPreferencesFailure,
  fetchLimitationsUserPreferencesSuccess,
  setsUpdatedUserPreferences,
  updateUserPreferencesRequest,
  updateUserPreferencesSuccess,
  updateUserPreferencesFailure,
} = slice.actions;

export const fetchUserPreferences = () => async (dispatch: any) => {
  try {
    dispatch(fetchUserPreferencesRequest());
    const userPreferences = await api.get({
      endpoint: `/user-preferences`,
    });
    dispatch(fetchUserPreferencesSuccess(userPreferences));
  } catch (e) {
    dispatch(fetchUserPreferencesFailure());
    return console.error(e);
  }
};

export const fetchLimitationsUserPreferences = () => async (dispatch: any) => {
  try {
    dispatch(fetchUserPreferencesRequest());
    const limitationsUserPreferences = await api.get({
      endpoint: `/user-preferences/limitations`,
    });
    dispatch(
      fetchLimitationsUserPreferencesSuccess(limitationsUserPreferences),
    );
  } catch (e) {
    dispatch(fetchUserPreferencesFailure());
    return console.error(e);
  }
};

export const setUpdatedUserPreferences =
  (userPreferences: TUserPreferences) => async (dispatch: any) => {
    dispatch(setsUpdatedUserPreferences(userPreferences));
  };

export const updateUserPreferences =
  (userPreferences: TUserPreferences) => async (dispatch: any) => {
    try {
      dispatch(updateUserPreferencesRequest());
      const { ...userPreferencesBody } = userPreferences;
      const response = await api.patch({
        endpoint: `/user-preferences`,
        data: { ...userPreferencesBody },
      });
      dispatch(updateUserPreferencesSuccess(response));
    } catch (e: any) {
      dispatch(updateUserPreferencesFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
