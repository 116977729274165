import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

// SERVICES
import TokenService from '../services/token.service';

// HOOKS
import { useQuery } from './useQuery';

// REDUX
import {
  signedInWithRightCustomerSignature,
  fetchInitialData,
  onFetchTokenSuccess,
} from '../slices/auth.slice';

export enum AuthStatus {
  AUTHENTICATED = 'AUTHENTICATED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
}

const tokenService = TokenService.getService();

export const useAuth = (
  verifyCustomerSignature = false,
) /* : AuthStatus  */ => {
  const query: URLSearchParams = useQuery();
  const customerSignatureFromQuery: string | null =
    query.get('customerSignature');

  const dispatch = useDispatch();
  /**
   * Validate if signed from redux store
   */
  const isSignedIn = useSelector(
    signedInWithRightCustomerSignature(
      customerSignatureFromQuery,
      verifyCustomerSignature,
    ),
  );

  /**
   * @todo check more than if not just null
   */
  if (isSignedIn) return AuthStatus.AUTHENTICATED;

  // Get token from the token service
  const token = tokenService.getToken();

  // If no token, return NOT_AUTHENTICATED
  if (!token) return AuthStatus.NOT_AUTHENTICATED;

  // If we have token, validate it hasn't expired
  /**
   * @todo type tokenContent
   */
  const tokenContent: any = jwtDecode(token);

  /**
   * Validate if:
   * 1) we don't have tokenContent.exp
   * 2) tokenContent.exp is before now
   * 3) Customer signature is same as customer signature on local storage token
   */
  if (
    !tokenContent ||
    !tokenContent.exp ||
    moment.unix(tokenContent.exp).isBefore(moment.utc()) ||
    !tokenContent.cus
  )
    return AuthStatus.NOT_AUTHENTICATED;

  /*   if (tokenContent.pem !== 'super') {
    return AuthStatus.NOT_AUTHENTICATED;
  } */
  /**
   * Set token content in redux and sign in user
   */
  dispatch(onFetchTokenSuccess(tokenContent));
  dispatch(fetchInitialData());
  return AuthStatus.AUTHENTICATED;
};
