import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { Period, TPeriod } from '../types/period.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedPeriod: Period.createEmpty(),
  allPeriods: [],
  limitations: {},
  errorMessage: {},
};

// Slice
const slice = createSlice({
  name: 'periods',
  initialState,
  reducers: {
    fetchPeriodsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchPeriodsSuccess: (state: any, { payload }) => {
      if (state.selectedPeriod.id > 0) {
        state.selectedPeriod =
          payload.find(
            (period: any) => period.id === state.selectedPeriod.id,
          ) || Period.createEmpty();
      }
      state.allPeriods = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsPeriodsSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchPeriodsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createPeriodRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createPeriodSuccess: (state: any, { payload }) => {
      state.allPeriods.push(payload);
      state.selectedPeriod = payload;
      finishedLoadingSuccess(state);
    },
    createPeriodFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
    setCurrentPeriod: (state: any, { payload }) => {
      state.selectedPeriod = payload;
      state.errorMessage = '';
    },
    deletePeriodRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deletePeriodSuccess: (state: any, payload: any) => {
      state.allPeriods = state.allPeriods.filter(
        (period: TPeriod) => period.id !== payload.payload.id,
      );
      state.selectedPeriod = Period.createEmpty();
      finishedLoadingSuccess(state);
    },
    deletePeriodFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
    updatePeriodRequest: (state: any) => {
      state.errorMessage = '';
      isLoadingRequest(state);
    },
    updatePeriodSuccess: (state: any, { payload }) => {
      state.errorMessage = '';
      const index = state.allPeriods.findIndex(
        (period: TPeriod) => period.id === payload.id,
      );
      state.allPeriods[index] = payload;
      finishedLoadingSuccess(state);
    },
    updatePeriodFailure: (state: any, { payload }) => {
      state.errorMessage = payload;
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const periodsLoading = (state: any) => state.periods.loading;
export const periodsSelector = (state: any) =>
  state.periods.allPeriods as TPeriod[];
export const periodSelectorCreateNew = () => () => {
  return Period.createEmpty();
};
export const selectedPeriodSelector = (state: any) => {
  return state.periods.selectedPeriod;
};
export const fetchLimitationsPeriodsSelector = (state: any) =>
  state.periods.limitations;
export const periodErrorMessageSelector = (state: any) =>
  state.periods.errorMessage;

// Actions
export const {
  fetchPeriodsRequest,
  fetchPeriodsSuccess,
  fetchPeriodsFailure,
  createPeriodRequest,
  createPeriodSuccess,
  fetchLimitationsPeriodsSuccess,
  createPeriodFailure,
  setCurrentPeriod,
  deletePeriodRequest,
  deletePeriodSuccess,
  deletePeriodFailure,
  updatePeriodRequest,
  updatePeriodSuccess,
  updatePeriodFailure,
} = slice.actions;

export const fetchPeriods =
  (
    showHiddenOption?: string,
    showHiddenQuery?: boolean,
    rootPeriodsOption?: string,
    rootPeriodsQuery?: boolean | undefined,
  ) =>
  async (dispatch: any) => {
    try {
      dispatch(fetchPeriodsRequest());
      let url = `/periods`;
      if (showHiddenQuery !== undefined && rootPeriodsQuery === undefined) {
        url = `/periods?${showHiddenOption}=${showHiddenQuery}`;
      } else if (
        showHiddenQuery !== undefined &&
        rootPeriodsQuery !== undefined
      ) {
        url = `/periods?${showHiddenOption}=${showHiddenQuery}&${rootPeriodsOption}=${rootPeriodsQuery}`;
      } else if (
        showHiddenQuery === undefined &&
        rootPeriodsQuery !== undefined
      ) {
        url = `/periods?${rootPeriodsOption}=${rootPeriodsQuery}`;
      }
      const periods = await api.get({ endpoint: url });
      dispatch(fetchPeriodsSuccess(periods));
    } catch (e) {
      dispatch(fetchPeriodsFailure());
      return console.error(e);
    }
  };

export const fetchLimitationsPeriods = () => async (dispatch: any) => {
  try {
    dispatch(fetchPeriodsRequest());
    const limitationsPeriods = await api.get({
      endpoint: `/periods/limitations`,
    });
    dispatch(fetchLimitationsPeriodsSuccess(limitationsPeriods));
  } catch (e) {
    dispatch(fetchPeriodsFailure());
    return console.error(e);
  }
};

export const createPeriod = (period: TPeriod) => async (dispatch: any) => {
  try {
    dispatch(createPeriodRequest());
    const { id: _, ...periodBody } = period;
    const response = await api.post({
      endpoint: `/periods`,
      data: periodBody,
    });
    dispatch(createPeriodSuccess(response));
    dispatch(fetchPeriods());
  } catch (e: any) {
    dispatch(createPeriodFailure(e.response.data.message));
    return console.error(e);
  }
};

export const createEmptyPeriod = () => async (dispatch: any) => {
  try {
    dispatch(createPeriodRequest());
    const response = Period.createEmpty();
    dispatch(createPeriodSuccess(response));
  } catch (e: any) {
    dispatch(createPeriodFailure(e.response.data.message));
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedPeriod = (period: TPeriod) => async (dispatch: any) => {
  dispatch(setCurrentPeriod(period));
};

export const copyPeriod = (period: TPeriod) => async (dispatch: any) => {
  try {
    dispatch(createPeriodRequest());
    const response = { ...period, id: 0, description: '' };
    dispatch(createPeriodSuccess(response));
  } catch (e: any) {
    dispatch(createPeriodFailure(e.response.data));
    return console.error(e);
  }
};

export const deletePeriod = (period: TPeriod) => async (dispatch: any) => {
  try {
    dispatch(deletePeriodRequest());
    await api.delete({
      endpoint: `/periods/${period.id}`,
    });
    dispatch(deletePeriodSuccess(period));
  } catch (e: any) {
    dispatch(deletePeriodFailure(e.response.data.message));
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const updatePeriod = (period: TPeriod) => async (dispatch: any) => {
  try {
    dispatch(updatePeriodRequest());
    const { ...PeriodBody } = period;
    const response = await api.patch({
      endpoint: `/periods/${period.id}`,
      data: { ...PeriodBody },
    });
    dispatch(updatePeriodSuccess(response));
  } catch (e: any) {
    dispatch(updatePeriodFailure(e.response.data.message));
    return console.error(e.response.data ? e.response.data : e.response);
  }
};
