import { useSelector } from 'react-redux';

// SERVICES
import TokenService from '../../services/token.service';

// REDUX
import { userSelector } from '../../slices/auth.slice';

// STYLES
import { Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import './index.scss';

const tokenService = TokenService.getService();

const SettingsMenuItem = () => {
  const tokenData = useSelector(userSelector);

  const onShouldLogOut = () => {
    const { cus: customerSignature } = tokenData;
    console.log('customer signature is');
    console.log(customerSignature);

    tokenService.clearToken();
    window.location.replace(`/login?customerSignature=${customerSignature}`);
  };

  return (
    <button className='menu-settings--button' onClick={onShouldLogOut}>
      <Tooltip title={'Logout'} placement='right'>
        <EllipsisOutlined />
      </Tooltip>
    </button>
  );
};

export default SettingsMenuItem;
