import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

// REDUX
import { login } from '../../../slices/auth.slice';
import { useAuth, AuthStatus } from '../../../hooks/useAuth';
import { useQuery } from '../../../hooks/useQuery';

// i18n
import intl from '../../../i18n/intl';

// STYLES
import '../index.scss';
import { Input, Button } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import {
  fetchPublicInfo,
  publicInfoSelector,
} from '../../../slices/publicInfo.slice';

const language: any = intl.messages;

const UsernameAndPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * SELECTORS
   */
  const authStatus = useAuth(true);
  const query = useQuery();
  const customerSignature: any = query.get('customerSignature');
  const publicInfo = useSelector(publicInfoSelector);

  /**
   * STATE VARS
   */
  const [username, setUsername] = useState<any>('');
  const [password, setPassword] = useState<any>('');

  useEffect(() => {
    dispatch(fetchPublicInfo(customerSignature));
  }, [customerSignature, dispatch]);

  const signIn = () => {
    const customerSignature = query.get('customerSignature');
    if (!customerSignature) return;
    dispatch(login(username, password, customerSignature));
  };

  if (authStatus === AuthStatus.AUTHENTICATED) history.push('/');

  return (
    <>
      <div className='sign-in--second-heading-box'>
        <h3 className='sign-in--second-heading'>
          {language.sign_in} to{' '}
          <span style={{ fontStyle: 'italic' }}>
            {customerSignature === 'undefined' ? 'undefined' : publicInfo.name}
          </span>
        </h3>
        {process.env.REACT_APP_TE_APP_ENV === 'development' && (
          /* For development environment */
          <p className='sign-in-info-text'>
            You are in development environment.
          </p>
        )}
        {process.env.REACT_APP_TE_APP_ENV === 'beta' &&
        publicInfo.isProduction === true ? (
          /* For beta environment */
          <p className='sign-in-info-text'>
            You are trying to sign in with a production database into TE Admin
            beta environment. Please try another database.
          </p>
        ) : process.env.REACT_APP_TE_APP_ENV === 'staging' &&
          publicInfo.isProduction === true ? (
          /* For staging environment. */
          <p className='sign-in-info-text'>
            You are trying to sign in with a production database into TE Admin
            staging environment. Please try another database.
          </p>
        ) : null}
      </div>
      <div className='sign-in--input-box'>
        <p className='input-label'>{language.username_email}:</p>
        <Input
          placeholder={language.enter_username_email}
          value={username}
          onChange={(event: any) => setUsername(event.target.value)}
        />
        <p className='input-label'>{language.password}:</p>
        <Input.Password
          placeholder={language.enter_password}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          value={password}
          onChange={(event: any) => setPassword(event.target.value)}
          onPressEnter={signIn}
        />
        <Button
          className='sign-in--button'
          onClick={signIn}
          disabled={
            (process.env.REACT_APP_TE_APP_ENV === 'beta' &&
              publicInfo.isProduction === true) ||
            (process.env.REACT_APP_TE_APP_ENV === 'staging' &&
              publicInfo.isProduction === true)
          }
        >
          {language.sign_in}!
        </Button>
      </div>
    </>
  );
};

export default UsernameAndPassword;
