import { TAltDesignations } from './languages.type';
import { THistory } from './dateHistory.type';

export type TType = {
  name: string;
  extId: string | number;
  id: number;
  active: boolean;
  personalData: boolean;
  description: string;
  altDesignations: Array<TAltDesignations>;
  allowStandardObjects: boolean;
  allowAbstractObjects: boolean;
  allowVirtualObjects: boolean;
  personType: boolean;
  locationType: boolean;
  aliasType: number;
  externalOwner: string;
  subTypes: Array<number>;
  fields: Array<number>;
  parentFields: Array<number>;
  organizations: Array<number>;
  reservationTemplates: Array<number>;
  documentation: string;
  history: Array<THistory>;
};

export class Type {
  static createEmpty = (): TType => ({
    name: '',
    extId: '',
    id: 0,
    active: true,
    personalData: false,
    description: '',
    altDesignations: [],
    allowStandardObjects: false,
    allowAbstractObjects: false,
    allowVirtualObjects: false,
    personType: false,
    locationType: false,
    aliasType: 0,
    externalOwner: '',
    subTypes: [],
    fields: [],
    parentFields: [],
    organizations: [],
    reservationTemplates: [],
    documentation: '',
    history: [],
  });
}

export type TTreeListData = {
  title: string;
  key: string;
  children: Array<TTreeListData>;
  disabled?: boolean;
};

export type TExternalOwner = {
  id: string;
  name: string;
};
