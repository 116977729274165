import { useEffect, useState } from 'react';
import moment from 'moment';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import './index.scss';
import {
  Table,
  Space,
  Button,
  Popconfirm,
  DatePicker,
  Form,
  Select,
  TimePicker,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

// TYPES
import {
  TPeriodInterval,
  PeriodInterval,
  ETimeIntervals,
  TPeriod,
} from '../../../types/period.type';

type Props = {
  intervals: TPeriodInterval[];
  onIntervalsChanged: any;
  period: TPeriod;
};

const language: any = intl.messages;

/* ------------------------------------------------------------------------------- */

const PeriodsTable = ({ intervals, onIntervalsChanged, period }: Props) => {
  // EVENT HANDLERS
  const [startInterval, setStartInterval] = useState<any>({});
  const [endInterval, setEndInterval] = useState<any>({});

  // State for setting errors
  const [error, setError] = useState(false);

  const onChangeIntervalHandler = (
    startIntervalValue: number,
    endIntervalValue: number,
    interval: any,
  ) => {
    const index = intervals.indexOf(interval);
    const currentStartInterval = { ...startInterval };
    currentStartInterval[index] = startIntervalValue;
    const currentEndInterval = { ...endInterval };
    currentEndInterval[index] = endIntervalValue;

    const updatedInterval = Object.assign({}, interval);
    if (currentStartInterval && currentStartInterval[index]) {
      updatedInterval.start = currentStartInterval[index];
      setError(false);
    }
    if (currentEndInterval && currentEndInterval[index]) {
      updatedInterval.end = currentEndInterval[index];
      setError(false);
    }
    if (
      interval.type === 'day' &&
      updatedInterval.start === 1 &&
      updatedInterval.end === 32
    ) {
      const newIntervals: TPeriodInterval[] = [...intervals];
      newIntervals.splice(index, 1, updatedInterval);
      onIntervalsChanged('intervals', newIntervals);
      setError(true);
    } else if (
      interval.type === 'month' &&
      updatedInterval.start === 1 &&
      updatedInterval.end === 13
    ) {
      const newIntervals: TPeriodInterval[] = [...intervals];
      newIntervals.splice(index, 1, updatedInterval);
      onIntervalsChanged('intervals', newIntervals);
      setError(true);
    } else if (
      (interval.type === 'week' &&
        updatedInterval.start === 1 &&
        updatedInterval.end === 53) ||
      (interval.type === 'week' &&
        updatedInterval.start === 1 &&
        updatedInterval.end === 54)
    ) {
      const newIntervals: TPeriodInterval[] = [...intervals];
      newIntervals.splice(index, 1, updatedInterval);
      onIntervalsChanged('intervals', newIntervals);
      setError(true);
    } else {
      setError(false);
      const newIntervals: TPeriodInterval[] = [...intervals];
      newIntervals.splice(index, 1, updatedInterval);
      onIntervalsChanged('intervals', newIntervals);
    }
  };

  useEffect(() => {
    setStartInterval({});
    setEndInterval({});
  }, [period]);

  const addInterval = (intervalType: ETimeIntervals) => {
    let newInterval: TPeriodInterval[];
    if (intervals === undefined) {
      newInterval = [];
    } else {
      newInterval = [...intervals];
    }
    newInterval.push(PeriodInterval.create(intervalType));
    onIntervalsChanged('intervals', newInterval);
  };

  const onDeleteHandler = (interval: any, intervalIndex: number) => {
    setError(false);
    onIntervalsChanged(
      'intervals',
      intervals.filter((i: any, index: number) => index !== intervalIndex),
    );
  };

  const listOfWeekdays = [
    { id: 0, name: 'sunday' },
    { id: 1, name: 'monday' },
    { id: 2, name: 'tuesday' },
    { id: 3, name: 'wednesday' },
    { id: 4, name: 'thursday' },
    { id: 5, name: 'friday' },
    { id: 6, name: 'saturday' },
  ];

  const listOfMonths = [
    { id: 1, name: 'january' },
    { id: 2, name: 'february' },
    { id: 3, name: 'march' },
    { id: 4, name: 'april' },
    { id: 5, name: 'may' },
    { id: 6, name: 'june' },
    { id: 7, name: 'july' },
    { id: 8, name: 'august' },
    { id: 9, name: 'september' },
    { id: 10, name: 'october' },
    { id: 11, name: 'november' },
    { id: 12, name: 'december' },
  ];

  const createRange = (min: number, max: number) => {
    const range = [];
    for (let i = min; i <= max; i++) {
      range.push(i);
    }
    return range;
  };

  const columns = [
    {
      title: (
        <span className='small-heading-text'>{language.interval_type}</span>
      ),
      dataIndex: 'type',
      key: Math.random(),
      className: 'period-intervals-type',
      render: (type: any) => <span>{language[type]}</span>,
    },
    {
      title: (
        <span className='small-heading-text'>
          {language.start} / {language.end}
        </span>
      ),
      dataIndex: 'start',
      key: 'start',
      className: 'period-intervals-select',
      render: (start: number, record: any) =>
        record.type === 'week' ? (
          <div style={{ display: 'flex' }}>
            <Select
              size='small'
              style={{ width: '120px' }}
              value={start}
              onChange={(value: number) =>
                onChangeIntervalHandler(value, record.end, record.interval)
              }
            >
              {createRange(1, 52).map((weekday: any) => (
                <Select.Option key={weekday} value={weekday}>
                  {weekday}
                </Select.Option>
              ))}
            </Select>
            <p
              className='small-heading-text'
              style={{ marginBottom: 0, marginLeft: '6px', marginRight: '6px' }}
            >
              {' '}
              -{' '}
            </p>
            <Select
              size='small'
              style={{ width: '120px' }}
              value={record.end - 1}
              onChange={(value: number) =>
                onChangeIntervalHandler(start, value + 1, record.interval)
              }
            >
              {createRange(1, 53).map((weekday: any) => (
                <Select.Option key={weekday} value={weekday}>
                  {weekday}
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : record.type === 'weekNumber' ? (
          <DatePicker.RangePicker
            picker='week'
            format='YYww'
            value={[moment(start, 'YYww'), moment(record.end - 1, 'YYww')]}
            onChange={(dates: any) => {
              onChangeIntervalHandler(
                parseInt(dates[0].format('YYww')),
                parseInt(dates[1].format('YYww')) + 1,
                record.interval,
              );
            }}
          />
        ) : record.type === 'year' ? (
          <DatePicker.RangePicker
            picker='year'
            format='YYYY'
            value={[moment(start, 'YYYY'), moment(record.end - 1, 'YYYY')]}
            onChange={(dates: any) => {
              onChangeIntervalHandler(
                parseInt(dates[0].format('YYYY')),
                parseInt(dates[1].format('YYYY')) + 1,
                record.interval,
              );
            }}
          />
        ) : record.type === 'month' ? (
          <div style={{ display: 'flex' }}>
            <Select
              size='small'
              style={{ width: '120px' }}
              value={start}
              onChange={(value: number) =>
                onChangeIntervalHandler(value, record.end, record.interval)
              }
            >
              {listOfMonths.map((month: any) => (
                <Select.Option key={month.name} value={month.id}>
                  {language[month.name]}
                </Select.Option>
              ))}
            </Select>
            <p
              className='small-heading-text'
              style={{ marginBottom: 0, marginLeft: '6px', marginRight: '6px' }}
            >
              {' '}
              -{' '}
            </p>
            <Select
              size='small'
              style={{ width: '120px' }}
              value={record.end - 1}
              onChange={(value: number) =>
                onChangeIntervalHandler(start, value + 1, record.interval)
              }
            >
              {listOfMonths.map((month: any) => (
                <Select.Option key={month.name} value={month.id}>
                  {language[month.name]}
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : record.type === 'date' ? (
          <DatePicker.RangePicker
            format='YYYY-MM-DD'
            value={[
              moment.unix(start).utc(),
              moment.unix(record.end - 86400).utc(),
            ]}
            onChange={(dates: any) => {
              onChangeIntervalHandler(
                parseInt(dates[0].format('X')),
                parseInt(dates[1].format('X')) + 86400,
                record.interval,
              );
            }}
          />
        ) : record.type === 'day' ? (
          <div style={{ display: 'flex' }}>
            <Select
              size='small'
              style={{ width: '120px' }}
              value={start}
              onChange={(value: number) =>
                onChangeIntervalHandler(value, record.end - 1, record.interval)
              }
            >
              {createRange(1, 31).map((weekday: any) => (
                <Select.Option key={weekday} value={weekday}>
                  {weekday}
                </Select.Option>
              ))}
            </Select>
            <p
              className='small-heading-text'
              style={{ marginBottom: 0, marginLeft: '6px', marginRight: '6px' }}
            >
              {' '}
              -{' '}
            </p>
            <Select
              size='small'
              style={{ width: '120px' }}
              value={record.end - 1}
              onChange={(value: number) =>
                onChangeIntervalHandler(start, value + 1, record.interval)
              }
            >
              {createRange(1, 31).map((weekday: any) => (
                <Select.Option key={weekday} value={weekday}>
                  {weekday}
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : record.type === 'weekday' ? (
          <div style={{ display: 'flex' }}>
            <Select
              size='small'
              style={{ width: '120px' }}
              value={start}
              onChange={(value: number) =>
                onChangeIntervalHandler(value, record.end, record.interval)
              }
            >
              {listOfWeekdays.map((weekday: any) => (
                <Select.Option key={weekday.name} value={weekday.id}>
                  {language[weekday.name]}
                </Select.Option>
              ))}
            </Select>
            <p
              className='small-heading-text'
              style={{ marginBottom: 0, marginLeft: '6px', marginRight: '6px' }}
            >
              {' '}
              -{' '}
            </p>
            <Select
              size='small'
              style={{ width: '120px' }}
              value={record.end - 1}
              onChange={(value: number) =>
                onChangeIntervalHandler(start, value + 1, record.interval)
              }
            >
              {listOfWeekdays.map((weekday: any) => (
                <Select.Option key={weekday.name} value={weekday.id}>
                  {language[weekday.name]}
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : (
          <TimePicker.RangePicker
            defaultValue={[
              moment.unix(start).utc(),
              moment.unix(record.end).utc(),
            ]}
            format={'HH:mm'}
            onChange={(values: any) => {
              onChangeIntervalHandler(
                parseInt(values[0].format('X')),
                parseInt(values[1].format('X')),
                record.interval,
              );
            }}
            minuteStep={5}
          />
        ),
    },
    {
      title: <span className='small-heading-text'>{language.action}</span>,
      key: 'action',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={language.sure_to_delete_period}
            onConfirm={() => onDeleteHandler(record.interval, record.index)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Form.Item
        label={`${language.add_interval}`}
        style={{ marginBottom: 10 }}
      >
        <Select
          size='small'
          onChange={addInterval}
          value={ETimeIntervals.NONE}
          placeholder={language.select_interval}
        >
          {Object.entries(ETimeIntervals).map(([key, value]) => (
            <Select.Option value={key} key={key}>
              {language[value]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Table
        size='small'
        dataSource={intervals?.map((interval: any, index: number) => ({
          key: interval.start,
          index,
          interval,
          start: startInterval[index] || interval.start,
          end: endInterval[index] || interval.end,
          ...interval,
        }))}
        columns={columns}
        style={{ marginBottom: 20 }}
        className='period-intervals-table'
      />{' '}
      {error === true && (
        <p style={{ color: '#ff6357', fontSize: '16px' }}>
          {language.not_possible_to_add_an_interval_including_everything}
        </p>
      )}
    </>
  );
};

export default PeriodsTable;
