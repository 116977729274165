export type TLanguage = {
  id: string;
  name: string;
  isActive: boolean;
};

export type TAltDesignations = {
  language: string;
  name: string;
  description: string;
};

export class AltDesignations {
  static create = (language: string): TAltDesignations => ({
    language: language,
    name: '',
    description: '',
  });
}
