// i18N
import intl from '../../../../i18n/intl';

// STYLES
import { Input, Button, Table, Space, Popconfirm } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const language: any = intl.messages;

type Props = {
  editableFeatures: string[];
  onChange: any;
  property: string;
};

export const EditableFeaturesTable = ({
  editableFeatures,
  onChange,
  property,
}: Props) => {
  // EVENT HANDLERS
  const onChangeHandler = (editableFeature: any, value: any) => {
    const index = editableFeatures.indexOf(editableFeature);
    let updatedData = [...editableFeature];
    updatedData = value;
    const newData: any = [...editableFeatures];
    newData.splice(index, 1, updatedData);
    onChange(newData, property);
  };

  const onAddRow = () => {
    const newDataSource = [...editableFeatures];
    newDataSource.push('');
    onChange(newDataSource, property);
  };

  const onDeleteRow = (dataKey: string) => {
    onChange(
      editableFeatures.filter(
        (editableFeature: string) => editableFeature !== dataKey,
      ),
      property,
    );
  };

  // Columns
  const columns = [
    {
      dataIndex: 'editableFeature',
      key: 'editableFeature',
      width: 280,
      render: (editableFeature: string) => (
        <Input
          size='small'
          value={editableFeature}
          onChange={(event: any) =>
            onChangeHandler(editableFeature, event.target.value)
          }
        />
      ),
    },
    {
      key: 'action',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={language.sure_to_delete}
            onConfirm={() => onDeleteRow(record.editableFeature)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Table
        size='small'
        dataSource={editableFeatures?.map((editableFeature: string) => ({
          key: editableFeature,
          editableFeature,
        }))}
        columns={columns}
        style={{ marginBottom: '16px', maxWidth: '75vw' }}
      />
      <Button
        type='default'
        size='small'
        icon={<PlusOutlined />}
        style={{ width: 100 }}
        onClick={onAddRow}
      >
        {language.add}
      </Button>
    </div>
  );
};
