import { useState } from 'react';

// i18N
import intl from '../../i18n/intl';

// STYLES
import { Collapse, Form, Input, Select, Checkbox } from 'antd';

// TYPES
import { ENUMS } from '@timeedit/types/lib';
import {
  EApplySSOPermissions,
  EAuthLogLevel,
  EAuthSaml2SignatureAlgorithm,
  EAuthSaml2Signing,
  EAuthStrategy,
  EUserObjectMatching,
  ISAML2ConfigCreate,
  ITEIdPConfigCreate,
  TAuthenticationConfigurationType,
} from '../../types/authConfig.type';

// COMPONENTS
import { TEItem, TEToolTip } from '../TEToolTip';
import { AttributesTable } from './AttributesTable';
import { UserPermissionsTable } from './UserPermissionsTable';

const { EAppId } = ENUMS;
const language: any = intl.messages;

type Props = {
  onChange: any;
  authenticationConfiguration: TAuthenticationConfigurationType;
  onChangeTwoProperties: any;
};

type PropsConfiguration = {
  onChange: any;
  authenticationConfiguration: TAuthenticationConfigurationType;
};

type PropsSAML2 = {
  onChange: any;
  authenticationConfiguration: any;
};

type PropsIDP = {
  onChange: any;
  authenticationConfiguration: any;
};

/* ---------------------------------------------------------------------- */

export const AuthenticationGeneralSettings = ({
  onChange,
  authenticationConfiguration,
  onChangeTwoProperties,
}: Props) => {
  // EVENT HANDLERS
  const onHandleChange = (value: any, property: string) => {
    if (property === 'strategy' && value === 'TE_IDP') {
      onChangeTwoProperties(
        ['strategy', 'config'],
        [value, ITEIdPConfigCreate.createEmpty()],
      );
    } else if (property === 'strategy' && value === 'SAML2') {
      onChangeTwoProperties(
        ['strategy', 'config'],
        [value, ISAML2ConfigCreate.createEmpty()],
      );
    } else {
      onChange(property, value);
    }
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Form.Item label={`${language.name}`}>
          <Input
            size='small'
            value={authenticationConfiguration.name}
            onChange={(event: any) =>
              onHandleChange(event.target.value, 'name')
            }
          />
        </Form.Item>
        <p className='small-heading-text'>
          {language.id}: {authenticationConfiguration.id}
        </p>
        <TEItem helpText={language.help_auth_config_active}>
          <Checkbox
            checked={authenticationConfiguration.active}
            onChange={(event: any) =>
              onHandleChange(event.target.checked, 'active')
            }
          >
            {language.active}
          </Checkbox>
        </TEItem>
        <TEItem helpText={language.help_auth_config_default}>
          <Checkbox
            checked={authenticationConfiguration.default}
            onChange={(event: any) =>
              onHandleChange(event.target.checked, 'default')
            }
            disabled={authenticationConfiguration.active === false}
          >
            {language.default}
          </Checkbox>
        </TEItem>
        <Form.Item label={language.log_level} tooltip={language.help_log_level}>
          <Select
            showSearch
            style={{ width: 100 }}
            size='small'
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={language[authenticationConfiguration.logLevel]}
            onChange={(event: any) => onHandleChange(event, 'logLevel')}
          >
            {Object.entries(EAuthLogLevel).map(([key, value]) => (
              <Select.Option value={value} key={key}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          tooltip={language.help_enabled_applications}
          label={language.enabled_applications}
        >
          <Select
            mode='multiple'
            showSearch
            size='small'
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={authenticationConfiguration.enabledForAppIds}
            onChange={(event: any) => onHandleChange(event, 'enabledForAppIds')}
          >
            {Object.entries(EAppId).map(([key, value]) => (
              <Select.Option value={value} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.strategy}
          tooltip={language.help_auth_configs_strategy}
        >
          <Select
            size='small'
            showSearch
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={authenticationConfiguration.strategy}
            onChange={(event: any) => {
              onHandleChange(event, 'strategy');
            }}
          >
            {Object.entries(EAuthStrategy).map(([key, value]) => (
              <Select.Option value={value} key={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={`${language.description}`}
          tooltip={language.description}
        >
          <Input.TextArea
            size='small'
            value={authenticationConfiguration.description}
            onChange={(event: any) =>
              onHandleChange(event.target.value, 'description')
            }
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};

export const AuthenticationConfiguration = ({
  onChange,
  authenticationConfiguration,
}: PropsConfiguration) => {
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.configuration} key='2'>
        {authenticationConfiguration.strategy === EAuthStrategy.teIdp && (
          <IDP
            onChange={onChange}
            authenticationConfiguration={authenticationConfiguration.config}
          />
        )}
        {authenticationConfiguration.strategy === EAuthStrategy.saml2 && (
          <SAML2
            onChange={onChange}
            authenticationConfiguration={authenticationConfiguration.config}
          />
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export const IDP = ({ onChange, authenticationConfiguration }: PropsIDP) => {
  // EVENT HANDLER
  const onChangeConfiguration = (value: any, property: string) => {
    const updatedConfig = { ...authenticationConfiguration };
    updatedConfig[property] = value;
    const newConfig = { ...updatedConfig };
    onChange('config', newConfig);
  };

  return (
    <TEItem helpText={language.help_use_mfa}>
      <Checkbox
        checked={authenticationConfiguration.useMFA}
        onChange={(event: any) =>
          onChangeConfiguration(event.target.checked, 'useMFA')
        }
      >
        {language.use_MFA}
      </Checkbox>
    </TEItem>
  );
};

export const SAML2 = ({
  onChange,
  authenticationConfiguration,
}: PropsSAML2) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  // EVENT HANDLER
  const onChangeConfiguration = (value: any, property: string) => {
    if (property === 'spEntityId' && value === '') {
      setErrorMessage(`${language.timeedit_entity_id_missing}`);
    }
    const updatedConfig = { ...authenticationConfiguration };
    updatedConfig[property] = value;
    const newConfig = { ...updatedConfig };
    onChange('config', newConfig);
  };

  const onChangeUserObjectField = (data: any, index: number, value: any) => {
    let updatedData = data;
    updatedData = value;
    const newData: any = [...authenticationConfiguration.userObjectField];
    newData.splice(index, 1, updatedData);
    onChangeConfiguration(newData, 'userObjectField');
  };

  return (
    <>
      <Form.Item
        label={language.idp_metadata_url}
        tooltip={language.help_idp_metadata_url}
      >
        <Input
          size='small'
          value={authenticationConfiguration.idpMetaDataUrl}
          onChange={(event: any) =>
            onChangeConfiguration(event.target.value, 'idpMetaDataUrl')
          }
        />
      </Form.Item>
      <Form.Item
        label={language.idp_entity_id}
        tooltip={language.help_idp_entity_id}
      >
        <Input
          size='small'
          value={authenticationConfiguration.idpEntityId}
          onChange={(event: any) =>
            onChangeConfiguration(event.target.value, 'idpEntityId')
          }
        />
      </Form.Item>
      {errorMessage && (
        <p style={{ color: '#ff6357', fontSize: '14px', margin: 0 }}>
          {errorMessage}
        </p>
      )}
      <Form.Item
        label={language.timeedit_entity_id}
        tooltip={language.help_timeedit_entity_id}
      >
        <Input
          size='small'
          value={authenticationConfiguration.spEntityId}
          onChange={(event: any) =>
            onChangeConfiguration(event.target.value, 'spEntityId')
          }
        />
      </Form.Item>
      <Form.Item
        label={language.idp_logout_url}
        tooltip={language.help_idp_logout_url}
      >
        <Input
          size='small'
          value={authenticationConfiguration.idpLogoutUrl}
          onChange={(event: any) =>
            onChangeConfiguration(event.target.value, 'idpLogoutUrl')
          }
        />
      </Form.Item>
      <Form.Item
        label={language.idp_entry_point_url}
        tooltip={language.help_idp_entry_point_url}
      >
        <Input
          size='small'
          value={authenticationConfiguration.idpEntryPointUrl}
          onChange={(event: any) =>
            onChangeConfiguration(event.target.value, 'idpEntryPointUrl')
          }
        />
      </Form.Item>
      <Form.Item
        label={language.idp_private_key}
        tooltip={language.help_idp_private_key}
      >
        <Input
          size='small'
          value={authenticationConfiguration.idpPrivateKey}
          onChange={(event: any) =>
            onChangeConfiguration(event.target.value, 'idpPrivateKey')
          }
        />
      </Form.Item>
      <Form.Item
        label={language.signature_signing}
        tooltip={language.help_signature_signing}
      >
        <Select
          size='small'
          showSearch
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={authenticationConfiguration.signatureSigning}
          onChange={(event: any) =>
            onChangeConfiguration(event, 'signatureSigning')
          }
        >
          {Object.entries(EAuthSaml2Signing).map(([key, value]) => (
            <Select.Option value={value} key={key}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={language.signature_algorithm}
        tooltip={language.help_signature_algorithm}
      >
        <Select
          size='small'
          showSearch
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={authenticationConfiguration.signatureAlgorithm}
          onChange={(event: any) =>
            onChangeConfiguration(event, 'signatureAlgorithm')
          }
        >
          {Object.entries(EAuthSaml2SignatureAlgorithm).map(([key, value]) => (
            <Select.Option value={value} key={key}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <TEItem helpText={language.help_prefer_http_post}>
        <Checkbox
          checked={authenticationConfiguration.preferHttpPost}
          onChange={(event: any) =>
            onChangeConfiguration(event.target.checked, 'preferHttpPost')
          }
        >
          {language.prefer_http_post}
        </Checkbox>
      </TEItem>
      <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 16 }}>
        <Collapse.Panel header={language.attributes_parsing} key='2'>
          <Form.Item
            label={language.user_name_attribute}
            tooltip={language.help_user_name_attribute}
          >
            <Input
              size='small'
              value={authenticationConfiguration.userNameAttribute}
              onChange={(event: any) =>
                onChangeConfiguration(event.target.value, 'userNameAttribute')
              }
            />
          </Form.Item>
          <Form.Item
            label={language.email_attribute}
            tooltip={language.help_email_attribute}
          >
            <Input
              size='small'
              value={authenticationConfiguration.emailAttribute}
              onChange={(event: any) =>
                onChangeConfiguration(event.target.value, 'emailAttribute')
              }
            />
          </Form.Item>
          <Form.Item
            label={language.given_name_attribute}
            tooltip={language.help_given_name_attribute}
          >
            <Input
              size='small'
              value={authenticationConfiguration.givenNameAttribute}
              onChange={(event: any) =>
                onChangeConfiguration(event.target.value, 'givenNameAttribute')
              }
            />
          </Form.Item>
          <Form.Item
            label={language.last_name_attribute}
            tooltip={language.help_last_name_attribute}
          >
            <Input
              size='small'
              value={authenticationConfiguration.lastNameAttribute}
              onChange={(event: any) =>
                onChangeConfiguration(event.target.value, 'lastNameAttribute')
              }
            />
          </Form.Item>
          <TEToolTip helpText={language.help_mandatory_attributes}>
            <p className='small-heading-text'>
              {language.mandatory_attributes}
            </p>
          </TEToolTip>
          <AttributesTable
            dataSource={authenticationConfiguration.mandatoryAttributes}
            onChange={onChangeConfiguration}
            property={'mandatoryAttributes'}
          />
          <TEToolTip helpText={language.help_forbidden_attributes}>
            <p className='small-heading-text'>
              {language.forbidden_attributes}
            </p>
          </TEToolTip>
          <AttributesTable
            dataSource={authenticationConfiguration.forbiddenAttributes}
            onChange={onChangeConfiguration}
            property={'forbiddenAttributes'}
          />
          <Form.Item
            label={language.user_object_matching}
            tooltip={language.help_user_object_matching}
          >
            <Select
              size='small'
              showSearch
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={language[authenticationConfiguration.userObjectMatching]}
              onChange={(event: any) =>
                onChangeConfiguration(event, 'userObjectMatching')
              }
            >
              {Object.entries(EUserObjectMatching).map(([key, value]) => (
                <Select.Option value={value} key={key}>
                  {language[value]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={language.user_object_extid}
            tooltip={language.help_user_object_extid}
          >
            <Input
              size='small'
              value={authenticationConfiguration.userObjectExtId}
              onChange={(event: any) =>
                onChangeConfiguration(event.target.value, 'userObjectExtId')
              }
            />
          </Form.Item>
          <TEToolTip helpText={language.help_user_object_field}>
            <p className='small-heading-text' style={{ marginBottom: 4 }}>
              {language.user_object_field}
            </p>
          </TEToolTip>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Form.Item label='Attribute'>
              <Input
                style={{ width: 260 }}
                size='small'
                value={authenticationConfiguration.userObjectField[0]}
                onChange={(event: any) =>
                  onChangeUserObjectField(
                    authenticationConfiguration.userObjectField[0],
                    0,
                    event.target.value,
                  )
                }
              />
            </Form.Item>
            <Form.Item label='Value'>
              <Input
                style={{ width: 260 }}
                size='small'
                value={authenticationConfiguration.userObjectField[1]}
                onChange={(event: any) =>
                  onChangeUserObjectField(
                    authenticationConfiguration.userObjectField[1],
                    1,
                    event.target.value,
                  )
                }
              />
            </Form.Item>
          </div>
          <TEItem helpText={language.help_mandatory_user_object}>
            <Checkbox
              checked={authenticationConfiguration.requireUserObject}
              onChange={(event: any) =>
                onChangeConfiguration(event.target.checked, 'requireUserObject')
              }
            >
              {language.mandatory_user_object}
            </Checkbox>
          </TEItem>
        </Collapse.Panel>
      </Collapse>
      <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 16 }}>
        <Collapse.Panel header={language.permissions} key='2'>
          <Form.Item
            label={language.set_permissions}
            tooltip={language.help_set_permissions}
          >
            <Select
              size='small'
              showSearch
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={language[authenticationConfiguration.applySSOPermissions]}
              onChange={(event: any) =>
                onChangeConfiguration(event, 'applySSOPermissions')
              }
            >
              {Object.entries(EApplySSOPermissions).map(([key, value]) => (
                <Select.Option value={value} key={key}>
                  {language[value]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'Template user'} tooltip={'help-text-missing'}>
            <Input
              size='small'
              value={authenticationConfiguration.templateUser}
              onChange={(event: any) =>
                onChangeConfiguration(event.target.value, 'templateUser')
              }
            />
          </Form.Item>
          <TEToolTip helpText={language.help_user_permissions}>
            <p className='small-heading-text'>{language.user_permissions}</p>
          </TEToolTip>
          <UserPermissionsTable
            userPermissions={
              authenticationConfiguration.partialAttributesMatching
            }
            onChange={onChangeConfiguration}
          />
        </Collapse.Panel>
      </Collapse>
    </>
  );
};
