import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TimeRule, TTimeRule } from '../types/timerule.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedTimeRule: TimeRule.createEmpty(),
  allTimeRules: [],
  limitations: {},
};

// Slice
const slice = createSlice({
  name: 'timeRules',
  initialState,
  reducers: {
    fetchTimeRulesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchTimeRulesSuccess: (state: any, { payload }) => {
      if (state.selectedTimeRule.id > 0) {
        state.selectedTimeRule =
          payload.find(
            (timeRule: any) => timeRule.id === state.selectedTimeRule.id,
          ) || TimeRule.createEmpty();
      }
      state.allTimeRules = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsTimeRulesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchTimeRulesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createTimeRuleRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createTimeRuleSuccess: (state: any, { payload }) => {
      state.allTimeRules.push(payload);
      state.selectedTimeRule = payload;
      finishedLoadingSuccess(state);
    },
    createTimeRuleFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentTimeRule: (state: any, { payload }) => {
      state.selectedTimeRule = payload;
    },
    deleteTimeRuleRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteTimeRuleSuccess: (state: any, payload: any) => {
      state.allTimeRules = state.allTimeRules.filter(
        (timeRule: TTimeRule) => timeRule.id !== payload.payload.id,
      );
      state.selectedTimeRule = TimeRule.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteTimeRuleFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateTimeRuleRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateTimeRuleSuccess: (state: any, { payload }) => {
      const index = state.allTimeRules.findIndex(
        (timeRule: TTimeRule) => timeRule.id === payload.id,
      );
      state.allTimeRules[index] = payload;
      finishedLoadingSuccess(state);
    },
    updateTimeRuleFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const timeRulesLoading = (state: any) => state.timeRules.loading;
export const timeRulesSelector = (state: any) =>
  state.timeRules.allTimeRules as TTimeRule[];
export const timeRuleSelectorCreateNew = () => () => {
  return TimeRule.createEmpty();
};
export const selectedTimeRuleSelector = (state: any) => {
  return state.timeRules.selectedTimeRule;
};
export const fetchLimitationsTimeRulesSelector = (state: any) =>
  state.timeRules.limitations;

// Actions
export const {
  fetchTimeRulesRequest,
  fetchTimeRulesSuccess,
  fetchLimitationsTimeRulesSuccess,
  fetchTimeRulesFailure,
  createTimeRuleRequest,
  createTimeRuleSuccess,
  createTimeRuleFailure,
  setCurrentTimeRule,
  deleteTimeRuleRequest,
  deleteTimeRuleSuccess,
  deleteTimeRuleFailure,
  updateTimeRuleRequest,
  updateTimeRuleSuccess,
  updateTimeRuleFailure,
} = slice.actions;

export const fetchTimeRules = () => async (dispatch: any) => {
  try {
    dispatch(fetchTimeRulesRequest());
    const timeRules = await api.get({ endpoint: `/time-rules` });
    dispatch(fetchTimeRulesSuccess(timeRules));
  } catch (e) {
    dispatch(fetchTimeRulesFailure());
    return console.error(e);
  }
};

export const fetchLimitationsTimeRules = () => async (dispatch: any) => {
  try {
    dispatch(fetchTimeRulesRequest());
    const limitationsTimeRules = await api.get({
      endpoint: `/time-rules/limitations`,
    });
    dispatch(fetchLimitationsTimeRulesSuccess(limitationsTimeRules));
  } catch (e) {
    dispatch(fetchTimeRulesFailure());
    return console.error(e);
  }
};

export const createTimeRule =
  (timeRule: TTimeRule) => async (dispatch: any) => {
    try {
      dispatch(createTimeRuleRequest());
      const { id: _, ...timeRuleBody } = timeRule;
      const response = await api.post({
        endpoint: `/time-rules`,
        data: timeRuleBody,
      });
      dispatch(createTimeRuleSuccess(response));
      dispatch(fetchTimeRules());
    } catch (e: any) {
      dispatch(createTimeRuleFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const createEmptyTimeRule = () => async (dispatch: any) => {
  try {
    dispatch(createTimeRuleRequest());
    const response = TimeRule.createEmpty();
    dispatch(createTimeRuleSuccess(response));
  } catch (e: any) {
    dispatch(createTimeRuleFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedTimeRule =
  (timeRule: TTimeRule) => async (dispatch: any) => {
    dispatch(setCurrentTimeRule(timeRule));
  };

export const copyTimeRule = (timeRule: TTimeRule) => async (dispatch: any) => {
  try {
    dispatch(createTimeRuleRequest());
    const response = { ...timeRule, id: 0, description: '' };
    dispatch(createTimeRuleSuccess(response));
  } catch (e: any) {
    dispatch(createTimeRuleFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const deleteTimeRule =
  (timeRule: TTimeRule) => async (dispatch: any) => {
    try {
      dispatch(deleteTimeRuleRequest());
      await api.delete({
        endpoint: `/time-rules/${timeRule.id}`,
      });
      dispatch(deleteTimeRuleSuccess(timeRule));
    } catch (e: any) {
      dispatch(deleteTimeRuleFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const updateTimeRule =
  (timeRule: TTimeRule) => async (dispatch: any) => {
    try {
      dispatch(updateTimeRuleRequest());
      const { ...TimeRuleBody } = timeRule;
      const response = await api.patch({
        endpoint: `/time-rules/${timeRule.id}`,
        data: { ...TimeRuleBody },
      });
      dispatch(updateTimeRuleSuccess(response));
    } catch (e: any) {
      dispatch(updateTimeRuleFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
