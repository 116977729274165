import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'antd';

// UTILS
import { isSecondLevelItemSelected } from '../../utils/menu.utils.js';

// STYLES
import './index.scss';

// TYPES
type Props = {
  label: string;
  route: string;
  icon?: any | undefined | null;
};

const Menu2ndLevelItem = ({ label, route, icon }: Props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const isActive = isSecondLevelItemSelected(route, match);

  const onGoToPage = () => {
    history.push(route);
  };

  return (
    <div className='sidebar-menu-item--wrapper'>
      <Button onClick={onGoToPage} className='second-menu-button' type='text'>
        <span
          className={`sidebar-menu-item--text ${
            isActive ? 'active' : 'inactive'
          }`}
        >
          {icon}
          {label}
        </span>
      </Button>
    </div>
  );
};

export default Menu2ndLevelItem;
