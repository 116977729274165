import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// ACTIONS
import {
  copyRole,
  createEmptyRole,
  createRole,
  deleteRole,
  fetchLimitationsRoles,
  fetchLimitationsRolesSelector,
  fetchRoles,
  rolesSelector,
  selectedRoleSelector,
  setSelectedRole,
  updateRole,
} from '../../../slices/role.slice';

// STYLES
import { Form } from 'antd';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import NormalListView from '../../../components/List/NormalListView';
import {
  RolesComponent,
  PermissionsComponent,
} from '../../../components/RolesAndPermissionsComponent';

// TYPES
import { TRole } from '../../../types/role.type';

// UTILS
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

const language: any = intl.messages;

type Props = {
  customerSignature: string;
};

/* ---------------------------------------------------------------------- */

const RolesAndPermissionsPage = ({ customerSignature }: Props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const roles: TRole[] = useSelector(rolesSelector);
  const limitations = useSelector(fetchLimitationsRolesSelector);
  const selectedRole: TRole = useSelector(selectedRoleSelector);

  const listData = roles.map((listOption: any) => ({
    listLabel: listOption.name,
    value: listOption.id,
  }));

  const onRoleSelected = (selectedValue: any) => {
    const currentRole = roles.find((role: any) => role.id === selectedValue);
    if (currentRole) {
      dispatch(setSelectedRole(currentRole));
    }
  };

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/organizations-users`,
          label: `${language.organizations_users}`,
        },
        {
          path: `/organizations-users/roles-and-permissions`,
          label: `${language.roles_permissions}`,
        },
        {
          path: `/organizations-users/roles-and-permissions`,
          label: `${selectedRole.name}`,
        },
      ]),
    );
  }, [customerSignature, dispatch, selectedRole.id, selectedRole.name]);

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchLimitationsRoles());
  }, [dispatch]);

  // EVENT HANDLERS
  // Adds new role
  const onPlusButton = () => {
    dispatch(createEmptyRole());
  };

  // Deletes choosen role
  const onDeleteButton = () => {
    dispatch(deleteRole(selectedRole));
    dispatch(fetchRoles());
  };

  // Save changes functiom
  const onSaveChangesRoleButton = () => {
    if (selectedRole.id === 0) {
      dispatch(createRole(selectedRole));
      dispatch(fetchRoles());
    }
    dispatch(updateRole(selectedRole));
    dispatch(fetchRoles());
  };

  const onHandleChange = (property: string, value: any) => {
    dispatch(setSelectedRole({ ...selectedRole, [property]: value }));
  };

  // Discard changes function
  const onDiscardChanges = () => {
    onRoleSelected(selectedRole.id);
  };

  // Copy role
  const onCopy = () => {
    dispatch(copyRole(selectedRole));
  };

  return (
    <>
      <ListView
        sectionHeading={language.roles_permissions}
        number={listData.length}
        onSelect={onPlusButton}
      >
        <NormalListView
          data={listData}
          onSelect={onRoleSelected}
          onDelete={onDeleteButton}
          onCopy={onCopy}
          selectedItemId={selectedRole.id}
        />
      </ListView>
      <div className='inner-content--wrapper'>
        <SectionHeader
          listChoice={language.roles_permissions}
          modifiedDate={2021}
          modifiedBy='admin'
          changeHandler={onSaveChangesRoleButton}
          discardHandler={onDiscardChanges}
          displayProp={false}
          history={false}
          isDisabled={isSavedDisabled(selectedRole, roles)}
        />
        <Form layout='vertical' form={form}>
          <div style={{ position: 'relative' }}>
            <RolesComponent
              role={selectedRole}
              onChange={onHandleChange}
              limitations={limitations}
            />
            <PermissionsComponent onChange={onHandleChange} />
          </div>
        </Form>
      </div>
    </>
  );
};

export default RolesAndPermissionsPage;
