import { useSelector } from 'react-redux';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Button, Table, Space, Popconfirm, Select } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

// ACTIONS
import { rolesSelector } from '../../../slices/role.slice';

// TYPES
import { TRole } from '../../../types/role.type';
import { OrganizationsAndRoles } from '../../../types/user.type';
import { TOrganizationAndRoles } from '@timeedit/types/lib';

// COMPONENTS
import { TEToolTip } from '../../TEToolTip';
import { getNameOnOrganization, getNameOnRole } from '../../../utils/getNameOn';
import { TOrganization } from '../../../types/organization.type';
import { organizationsSelector } from '../../../slices/organization.slice';

const language: any = intl.messages;

type Props = {
  dataSource: TOrganizationAndRoles[];
  onChange: any;
  property: string;
};

/* ----------------------------------------------------------------------------------------------- */

export const OrgAndRolesTable = ({ dataSource, onChange, property }: Props) => {
  const allRoles: TRole[] = useSelector(rolesSelector);
  const allOrganizations: TOrganization[] = useSelector(organizationsSelector);

  // EVENT HANDLERS
  const onChangeHandler = (data: any, propertyElement: string, value: any) => {
    const index = dataSource.indexOf(data);
    const updatedData = Object.assign({}, data);
    updatedData[propertyElement] = value;
    const newData: any = [...dataSource];
    newData.splice(index, 1, updatedData);
    onChange(property, newData);
  };

  const onAddRow = () => {
    const newDataSource = [...dataSource];
    newDataSource.push(OrganizationsAndRoles.createEmpty());
    onChange(property, newDataSource);
  };

  const onDeleteRow = (dataKey: any) => {
    onChange(
      property,
      dataSource.filter((data: any) => data !== dataKey),
    );
  };

  // Columns
  const columns = [
    {
      title: (
        <TEToolTip helpText=''>
          <span className='small-heading-text'>{language.organization}</span>
        </TEToolTip>
      ),
      dataIndex: 'organization',
      key: 'organization',
      width: 260,
      render: (organization: any, record: any) => (
        <Select
          size='small'
          value={getNameOnOrganization(allOrganizations, organization)}
          onChange={(event: any) =>
            onChangeHandler(record.organization, 'organization', event)
          }
        >
          {allOrganizations.map((org: any) => (
            <Select.Option value={org.organizationId} key={org.organizationId}>
              {org.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: (
        <TEToolTip helpText=''>
          <span className='small-heading-text'>{language.role}</span>
        </TEToolTip>
      ),
      dataIndex: 'role',
      key: 'role',
      width: 260,
      render: (role: any, record: any) => (
        <Select
          size='small'
          value={getNameOnRole(allRoles, role)}
          onChange={(event: any) => onChangeHandler(record.role, 'role', event)}
        >
          {allRoles.map((role: any) => (
            <Select.Option value={role.id} key={role.id}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: <span className='small-heading-text'>{language.action}</span>,
      key: 'action',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={language.sure_to_delete}
            onConfirm={() => onDeleteRow(record.data)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '1200px' }}>
      <Button
        type='default'
        size='small'
        icon={<PlusOutlined />}
        onClick={onAddRow}
        style={{ marginBottom: '16px', width: '120px' }}
      >
        {language.add_row}
      </Button>
      <Table
        size='small'
        dataSource={dataSource?.map(
          (data: TOrganizationAndRoles, index: number) => ({
            key: index,
            ...data,
            data,
          }),
        )}
        columns={columns}
        style={{ marginBottom: '16px' }}
      />
    </div>
  );
};
