import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import api from '../services/api.service';

// TYPES
import { ReservationMode, TReservationMode } from '../types/reservation.type';
import { TNewSortOrderReservationModes } from '../types/sortOrder.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedReservationMode: ReservationMode.createEmpty(),
  allReservationModes: [],
  reservationModesForOtherPages: [],
  languages: [],
  limitations: {},
};

// Slice
const slice = createSlice({
  name: 'reservationModes',
  initialState,
  reducers: {
    fetchReservationModesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchReservationModesSuccess: (state: any, { payload }) => {
      if (state.selectedReservationMode.id > 0) {
        state.selectedReservationMode =
          payload.find(
            (mode: any) => mode.id === state.selectedReservationMode.id,
          ) || ReservationMode.createEmpty();
      }
      state.allReservationModes = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchReservationModesForOthersSuccess: (state: any, { payload }) => {
      state.reservationModesForOtherPages = payload;
    },
    fetchLanguagesReservationModesSuccess: (state: any, { payload }) => {
      state.languages = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsReservationModesSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchReservationModesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createReservationModeRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createReservationModeSuccess: (state: any, { payload }) => {
      state.allReservationModes.push(payload);
      state.selectedReservationMode = payload;
      finishedLoadingSuccess(state);
    },
    createReservationModeFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentReservationMode: (state: any, { payload }) => {
      state.selectedReservationMode = payload;
    },
    deleteReservationModeRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteReservationModeSuccess: (state: any, payload: any) => {
      state.allReservationModes = state.allReservationModes.filter(
        (mode: TReservationMode) => mode.id !== payload.payload.id,
      );
      state.selectedReservationMode = ReservationMode.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteReservationModeFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateReservationModeRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateReservationModeSuccess: (state: any, { payload }) => {
      const newReservationModes = [...state.allReservationModes];
      const index = state.allReservationModes.findIndex(
        (mode: TReservationMode) => mode.id === payload.id,
      );
      newReservationModes[index] = payload;
      state.allReservationModes = newReservationModes;
      state.selectedReservationMode = payload;
      finishedLoadingSuccess(state);
    },
    updateReservationModeFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateReservationModesOrderRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateReservationModesOrderSuccess: (state: any, payload: any) => {
      const newReservationModes = payload.payload
        .map((p: number) =>
          state.allReservationModes.find(
            (reservationMode: TReservationMode) => p === reservationMode.id,
          ),
        )
        .filter((mode: any) => mode !== undefined);
      state.allReservationModes = newReservationModes;
      finishedLoadingSuccess(state);
    },
    updateReservationModesOrderFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const reservationModesLoading = (state: any) =>
  state.reservationModes.loading;
export const reservationModesSelector = (state: any) =>
  state.reservationModes.allReservationModes as TReservationMode[];
export const reservationModesForOtherPagesSelector = (state: any) =>
  state.reservationModes.reservationModesForOtherPages;
export const reservationModeSelectorCreateNew = () => () => {
  return ReservationMode.createEmpty();
};
export const selectedReservationModeSelector = (state: any) => {
  return state.reservationModes.selectedReservationMode;
};
export const languagesReservationModesSelector = (state: any) =>
  state.reservationModes.languages as [];
export const limitationsReservationModesSelector = (state: any) =>
  state.reservationModes.limitations;

// Actions
export const {
  fetchReservationModesRequest,
  fetchReservationModesSuccess,
  fetchReservationModesForOthersSuccess,
  fetchLanguagesReservationModesSuccess,
  fetchLimitationsReservationModesSuccess,
  fetchReservationModesFailure,
  createReservationModeRequest,
  createReservationModeSuccess,
  createReservationModeFailure,
  setCurrentReservationMode,
  deleteReservationModeRequest,
  deleteReservationModeSuccess,
  deleteReservationModeFailure,
  updateReservationModeRequest,
  updateReservationModeSuccess,
  updateReservationModeFailure,
  updateReservationModesOrderRequest,
  updateReservationModesOrderSuccess,
  updateReservationModesOrderFailure,
} = slice.actions;

export const fetchReservationModes =
  (
    sortOption?: string | undefined,
    sortQuery?: string | undefined,
    templateTypeOption?: string,
    templateTypeQuery?: string,
  ) =>
  async (dispatch: any) => {
    try {
      let url = `/reservation-modes`;
      if (sortQuery !== undefined && templateTypeQuery === undefined) {
        url = `/reservation-modes?${sortOption}=${sortQuery}`;
      } else if (sortQuery === undefined && templateTypeQuery !== undefined) {
        url = `/reservation-modes?${templateTypeOption}=${templateTypeQuery}`;
      } else if (sortQuery !== undefined && templateTypeQuery !== undefined) {
        url = `/reservation-modes?${sortOption}=${sortQuery}&${templateTypeOption}=${templateTypeQuery}`;
      }
      dispatch(fetchReservationModesRequest());
      const reservationModes = await api.get({
        endpoint: url,
      });
      dispatch(fetchReservationModesSuccess(reservationModes));
    } catch (e) {
      dispatch(fetchReservationModesFailure());
      return console.error(e);
    }
  };

export const fetchReservationModesForOtherPages =
  () => async (dispatch: any) => {
    try {
      dispatch(fetchReservationModesRequest());
      const reservationModes = await api.get({
        endpoint: `/reservation-modes`,
      });
      dispatch(fetchReservationModesForOthersSuccess(reservationModes));
    } catch (e) {
      dispatch(fetchReservationModesFailure());
      return console.error(e);
    }
  };

export const fetchLanguagesForReservationModes =
  () => async (dispatch: any) => {
    try {
      dispatch(fetchReservationModesRequest());
      const languagesReservationModes = await api.get({
        endpoint: `/reservation-modes/languages`,
      });
      dispatch(
        fetchLanguagesReservationModesSuccess(languagesReservationModes),
      );
    } catch (e) {
      dispatch(fetchReservationModesFailure());
      return console.error(e);
    }
  };

export const fetchLimitationsForReservationModes =
  () => async (dispatch: any) => {
    try {
      dispatch(fetchReservationModesRequest());
      const limitationsReservationModes = await api.get({
        endpoint: `/reservation-modes/limitations`,
      });
      dispatch(
        fetchLimitationsReservationModesSuccess(limitationsReservationModes),
      );
    } catch (e) {
      dispatch(fetchReservationModesFailure());
      return console.error(e);
    }
  };

export const createReservationMode =
  (reservationMode: TReservationMode) => async (dispatch: any) => {
    try {
      dispatch(createReservationModeRequest());
      const { id: _, ...reservationModeBody } = reservationMode;
      const response = await api.post({
        endpoint: `/reservation-modes`,
        data: reservationModeBody,
      });
      dispatch(createReservationModeSuccess(response));
      dispatch(fetchReservationModes());
    } catch (e: any) {
      dispatch(createReservationModeFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const createEmptyReservationMode = () => async (dispatch: any) => {
  try {
    dispatch(createReservationModeRequest());
    const response = ReservationMode.createEmpty();
    dispatch(createReservationModeSuccess(response));
  } catch (e: any) {
    dispatch(createReservationModeFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedReservationMode =
  (reservationMode: TReservationMode) => async (dispatch: any) => {
    dispatch(setCurrentReservationMode(reservationMode));
  };

export const copyReservationMode =
  (reservationMode: TReservationMode) => async (dispatch: any) => {
    try {
      dispatch(createReservationModeRequest());
      const response = {
        ...reservationMode,
        id: 0,
      };
      dispatch(createReservationModeSuccess(response));
    } catch (e) {
      dispatch(createReservationModeFailure());
      return console.error(e);
    }
  };

export const deleteReservationMode =
  (reservationMode: TReservationMode) => async (dispatch: any) => {
    try {
      dispatch(deleteReservationModeRequest());
      await api.delete({
        endpoint: `/reservation-modes/${reservationMode.id}`,
      });
      dispatch(deleteReservationModeSuccess(reservationMode));
    } catch (e: any) {
      dispatch(deleteReservationModeFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const updateReservationMode =
  (reservationMode: TReservationMode) => async (dispatch: any) => {
    try {
      dispatch(updateReservationModeRequest());
      const { ...ReservationModeBody } = reservationMode;
      const response = await api.patch({
        endpoint: `/reservation-modes/${reservationMode.id}`,
        data: { ...ReservationModeBody },
      });
      dispatch(updateReservationModeSuccess(response));
    } catch (e: any) {
      dispatch(updateReservationModeFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const updateReservationModesOrder =
  (reservationModesOrder: TNewSortOrderReservationModes) =>
  async (dispatch: any) => {
    try {
      dispatch(updateReservationModesOrderRequest());
      const response = await api.patch({
        endpoint: `/reservation-modes/sort-order`,
        data: reservationModesOrder,
      });
      dispatch(updateReservationModesOrderSuccess(response));
    } catch (e: any) {
      dispatch(updateReservationModesOrderFailure());
      return console.error(e.response?.data ? e.response.data : e.response);
    }
  };
