import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './index.scss';
import {Form, Modal, Select} from 'antd';

// ACTIONS
import {setBreadcrumbs} from '../../../slices/ui.slice';


import intl from '../../../i18n/intl';
import NormalListView from "../../../components/List/NormalListView";
import ListView from "../../../components/List";

import {
    copyPaddingRule,
    createEmptyPaddingRule,
    createPaddingRule,
    criteriaDataListSelector,
    deletePaddingRule,
    fetchLimitationsForPaddingRules,
    fetchPaddingRules, limitationsPaddingRulesSelector,
    paddingRulesErrorMessage,
    paddingRulesSelector, removePaddingRuleViewerList,
    selectedPaddingRuleSelector, setCurrentPaddingRule,
    setTravelTimeRule,
    travelTimeRuleSelector,
    updatePaddingRule
} from "../../../slices/paddingRules.slice";

import {EPaddingRuleType, TPaddingRule} from "../../../types/padding.type";
import PaddingRulesFormComponent from "../../../components/PaddingComponents/PaddingRulesFormComponent";
import SectionHeader from "../../../components/SectionHeader";
import {cloneDeep} from "lodash";
import PaddingRulesViewerComponent from "../../../components/PaddingComponents/PaddingRulesViewComponent";


type Props = {
    customerSignature: string;
};

const language: any = intl.messages;

const PaddingPage = ({customerSignature}: Props) => {
    const dispatch = useDispatch();
    const paddingRules: TPaddingRule[] = useSelector(paddingRulesSelector)
    const limitations = useSelector(limitationsPaddingRulesSelector);

    const paddingRule = useSelector(selectedPaddingRuleSelector)
    const errorMessage = useSelector(paddingRulesErrorMessage);
    const [errorMessageState, setErrorMessageState] = useState(false);
    const [isViewerEnabled, setIsViewerEnabled] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedPaddingType, setSelectedPaddingType] = useState(EPaddingRuleType.DIRECT);

    const listData = paddingRules.map((paddingRule: TPaddingRule) => ({
        listLabel: paddingRule.name,
        value: paddingRule.id,
    }))
    const criteriaDataList = useSelector(criteriaDataListSelector);
    const travelTimeRule = useSelector(travelTimeRuleSelector);

    useEffect(() => {
        dispatch(fetchPaddingRules());
        dispatch(fetchLimitationsForPaddingRules())
    }, []);

    useEffect(() => {
        dispatch(
            setBreadcrumbs([
                {path: `/`, label: `${customerSignature}`},
                {
                    path: `/system`,
                    label: `${language.system}`,
                },
                {
                    path: `/system/padding-rules`,
                    label: `${language.padding}`,
                },
            ]),
        );
        setErrorMessageState(false);
    }, [
        dispatch,
        customerSignature,
    ]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCreate = ({impactedObjects}: any = {}) => {
        dispatch(createEmptyPaddingRule(selectedPaddingType, impactedObjects || []));
        setIsModalVisible(false);
        setIsViewerEnabled(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const toggleViewer = () => {
        dispatch(removePaddingRuleViewerList());
        setIsViewerEnabled(!isViewerEnabled);
    };

    const onPaddingRuleSelected = (selectedValue: any) => {
        const currentPaddingRule = paddingRules.find((paddingRule: TPaddingRule) => paddingRule.id === selectedValue);
        if (currentPaddingRule) {
            if (currentPaddingRule.paddingType === EPaddingRuleType.TRAVEL_TIME) {
                dispatch(setTravelTimeRule(currentPaddingRule.rule));
            }
        }
        dispatch(setCurrentPaddingRule(currentPaddingRule));
        if (isViewerEnabled) toggleViewer();

    }

    const onSaveChangePaddingRuleButton = async () => {
        const paddingRuleCopy = cloneDeep(paddingRule);

        if (paddingRuleCopy.paddingType === EPaddingRuleType.TRAVEL_TIME) {
            // Fetch travel time padding rule ( apparently can't use selector here )
            paddingRuleCopy.rule = cloneDeep(travelTimeRule);
        }

        paddingRuleCopy.rule.impactedObjects = criteriaDataList
            .filter((criteria: any) => criteria.impactedObject === true)
            .map((criteria: any) => criteria.item);

        if (paddingRuleCopy.paddingType === EPaddingRuleType.DIRECT) {
            paddingRuleCopy.rule.objectsOnReservation = criteriaDataList
                .filter((criteria: any) => criteria.impactedObject === false)
                .map((criteria: any) => criteria.item);
        }

        if (paddingRule.id === 0 || paddingRule.id === -1) {
            await dispatch(createPaddingRule(paddingRuleCopy));
            errorMessage === {} ? setErrorMessageState(false) : setErrorMessageState(true);
        } else {
            await dispatch(updatePaddingRule(paddingRuleCopy));
            errorMessage === {} ? setErrorMessageState(false) : setErrorMessageState(true);
        }
    }

    const onDiscard = () => {
        onPaddingRuleSelected(paddingRule.id);
    }

    const onHandleChange = (property: string, value: any) => {
        dispatch(setCurrentPaddingRule({...paddingRule, [property]: value}))
    }

    const onDeleteButton = () => {
        if (paddingRule.id !== 0) dispatch(deletePaddingRule(paddingRule))
    };

    const onCopy = () => {
        dispatch(copyPaddingRule(paddingRule));
    };

    const [form] = Form.useForm();


    return (
        <>

            <ListView
                sectionHeading={language.padding_rules}
                number={listData.length}
                onSelect={showModal}
            >
                <NormalListView
                    data={listData}
                    onSelect={onPaddingRuleSelected}
                    onDelete={onDeleteButton}
                    onCopy={onCopy}
                    selectedItemId={paddingRule?.id}
                />
                <div className={"viewer-button"} onClick={() => {
                    toggleViewer();
                }}>Viewer
                </div>
            </ListView>
            {/* @ts-ignore */}
            <Modal title={language.select_padding_type} visible={isModalVisible} onOk={handleCreate}
                   onCancel={handleCancel}>
                <Select value={selectedPaddingType} style={{width: "200px"}}
                        onSelect={(value: any) => setSelectedPaddingType(value)}>
                    <Select.Option key={1} value={EPaddingRuleType.DIRECT}>{language.direct_padding}</Select.Option>
                    <Select.Option key={2}
                                   value={EPaddingRuleType.TRAVEL_TIME}>{language.travel_time_padding}</Select.Option>
                </Select>
            </Modal>
            {paddingRule && !isViewerEnabled &&
                (
                    <div className={"inner-content--wrapper"} style={{width: "calc(100vw - 37.0rem)"}}>
                        <SectionHeader
                            listChoice={`${paddingRule.name}`}
                            changeHandler={onSaveChangePaddingRuleButton}
                            discardHandler={onDiscard}
                            displayProp={false}
                            isDisabled={false}
                            error={errorMessageState || errorMessage ? `${errorMessage}` : null} history={false}/>
                        <div className='inner-content--wrapper'>
                            <Form layout='vertical' form={form}>
                                <PaddingRulesFormComponent onChange={onHandleChange} paddingRule={paddingRule}
                                                           limitations={limitations}/>
                            </Form>
                        </div>
                    </div>)
            }
            {isViewerEnabled && (
                <>
                    <PaddingRulesViewerComponent onPaddingRuleSelected={onPaddingRuleSelected}
                                                 handleCreate={handleCreate}/>
                </>
            )}
        </>
    );
}

export default PaddingPage;