import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import { HolidayReservationsComponent } from '../../../components/HolidayReservationComponent';

// TYPES
import { THolidayRegion } from '../../../types/holidayreservation.type';

// ACTIONS
import {
  fetchHolidayRegions,
  holidayRegionsSelector,
} from '../../../slices/holidayReservation.slice';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const HolidayReservationsPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const holidayRegions: THolidayRegion[] = useSelector(holidayRegionsSelector);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/holidays`,
          label: `${language.holiday_reservations}`,
        },
      ]),
    );
    dispatch(fetchHolidayRegions());
  }, [customerSignature, dispatch]);

  return (
    <div className='inner-content--wrapper'>
      <SectionHeader
        listChoiceHeading={language.holiday_reservations}
        displayProp
        history={false}
      />
      <HolidayReservationsComponent holidayRegions={holidayRegions} />
    </div>
  );
};

export default HolidayReservationsPage;
