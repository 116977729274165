import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

// REDUX
import { typesSelector } from '../../slices/type.slice';
import {
  fetchFieldsForObjects,
  fieldsForObjectsSelector,
} from '../../slices/field.slice';
import { organizationsSelector } from '../../slices/organization.slice';
import {
  fetchObjects,
  objectsSelector,
  selectedObjectsSelector,
  setSelectedObjects,
  copyObject,
  createEmptyObject,
  setListObjects,
} from '../../slices/object.slice';
import {
  getView,
  initView,
  selectVisibleColsForDatasourceId,
  updateView,
} from '../../slices/ui.slice';

// i18N
import intl from '../../i18n/intl';

// STYLES
import {
  Table,
  Modal,
  Space,
  Popconfirm,
  Button,
  Checkbox,
  Select,
  Dropdown,
  Menu,
  Input,
  Divider,
} from 'antd';
import {
  DeleteOutlined,
  CopyOutlined,
  DownOutlined,
  PlusSquareOutlined,
  PlusCircleFilled,
  MinusSquareOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import './index.scss';

// TYPES
import {
  TTEObject,
  EProperty,
  EPersonalInformation,
  TEObject,
  TTEFieldValue,
  DateRange,
  DateRangeRelations,
} from '../../types/object.type';
import { TType } from '../../types/type.type';
import { TField } from '../../types/field.type';
import { TOrganizationTreeNode } from '../../types/organization.type';
import { IState } from '../../types/state.interface';

// UTILS
import { getNonReferenceFields } from '../../utils/objectUtils';
import {
  getNameOnType,
  getNameOnOrganization,
  getExtIdName,
} from '../../utils/getNameOn';

// COMPONENTS
import { SingleObjectEditor } from '../SingleObjectEditor';
import { SelectMultipleModal } from '../Modals';
import {
  renderElementsSingleRow,
  RenderHeadingElement,
} from '../RenderElements/index';
import { TEToolTip } from '../TEToolTip';

type Props = {
  listedObjects: TTEObject[];
  onChange: any;
  onChangeTwoProperties: any;
  limitations: any;
  number: number;
  onDelete: any;
  selectedTypeSearch: any;
};

const language: any = intl.messages;

/* -------------------------------------------------------------------------------------------------- */

export const ObjectsMassChangesView = ({
  listedObjects,
  onChange,
  onChangeTwoProperties,
  limitations,
  number,
  onDelete = () => {},
  selectedTypeSearch,
}: Props) => {
  const dispatch = useDispatch();
  const selectedObjects: TTEObject[] = useSelector(selectedObjectsSelector);
  const allObjects: TTEObject[] = useSelector(objectsSelector);
  const fieldsForObjects: TField[] = useSelector(fieldsForObjectsSelector);
  const allTypes: TType[] = useSelector(typesSelector);
  const allOrganizations: TOrganizationTreeNode[] = useSelector(
    organizationsSelector,
  );

  useEffect(() => {
    dispatch(fetchObjects());
    dispatch(fetchFieldsForObjects());
  }, [dispatch]);

  // Effect to load stored views and to update stored views
  useEffect(() => {
    dispatch(getView('objectTable'));
    dispatch(initView('objectTable'));
  }, [dispatch]);

  // All states used in this component
  const [showModal, setShowModal] = useState('');
  const [orgId, setOrgId] = useState<any>();
  const [relationObjectId, setRelationObjectId] = useState<any>();
  const [memberObjectId, setMemberObjectId] = useState<any>();
  const [optionalObjectId, setOptionalObjectId] = useState<any>();
  const [availabilityObjectId, setAvailabilityObjectId] = useState<any>();
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState<any>();
  const [showCheckboxModal, setShowCheckboxModal] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState<any>();

  let fieldIdForSelectedType: any = [];
  if (selectedTypeSearch !== undefined) {
    const type = allTypes.find((type: TType) => type.id === selectedTypeSearch);
    fieldIdForSelectedType = getNonReferenceFields(fieldsForObjects, type);
  }

  // Add new object and add it to the listed objects
  const onAddObject = () => {
    const newListedObjects: any = [...listedObjects];
    newListedObjects.push(
      TEObject.createEmpty(selectedTypeSearch, fieldIdForSelectedType),
    );
    const newObjects: any = [];
    newObjects.push(
      TEObject.createEmpty(selectedTypeSearch, fieldIdForSelectedType),
    );
    dispatch(createEmptyObject(selectedTypeSearch, fieldIdForSelectedType));
    dispatch(setSelectedObjects(newObjects));
    dispatch(setListObjects(newListedObjects));
    setExpandedRowKey([0]);
  };

  // Copy object and set it as the selected object
  const onCopy = (selectedObjects: any[]) => {
    dispatch(copyObject(selectedObjects[0]));
    setExpandedRowKey([0]);
  };

  // EVENT HANDLERS
  // Event handlers for handle MASS CHANGES of all selected objects:
  // Change handler for Select-elements
  const handleSelectChange = (objects: any, event: any, property: string) => {
    onChange(event, property, objects);
  };

  // Adding values to an existing array with values
  const onHandleAdd = (
    objects: any,
    value: any,
    property: string,
    fieldId?: number,
  ) => {
    if (property === 'teFields') {
      const updatedObjects = objects.map((object: any) => {
        const updatedFields = object.teFields.map((teField: any) => {
          if (teField.id !== fieldId) {
            return teField;
          } else {
            return Object.assign({}, teField, {
              values: [...teField.values, value],
            });
          }
        });
        return Object.assign({}, object, { teFields: updatedFields });
      });
      dispatch(setSelectedObjects(updatedObjects));
    } else if (property === 'availabilityRelations') {
      const updatedObjects = objects.map((object: any) => {
        const updatedAvailabilityRelations = object.availabilityRelations.map(
          (availabilityRelation: any) => {
            return Object.assign({}, availabilityRelation, {
              objectIds: [...availabilityRelation.objectIds, value],
            });
          },
        );
        return Object.assign({}, object, {
          availabilityRelations: updatedAvailabilityRelations,
        });
      });
      dispatch(setSelectedObjects(updatedObjects));
    } /* else if (property === 'relations') {
      const updatedObjects = objects.map((object: any) => {
        const updatedRelations = object.relations.map((relation: any) => {
          return Object.assign({}, relation, {
            objectIds: [...relation.objectIds, value],
          });
        });
        return Object.assign({}, object, {
          relations: updatedRelations,
        });
      });
      dispatch(setSelectedObjects(updatedObjects)); 
    } */ else {
      const updatedObjects = objects.map((object: any) => {
        const newProperty: any = {};
        newProperty[property] = [...object[property], value];
        return Object.assign({}, object, newProperty);
      });
      dispatch(setSelectedObjects(updatedObjects));
    }
  };

  // Removing values from an existing array with values
  const onHandleRemove = (
    objects: any,
    value: any,
    property: string,
    fieldId?: any,
  ) => {
    if (property === 'teFields') {
      const updatedObjects = objects.map((object: any) => {
        const updatedFields = object.teFields.map((teField: any) => {
          if (teField.id !== fieldId) {
            return teField;
          } else {
            return Object.assign({}, teField, {
              values: [...teField.values.filter((v: any) => v !== value)],
            });
          }
        });
        return Object.assign({}, object, { teFields: updatedFields });
      });
      dispatch(setSelectedObjects(updatedObjects));
    } else if (property === 'members') {
      const updatedObjects = objects.map((object: any) => {
        const newProperty: any = {};
        newProperty[property] = object[property].filter(
          (v: any) => value.indexOf(v.objectId) === -1,
        );
        return Object.assign({}, object, newProperty);
      });
      dispatch(setSelectedObjects(updatedObjects));
    }
    // PRATA MED DH om hur vi vill den här funktionen ska fungera / vill vi ens ha den i mass-viewn..?
    /* else if (property === 'relations') {
      const updatedObjects = objects.map((object: any) => {
        const newProperty: any = {};
        newProperty.relations = object.relations.filter(
          (relation: any) => relation.objectIds.includes(!value), // få till denna
        );
        return Object.assign({}, object, newProperty);
      });
      dispatch(setSelectedObjects(updatedObjects));
    }  */
    else if (property === 'availabilityRelations') {
      const updatedObjects = objects.map((object: any) => {
        const updatedAvailabilityRelations = object.availabilityRelations.map(
          (availabilityRelation: any) => {
            return Object.assign({}, availabilityRelation, {
              objectIds: [
                ...availabilityRelation.objectIds.filter(
                  (id: any) => id !== value,
                ),
              ],
            });
          },
        );
        return Object.assign({}, object, {
          availabilityRelations: updatedAvailabilityRelations,
        });
      });
      dispatch(setSelectedObjects(updatedObjects));
    } else {
      const updatedObjects = objects.map((object: any) => {
        const newProperty: any = {};
        newProperty[property] = object[property].filter(
          (v: any) => value.indexOf(v) === -1,
        );
        return Object.assign({}, object, newProperty);
      });
      dispatch(setSelectedObjects(updatedObjects));
    }
  };

  // SINGLE OBJECT change handlers:
  const onChangeSingleObjectHandler = (
    object: TTEObject,
    event: any,
    property: string,
  ) => {
    onChange(event, property, [object]);
  };

  // Function for changing field values on single object
  const onChangeFieldValues = (
    objects: any,
    fieldValue: any,
    index: number,
    fieldId: number,
  ) => {
    const updatedObjects = objects.map((object: any) => {
      const field = Object.assign(
        {},
        object.teFields.find(
          (teField: TTEFieldValue) => teField.fieldId === fieldId,
        ),
      );
      if (!field.values) {
        return object;
      }
      field.values = [...field.values];
      field.values[index] = fieldValue;
      const newFields = [...object.teFields];
      let fieldIndex = -1;
      newFields.forEach((newField: any, idx: number) => {
        if (newField.fieldId === field.fieldId) {
          fieldIndex = idx;
        }
      });
      newFields.splice(fieldIndex, 1, field);
      return Object.assign({}, object, { teFields: newFields });
    });
    dispatch(setSelectedObjects(updatedObjects));
  };

  // Properties menu to choose value from
  const properties = (
    <Menu
      onClick={(value: any) =>
        handleSelectChange(selectedObjects, value.key, 'property')
      }
    >
      {Object.entries(EProperty).map(([key, value]) => (
        <Menu.Item key={key}>{language[value]}</Menu.Item>
      ))}
    </Menu>
  );

  // Personal information menu to choose values from
  const personalInformation = (
    <Menu
      onClick={(value: any) =>
        handleSelectChange(selectedObjects, value.key, 'personalInformation')
      }
    >
      {Object.entries(EPersonalInformation).map(([key, value]) => (
        <Menu.Item key={key}>{language[value]}</Menu.Item>
      ))}
    </Menu>
  );

  // Functions for handling visbility for modals where we have adding and removing possibilites
  const onModalOpen = (modalId: string) => {
    setShowModal(modalId);
  };
  const onModalClose = () => {
    setShowModal('');
  };

  // Functions updating right id on onHandleAdd()
  const updateOrgId = (value: any) => {
    setOrgId(value);
  };
  const updateRelationObjectId = (value: any) => {
    setRelationObjectId(value);
  };
  const updateMemberObjectId = (value: any) => {
    setMemberObjectId(value);
  };
  const updateOptionalObjectId = (value: any) => {
    setOptionalObjectId(value);
  };
  const updateAvailabilityObjectId = (value: any) => {
    setAvailabilityObjectId(value);
  };

  // Re-useable function when saving in Modal -  calling onHandleAdd function
  const handleOK = useCallback(
    (value: any, property: string) => {
      onHandleAdd(selectedObjects, value, property);
      onModalClose();
    },
    [
      orgId,
      relationObjectId,
      memberObjectId,
      optionalObjectId,
      availabilityObjectId,
    ],
  );

  // Re-useable function when saving in Modal -  calling onHandleRemove function
  const handleOKremove = useCallback(
    (value: any, property: string) => {
      onHandleRemove(selectedObjects, value, property);
      onModalClose();
    },
    [
      orgId,
      relationObjectId,
      memberObjectId,
      optionalObjectId,
      availabilityObjectId,
    ],
  );

  const [modalValue, setModalValue] = useState<any>();
  const [modalCategories, setModalCategories] = useState<any>();

  // Functions for adding categories on editable fields with fieldType: category
  const onAddCategoryModalOpen = (fieldId: number, categories: any[]) => {
    setAddCategoryModal(true);
    setModalValue(fieldId);
    setModalCategories(categories);
  };
  const onAddCategoryModalClose = () => {
    setAddCategoryModal(false);
    setModalValue(null);
    setModalCategories(null);
  };

  const onAddCategory = (fieldId: number, categories: any[], value: string) => {
    const newCategory: any[] = [...categories];
    newCategory.push(value);
    onChangeFieldValues(selectedObjects, value, 0, fieldId);
    setAddCategoryModal(false);
  };

  // Function for getting the full object by id
  const getFullObject = (objectKey: any) => {
    const result = selectedObjects.find((o: any) => o.id === objectKey);
    if (result) {
      return result;
    }
    return objectKey;
  };

  // Filter out only the fields that exist on the listedObjects
  const fieldIds = _.flatten(
    listedObjects?.map((listedObject: any) =>
      listedObject.teFields?.map((teField: any) => teField.fieldId),
    ),
  );

  // Field info to show in the columns
  const columnFields = fieldsForObjects?.filter(
    (field: any) => fieldIds.indexOf(field.id) !== -1,
  );

  // GUI Settings
  // Value for the columns we want to send to TE Views
  const visibleCols = useSelector((state: IState) =>
    selectVisibleColsForDatasourceId(state, 'objectTable'),
  );

  const updateVisibleCols = (columns: string[], value: any) => {
    const updatedValues = { ...visibleCols };
    columns.forEach((column: string) => {
      updatedValues[column] = value;
    });
    dispatch(updateView('objectTable', updatedValues));
  };

  const additionalColumnChoices = [
    'members',
    'relations',
    'organizations',
    'optional_relations',
    'availability_relations',
    'created',
    'created_by',
    'modified',
    'modified_by',
  ];

  const actionsProperties = ['delete', 'copy', 'activate_deactivate'];

  const systemInfoProperties = [
    'id',
    'ext_id',
    'property',
    'personal_information',
    'type',
  ];

  // Handlers for selecting/unselect all columns within an area
  const onSelectAllColumns = (arrayOfColumns: any[], checked: boolean) => {
    if (checked === true) {
      updateVisibleCols(arrayOfColumns, true);
    }
  };
  const onDeselectAllColumns = (arrayOfColumns: any[], checked: boolean) => {
    if (checked === true) {
      updateVisibleCols(arrayOfColumns, false);
    }
  };

  // ALL available columns to show
  const columns: any = [
    {
      title: <span className='small-heading-text'>{language.ext_id}</span>,
      dataIndex: 'extId',
      key: 'extId',
      width: 100,
      className: 'column-item table-column-item',
      render: (extId: string) => <span key={Math.random()}>{extId}</span>,
      sorter: (a: any, b: any) => a.extId.localeCompare(b.extId),
    },
  ];

  if (visibleCols.type === true) {
    columns.push({
      title: <span className='small-heading-text'>{language.types}</span>,
      width: 150,
      dataIndex: 'types',
      key: 'types',
      className: 'column-item types-column table-column-item',
      render: (types: any) => (
        <span key={Math.random()}>
          {types?.map((t: any) => (
            <span key={t}>{getNameOnType(allTypes, t)} </span>
          ))}
        </span>
      ),
    });
  }

  if (visibleCols.delete === true) {
    columns.push({
      title: (
        <Popconfirm
          title={language.sure_to_delete_type}
          onConfirm={() => onDelete(selectedObjects)}
          onCancel={() => console.info('should do nothing, no delete')}
          okText={language.yes}
          cancelText={language.no}
        >
          <Button size='small' danger className='delete-all-objects-btn'>
            <span>{language.delete_all}</span>
          </Button>
        </Popconfirm>
      ),
      key: 'action',
      width: 80,
      className: 'center-aligned column-item table-column-item',
      render: (record: any) => (
        <Space size='small'>
          <Popconfirm
            title={language.sure_to_delete_type}
            onConfirm={() => onDelete([record])}
            onCancel={() => console.info('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='objects-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    });
  }

  if (visibleCols.copy === true) {
    columns.push({
      title: <span className='small-heading-text'>{language.copy}</span>,
      key: 'action',
      width: 60,
      className: 'center-aligned column-item table-column-item',
      render: (record: any) => (
        <Space size='small'>
          <Button
            size='small'
            type='default'
            className='objects-list-action-button-copy'
            icon={<CopyOutlined />}
            onClick={() => onCopy([record.listedObject])}
          />
        </Space>
      ),
    });
  }

  if (visibleCols.ext_id === true) {
    columns.push({
      title: <span className='small-heading-text'>{language.ext_id}</span>,
      dataIndex: 'extId',
      key: 'extId',
      width: 100,
      className: 'column-item table-column-item',
      render: (extId: string) => <span key={Math.random()}>{extId}</span>,
      sorter: (a: any, b: any) => a.extId.localeCompare(b.extId),
    });
  }

  if (visibleCols.id === true) {
    columns.push({
      title: <span className='small-heading-text'>{language.id}</span>,
      dataIndex: 'id',
      key: 'id',
      width: 70,
      className: 'id column-item table-column-item',
      render: (id: number) => (
        <span style={{ maxWidth: 10 }} key={Math.random()}>
          {id}
        </span>
      ),
      sorter: (a: any, b: any) => a.id - b.id,
    });
  }

  // Field values will only be rendered if the listedObjects has the same type
  const leftType: any = listedObjects[0]?.types;
  if (
    listedObjects.every((listedObject: TTEObject) => {
      const rightType = listedObject.types;
      return (
        leftType.length === rightType.length &&
        _.intersection(leftType, rightType).length === leftType.length
      );
    })
  ) {
    columnFields
      .filter((columnField: any) => visibleCols[columnField.extId] === true)
      .forEach((field: any) => {
        columns.push({
          title: (
            <div className='heading-item-column' key={field.id}>
              <span className='small-heading-text' key={field.id}>
                {field.name}
              </span>
            </div>
          ),
          width: 200,
          key: field.id,
          className: 'column-item table-column-item',
          children: [
            {
              title: (
                <RenderHeadingElement
                  type={field.fieldType}
                  name={field.name}
                  fieldId={field.id}
                  fieldCategories={field.categories}
                  editable={field.editable}
                  multiple={field.multiple}
                  onChangeFieldValues={onChangeFieldValues}
                  onHandleAdd={onHandleAdd}
                  onHandleRemove={onHandleRemove}
                  selectedObjects={selectedObjects}
                  onAddCategoryModalOpen={onAddCategoryModalOpen}
                />
              ),
              dataIndex: 'teFields',
              key: field.id,
              width: 200,
              className: `${field.name} column-item table-column-item`,
              render: (teFields: any, record: any) => {
                const fieldSingle = teFields.find(
                  (teField: TTEFieldValue) => teField.fieldId === field.id,
                );
                return fieldSingle ? (
                  <div key={field.id} className={'field-values-column'}>
                    {renderElementsSingleRow(
                      record,
                      field.fieldType,
                      fieldSingle.values || [],
                      teFields,
                      fieldSingle.fieldId,
                      field.categories,
                      onChangeFieldValues,
                    )}
                  </div>
                ) : null;
              },
            },
          ],
        });
      });
  }

  if (visibleCols.personal_information === true) {
    columns.push({
      title: (
        <span className='small-heading-text'>
          {language.personal_information}
        </span>
      ),
      width: 200,
      key: 'personalInformation',
      className: 'column-item table-column-item',
      filters: [
        {
          text: `${language.yes}`,
          value: 'yes',
        },
        {
          text: `${language.no}`,
          value: 'no',
        },
        {
          text: `${language.default}`,
          value: 'default',
        },
      ],
      onFilter: (value: any, record: any) =>
        record.personalInformation.indexOf(value) === 0,
      children: [
        {
          title: (
            <Dropdown overlay={personalInformation}>
              <Button
                size='small'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <span
                  className='small-heading-text'
                  style={{ marginBottom: 4 }}
                >
                  {language.personal_information}
                </span>
                <DownOutlined />
              </Button>
            </Dropdown>
          ),
          dataIndex: 'personalInformation',
          key: 'personalInformation',
          width: 200,
          className: 'column-item table-column-item',
          render: (personalInformation: string, record: any) => (
            <Select
              size='small'
              dropdownMatchSelectWidth
              value={personalInformation}
              onChange={(value: any) =>
                onChangeSingleObjectHandler(
                  record.listedObject,
                  value,
                  'personalInformation',
                )
              }
            >
              {Object.entries(EPersonalInformation).map(([key, value]) => (
                <Select.Option key={key} value={key}>
                  {language[value]}
                </Select.Option>
              ))}
            </Select>
          ),
        },
      ],
    });
  }

  if (visibleCols.property === true) {
    columns.push({
      title: <span className='small-heading-text'>{language.property}</span>,
      width: 160,
      key: 'property',
      className: 'column-item table-column-item',
      filters: [
        {
          text: `${language.standard}`,
          value: 'standard',
        },
        {
          text: `${language.abstract}`,
          value: 'abstract',
        },
        {
          text: `${language.virtualAbstract}`,
          value: 'virtualAbstract',
        },
        {
          text: `${language.virtualStandard}`,
          value: 'virtualStandard',
        },
        {
          text: `${language.templateObject}`,
          value: 'templateObject',
        },
      ],
      onFilter: (value: any, record: any) =>
        record.property.indexOf(value) === 0,
      children: [
        {
          title: (
            <Dropdown overlay={properties}>
              <Button
                size='small'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <span
                  className='small-heading-text'
                  style={{ marginBottom: 4 }}
                >
                  {language.property}
                </span>
                <DownOutlined />
              </Button>
            </Dropdown>
          ),
          dataIndex: 'property',
          key: 'property',
          width: 160,
          className: 'column-item table-column-item',
          render: (property: string, record: any) => (
            <Select
              size='small'
              dropdownMatchSelectWidth
              value={property}
              onChange={(value: any) => {
                onChangeSingleObjectHandler(
                  record.listedObject,
                  value,
                  'property',
                );
              }}
            >
              {Object.entries(EProperty).map(([key, value]) => (
                <Select.Option key={key} value={key}>
                  {language[value]}
                </Select.Option>
              ))}
            </Select>
          ),
        },
      ],
    });
  }

  if (visibleCols.activate_deactivate === true) {
    columns.push({
      width: 210,
      key: 'active',
      dataIndex: 'active',
      className: 'center-aligned column-item table-column-item',
      filters: [
        {
          text: `${language.active}`,
          value: true,
        },
        {
          text: `${language.not_active}`,
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => record.active === value,
      title: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            size='small'
            onClick={() => handleSelectChange(selectedObjects, false, 'active')}
          >
            <span>{language.de_activate}</span>
          </Button>
          <Button
            size='small'
            onClick={() => handleSelectChange(selectedObjects, true, 'active')}
          >
            <span>{language.activate}</span>
          </Button>
        </div>
      ),
      render: (active: any, record: any) => (
        <Checkbox
          checked={active}
          onChange={(event: any) =>
            onChangeSingleObjectHandler(
              record.listedObject,
              event.target.checked,
              'active',
            )
          }
        />
      ),
    });
  }

  if (visibleCols.members === true) {
    columns.push({
      title: (
        <div className='heading-item'>
          <span key={Math.random()} className='small-heading-text'>
            {language.members}
          </span>
        </div>
      ),
      width: 100,
      key: 'members',
      className: 'table-column-item',
      children: [
        {
          title: (
            <>
              <Button
                size='middle'
                icon={<PlusSquareOutlined />}
                style={{ paddingRight: 10, background: 'none' }}
                className='dropdown-button'
                onClick={() => onModalOpen('membModal')}
              />
              <Button
                size='middle'
                icon={<MinusSquareOutlined />}
                style={{ background: 'none' }}
                className='dropdown-button'
                onClick={() => onModalOpen('removeMembModal')}
              />
              <SelectMultipleModal
                title={language.add_member_object}
                okText={language.save_added_member_object}
                onOK={() => {
                  memberObjectId.map((id: any) => {
                    return handleOK(DateRange.create(id), 'members');
                  });
                }}
                visible={showModal === 'membModal'}
                onCancel={onModalClose}
                array={allObjects.map((object: any) => (
                  <Select.Option
                    key={object.id}
                    value={object.id}
                    label={object.extId}
                  >
                    {object.extId}
                  </Select.Option>
                ))}
                update={updateMemberObjectId}
                value={memberObjectId}
              />
              <SelectMultipleModal
                title={language.remove_member_object}
                okText={language.save_removed_member_object}
                onOK={() => {
                  memberObjectId.map((id: any) => {
                    return handleOKremove(id, 'members');
                  });
                }}
                onCancel={onModalClose}
                visible={showModal === 'removeMembModal'}
                array={allObjects.map((object: any) => (
                  <Select.Option
                    key={object.id}
                    value={object.id}
                    label={object.extId}
                  >
                    {object.extId}
                  </Select.Option>
                ))}
                update={updateMemberObjectId}
                value={memberObjectId}
              />
            </>
          ),
          dataIndex: 'members',
          key: 'members',
          width: 100,
          className: 'column-item table-column-item',
          render: (members: any) => (
            <span key={Math.random()}>
              {members?.map((member: any) => (
                <span key={member}>
                  {getExtIdName(allObjects, member.objectId)},
                </span>
              ))}
            </span>
          ),
        },
      ],
    });
  }
  if (visibleCols.relations === true) {
    columns.push({
      title: (
        <div className='heading-item'>
          <span className='small-heading-text'>{language.relations}</span>
        </div>
      ),
      width: 100,
      key: 'relations',
      className: 'column-item table-column-item',
      onFilter: (value: any, record: any) =>
        record.relations.indexOf(value) === 0,
      children: [
        {
          title: (
            <>
              <Button
                size='middle'
                icon={<PlusSquareOutlined />}
                style={{ paddingRight: 10, background: 'none' }}
                className='dropdown-button'
                onClick={() => onModalOpen('relModal')}
              />
              {/*               <Button
                size='middle'
                icon={<MinusSquareOutlined />}
                style={{ background: 'none' }}
                className='dropdown-button'
                onClick={() => onModalOpen('removeRelModal')}
              /> */}
              <SelectMultipleModal
                title={language.add_relation_object}
                okText={language.save_added_relation_object}
                onOK={() => {
                  relationObjectId.map((id: any) => {
                    return handleOK(DateRangeRelations.create(id), 'relations');
                  });
                }}
                onCancel={onModalClose}
                visible={showModal === 'relModal'}
                array={allObjects.map((object: any) => (
                  <Select.Option
                    key={object.id}
                    value={object.id}
                    label={object.extId}
                  >
                    {object.extId}
                  </Select.Option>
                ))}
                update={updateRelationObjectId}
                value={relationObjectId}
              />
            </>
          ),
          dataIndex: 'relations',
          key: 'relations',
          width: 100,
          className: 'column-item table-column-item',
          render: (relations: any) => (
            <TEToolTip
              key={relations}
              helpText={relations?.map((relation: any) =>
                relation.objectIds?.map((objectId: number) =>
                  getExtIdName(allObjects, objectId),
                ),
              )}
            >
              <span key={Math.random()}>
                {relations?.map((relation: any) =>
                  relation.objectIds?.map((objectId: number) => (
                    <span key={objectId}>
                      {getExtIdName(allObjects, objectId)},{' '}
                    </span>
                  )),
                )}
              </span>
            </TEToolTip>
          ),
        },
      ],
    });
  }

  if (visibleCols.organizations === true) {
    columns.push({
      title: (
        <div className='heading-item'>
          <span key={Math.random()} className='small-heading-text'>
            {language.organizations}
          </span>
        </div>
      ),
      width: 120,
      key: 'organizations',
      className: 'column-item table-column-item',
      children: [
        {
          title: (
            <>
              <Button
                size='middle'
                icon={<PlusSquareOutlined />}
                style={{ paddingRight: 10, background: 'none' }}
                className='dropdown-button'
                onClick={() => onModalOpen('orgModal')}
              />
              <Button
                size='middle'
                icon={<MinusSquareOutlined />}
                style={{ background: 'none' }}
                className='dropdown-button'
                onClick={() => onModalOpen('removeOrgModal')}
              />
              <SelectMultipleModal
                title={language.add_organization}
                okText={language.save_added_organization}
                onOK={() => {
                  orgId.map((id: any) => {
                    return handleOK(id, 'organizations');
                  });
                }}
                onCancel={onModalClose}
                visible={showModal === 'orgModal'}
                array={allOrganizations.map((object: any) => (
                  <Select.Option
                    key={object.id}
                    value={object.id}
                    label={object.name}
                  >
                    {object.name}
                  </Select.Option>
                ))}
                update={updateOrgId}
                value={orgId}
              />
              <SelectMultipleModal
                title={language.remove_organization}
                okText={language.save_removed_organization}
                onOK={() => {
                  orgId.map((id: any) => {
                    return handleOKremove(id, 'organizations');
                  });
                }}
                onCancel={onModalClose}
                visible={showModal === 'removeOrgModal'}
                array={allOrganizations.map((object: any) => (
                  <Select.Option
                    key={object.id}
                    value={object.id}
                    label={object.name}
                  >
                    {object.name}
                  </Select.Option>
                ))}
                update={updateOrgId}
                value={orgId}
              />
            </>
          ),
          dataIndex: 'organizations',
          key: 'organizations',
          width: 120,
          className: 'column-item table-column-item',
          render: (organizations: any) => (
            <span key={Math.random()}>
              {organizations?.map((organization: any) => (
                <span key={organization}>
                  {getNameOnOrganization(allOrganizations, organization)},
                </span>
              ))}
            </span>
          ),
        },
      ],
    });
  }

  if (visibleCols.availability_relations === true) {
    columns.push({
      title: (
        <div>
          <span className='small-heading-text'>
            {language.availability_relations}
          </span>
        </div>
      ),
      width: 160,
      key: 'availabilityRelations',
      dataIndex: 'availabilityRelations',
      className: 'column-item table-column-item',
      children: [
        {
          title: (
            <>
              <Button
                size='middle'
                icon={<PlusSquareOutlined />}
                style={{ paddingRight: 10, background: 'none' }}
                className='dropdown-button'
                onClick={() => onModalOpen('availRelModal')}
              />
              <Button
                size='middle'
                icon={<MinusSquareOutlined />}
                style={{ background: 'none' }}
                className='dropdown-button'
                onClick={() => onModalOpen('removeAvailRelModal')}
              />
              <SelectMultipleModal
                title={language.add_availability_relation_object}
                okText={language.save_added_availability_relation_object}
                onOK={() => {
                  availabilityObjectId.map((id: any) => {
                    return handleOK(id, 'availabilityRelations');
                  });
                }}
                onCancel={onModalClose}
                visible={showModal === 'availRelModal'}
                array={allObjects.map((object: any) => (
                  <Select.Option
                    key={object.id}
                    value={object.id}
                    label={object.extId}
                  >
                    {object.extId}
                  </Select.Option>
                ))}
                update={updateAvailabilityObjectId}
                value={availabilityObjectId}
              />
              <SelectMultipleModal
                title={language.remove_availability_relation_object}
                okText={language.save_removed_availability_relation_object}
                onOK={() => {
                  availabilityObjectId.map((id: any) => {
                    return handleOKremove(id, 'availabilityRelations');
                  });
                }}
                onCancel={onModalClose}
                visible={showModal === 'removeAvailRelModal'}
                array={allObjects.map((object: any) => (
                  <Select.Option
                    key={object.id}
                    value={object.id}
                    label={object.extId}
                  >
                    {object.extId}
                  </Select.Option>
                ))}
                update={updateAvailabilityObjectId}
                value={availabilityObjectId}
              />
            </>
          ),
          dataIndex: 'availabilityRelations',
          key: 'availabilityRelations',
          width: 160,
          className: 'column-item table-column-item',
          render: (availabilityRelations: any) => (
            <TEToolTip
              key={Math.random()}
              helpText={availabilityRelations?.map((relation: any) =>
                relation.objectIds.map((objectId: any) =>
                  getExtIdName(allObjects, objectId),
                ),
              )}
            >
              <span key={Math.random()}>
                {availabilityRelations?.map((relation: any) =>
                  relation.objectIds?.map((objectId: number) => (
                    <span key={objectId}>
                      {getExtIdName(allObjects, objectId)},
                    </span>
                  )),
                )}
              </span>
            </TEToolTip>
          ),
        },
      ],
    });
  }

  if (visibleCols.optional_relations === true) {
    columns.push({
      title: (
        <div>
          <span className='small-heading-text'>
            {language.optional_relations}
          </span>
        </div>
      ),
      width: 140,
      key: 'optionalRelations',
      className: 'column-item table-column-item',
      children: [
        {
          title: (
            <>
              <Button
                size='middle'
                icon={<PlusSquareOutlined />}
                style={{ paddingRight: 10, background: 'none' }}
                className='dropdown-button'
                onClick={() => onModalOpen('optRelModal')}
              />
              <Button
                size='middle'
                icon={<MinusSquareOutlined />}
                style={{ background: 'none' }}
                className='dropdown-button'
                onClick={() => onModalOpen('removeOptRelModal')}
              />
              <SelectMultipleModal
                title={language.add_optional_relation_object}
                okText={language.save_added_optional_relation_object}
                onOK={() => {
                  optionalObjectId.map((id: any) => {
                    return handleOK(id, 'optionalRelations');
                  });
                }}
                onCancel={onModalClose}
                visible={showModal === 'optRelModal'}
                array={allObjects.map((object: any) => (
                  <Select.Option
                    key={object.id}
                    value={object.id}
                    label={object.extId}
                  >
                    {object.extId}
                  </Select.Option>
                ))}
                update={updateOptionalObjectId}
                value={optionalObjectId}
              />
              <SelectMultipleModal
                title={language.remove_optional_relation_object}
                okText={language.save_removed_optional_relation_object}
                onOK={() => {
                  optionalObjectId.map((id: any) => {
                    return handleOKremove(id, 'optionalRelations');
                  });
                }}
                onCancel={onModalClose}
                visible={showModal === 'removeOptRelModal'}
                array={allObjects.map((object: any) => (
                  <Select.Option
                    key={object.id}
                    value={object.id}
                    label={object.extId}
                  >
                    {object.extId}
                  </Select.Option>
                ))}
                update={updateOptionalObjectId}
                value={optionalObjectId}
              />
            </>
          ),
          dataIndex: 'optionalRelations',
          key: 'optionalRelations',
          width: 140,
          className: 'column-item table-column-item',
          render: (optionalRelations: any) => (
            <span key={Math.random()}>
              {optionalRelations.map((optionalRelation: any) => (
                <span key={optionalRelation}>
                  {getExtIdName(allObjects, optionalRelation)}
                </span>
              ))}
            </span>
          ),
        },
      ],
    });
  }

  if (visibleCols.created === true) {
    columns.push({
      title: <span className='small-heading-text'>{language.created}</span>,
      key: 'created',
      width: '160px',
      dataIndex: 'created',
      className: 'column-item table-column-item',
      render: (created: any) => (
        <span key={Math.random()}>
          {moment.unix(created).format('YYYY-MM-DD')}
        </span>
      ),
      sorter: (a: any, b: any) => a.created - b.created,
    });
  }

  if (visibleCols.created_by === true) {
    columns.push({
      title: <span className='small-heading-text'>{language.created_by}</span>,
      key: 'createdBy',
      dataIndex: 'createdBy',
      className: 'column-item table-column-item',
      width: '150px',
      render: (createdBy: any) => <span key={Math.random()}>{createdBy}</span>,
      sorter: (a: any, b: any) => a.createdBy.localeCompare(b.createdBy),
    });
  }

  if (visibleCols.modified === true) {
    columns.push({
      title: <span className='small-heading-text'>{language.modified}</span>,
      key: 'history',
      dataIndex: 'history',
      className: 'column-item table-column-item',
      width: '180px',
      render: (history: Array<any>) => (
        <span key={Math.random()}>
          {history
            ? moment.unix(history[0]?.modified).format('YYYY-MM-DD')
            : ''}
        </span>
      ),
      sorter: (a: any, b: any) => a.modified - b.modified,
    });
  }

  if (visibleCols.modified_by === true) {
    columns.push({
      title: <span className='small-heading-text'>{language.modified_by}</span>,
      key: 'history',
      dataIndex: 'history',
      className: 'column-item table-column-item',
      width: '150px',
      render: (history: Array<any>) => (
        <span key={Math.random()}>{history ? history[0]?.modifiedBy : ''}</span>
      ),
      sorter: (a: any, b: any) => a.modifiedBy.localeCompare(b.modifiedBy),
    });
  }

  // Function for selecting row(s)
  const rowSelection: any = {
    selectedRowKeys: selectedObjects?.map(
      (selectedObject: any) => selectedObject.id,
    ),
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      dispatch(
        setSelectedObjects(
          selectedRows.map((selectedRow: any) => selectedRow.listedObject),
        ),
      );
    },
    getCheckboxProps: (record: TTEObject) => ({
      extId: record.extId,
    }),
  };

  // Function for setting only one row as expanded, the 'expandedRowKey'
  const onTableRowExpand = (expanded: any, record: any) => {
    const keys = [];
    if (expanded) {
      keys.push(record.id);
    }
    setExpandedRowKey(keys);
  };

  // Expanded row shows the single object view for the choosen object
  const expandedRowRender = () => {
    return (
      <SingleObjectEditor
        object={getFullObject(expandedRowKey[0])}
        onChange={onChange}
        onChangeTwoProperties={onChangeTwoProperties}
        limitations={limitations}
        objects={allObjects}
      />
    );
  };

  // Functions fo modal where the user has the possibility to set which columns to show
  const onCheckboxModalOpen = () => {
    setShowCheckboxModal(true);
  };
  const onCheckboxModalClose = () => {
    setShowCheckboxModal(false);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '8px',
          marginTop: '20px',
        }}
      >
        <Button
          size='small'
          icon={<PlusOutlined />}
          style={{ marginRight: 6 }}
          onClick={onAddObject}
          disabled={
            selectedTypeSearch === undefined ? true : selectedTypeSearch === 1
          }
        >
          {language.add_object}
        </Button>
        <div>
          <Button
            type='default'
            className='set-columns-button'
            onClick={onCheckboxModalOpen}
            icon={<PlusCircleFilled />}
          >
            {language.columns}
          </Button>
          <Modal
            width='850px'
            visible={showCheckboxModal}
            title={language.columns}
            okText={language.ok}
            onCancel={onCheckboxModalClose}
            onOk={onCheckboxModalClose}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div className='checkboxes-wrapper' style={{ minWidth: '220px' }}>
                <p style={{ margin: 0, fontWeight: 'bold' }}>
                  {language.actions}:
                </p>
                <Checkbox
                  checked={
                    visibleCols.delete === true &&
                    visibleCols.copy === true &&
                    visibleCols.activate_deactivate === true
                  }
                  onChange={(event: any) =>
                    onSelectAllColumns(actionsProperties, event.target.checked)
                  }
                >
                  {language.show_all_actions}
                </Checkbox>
                <Checkbox
                  checked={
                    visibleCols.delete !== true &&
                    visibleCols.copy !== true &&
                    visibleCols.activate_deactivate !== true
                  }
                  onChange={(event: any) =>
                    onDeselectAllColumns(
                      actionsProperties,
                      event.target.checked,
                    )
                  }
                >
                  {language.show_no_actions}
                </Checkbox>
                <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                {actionsProperties.map((actionProperty: any) => (
                  <Checkbox
                    key={actionProperty}
                    checked={visibleCols[actionProperty]}
                    onChange={(event: any) =>
                      updateVisibleCols([actionProperty], event.target.checked)
                    }
                  >
                    {language[actionProperty]}
                  </Checkbox>
                ))}
              </div>
              <div className='checkboxes-wrapper' style={{ minWidth: '220px' }}>
                <p style={{ margin: 0, fontWeight: 'bold' }}>
                  {language.system_information}:
                </p>
                <Checkbox
                  checked={
                    visibleCols.id === true &&
                    visibleCols.ext_id === true &&
                    visibleCols.property === true &&
                    visibleCols.personal_information === true &&
                    visibleCols.type === true
                  }
                  onChange={(event: any) =>
                    onSelectAllColumns(
                      systemInfoProperties,
                      event.target.checked,
                    )
                  }
                >
                  {language.show_all_system_information}
                </Checkbox>
                <Checkbox
                  checked={
                    visibleCols.id !== true &&
                    visibleCols.ext_id !== true &&
                    visibleCols.property !== true &&
                    visibleCols.personal_information !== true &&
                    visibleCols.type !== true
                  }
                  onChange={(event: any) =>
                    onDeselectAllColumns(
                      systemInfoProperties,
                      event.target.checked,
                    )
                  }
                >
                  {language.show_no_system_information}
                </Checkbox>
                <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                {systemInfoProperties.map((systemInfoProperty: any) => (
                  <Checkbox
                    key={systemInfoProperty}
                    checked={visibleCols[systemInfoProperty]}
                    onChange={(event: any) =>
                      updateVisibleCols(
                        [systemInfoProperty],
                        event.target.checked,
                      )
                    }
                  >
                    {language[systemInfoProperty]}
                  </Checkbox>
                ))}
              </div>
              {listedObjects.every((listedObject: any) => {
                const rightType = listedObject.types;
                return (
                  leftType?.length === rightType.length &&
                  _.intersection(leftType, rightType).length === leftType.length
                );
              }) ? (
                <div
                  className='checkboxes-wrapper'
                  style={{ minWidth: '220px' }}
                >
                  <p style={{ margin: 0, fontWeight: 'bold' }}>
                    {language.fields}:
                  </p>
                  <Checkbox
                    checked={columnFields?.every(
                      (columnField: any) =>
                        visibleCols[columnField.extId] === true,
                    )}
                    onChange={(event) =>
                      onSelectAllColumns(
                        columnFields.map(
                          (columnField: any) => columnField.extId,
                        ),
                        event.target.checked,
                      )
                    }
                  >
                    {language.select_all_fields}
                  </Checkbox>
                  <Checkbox
                    checked={columnFields?.every(
                      (columnField: any) =>
                        visibleCols[columnField.extId] === false,
                    )}
                    onChange={(event) =>
                      onDeselectAllColumns(
                        columnFields.map(
                          (columnField: any) => columnField.extId,
                        ),
                        event.target.checked,
                      )
                    }
                  >
                    {language.de_select_all_fields}
                  </Checkbox>
                  <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                  {columnFields.map((columnField: any) => (
                    <Checkbox
                      key={columnField.id}
                      checked={visibleCols[columnField.extId]}
                      onChange={(event: any) =>
                        updateVisibleCols(
                          [columnField.extId],
                          event.target.checked,
                        )
                      }
                    >
                      {columnField.extId}
                    </Checkbox>
                  ))}
                </div>
              ) : (
                <div
                  className='checkboxes-wrapper'
                  style={{ minWidth: '180px' }}
                >
                  <p style={{ margin: 0, fontWeight: 'bold' }}>
                    {language.fields}:
                  </p>
                  <p style={{ margin: 0 }}>
                    {
                      language.field_values_not_possible_to_display_or_edit_if_the_selected_objects_have_different_types
                    }
                    .
                  </p>
                </div>
              )}
              <div className='checkboxes-wrapper' style={{ minWidth: '220px' }}>
                <p style={{ margin: 0, fontWeight: 'bold' }}>Other:</p>
                {additionalColumnChoices.map((column: any) => (
                  <Checkbox
                    key={column}
                    checked={visibleCols[column]}
                    onChange={(event) =>
                      updateVisibleCols([column], event.target.checked)
                    }
                  >
                    {language[column]}
                  </Checkbox>
                ))}
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <p className='small-heading-text' style={{ marginBottom: 0 }}>
        {language.listed_objects}: {number}
      </p>
      <p style={{ marginBottom: 0 }}>
        <span style={{ fontWeight: 'bold' }}>Note!</span> You need to mark the
        object(s) below that you want to edit.
      </p>
      <Table
        size='small'
        dataSource={listedObjects.map((listedObject: any) => ({
          key: listedObject?.id,
          ...listedObject,
          listedObject,
        }))}
        columns={columns}
        rowSelection={rowSelection}
        expandable={{
          expandedRowKeys: expandedRowKey,
          expandedRowRender: expandedRowRender,
          onExpand: onTableRowExpand,
          rowExpandable: (expandedRowKey) => expandedRowKey,
        }}
        scroll={{ y: 1000 }}
      />
      <Modal
        title='Add category'
        okText='Add'
        onOk={() => onAddCategory(modalValue, modalCategories, newCategoryName)}
        cancelText='Close'
        width='500px'
        onCancel={onAddCategoryModalClose}
        visible={addCategoryModal}
      >
        <Input
          size='small'
          onChange={(event: any) => setNewCategoryName(event.target.value)}
        />
      </Modal>
    </div>
  );
};
