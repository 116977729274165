import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// i18N
import intl from '../../i18n/intl';

// REDUX
import {
  deleteAll,
  updateMaintenance,
  closeUnusedSessionsAction,
  deleteAllSelector,
  maintenanceResponseSelector,
} from '../../slices/maintenance.slice';
import {
  fetchSystemActionInfo,
  selectedSystemActionSelector,
  setSelectedSystemAction,
} from '../../slices/systemActions.slice';

// STYLES
import {
  Collapse,
  Checkbox,
  Button,
  Popconfirm,
  Space,
  Select,
  Input,
  Result,
  Divider,
  Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import './index.scss';

// COMPONENTS
import { TEToolTip } from '../TEToolTip';
import { TMaintenance } from '../../types/maintenance.type';
import { TSystemAction } from '../../types/systemAction.type';

type PropsMaintenance = {
  maintenance: TMaintenance[];
};

type PropsSystemActions = {
  systemActions: TSystemAction[];
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const Maintenance = ({ maintenance }: PropsMaintenance) => {
  const dispatch = useDispatch();
  const maintenanceResponse = useSelector(maintenanceResponseSelector);
  const deleteAllResponse = useSelector(deleteAllSelector);
  const [itemsChecked, setItemsChecked] = useState<any>([]);

  // EVENT HANDLERS
  const onChange = (checked: boolean, value: string) => {
    if (checked === true) {
      setItemsChecked([...itemsChecked, value]);
    } else {
      setItemsChecked(itemsChecked.filter((item: string) => item !== value));
    }
    setShowResult(false);
    setResetDatabase(false);
  };

  const [showResult, setShowResult] = useState(false);

  const splittedItemsChecked = itemsChecked.join(', ');

  // Pass the state to the API Post-request performing maintenance
  const onPerformMaintenance = () => {
    itemsChecked.map((checkedItem: any) =>
      dispatch(updateMaintenance(checkedItem)),
    );
    setShowResult(true);
    setResetDatabase(false);
  };

  const [resetDatabase, setResetDatabase] = useState(false);
  const [forceDelete, setForceDelete] = useState(false);
  let requestInput: any;

  const onResetDatabase = () => {
    dispatch(deleteAll(requestInput));
    setResetDatabase(true);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.maintenance} key='1'>
        <div className='maintenance--wrapper'>
          <div className='maintenance-actions-wrapper'>
            <div className='maintenance-checkboxes'>
              {maintenance?.map((item: any) => (
                <Checkbox
                  key={item.id}
                  checked={itemsChecked.indexOf(item.id) !== -1}
                  onChange={(event: any) =>
                    onChange(event.target.checked, item.id)
                  }
                >
                  <Tooltip
                    title={() => (
                      <p style={{ margin: 0 }}>
                        {item.description.split(',').map((desc: string) => (
                          <>
                            {desc}
                            <br />
                          </>
                        ))}
                      </p>
                    )}
                    key={item.id}
                    placement='right'
                    overlayInnerStyle={{
                      width: 500,
                    }}
                  >
                    {item.name}
                  </Tooltip>
                </Checkbox>
              ))}
            </div>
            <Button
              type='primary'
              onClick={onPerformMaintenance}
              style={{
                marginTop: 10,
                marginBottom: 30,
                fontWeight: 900,
              }}
            >
              {language.perform_maintenance}
            </Button>
            <p className='small-heading-text'>
              <TEToolTip helpText={language.action_will_delete_information}>
                {language.reset_database}:{' '}
              </TEToolTip>
              <span>{language.WARNING} </span>
              {language.this_action_is_not_recommended}!
            </p>
            <Space size='middle'>
              <Popconfirm
                title={`${language.sure_to_reset_database}?`}
                onConfirm={() => {
                  setForceDelete(true);
                }}
                onCancel={() => console.log('should do nothing, no delete')}
                okText={language.yes}
                cancelText={language.no}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                {forceDelete === true ? (
                  <>
                    <Popconfirm
                      title={`${language.really_sure_to_reset_database}?`}
                      onConfirm={() => {
                        onResetDatabase();
                        setForceDelete(false);
                      }}
                      onCancel={() =>
                        console.log('should do nothing, no delete')
                      }
                      okText={language.yes}
                      cancelText={language.no}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <Button type='primary' danger style={{ fontWeight: 900 }}>
                        {language.execute_reset_database}
                      </Button>
                    </Popconfirm>
                  </>
                ) : (
                  <Button type='primary' danger style={{ fontWeight: 900 }}>
                    {language.reset_database}
                  </Button>
                )}
              </Popconfirm>
            </Space>
            {resetDatabase && (
              <Result
                status='warning'
                title={language.database_is_reseted}
                subTitle={language.there_is_no_way_to_recall_it}
              />
            )}
          </div>
          <div className='maintenance-list-wrapper'>
            {showResult && splittedItemsChecked.length > 0 && (
              <Result
                status='success'
                title={`${language.successfully_performed_maintenance}!`}
                subTitle={`${maintenanceResponse?.message} ${deleteAllResponse?.message}`}
              />
            )}
            {showResult && splittedItemsChecked.length === 0 && (
              <Result
                status='warning'
                title={`${language.maintenance_not_performed}!`}
                subTitle={`${language.please_select_items_before_clicking_on} ${language.perform_maintenance}`}
              />
            )}
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const MaintenanceMiscellaneous = ({
  systemActions,
}: PropsSystemActions) => {
  const dispatch = useDispatch();
  const selectedSystemActionResponse = useSelector(
    selectedSystemActionSelector,
  );

  const [action, setAction] = useState<any>();
  const [choosenValue, setChoosenValue] = useState<any>();
  const [noValueSelected, setNoValueSelected] = useState<string | null>();
  const [updatedShortInterval, setUpdatedShortInterval] =
    useState<boolean>(false);

  // EVENT HANDLER
  const getActionInfo = () => {
    const selectedSystemAction = systemActions.find(
      (systemAction: TSystemAction) => systemAction.id === action,
    );
    if (selectedSystemAction && selectedSystemAction.requiresValue === false) {
      dispatch(setSelectedSystemAction(selectedSystemAction));
      dispatch(fetchSystemActionInfo(selectedSystemAction.id));
    } else if (
      selectedSystemAction &&
      selectedSystemAction.requiresValue === true &&
      choosenValue === undefined
    ) {
      setNoValueSelected('Please select a value');
    } else if (
      selectedSystemAction &&
      selectedSystemAction.requiresValue === true &&
      choosenValue !== undefined
    ) {
      setNoValueSelected(null);
      dispatch(fetchSystemActionInfo(selectedSystemAction.id, choosenValue));
    }
  };

  const onCloseUnusedSessionsButton = (shortInterval: any) => {
    // Send the values from the state to an API request that are closing unused sessions
    dispatch(closeUnusedSessionsAction(shortInterval));
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.miscellaneous} key='3'>
        <div className='action-top-bar--wrapper'>
          <div>
            <p className='form-item-text' style={{ margin: 0 }}>
              {language.select_action}
            </p>
            <Select
              size='small'
              onChange={(value: any) => {
                setAction(value);
                setChoosenValue(undefined);
                setNoValueSelected(null);
              }}
              style={{ width: 200 }}
            >
              {systemActions?.map((actionType: any) => (
                <Select.Option key={actionType.id} value={actionType.id}>
                  {actionType.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div style={{ marginLeft: 20 }}>
            <p className='form-item-text' style={{ margin: 0 }}>
              {language.value}
            </p>
            <Input
              size='small'
              value={choosenValue}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setChoosenValue(event.target.value)
              }
              style={{ width: 200 }}
            />
          </div>
          <Button
            size='small'
            style={{ marginLeft: 5, marginBottom: 2 }}
            onClick={getActionInfo}
          >
            {language.search}
          </Button>
        </div>
        {noValueSelected !== null && (
          <p
            style={{
              color: '#ff6357',
              fontSize: '16px',
            }}
          >
            {noValueSelected}
          </p>
        )}
        <div className='system-actions-box'>
          {selectedSystemActionResponse !== undefined && (
            <html>
              <body>
                <pre>
                  <code>{selectedSystemActionResponse.result}</code>
                </pre>
              </body>
            </html>
          )}
        </div>
        <Divider />
        <Checkbox
          style={{ marginRight: 20 }}
          onClick={(event: any) =>
            setUpdatedShortInterval(event.target.checked)
          }
        >
          <TEToolTip helpText={language.help_short_interval}>
            Short interval
          </TEToolTip>
        </Checkbox>
        <Button
          type='default'
          style={{ marginTop: 10 }}
          onClick={() => onCloseUnusedSessionsButton(updatedShortInterval)}
          danger
        >
          {language.close_unused_sessions}
        </Button>
      </Collapse.Panel>
    </Collapse>
  );
};
