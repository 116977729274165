import { Breadcrumb } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// SELECTORS
import { breadcrumbsSelector } from '../../slices/ui.slice';

// STYLES
import './index.scss';

const TopBar = () => {
  const breadcrumbs = useSelector(breadcrumbsSelector);

  return (
    <div className='top-bar--wrapper'>
      <Breadcrumb>
        {breadcrumbs.map((breadcrumb: any) => (
          <Breadcrumb.Item key={breadcrumb.path}>
            <Link className='breadcrumb-link' to={breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default TopBar;
