import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// i18N
import intl from '../../i18n/intl';

// STYLES
import { Collapse, Form, Input, Select, Checkbox, Transfer } from 'antd';

// ACTIONS
import { authenticationConfigurationsSelector } from '../../slices/authConfig.slice';
import { fetchLanguages, languagesSelector } from '../../slices/language.slice';
import { objectsSelector } from '../../slices/object.slice';

// TYPES
import { TTEUser, ENUMS } from '@timeedit/types/lib';

// COMPONENTS
import { OrgAndRolesTable } from './OrgAndRolesTable';
import { AppPermissionsTable } from './AppPermissionsTable';
import { ScopesTable } from './ScopesTable';

// UTILS
import { timeConverter } from '../../utils/timeConverter';

const { EUserVisibility, EUserAllowJavaClientAccess } = ENUMS;
const language: any = intl.messages;

type Props = {
  user: TTEUser;
  onChange: any;
};

/* ------------------------------------------------------------------------- */

export const UserManagerGeneralSettings = ({ user, onChange }: Props) => {
  const dispatch = useDispatch();
  const authenticationConfigurations = useSelector(
    authenticationConfigurationsSelector,
  );
  // const organizations = useSelector(organizationNodesSelector);
  const languages = useSelector(languagesSelector);
  const objects = useSelector(objectsSelector);

  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchLanguages());
  }, [dispatch]);

  const onChangeHandler = (property: string, value: any) => {
    onChange(property, value);
  };

  // Inputs for User Objects Transfer-element
  // Data with ALL available objects
  const dataSource = objects.map((object: any) => ({
    key: String(object.id),
    title: object.extId, // To be changed when we have first field name
  }));

  // The already choosen ones
  const targetKeys = user?.userObjects?.map((userObject: any) =>
    String(userObject),
  );

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const onSelection = (sourceSelectedKeys: any, targetSelectedKeys: any) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChange = (nextTargetKeys: any) => {
    onChange(
      'userObjects',
      nextTargetKeys.map((key: string) => parseInt(key)),
    );
  };

  const getStrategyOnAuthConfig = (authConfigId: string) => {
    const result = authenticationConfigurations.find(
      (authConfig: any) => authConfig.id === authConfigId,
    );
    if (result) {
      return result.strategy;
    } else {
      return authConfigId;
    }
  };

  const [isError, setIsError] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const onChangePasswordNeeded = (value: boolean) => {
    setShowChangePassword(value);
  };

  const onCheckValidation = (password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      setIsError(`${language.repeated_password_should_match_with_password}!`);
    } else {
      setIsError('');
    }
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Form.Item label={language.visibility}>
          <Select
            size='small'
            showSearch
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={language[user?.visibility]}
            onChange={(event: any) => onChangeHandler('visibility', event)}
          >
            {Object.entries(EUserVisibility).map(([key, value]) => (
              <Select.Option value={value} key={key}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={`${language.username}`}>
          <Input
            size='small'
            value={user?.username}
            onChange={(event: any) =>
              onChangeHandler('username', event.target.value)
            }
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={user?.personalData}
            onChange={(event: any) =>
              onChangeHandler('personalData', event.target.checked)
            }
          >
            {language.personal_data}
          </Checkbox>
        </Form.Item>
        <Form.Item label={`${language.description}`}>
          <Input.TextArea
            size='small'
            rows={5}
            value={user?.description ?? undefined}
            onChange={(event: any) =>
              onChangeHandler('description', event.target.values)
            }
          />
        </Form.Item>
        <Form.Item label={`${language.first_name}`}>
          <Input
            size='small'
            value={user?.firstName}
            onChange={(event: any) =>
              onChangeHandler('firstName', event.target.value)
            }
          />
        </Form.Item>
        <Form.Item label={`${language.last_name}`}>
          <Input
            size='small'
            value={user?.lastName}
            onChange={(event: any) =>
              onChangeHandler('lastName', event.target.value)
            }
          />
        </Form.Item>
        <Form.Item label={`${language.email}`}>
          <Input
            size='small'
            value={user?.email}
            onChange={(event: any) =>
              onChangeHandler('email', event.target.value)
            }
          />
        </Form.Item>
        <Form.Item label={`${language.language}`}>
          <Select
            size='small'
            value={user?.language}
            onChange={(event: any) => onChangeHandler('language', event)}
          >
            {languages.map((l: any) => (
              <Select.Option value={l.id} key={l.id}>
                {l.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={`${language.date_format}`}>
          <Select
            size='small'
            value={user?.dateFormat}
            onChange={(event: any) => onChangeHandler('dateFormat', event)}
          >
            {languages.map((l: any) => (
              <Select.Option key={l.id} value={l.id}>
                {l.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <p className='small-heading-text'>
          {language.time_zone}: {user?.timezone}
        </p>
        <Form.Item label={`${language.standard_organization}`}>
          {/* @todo: Shall only be able to choose among leafs. 
          I guess we need to extract the nodes with subOrganizations */}
          <Select
            size='small'
            value={user?.standardOrganization}
            onChange={(event: any) =>
              onChangeHandler('standardOrganization', event)
            }
          >
            {/*            {organizations.map((org: any) => (
              <Select.Option key={org.id} value={org.id}>
                {org.name}
              </Select.Option>
            ))} */}
          </Select>
        </Form.Item>
        <Form.Item label={`${language.allow_Java_client}`}>
          <Select
            size='small'
            showSearch
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={language[user?.allowJavaClientAccess]}
            onChange={(event: any) =>
              onChangeHandler('allowJavaClientAccess', event)
            }
          >
            {Object.entries(EUserAllowJavaClientAccess).map(([key, value]) => (
              <Select.Option value={value} key={key}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <p className='small-heading-text'>
          {language.last_login}: {timeConverter(user?.logins[0]?.login)},{' '}
          {user?.logins[0]?.app}
        </p>
        <p className='small-heading-text'>
          {language.created}: {timeConverter(user?.created)}
        </p>
        <p className='small-heading-text'>
          {language.modified}: {timeConverter(user?.history[0]?.modified)},{' '}
          {user?.history[0]?.modifiedBy}
        </p>
        <Form.Item>
          <Checkbox
            checked={user?.active}
            onChange={(event: any) =>
              onChangeHandler('active', event.target.checked)
            }
          >
            {language.active_user}
          </Checkbox>
        </Form.Item>
        <Form.Item label={language.organizations_and_roles}>
          <OrgAndRolesTable
            dataSource={user?.organizationsAndRoles}
            onChange={onChangeHandler}
            property='organizationsAndRoles'
          />
        </Form.Item>
        <Form.Item label={'Set scopes'}>
          <ScopesTable
            dataSource={user?.scopes}
            onChange={onChangeHandler}
            property='scopes'
          />
        </Form.Item>
        <Form.Item label={language.set_app_permissions}>
          <AppPermissionsTable
            dataSource={user?.appPermissions}
            onChange={onChangeHandler}
            property='appPermissions'
          />
        </Form.Item>
        <Form.Item label={language.user_objects}>
          <Transfer
            dataSource={dataSource}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) > -1
            }
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onSelectionChange}
            onSelectChange={onSelection}
            render={(item: any) => <span>{item.title}</span>}
          />
        </Form.Item>
        <Form.Item label={language.authentication_configuration}>
          <Select
            size='small'
            value={user?.authConfigId}
            onChange={(event: any) => onChangeHandler('authConfigId', event)}
          >
            {authenticationConfigurations.map((authConfig: any) => (
              <Select.Option key={authConfig.id} value={authConfig.id}>
                {authConfig.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {getStrategyOnAuthConfig(user?.authConfigId) === 'TE_IDP' && (
          <>
            <Checkbox
              onChange={(event: any) =>
                onChangeHandler(
                  'requirePasswordOnFirstSignIn',
                  event.target.checked,
                )
              }
              checked={user?.requirePasswordOnFirstSignIn}
            >
              {language.require_password_on_first_sign_in}
            </Checkbox>
            {user?.requirePasswordOnFirstSignIn === true && (
              <p
                style={{ marginBottom: 0, color: '#969d9f', fontSize: '14px' }}
              >
                {
                  language.user_will_be_required_to_set_new_password_on_first_log_in
                }
                .
              </p>
            )}
            <div style={{ marginTop: '20px' }}>
              <Checkbox
                checked={showChangePassword}
                onChange={(event: any) =>
                  onChangePasswordNeeded(event.target.checked)
                }
              >
                {language.change_password}
              </Checkbox>
              {showChangePassword && (
                <>
                  <Form.Item
                    label={`${language.password}`}
                    style={{ marginTop: '20px' }}
                  >
                    <Input
                      type='password'
                      size='small'
                      value={user?.password !== null ? user?.password : '-'}
                      onChange={(event: any) => {
                        onChangeHandler('password', event.target.value);
                        onCheckValidation(event.target.value, repeatedPassword);
                      }}
                    />
                  </Form.Item>
                  <p
                    style={{
                      marginTop: '16px',
                      color: '#ff6357',
                      fontSize: '12px',
                    }}
                  >
                    {isError}
                  </p>
                  <Form.Item label={language.repeat_password}>
                    <Input
                      type='password'
                      size='small'
                      onChange={(event: any) => {
                        setRepeatedPassword(event.target.value);
                        onCheckValidation(
                          user?.password !== null ? user?.password : '',
                          event.target.value,
                        );
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </div>
          </>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};
