import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form } from 'antd';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import NormalListView from '../../../components/List/NormalListView';
import {
  TimeRulesGeneralSettings,
  TimeRulesDetails,
  TimeRulesTemplates,
} from '../../../components/TimeRulesComponent';

// TYPES
import { TTimeRule } from '../../../types/timerule.type';

// ACTIONS
import {
  fetchTimeRules,
  timeRulesSelector,
  createTimeRule,
  deleteTimeRule,
  updateTimeRule,
  createEmptyTimeRule,
  copyTimeRule,
  selectedTimeRuleSelector,
  setSelectedTimeRule,
  fetchLimitationsTimeRulesSelector,
  fetchLimitationsTimeRules,
} from '../../../slices/timeRule.slice';

// UTILS
import { timeConverter } from '../../../utils/timeConverter';
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const TimeRulesPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const timeRules: TTimeRule[] = useSelector(timeRulesSelector);
  const limitations = useSelector(fetchLimitationsTimeRulesSelector);
  const selectedTimeRule: TTimeRule = useSelector(selectedTimeRuleSelector);

  const listData = timeRules.map((timeRule: any) => ({
    listLabel: timeRule.name,
    value: timeRule.id,
  }));

  const onTimeRuleSelected = (selectedValue: any) => {
    const currentTimeRule = timeRules.find(
      (timeRule) => timeRule.id === selectedValue,
    );
    if (currentTimeRule) {
      dispatch(setSelectedTimeRule(currentTimeRule));
    }
  };

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/time-rules`,
          label: `${language.time_rules}`,
        },
        {
          path: `/${selectedTimeRule.id}`,
          label: `${selectedTimeRule.name}`,
        },
      ]),
    );
  }, [dispatch, customerSignature, selectedTimeRule.id, selectedTimeRule.name]);

  useEffect(() => {
    dispatch(fetchTimeRules());
    dispatch(fetchLimitationsTimeRules());
  }, [dispatch]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabled(selectedTimeRule, timeRules));
  }, [timeRules, selectedTimeRule]);

  const [form] = Form.useForm();

  // EVENT HANDLERS
  // Adds new time rule
  const onPlusButton = () => {
    dispatch(createEmptyTimeRule());
  };

  // Deletes selected Time rule
  const onDeleteButton = () => {
    dispatch(deleteTimeRule(selectedTimeRule));
  };

  // Save changes function
  const onSaveChangesTimeRuleButton = () => {
    if (selectedTimeRule.id === 0) {
      dispatch(createTimeRule(selectedTimeRule));
    } else {
      dispatch(updateTimeRule(selectedTimeRule));
    }
  };

  // Change handler
  const onHandleChange = (property: string, value: any) => {
    dispatch(setSelectedTimeRule({ ...selectedTimeRule, [property]: value }));
  };

  // Change handler for when adding/removing absolute times
  const onChangeAbsoluteTimes = (
    startTime: number,
    endTime: number,
    property1: string,
    property2: string,
  ) => {
    const newTimeRule = {
      ...selectedTimeRule,
      [property1]: startTime,
      [property2]: endTime,
    };
    dispatch(setSelectedTimeRule(newTimeRule));
  };

  // Change handler for when changing standard step
  const onChangeStandardStep = (value: number, properties: string[]) => {
    const newTimeRule: any = {
      ...selectedTimeRule,
    };
    properties.forEach((property: any) => {
      newTimeRule[property] = value;
    });
    dispatch(setSelectedTimeRule(newTimeRule));
  };

  // Discard changes
  const onDiscardChanges = () => {
    onTimeRuleSelected(selectedTimeRule?.id);
  };

  // Copy time rule
  const onCopy = () => {
    dispatch(copyTimeRule(selectedTimeRule));
  };

  return (
    <>
      <ListView
        sectionHeading={language.time_rules}
        number={listData.length}
        onSelect={onPlusButton}
      >
        <NormalListView
          data={listData}
          onSelect={onTimeRuleSelected}
          onDelete={onDeleteButton}
          onCopy={onCopy}
          selectedItemId={selectedTimeRule.id}
        />
      </ListView>
      <div className='inner-content--wrapper'>
        <SectionHeader
          listChoice={
            selectedTimeRule.name
              ? `${selectedTimeRule.name}`
              : `${language.time_rule}`
          }
          history={selectedTimeRule?.history}
          modifiedDate={
            selectedTimeRule.history?.length > 0
              ? timeConverter(selectedTimeRule?.history[0]?.modified)
              : ''
          }
          modifiedBy={
            selectedTimeRule.history?.length > 0
              ? selectedTimeRule?.history[0]?.modifiedBy
              : ''
          }
          changeHandler={onSaveChangesTimeRuleButton}
          discardHandler={onDiscardChanges}
          displayProp={false}
          isDisabled={saveDisabled}
        />
        <Form layout='vertical' form={form}>
          <TimeRulesGeneralSettings
            timeRule={selectedTimeRule}
            onChange={onHandleChange}
            limitations={limitations}
          />
          <TimeRulesDetails
            timeRule={selectedTimeRule}
            onChange={onHandleChange}
            onChangeAbsoluteTimes={onChangeAbsoluteTimes}
            onChangeStandardStep={onChangeStandardStep}
          />
          <TimeRulesTemplates templates={selectedTimeRule.templates} />
        </Form>
      </div>
    </>
  );
};

export default TimeRulesPage;
