import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// REDUX
import {
  amountOfReservationsMovedSelector,
  amountOfReservationsToBeMovedSelector,
  createTimeZone,
  dstsSelector,
  fetchDsts,
  fetchNumberOfReservationsToBeMoved,
  fetchTimeZones,
  fetchZones,
  moveReservationsBetweenTimeZones,
  selectedTimeZonesSelector,
  setUpdatedTimeZones,
  updateTimeZones,
  zonesSelector,
} from '../../slices/timeZone.slice';

// i18N
import intl from '../../i18n/intl';

// STYLES
import { Collapse, Select, Button, Modal } from 'antd';

// TYPES
import { TDst, TTimeZone, TZone } from '../../types/timeZone.type';

// UTILS
import { isSavedDisabledObjects } from '../../utils/isSavedDisabled';
import { getNameOnTimeZone } from '../../utils/getNameOn';

// COMPONENTS
import SystemPrefsActionButtons from '../SystemPrefs/SystemPrefsActionButtons';
import TimeZonesTable from './TimeZonesTable';
import { TEToolTip } from '../TEToolTip';

type PropsTimeZones = {
  timeZones: TTimeZone[];
  limitations: any;
};

const language: any = intl.messages;

const onBlur = () => {};
const onFocus = () => {};

/* ---------------------------------------------------------------------- */

export const TimeZones = ({ timeZones, limitations }: PropsTimeZones) => {
  const dispatch = useDispatch();
  const zones: TZone[] = useSelector(zonesSelector);
  const dsts: TDst[] = useSelector(dstsSelector);
  const selectedTimeZones: TTimeZone[] | any = useSelector(
    selectedTimeZonesSelector,
  );
  const amountOfReservationsToBeMoved = useSelector(
    amountOfReservationsToBeMovedSelector,
  );
  const numberOfReservationsMoved = useSelector(
    amountOfReservationsMovedSelector,
  );

  const [moveFrom, setMoveFrom] = useState<any>();
  const [moveTo, setMoveTo] = useState<any>();
  const [errorMessage, setErrorMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [movedReservations, setMovedReservations] = useState(false);

  useEffect(() => {
    dispatch(fetchZones());
    dispatch(fetchDsts());
  }, [dispatch]);

  useEffect(() => {
    setMovedReservations(false);
  }, [moveTo, moveFrom]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabledObjects(selectedTimeZones, timeZones));
  }, [selectedTimeZones, timeZones]);

  // EVENT HANDLERS
  // Change handler
  const onChangeTimeZone = (
    value: any,
    property: string,
    timeZone: TTimeZone,
  ) => {
    const index = selectedTimeZones.indexOf(timeZone);
    const updatedTimeZone: any = Object.assign({}, timeZone);
    updatedTimeZone[property] = value;
    const newTimeZones: TTimeZone[] = [...selectedTimeZones];
    newTimeZones.splice(index, 1, updatedTimeZone);
    dispatch(setUpdatedTimeZones(newTimeZones));
  };

  // Save changes handler
  const onSaveChangesButton = () => {
    const changedTimeZones: any = selectedTimeZones.filter(
      (updatedTimeZone: any) => {
        const oldTimeZone = timeZones.find(
          (timeZone: any) => timeZone.id === updatedTimeZone.id,
        );
        return oldTimeZone !== updatedTimeZone;
      },
    );

    // eslint-disable-next-line array-callback-return
    changedTimeZones.map((timeZone: TTimeZone) => {
      if (timeZone.id === 0) {
        dispatch(createTimeZone(timeZone));
      } else {
        dispatch(updateTimeZones(changedTimeZones));
      }
    });
  };

  const onDiscardChanges = () => {
    dispatch(fetchTimeZones());
  };

  const onShowModal = () => {
    if (moveFrom === undefined || moveTo === undefined) {
      setErrorMessage(true);
      setShowModal(false);
    } else {
      // API request GET amountOfReservationsToBeMoved
      dispatch(fetchNumberOfReservationsToBeMoved(moveFrom, moveTo));
      setErrorMessage(false);
      setShowModal(true);
    }
  };

  /* ON click, send the data to the server and make the move */
  const onMoveReservationsBetweenTimeZones = () => {
    // API request PATCH moveReservationsBetweenTimeZones
    dispatch(
      moveReservationsBetweenTimeZones({
        fromTimeZone: moveFrom,
        toTimeZone: moveTo,
      }),
    );
    setMovedReservations(true);
    setShowModal(false);
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.time_zones} key='7'>
        <SystemPrefsActionButtons
          displayProp={false}
          changeHandler={onSaveChangesButton}
          discardHandler={onDiscardChanges}
          isDisabled={saveDisabled}
        />
        <TimeZonesTable
          timeZones={selectedTimeZones}
          dsts={dsts}
          zones={zones}
          onTimeZonesChanged={onChangeTimeZone}
          limitations={limitations}
        />
        <TEToolTip helpText={language.help_move_reservation_system_prefs}>
          <p className='small-heading-text' style={{ marginTop: 25 }}>
            {language.move_reservations_between_time_zones}
          </p>
        </TEToolTip>
        <div className='row-direction--wrapper'>
          <div style={{ marginBottom: 6 }}>
            <Select
              size='small'
              onChange={(value: number) => setMoveFrom(value)}
              showSearch
              style={{ width: '300px' }}
              onFocus={onFocus}
              onBlur={onBlur}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={moveFrom}
              placeholder={language.from_time_zone}
            >
              {selectedTimeZones.map((timeZone: TTimeZone) => (
                <Select.Option key={timeZone.id} value={timeZone.id}>
                  {timeZone.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div style={{ marginLeft: 6, marginBottom: 6 }}>
            <Select
              size='small'
              onChange={(value: number) => setMoveTo(value)}
              showSearch
              style={{ width: '300px' }}
              onFocus={onFocus}
              onBlur={onBlur}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={moveTo}
              placeholder={language.to_time_zone}
            >
              {selectedTimeZones.map((timeZone: any) => (
                <Select.Option key={timeZone.id} value={timeZone.id}>
                  {timeZone.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div
          className='row-direction--wrapper'
          style={{ alignItems: 'center' }}
        >
          <Button size='small' type='default' onClick={onShowModal}>
            {language.move_reservations}
          </Button>
          {showModal && moveFrom !== undefined && moveTo !== undefined && (
            <Modal
              title={language.move_reservations_between_time_zones}
              okText={language.move}
              cancelText={language.cancel}
              onOk={onMoveReservationsBetweenTimeZones}
              onCancel={onModalClose}
              visible={showModal}
            >
              <p className='form-item-text message'>
                {language.are_you_sure_to_move}{' '}
                {amountOfReservationsToBeMoved.numberOfReservationsToBeMoved}{' '}
                {language.reservations_from} &quot;
                {getNameOnTimeZone(timeZones, moveFrom)}
                &quot; {language.to.lowerCase()} &quot;
                {getNameOnTimeZone(timeZones, moveTo)}
                &quot; {language.preserving_local_time}?
              </p>
            </Modal>
          )}
          {errorMessage && (
            <p className='form-item-text error message'>
              {language.please_select_time_zones}!
            </p>
          )}
        </div>{' '}
        {movedReservations && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '20px',
            }}
          >
            <p className='small-heading-text' style={{ marginBottom: 0 }}>
              {language.number_of_reservations_moved}:{' '}
              {numberOfReservationsMoved?.numberOfReservationsMoved}
            </p>
            <p className='small-heading-text' style={{ marginBottom: 0 }}>
              {language.number_of_cancellations_moved}:{' '}
              {numberOfReservationsMoved?.numberOfCancellationsMoved}
            </p>
            <p className='small-heading-text' style={{ marginBottom: 0 }}>
              {language.number_of_history_reservations_moved}:{' '}
              {numberOfReservationsMoved?.numberOfHistoryReservationsMoved}
            </p>
            <p className='small-heading-text' style={{ marginBottom: 0 }}>
              {language.number_of_culls_moved}:{' '}
              {numberOfReservationsMoved?.numberOfCullsMoved}
            </p>
          </div>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};
