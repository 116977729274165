import { useSelector } from 'react-redux';
import _ from 'lodash';

// REDUX
import { typesSelector } from '../../../slices/type.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form, Checkbox } from 'antd';

// TYPES
import { TType } from '../../../types/type.type';

// UTILS
import { getNameOnType } from '../../../utils/getNameOn';

type Props = {
  types: Array<any>;
  typeCombinations: Array<any>;
  onChange: Function;
};

const language: any = intl.messages;

/* ----------------------------------------------------------------------------------- */

const CombinationsComponent = ({
  types,
  typeCombinations,
  onChange,
}: Props) => {
  // All types
  const allTypes: TType[] = useSelector(typesSelector);

  const array = types?.map((type: any) => {
    return [type.type];
  });

  const combineFunction = (A: any, comb: any = [], result: any = [comb]) => {
    for (let i = 0; i < A?.length; i++)
      result = result.concat(
        combineFunction(
          A.slice(0, i).concat(A.slice(i + 1)),
          comb.concat(A[i]),
        ),
      );
    return result.map((r: any) => r.sort((a: number, b: number) => a - b));
  };

  const onChangeCheckboxHandler = (event: any, property: string, res: any) => {
    let newCombinations: any[];
    if (event.target.checked) {
      if (typeCombinations === undefined) {
        newCombinations = [res];
      } else {
        newCombinations = typeCombinations.concat([res]);
      }
    } else {
      newCombinations = typeCombinations.filter(
        (combination: any) => !_.isEqual(combination, res),
      );
    }
    onChange(property, newCombinations);
  };

  // Show the combinations from the API
  const isCombinationSelected = (
    combination: any,
    typeCombinations: Array<any>,
  ) => {
    return _.some(typeCombinations, (value: number[]) => {
      return _.isEqual(combination, value);
    });
  };

  if (types?.length === 0) {
    return null;
  } else {
    const result: any = _.uniqBy(combineFunction(array), (value: any) => {
      return value.join(' ');
    });

    return (
      <div>
        <p className='small-heading-text' style={{ marginTop: 20 }}>
          {language.combinations}:
        </p>
        <Form.Item className='form-column-direction'>
          {result.map((res: any) => (
            <Checkbox
              key={res}
              onChange={(checked: any) =>
                onChangeCheckboxHandler(checked, 'typeCombinations', res)
              }
              checked={isCombinationSelected(res, typeCombinations)}
            >
              {res.map((r: any) => getNameOnType(allTypes, r)).join(', ')}
            </Checkbox>
          ))}
        </Form.Item>
      </div>
    );
  }
};
export default CombinationsComponent;
