import {createSelector, createSlice} from '@reduxjs/toolkit';
import {EPaddingRuleType, TPaddingRule, createEmptyRule, TPaddingObjectFilterCriteria} from "../types/padding.type";
import {finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest} from "./sliceHelpers";
import api from '../services/api.service';
import {useSelector} from "react-redux";

export const initialState = {
    selectedPaddingRule: null,
    allPaddingRules: [],
    errorMessage: "",
}


const slice = createSlice({
    name: 'padding',
    initialState,
    reducers: {
        fetchPaddingRulesRequest: (state: any) => {
            isLoadingRequest(state);
        },
        fetchPaddingRulesSuccess: (state: any, {payload}) => {
            state.allPaddingRules = payload.results
            finishedLoadingSuccess(state);
        },
        fetchPaddingRulesFailure: (state: any) => {
            finishedLoadingFailure(state);
        },
        fetchLimitationsPaddingRulesSuccess: (state: any, {payload}) => {
            state.limitations = payload;
            finishedLoadingSuccess(state);
        },
        createPaddingRuleRequest: (state: any) => {
            state.errorMessage = '';
            isLoadingRequest(state);
        },
        createPaddingRuleSuccess: (state: any, {payload}) => {
            state.allPaddingRules.push(payload);
            if (payload.paddingType === EPaddingRuleType.TRAVEL_TIME) {
                state.travelTimeRule = payload.rule;
            }
            state.selectedPaddingRule = payload;
            finishedLoadingSuccess(state);
        },
        createPaddingRuleFailure: (state: any, {payload}) => {
            state.errorMessage = payload;
            finishedLoadingFailure(state);
        },
        updatePaddingRuleRequest: (state: any) => {
            state.errorMessage = '';
            isLoadingRequest(state);
        },
        updatePaddingRuleSuccess: (state: any, {payload}) => {
            const newPaddingRules = [...state.allPaddingRules]
            const index = newPaddingRules.findIndex(rule => rule.id === payload.id);
            newPaddingRules[index] = payload;
            state.allPaddingRules = newPaddingRules;
            finishedLoadingSuccess(state);
        },
        updatePaddingRuleFailure: (state: any, {payload}) => {
            state.errorMessage = payload;
            finishedLoadingFailure(state);
        },
        deletePaddingRuleRequest: (state: any) => {
            isLoadingRequest(state);
        },
        deletePaddingRuleSuccess: (state: any, {payload}) => {
            state.allPaddingRules = state.allPaddingRules.filter((rule: TPaddingRule) => rule.id !== payload.id);
            state.selectedPaddingRule = null;
            finishedLoadingSuccess(state);
        },
        deletePaddingRuleFailure: (state: any) => {
            finishedLoadingFailure(state);
        },
        setCurrentPaddingRule: (state: any, {payload}) => {
            state.selectedPaddingRule = payload;
            state.errorMessage = '';
        },
        setCriteriaDataList: (state: any, {payload}) => {
            state.criteriaDataList = payload;
        },
        setTravelTimeRule: (state: any, {payload}) => {
            state.travelTimeRule = payload;
        },
        setTravelTimeRulePadding: (state: any, {payload}) => {
            state.travelTimeRule.distances[payload.index] = {... state.travelTimeRule.distances[payload.index], padding: payload.padding};
        },
        fetchPaddingRulesViewerListRequest: (state: any) => {
            isLoadingRequest(state);
        },
        fetchPaddingRulesViewerListSuccess: (state: any, {payload}) => {
            state.paddingRuleViewerList = payload.results;
        },
        fetchPaddingRulesViewerListFailure: (state: any, {payload}) => {
            state.errorMessage = payload;
            finishedLoadingFailure(state);
        },
        removePaddingRuleViewerList: (state: any) => {
            state.paddingRuleViewerList = [];
        },
        setPaddingRuleErrorMessage: (state: any, {payload}) => {
            state.errorMessage = payload
            state.hasErrors = !!payload;
        },
    }
})

export default slice.reducer;

/**
 * Padding Rules Selectors
 */
export const paddingRulesSelector = (state: any) => {
    return state.paddingRules.allPaddingRules as TPaddingRule[];
};
export const paddingRulesLoading = (state: any) => {
    return state.paddingRules.loading;
};
export const selectedPaddingRuleSelector = (state: any) => {
    return state.paddingRules.selectedPaddingRule;
};
export const paddingRulesErrorMessage = (state: any) => {
    return state.paddingRules.errorMessage;
};
export const criteriaDataListSelector = (state: any) => {
    return state.paddingRules.criteriaDataList;
};
export const travelTimeRuleSelector = (state: any) => {
    return state.paddingRules.travelTimeRule;
};
export const paddingRuleViewerListSelector = (state: any) => {
    return state.paddingRules.paddingRuleViewerList;
};

const travelTimeDistancePaddingSelector = createSelector(
    (state: any) => state.paddingRules,
    ( _: any, index: number) => index,
    (paddingRules: any, index: number) => {
        return paddingRules.travelTimeRule.distances[index]?.padding
    }
);

export const getSpecificPaddingValueFromState = (index: number) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSelector((state) =>
        travelTimeDistancePaddingSelector(state, index)
    )
}

/**
 * Padding Rules Limitation
 */
export const limitationsPaddingRulesSelector = (state: any) => {
    return state.paddingRules.limitations;
}

export const {
    fetchPaddingRulesRequest,
    fetchLimitationsPaddingRulesSuccess,
    fetchPaddingRulesFailure,
    fetchPaddingRulesSuccess,
    createPaddingRuleRequest,
    createPaddingRuleSuccess,
    createPaddingRuleFailure,
    updatePaddingRuleRequest,
    updatePaddingRuleSuccess,
    updatePaddingRuleFailure,
    deletePaddingRuleRequest,
    deletePaddingRuleSuccess,
    deletePaddingRuleFailure,
    setCurrentPaddingRule,
    setCriteriaDataList,
    setTravelTimeRule,
    setTravelTimeRulePadding,
    fetchPaddingRulesViewerListRequest,
    fetchPaddingRulesViewerListSuccess,
    fetchPaddingRulesViewerListFailure,
    removePaddingRuleViewerList,
    setPaddingRuleErrorMessage,
} = slice.actions;

export const createPaddingRule = (paddingRule: TPaddingRule) => async (dispatch: any) => {
    try {
        dispatch(createPaddingRuleRequest());
        const {id: _, ...paddingRuleData} = paddingRule;
        const response = await api.post(
            {
                endpoint: '/padding-rules',
                data: paddingRuleData
            });
        dispatch(createPaddingRuleSuccess(response));
        dispatch(fetchPaddingRules())
    } catch (e: any) {
        dispatch(createPaddingRuleFailure(e.response.data.message));
        return console.error(e.response.data ? e.response.data : e.response);
    }
}

export const updatePaddingRule = (paddingRule: TPaddingRule) => async (dispatch: any) => {
    try {
        dispatch(updatePaddingRuleRequest());
        // @ts-ignore
        const {history: _, ... paddingRuleData} = paddingRule;
        const response = await api.patch(
            {
                endpoint: `/padding-rules/${paddingRule.id}`,
                data: paddingRuleData
            });
        dispatch(updatePaddingRuleSuccess(response));
    } catch (e: any) {
        dispatch(updatePaddingRuleFailure(e.response.data.message));
        return console.error(e.response.data ? e.response.data : e.response);
    }
}

export const deletePaddingRule = (paddingRule: TPaddingRule) => async (dispatch: any) => {
    try {
        dispatch(deletePaddingRuleRequest());
        await api.delete({
            endpoint: `/padding-rules/${paddingRule.id}`
        });
        dispatch(deletePaddingRuleSuccess(paddingRule));
    } catch (e: any) {
        dispatch(deletePaddingRuleFailure());
        return console.error(e);
    }
}

export const fetchPaddingRules = (searchTextOption?: string, searchTextQuery?: string) => async (dispatch: any) => {
    try {
        dispatch(fetchPaddingRulesRequest());
        let url = '/padding-rules';
        let encodedSortQuery: string | undefined;
        if (searchTextQuery) {
            encodedSortQuery = encodeURIComponent(searchTextQuery);
        }
        if (searchTextOption && searchTextQuery) {
            url = `${url}?${searchTextOption}=${encodedSortQuery}`;
        }

        const paddingRules = await api.get({
            endpoint: url
        });
        dispatch(fetchPaddingRulesSuccess(paddingRules))
    } catch (e) {
        dispatch(fetchPaddingRulesFailure());
        return console.error(e)
    }
}


export const CreateEmptyPaddingRule = () => async (dispatch: any) => {
    try {
        dispatch(createPaddingRuleRequest());
        const response = createEmptyRule(EPaddingRuleType.DIRECT);
        dispatch(createPaddingRuleSuccess(response));
    } catch (e: any) {
        dispatch(createPaddingRuleFailure(e.response.data.message));
        return console.error(e.response.data ? e.response.data : e.response);
    }
};

export const setSelectedPaddingRule = (paddingRule: TPaddingRule) => (dispatch: any) => {
    dispatch(setCurrentPaddingRule(paddingRule))
}

export const fetchLimitationsForPaddingRules = () => async (dispatch: any) => {
    try {
        dispatch(fetchPaddingRulesRequest());
        const limitationsPaddingRule = await api.get({
            endpoint: `/padding-rules/limitations`,
        })
        dispatch(fetchLimitationsPaddingRulesSuccess(limitationsPaddingRule))
    } catch (e) {
        dispatch(fetchPaddingRulesFailure());
        return console.error(e);
    }
}

export const copyPaddingRule = (paddingRule: TPaddingRule) => async (dispatch: any) => {
    try {
        dispatch(createPaddingRuleRequest());
        const response = {...paddingRule, id: 0};
        dispatch(createPaddingRuleSuccess(response));
    } catch (e: any) {
        dispatch(createPaddingRuleFailure(e.response.data.message));
        return console.error(e);
    }
}

export const createEmptyPaddingRule = (paddingType: EPaddingRuleType, impactedObjects: TPaddingObjectFilterCriteria[] = []) => async (dispatch: any) => {
    try {
        dispatch(createPaddingRuleRequest());
        const response = createEmptyRule(paddingType, impactedObjects);
        dispatch(createPaddingRuleSuccess(response));
    } catch (e: any) {
        dispatch(createPaddingRuleFailure(e.response.data.message));
        return console.error(e.response.data ? e.response.data : e.response);
    }
}




