import { useState } from 'react';
import moment from 'moment';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Table, Space, Button, Popconfirm, TimePicker, Input } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import '../index.scss';
import '../../ReservationTemplatesComponents/TypesTable/index.scss';

// TYPES
import { TimeCreate, TTime } from '../../../types/timerule.type';

type Props = {
  timeSlots: any;
  onChangeTimeSlots: Function;
};

const language: any = intl.messages;

const TimeRulesTable = ({ timeSlots, onChangeTimeSlots }: Props) => {
  // EVENT HANDLERS
  const onChangeTimeSlotHandler = (dates: any, timeRange: any) => {
    const index = timeSlots.indexOf(timeRange);
    const updatedTimeSlot = Object.assign({}, timeRange);
    updatedTimeSlot.clockTimeStart = parseInt(dates[0].format('X'));
    updatedTimeSlot.clockTimeEnd = parseInt(dates[1].format('X'));
    const newTimeSlots: TTime[] = [...timeSlots];
    newTimeSlots.splice(index, 1, updatedTimeSlot);
    onChangeTimeSlots(newTimeSlots);
  };

  const [newTimeSlotString, setNewTimeSlotString] = useState<string>('');

  const onAddTimeSlot = (timeSlot: string) => {
    let newTimeSlot: any = timeSlot.split(',');
    const slots: TTime[] = [];
    newTimeSlot.forEach((timeSlot: any) => {
      if (timeSlot.indexOf('x') !== -1) {
        // Split on before and after'x',
        const separatedWithX = timeSlot.split('x');
        // Split on '-'
        const separatedSpecialTimes = separatedWithX.map((t: any) =>
          t.split('-'),
        );

        // Set startTime, length, occurence & endTime
        const step1and2 = separatedSpecialTimes[0];
        const spStartTime = moment(step1and2[0], 'HH:mm').diff(
          moment().startOf('day'),
          'seconds',
        );
        const spLength =
          moment(step1and2[1], 'HH:mm').diff(
            moment().startOf('day'),
            'seconds',
          ) -
          moment(step1and2[0], 'HH:mm').diff(
            moment().startOf('day'),
            'seconds',
          );
        const step3and4 = separatedSpecialTimes[1];
        const spOccurrence = moment(step3and4[0], 'HH:mm').diff(
          moment().startOf('day'),
          'seconds',
        );
        const spEndTime = moment(step3and4[1], 'HH:mm').diff(
          moment().startOf('day'),
          'seconds',
        );

        for (
          let i = spStartTime;
          i <= spEndTime - spLength;
          i = i + spOccurrence
        ) {
          slots.push(TimeCreate.create(i, i + spLength));
        }
      } else {
        newTimeSlot = timeSlot.split('-');
        newTimeSlot = newTimeSlot.map((t: any) => {
          if (t.indexOf(':') !== -1) {
            return moment(t, 'HH:mm').diff(moment().startOf('day'), 'seconds');
          }
          return moment(t, 'HH').diff(moment().startOf('day'), 'seconds');
        });
        slots.push(TimeCreate.create(newTimeSlot[0], newTimeSlot[1]));
      }
    });
    onChangeTimeSlots([...timeSlots, ...slots]);
  };

  const deleteTimeSlot = (selectedTimeSlot: TTime) => {
    onChangeTimeSlots(
      timeSlots.filter((timeSlot: TTime) => timeSlot !== selectedTimeSlot),
    );
  };

  const timeFormat = 'HH:mm';

  const columns = [
    {
      title: `${language.time_slot}`,
      dataIndex: 'clockTimeStart',
      key: 'clockTimeStart',
      render: (clockTimeStart: any, record: any) => (
        <div style={{ display: 'flex' }}>
          <TimePicker.RangePicker
            size='small'
            format={timeFormat}
            defaultValue={[
              moment.unix(clockTimeStart).utc(),
              moment.unix(record.clockTimeEnd).utc(),
            ]}
            onChange={(dates: any) =>
              onChangeTimeSlotHandler(dates, record.timeSlot)
            }
          />
        </div>
      ),
    },
    {
      title: `${language.action}`,
      key: 'action',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={`${language.sure_to_delete_time_slot}?`}
            onConfirm={() => deleteTimeSlot(record.timeSlot)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Input
          size='small'
          style={{ width: 500, marginRight: '6px' }}
          onChange={(event: any) => setNewTimeSlotString(event.target.value)}
        />
        <Button
          icon={<PlusOutlined />}
          size='small'
          onClick={() => onAddTimeSlot(newTimeSlotString)}
        >
          {language.add_time_slot}
        </Button>
      </div>
      <div className='timeslots-info-text'>
        <p>{language.ex_1_time_slots}</p>
        <p>{language.ex_2_time_slots}</p>
      </div>
      <Table
        size='small'
        dataSource={timeSlots?.map((timeSlot: any) => ({
          key: Math.random(),
          timeSlot,
          ...timeSlot,
        }))}
        columns={columns}
        style={{ width: 500, minHeight: 100 }}
        pagination={false}
        scroll={{ y: 250 }}
      />
    </>
  );
};

export default TimeRulesTable;
