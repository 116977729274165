import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// ACTIONS
import { setBreadcrumbs } from '../../../slices/ui.slice';
import {
  copyUser,
  createEmptyUser,
  createUser,
  deleteUser,
  fetchUsers,
  selectedUserSelector,
  setSelectedUser,
  updateUser,
  usersSelector,
} from '../../../slices/user.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form } from 'antd';

// TYPES
import { TTEUser } from '@timeedit/types/lib';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import NormalListView from '../../../components/List/NormalListView';
import { UserManagerGeneralSettings } from '../../../components/UserManagerComponent';

// UTILS
import { timeConverter } from '../../../utils/timeConverter';
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

const language: any = intl.messages;

type Props = {
  customerSignature: string;
};

/* -------------------------------------------------------------------------------- */

const UserManagerPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const users: TTEUser[] = useSelector(usersSelector);
  const selectedUser: TTEUser = useSelector(selectedUserSelector);

  const listData = users.map((user: any) => ({
    listLabel: user.username,
    value: user.id,
  }));

  const onUserSelected = (selectedValue: string | null) => {
    const currentUser: any = users.find(
      (user: TTEUser) => user.id === selectedValue,
    );
    if (currentUser) {
      dispatch(setSelectedUser(currentUser));
    }
  };

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/organizations-users`,
          label: `${language.organizations_users}`,
        },
        {
          path: `/organizations-users/user-manager`,
          label: `${language.user_manager}`,
        },
        {
          path: `/organizations-users/user-manager`,
          label: `${selectedUser?.username}`,
        },
      ]),
    );
  }, [customerSignature, dispatch, selectedUser?.username, selectedUser?.id]);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  // @todo: Does this one really works? Check when we have right data from the server
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabled(selectedUser, users));
  }, [users, selectedUser]);

  // EVENT HANDLERS
  const onPlusButton = () => {
    dispatch(createEmptyUser(customerSignature));
  };

  const onDeleteButton = () => {
    dispatch(deleteUser(selectedUser));
  };

  const onSaveChangesUsers = () => {
    if (selectedUser.id === null) {
      dispatch(createUser(selectedUser));
    } else {
      dispatch(updateUser(selectedUser));
    }
  };

  const onHandleChanges = (property: string, value: any) => {
    dispatch(setSelectedUser({ ...selectedUser, [property]: value }));
  };

  const onDiscardChanges = () => {
    onUserSelected(selectedUser.id);
  };

  const onCopy = () => {
    dispatch(copyUser(selectedUser));
  };

  return (
    <>
      <ListView
        sectionHeading={language.user_manager}
        number={listData.length}
        onSelect={onPlusButton}
      >
        <NormalListView
          data={listData}
          onSelect={onUserSelected}
          onDelete={onDeleteButton}
          onCopy={onCopy}
          selectedItemId={selectedUser?.id}
        />
      </ListView>
      <div className='inner-content--wrapper'>
        <SectionHeader
          listChoice={
            selectedUser ? selectedUser.username : `${language.user_manager}`
          }
          modifiedDate={
            selectedUser?.history?.length !== 0
              ? timeConverter(selectedUser?.history[0]?.modified)
              : undefined
          }
          modifiedBy={selectedUser?.history[0]?.modifiedBy}
          changeHandler={onSaveChangesUsers}
          discardHandler={onDiscardChanges}
          displayProp={false}
          history={selectedUser?.history}
          isDisabled={saveDisabled}
        />
        <Form layout='vertical' form={form}>
          <div style={{ position: 'relative' }}>
            <UserManagerGeneralSettings
              user={selectedUser}
              onChange={onHandleChanges}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default UserManagerPage;
