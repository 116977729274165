import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';
import _ from 'lodash';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form } from 'antd';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import NormalListView from '../../../components/List/NormalListView';
import {
  AvailabilityTypesGeneralSettings,
  AvailabilityTypesTypes,
} from '../../../components/AvailabilityTypesComponent';

// TYPES
import { TAvailabilityRelationType } from '../../../types/availabilityrelation.type';

// ACTIONS
import {
  fetchAvailabilityTypes,
  availabilityTypesSelector,
  createAvailabilityType,
  deleteAvailabilityType,
  updateAvailabilityType,
  createEmptyAvailabilityType,
  copyAvailabilityType,
  selectedAvailabilityTypeSelector,
  setSelectedAvailabilityType,
  fetchLimitationsAvailabilityTypesSelector,
  fetchLimitationsAvailabilityTypes,
} from '../../../slices/availabilityType.slice';

// UTILS
import { isSavedDisabled } from '../../../utils/isSavedDisabled';

type Props = {
  customerSignature: string;
};

const language: any = intl.messages;

const AvailabilityTypesPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const availabilityTypes: TAvailabilityRelationType[] = useSelector(
    availabilityTypesSelector,
  );
  const limitations = useSelector(fetchLimitationsAvailabilityTypesSelector);
  const selectedAvailabilityType: TAvailabilityRelationType = useSelector(
    selectedAvailabilityTypeSelector,
  );

  // State for setting error if ext.id already exists
  const [extIdError, setExtIdError] = useState(false);

  const listData = availabilityTypes.map((availabilityType: any) => ({
    listLabel: availabilityType.name,
    value: availabilityType.id,
  }));

  const onAvailabilityTypeSelected = (selectedValue: any) => {
    const currentAvailabilityType = availabilityTypes.find(
      (availabilityType: TAvailabilityRelationType) =>
        availabilityType.id === selectedValue,
    );
    if (currentAvailabilityType) {
      dispatch(setSelectedAvailabilityType(currentAvailabilityType));
      setExtIdError(false);
    }
  };

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/availability-types`,
          label: `${language.availability_types}`,
        },
        {
          path: `/${selectedAvailabilityType.id}`,
          label: `${selectedAvailabilityType.name}`,
        },
      ]),
    );
  }, [
    dispatch,
    customerSignature,
    selectedAvailabilityType.id,
    selectedAvailabilityType.name,
  ]);

  useEffect(() => {
    dispatch(fetchAvailabilityTypes());
    dispatch(fetchLimitationsAvailabilityTypes());
  }, [dispatch]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(
      isSavedDisabled(selectedAvailabilityType, availabilityTypes),
    );
  }, [availabilityTypes, selectedAvailabilityType]);

  const [form] = Form.useForm();

  // EVENT HANDLERS
  // New availability type
  const onPlusButton = () => {
    dispatch(createEmptyAvailabilityType());
  };

  // Deletes choosen availability type
  const onDeleteButton = () => {
    dispatch(deleteAvailabilityType(selectedAvailabilityType));
  };

  // Excluding the selected avail.type
  const otherAvailabilityTypes = availabilityTypes.filter(
    (availabilityType) => availabilityType !== selectedAvailabilityType,
  );

  // Save changes function
  const onSaveChangesAvailabilityTypeButton = () => {
    if (selectedAvailabilityType.id === 0) {
      if (
        _.some(
          otherAvailabilityTypes,
          (availabilityType: TAvailabilityRelationType) =>
            availabilityType.extId === selectedAvailabilityType.extId,
        )
      ) {
        setExtIdError(true);
      } else {
        dispatch(createAvailabilityType(selectedAvailabilityType));
        setExtIdError(false);
      }
    }
    dispatch(updateAvailabilityType(selectedAvailabilityType));
    setExtIdError(false);
  };

  const onHandleChange = (property: string, value: string) => {
    dispatch(
      setSelectedAvailabilityType({
        ...selectedAvailabilityType,
        [property]: value,
      }),
    );
  };

  // Discard changes function
  const onDiscardChanges = () => {
    onAvailabilityTypeSelected(selectedAvailabilityType.id);
  };

  // Copy availability relation type
  const onCopy = () => {
    dispatch(copyAvailabilityType(selectedAvailabilityType));
  };

  if (selectedAvailabilityType) {
    return (
      <>
        <ListView
          sectionHeading={language.availability_types}
          number={listData.length}
          onSelect={onPlusButton}
        >
          <NormalListView
            data={listData}
            onSelect={onAvailabilityTypeSelected}
            onDelete={onDeleteButton}
            onCopy={onCopy}
            selectedItemId={selectedAvailabilityType.id}
          />
        </ListView>
        <div className='inner-content--wrapper'>
          <SectionHeader
            listChoice={
              selectedAvailabilityType.name
                ? `${selectedAvailabilityType.name}`
                : `${language.availability_type}`
            }
            changeHandler={onSaveChangesAvailabilityTypeButton}
            discardHandler={onDiscardChanges}
            displayProp={false}
            history={false}
            error={
              extIdError === true
                ? `${language.extid_availability_type_already_exists}`
                : null
            }
            isDisabled={saveDisabled}
          />
          <Form layout='vertical' form={form}>
            <AvailabilityTypesGeneralSettings
              availabilityType={selectedAvailabilityType}
              onChange={onHandleChange}
              limitations={limitations}
            />
            <AvailabilityTypesTypes
              availabilityTypeTypes={selectedAvailabilityType.availabilityTypes}
              onChange={onHandleChange}
            />
          </Form>
        </div>
      </>
    );
  }
  return null;
};

export default AvailabilityTypesPage;
