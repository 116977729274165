import { ChangeEvent } from 'react';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import {
  Table,
  Space,
  Button,
  Popconfirm,
  Form,
  Select,
  Checkbox,
  Input,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import {
  TTimeZone,
  TDst,
  TZone,
  NewTimeZone,
} from '../../../types/timeZone.type';
import {
  deleteTimeZone,
  setUpdatedTimeZones,
} from '../../../slices/timeZone.slice';
import { useDispatch } from 'react-redux';

type Props = {
  timeZones: TTimeZone[];
  onTimeZonesChanged: any;
  limitations: any;
  dsts: TDst[];
  zones: TZone[];
};

const language: any = intl.messages;

const onBlur = () => {};
const onFocus = () => {};

const TimeZonesTable = ({
  timeZones,
  onTimeZonesChanged,
  limitations,
  dsts,
  zones,
}: Props) => {
  const dispatch = useDispatch();

  // EVENT HANDLERS
  const onChangeHandler = (
    timeZone: TTimeZone,
    value: any,
    property: string,
  ) => {
    onTimeZonesChanged(value, property, timeZone);
  };

  const addTimeZone = (zoneId: string) => {
    const newTimeZones: TTimeZone[] = [...timeZones];
    newTimeZones.push(NewTimeZone.create(zoneId));
    dispatch(setUpdatedTimeZones(newTimeZones));
  };

  const onDeleteHandler = (timeZone: TTimeZone) => {
    dispatch(deleteTimeZone(timeZone));
  };

  const columns = [
    {
      title: <span className='small-heading-text'>{language.short_name}</span>,
      dataIndex: 'shortName',
      width: '6vw',
      key: 'shortName',
      render: (shortName: any, record: any) => (
        <Input
          size='small'
          value={shortName}
          onChange={(event: any) => {
            if (
              new TextEncoder().encode(event?.target.value).length >
              limitations.signature
            ) {
              return;
            }
            onChangeHandler(record.timeZone, event.target.value, 'shortName');
          }}
        />
      ),
    },
    {
      title: <span className='small-heading-text'>{language.long_name}</span>,
      dataIndex: 'name',
      key: 'name',
      width: '15vw',
      render: (name: any, record: any) => (
        <Input
          size='small'
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (
              new TextEncoder().encode(event?.target.value).length >
              limitations.name
            ) {
              return;
            }
            onChangeHandler(record.timeZone, event.target.value, 'name');
          }}
          value={name}
        />
      ),
    },
    {
      title: <span className='small-heading-text'>{language.time_zone}</span>,
      dataIndex: 'timeZone',
      key: 'timeZone',
      width: '15vw',
      render: (timeZone: any, record: any) => (
        <Select
          size='small'
          style={{ width: '15vw' }}
          value={timeZone.timeZone}
          onChange={(value: any) => {
            if (new TextEncoder().encode(value).length > limitations.tz) {
              return;
            }
            onChangeHandler(record.timeZone, value, 'timeZone');
          }}
        >
          {zones.map((zone: TZone) => (
            <Select.Option key={zone.id} value={zone.id}>
              {zone.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: (
        <span className='small-heading-text'>
          {language.daylight_savings_time}
        </span>
      ),
      dataIndex: 'daylightSavingsTime',
      key: 'daylightSavingsTime',
      width: '15vw',
      render: (daylightSavingsTime: any, record: any) => (
        <Select
          size='small'
          style={{ width: '15vw' }}
          value={daylightSavingsTime}
          onChange={(value: any) => {
            if (new TextEncoder().encode(value).length > limitations.dst) {
              return;
            }
            onChangeHandler(record.timeZone, value, 'daylightSavingsTime');
          }}
        >
          {dsts.map((dst: TDst) => (
            <Select.Option key={dst.id} value={dst.id}>
              {dst.name} ({dst.id})
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: <span className='small-heading-text'>{language.default}</span>,
      dataIndex: 'defaultCheck',
      key: 'defaultCheck',
      width: '4vw',
      className: 'center-aligned',
      render: (defaultCheck: any) => (
        <Checkbox disabled checked={defaultCheck} />
      ),
    },
    {
      title: <span className='small-heading-text'>{language.offset}</span>,
      dataIndex: 'offset',
      key: 'offset',
      width: '10vw',
      render: (offset: any, record: any) => (
        <Input
          size='small'
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChangeHandler(record.timeZone, event.target.value, 'offset')
          }
          value={offset}
        />
      ),
    },
    {
      title: <span className='small-heading-text'>{language.action}</span>,
      key: 'action',
      className: 'center-aligned',
      width: '5vw',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={`${language.sure_to_delete_time_zone}?`}
            onConfirm={() => onDeleteHandler(record.timeZone)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Form.Item
        label={`${language.add_new_time_zone}`}
        style={{ marginBottom: 10 }}
      >
        <Select
          showSearch
          size='small'
          onChange={addTimeZone}
          placeholder={language.add_new_time_zone}
          onFocus={onFocus}
          onBlur={onBlur}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value='-'
        >
          {zones.map((zone: any) => (
            <Select.Option key={zone.id} value={zone.id}>
              {zone.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Table
        size='small'
        dataSource={timeZones.map((timeZone: any) => ({
          key: timeZone.id,
          ...timeZone,
          timeZone,
        }))}
        pagination={false}
        scroll={{ y: 450 }}
        columns={columns}
        style={{ maxWidth: '80vw' }}
      />
    </>
  );
};

export default TimeZonesTable;
