import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './index.scss';
import {Button, Input, Select, Switch, Table} from "antd";
import {TEToolTip} from "../../TEToolTip";
import intl from "../../../i18n/intl";
import {EPaddingRuleType, TPaddingObjectFilterCriteria, TPaddingRule} from "../../../types/padding.type";
import {typesSelector} from "../../../slices/type.slice";
import {fieldsSelector} from "../../../slices/field.slice";
import {EFieldType, TField} from "../../../types/field.type";
import {TType} from "../../../types/type.type";
import {criteriaDataListSelector, setCriteriaDataList} from "../../../slices/paddingRules.slice";
import {cloneDeep} from "lodash";
import {CopyOutlined, DeleteOutlined} from "@ant-design/icons";

type Props = {
    paddingRule: TPaddingRule
}

const getFieldsForSelectedType = (typeId: number | undefined, allTypes: TType[], allFields: TField[]) => {
    if (!typeId) return [];
    const type = allTypes.find((type) => type.id === typeId);
    if (!type) return [];
    const allFieldIds = [...(type.fields || []), ...(type.parentFields || [])];
    return allFields.filter((field) => allFieldIds.includes(field.id));
};


const language: any = intl.messages;
const {Option} = Select;

const ObjectCriteriaComponent = ({paddingRule}: Props) => {
    const dispatch = useDispatch();

    const dataList = useSelector(criteriaDataListSelector)
    const allTypes = useSelector(typesSelector);
    const allFields = useSelector(fieldsSelector).filter((field: TField) => field.fieldType === EFieldType.CATEGORY);

    useEffect(() => {
        dispatch(setCriteriaDataList(getCriteriaDataList(paddingRule, true)))
    }, [paddingRule.id])

    const getCriteriaDataList = (paddingRule: TPaddingRule, ruleChanged: boolean = false) => {
        const impactedLength = paddingRule.rule.impactedObjects.length;
        let array
        if (dataList == null || dataList.length === 0 || ruleChanged) {
            // @ts-ignore
            array = paddingRule.paddingType === EPaddingRuleType.DIRECT && paddingRule.rule.objectsOnReservation?.length ?
                // @ts-ignore
                [...paddingRule.rule.impactedObjects, ...paddingRule.rule.objectsOnReservation]
                : [...paddingRule.rule.impactedObjects]
        } else {
            return dataList
        }

        return array.map((item: any, index: any) => {
            return {
                item: item,
                key: index,
                impactedObject: index + 1 <= impactedLength,
                rowData: {
                    fields: !item.typeId || item.typeId === 1 ? allFields : allFields.filter((field: any) => field.types.includes(item.typeId)),
                    fieldValues: !item.fieldCriteria?.fieldId ? [] : allFields.filter(field => field.id === item.fieldCriteria?.fieldId)[0].categories,
                }
            } as {
                item: TPaddingObjectFilterCriteria,
                key: number,
                impactedObject: boolean,
                rowData: {
                    fields: TField[],
                    fieldValues: string[],
                }
            }
        })
    }

    const getFields = (index: number) => {
        const data = dataList.filter((item: any) => {
            return item.key === index
        })[0]

        return data.rowData.fields.map((field: any) => {
            return (<Option key={field.id} value={field.id}>{field.name}</Option>)
        })
    }

    const getFieldValues = (index: number) => {
        const data = dataList.filter((item: any) => {
            return item.key === index
        })[0]

        return data.rowData.fieldValues.map((field: any) => {
            return (<Option key={field} value={field}>{field}</Option>)
        })
    }

    const onCopy = (index: number) => {
        const newDataList = cloneDeep(dataList);
        const rowCopy = cloneDeep(newDataList.filter((item: any) => {
            return item.key === index
        })[0])
        newDataList.push({
            ...rowCopy, key: newDataList.length,
        })
        dispatch(setCriteriaDataList(newDataList));
    }

    const onDelete = (index: number) => {
        const newDataList = cloneDeep(dataList).filter((item: any) => {
            return item.key !== index
        })
        dispatch(setCriteriaDataList(newDataList));
    }


    const columns = [
        {
            title: (
                <TEToolTip helpText=''>
                    <span className='small-heading-text'>{language.objects_type}</span>
                </TEToolTip>
            ),
            key: 'typeId',
            width: 120,
            render: (record: any) => {
                return (
                    <Select value={record.item.typeId ? record.item.typeId : 0} style={{width: 160}}
                            showSearch
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={(value: any) => {
                                const fieldsForSelectedType = getFieldsForSelectedType(value, allTypes, allFields)
                                const dataListCopy = cloneDeep(dataList)
                                const dataListIndex = dataListCopy.findIndex((dataRow: any) => dataRow.key === record.key);
                                if(dataListCopy[dataListIndex].item.fieldCriteria === undefined && !dataListCopy[dataListIndex].item.extId) dataListCopy[dataListIndex].item.fieldCriteria = {}
                                if (dataListIndex !== -1) {
                                    dataListCopy[dataListIndex].rowData.fields = fieldsForSelectedType
                                    // @ts-ignore
                                    dataListCopy[dataListIndex].item.fieldCriteria.fieldId = 0
                                    dataListCopy[dataListIndex].item.fieldCriteria.fieldValues = 0
                                } else {
                                    dataListCopy[dataListIndex].rowData = {
                                        fields: fieldsForSelectedType,
                                        fieldValues: [],
                                    };
                                }
                                dataListCopy[dataListIndex].item.typeId = value;
                                dispatch(setCriteriaDataList(dataListCopy));
                            }}>
                        <Option key={0} value={0}>{language.select_type}</Option>
                        {
                            allTypes.map((type: any) => (
                                <Option key={type.id} value={type.id}>{type.name}</Option>
                            ))
                        }
                    </Select>
                )
            },
        },
        {
            title: (
                <TEToolTip helpText=''>
                    <span className='small-heading-text'>{language.field_criteria}</span>
                </TEToolTip>
            ),
            key: 'fieldId',
            width: 120,
            render: (record: any) => {
                return (
                    <Select value={record.item.fieldCriteria?.fieldId || 0}
                            style={{width: 160}}
                            showSearch
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={!record.item.typeId || (record.item.objectExtIds && !!record.item.objectExtIds[0])}
                            onSelect={(value: any) => {
                                const dataListCopy = cloneDeep(dataList)
                                const dataListIndex = dataListCopy.findIndex((dataRow: any) => dataRow.key === record.key);
                                if(dataListCopy[dataListIndex].item.fieldCriteria === undefined && !dataListCopy[dataListIndex].item.extId) dataListCopy[dataListIndex].item.fieldCriteria = {}
                                if (dataListIndex !== -1) {
                                    dataListCopy[dataListIndex].rowData.fieldValues = value === 0 ? [] : allFields.filter(field => field.id === value)[0].categories
                                    dataListCopy[dataListIndex].item.fieldCriteria.fieldValues = 0
                                } else {
                                    dataListCopy[dataListIndex].rowData = {
                                        fields: allFields,
                                        fieldValues: value === 0 ? [] : allFields.filter(field => field.id === value)[0].categories,
                                    };
                                }
                                if (value === 0) {
                                    // @ts-ignore
                                    dataListCopy[dataListIndex].item.fieldCriteria.fieldValues = []
                                }
                                // @ts-ignore
                                dataListCopy[dataListIndex].item.fieldCriteria.fieldId = value;
                                dispatch(setCriteriaDataList(dataListCopy));
                            }}
                    >
                        <Option key={0} value={0}>{language.select_field}</Option>
                        {getFields(record.key)}
                    </Select>)
            },
        },
        {
            title: (
                <TEToolTip helpText=''>
                    <span className='small-heading-text'>{language.field_values}</span>
                </TEToolTip>
            ),
            key: 'fieldValues',
            width: 120,
            render: (record: any) => {
                return (
                    <Select
                        value={record.item.fieldCriteria && record.item.fieldCriteria.fieldValues ? record.item.fieldCriteria.fieldValues[0] : null}
                        showSearch
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={!record.item.typeId || (record.item.objectExtIds && !!record.item.objectExtIds[0])}
                        style={{width: 160}} onSelect={(value: any) => {
                        const dataListCopy = cloneDeep(dataList)
                        const dataListIndex = dataListCopy.findIndex((dataRow: any) => dataRow.key === record.key);

                        // @ts-ignore
                        dataListCopy[dataListIndex].item.fieldCriteria.fieldValues = [value];
                        dispatch(setCriteriaDataList(dataListCopy));
                    }}>
                        <Option key={0} value={null}>{language.select_value}</Option>
                        {getFieldValues(record.key)}
                    </Select>
                )
            },
        },
        {
            title: (
                <TEToolTip helpText=''>
                    <span className='small-heading-text'>{language.object_ext_id}</span>
                </TEToolTip>
            ),
            key: 'objectExtIds',
            width: 120,
            render: (record: any) => (
                <Input
                    size='small'
                    defaultValue={""}
                    style={{width: 160, height: 30}}
                    onChange={(e) => {
                        const dataListCopy = cloneDeep(dataList)
                        const dataListIndex = dataListCopy.findIndex((dataRow: any) => dataRow.key === record.key);
                        dataListCopy[dataListIndex].item.fieldCriteria = e.target.value ? undefined : {};
                        if(!dataListCopy[dataListIndex].item.objectExtIds) dataListCopy[dataListIndex].item.objectExtIds = [];

                        dataListCopy[dataListIndex].item.objectExtIds[0] = e.target.value;
                        dispatch(setCriteriaDataList(dataListCopy));
                    }}
                    value={record.item.objectExtIds && record.item.objectExtIds[0] ? record.item.objectExtIds[0] : ""}
                    disabled={record.item.fieldCriteria?.fieldId || !record.item.typeId}
                />
            ),
        },
    ]

    if (paddingRule.paddingType === EPaddingRuleType.DIRECT) {
        columns.push({
            title: (
                <TEToolTip helpText=''>
                    <span className='small-heading-text'>{language.impactedObject}</span>
                </TEToolTip>
            ),
            key: 'impactedObject',
            width: 120,
            render: (record: any) => (
                <Switch checked={record.impactedObject} onChange={() => {
                    const dataListCopy = cloneDeep(dataList)
                    const dataListIndex = dataListCopy.findIndex((dataRow: any) => dataRow.key === record.key);
                    dataListCopy[dataListIndex].impactedObject = !record.impactedObject;
                    dispatch(setCriteriaDataList(dataListCopy));
                }}/>
            ),
        })
    }

    columns.push(
        {
            title: (
                <TEToolTip helpText=''>
                    <span className='small-heading-text'>{language.actions}</span>
                </TEToolTip>
            ),
            key: 'buttons',
            width: 120,
            render: (record: any) => (
                <>
                    <Button
                        size='large'
                        type='default'
                        className='objects-list-action-button-copy'
                        icon={<CopyOutlined/>}
                        onClick={(event: any) => {
                            event.stopPropagation();
                            onCopy(record.key);
                        }}/>
                    <Button
                        size='large'
                        danger
                        ghost
                        type='default'
                        icon={<DeleteOutlined/>}
                        onClick={() => onDelete(record.key)}/>
                </>
            ),
        },
    )

    return (
        <>
            <h4>{paddingRule.paddingType === EPaddingRuleType.TRAVEL_TIME ? "Impacted objects on reservations" : "Objects on reservations"}</h4>
            <Table
                size='small'
                dataSource={dataList}
                columns={columns}
                style={{marginBottom: '16px', maxHeight: 500, overflowY: 'auto'}}
                pagination={false}
            />
            <span className={"add-object-button"} onClick={() => {
                const dataListCopy = cloneDeep(dataList)
                dataListCopy.push({
                    key: dataListCopy.length,
                    item: {fieldCriteria: {}},
                    impactedObject: paddingRule.paddingType !== EPaddingRuleType.DIRECT,
                    rowData: {
                        fields: [],
                        fieldValues: [],
                    }
                });
                dispatch(setCriteriaDataList(dataListCopy));
            }}>+ {language.add_object_criteria}</span>
        </>
    )
}

export default ObjectCriteriaComponent;