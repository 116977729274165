import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// i18N
import intl from '../../../i18n/intl';

// REDUX
import {
  updateSupportInfo,
  setUpdatedSupportInfo,
  updatedSupportInfoSelector,
  fetchSupportInfo,
  supportInfoSelector,
} from '../../../slices/supportInfo.slice';

// STYLES
import { Collapse, Input, Form } from 'antd';

// TYPES
import { TSupportInfo } from '../../../types/systempreferences.type';

// UTILS
import { isSavedDisabledNoId } from '../../../utils/isSavedDisabled';

// COMPONENTS
import SystemPrefsActionButtons from '../SystemPrefsActionButtons';
import { TEToolTip } from '../../TEToolTip';

type PropsSupportInfo = {
  limitations: any;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const SupportInfo = ({ limitations }: PropsSupportInfo) => {
  const dispatch = useDispatch();
  const supportInfo = useSelector(supportInfoSelector);
  const updatedSupportInfo: TSupportInfo = useSelector(
    updatedSupportInfoSelector,
  );

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabledNoId(updatedSupportInfo, supportInfo));
  }, [updatedSupportInfo, supportInfo]);

  // EVENT HANDLERS
  const onHandleChanges = (
    value: any,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (new TextEncoder().encode(value).length > limitationValue) {
        return;
      }
    }
    const newSupportInfo = {
      ...updatedSupportInfo,
      [property]: value,
    };
    dispatch(setUpdatedSupportInfo(newSupportInfo));
  };

  const onSaveChangesButton = () => {
    dispatch(updateSupportInfo(updatedSupportInfo));
  };

  const onDiscardChanges = () => {
    dispatch(fetchSupportInfo());
  };

  return (
    <Collapse defaultActiveKey={['2']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.support_info} key='1'>
        <SystemPrefsActionButtons
          displayProp={false}
          changeHandler={onSaveChangesButton}
          discardHandler={onDiscardChanges}
          isDisabled={saveDisabled}
        />
        <div className='supportinfo-wrapper'>
          <div className='supportinfo-general'>
            <div className='supportinfo-form-item'>
              <Form.Item
                label={language.general_description}
                tooltip={`(Maximum size of ${limitations.description} bytes)`}
                style={{ marginBottom: 0 }}
              >
                <Input
                  size='small'
                  value={updatedSupportInfo?.generalDescription}
                  onChange={(event: any) => {
                    onHandleChanges(
                      event.target.value,
                      'generalDescription',
                      limitations.description,
                    );
                  }}
                />
              </Form.Item>
            </div>
            <div className='supportinfo-form-item'>
              <Form.Item
                tooltip={`(Maximum size of ${limitations.name} bytes)`}
                label={language.general_name}
                style={{ marginBottom: 0 }}
              >
                <Input
                  size='small'
                  value={updatedSupportInfo?.generalName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onHandleChanges(
                      event.target.value,
                      'generalName',
                      limitations.name,
                    );
                  }}
                />
              </Form.Item>
            </div>
            <div className='supportinfo-form-item'>
              <Form.Item
                tooltip={`(Maximum size of ${limitations.email} bytes)`}
                label={language.general_email}
                style={{ marginBottom: 0 }}
              >
                <Input
                  size='small'
                  value={updatedSupportInfo?.generalEmail}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onHandleChanges(
                      event.target.value,
                      'generalEmail',
                      limitations.email,
                    );
                  }}
                />
              </Form.Item>
            </div>
            <div className='supportinfo-form-item'>
              <Form.Item
                tooltip={`(Maximum size of ${limitations.phone} bytes)`}
                label={language.general_phone}
                style={{ marginBottom: 0 }}
              >
                <Input
                  size='small'
                  value={supportInfo?.generalPhone}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onHandleChanges(
                      event.target.value,
                      'generalPhone',
                      limitations.phone,
                    );
                  }}
                />
              </Form.Item>
            </div>
            <div className='supportinfo-form-item'>
              <Form.Item
                tooltip={`(Maximum size of ${limitations.url} bytes)`}
                label={language.general_URL_title}
                style={{ marginBottom: 0 }}
              >
                <Input.Group className='double-input-wrapper'>
                  <Input
                    size='small'
                    value={updatedSupportInfo?.generalUrl}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      onHandleChanges(
                        event.target.value,
                        'generalUrl',
                        limitations.url,
                      );
                    }}
                  />
                  <Input
                    size='small'
                    value={updatedSupportInfo?.generalUrlTitle}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      onHandleChanges(
                        event.target.value,
                        'generalUrlTitle',
                        limitations.urlTitle,
                      );
                    }}
                    style={{ marginLeft: 6, width: 130 }}
                  />
                </Input.Group>
              </Form.Item>
            </div>
            <div className='supportinfo-form-item'>
              <Form.Item
                label={language.general_manual_title}
                style={{ marginBottom: 0 }}
              >
                <Input.Group className='double-input-wrapper'>
                  <Input
                    size='small'
                    value={updatedSupportInfo?.generalManualUrl}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      onHandleChanges(event.target.value, 'generalManualUrl');
                    }}
                  />
                  <Input
                    size='small'
                    value={updatedSupportInfo?.generalManualUrlTitle}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      onHandleChanges(
                        event.target.value,
                        'generalManualUrlTitle',
                      )
                    }
                    style={{ marginLeft: 6, width: 130 }}
                  />
                </Input.Group>
              </Form.Item>
            </div>
          </div>
          <div className='supportinfo-technical'>
            <div className='supportinfo-form-item'>
              <Form.Item
                tooltip={`(Maximum size of ${limitations.description} bytes)`}
                style={{ marginBottom: 0 }}
                label={language.technical_description}
              >
                <Input
                  size='small'
                  value={updatedSupportInfo?.technicalDescription}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onHandleChanges(
                      event.target.value,
                      'technicalDescription',
                      limitations.description,
                    );
                  }}
                />
              </Form.Item>
            </div>
            <div className='supportinfo-form-item'>
              <Form.Item
                tooltip={`(Maximum size of ${limitations.name} bytes)`}
                style={{ marginBottom: 0 }}
                label={language.technical_name}
              >
                <Input
                  size='small'
                  value={updatedSupportInfo?.technicalName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onHandleChanges(
                      event.target.value,
                      'technicalName',
                      limitations.name,
                    );
                  }}
                />
              </Form.Item>
            </div>
            <div className='supportinfo-form-item'>
              <Form.Item
                tooltip={`(Maximum size of ${limitations.email} bytes)`}
                label={language.technical_email}
                style={{ marginBottom: 0 }}
              >
                <Input
                  size='small'
                  value={updatedSupportInfo?.technicalEmail}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onHandleChanges(
                      event.target.value,
                      'technicalEmail',
                      limitations.email,
                    );
                  }}
                />
              </Form.Item>
            </div>
            <div className='supportinfo-form-item'>
              <Form.Item
                tooltip={`(Maximum size of ${limitations.phone} bytes)`}
                label={language.technical_phone}
                style={{ marginBottom: 0 }}
              >
                <Input
                  size='small'
                  value={updatedSupportInfo?.technicalPhone}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onHandleChanges(
                      event.target.value,
                      'technicalPhone',
                      limitations.phone,
                    );
                  }}
                />
              </Form.Item>
            </div>
            <div className='supportinfo-form-item'>
              <Form.Item
                tooltip={`(Maximum size of ${limitations.url} bytes)`}
                label={language.technical_URL_title}
                style={{ marginBottom: 0 }}
              >
                <Input.Group className='double-input-wrapper'>
                  <Input
                    size='small'
                    value={updatedSupportInfo?.technicalUrl}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      onHandleChanges(
                        event.target.value,
                        'technicalUrl',
                        limitations.url,
                      );
                    }}
                  />
                  <Input
                    size='small'
                    value={updatedSupportInfo?.technicalUrlTitle}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      onHandleChanges(
                        event.target.value,
                        'technicalUrlTitle',
                        limitations.urlTitle,
                      );
                    }}
                    style={{ marginLeft: 6, width: 130 }}
                  />
                </Input.Group>
              </Form.Item>
            </div>
            <div className='supportinfo-form-item'>
              <Form.Item
                label={language.technical_manual_title}
                style={{ marginBottom: 0 }}
              >
                <Input.Group className='double-input-wrapper'>
                  <Input
                    size='small'
                    value={updatedSupportInfo?.technicalManualUrl}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      onHandleChanges(event.target.value, 'technicalManualUrl')
                    }
                  />
                  <Input
                    size='small'
                    value={updatedSupportInfo?.technicalManualUrlTitle}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      onHandleChanges(
                        event.target.value,
                        'technicalManualUrlTitle',
                      )
                    }
                    style={{ marginLeft: 6, width: 130 }}
                  />
                </Input.Group>
              </Form.Item>
            </div>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};
