import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TEObject, TTEObject } from '../types/object.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedObjects: [],
  listedObjects: [],
  allObjects: [],
};

// Slice
const slice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    fetchObjectsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchObjectsSuccess: (state: any, { payload }) => {
      const selectedIds = state.selectedObjects.map(
        (selectedObject: any) => selectedObject.id,
      );
      state.selectedObjects = payload.results.filter(
        (object: any) => selectedIds.indexOf(object.id) !== -1,
      );
      state.listedObjects = payload.results;
      state.allObjects = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchObjectsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createObjectRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createObjectSuccess: (state: any, { payload }) => {
      state.allObjects.push(payload);
      state.listedObjects.push(payload);
      state.selectedObjects = [payload];
      finishedLoadingSuccess(state);
    },
    createObjectFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentObjects: (state: any, { payload }) => {
      state.selectedObjects = payload;
      const listedIds = state.listedObjects.map(
        (listedObject: any) => listedObject.id,
      );
      payload.forEach((object: TTEObject) => {
        const index = listedIds.indexOf(object.id);
        state.listedObjects[index] = object;
      });
    },
    setListedObjects: (state: any, { payload }) => {
      state.listedObjects = payload;
    },
    deleteObjectRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteObjectSuccess: (state: any, payload: any) => {
      state.allObjects = state.allObjects.filter(
        (object: TTEObject) => object.id !== payload.payload.id,
      );
      state.selectedObject = TEObject.createEmpty(1, []);
      finishedLoadingSuccess(state);
    },
    deleteObjectsSuccess: (state: any, payload: any) => {
      state.allObjects = state.allObjects.filter(
        (object: TTEObject) => object.id !== payload.payload.id,
      );
      state.selectedObjects = state.selectedObjects.filter(
        (object: TTEObject) => object.id !== payload.payload.id,
      );
    },
    deleteObjectFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateObjectRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateObjectSuccess: (state: any, { payload }) => {
      const index = state.allObjects.findIndex(
        (object: TTEObject) => object.id === payload.id,
      );
      state.allObjects[index] = payload;
      finishedLoadingSuccess(state);
    },
    updateObjectsSuccess: (state: any, { payload }) => {
      payload.forEach((p: any) => {
        const index = state.allObjects.findIndex(
          (object: TTEObject) => object.id === p.id,
        );
        state.allObjects[index] = p;
      });
      finishedLoadingSuccess(state);
    },
    updateObjectFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const objectsLoading = (state: any) => state.objects.loading;
export const objectsSelector = (state: any) =>
  state.objects.allObjects as TTEObject[];
export const objectSelectorCreateNew =
  (selectedTypeSearch: any, fieldIdsForSelectedType: any) => () => {
    return TEObject.createEmpty(selectedTypeSearch, fieldIdsForSelectedType);
  };
export const selectedObjectsSelector = (state: any) => {
  return state.objects.selectedObjects;
};
export const listedObjectSelector = (state: any) => {
  return state.objects.listedObjects;
};

// Actions
export const {
  fetchObjectsRequest,
  fetchObjectsSuccess,
  fetchObjectsFailure,
  createObjectRequest,
  createObjectSuccess,
  createObjectFailure,
  setCurrentObjects,
  setListedObjects,
  deleteObjectRequest,
  deleteObjectSuccess,
  deleteObjectsSuccess,
  deleteObjectFailure,
  updateObjectRequest,
  updateObjectSuccess,
  updateObjectsSuccess,
  updateObjectFailure,
} = slice.actions;

export const fetchObjects = () => async (dispatch: any) => {
  try {
    dispatch(fetchObjectsRequest());
    const objects = await api.get({
      endpoint: `/object-manager`,
    });
    dispatch(fetchObjectsSuccess(objects));
  } catch (e) {
    dispatch(fetchObjectsFailure());
    return console.error(e);
  }
};

export const createObject = (object: TTEObject) => async (dispatch: any) => {
  try {
    dispatch(createObjectRequest());
    // Blir fel då ett helt tomt objekt skapas, tar ej med uppdateringarna som gjorts
    const { id: _, ...ObjectBody } = object;
    const response = await api.post({
      endpoint: `/object-manager`,
      data: ObjectBody,
    });
    // const responses: any = await Promise.all(results);
    dispatch(createObjectSuccess(response));
  } catch (e) {
    dispatch(createObjectFailure());
    return console.error(e);
  }
};

export const createEmptyObject =
  (selectedTypeSearch: any, fieldIdsForSelectedType: any[]) =>
  async (dispatch: any) => {
    try {
      dispatch(createObjectRequest());
      const response = TEObject.createEmpty(
        selectedTypeSearch,
        fieldIdsForSelectedType,
      );
      dispatch(createObjectSuccess(response));
    } catch (e: any) {
      dispatch(createObjectFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const setSelectedObjects = (objects: []) => async (dispatch: any) => {
  dispatch(setCurrentObjects(objects));
};

export const setListObjects =
  (objects: TTEObject[]) => async (dispatch: any) => {
    dispatch(setListedObjects(objects));
  };

export const copyObject = (object: TTEObject) => async (dispatch: any) => {
  try {
    dispatch(createObjectRequest());
    const response = { ...object, id: 0, extId: '' };
    dispatch(createObjectSuccess(response));
  } catch (e) {
    dispatch(createObjectFailure());
    return console.error(e);
  }
};

export const deleteObject = (object: TTEObject) => async (dispatch: any) => {
  try {
    dispatch(deleteObjectRequest());
    await api.delete({
      endpoint: `/object-manager/${object.id}`,
    });
    dispatch(deleteObjectSuccess(object));
  } catch (e) {
    dispatch(deleteObjectFailure());
    return console.error(e);
  }
};

export const deleteObjects =
  (objects: Array<TTEObject>) => async (dispatch: any) => {
    try {
      dispatch(deleteObjectRequest());
      const results = await objects.map(async (object: TTEObject) => {
        await api.delete({
          endpoint: `/object-manager/${object.id}`,
        });
      });
      const responses: any = await Promise.all(results);
      dispatch(deleteObjectsSuccess(responses));
    } catch (e) {
      dispatch(deleteObjectFailure());
      return console.error(e);
    }
  };

export const updateObjects =
  (objects: Array<TTEObject>) => async (dispatch: any) => {
    try {
      dispatch(updateObjectRequest());
      const results = await objects.map(async (object: TTEObject) => {
        const { ...ObjectBody } = object;
        const response = await api.patch({
          endpoint: `/object-manager/${object.id}`,
          data: { ...ObjectBody },
        });
        return response;
      });
      const responses: any = await Promise.all(results);
      dispatch(updateObjectsSuccess(responses));
    } catch (e) {
      dispatch(updateObjectFailure());
      return console.error(e);
    }
  };
