import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';

// i18N
import intl from '../../../i18n/intl';

// REDUX
import { organizationsSelector } from '../../../slices/organization.slice';
import { rolesSelector } from '../../../slices/role.slice';

// STYLES
import './index.scss';
import { Button, Space, Popconfirm, Select, Input } from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  MenuOutlined,
  MinusOutlined,
} from '@ant-design/icons';

// TYPES
import { TOrganizationTreeNode } from '../../../types/organization.type';
import { TRole } from '../../../types/role.type';

// COMPONENTS
import { TEToolTip } from '../../TEToolTip';
import DragableTable from '../../ReuseableTables/DragableTable/DragableTable';

// UTILS
import { getNameOnOrganization, getNameOnRole } from '../../../utils/getNameOn';
import { changeArrayOrder } from '../../../utils/changeArrayOrder';

const language: any = intl.messages;

type Props = {
  userPermissions:
    | [[string, string], string[], string[], string, string[], string][]
    | any;
  onChange: any;
};

export const UserPermissionsTable = ({ userPermissions, onChange }: Props) => {
  const organizations: TOrganizationTreeNode[] = useSelector(
    organizationsSelector,
  );
  const chooseableRoles: TRole[] = useSelector(rolesSelector);
  const [orderOfOrganizationRole, setOrderOfOrganizationRole] = useState<any[]>(
    [...userPermissions],
  );

  // EVENT HANDLERS
  const onChangeHandler = (data: any, indexWithinArray: number, value: any) => {
    const index = orderOfOrganizationRole.indexOf(data.userPermission);
    const updatedUserPermissions = [...data.userPermission];
    updatedUserPermissions[indexWithinArray] = value;
    const newUserPermissions: any = [...orderOfOrganizationRole];
    newUserPermissions.splice(index, 1, updatedUserPermissions);
    onChange(newUserPermissions, 'partialAttributesMatching');
  };

  const onAddRow = () => {
    const newUserPermissions: [
      [string, string],
      string[],
      string[],
      string,
      string[],
      string,
    ][] = [...userPermissions];
    newUserPermissions.push([['', ''], [''], [''], '', [''], '']);
    onChange(newUserPermissions, 'partialAttributesMatching');
  };

  const onDeleteRow = (dataKey: any) => {
    onChange(
      userPermissions.filter(
        (userPermission: any) => userPermission !== dataKey,
      ),
      'partialAttributesMatching',
    );
  };
  const onChangeInsideArray = (
    userPermission: any,
    fullArray: any,
    data: any,
    indexWithinArray: number,
    value: any,
    insideIndex?: number,
  ) => {
    const index = fullArray.indexOf(data);
    let updatedValue;
    if (insideIndex !== undefined) {
      updatedValue = [...data];
      updatedValue[insideIndex] = value;
    } else {
      updatedValue = data;
      updatedValue = value.toString();
    }
    const newValues: any = [...fullArray];
    newValues.splice(index, 1, updatedValue);
    onChangeHandler(userPermission, indexWithinArray, newValues);
  };

  const onAddInsideRowSingle = (
    userPermission: any,
    indexWithinArray: number,
    item: any, // add organization, role or userObject here
  ) => {
    const newRowValue = [...item];
    newRowValue.push('');
    onChangeHandler(userPermission, indexWithinArray, newRowValue);
  };

  const onAddInsideRowDouble = (
    userPermission: any,
    item: any, // add attributePattern here
    indexWithinArray: number,
  ) => {
    const newRowValue = [...item];
    newRowValue.push(['', '']);
    onChangeHandler(userPermission, indexWithinArray, newRowValue);
  };

  const onRemoveInsideRow = (
    userPermission: any,
    fullArray: any,
    data: any,
    indexWithinArray: number,
  ) => {
    onChangeHandler(
      userPermission,
      indexWithinArray,
      fullArray.filter((d: any) => d !== data),
    );
  };

  // Columns
  const columns = [
    {
      title: (
        <TEToolTip helpText={language.help_sort_res_template_types}>
          <span className='small-heading-text'>{language.sort}</span>
        </TEToolTip>
      ),
      dataIndex: 'sort',
      width: 25,
      className: 'user-permission-table',
      render: () => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />,
    },
    {
      title: (
        <TEToolTip helpText={language.help_attributes_pattern}>
          <span className='small-heading-text'>
            {language.attributes_pattern}
          </span>
          <span style={{ fontStyle: 'italic' }}> (Attribute + Value)</span>
        </TEToolTip>
      ),
      dataIndex: 'attributesPattern',
      key: 'attributesPattern',
      width: 180,
      className: 'user-permission-table',
      render: (record: any, userPermission: any) =>
        userPermission[0].map((attributesPattern: any, index: any) => (
          <div key={index} style={{ display: 'flex' }}>
            <Input
              size='small'
              key={'attribute'}
              value={attributesPattern[0]}
              style={{ width: 160 }}
              onChange={(event: any) =>
                onChangeInsideArray(
                  userPermission,
                  userPermission[0],
                  attributesPattern,
                  0,
                  event.target.value,
                  0,
                )
              }
            />
            <Input
              size='small'
              key={'value'}
              value={attributesPattern[1]}
              style={{ width: 70 }}
              onChange={(event: any) =>
                onChangeInsideArray(
                  userPermission,
                  userPermission[0],
                  attributesPattern,
                  0,
                  event.target.value,
                  1,
                )
              }
            />
            {userPermission[0].length > 1 && (
              <Button
                size='small'
                danger
                ghost
                type='default'
                className='types-list-action-button-delete'
                icon={<MinusOutlined />}
                onClick={() =>
                  onRemoveInsideRow(
                    userPermission,
                    userPermission[0],
                    attributesPattern,
                    0,
                  )
                }
              />
            )}
            {index === userPermission[0].length - 1 && (
              <Button
                size='small'
                style={{ border: 'none', boxShadow: 'none' }}
                icon={<PlusOutlined />}
                onClick={() =>
                  onAddInsideRowDouble(userPermission, userPermission[0], 0)
                }
              />
            )}
          </div>
        )),
    },
    {
      title: (
        <TEToolTip helpText={'help_text_missing'}>
          <span className='small-heading-text'>{language.organization}</span>
        </TEToolTip>
      ),
      dataIndex: 'organization',
      key: 'organization',
      width: 190,
      className: 'user-permission-table',
      render: (record: any, userPermission: any) =>
        userPermission[1].map((organization: any, index: number) => (
          <div key={index} style={{ display: 'flex', marginRight: 12 }}>
            <Select
              size='small'
              style={{ width: 180 }}
              key={organization}
              showSearch
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={getNameOnOrganization(
                organizations,
                parseInt(organization),
              )}
              onChange={(event: any) =>
                onChangeInsideArray(
                  userPermission,
                  userPermission[1],
                  organization,
                  1,
                  event,
                )
              }
            >
              {organizations.map((org: any) => (
                <Select.Option key={org.name} value={org.id}>
                  {org.name}
                </Select.Option>
              ))}
            </Select>
            {userPermission[1].length > 1 && (
              <Button
                size='small'
                danger
                ghost
                type='default'
                className='types-list-action-button-delete'
                icon={<MinusOutlined />}
                onClick={() =>
                  onRemoveInsideRow(
                    userPermission,
                    userPermission[1],
                    organization,
                    1,
                  )
                }
              />
            )}
            {index === userPermission[1].length - 1 && (
              <Button
                size='small'
                style={{ border: 'none', boxShadow: 'none' }}
                icon={<PlusOutlined />}
                onClick={() =>
                  onAddInsideRowSingle(userPermission, 1, userPermission[1])
                }
              />
            )}
          </div>
        )),
    },
    {
      title: (
        <TEToolTip helpText={'help_text_missing'}>
          <span className='small-heading-text'>{'Role'}</span>
        </TEToolTip>
      ),
      dataIndex: 'role',
      key: 'role',
      width: 190,
      className: 'user-permission-table',
      render: (record: any, userPermission: any) =>
        userPermission[2].map((role: any, index: number) => (
          <div key={index} style={{ display: 'flex', marginRight: 12 }}>
            <Select
              size='small'
              style={{ width: 180 }}
              key={role}
              showSearch
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={getNameOnRole(chooseableRoles, role)}
              onChange={(event: any) =>
                onChangeInsideArray(
                  userPermission,
                  userPermission[2],
                  role,
                  2,
                  event,
                )
              }
            >
              {chooseableRoles.map((role: any) => (
                <Select.Option key={role.id} value={role.id}>
                  {role.name}
                </Select.Option>
              ))}
            </Select>
            {userPermission[2].length > 1 && (
              <Button
                size='small'
                danger
                ghost
                type='default'
                className='types-list-action-button-delete'
                icon={<MinusOutlined />}
                onClick={() =>
                  onRemoveInsideRow(userPermission, userPermission[2], role, 2)
                }
              />
            )}
            {index === userPermission[2].length - 1 && (
              <Button
                size='small'
                style={{ border: 'none', boxShadow: 'none' }}
                icon={<PlusOutlined />}
                onClick={() =>
                  onAddInsideRowSingle(userPermission, 2, userPermission[2])
                }
              />
            )}
          </div>
        )),
    },
    {
      title: (
        <TEToolTip helpText={language.help_auth_configs_standard_organization}>
          <span className='small-heading-text'>
            {language.standard_organization}
          </span>
        </TEToolTip>
      ),
      dataIndex: 'standardOrganizations',
      key: 'standardOrganizations',
      width: 170,
      className: 'user-permission-table',
      render: (record: any, userPermission: any) => (
        <Select
          size='small'
          style={{ width: 170 }}
          showSearch
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={getNameOnOrganization(
            organizations,
            parseInt(userPermission[3]),
          )}
          onChange={(event: any) => onChangeHandler(userPermission, 3, event)}
        >
          {organizations.map((org: any) => (
            <Select.Option key={org.id} value={org.id}>
              {org.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: (
        <TEToolTip helpText={language.help_auth_configs_user_objects}>
          <span className='small-heading-text'>{language.user_objects}</span>
        </TEToolTip>
      ),
      dataIndex: 'userObject',
      key: 'userObject',
      width: 180,
      className: 'user-permission-table',
      render: (record: any, userPermission: any) =>
        userPermission[4].map((userObject: any, index: number) => (
          <div style={{ display: 'flex' }} key={index}>
            <Input
              size='small'
              key={'userObject'}
              value={userObject}
              onChange={(event: any) =>
                onChangeInsideArray(
                  userPermission,
                  userPermission[4],
                  userObject,
                  4,
                  event.target.value,
                )
              }
            />
            {userPermission[4].length > 1 && (
              <Button
                size='small'
                danger
                ghost
                type='default'
                className='types-list-action-button-delete'
                icon={<MinusOutlined />}
                onClick={() =>
                  onRemoveInsideRow(
                    userPermission,
                    userPermission[4],
                    userObject,
                    4,
                  )
                }
              />
            )}
            {index === userPermission[4].length - 1 && (
              <Button
                size='small'
                style={{ border: 'none', boxShadow: 'none' }}
                icon={<PlusOutlined />}
                onClick={() =>
                  onAddInsideRowSingle(userPermission, 4, userPermission[4])
                }
              />
            )}
          </div>
        )),
    },
    {
      title: (
        <TEToolTip helpText=''>
          <span className='small-heading-text'>{language.description}</span>
        </TEToolTip>
      ),
      dataIndex: 'description',
      key: 'description',
      width: 180,
      className: 'user-permission-table',
      render: (record: any, userPermission: any) => (
        <Input.TextArea
          value={userPermission[5]}
          onChange={(event: any) =>
            onChangeHandler(userPermission, 5, event.target.value)
          }
          autoSize={{ minRows: 3, maxRows: 15 }}
        />
      ),
    },
    {
      title: <span className='small-heading-text'>{language.action}</span>,
      key: 'action',
      width: 50,
      className: 'user-permission-table',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={language.sure_to_delete}
            onConfirm={() => onDeleteRow(record.userPermission)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setOrderOfOrganizationRole(
        changeArrayOrder(orderOfOrganizationRole, dragIndex, hoverIndex),
      );
    },
    [orderOfOrganizationRole],
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        type='default'
        size='small'
        icon={<PlusOutlined />}
        onClick={onAddRow}
        style={{ marginBottom: '16px', width: '120px' }}
      >
        {language.add_row}
      </Button>
      <DragableTable
        size='small'
        dataSource={orderOfOrganizationRole?.map(
          (userPermission: any, index: number) => ({
            key: index,
            ...userPermission,
            userPermission,
          }),
        )}
        columns={columns}
        style={{ marginBottom: '16px' }}
        onDrag={moveRow}
      />
    </div>
  );
};
