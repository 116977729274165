import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

// REDUX
import { setBreadcrumbs } from '../../slices/ui.slice';

// i18N
import intl from '../../i18n/intl';

const language: any = intl.messages;

const OrganizationsUsersPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          path: '/organizations-users',
          label: `${language.organizations_users}`,
        },
      ]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Redirect to='/organizations-users/organizations' />;
};

export default OrganizationsUsersPage;
