import { ReactNode } from 'react';

// COMPONENTS
import Menu from '../Menu';
import TopBar from '../TopBar';

// STYLES
import './index.scss';

type Props = {
  children: ReactNode;
};

const MainLayout = ({ children }: Props) => {
  return (
    <div className='app--wrapper'>
      <Menu />
      <div className='main--wrapper'>
        <TopBar />
        <div className='content--wrapper'>{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
