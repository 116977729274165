import { CONSTANTS } from '@timeedit/types/lib';
const { AppIdsArr } = CONSTANTS;
/**
 * TS typing for AuthConfig
 */

export enum EAuthStrategy {
  teIdp = 'teIdp',
  saml2 = 'saml2',
}

export enum EAuthLogLevel {
  none = 'none',
  low = 'low',
  high = 'high',
  debug = 'debug',
}

export enum EAuthSaml2Signing {
  NONE = 'none', // Do not sign at all, and disregard signing from the IdP
  SIGN = 'sign', // We sign our requests, and if IdP has signed we will decode, but do not require signed callbacks from IdP
  REQUIRED = 'required', // We sign, and require signed callbacks from the IdP
}

export enum EAuthSaml2SignatureAlgorithm {
  SHA1 = 'SHA-1',
  SHA256 = 'SHA-256',
  SHA512 = 'SHA-512',
}

export enum EApplySSOPermissions {
  ALWAYS = 'ALWAYS',
  NEW_USERS = 'NEW_USERS',
  NEVER = 'NEVER',
}

export enum EUserObjectMatching {
  OBJECT_EXTID = 'OBJECT_EXTID',
  OBJECT_FIELD = 'OBJECT_FIELD',
}

export interface IAuthConfigDefaults {
  version: number;
}

export interface ISAML2Config extends IAuthConfigDefaults {
  /**
   * Unique entity in TE meta data for config
   * Mandatory, specifies our (TimeEdit's) entity id for our customer
   */
  spEntityId: string;

  /**
   * FROM METADATA OR SPECIFICALLY ENTERED
   */
  /**
   * URL for fetching IdP meta data
   * Not mandatory, remaining parameters can be used for full configuration
   */
  idpMetaDataUrl: string | null; // IdP meta data location
  /**
   * IdP entity id
   * Required if metadata url is specified
   */
  idpEntityId: string | null;

  /**
   * IF WE DON'T HAVE METADATA
   */
  /**
   * Login URL
   * Used if no metadata is specified
   */
  idpEntryPointUrl: string | null;
  /**
   * Private key to sign requests with
   * Only specified if no metadata is provided
   */
  idpPrivateKey: string | null;
  /**
   * Logout url
   * Normally logout URL is same as login url
   * Sometimes one can need to specify an extra logout URL
   * If not specified use:
   * 1) Logout URL in metadata
   * 2) If metadata is not specified, use idpEntryPointUrl
   */
  idpLogoutUrl: string | null;

  /**
   * Signing requirements
   * See enum definition
   */
  signatureSigning: EAuthSaml2Signing;
  /**
   * Which algorithm we should use for signing our requests
   * Default: SHA-256 for new auth configs
   */
  signatureAlgorithm: EAuthSaml2SignatureAlgorithm;
  /**
   * Use POST for callbacks
   * Default: true
   */
  preferHttpPost: boolean;

  /**
   * ATTRIBUTES PARSING
   */
  /**
   * Default: "eduPersonPrincipalName"
   */
  userNameAttribute: string | null; // Attribute in SAML2 data that contains user account name
  /**
   * Default: "mail"
   */
  emailAttribute: string | null; // Attribute in SAML2 data that contains user email
  /**
   * Default: "givenName"
   */
  givenNameAttribute: string | null; // Attribute in SAML2 data that contains user first name
  /**
   * Default: "sn"
   */
  lastNameAttribute: string | null; // Attribute in SAML2 data that contains user last name

  /**
   * Only allow login based on the presence of a certain sets of attributes and values
   * Array of tuples of strings, idx = 0 => comma separated list of attributes, idx = 1 => comma separated list of values
   * Haxxing regexp will be run on the backend
   */
  mandatoryAttributes: [string, string][];
  /**
   * Always disallow login based on the presence of a certain sets of attributes and values
   * Array of tuples of strings, idx = 0 => comma separated list of attributes, idx = 1 => comma separated list of values
   * Haxxing regexp will be run on the backend
   */
  forbiddenAttributes: [string, string][]; // Allow login only if user don't contain attributes

  userObjectMatching: EUserObjectMatching; // Check with JH, ska vi visa upp endast en av nedan beroende på vad som är valt?
  // Why send this value to the server?
  userObjectExtId: string | null;
  userObjectField: [string, string] | null;
  /**
   * Default: false
   */
  requireUserObject: boolean;

  /**
   * PERMISSIONS
   */
  /**
   * Apply permissions from the SSO based on the authentication request
   * Default: always
   */
  applySSOPermissions: EApplySSOPermissions;
  /**
   * To type in a userId to use as template user user when new user is created
   */
  templateUser: string;
  /**
   * Partial attributes matching
   * idx 0 = attributes pattern including attribute and value
   * idx 1 = organizations
   * idx 2 = roles
   * idx 3 = standard organization
   * idx 4 = user objects
   * idx 5 = description
   */
  partialAttributesMatching: [
    [string, string][],
    string[],
    string[],
    string,
    string[],
    string,
  ][];
}

export interface ITEIdPConfig extends IAuthConfigDefaults {
  useMFA: boolean;
}

export interface IAuthConfig extends Document {
  name: string;
  description: string;
  organizationId: string;
  strategy: EAuthStrategy;
  config: ISAML2Config | ITEIdPConfig;
  active: boolean;
  default: boolean;
  enabledForAppIds: typeof AppIdsArr;
  logLevel: EAuthLogLevel;
}

export type TAuthenticationConfigurationType = {
  name: string;
  description: string;
  id: string;
  organizationId: string;
  customerSignature: string; //  OK to be empty
  logLevel: EAuthLogLevel;
  active: boolean;
  default: boolean;
  enabledForAppIds: typeof AppIdsArr;
  strategy: EAuthStrategy | '';
  config: ITEIdPConfig | ISAML2Config | null;
  /*   history: THistory[]; */
};

export class AuthenticationConfigurationType {
  static createEmpty = (
    customerSignature: string,
  ): TAuthenticationConfigurationType => ({
    name: '',
    description: '',
    id: '0',
    customerSignature: customerSignature,
    organizationId: '',
    logLevel: EAuthLogLevel.none,
    active: false,
    default: false,
    enabledForAppIds: [],
    strategy: '',
    config: null,
    /*    history: [], */
  });
}

export class ITEIdPConfigCreate {
  static createEmpty = (): ITEIdPConfig => ({
    version: 1,
    useMFA: false,
  });
}

export class ISAML2ConfigCreate {
  static createEmpty = (): ISAML2Config => ({
    version: 1,
    spEntityId: '',
    idpMetaDataUrl: null,
    idpEntityId: null,
    idpEntryPointUrl: null,
    idpPrivateKey: null,
    idpLogoutUrl: null,
    signatureSigning: EAuthSaml2Signing.NONE,
    signatureAlgorithm: EAuthSaml2SignatureAlgorithm.SHA256,
    preferHttpPost: true,
    userNameAttribute: 'eduPersonPrincipalName',
    emailAttribute: 'mail',
    givenNameAttribute: 'givenName',
    lastNameAttribute: 'sn',
    mandatoryAttributes: [],
    forbiddenAttributes: [],
    userObjectMatching: EUserObjectMatching.OBJECT_FIELD,
    userObjectExtId: null,
    userObjectField: ['', ''],
    requireUserObject: false,
    applySSOPermissions: EApplySSOPermissions.NEVER,
    templateUser: '',
    partialAttributesMatching: [],
  });
}
