export enum EPaddingRuleType {
    DIRECT = 'DIRECT',
    TRAVEL_TIME = 'TRAVEL_TIME',
}

export type TPaddingFieldCriteria = {
    fieldId: number;
    fieldValues: string[];
};

export type TPaddingObjectFilterCriteria = {
    typeId?: number;
    fieldCriteria?: TPaddingFieldCriteria;
    objectExtIds?: string[];
};

export type TTravelTimePaddingDistance = {
    fieldValues: [string, string];
    padding: number;
};

/**
 * TRAVEL TIME PADDING
 */
export type TTravelTimePaddingRule = {
    controllingTypeId: number;
    controllingFieldId: number;
    distances: TTravelTimePaddingDistance[];
    impactedObjects: TPaddingObjectFilterCriteria[];
};

/**
 * DIRECT PADDING
 */
export type TDirectPaddingRule = {
    timeConditions?: TTimeCondition[]
    objectsOnReservation?: TPaddingObjectFilterCriteria[];
    impactedObjects: TPaddingObjectFilterCriteria[];
    paddingBefore: number;
    paddingAfter: number;
};

export type TTimeCondition = {
    startTime: number;
    endTime: number;
}

export type TPaddingRule = {
    id: number;
    paddingType: EPaddingRuleType;
    name: string;
    description: string;
    rule: TDirectPaddingRule | TTravelTimePaddingRule;
};

export const createEmptyRule = (type: EPaddingRuleType, impactedObjects: TPaddingObjectFilterCriteria[] = []) => {

    // @ts-ignore
    const paddingRule: TPaddingRule = {
        id: type === EPaddingRuleType.DIRECT ? 0 : -1,
        paddingType: type,
        name: 'New padding rule',
        description: '',
    }

    if(type === EPaddingRuleType.DIRECT) {
        paddingRule.rule = {
            timeConditions: [{
                startTime: 0,
                endTime: 0,
            }],
                impactedObjects: impactedObjects,
                paddingBefore: 0,
                paddingAfter: 0,
        }
    }else {
        paddingRule.rule = {
            controllingTypeId: 0,
            controllingFieldId: 0,
            distances: [],
            impactedObjects: [],
        }
    }
    return paddingRule
}