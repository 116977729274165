import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { FieldRelation, TFieldRelation } from '../types/fieldRelation.type';

// REDUX
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedFieldRelation: FieldRelation.createEmpty(),
  allFieldRelations: [],
  limitations: {},
};

// Slice
const slice = createSlice({
  name: 'fieldRelations',
  initialState,
  reducers: {
    fetchFieldRelationsRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchFieldRelationsSuccess: (state: any, { payload }) => {
      if (state.selectedFieldRelation.id > 0) {
        state.selectedFieldRelation =
          payload.find(
            (relation: any) => relation.id === state.selectedFieldRelation.id,
          ) || FieldRelation.createEmpty();
      }
      state.allFieldRelations = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLimitationsFieldRelationsSuccess: (state: any, { payload }) => {
      state.limitations = payload;
      finishedLoadingSuccess(state);
    },
    fetchFieldRelationsFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createFieldRelationRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createFieldRelationSuccess: (state: any, { payload }) => {
      state.allFieldRelations.push(payload);
      state.selectedFieldRelation = payload;
      finishedLoadingSuccess(state);
    },
    createFieldRelationFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentFieldRelation: (state: any, { payload }) => {
      state.selectedFieldRelation = payload;
    },
    deleteFieldRelationRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteFieldRelationSuccess: (state: any, payload: any) => {
      state.allFieldRelations = state.allFieldRelations.filter(
        (fieldRelation: TFieldRelation) =>
          fieldRelation.id !== payload.payload.id,
      );
      state.selectedFieldRelation = FieldRelation.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteFieldRelationFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateFieldRelationRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateFieldRelationSuccess: (state: any, { payload }) => {
      const index = state.allFieldRelations.findIndex(
        (fr: TFieldRelation) => fr.id === payload.id,
      );
      state.allFieldRelations[index] = payload;
      finishedLoadingSuccess(state);
    },
    updateFieldRelationFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const fieldRelationsLoading = (state: any) =>
  state.fieldRelations.loading;
export const fieldRelationsSelector = (state: any) =>
  state.fieldRelations.allFieldRelations as TFieldRelation[];
export const fieldRelationSelectorCreateNew = () => () => {
  return FieldRelation.createEmpty();
};
export const selectedFieldRelationSelector = (state: any) => {
  return state.fieldRelations.selectedFieldRelation;
};
export const fetchLimitationsFieldRelationsSelector = (state: any) =>
  state.fieldRelations.limitations;

// Actions
export const {
  fetchFieldRelationsRequest,
  fetchFieldRelationsSuccess,
  fetchLimitationsFieldRelationsSuccess,
  fetchFieldRelationsFailure,
  createFieldRelationRequest,
  createFieldRelationSuccess,
  createFieldRelationFailure,
  setCurrentFieldRelation,
  deleteFieldRelationRequest,
  deleteFieldRelationSuccess,
  deleteFieldRelationFailure,
  updateFieldRelationRequest,
  updateFieldRelationSuccess,
  updateFieldRelationFailure,
} = slice.actions;

export const fetchFieldRelations = () => async (dispatch: any) => {
  try {
    dispatch(fetchFieldRelationsRequest());
    const fieldRelations = await api.get({ endpoint: `/field-relations` });
    dispatch(fetchFieldRelationsSuccess(fieldRelations));
  } catch (e) {
    dispatch(fetchFieldRelationsFailure());
    return console.error(e);
  }
};

export const fetchLimitationsFieldRelations = () => async (dispatch: any) => {
  try {
    dispatch(fetchFieldRelationsRequest());
    const limitationsFieldRelations = await api.get({
      endpoint: `/field-relations/limitations`,
    });
    dispatch(fetchLimitationsFieldRelationsSuccess(limitationsFieldRelations));
  } catch (e) {
    dispatch(fetchFieldRelationsFailure());
    return console.error(e);
  }
};

export const createFieldRelation =
  (fieldRelation: TFieldRelation) => async (dispatch: any) => {
    try {
      dispatch(createFieldRelationRequest());
      const { id: _, ...fieldRelationBody } = fieldRelation;
      const response = await api.post({
        endpoint: `/field-relations`,
        data: fieldRelationBody,
      });
      dispatch(createFieldRelationSuccess(response));
      dispatch(fetchFieldRelations());
    } catch (e: any) {
      dispatch(createFieldRelationFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const createEmptyFieldRelation = () => async (dispatch: any) => {
  try {
    dispatch(createFieldRelationRequest());
    const response = FieldRelation.createEmpty();
    dispatch(createFieldRelationSuccess(response));
  } catch (e: any) {
    dispatch(createFieldRelationFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedFieldRelation =
  (fieldRelation: TFieldRelation) => async (dispatch: any) => {
    dispatch(setCurrentFieldRelation(fieldRelation));
  };

export const copyFieldRelation =
  (fieldRelation: TFieldRelation) => async (dispatch: any) => {
    try {
      dispatch(createFieldRelationRequest());
      const response = {
        ...fieldRelation,
        id: 0,
        description: '',
        documentation: '',
        reservationTemplates: [],
      };
      dispatch(createFieldRelationSuccess(response));
    } catch (e) {
      dispatch(createFieldRelationFailure());
      return console.error(e);
    }
  };

export const deleteFieldRelation =
  (fieldRelation: TFieldRelation) => async (dispatch: any) => {
    try {
      dispatch(deleteFieldRelationRequest());
      await api.delete({
        endpoint: `/field-relations/${fieldRelation.id}`,
      });
      dispatch(deleteFieldRelationSuccess(fieldRelation));
    } catch (e: any) {
      dispatch(deleteFieldRelationFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };

export const updateFieldRelation =
  (fieldRelation: TFieldRelation) => async (dispatch: any) => {
    try {
      dispatch(updateFieldRelationRequest());
      const { ...FieldRelationBody } = fieldRelation;
      const response = await api.patch({
        endpoint: `/field-relations/${fieldRelation.id}`,
        data: { ...FieldRelationBody },
      });
      dispatch(updateFieldRelationSuccess(response));
    } catch (e: any) {
      dispatch(updateFieldRelationFailure());
      return console.error(e.response.data ? e.response.data : e.response);
    }
  };
