// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Button, Input, Popconfirm, Select, Space, Table, Form } from "antd";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";

// TYPES
import { AltDesignations, TAltDesignations, TLanguage } from "../../../types/languages.type";

// COMPONENTS
import { TEToolTip } from "../../TEToolTip";

const language: any = intl.messages;
const onBlur = () => {};
const onFocus = () => {};

type Props = {
  altDesignations: TAltDesignations[];
  onChangeHandler: any;
  onChange: any;
  limitationName: number;
  limitationDescription: number;
  languages?: Array<TLanguage>;
}

export const LanguageTable = ({altDesignations, onChangeHandler, limitationName, limitationDescription, languages, onChange}: Props) => {
  
  const onAddRow = (lang: string) => {
    const newAltDesignations: TAltDesignations[] = [...altDesignations];
    newAltDesignations.push(AltDesignations.create(lang));
    onChange('altDesignations', newAltDesignations);
  }

  const onDeleteRow = (row: TAltDesignations) => {
    onChange('altDesignations', altDesignations.filter((e: TAltDesignations) => e.language !== row.language));
  }

  const columns = [
    {
      title: (
        <TEToolTip helpText=''>
          <span className='small-heading-text'>{language.language}</span>
        </TEToolTip>
      ),
      dataIndex: 'language',
      key: 'language',
      width: 120,
      render: (language: string) => (
        <span>{languages?.find((e: TLanguage) => e.id === language)?.name}</span>
      ),
    },
    {
      title: (
        <TEToolTip helpText={`${language.help_language_alt_name} (Maximum size of ${limitationName} bytes)`}>
          <span className='small-heading-text'>{language.alt_name}</span>
        </TEToolTip>
      ),
      dataIndex: 'name',
      key: 'name',
      width: 128,
      render: (name: string, record: any) => (
        <Input
          size='small'
          value={name}
          onChange={(event: any) =>
            onChangeHandler(record.altDesignation, 'name', event.target.value, limitationName)
          }
        />
      ),
    },
    {
      title: (
        <TEToolTip helpText={`${language.help_language_alt_description} (Maximum size of ${limitationDescription} bytes)`}>
          <span className='small-heading-text'>{language.alt_description}</span>
        </TEToolTip>
      ),
      dataIndex: 'description',
      key: 'description',
      width: 260,
      render: (description: string, record: any) => (
        <Input
          size='small'
          value={description}
          onChange={(event: any) =>
            onChangeHandler(record.altDesignation, 'description', event.target.value, limitationDescription)
          }
        />
      ),
    },
    {
      key: 'action',
      width: 20,
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={language.sure_to_delete}
            onConfirm={() => onDeleteRow(record.altDesignation)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const unusedLanguages = () => {
    return languages?.filter((e: TLanguage) => !(altDesignations?.map(e => e.language)).includes(e.id));
  }
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      <Form.Item label='Add Translation' style={{ marginBottom: 10 }}>
        <Select
          showSearch
          onFocus={onFocus}
          onBlur={onBlur}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size='small'
          onChange={onAddRow}
          placeholder={language.add_type}
          value='-'
        >
          {unusedLanguages()?.map((lang: TLanguage) => (
            <Select.Option key={lang.id} value={lang.id}>
              {lang.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Table
        size='small'
        dataSource={altDesignations?.map(
          (altDesignation: TAltDesignations) => ({
            key: altDesignation.language,
            ...altDesignation,
            altDesignation,
          }),
        )}
        columns={columns}
        style={{ marginBottom: '16px' }}
      />
    </div>
  );
}