import { ChangeEvent } from 'react';

// i18N
import intl from '../../i18n/intl';

// STYLES
import { Input, Collapse, Checkbox, Form } from 'antd';

// COMPONENTS
import AvailabilityTypesTable from './AvailabilityTypesTable';
import { TEItem } from '../TEToolTip';

// TYPES
import {
  TAvailabilityRelationType,
  TAvailabilityTypesItem,
} from '../../types/availabilityrelation.type';

type Props = {
  availabilityType: TAvailabilityRelationType;
  onChange: Function;
  limitations?: any;
};

type PropsTypes = {
  availabilityTypeTypes: TAvailabilityTypesItem[];
  onChange: Function;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const AvailabilityTypesGeneralSettings = ({
  availabilityType,
  onChange,
  limitations,
}: Props) => {
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  const onChangeCheckboxHandler = (event: any, property: string) => {
    onChange(property, event.target.checked);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Form.Item
          label={language.name}
          tooltip={`(Maximum size of ${limitations.name} bytes)`}
        >
          <Input
            size='small'
            defaultValue={availabilityType.name}
            value={availabilityType.name}
            onChange={(event: any) => {
              onChangeInputHandler(event, 'name', limitations.name);
            }}
          />
        </Form.Item>
        <Form.Item
          label={language.ext_id}
          tooltip={`${language.help_ext_id} (Maximum size of ${limitations.extId} bytes)`}
        >
          <Input
            size='small'
            defaultValue={availabilityType.extId}
            value={availabilityType.extId}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'extId', limitations.extId)
            }
          />
        </Form.Item>
        <p className='form-item-text' style={{ marginBottom: '1em' }}>
          {language.id}: {availabilityType.id}
        </p>
        <Form.Item
          label={language.description}
          tooltip={`(Maximum size of ${limitations.description} bytes)`}
        >
          <Input.TextArea
            size='small'
            rows={5}
            defaultValue={availabilityType.description}
            value={availabilityType.description}
            onChange={(value: any) =>
              onChangeInputHandler(
                value,
                'description',
                limitations.description,
              )
            }
          />
        </Form.Item>
        <TEItem
          helpText={language.help_constitutes_obstacle_availability_types}
        >
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'constitutesObstacles')
            }
            checked={availabilityType.constitutesObstacles}
          >
            {language.constitutes_obstacle}
          </Checkbox>
        </TEItem>
        <TEItem helpText={language.help_occupied_by_default_availability_types}>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'occupiedByDefault')
            }
            checked={availabilityType.occupiedByDefault}
          >
            {language.occupied_by_default}
          </Checkbox>
        </TEItem>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const AvailabilityTypesTypes = ({
  availabilityTypeTypes,
  onChange,
}: PropsTypes) => {
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.types} key='2'>
        <AvailabilityTypesTable
          availabilityTypes={availabilityTypeTypes}
          onTypesChanged={onChange}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
