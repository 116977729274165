import { THistory } from './dateHistory.type';

export enum EPeriodAccess {
  PUBLIC = 'PUBLIC',
  LIMITED = 'LIMITED',
  HIDDEN = 'HIDDEN',
}

export type TPeriod = {
  name: string;
  shortName: string;
  id: number;
  description: string;
  userAccess: EPeriodAccess;
  intervals: Array<TPeriodInterval>;
  childPeriods: Array<number>;
  history: Array<THistory>;
};

export class Period {
  static createEmpty = (): TPeriod => ({
    name: '',
    shortName: '',
    id: 0,
    description: '',
    userAccess: EPeriodAccess.PUBLIC,
    intervals: [],
    childPeriods: [],
    history: [],
  });
}

export enum ETimeIntervals {
  DATE = 'DATE',
  YEAR = 'YEAR',
  WEEK = 'WEEK',
  WEEK_NUMBER = 'WEEK_NUMBER',
  MONTH = 'MONTH',
  DAY = 'DAY',
  WEEKDAY = 'WEEKDAY',
  TIME = 'TIME',
  NONE = '-',
}

export type TIntervalRecord = {
  type: ETimeIntervals;
  start: number;
  end: number;
  index: number;
};

/* Interval types */
export type TPeriodInterval = {
  type: ETimeIntervals;
  start: number;
  end: number;
};

const getDefaultStart = (type: ETimeIntervals): number => {
  if (type === ETimeIntervals.WEEK) {
    return 1;
  } else if (type === ETimeIntervals.YEAR) {
    return 2020;
  } else if (type === ETimeIntervals.MONTH) {
    return 1;
  } else if (type === ETimeIntervals.DATE) {
    return parseInt((Date.now() / 1000).toFixed());
  } else if (type === ETimeIntervals.DAY) {
    return 1;
  } else if (type === ETimeIntervals.WEEKDAY) {
    return 1;
  } else if (type === ETimeIntervals.TIME) {
    return 0;
  } else if (type === ETimeIntervals.WEEK_NUMBER) {
    return 2101;
  } else {
    return 0;
  }
};

const getDefaultEnd = (type: ETimeIntervals): number => {
  if (type === ETimeIntervals.WEEK) {
    return 52;
  } else if (type === ETimeIntervals.YEAR) {
    return 2029;
  } else if (type === ETimeIntervals.MONTH) {
    return 12;
  } else if (type === ETimeIntervals.DATE) {
    return parseInt((Date.now() / 1000).toFixed()) + 31556926;
  } else if (type === ETimeIntervals.DAY) {
    return 31;
  } else if (type === ETimeIntervals.WEEKDAY) {
    return 7;
  } else if (type === ETimeIntervals.TIME) {
    return 86400;
  } else if (type === ETimeIntervals.WEEK_NUMBER) {
    return 2152;
  } else {
    return 0;
  }
};

export class PeriodInterval {
  static create = (intervalType: ETimeIntervals): TPeriodInterval => ({
    type: intervalType,
    start: getDefaultStart(intervalType),
    end: getDefaultEnd(intervalType),
  });
}
