import { useSelector } from 'react-redux';

// REDUX
import { typesSelector } from '../../../slices/type.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Table, Space, Button, Popconfirm, Form, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

// TYPES
import { TMemberTypesItem, MemberTypesItem } from '../../../types/member.type';
import { TType } from '../../../types/type.type';

// UTILS
import { getNameOnType } from '../../../utils/getNameOn';

// COMPONENTS
import { TEToolTip } from '../../TEToolTip';

const language: any = intl.messages;

type Props = {
  memberTypes: any;
  onTypesChanged: any;
};

const memberLevels = [
  { value: 0, label: `${language.member_level} 1` },
  { value: 1, label: `${language.member_level} 2` },
  { value: 2, label: `${language.member_level} 3` },
  { value: 3, label: `${language.member_level} 4` },
  { value: 4, label: `${language.member_level} 5` },
  { value: 5, label: `${language.member_level} 6` },
  { value: 6, label: `${language.member_level} 7` },
  { value: 7, label: `${language.member_level} 8` },
  { value: 8, label: `${language.member_level} 9` },
  { value: 9, label: `${language.member_level} 10` },
  { value: 10, label: `${language.member_level} 11` },
  { value: 11, label: `${language.member_level} 12` },
];

const MemberRelationTable = ({ memberTypes, onTypesChanged }: Props) => {
  // All types
  const types: TType[] = useSelector(typesSelector);

  // EVENT HANDLERS
  const onChangeSelectHandler = (
    typeMember: any,
    property: string,
    value: any,
  ) => {
    const index = memberTypes.indexOf(typeMember);
    const updatedType = Object.assign({}, typeMember);
    updatedType[property] = value;
    const newTypes: TMemberTypesItem[] = [...memberTypes];
    newTypes.splice(index, 1, updatedType);
    onTypesChanged('memberTypes', newTypes);
  };

  const addType = (typeId: number | string | undefined) => {
    const newMemberTypeTypes: TMemberTypesItem[] = [...memberTypes];
    newMemberTypeTypes.push(
      MemberTypesItem.create(typeId, newMemberTypeTypes.length),
    );
    onTypesChanged('memberTypes', newMemberTypeTypes);
  };

  const onDeleteHandler = (typeId: number) => {
    onTypesChanged(
      'memberTypes',
      memberTypes.filter((type: any) => type.type !== typeId),
    );
  };

  const onBlur = () => {};
  const onFocus = () => {};

  const columns = [
    {
      title: (
        <TEToolTip helpText={language.help_type_member_types}>
          <span className='small-heading-text'>{language.type}</span>
        </TEToolTip>
      ),
      dataIndex: 'type',
      width: 260,
      key: 'type',
      render: (type: string) => <span>{getNameOnType(types, type)}</span>,
    },
    {
      title: (
        <TEToolTip helpText={language.help_member_level_member_types}>
          <span className='small-heading-text'>{language.member_level}</span>
        </TEToolTip>
      ),
      dataIndex: 'memberLevel',
      key: 'memberLevel',
      render: (memberLevel: any, record: any) => (
        <Select
          style={{ width: 200 }}
          size='small'
          value={memberLevel}
          onChange={(value: any) =>
            onChangeSelectHandler(record.memberType, 'memberLevel', value)
          }
        >
          {memberLevels.map((memberLevel: any) => (
            <Select.Option key={memberLevel.label} value={memberLevel.value}>
              {memberLevel.label}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: <span className='small-heading-text'>{language.action}</span>,
      key: 'action',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={language.sure_to_delete}
            onConfirm={() => onDeleteHandler(record.type)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // To being able to exclude the already choosen types
  const typeIds = memberTypes?.map((memberType: any) => memberType.type);
  let typesToChooseFrom = [];
  if (typeIds === undefined) {
    typesToChooseFrom = types;
  } else {
    typesToChooseFrom = types.filter(
      (type: TType) => typeIds?.indexOf(type.id) === -1,
    );
  }

  return (
    <>
      <Form.Item label={`${language.add_type}`} style={{ marginBottom: 10 }}>
        <Select
          showSearch
          onFocus={onFocus}
          onBlur={onBlur}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size='small'
          onChange={addType}
          placeholder={language.add_type}
          value='-'
        >
          {typesToChooseFrom.map((type) => (
            <Select.Option key={type.name} value={type.id}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Table
        size='small'
        dataSource={memberTypes?.map((memberType: any) => ({
          key: memberType.type,
          ...memberType,
          memberType,
        }))}
        columns={columns}
      />
    </>
  );
};

export default MemberRelationTable;
