export type TAuthenticationToken = {
  id: number;
  clientId: string;
  password: string;
  confirmPassword?: string;
  isActive: boolean;
  useToken: boolean;
  lastUsed: number | null;
};

export class AuthenticationToken {
  static create = (): TAuthenticationToken => ({
    id: 0,
    clientId: '',
    password: '',
    isActive: true,
    useToken: false,
    lastUsed: null,
  });
}

export class AuthenticationTokenCreateEmpty {
  static createEmpty = (): TAuthenticationToken => ({
    id: 0,
    clientId: '',
    password: '',
    isActive: true,
    useToken: false,
    lastUsed: null,
  });
}
