// i18N
import intl from '../../../i18n/intl';

// STYLES
import './index.scss';
import { Input, Button, Table, Space, Popconfirm } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

// COMPONENTS
import { TEToolTip } from '../../TEToolTip';

const language: any = intl.messages;

type Props = {
  dataSource: [string, string][];
  onChange: any;
  property: string;
};

export const AttributesTable = ({ dataSource, onChange, property }: Props) => {
  // EVENT HANDLERS
  const onChangeHandler = (data: any, indexWithinArray: number, value: any) => {
    const index = dataSource.indexOf(data.data);
    const updatedData = [...data.data];
    updatedData[indexWithinArray] = value;
    const newData: any = [...dataSource];
    newData.splice(index, 1, updatedData);
    onChange(newData, property);
  };

  const onAddRow = () => {
    const newDataSource = [...dataSource];
    newDataSource.push(['', '']);
    onChange(newDataSource, property);
  };

  const onDeleteRow = (dataKey: any) => {
    onChange(
      dataSource.filter((data: any) => data !== dataKey),
      property,
    );
  };

  // Columns
  const columns = [
    {
      title: (
        <TEToolTip helpText=''>
          <span className='small-heading-text'>{language.attributes}</span>
        </TEToolTip>
      ),
      dataIndex: 'attributes',
      key: 'attributes',
      width: 260,
      render: (record: any, attributes: any) => (
        <Input
          size='small'
          value={attributes.attributes}
          onChange={(event: any) =>
            onChangeHandler(attributes, 0, event.target.value)
          }
        />
      ),
    },
    {
      title: (
        <TEToolTip helpText=''>
          <span className='small-heading-text'>{language.values}</span>
        </TEToolTip>
      ),
      dataIndex: 'values',
      key: 'values',
      width: 260,
      render: (record: any, values: any) => (
        <Input
          size='small'
          value={values[1]}
          onChange={(event: any) =>
            onChangeHandler(values, 1, event.target.value)
          }
        />
      ),
    },
    {
      title: <span className='small-heading-text'>{language.action}</span>,
      key: 'action',
      render: (record: any) => (
        <Space size='middle'>
          <Popconfirm
            title={language.sure_to_delete}
            onConfirm={() => onDeleteRow(record.data)}
            onCancel={() => console.log('should do nothing, no delete')}
            okText={language.yes}
            cancelText={language.no}
          >
            <Button
              size='small'
              danger
              ghost
              type='default'
              className='types-list-action-button-delete'
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '1200px' }}>
      <Button
        type='default'
        size='small'
        icon={<PlusOutlined />}
        onClick={onAddRow}
        style={{ marginBottom: '16px', width: '120px' }}
      >
        {language.add_row}
      </Button>
      <Table
        size='small'
        dataSource={dataSource?.map(
          (data: [string, string], index: number) => ({
            key: index,
            attributes: data[0],
            ...data,
            data,
          }),
        )}
        columns={columns}
        style={{ marginBottom: '16px' }}
      />
    </div>
  );
};
