import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Form } from 'antd';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import { SupportInfo } from '../../../components/SystemPrefs/SupportInfo';
import {
  EmailSettings,
  EmailTemplates,
} from '../../../components/SystemPrefs/EmailPreferences';
import { SystemPreferences } from '../../../components/SystemPrefs/SystemPrefsMisc';
import { PasswordRules } from '../../../components/SystemPrefs/PasswordRules';
import { UserPreferences } from '../../../components/UserPreferences';
import { TimeZones } from '../../../components/TimeZonesComponent';

// TYPES
import {
  TPasswordRules,
  TSystemPreferencesMisc,
  TUserPreferences,
} from '../../../types/systempreferences.type';
import { TTimeZone } from '../../../types/timeZone.type';

// ACTIONS
import {
  fetchLimitationsForSupportInfo,
  limitationsSupportInfoSelector,
} from '../../../slices/supportInfo.slice';
import {
  limitationsEmailPreferencesSelector,
  variablesEmailSelector,
  fetchLimitationsForEmailPreferences,
  fetchVariablesForEmails,
} from '../../../slices/emailPreferences.slice';
import {
  fetchLimitationsForSystemPreferences,
  fetchSystemPreferences,
  limitationsSystemPreferencesSelector,
  systemPreferencesSelector,
} from '../../../slices/systemPreferences.slice';
import {
  fetchLimitationsForPasswordRules,
  fetchPasswordRules,
  limitationsPasswordRulesSelector,
  passwordRulesSelector,
} from '../../../slices/passwordRules.slice';
import {
  fetchLimitationsUserPreferences,
  fetchUserPreferences,
  limitationsUserPreferencesSelector,
  userPreferencesSelector,
} from '../../../slices/userPreferences.slice';
import {
  fetchLimitationsForTimeZones,
  fetchTimeZones,
  limitationsTimeZonesSelector,
  timeZonesSelector,
} from '../../../slices/timeZone.slice';

type Props = {
  customerSignature: any;
};

const language: any = intl.messages;

const SystemPreferencesPage = ({ customerSignature }: Props) => {
  const dispatch = useDispatch();
  const supportInfoLimitations = useSelector(limitationsSupportInfoSelector);
  const emailVariables = useSelector(variablesEmailSelector);
  const emailPreferencesLimitations = useSelector(
    limitationsEmailPreferencesSelector,
  );
  const systemPreferences: TSystemPreferencesMisc = useSelector(
    systemPreferencesSelector,
  );
  const systemPreferencesLimitations = useSelector(
    limitationsSystemPreferencesSelector,
  );
  const passwordRules: TPasswordRules = useSelector(passwordRulesSelector);
  const passwordRulesLimitations = useSelector(
    limitationsPasswordRulesSelector,
  );
  const userPreferences: TUserPreferences = useSelector(
    userPreferencesSelector,
  );
  const userPreferencesLimitations = useSelector(
    limitationsUserPreferencesSelector,
  );
  const timeZones: TTimeZone[] = useSelector(timeZonesSelector);
  const timeZonesLimitations = useSelector(limitationsTimeZonesSelector);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/system`,
          label: `${language.system}`,
        },
        {
          path: `/system/system-preferences`,
          label: `${language.system_preferences}`,
        },
      ]),
    );
    dispatch(fetchLimitationsForSupportInfo());
    dispatch(fetchLimitationsForEmailPreferences());
    dispatch(fetchVariablesForEmails());
    dispatch(fetchSystemPreferences());
    dispatch(fetchLimitationsForSystemPreferences());
    dispatch(fetchPasswordRules());
    dispatch(fetchLimitationsForPasswordRules());
    dispatch(fetchUserPreferences());
    dispatch(fetchLimitationsUserPreferences());
    dispatch(fetchTimeZones());
    dispatch(fetchLimitationsForTimeZones());
  }, [customerSignature, dispatch]);

  return (
    <div className='inner-content--wrapper'>
      <SectionHeader
        listChoiceHeading={language.system_preferences}
        displayProp
        history={false}
      />
      <Form>
        <SupportInfo limitations={supportInfoLimitations} />
        <EmailSettings />
        <EmailTemplates
          emailVariables={emailVariables}
          limitations={emailPreferencesLimitations}
        />
        <SystemPreferences
          systemPreferences={systemPreferences}
          limitations={systemPreferencesLimitations}
        />
        <PasswordRules
          passwordRules={passwordRules}
          limitations={passwordRulesLimitations}
        />
        <UserPreferences
          userPreferences={userPreferences}
          limitations={userPreferencesLimitations}
        />
        <TimeZones timeZones={timeZones} limitations={timeZonesLimitations} />
      </Form>
    </div>
  );
};

export default SystemPreferencesPage;
