import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// REDUX
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  allLanguages: [],
};

// Slice
const slice = createSlice({
  name: 'languages',
  initialState,
  reducers: {
    fetchLanguagesRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchLanguagesSuccess: (state: any, { payload }) => {
      state.allLanguages = payload.results;
      finishedLoadingSuccess(state);
    },
    fetchLanguagesFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const languagesLoading = (state: any) => state.languages.loading;
export const languagesSelector = (state: any) => state.languages.allLanguages;

// Actions
export const {
  fetchLanguagesRequest,
  fetchLanguagesSuccess,
  fetchLanguagesFailure,
} = slice.actions;

export const fetchLanguages = () => async (dispatch: any) => {
  try {
    dispatch(fetchLanguagesRequest());
    const languages = await api.get({ endpoint: `/languages` });
    dispatch(fetchLanguagesSuccess(languages));
  } catch (e) {
    dispatch(fetchLanguagesFailure());
    return console.error(e);
  }
};
