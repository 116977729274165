import { createSlice } from '@reduxjs/toolkit';
import { TBreadcrumb } from '../types/breadcrumb.type';
import { IUIState, IState } from '../types/state.interface';
import { configService } from '../services/config.service';
import api from '../services/api.service';

export const initialState = {
  breadcrumbs: [],
  tableViews: {},
};

type ViewColumns = {
  [column: string]: any;
};

const userId = (state: IState) => state.auth.tokenData.ref;
const orgId = (state: IState) => state.auth.tokenData.cus;

const updateViewReducer = (
  state: IUIState,
  { payload }: { payload: { datasourceId: string; columns?: ViewColumns } },
) => {
  state.tableViews = {
    ...state.tableViews,
    [payload.datasourceId]: payload.columns,
  };
};

// Slice definition
const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setBreadcrumbs: (
      state: IUIState,
      { payload }: { payload: TBreadcrumb[] },
    ) => {
      state.breadcrumbs = payload;
    },
    getViewSuccess: (
      state: IUIState,
      { payload }: { payload: { columns: ViewColumns; datasourceId: string } },
    ) => {
      updateViewReducer(state, {
        payload,
      });
    },
    initView: (
      state: IUIState,
      { payload: datasourceId }: { payload: string },
    ) => {
      updateViewReducer(state, { payload: { datasourceId } });
    },
    updateViewRequest: updateViewReducer,
  },
});

// The reducer
export default uiSlice.reducer;

// Selectors
export const breadcrumbsSelector = (state: IState) => state.ui.breadcrumbs;

export const selectVisibleColsForDatasourceId = (
  state: IState,
  datasourceId: string,
) => state.ui.tableViews[datasourceId] || {};

// Three actions generated from the slice
export const { setBreadcrumbs, initView, getViewSuccess, updateViewRequest } =
  uiSlice.actions;

// ACTIONS for TE Views
export const getView =
  (datasourceId: string) => async (dispatch: any, getState: () => IState) => {
    try {
      const result = (await api.get({
        endpoint: `${configService.VIEWS_URL}/tables/${
          configService.CLIENT_ID
        }/${datasourceId}/${orgId(getState())}/${userId(getState())}`,
        absoluteUrl: true,
        requiresAuth: false,
      })) as { table: { columns: ViewColumns; datasourceId: string } };
      dispatch(
        getViewSuccess({ columns: result.table?.columns, datasourceId }),
      );
    } catch (e) {
      console.error(e);
    }
  };

export const updateView =
  (datasourceId: string, columns: ViewColumns) =>
  async (dispatch: any, getState: () => IState) => {
    try {
      dispatch(updateViewRequest({ datasourceId, columns }));
      await api.put({
        endpoint: `${configService.VIEWS_URL}/tables/${
          configService.CLIENT_ID
        }/${datasourceId}/${orgId(getState())}/${userId(getState())}`,
        absoluteUrl: true,
        data: { columns, datasourceId, pageSize: 100 },
        requiresAuth: false,
      });
    } catch (e) {
      console.error(e);
    }
  };
