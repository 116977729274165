import { ChangeEvent } from 'react';

// i18N
import intl from '../../i18n/intl';

// STYLES
import { Collapse, Form, Input, Select } from 'antd';

// COMPONENTS
import MemberRelationTable from './MemberRelTable';
import { TEItem } from '../TEToolTip';

// TYPES
import {
  EReservationKindMemberType,
  TMemberType,
  TMemberTypesItem,
} from '../../types/member.type';

type PropsGeneral = {
  memberType: TMemberType;
  onChange: Function;
  limitations?: any;
};

type PropsTypes = {
  memberTypesItems: TMemberTypesItem[];
  onChange: Function;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

const onBlur = () => {};
const onFocus = () => {};

export const MemberRelationsGeneralSettings = ({
  memberType,
  onChange,
  limitations,
}: PropsGeneral) => {
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  const onChangeSelectHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
  ) => {
    onChange(property, event);
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Form.Item
          label={`${language.name}`}
          tooltip={`(Maximum size of ${limitations.name} bytes)`}
        >
          <Input
            size='small'
            defaultValue={memberType.name}
            value={memberType.name}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'name', limitations.name)
            }
          />
        </Form.Item>
        <p className='form-item-text' style={{ marginBottom: '1em' }}>
          {language.id}: {memberType.id}
        </p>
        <Form.Item label={`${language.property}`}>
          <Select
            size='small'
            onChange={(value: any) =>
              onChangeSelectHandler(value, 'reservationKind')
            }
            onFocus={onFocus}
            onBlur={onBlur}
            value={memberType.reservationKind}
          >
            {Object.entries(EReservationKindMemberType).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.description}
          tooltip={`(Maximum size of ${limitations.description} bytes)`}
        >
          <Input.TextArea
            size='small'
            rows={5}
            defaultValue={memberType.description}
            value={memberType.description}
            onChange={(value: any) =>
              onChangeInputHandler(
                value,
                'description',
                limitations.description,
              )
            }
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const MemberRelationsTypes = ({
  memberTypesItems,
  onChange,
}: PropsTypes) => {
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.types} key='2'>
        <MemberRelationTable
          memberTypes={memberTypesItems}
          onTypesChanged={onChange}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
