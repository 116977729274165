import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from '../../../slices/ui.slice';
import _ from 'lodash';

// i18N
import intl from '../../../i18n/intl';

// STYLES
import { Collapse, Divider, Form, Menu } from 'antd';

// COMPONENTS
import SectionHeader from '../../../components/SectionHeader';
import ListView from '../../../components/List';
import TreeListView from '../../../components/List/TreeListView';
import { ObjectsMassChangesView } from '../../../components/ObjectsMassChanges';

// ACTIONS
import {
  fetchObjects,
  objectsSelector,
  createObject,
  selectedObjectsSelector,
  setSelectedObjects,
  setListObjects,
  updateObjects,
  deleteObjects,
  listedObjectSelector,
} from '../../../slices/object.slice';
import {
  fetchTypes,
  setSelectedType,
  typesSelector,
} from '../../../slices/type.slice';
import { fetchFields, fieldsSelector } from '../../../slices/field.slice';

// TYPES
import { TTEObject } from '../../../types/object.type';
import { TTreeListData, TType } from '../../../types/type.type';
import { TField, EFieldType } from '../../../types/field.type';

// UTILS
import { isSavedDisabledObjects } from '../../../utils/isSavedDisabled';
import { timeConverter } from '../../../utils/timeConverter';
import {
  fetchOrganizations,
  organizationsSelector,
} from '../../../slices/organization.slice';
import { ListSearch } from '../../../components/ListSearch';

type Props = {
  limitations: any;
  customerSignature: string;
};

const language: any = intl.messages;

// Function for creating the type hierarchy tree
const createTypeTree = (current: any, types: TType[]) => {
  const node: TTreeListData = {
    title: current?.name,
    key: String(current?.id),
    children: [],
  };
  if (current.subTypes) {
    current.subTypes.forEach(() => {
      node.children.push(createTypeTree(types.shift(), types));
    });
  }
  return node;
};

/* ---------------------------------------------------------------------- */

const ObjectManagerPage = ({ limitations, customerSignature }: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // States for setting errors
  const [extIdError, setExtIdError] = useState(false);

  // Getting all objects
  const objects: TTEObject[] = useSelector(objectsSelector);
  const selectedObjects: TTEObject[] = useSelector(selectedObjectsSelector);
  const listedObjects: TTEObject[] = useSelector(listedObjectSelector);

  // Getting all types
  const types = useSelector(typesSelector);
  const treeTypes = [...types] || [];
  let treeListData: TTreeListData[] = [{ title: '', key: '', children: [] }];
  if (treeTypes.length > 0) {
    treeListData = [createTypeTree(treeTypes.shift(), treeTypes)];
  }

  // 1. Getting the right objects to show will be done by a request to the server: findObjects
  // - where we send in search criterias 'selectedTypeSearch' and 'selectedCategoryValueSearch'
  const [selectedTypeSearch, setSelectedTypeSearch] = useState<number>();
  const [selectedCategoryValueSearch, setSelectedCategoryValueSearch] =
    useState<any>();
  const [selectedOrganizationValueSearch, setSelectedOrganizationValueSearch] =
    useState<any>();

  // 2. @todo: Request to the server: findObjects:
  useEffect(() => {
    // dispatch(findObjects);
    dispatch(fetchTypes('sort', 'parentName'));
    dispatch(fetchFields());
    dispatch(fetchOrganizations());
  }, [
    selectedTypeSearch,
    selectedCategoryValueSearch,
    selectedOrganizationValueSearch,
    dispatch,
  ]);
  // @todo: Behöver använda  dispatch(setListObjects; endast en gång som sätter antingen baserat på vald typ eller på vald category när hämtat det från API't

  const [selectedObjectsByType, setSelectedObjectsByType] =
    useState<any>(objects);

  // Get object based on which type that is selected
  const onTypeSelected = (selectedValue: string) => {
    setSelectedCategoryValueSearch(undefined);
    setSelectedOrganizationValueSearch(undefined);
    setSelectedTypeSearch(parseInt(selectedValue));
    const currentType = types.find(
      (type) => type.id === parseInt(selectedValue),
    );
    if (currentType) {
      dispatch(setSelectedType(currentType));
      setExtIdError(false);
    }
    if (currentType?.extId === 'root') {
      setSelectedObjectsByType(objects);
      // @todo: When we have the API for objects, skicka ett anrop till servern 'findObjects' som ger objekten för alla undertyper!
    } else {
      setSelectedObjectsByType(
        objects.filter((object: any) =>
          object.types.includes(parseInt(selectedValue)),
        ),
      );
      dispatch(
        setListObjects(
          objects.filter((object: any) =>
            object.types.includes(parseInt(selectedValue)),
          ),
        ),
      );
    }
  };

  // Filter out only the fields that exist on the selectedObjectsByType
  const fieldIds = _.flatten(
    selectedObjectsByType?.map((object: any) =>
      object.teFields?.map((teField: any) => teField.fieldId),
    ),
  );

  // Field info
  const fields: TField[] = useSelector(fieldsSelector);
  const columnFields = fields.filter(
    (field: any) => fieldIds.indexOf(field.id) !== -1,
  );

  // Categories available for the choosen type
  const fieldsWithCategoryType = columnFields.filter(
    (field: any) => field.fieldType === EFieldType.CATEGORY,
  );

  // Creates the treeListData for the category view:
  const dataSourceCategories = fieldsWithCategoryType.map((field: any) => ({
    title: field.name,
    key: String(field.id),
    children: field.categories.map((category: any) => ({
      title: <span className='list-item-text'>{category}</span>,
      key: `${field.id},${category}`,
      children: [],
    })),
  }));
  const treeListCategories: any = {
    title: '',
    key: '',
    children: dataSourceCategories,
  };

  // Get selectedCategoryValueSearch to send to 'findObjects()' based on which category that is selected
  const onCategorySelected = (selectedKey: string) => {
    const [id, value] = selectedKey.split(',');
    setSelectedCategoryValueSearch({ id: parseInt(id), value });
  };

  // Organizations available
  const organizations = useSelector(organizationsSelector);
  // Creates the treeListData for the organization view:
  const dataSourceOrganizations = organizations?.map((org: any) => ({
    title: org.name,
    key: String(org.id),
    children: org.subOrganizations?.map((subOrg: any) => ({
      title: <span className='list-item-text'>{subOrg}</span>,
      key: `${org.id},${subOrg}`,
      children: [],
    })),
  }));
  const treeListOrganizations: any = {
    title: '',
    key: '',
    children: dataSourceOrganizations,
  };

  // Get selectedOrganizationsValueSearch to send to 'findObjects()' based on which organization that is selected
  const onOrganizationSelected = (selectedKey: string) => {
    const [id, value] = selectedKey.split(',');
    setSelectedOrganizationValueSearch({ id: parseInt(id), value });
  };

  const selectedObject = selectedObjects.length > 0 ? selectedObjects[0] : null;

  // SEARCH FUNCTION
  // @todo: To be used later on when we have sort and filter queries from the API
  const [sortQuery, setSortQuery] = useState<string | number>();
  const [sortOption, setSortOption] = useState<string>();

  // When searching with optional text string
  const onSearch = (value: string) => {
    setSortOption('name');
    setSortQuery(value);
  };

  useEffect(() => {
    dispatch(fetchTypes('sort', 'parentName'));
    dispatch(fetchObjects());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: `/`, label: `${customerSignature}` },
        {
          path: `/object-settings`,
          label: `${language.object_settings}`,
        },
        {
          path: `/object-settings/object-manager`,
          label: `${language.object_manager}`,
        },

        {
          path: `/${
            selectedObjects?.length === 1 ? selectedObjects[0]?.id : ''
          }`,
          label: `${
            selectedObjects?.length === 1 ? selectedObjects[0]?.extId : ''
          }`,
        },
      ]),
    );
  }, [customerSignature, dispatch, selectedObjects]);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  useEffect(() => {
    setSaveDisabled(isSavedDisabledObjects(selectedObjects, objects));
  }, [objects, selectedObjects]);

  // EVENT HANDLERS
  // Deletes choosen object
  const onDeleteButton = (objects: any) => {
    dispatch(deleteObjects(objects));
    dispatch(fetchObjects());
  };

  // Excluding the selected object
  const otherObjects = objects?.filter((object) => object !== selectedObject);

  // Save changes function
  const onSaveChangesObjectButton = () => {
    if (selectedObjects[0].id === 0) {
      if (
        _.some(
          otherObjects,
          (object: TTEObject) => object.extId === selectedObjects[0].extId,
        )
      ) {
        setExtIdError(true);
      } else {
        dispatch(createObject(selectedObjects[0]));
        setExtIdError(false);
      }
    } else {
      dispatch(updateObjects(selectedObjects));
      setExtIdError(false);
    }
  };

  // Function for handling changes on objects
  const onHandleChanges = (value: string, property: string, objects: any) => {
    const updatedObjects: TTEObject[] = objects?.map((object: TTEObject) => {
      return { ...object, [property]: value };
    });
    const newSelectedObjects: any = [...selectedObjects];
    updatedObjects.forEach((updatedObject: TTEObject) => {
      const index = selectedObjects.findIndex(
        (selectedObject: TTEObject) => selectedObject.id === updatedObject.id,
      );
      newSelectedObjects.splice(index, 1, updatedObject);
    });
    dispatch(setSelectedObjects(newSelectedObjects));
  };

  const onHandleChangesOnTwoProperties = (
    value: any[],
    property: string[],
    objects: any,
  ) => {
    const updatedObjects: TTEObject[] = objects?.map((object: TTEObject) => {
      return { ...object, [property[0]]: value[0], [property[1]]: value[1] };
    });
    const newSelectedObjects: any = [...selectedObjects];
    updatedObjects?.forEach((updatedObject: TTEObject) => {
      const index = selectedObjects.findIndex(
        (selectedObject: TTEObject) => selectedObject.id === updatedObject.id,
      );
      newSelectedObjects.splice(index, 1, updatedObject);
    });
    dispatch(setSelectedObjects(newSelectedObjects));
  };

  // Discard changes function
  const onDiscardChanges = (selectedObjects: any) => {
    const selectedIds = selectedObjects.map(
      (selectedObject: any) => selectedObject.id,
    );
    const unChangedObjects: any = objects.filter(
      (object: any) => selectedIds.indexOf(object.id) !== -1,
    );
    dispatch(setSelectedObjects(unChangedObjects));
  };

  const menu = (
    <Menu
      onClick={(event: any) => {
        setSortQuery(event.key);
        setSortOption(event.domEvent.target.id);
      }}
      className='list-search--menu'
    >
      <Menu.SubMenu title='Some search query..'>
        <Menu.Item
          key={undefined}
          id={undefined}
          className='list-search--menu-item'
        >
          Some sort options to choose from..
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <>
      <ListView
        listTitle={language.types}
        number={types.length}
        onSelect='hidden'
      >
        <TreeListView
          data={treeListData}
          onSelect={onTypeSelected}
          automaticallyExpand
        />
      </ListView>
      <ListView
        listTitle={language.categories}
        number={dataSourceCategories.length}
        onSelect='hidden'
      >
        <TreeListView
          data={[treeListCategories]}
          onSelect={onCategorySelected}
          automaticallyExpand={false} // @todo: Need to fix this one! By setting this one to false I can't neither expand them when I want
          // And I really need to be able to expand them but do not want them expanded as default
        />
        <Divider />
        <Collapse defaultActiveKey={['2']} ghost>
          <Collapse.Panel
            header={language.organizations}
            key='1'
            className='nested-list-collapse'
          >
            <ListView number={dataSourceOrganizations.length} onSelect='hidden'>
              <TreeListView
                data={[treeListOrganizations]}
                onSelect={onOrganizationSelected}
                automaticallyExpand={false} // @todo: Need to fix this one! By setting this one to false I can't neither expand them when I want
                // And I really need to be able to expand them but do not want them expanded as default
              />
            </ListView>
          </Collapse.Panel>
        </Collapse>
      </ListView>
      <div className='inner-content--wrapper'>
        <SectionHeader
          listChoiceHeading={
            selectedObjects.length === 1
              ? selectedObjects[0]?.extId
              : `${language.object_manager}`
          }
          modifiedDate={
            selectedObject
              ? selectedObject.history?.length > 0
                ? timeConverter(selectedObject?.history[0]?.modified)
                : undefined
              : undefined
          }
          modifiedBy={
            selectedObject
              ? selectedObject.history?.length > 0
                ? selectedObject?.history[0]?.modifiedBy
                : ''
              : ''
          }
          changeHandler={onSaveChangesObjectButton}
          discardHandler={() => onDiscardChanges(selectedObjects)}
          displayProp={false}
          history={selectedObject?.history}
          isDisabled={saveDisabled}
          error={
            extIdError === true
              ? `${language.extid_object_already_exists}`
              : null
          }
        />
        <div style={{ overflowY: 'scroll' }}>
          <Form layout='vertical' form={form}>
            <div style={{ position: 'relative' }}>
              <ListSearch onSearch={onSearch} menu={menu} />
              <ObjectsMassChangesView
                listedObjects={listedObjects}
                number={listedObjects.length}
                onChange={onHandleChanges}
                onChangeTwoProperties={onHandleChangesOnTwoProperties}
                limitations={limitations}
                onDelete={onDeleteButton}
                selectedTypeSearch={selectedTypeSearch}
              />
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ObjectManagerPage;
