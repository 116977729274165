import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

// i18N
import intl from '../../i18n/intl';

// REDUX
import { setBreadcrumbs } from '../../slices/ui.slice';

const language: any = intl.messages;

const APIandServicesPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { path: '/api-services', label: `${language.api_services}` },
      ]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Redirect to='/api-services/services' />;
};

export default APIandServicesPage;
