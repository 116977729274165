// i18n
import intl from '../../../i18n/intl';

// STYLES
import '../index.scss';
import { Input, Button } from 'antd';

const language: any = intl.messages;

const ResetPassword = () => {
  const resetPassword = () => {
    // Add the real API request / @todo: Do we need this Reset password function?
    console.log(language.reset_password);
  };

  return (
    <>
      <div className='sign-in--second-heading-box'>
        <h3 className='sign-in--second-heading'>{language.reset_password}</h3>
      </div>
      <div className='sign-in--input-box'>
        <p className='input-label'>{language.username}:</p>
        <Input
          placeholder={language.enter_username}
          className='sign-in--reset-label'
        />
        <p className='input-label'>{language.email}:</p>
        <Input placeholder={language.enter_email} />
        <Button className='sign-in--button' onClick={resetPassword}>
          {language.reset_password}
        </Button>
      </div>
    </>
  );
};

export default ResetPassword;
