// i18N
import intl from '../../i18n/intl';

// STYLES
import { Button, Input, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

type Props = {
  onSearch: any;
  menu: any;
};

const language: any = intl.messages;

export const ListSearch = ({ onSearch = () => {}, menu }: Props) => {
  return (
    <div className='search-wrapper'>
      <Input.Search
        size='small'
        placeholder={language.search}
        onSearch={(value: string) => onSearch(value)}
        onPressEnter={(event: any) => onSearch(event.target.value)}
        allowClear
        style={{ width: 180 }}
      />
      <Dropdown overlay={menu} className='search-dropdown'>
        <Button size='small' type='default' icon={<DownOutlined />} />
      </Dropdown>
    </div>
  );
};
