import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';

// TYPES
import { TApi, Api } from '../types/tapi.type';

// ACTIONS
import {
  finishedLoadingFailure,
  finishedLoadingSuccess,
  isLoadingRequest,
} from './sliceHelpers';

export const initialState = {
  loading: false,
  hasErrors: false,
  selectedApi: Api.createEmpty(),
  allApis: [],
};

// Slice
const slice = createSlice({
  name: 'apis',
  initialState,
  reducers: {
    fetchApisRequest: (state: any) => {
      isLoadingRequest(state);
    },
    fetchApisSuccess: (state: any, { payload }) => {
      const selectedIds = state.allApis.map((api: any) => api.id);
      state.allApis = payload.filter(
        (aT: any) => selectedIds.indexOf(aT.id) !== -1,
      );
      state.allApis = payload;
      if (state.selectedApi.id > 0) {
        state.selectedApi =
          payload.find((p: any) => p.id === state.selectedApi.id) ||
          Api.createEmpty();
      }
      finishedLoadingSuccess(state);
    },
    fetchApisFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    createApiRequest: (state: any) => {
      isLoadingRequest(state);
    },
    createApiSuccess: (state: any, { payload }) => {
      state.allApis.push(payload);
      state.selectedApi = payload;
      finishedLoadingSuccess(state);
    },
    createApiFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    setCurrentApi: (state: any, { payload }) => {
      state.selectedApi = payload;
    },
    setApis: (state: any, { payload }) => {
      state.allApis = payload;
    },
    deleteApiRequest: (state: any) => {
      isLoadingRequest(state);
    },
    deleteApiSuccess: (state: any, payload: any) => {
      state.allApis = state.allApis.filter(
        (api: TApi) => api.id !== payload.payload.id,
      );
      state.selectedapi = Api.createEmpty();
      finishedLoadingSuccess(state);
    },
    deleteApiFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
    updateApiRequest: (state: any) => {
      isLoadingRequest(state);
    },
    updateApiSuccess: (state: any, { payload }) => {
      payload.forEach((p: any) => {
        const index = state.allApis.findIndex((api: TApi) => api.id === p.id);
        state.allApis[index] = p;
      });
      finishedLoadingSuccess(state);
    },
    updateApiFailure: (state: any) => {
      finishedLoadingFailure(state);
    },
  },
});

export default slice.reducer;

// Selectors
export const apisLoading = (state: any) => state.apis.loading;
export const apisSelector = (state: any) => state.apis.allApis as TApi[];
export const apiSelectorCreateNew = () => () => {
  return Api.createEmpty();
};
export const selectedApiSelector = (state: any) => {
  return state.apis.selectedApi;
};

// Actions
export const {
  fetchApisRequest,
  fetchApisSuccess,
  fetchApisFailure,
  createApiRequest,
  createApiSuccess,
  createApiFailure,
  setCurrentApi,
  setApis,
  deleteApiRequest,
  deleteApiSuccess,
  deleteApiFailure,
  updateApiRequest,
  updateApiSuccess,
  updateApiFailure,
} = slice.actions;

export const fetchApis = () => async (dispatch: any) => {
  try {
    dispatch(fetchApisRequest());
    const apis = await api.get({
      endpoint: `/api`,
    });
    dispatch(fetchApisSuccess(apis));
  } catch (e) {
    dispatch(fetchApisFailure());
    return console.error(e);
  }
};

export const createApi = (applicationKey: TApi) => async (dispatch: any) => {
  try {
    dispatch(createApiRequest());
    const { id: _, ...apiBody } = applicationKey;
    const response = await api.post({
      endpoint: `/api`,
      data: apiBody,
    });
    dispatch(createApiSuccess(response));
  } catch (e: any) {
    dispatch(createApiFailure());
    return console.error(e.response.data ? e.response.data : e.response);
  }
};

export const setSelectedApis = (apis: TApi[]) => async (dispatch: any) => {
  dispatch(setApis(apis));
};

export const setSelectedApi = (api: any) => async (dispatch: any) => {
  dispatch(setCurrentApi(api));
};

export const deleteApi = (applicationKey: TApi) => async (dispatch: any) => {
  try {
    dispatch(deleteApiRequest());
    await api.delete({
      endpoint: `/api/${applicationKey.id}`,
    });
    dispatch(deleteApiSuccess(api));
  } catch (e) {
    dispatch(deleteApiFailure());
    return console.error(e);
  }
};

export const updateApis = (apis: TApi[]) => async (dispatch: any) => {
  try {
    dispatch(updateApiRequest());
    const results = await apis.map(async (applicationKey: TApi) => {
      const { ...ApiBody } = applicationKey;
      const response = await api.patch({
        endpoint: `/api/${applicationKey.id}`,
        data: { ...ApiBody },
      });
      return response;
    });
    const responses: any = await Promise.all(results);
    dispatch(updateApiSuccess(responses));
  } catch (e) {
    dispatch(updateApiFailure());
    return console.error(e);
  }
};
