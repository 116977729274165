import { useState, ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// i18n
import intl from '../../i18n/intl';

// STYLES
import {
  Collapse,
  Form,
  Input,
  Checkbox,
  Select,
  Transfer,
  InputNumber,
  Space,
  DatePicker,
  Button,
} from 'antd';
import './index.scss';

// COMPONENTS
import TypesTable from './TypesTable';
import { TEItem } from '../TEToolTip';

// ACTIONS
import { reservationModesSelector } from '../../slices/reservationMode.slice';
import { fieldsSelector } from '../../slices/field.slice';
import { relationTypesSelector } from '../../slices/relationType.slice';
import { timeRulesSelector } from '../../slices/timeRule.slice';
import { typesSelector } from '../../slices/type.slice';
import { organizationsSelector } from '../../slices/organization.slice';
import { rolesSelector } from '../../slices/role.slice';
import {
  getView,
  initView,
  selectVisibleColsForDatasourceId,
  updateView,
} from '../../slices/ui.slice';

// TYPES
import { TField } from '../../types/field.type';
import {
  EDefaultStatus,
  TReservationMode,
  TReservationTemplate,
  TTemplateType,
} from '../../types/reservation.type';
import { TRelationType } from '../../types/relation.type';
import { EReservationPeriodType, TTimeRule } from '../../types/timerule.type';
import { TType } from '../../types/type.type';
import { TRole } from '../../types/role.type';
import { IState } from '../../types/state.interface';
import {
  movedReservationsInfoSelector,
  movedToTrashResponseSelector,
  moveReservations,
  moveReservationsToTrash,
} from '../../slices/reservationTemplate.slice';

const onBlur = () => {};
const onFocus = () => {};

type PropsGeneral = {
  reservationTemplate: TReservationTemplate;
  onChange: Function;
  limitations: any;
};

type PropsTemplateTypes = {
  onChange: Function;
  templateTypes: TTemplateType[];
  typeCombinations: Array<Array<number>>;
  limitations?: any;
};

type PropsConnections = {
  relationTypesConnections: Array<number>;
  fieldsConnections: Array<number>;
  reservationModesConnections: Array<number>;
  organizationsConnections: Array<number>;
  onChange: Function;
};

const language: any = intl.messages;

/* ---------------------------------------------------------------------- */

export const ReservationTemplatesGeneralSettings = ({
  reservationTemplate,
  onChange,
  limitations,
}: PropsGeneral) => {
  // EVENT HANDLERS
  const onChangeInputHandler = (
    event: ChangeEvent<HTMLInputElement>,
    property: string,
    limitationValue?: any,
  ) => {
    if (limitationValue) {
      if (
        new TextEncoder().encode(event?.target.value).length > limitationValue
      ) {
        return;
      }
    }
    onChange(property, event.target.value, limitationValue);
  };

  const onChangeCheckboxHandler = (event: any, property: string) => {
    onChange(property, event.target.checked);
  };

  const onChangeSelectHandler = (event: any, property: string) => {
    onChange(property, event);
  };

  const timeRules: TTimeRule[] = useSelector(timeRulesSelector);
  const roles: TRole[] = useSelector(rolesSelector);
  const organizations: any = useSelector(organizationsSelector);

  // Extending the array of roles with id: 0
  const extendedTimeRules = timeRules.concat({
    id: 0,
    name: '-',
    description: '',
    history: [],
    weekdays: [],
    clockTimeStart: 28800,
    clockTimeEnd: 64800,
    standardStep: 900,
    minimumStep: 900,
    beginStep: 900,
    importStep: 900,
    minimumLength: 1800,
    maximumLength: 86400,
    defaultLength: 3600,
    beginOffset: 0,
    relativeStartTime: -15552000,
    relativeEndTime: 47520000,
    useStartTimeToOpenNextDay: false,
    useSeparateCancellationRules: false,
    relativeCancellationStartTime: -15552000,
    relativeCancellationEndTime: 47520000,
    reservationPeriod1: EReservationPeriodType.NONE,
    reservationLength1: 0,
    reservationCount1: 0,
    reservationPeriod2: EReservationPeriodType.NONE,
    reservationLength2: 0,
    reservationCount2: 0,
    useModifiedByWhenCalculatingUserQuota: false,
    templates: [],
  });

  // Extending the array of roles with id: 0
  const privateRoles = roles.concat({ id: 0, name: '-', description: '' });

  // Extending the array of organizations with id: 0
  const extendedOrganizations = organizations.concat({
    id: 0,
    name: '-',
    description: '',
  });

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.general_settings} key='1'>
        <Form.Item
          label={language.name}
          tooltip={`(Maximum size of ${limitations} bytes)`}
        >
          <Input
            size='small'
            value={reservationTemplate.name}
            onChange={(value: any) =>
              onChangeInputHandler(value, 'name', limitations)
            }
          />
        </Form.Item>
        <p className='form-item-text' style={{ marginBottom: '1em' }}>
          {language.id}: {reservationTemplate.id}
        </p>
        <Form.Item
          label={language.time_rule}
          tooltip={language.help_text_restemplates_timerule}
        >
          <Select
            size='small'
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={reservationTemplate.timeRule}
            onChange={(value: any) => onChangeSelectHandler(value, 'timeRule')}
          >
            {extendedTimeRules.map((timeRule: any) => (
              <Select.Option key={timeRule.id} value={timeRule.id}>
                {timeRule.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.private_role}
          tooltip={language.help_text_restemplates_privaterole}
        >
          <Select
            size='small'
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={reservationTemplate.privateRole}
            onChange={(value: any) =>
              onChangeSelectHandler(value, 'privateRole')
            }
          >
            {privateRoles.map((role: any) => (
              <Select.Option key={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.standard_organization}
          tooltip={language.help_text_restemplates_standardorg}
        >
          <Select
            size='small'
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={reservationTemplate.standardOrganization}
            onChange={(value: any) =>
              onChangeSelectHandler(value, 'standardOrganization')
            }
          >
            {extendedOrganizations.map((org: any) => (
              <Select.Option key={org.id} value={org.id}>
                {org.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={language.default_status}
          tooltip={language.help_text_restemplates_deafultstatus}
        >
          <Select
            size='small'
            onFocus={onFocus}
            onBlur={onBlur}
            value={reservationTemplate.defaultStatus}
            onChange={(value: EDefaultStatus) =>
              onChangeSelectHandler(value, 'defaultStatus')
            }
          >
            {Object.entries(EDefaultStatus).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {language[value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <TEItem helpText={language.help_text_restemplates_active}>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'active')
            }
            checked={reservationTemplate.active}
          >
            {language.active}
          </Checkbox>
        </TEItem>
        <TEItem helpText={language.help_text_restemplates_affectorders}>
          <Checkbox
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'affectOrders')
            }
            checked={reservationTemplate.affectOrders}
          >
            {language.affect_orders}
          </Checkbox>
        </TEItem>
        <TEItem helpText={language.help_text_delete_reservations_when_culling}>
          <Checkbox
            className='checkbox-bold-text'
            onChange={(checked: any) =>
              onChangeCheckboxHandler(checked, 'deleteWhenCulling')
            }
            checked={reservationTemplate.deleteWhenCulling}
          >
            {language.delete_reservation_when_culling}
          </Checkbox>
        </TEItem>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const ReservationTemplatesTypes = ({
  templateTypes,
  typeCombinations,
  onChange,
  limitations,
}: PropsTemplateTypes) => {
  const dispatch = useDispatch();

  // Value for the columns we want to send to TE Views
  const visibleCols = useSelector((state: IState) =>
    selectVisibleColsForDatasourceId(state, 'templateTable'),
  );

  // Effect to load stored views and to update stored views
  useEffect(() => {
    dispatch(getView('templateTable'));
    dispatch(initView('templateTable'));
  }, [dispatch]);

  const updateVisibleCols = (columns: string[], value: any) => {
    const updatedValues = { ...visibleCols };
    columns.forEach((column: string) => {
      updatedValues[column] = value;
    });
    dispatch(updateView('templateTable', updatedValues));
  };

  const columnsForTypes = [
    'properties',
    'occurrence',
    'members',
    'availability',
    'special',
    'text',
    'padding',
  ];

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.types} key='2'>
        <div className='add-type-choices-wrapper'>
          {columnsForTypes.map((column: any) => (
            <Form.Item key={column}>
              <Checkbox
                key={column}
                checked={visibleCols[column]}
                onChange={(event) =>
                  updateVisibleCols([column], event.target.checked)
                }
              >
                {language[column]}
              </Checkbox>
            </Form.Item>
          ))}
        </div>
        <TypesTable
          visibleCols={visibleCols}
          templateTypes={templateTypes}
          typeCombinations={typeCombinations}
          onTypesChanged={onChange}
          onCombinationsChanged={onChange}
          limitations={limitations}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */

export const ReservationTemplatesConnections = ({
  onChange,
  relationTypesConnections,
  reservationModesConnections,
  fieldsConnections,
  organizationsConnections,
}: PropsConnections) => {
  // Relation types
  // Fetching all available relation types
  const relationTypes: TRelationType[] = useSelector(relationTypesSelector);

  // Data with ALL available relation types to choose from
  const dataSourceRelationTypes = relationTypes.map((rt: any) => ({
    key: String(rt.id),
    title: rt.name,
    description: rt.description,
  }));

  // Current relation types
  const targetKeysRelationTypes = relationTypesConnections?.map(
    (selectedRelationType: any) => String(selectedRelationType),
  );
  const [selectedKeysRelationTypes, setSelectedKeysRelationTypes] = useState<
    string[]
  >([]);

  const onSelectionRelationTypes = (
    sourceSelectedKeys: any,
    targetSelectedKeys: any,
  ) => {
    setSelectedKeysRelationTypes([
      ...sourceSelectedKeys,
      ...targetSelectedKeys,
    ]);
  };

  const onSelectionChangeRelationTypes = (nextTargetKeys: any) => {
    onChange(
      'relationTypes',
      nextTargetKeys.map((key: string) => parseInt(key)),
    );
  };

  // Fields
  // Fetching all available fields
  const fields: TField[] = useSelector(fieldsSelector);

  // Data with ALL available fields to choose from
  const dataSourceFields = fields.map((field: any) => ({
    key: String(field.id),
    title: field.extId,
    description: field.description,
  }));

  const targetKeysFields = fieldsConnections?.map((selectedField: any) =>
    String(selectedField),
  );

  const [selectedKeysFields, setSelectedKeysFields] = useState<string[]>([]);

  const onSelectionFields = (
    sourceSelectedKeys: any,
    targetSelectedKeys: any,
  ) => {
    setSelectedKeysFields([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onSelectionChangeFields = (nextTargetKeys: any) => {
    onChange('fields', nextTargetKeys);
  };

  // Reservation modes
  // Fetching all available res. modes:
  const reservationModes: TReservationMode[] = useSelector(
    reservationModesSelector,
  );

  // Data with ALL available res.modes
  const dataSourceReservationModes = reservationModes.map((mode: any) => ({
    key: String(mode.id),
    title: mode.name,
    description: mode.description,
  }));

  const targetKeysReservationModes = reservationModesConnections?.map(
    (selectedMode: any) => String(selectedMode),
  );
  const [selectedKeysReservationModes, setSelectedKeysReservationModes] =
    useState<string[]>([]);

  const onSelectionReservationModes = (
    sourceSelectedKeysReservationModes: any,
    targetSelectedKeysReservationModes: any,
  ) => {
    setSelectedKeysReservationModes([
      ...sourceSelectedKeysReservationModes,
      ...targetSelectedKeysReservationModes,
    ]);
  };

  const onSelectionChangeReservationModes = (
    nextTargetKeysReservationModes: any,
  ) => {
    onChange(
      'reservationModes',
      nextTargetKeysReservationModes.map((key: string) => parseInt(key)),
    );
  };

  // Organizations
  // Fetching all available organizations
  const organizations: any = useSelector(organizationsSelector);

  // Data with all available organizations
  const dataSourceOrganizations = organizations.map((org: any) => ({
    key: String(org.id),
    title: org.name,
    description: org.description,
  }));

  const targetKeysOrganizations = organizationsConnections?.map(
    (selectedOrganization: any) => String(selectedOrganization),
  );
  const [selectedKeysOrganizations, setSelectedKeysOrganizations] = useState<
    string[]
  >([]);

  const onSelectionOrganizations = (
    sourceSelectedKeys: any,
    targetSelectedKeys: any,
  ) => {
    setSelectedKeysOrganizations([
      ...sourceSelectedKeys,
      ...targetSelectedKeys,
    ]);
  };

  const onSelectionChangeOrganizations = (nextTargetKeys: any) => {
    onChange(
      'organizations',
      nextTargetKeys.map((key: string) => parseInt(key)),
    );
  };
  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.connections} key='3'>
        <Form.Item label={language.relation_types}>
          <Transfer
            dataSource={dataSourceRelationTypes}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeysRelationTypes}
            selectedKeys={selectedKeysRelationTypes}
            onChange={onSelectionChangeRelationTypes}
            onSelectChange={onSelectionRelationTypes}
            render={(item) => item.title}
          />
        </Form.Item>
        <Form.Item label={language.fields}>
          <Transfer
            dataSource={dataSourceFields}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeysFields}
            selectedKeys={selectedKeysFields}
            onChange={onSelectionChangeFields}
            onSelectChange={onSelectionFields}
            render={(item) => item.title}
          />
        </Form.Item>
        <Form.Item label={language.reservation_modes}>
          <Transfer
            dataSource={dataSourceReservationModes}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeysReservationModes}
            selectedKeys={selectedKeysReservationModes}
            onChange={onSelectionChangeReservationModes}
            onSelectChange={onSelectionReservationModes}
            render={(item) => item.title}
          />
        </Form.Item>
        <Form.Item label={language.organizations}>
          <Transfer
            dataSource={dataSourceOrganizations}
            showSearch
            filterOption={(input: any, option: any) =>
              option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            targetKeys={targetKeysOrganizations}
            selectedKeys={selectedKeysOrganizations}
            onChange={onSelectionChangeOrganizations}
            onSelectChange={onSelectionOrganizations}
            render={(item: any) => item.title}
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */
type PropsMoveTo = {
  reservationTemplates: TReservationTemplate[];
  reservationTemplate: TReservationTemplate;
};

export const ReservationTemplatesMoveReservations = ({
  reservationTemplates,
  reservationTemplate,
}: PropsMoveTo) => {
  const dispatch = useDispatch();
  const moveReservationsInfo = useSelector(movedReservationsInfoSelector);

  // All types
  const types: TType[] = useSelector(typesSelector);

  const [moveTo, setMoveTo] = useState<number>();
  const [count, setCount] = useState<number>();
  const [date, setDate] = useState<any>();
  const [templateType, setTemplateType] = useState<number>();

  const newArrayOfReservationTemplates = reservationTemplates
    .filter((rt: TReservationTemplate) => reservationTemplate.id !== rt.id)
    .map((rt: TReservationTemplate) => ({
      key: rt.id,
      value: rt.id,
      name: rt.name,
    }));

  let parsedDate: number;
  if (date !== undefined) {
    parsedDate = parseInt(date.format('X'));
  }

  const onMoveReservations = () => {
    /* Send the updated states to the server with an API-request including:
      {moveTo}, {count}, {date}, {templateType}  */
    const moveReservationsRequest = {
      reservationTemplate: moveTo,
      count,
      date: parsedDate,
      type: templateType,
    };
    dispatch(moveReservations(moveReservationsRequest, reservationTemplate));
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header={language.move_reservations} key='4'>
        <Form.Item>
          <Select
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            size='small'
            value={moveTo}
            onChange={setMoveTo}
            placeholder={language.move_to}
          >
            {newArrayOfReservationTemplates.map((template: any) => (
              <Select.Option key={template.key} value={template.value}>
                {template.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          tooltip={language.help_text_restemplates_movereservations_count}
          label={language.count}
        >
          <InputNumber
            size='small'
            min={1}
            max={100}
            value={count}
            onChange={() => setCount}
          />
        </Form.Item>
        <Form.Item label={language.from_date}>
          <Space direction='vertical'>
            <DatePicker size='small' value={date} onChange={setDate} />
          </Space>
        </Form.Item>
        <Form.Item label={`${language.type}`}>
          <Select
            showSearch
            onFocus={onFocus}
            onBlur={onBlur}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            size='small'
            value={templateType}
            onChange={setTemplateType}
            placeholder={`${language.choose_type}`}
          >
            {types.map((type: any) => (
              <Select.Option key={type.id} value={type.id}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          size='small'
          type='default'
          disabled={moveTo === undefined}
          onClick={onMoveReservations}
        >
          {language.move_reservations}
        </Button>
        {moveReservationsInfo !== '' && (
          <>
            <p
              className='small-heading-text'
              style={{ marginBottom: 0, marginTop: 20 }}
            >
              Total number of reservations:{' '}
              {moveReservationsInfo.totalNumberOfReservations}
            </p>
            <p className='small-heading-text'>
              Number of reservations moved:{' '}
              {moveReservationsInfo.numberOfReservationsMoved}
            </p>
            {moveReservationsInfo.failedReservations?.map(
              (failedReservation: any) => (
                <p key={failedReservation}>{failedReservation}</p>
              ),
            )}
          </>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

/* ---------------------------------------------------------------------- */
type PropsMoveToTrash = {
  reservationTemplate: TReservationTemplate;
};

export const MoveReservationsToTrash = ({
  reservationTemplate,
}: PropsMoveToTrash) => {
  const dispatch = useDispatch();
  const movedToTrashResponse = useSelector(movedToTrashResponseSelector);

  const [moveToTrashDone, setMoveToTrashDone] = useState(false);

  return (
    <Collapse defaultActiveKey={['1']} ghost style={{ fontSize: 18 }}>
      <Collapse.Panel header='Move reservations to trash' key='5'>
        <Button
          size='small'
          type='default'
          onClick={() => {
            dispatch(moveReservationsToTrash(reservationTemplate));
            setMoveToTrashDone(true);
          }}
        >
          Validate reservations and move to trash
        </Button>
        <p style={{ fontSize: '12px' }}>(If validation fails, move to trash)</p>
        {moveToTrashDone && movedToTrashResponse !== '' ? (
          <>
            <p
              className='small-heading-text'
              style={{ marginBottom: 0, marginTop: 20 }}
            >
              Number of correct reservations:{' '}
              {movedToTrashResponse.numberOfCorrectReservations}
            </p>
            <p className='small-heading-text'>
              Number of reservations moved to trash:{' '}
              {movedToTrashResponse.numberOfReservationsMovedToTrash}
            </p>
          </>
        ) : null}
      </Collapse.Panel>
    </Collapse>
  );
};
