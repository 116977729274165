import { InputNumber } from 'antd';

// Functions to set correct hours and minutes for InputNumber-elements
// Calculating hours and minutes from the input in seconds
const durationHours = (property: any) => {
  const hours = Math.floor(property / 60 / 60);
  return hours;
};

const durationMinutes = (property: any) => {
  const hours = Math.floor(property / 60 / 60);
  const residualSeconds = property - hours * 60 * 60;
  const minutes = residualSeconds / 60;
  return minutes;
};

export const FromSecondsInputNumber = (
  value: any,
  onChangeHandler: any,
  property: string,
  limitations?: any,
  disabled?: boolean,
) => {
  return (
    <>
      <InputNumber
        size='small'
        min={0}
        max={4391}
        value={durationHours(value)}
        onChange={(hours: any) => {
          if (new TextEncoder().encode(value).length > limitations) {
            return;
          }
          onChangeHandler(
            (hours * 60 * 60 + durationMinutes(value) * 60).toString(),
            property,
          );
        }}
        disabled={disabled}
      />
      <p className='semicolon-divider'> : </p>
      <InputNumber
        size='small'
        min={0}
        max={60}
        step={5}
        value={durationMinutes(value)}
        onChange={(minutes: any) => {
          if (new TextEncoder().encode(value).length > limitations) {
            return;
          }
          onChangeHandler(
            (minutes * 60 + durationHours(value) * 60 * 60).toString(),
            property,
          );
        }}
        disabled={disabled}
      />
    </>
  );
};

export const fromSecondsToHours = (
  value: any,
  onChangeHandler: any,
  property: string,
  limitations?: any,
  disabled?: boolean,
) => {
  return (
    <InputNumber
      size='small'
      min={0}
      max={24}
      value={durationHours(value)}
      onChange={(hours: any) => {
        if (new TextEncoder().encode(value).length > limitations) {
          return;
        }
        onChangeHandler(
          (hours * 60 * 60 + durationMinutes(value) * 60).toString(),
          property,
        );
      }}
      disabled={disabled}
    />
  );
};
